import { GetValues, OnSubmit, SetCodeDisabled, SetTempPatientData, SetValue, TempPatientData } from './types';

export const handleSearchPatient = (
  getValues: GetValues,
  setValue: SetValue,
  setCodeDisabled: SetCodeDisabled,
  formErrors,
  setFormErrors,
  setTempPatientData: SetTempPatientData,
  tempPatientData: TempPatientData,
  onSubmit: OnSubmit,
) => {
  return (name: string) => {
    if (name !== 'id') {
      const value = getValues(name);
      setCodeDisabled(!!value);
      if (!!value) {
        setValue('id', null);
        setFormErrors({
          ...formErrors,
          patient: {
            first_name: false,
            second_name: false,
            phone: false,
            not_unique: {
              first_name: false,
              last_name: false,
              second_name: false,
              birth_date: false,
              phone: false,
            },
            invalid: {
              phone: false,
              birth_date: false,
              email: false,
            },
          },
        });
      }
      switch (name) {
        case 'birth_date':
        case 'email':
        case 'stream':
          setTempPatientData({ ...tempPatientData, [name]: value });
          break;
      }
    }
    onSubmit(getValues());
  };
};

// Валидация строки вида "dd.mm.yyyy". Дата не может быть раньше 1900 года. Не может быть позже текущего года.
export const isDateStringValid = (string) => {
  // Проверка на совпадение по форме "dd.mm.yyyy"
  const regex = /^\d{2}\.\d{2}\.\d{4}$/;
  if (!regex.test(string)) {
    return false;
  }

  // Разделение на дни, месяца и годы для дальшейней проверки
  const [day, month, year] = string.split('.');

  // Проверка годов: не меньше 1900-ого, не больше текущего
  if (year < 1900 || year > new Date().getFullYear()) {
    return false;
  }

  // Создание объекта даты из вводных данных, для дальшейней проверки
  const date = new Date(year, month - 1, day);

  // Проверка через эту дату частей вводных данных. Соотносятся ли они с созданной датой
  if (date && date.getMonth() === month - 1 && date.getDate() == day && date.getFullYear() == year) {
    return true;
  }

  return false;
};

// Валидация строки с электронной почтой
export const isEmailStringValid = (string) => {
  const re = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/;
  return re.test(String(string).toLowerCase());
};

// Получает массив строк, возвращает массив уникальных строк. "Строка" и "строка" - неуникальны. В приоритете будет "Строка"
export const getUniqueStrings = (arr) => {
  const uniquenessObj = {};

  for (const str of arr) {
    const lowerStr = str.toLowerCase();
    if (lowerStr in uniquenessObj) {
      if (uniquenessObj[lowerStr][0] !== uniquenessObj[lowerStr][0].toUpperCase() && str[0] === str[0].toUpperCase()) {
        uniquenessObj[lowerStr] = str;
      }
    } else {
      uniquenessObj[lowerStr] = str;
    }
  }

  return Object.values(uniquenessObj);
};
