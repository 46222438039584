import React, {useState} from "react";
import styled from "styled-components";

type Props = {
  text: string;
  max: number;
  delta?: number;
  size?: number;
  setTextHeight?: Function;
}

const EllipsisTextField = styled.div`
  display: block;
`

const EllipsisTextButton = styled.span`
  color:#577BF9;
  margin-left: 4px;
  cursor: pointer;
`

export function TextEllipsis({text, max, delta = 22, size = 1800, setTextHeight} : Props) {
  const result = text.length > size ? text.substring(0, size - 1) : text;
  const isCollapsed = result.length > max + delta;
  const [collapsed, setCollapsed] = useState<boolean>(isCollapsed);

  return (
      isCollapsed
      ?
        <EllipsisTextField>
        {collapsed ? result.substring(0, max - 1) + '...' : result}
        <EllipsisTextButton
          onClick={(event) => {
          event.stopPropagation();
          setCollapsed(!collapsed);
          setTextHeight && setTextHeight(0);
        }}>
          {collapsed ? 'Показать' : 'Скрыть'} весь текст
        </EllipsisTextButton>
        </EllipsisTextField>
      : result
  )
}
