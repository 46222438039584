import React, { FC } from 'react';

const SearchIcon: FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9183 9.66676H10.2567L10.0267 9.43843C10.8392 8.49093 11.3333 7.2626 11.3333 5.91676C11.3333 2.9251 8.90833 0.500095 5.91667 0.500095C2.925 0.500095 0.5 2.9251 0.5 5.91676C0.5 8.90843 2.925 11.3334 5.91667 11.3334C7.2625 11.3334 8.49 10.8401 9.4375 10.0284L9.6675 10.2568V10.9168L13.8325 15.0759L15.075 13.8334L10.9183 9.66676V9.66676ZM5.91666 9.66672C3.84499 9.66672 2.16666 7.98839 2.16666 5.91672C2.16666 3.84589 3.84499 2.16672 5.91666 2.16672C7.98749 2.16672 9.66666 3.84589 9.66666 5.91672C9.66666 7.98839 7.98749 9.66672 5.91666 9.66672V9.66672Z"
        fill="#A8AEB5"
      />
    </svg>
  );
};

export default SearchIcon;
