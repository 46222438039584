import React from 'react';
import { HashRouter, BrowserRouter, Route, Navigate, Routes, useLocation } from 'react-router-dom';
import Login from '../pages/LoginPage/Login';
import ProfilePage from '../pages/ProfilePage/ProfilePage';
import PatientsPage from '../pages/PatientPage';
import ReAuth from '../pages/LoginPage/ReAuth';
import StaffPage from '../pages/StaffPage';
import PricePage from '../pages/PricePage';
import CalendarPage from '../pages/CalendarPage';
import MedicalCardPage from '../pages/MedcardPage';
import { useGetPermissionsQuery, useGetUserQuery } from '../services/users';
import { useAppSelector } from '../store/hooks';
import { routerConfig } from './routerConfig';
import { VisitsAndPaymentsPage } from '../pages/VisitsAndPaymentsPage';
import WelcomeModal from '../components/WelcomeModal';
import { ReportPage } from '../pages/ReportPage';
import { StudyModal } from '../components/StudyModal';
import { FreezeModal } from '../components/FreezeModal';
import { PermissionDenied } from '../components/PermissionDenied';
import Scrollbars from 'react-custom-scrollbars-2';
import { NotFound } from '../components/NotFound';

function PrivateRoute({ children, permission = '' }) {
  const { user, isAuth } = useAppSelector((state) => state.common);
  const { id } = user || {};
  const { isLoading: isUserLoading } = useGetUserQuery(user?.id, { skip: !user?.id });
  const { data: permissions, isLoading: isPermissionsLoading } = useGetPermissionsQuery(id, { skip: !id });
  const location = useLocation();

  if (isPermissionsLoading || isUserLoading) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div className="spinner" />
      </div>
    );
  }

  if (!isAuth) {
    return <Navigate to={routerConfig.REAUTH.path} state={{ from: location }} />;
  }

  if (!permission || (permission && permissions[permission])) {
    return (
      <>
        {children}
        <WelcomeModal />
        <StudyModal />
        <FreezeModal />
      </>
    );
  }

  return <PermissionDenied />;
}

export default function AppNavigator() {
  // eslint-disable-next-line
  const Router = process.env.REACT_APP_ELECTRON ? HashRouter : BrowserRouter;
  return (
    <Router>
      <Routes>
        <Route exact path={routerConfig.ROOT.path} element={<Login />} />
        <Route path={routerConfig.REAUTH.path} element={<ReAuth />} />
        {/*<Route*/}
        {/*  path={routerConfig.RECEPTION.path}*/}
        {/*  element={*/}
        {/*    <PrivateRoute>*/}
        {/*      <ReceptionPage />*/}
        {/*    </PrivateRoute>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path={routerConfig.PROFILE.path}
          element={
            <PrivateRoute permission={'edit_clinic_profile'}>
              <Scrollbars style={{ width: '97.5%', height: '100vh', overflowX: 'hidden' }} autoHide>
                <ProfilePage />
              </Scrollbars>
            </PrivateRoute>
          }
        />
        <Route
          path={routerConfig.PATIENTS.path}
          element={
            <PrivateRoute>
              <PatientsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routerConfig.STAFF.path}
          element={
            <PrivateRoute>
              <Scrollbars style={{ width: '97.5%', height: '100vh', overflowX: 'hidden' }} autoHide>
                <StaffPage />
              </Scrollbars>
            </PrivateRoute>
          }
        />
        <Route
          path={routerConfig.SCHEDULE.path}
          element={
            <PrivateRoute>
              <CalendarPage />
            </PrivateRoute>
          }
        />
        <Route
          path={routerConfig.PRICE.path}
          element={
            <PrivateRoute>
              <Scrollbars style={{ width: '97.5%', height: '100vh', overflowX: 'hidden' }} autoHide>
                <PricePage />
              </Scrollbars>
            </PrivateRoute>
          }
        />
        <Route
          path={routerConfig.MEDCARD.path}
          element={
            <PrivateRoute>
              <Scrollbars style={{ width: '97.5%', height: '100vh', overflowX: 'hidden' }} autoHide>
                <MedicalCardPage />
              </Scrollbars>
            </PrivateRoute>
          }
        />
        <Route
          path={routerConfig.VISITS_AND_PAYMENTS.path}
          element={
            <PrivateRoute>
              <Scrollbars style={{ width: '97.5%', height: '100vh', overflowX: 'hidden' }} autoHide>
                <VisitsAndPaymentsPage />
              </Scrollbars>
            </PrivateRoute>
          }
        />
        <Route
          path={routerConfig.REPORT.path}
          element={
            <PrivateRoute permission={'create_report'}>
              <ReportPage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
