import styled from 'styled-components';
import { styled as styledMui } from '@material-ui/styles';
import CalendarPicker from '@mui/lab/CalendarPicker';

export const CalendarWrapper = styled.div`
  position: relative;
  background: white;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  width: 288px;
`;
export const CalendarFooter = styled.div`
  position: relative;
  margin-top: -12px;
`;

interface CustomCalendarProps {
  selectedColor?: string;
}

export const CustomCalendar = styledMui(CalendarPicker)(({ selectedColor }: CustomCalendarProps) => ({
  padding: '20px 8px 0',
  boxSizing: 'border-box',
  width: '288px !important',
  color: '#515D6B',
  '& .MuiPickersDay-today': {
    backgroundColor: 'inherit',
  },
  '& .Mui-disabled': {
    backgroundColor: 'inherit !important',
    color: 'rgba(0, 0, 0, 0.38) !important',
  },
  '& .MuiCalendarPicker-viewTransitionContainer': {
    marginTop: '16px',
    overflow: 'hidden',
  },
  '& .PrivatePickersSlideTransition-root': {
    minHeight: 'auto',
    height: '100%',
    '& .css-i6bazn': {
      position: 'relative',
    },
  },
  '& .MuiTypography-root': {
    width: '45px',
    height: '24px',
    fontSize: '12px',
    fontWeight: '500',
    '&:nth-child(1)': {
      '&::after': {
        content: '"н"',
        display: 'inline-block',
      },
    },
    '&:nth-child(2)': {
      '&::after': {
        content: '"т"',
        display: 'inline-block',
      },
    },
    '&:nth-child(3)': {
      '&::after': {
        content: '"р"',
        display: 'inline-block',
      },
    },
    '&:nth-child(4)': {
      '&::after': {
        content: '"т"',
        display: 'inline-block',
      },
    },
    '&:nth-child(5)': {
      '&::after': {
        content: '"т"',
        display: 'inline-block',
      },
    },
    '&:nth-child(6)': {
      '&::after': {
        content: '"б"',
        display: 'inline-block',
      },
    },
    '&:nth-child(7)': {
      '&::after': {
        content: '"с"',
        display: 'inline-block',
      },
    },
  },
  '& .MuiPickersDay-root': {
    width: '20px',
    height: '20px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '22px',
    margin: '0 10.5px',
    borderRadius: '4px',
    paddingLeft: '1px',
    color: '#515D6B',
    outline: 'none',
    '&.Mui-selected': {
      backgroundColor: selectedColor ? selectedColor : 'white !important',
      color: '#515D6B',
      fontWeight: 'normal',
      '&:hover': {
        backgroundColor: '#EFF0F3',
      },
    },
    '&.MuiPickersDay-today': {
      border: 'none',
    },
  },
  '& .css-1dozdou': {
    margin: 0,
    padding: 0,
    justifyContent: 'space-around',
    fontFamily: 'Roboto',
    fontSize: '14px',
    '& .css-l0iinn': {
      margin: 0,
      fontWeight: '400',
      '& .MuiButtonBase-root': {
        display: 'none',
      },
    },
    '& .css-1v994a0': {
      marginLeft: '20px',
    },
    '& .PrivatePickersFadeTransitionGroup-root': {
      width: '80px',
      '& .css-1v994a0': {
        textAlign: 'center',
      },
    },
  },
  '& .css-k008qs': {
    transform: 'rotate(270deg)',
    position: 'absolute',
    width: '60px',
    left: '30%',
    top: '23px',
    '& .MuiIconButton-root.Mui-disabled': {
      '& .MuiSvgIcon-root': {
        color: '#A8AEB5',
      },
    },
    '& .MuiIconButton-root': {
      outline: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiSvgIcon-root': {
      color: '#253446',
      '&:hover': {
        color: '#577BF9',
      },
    },
  },
  '& .MuiSvgIcon-root': {
    width: '15px',
    height: '15px',
  },
}));

export const YearControls = styled.div`
  position: absolute;
  right: 31%;
  top: 5px;
`;

export const YearControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: space-between;
`;

export const ArrowWrapper = styled.div`
  color: #253446;
  :hover {
    color: #577bf9;
  }
`;
