import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { routerConfig } from '../../../navigation/routerConfig';

function Redirection({ staffPage: { isRedirectingToSchedule } }: any) {
  return isRedirectingToSchedule ? <Navigate to={routerConfig.WORKDAYS.path} /> : <div />;
}

export default connect((state: any) => ({ staffPage: state.staffPage }), {})(Redirection);
