import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Chat from '../Chat/Chat.js';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 56px;
  padding-right: 70px;
`;

const Title = styled.div`
  letter-spacing: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #253446;
  margin-right: auto;
  display: flex;
  column-gap: 17px;
`;

const BackButton = styled.img`
  width: 17px;
  margin-left: 20px;
  margin-right: 35px;
`;
type ChatContainerProps = {
  isExpanded: boolean;
};
const ChatContainer = styled.div<ChatContainerProps>`
  transform: translateX(${({ isExpanded }) => (isExpanded ? '-200px' : '0px')});
  transition: 0.25s;
`;

function Header({
  drawerState: { isExpanded },
  text,
  print = false,
  isPrintActive = false,
  onPrint,
}: {
  drawerState: RootState['drawerState'];
  text: string | ReactElement;
  print?: boolean;
  isPrintActive?: boolean;
  onPrint?: VoidFunction;
}) {
  const navigate = useNavigate();
  return (
    <Layout>
      <BackButton src="./img/black/go-back.svg" onClick={() => navigate(-1)} />
      <Title>
        {text}
        {print && (
          <img
            src={`./img/${isPrintActive ? 'black' : 'grey'}/printer.svg`}
            alt={'printer'}
            width={18}
            height={18}
            style={{ marginLeft: '4px' }}
            onClick={() => (isPrintActive ? onPrint?.() : null)}
          />
        )}
      </Title>
      <ChatContainer isExpanded={isExpanded}>
        <Chat />
      </ChatContainer>
    </Layout>
  );
}

export default connect(
  (state: RootState) => ({
    drawerState: state.drawerState,
  }),
  {},
)(Header);
