/* eslint-disable no-fallthrough */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { v1 as uuidv1 } from 'uuid';
import {
  getSchedulerModelType,
  selectionHandlerType,
  sessionClickHandlerType,
  viewSwitchHandlerType,
  sessionDropHandlerType,
  checkForHideCellsType,
  dateToStringFuncType,
  getEvenOddStringType,
  getDateRangeType,
  createSessionsInPeriodType,
  getTargetPeriodType,
  getSessionsDaysDatesType,
  getDatesWithSessionsType,
  getFilteredDatesType,
  sessionColorSetterType,
  calcAndDispatchModelAndWidthType,
  capitalizeType,
  sessionsSetType,
  getSessionsType,
  scrollTableToDateType,
  getMatchesWarningWindowMessageType,
  getMatchingSessionsType,
  getGroupedMatchingSessionsType,
  markMatchingSessionsType,
  calculateMatchesType,
  sessionResizeHandlerType,
  modelType,
} from './types';
import { format, areIntervalsOverlapping, eachDayOfInterval } from 'date-fns';
import russianLocale from 'date-fns/locale/ru';
import {
  setSchedulerWidth,
  setDatesToRender,
  setTableColumnModel,
  setDatesWithSessions,
  setSessionsMatches,
  setSelectedCabinets,
  setSessionWithTooltipId,
  setSessionsToCreate,
  setSessionsToRedact,
  setSessionsToDelete,
  setDateRange,
  setSelectedDate,
  setDateRangeV2,
  setSelectedCabinetsV2,
  setTableDisplayMode,
  setWorkdays,
  setHolidays,
  setDatesToHighlightSet,
} from '../../reducer';
import { TABLE_BODY_SELECTOR, TABLE_HEADER_SELECTOR, TIMEZONE_OFFSET, viewTypes } from './settings';
import { weekdaysKeys } from '../../../../../../pages/CalendarPage/ScheduleTable/settings';

const getTableHeight = ({ slotMinTime, slotMaxTime }) => {
  const minTimeHours = Number(slotMinTime.substring(0, slotMinTime.indexOf(':')));
  const maxTimeHours = Number(slotMaxTime.substring(0, slotMaxTime.indexOf(':')));
  const minTimeMinutes = Number(slotMinTime.substring(slotMinTime.indexOf(':') + 1, slotMinTime.lastIndexOf(':')));
  const maxTimeMinutes = Number(slotMaxTime.substring(slotMaxTime.indexOf(':') + 1, slotMaxTime.lastIndexOf(':')));

  const timeDeltaInMinutes = (maxTimeHours - minTimeHours) * 60 + (maxTimeMinutes - minTimeMinutes);
  const tableRowsCounter = timeDeltaInMinutes / 30;

  return (tableRowsCounter + 3) * 32 + 3;
};

// Добавляет кабинеты к текущим, если нет кабинета для отображения текущего совпадения целиком
export const getMissingCabinets = (currentSessionMatchCabinetsIds, selectedCabinets, cabinetsDictionary, dispatch) => {
  const selectedCabinetsIds = selectedCabinets.map((cabinet) => cabinet.id);
  const cabinetsIdsToAdd = currentSessionMatchCabinetsIds.filter(
    (cabinetID) => !selectedCabinetsIds.includes(~~cabinetID),
  );
  if (cabinetsIdsToAdd.length > 0) {
    const cabinetsToAdd = cabinetsDictionary.filter((cabinet) => cabinetsIdsToAdd.includes(cabinet.id.toString()));
    dispatch(setSelectedCabinets([...selectedCabinets, ...cabinetsToAdd]));
  }
};

// Принимает массив сессий, возвращает массив дат в формате Number, без учета часов, минут и секунд. Без повторов.
const getSessionsDaysDates: getSessionsDaysDatesType = (sessionsArray) => {
  return [...new Set(sessionsArray.map((session) => new Date(session._instance.range.start).setHours(0, 0, 0, 0)))];
};

// Получение смещения времени по часовому поясу, первой даты и последней даты из datesInRange
const getDateRange: getDateRangeType = (datesInRange) => {
  return {
    timeZoneOffset: new Date().getTimezoneOffset() * 60000,
    firstRangeDate: new Date(datesInRange[0]).getTime(),
    lastRangeDate: new Date(datesInRange[datesInRange.length - 1]).setDate(
      datesInRange[datesInRange.length - 1].getDate() + 1,
    ),
  };
};

// Получение массива сессий, с разными фильтрами
export const getSessions: getSessionsType = (
  datesInRange,
  targetDayOfWeek = undefined,
  targetCabinets = undefined,
  targetStafferID = undefined,
  showEvenDates = undefined,
  showOddDates = undefined,
  targetRange = undefined,
  staffSchedulerAPI,
  needIncorrectEvents = false, // Нужны ли сессии, которые не прошли проверку?
) => {
  const { timeZoneOffset, firstRangeDate, lastRangeDate } = getDateRange(datesInRange);
  const targetCabinetsIds = targetCabinets ? targetCabinets.map((cabinet) => cabinet.id) : [];
  const correctEvents = [];
  const incorrectEvents = [];
  staffSchedulerAPI.getEvents().forEach((session) => {
    const sessionDate = new Date(session._instance.range.start).getTime() + timeZoneOffset;
    let check = sessionDate >= firstRangeDate && sessionDate <= lastRangeDate;

    if (targetRange) {
      check =
        check &&
        targetRange.start.getHours() === session._instance.range.start.getHours() &&
        targetRange.end.getHours() === session._instance.range.end.getHours() &&
        targetRange.start.getMinutes() === session._instance.range.start.getMinutes() &&
        targetRange.end.getMinutes() === session._instance.range.end.getMinutes();
      if (!check) {
        if (needIncorrectEvents) {
          incorrectEvents.push(session);
        }
        return;
      }
    }

    if (showEvenDates || showOddDates) {
      if (targetRange && showEvenDates && showOddDates) {
        targetRange.start.getDate() % 2 === 0
          ? (check = check && new Date(sessionDate).getDate() % 2 === 0)
          : (check = check && new Date(sessionDate).getDate() % 2 !== 0);
        if (!check) {
          if (needIncorrectEvents) {
            incorrectEvents.push(session);
          }
          return;
        }
      } else if (showEvenDates && !showOddDates) {
        check = check && new Date(sessionDate).getDate() % 2 === 0;
        if (!check) {
          if (needIncorrectEvents) {
            incorrectEvents.push(session);
          }
          return;
        }
      } else if (!showEvenDates && showOddDates) {
        check = check && new Date(sessionDate).getDate() % 2 !== 0;
        if (!check) {
          if (needIncorrectEvents) {
            incorrectEvents.push(session);
          }
          return;
        }
      }
    }

    if (targetCabinets) {
      check = check && targetCabinetsIds.includes(~~session._def.resourceIds[0]);
      if (!check) {
        if (needIncorrectEvents) {
          incorrectEvents.push(session);
        }
        return;
      }
    }

    if (targetDayOfWeek) {
      check = check && targetDayOfWeek === session._instance.range.start.getDay();
      if (!check) {
        if (needIncorrectEvents) {
          incorrectEvents.push(session);
        }
        return;
      }
    }

    if (targetStafferID) {
      check = check && targetStafferID === session._def.extendedProps.stafferId;
      if (!check) {
        if (needIncorrectEvents) {
          incorrectEvents.push(session);
        }
        return;
      }
    }

    correctEvents.push(session);
  });

  if (needIncorrectEvents) {
    return { correctEvents, incorrectEvents };
  } else {
    return correctEvents;
  }
};

// Сравнивание массивов объектов по id
const compareArrayObjectsById = (arr1, arr2) => {
  const arr1Ids = arr1.map((obj) => obj.id);
  const arr2Ids = arr2.map((obj) => obj.id);

  const both = arr2.filter((obj) => arr1Ids.includes(obj.id));
  const onlyArr1 = arr1.filter((obj) => !arr2Ids.includes(obj.id));
  const onlyArr2 = arr2.filter((obj) => !arr1Ids.includes(obj.id));

  return [both, onlyArr1, onlyArr2];
};

// Возвращает массив дат для подсвечивания в календаре
export const getDatesWithSessions: getDatesWithSessionsType = (datesInRange, stafferId, staffSchedulerAPI) => {
  return getSessionsDaysDates(
    getSessions(
      datesInRange,
      undefined,
      undefined,
      stafferId,
      undefined,
      undefined,
      undefined,
      staffSchedulerAPI,
      false,
    ),
  );
};

// Возвращает фильтрованный массив дат, в зависимости от "чётных" и "нечётных"
export const getFilteredDates: getFilteredDatesType = (data) => {
  const { staffSchedulerAPI } = data;

  const workdays = data.workdays || staffSchedulerAPI.getOption('resourceAreaLabelContent');
  const holidays = data.holidays || staffSchedulerAPI.getOption('resourceAreaWidthEditable');
  const showEvenDates = staffSchedulerAPI.getOption('eventSourceFailure');
  const showOddDates = staffSchedulerAPI.getOption('dayHeaderFormat');
  const datesInRange = data.datesInRange || staffSchedulerAPI.getOption('dayPopoverFormat');
  const selectedDate = staffSchedulerAPI.getOption('eventSourceFetch');

  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  let datesInRangeToFilter = datesInRange;

  if (workdays && holidays) {
    const nonWorkingDates = holidays.filter((holiday) => !holiday.is_workday).map((holiday) => holiday.date);
    const nonWorkingWeekdays = workdays
      .filter((weekday) => !weekday.is_workday)
      .map((weekday) => (weekday.id === 7 ? 0 : weekday.id));
    datesInRangeToFilter = datesInRange.filter(
      (date) =>
        !nonWorkingDates.includes(new Date(date.getTime() - timezoneOffset).toISOString().substring(0, 10)) &&
        !nonWorkingWeekdays.includes(new Date(date.getTime() - timezoneOffset).getDay()),
    );
  }

  if ((showEvenDates && showOddDates) || (!showEvenDates && !showOddDates)) {
    return datesInRangeToFilter;
  } else if (showEvenDates && !showOddDates) {
    return datesInRangeToFilter.filter((date) => date.getDate() % 2 === 0);
  } else if (!showEvenDates && showOddDates) {
    return datesInRangeToFilter.filter((date) => date.getDate() % 2 !== 0);
  }

  if (selectedDate) {
    const selectedDateFormatted = new Date(new Date(selectedDate - timezoneOffset).setHours(0, 0, 0, 0));
    if (!datesInRangeToFilter.includes(selectedDateFormatted)) {
      datesInRangeToFilter = [...datesInRangeToFilter, selectedDateFormatted];
    }
  }

  return datesInRangeToFilter;
};

// Получение блокеров для таблицы
const getBlockers = (workdays, holidays, datesToRender, resourceIds, schedulerAPI) => {
  let blockersArray = [];
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const blockerSettings = {
    display: 'block',
    resourceIds,
    editable: false,
    startEditable: false,
    durationEditable: false,
    resourceEditable: false,
  };

  const datesToRenderNumerical = datesToRender.map((date) => new Date(date).getTime());

  const uniqueActualWeekdays = [
    ...new Set(datesToRenderNumerical.map((date) => weekdaysKeys[new Date(date).getDay()])),
  ];

  const filteredWorkdays = workdays.filter(
    (workday) => workday.is_workday && uniqueActualWeekdays.includes(workday.weekday),
  );

  const holidaysInDatesToRender = holidays.filter(
    (holiday) =>
      holiday.is_workday && datesToRenderNumerical.includes(new Date(holiday.date).getTime() + timezoneOffset),
  );

  let minWorkdayStartTime;
  let maxWorkdayEndTime;
  if (datesToRenderNumerical.length === 1) {
    if (holidaysInDatesToRender.length) {
      minWorkdayStartTime = holidaysInDatesToRender.map((holiday) => holiday.time_start).sort()[0];
      maxWorkdayEndTime = holidaysInDatesToRender
        .map((holiday) => holiday.time_end)
        .sort()
        .pop();
    } else {
      minWorkdayStartTime = filteredWorkdays.map((workday) => workday.time_start).sort()[0];
      maxWorkdayEndTime = filteredWorkdays
        .map((workday) => workday.time_end)
        .sort()
        .pop();
    }
  } else {
    minWorkdayStartTime = [
      ...filteredWorkdays.map((workday) => workday.time_start),
      ...holidaysInDatesToRender.map((holiday) => holiday.time_start),
    ].sort()[0];
    maxWorkdayEndTime = [
      ...filteredWorkdays.map((workday) => workday.time_end),
      ...holidaysInDatesToRender.map((holiday) => holiday.time_end),
    ]
      .sort()
      .pop();
  }

  schedulerAPI.batchRendering(() => {
    schedulerAPI.setOption('slotMinTime', minWorkdayStartTime);
    schedulerAPI.setOption('slotMaxTime', maxWorkdayEndTime);
    schedulerAPI.setOption(
      'contentHeight',
      getTableHeight({
        slotMinTime: minWorkdayStartTime,
        slotMaxTime: maxWorkdayEndTime,
      }),
    );
  });

  datesToRenderNumerical.forEach((date) => {
    const targetDate = new Date(date - timezoneOffset);
    const dateString = targetDate.toISOString();
    const existingHoliday = holidaysInDatesToRender.find(
      (holiday) => holiday.date === dateString.substring(0, dateString.indexOf('T')),
    );

    let earlyBlockerEnd;
    let lateBlockerStart;

    if (existingHoliday) {
      lateBlockerStart = existingHoliday.time_end;
      earlyBlockerEnd = existingHoliday.time_start;
    } else {
      earlyBlockerEnd = workdays.find(
        (workday) => workday.weekday === weekdaysKeys[new Date(targetDate).getDay()],
      ).time_start;
      lateBlockerStart = workdays.find(
        (workday) => workday.weekday === weekdaysKeys[new Date(targetDate).getDay()],
      ).time_end;
    }

    if (minWorkdayStartTime !== earlyBlockerEnd) {
      blockersArray = [
        ...blockersArray,
        {
          start:
            new Date(dateString.substring(0, dateString.indexOf('T')) + `T${minWorkdayStartTime}.000Z`).getTime() +
            timezoneOffset,
          end:
            new Date(dateString.substring(0, dateString.indexOf('T')) + 'T' + earlyBlockerEnd + '.000Z').getTime() +
            timezoneOffset,
          ...blockerSettings,
        },
      ];
    }
    if (maxWorkdayEndTime !== lateBlockerStart) {
      blockersArray = [
        ...blockersArray,
        {
          end:
            new Date(dateString.substring(0, dateString.indexOf('T')) + `T${maxWorkdayEndTime}.000Z`).getTime() +
            timezoneOffset,
          start:
            new Date(dateString.substring(0, dateString.indexOf('T')) + 'T' + lateBlockerStart + '.000Z').getTime() +
            timezoneOffset,
          ...blockerSettings,
        },
      ];
    }
  });

  return blockersArray;
};

// Удаление всех блокеров из таблицы
const clearBlockersFromTable = (tableAPI) => {
  tableAPI.getEvents().forEach((event) => {
    if (event._def.ui.display === 'block') {
      event.remove();
    }
  });
};

// Уборка старых блокеров и добавление новых в таблицу
const setBlockersIntoTable = (tableAPI, workdays, holidays, datesInRange, cabinetsIds) => {
  tableAPI.batchRendering(() => {
    clearBlockersFromTable(tableAPI);
    tableAPI.addEventSource(getBlockers(workdays, holidays, datesInRange, cabinetsIds, tableAPI));
  });
};

//Функция для вычисления модели данных таблицы расписания
//Возвращает массив дат для отрисовки и сгруппированную модель
export const getSchedulerModel: getSchedulerModelType = (data) => {
  const { staffSchedulerAPI } = data;

  const workdays = staffSchedulerAPI.getOption('resourceAreaLabelContent');
  const holidays = staffSchedulerAPI.getOption('resourceAreaWidthEditable');
  const selectedDate = staffSchedulerAPI.getOption('eventSourceFetch');
  const showEvenDates = staffSchedulerAPI.getOption('eventSourceFailure');
  const showOddDates = staffSchedulerAPI.getOption('dayHeaderFormat');
  const selectedCabinets = staffSchedulerAPI.getOption('resources');

  const datesInRange = getFilteredDates({ ...data, selectedCabinets });

  setBlockersIntoTable(
    staffSchedulerAPI,
    workdays,
    holidays,
    datesInRange,
    selectedCabinets.map((cabinet) => cabinet.id),
  );

  //Получение массива с рабочими сессиями. Только в актуальном DateRange. Только в актуальных кабинетах.
  const sessions = getSessions(
    datesInRange,
    undefined,
    selectedCabinets,
    undefined,
    showEvenDates,
    showOddDates,
    undefined,
    staffSchedulerAPI,
    false,
  );

  //Получение массива с днями недели, которые есть в рассматриваемом промежутке дат
  const weekdays = [...new Set(datesInRange.map((date) => date.getDay()))];

  //Получение пустой модели в формате: {[День недели]: {} }
  const model = {};
  weekdays.forEach((weekday) => {
    model[weekday] = {};
  });

  //Наполнение модели датами в формате: {[День недели]: {[Дата_1]: '', [Дата_2]: '', [Дата_3]: '' } }
  datesInRange.forEach(
    (date) => (model[date.getDay()][date.getTime()] = `${date.getTime() === selectedDate ? 'SELECTED' : ''}`),
  );

  //Наполнение модели хэшем с сессий в формате: {[День недели]: {[Дата_1]: '19301103140180', [Дата_2]: '4173020021201530' } }
  sessions.forEach((session) => {
    if (model[session._instance.range.start.getDay()]) {
      const sessionHash = `${
        session._def.resourceIds[0]
      }${session._instance.range.start.getHours()}${session._instance.range.start.getMinutes()}${session._instance.range.end.getHours()}${session._instance.range.end.getMinutes()}`;
      model[session._instance.range.start.getDay()][new Date(session._instance.range.start).setHours(0, 0, 0, 0)] +=
        sessionHash;
    }
  });

  //Группировка дат в модели по наличию одинаковых рабочиx сессий в формате {[День недели]: [[Дата_1][Дата_2][Дата_3, Дата_4]] }
  switch (staffSchedulerAPI.view.type) {
    case viewTypes.DISPLAY_BY_DAY:
      Object.entries(model).forEach((weekday) => {
        let datesGroupedBySessions = [];
        Object.entries(weekday[1]).forEach((date) => {
          datesGroupedBySessions = [...datesGroupedBySessions, [parseInt(date[0])]];
        });
        model[weekday[0]] = datesGroupedBySessions;
      });
      break;

    case viewTypes.DISPLAY_BY_PERIOD:
      Object.entries(model).forEach((weekday) => {
        let datesGroupedBySessions = [];
        let initialDateGroup;
        if (showEvenDates && showOddDates) {
          initialDateGroup = [[], []];
          Object.entries(weekday[1]).forEach((date) =>
            new Date(parseInt(date[0])).getDate() % 2 === 0
              ? initialDateGroup[0].push(date)
              : initialDateGroup[1].push(date),
          );
        } else {
          initialDateGroup = [Object.entries(weekday[1])];
        }
        initialDateGroup.forEach((e) =>
          e.forEach((date, dateIndex) => {
            if (dateIndex === 0 || e[dateIndex - 1][1] !== e[dateIndex][1]) {
              datesGroupedBySessions = [...datesGroupedBySessions, [parseInt(date[0])]];
            } else {
              datesGroupedBySessions[datesGroupedBySessions.length - 1].push(parseInt(date[0]));
            }
          }),
        );
        model[weekday[0]] = datesGroupedBySessions;
      });
      break;
  }

  //Создание на основе модели массива дат для отрисовки в формате [1656882000000, 1656277200000, 1658091600000, 1657486800000...]
  const datesToRender = [];
  Object.entries(model).forEach((weekday) => {
    weekday[1].forEach((datesGroup) => datesToRender.push(parseInt(datesGroup[0])));
  });

  return [datesToRender.sort((a, b) => a - b), model];
};

// Получает массив сессий, маркирует их как имеющие совпадения или не имеющие их
const markMatchingSessions: markMatchingSessionsType = (sessionsGroupedByDates) => {
  const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
  for (let i = 0; i < sessionsGroupedByDates.length; i++) {
    const currentSession = sessionsGroupedByDates[i];
    const currentSessionStart = new Date(currentSession[0]._instance.range.start).getTime() + timeZoneOffset;
    const currentSessionEnd = new Date(currentSession[0]._instance.range.end).getTime() + timeZoneOffset;
    const currentSessionCabinet = currentSession[0]._def.resourceIds[0];
    for (let j = i + 1; j < sessionsGroupedByDates.length; j++) {
      const comparedSession = sessionsGroupedByDates[j];
      const comparedSessionStart = new Date(comparedSession[0]._instance.range.start).getTime() + timeZoneOffset;
      const comparedSessionEnd = new Date(comparedSession[0]._instance.range.end).getTime() + timeZoneOffset;
      const comparedSessionCabinet = comparedSession[0]._def.resourceIds[0];
      if (
        currentSessionStart < comparedSessionEnd &&
        currentSessionEnd > comparedSessionStart &&
        currentSessionCabinet !== comparedSessionCabinet
      ) {
        currentSession[1] = true;
        comparedSession[1] = true;
      }
    }
  }
};

// Получение дат с совпадениями
const getMatchingSessions: getMatchingSessionsType = (data) => {
  const { staffSchedulerAPI } = data;

  const showEvenDates = staffSchedulerAPI.getOption('eventSourceFailure');
  const showOddDates = staffSchedulerAPI.getOption('dayHeaderFormat');
  const currentStaffer = staffSchedulerAPI.getOption('aspectRatio');
  const datesInRange = staffSchedulerAPI.getOption('dayPopoverFormat');

  const stafferID = currentStaffer.id;
  const timeZoneOffset = new Date().getTimezoneOffset() * 60000;

  const sessions = getSessions(
    datesInRange,
    undefined,
    undefined,
    stafferID,
    showEvenDates,
    showOddDates,
    undefined,
    staffSchedulerAPI,
    false,
  );

  sessions.sort((a, b) => {
    if (
      new Date(a._instance.range.start).setHours(0, 0, 0, 0) + timeZoneOffset <
      new Date(b._instance.range.start).setHours(0, 0, 0, 0) + timeZoneOffset
    ) {
      return -1;
    }
    if (
      new Date(a._instance.range.start).setHours(0, 0, 0, 0) + timeZoneOffset >
      new Date(b._instance.range.start).setHours(0, 0, 0, 0) + timeZoneOffset
    ) {
      return 1;
    }
    return 0;
  });

  let sessionsGroupedByDates = [];

  sessions.forEach((session, sessionIdx) => {
    const previousSessionDate =
      sessionIdx > 0
        ? new Date(sessions[sessionIdx - 1]._instance.range.start).setHours(0, 0, 0, 0) + timeZoneOffset
        : null;
    const currentSessionDate =
      new Date(sessions[sessionIdx]._instance.range.start).setHours(0, 0, 0, 0) + timeZoneOffset;
    if (sessionIdx === 0) {
      sessionsGroupedByDates = [[[session, false]]];
    } else if (previousSessionDate === currentSessionDate) {
      sessionsGroupedByDates[sessionsGroupedByDates.length - 1] = [
        ...sessionsGroupedByDates[sessionsGroupedByDates.length - 1],
        [session, false],
      ];
      if (sessionIdx === sessions.length - 1 && sessionsGroupedByDates[sessionsGroupedByDates.length - 1].length > 1) {
        markMatchingSessions(sessionsGroupedByDates[sessionsGroupedByDates.length - 1]);
      }
    } else {
      markMatchingSessions(sessionsGroupedByDates[sessionsGroupedByDates.length - 1]);
      sessionsGroupedByDates = [...sessionsGroupedByDates, [[session, false]]];
    }
  });

  const sessionsGroupedByMatches = sessionsGroupedByDates
    .map((sessionsGroup) => {
      return sessionsGroup
        .map((session) => {
          if (session[1]) return session[0];
        })
        .filter((session) => session);
    })
    .filter((e) => e.length);

  if (showEvenDates && !showOddDates) {
    return sessionsGroupedByMatches.filter(
      (sessionsGroup) => sessionsGroup[0]._instance.range.start.getDate() % 2 === 0,
    );
  } else if (!showEvenDates && showOddDates) {
    return sessionsGroupedByMatches.filter(
      (sessionsGroup) => sessionsGroup[0]._instance.range.start.getDate() % 2 !== 0,
    );
  } else {
    return sessionsGroupedByMatches;
  }
};

// Получение групп дат с совпадениями
const getGroupedMatchingSessions: getGroupedMatchingSessionsType = (matchingSessions, showEvenDates, showOddDates) => {
  if (matchingSessions.length) {
    // Создание копии массива с группировкой групп совпадающих сессий
    // Если активны режимы "чётные" и "нечётные", массив дробится на 2 группы: чётных совпадающих, и нечётных совпадающих
    const groupedMatchingSessionsGroups =
      showEvenDates && showOddDates
        ? [
            [matchingSessions.filter((sessionsGroup) => sessionsGroup[0]._instance.range.start.getDate() % 2 === 0)],
            [matchingSessions.filter((sessionsGroup) => sessionsGroup[0]._instance.range.start.getDate() % 2 !== 0)],
          ]
        : [[matchingSessions]];
    let matchesGroupedByPeriods = [];
    // Каждый массив с группами групп совпадающих сессий
    groupedMatchingSessionsGroups.forEach((groupedMatchingSessionsGroup) => {
      const matchingSessionsGroupsGroupedByPeriod = [];
      // Каждая группа групп совпадающих сессий
      groupedMatchingSessionsGroup.forEach((matchingSessionsGroupes) => {
        // Сортировка по дню недели внутри каждой группы совпадающих сессий: ПН->ВТ->СР->ЧТ->ПТ->СБ
        matchingSessionsGroupes.sort((a, b) => {
          if (new Date(a[0]._instance.range.start).getDay() < new Date(b[0]._instance.range.start).getDay()) {
            return -1;
          }
          if (new Date(a[0]._instance.range.start).getDay() > new Date(b[0]._instance.range.start).getDay()) {
            return 1;
          }
          return 0;
        });
        // Каждая группа совпадающих сессий
        matchingSessionsGroupes.forEach((matchingSessionsGroup, matchingSessionsGroupIndex) => {
          let sessionsGroupDateRangeHash = '';
          // Каждая сессия
          matchingSessionsGroup.forEach((session) => {
            const sessionStart = new Date(session._instance.range.start.toUTCString().slice(0, -4));
            const sessionEnd = new Date(session._instance.range.end.toUTCString().slice(0, -4));
            const sessionDateRangeHash = `${sessionStart.getHours()}:${sessionStart.getMinutes()}-${sessionEnd.getHours()}:${sessionEnd.getMinutes()}`;
            sessionsGroupDateRangeHash = `${sessionsGroupDateRangeHash} ${sessionDateRangeHash}`;
          });
          // Фиксируем в результирующий массив группу с совпадающими сессиями, дату первой сессии и хеш вида "9:00-13:00 15:00-17:00"
          matchingSessionsGroupsGroupedByPeriod[matchingSessionsGroupIndex] = [
            ...matchingSessionsGroup,
            new Date(matchingSessionsGroup[0]._instance.range.start),
            sessionsGroupDateRangeHash,
          ];
          // Сравнение для группировки: если текущая группа имеет такой же день недели и хеш - текущая группа получит маркер false
          // Если хэш или день недели отличаются - текущая группа получит маркер true
          const currentSessionGroup = matchingSessionsGroupsGroupedByPeriod[matchingSessionsGroupIndex];
          const previousSessionGroup =
            matchingSessionsGroupIndex > 0
              ? matchingSessionsGroupsGroupedByPeriod[matchingSessionsGroupIndex - 1]
              : null;
          const groupingCheck = previousSessionGroup
            ? previousSessionGroup[previousSessionGroup.length - 2].getDay() ===
                currentSessionGroup[currentSessionGroup.length - 2].getDay() &&
              previousSessionGroup[previousSessionGroup.length - 1] ===
                currentSessionGroup[currentSessionGroup.length - 1]
            : null;
          if (groupingCheck) {
            matchingSessionsGroupsGroupedByPeriod[matchingSessionsGroupsGroupedByPeriod.length - 1] = [
              false,
              ...matchingSessionsGroupsGroupedByPeriod[matchingSessionsGroupsGroupedByPeriod.length - 1],
            ];
          } else {
            matchingSessionsGroupsGroupedByPeriod[matchingSessionsGroupsGroupedByPeriod.length - 1] = [
              true,
              ...matchingSessionsGroupsGroupedByPeriod[matchingSessionsGroupsGroupedByPeriod.length - 1],
            ];
          }
        });
        matchesGroupedByPeriods = [
          ...matchesGroupedByPeriods,
          ...matchingSessionsGroupsGroupedByPeriod
            .filter((sessionsGroup) => sessionsGroup[0])
            .map((sessionsGroup) => sessionsGroup.slice(0, -2).slice(1)),
        ];
      });
    });
    return matchesGroupedByPeriods.sort((a, b) => {
      if (new Date(a[0]._instance.range.start).getDay() < new Date(b[0]._instance.range.start).getDay()) {
        return -1;
      }
      if (new Date(a[0]._instance.range.start).getDay() > new Date(b[0]._instance.range.start).getDay()) {
        return 1;
      }
      return 0;
    });
  } else {
    return [];
  }
};

// Функция расчёта совпадений
export const getSessionsMatches: calculateMatchesType = (data) => {
  const { staffSchedulerAPI, dispatch } = data;

  const showEvenDates = staffSchedulerAPI.getOption('eventSourceFailure');
  const showOddDates = staffSchedulerAPI.getOption('dayHeaderFormat');

  if (staffSchedulerAPI) {
    const matchingSessions = getMatchingSessions(data);
    if (matchingSessions.length) {
      switch (staffSchedulerAPI.view.type) {
        case viewTypes.DISPLAY_BY_DAY:
          dispatch(setSessionsMatches(matchingSessions));
          break;

        case viewTypes.DISPLAY_BY_PERIOD:
          dispatch(setSessionsMatches(getGroupedMatchingSessions(matchingSessions, showEvenDates, showOddDates)));
          break;
      }
    } else {
      dispatch(setSessionsMatches([]));
    }
  }
};

// Перерасчёт модели и ширины таблицы, совпадений сессий и дат для подсветки
export const getSchedulerMutatingValues = (data) => {
  const { staffSchedulerAPI } = data;

  const showEvenDates = staffSchedulerAPI.getOption('eventSourceFailure');
  const showOddDates = staffSchedulerAPI.getOption('dayHeaderFormat');
  const selectedCabinets = staffSchedulerAPI.getOption('resources');
  const currentStaffer = staffSchedulerAPI.getOption('aspectRatio');
  const datesInRange = staffSchedulerAPI.getOption('dayPopoverFormat');

  const model = getSchedulerModel({ ...data, selectedCabinets });
  const datesWithSessions = getDatesWithSessions(datesInRange, currentStaffer.id, staffSchedulerAPI);
  const schedulerWidth = selectedCabinets.length * model[0].length * 142 + 52;
  const sessionsMatches: [number, string[]][] = (
    staffSchedulerAPI.view.type === viewTypes.DISPLAY_BY_DAY
      ? getMatchingSessions(data)
      : getGroupedMatchingSessions(getMatchingSessions(data), showEvenDates, showOddDates)
  )
    .map((sessionsGroup) => [
      new Date(sessionsGroup[0]._instance.range.start).setHours(0, 0, 0, 0),
      [...new Set(sessionsGroup.map((session) => session._def.resourceIds[0]))],
    ])
    .sort((a, b) => a[0] - b[0]);
  return { datesToRender: model[0], tableColumnModel: model[1], schedulerWidth, datesWithSessions, sessionsMatches };
};

// Прокрутка таблицы для столбца, который отображается не полностью
export const halfColumnScroll = (args) => {
  const tableElement = document.getElementById('TableWrapperToScroll');
  const clickedElement = document.querySelectorAll(
    `[data-date="${args.startStr.split('T')[0]}"][data-resource-id="${args.resource._resource.id}"]`,
  )[1];
  const tableElementBox = tableElement.getBoundingClientRect();
  const clickedElementBox = clickedElement.getBoundingClientRect();
  switch (true) {
    case tableElementBox.left > clickedElementBox.left:
      tableElement.scrollBy({ top: 0, left: clickedElementBox.left - tableElementBox.left - 72, behavior: 'smooth' });
      break;
    case tableElementBox.right < clickedElementBox.right:
      tableElement.scrollBy({ top: 0, left: clickedElementBox.right - tableElementBox.right + 72, behavior: 'smooth' });
      break;
    default:
      break;
  }

  return true;
};

// Прокрутка таблицы к определённой дате
export const scrollTableToDate: scrollTableToDateType = (dateToScroll, datesToRender, selectedCabinets) => {
  if (dateToScroll !== 0) {
    const tableElement = document.getElementById('TableWrapperToScroll');
    const positionToScroll =
      datesToRender.indexOf(dateToScroll) * selectedCabinets.length * 142 -
      tableElement.offsetWidth / 2 +
      (selectedCabinets.length * 142) / 2 +
      50;
    tableElement.scroll({ top: 0, left: positionToScroll, behavior: 'smooth' });
  }
};

// Получение строки ", чётные" или ", нечётные" исходя из настроек
export const getEvenOddString: getEvenOddStringType = (date, showEvenDates, showOddDates, mode) => {
  if (!showEvenDates && !showOddDates) {
    return '';
  } else {
    if (mode === 'single') {
      let stringArr;
      date.getDay() === 1 || date.getDay() === 2 || date.getDay() === 4
        ? (stringArr = [', чётный', ', нечётный'])
        : (stringArr = [', чётная', ', нечётная']);
      if (showEvenDates && !showOddDates) {
        return stringArr[0];
      }
      if (!showEvenDates && showOddDates) {
        return stringArr[1];
      }
      if (showEvenDates && showOddDates) {
        return date.getDate() % 2 === 0 ? stringArr[0] : stringArr[1];
      }
    } else if (mode === 'plural') {
      if (showEvenDates && !showOddDates) {
        return ', чётные';
      }
      if (!showEvenDates && showOddDates) {
        return ', нечётные';
      }
      if (showEvenDates && showOddDates) {
        return date.getDate() % 2 === 0 ? ', чётные' : ', нечётные';
      }
    } else {
      throw new Error('Такого mode не существует!');
    }
  }
};

// Получение периода дат по указанной дате и кабинету
export const getTargetPeriod: getTargetPeriodType = (tableColumnModel, date) => {
  return tableColumnModel[date.getDay()].filter((datesGroup) => datesGroup.includes(date.setHours(0, 0, 0, 0)))[0];
};

// Форматирует дату в строку "День, полный месяц, полный год, полный день недели"
const dateToDayMonthYearWeekdayString: dateToStringFuncType = (date) =>
  format(date, 'd MMMM yyyy, EEEE', { locale: russianLocale });

// Получает сообщение для окна предупреждения о совпадениях
export const getMatchesWarningWindowMessage: getMatchesWarningWindowMessageType = (
  sessionsMatches,
  showEvenDates,
  showOddDates,
  tableColumnModel,
  actualCabinetsIDs,
  staffSchedulerAPI,
) => {
  let result = '';

  switch (staffSchedulerAPI.view.type) {
    case viewTypes.DISPLAY_BY_DAY:
      switch (true) {
        case sessionsMatches.length === 1:
          result = dateToDayMonthYearWeekdayString(sessionsMatches[0][0]);
          if (showEvenDates || showOddDates) {
            result += getEvenOddString(new Date(sessionsMatches[0][0]), showEvenDates, showOddDates, 'single');
          }
          break;
        case sessionsMatches.length > 1:
          result =
            dateToDayMonthYearWeekdayString(sessionsMatches[0][0]) +
            ' - ' +
            dateToDayMonthYearWeekdayString(sessionsMatches[sessionsMatches.length - 1][0]);
          if (showEvenDates || showOddDates) {
            result += getEvenOddString(new Date(sessionsMatches[0][0]), showEvenDates, showOddDates, 'plural');
          }
          break;
      }
      break;

    case viewTypes.DISPLAY_BY_PERIOD:
      const firstSessionMatchTargetPeriod = getTargetPeriod(tableColumnModel, new Date(sessionsMatches[0][0]));
      const lastSessionMatchTargetPeriod = getTargetPeriod(
        tableColumnModel,
        new Date(sessionsMatches[sessionsMatches.length - 1][0]),
      );
      switch (true) {
        case sessionsMatches.length === 1:
          switch (true) {
            case firstSessionMatchTargetPeriod.length === 1:
              result = dateToDayMonthYearWeekdayString(firstSessionMatchTargetPeriod[0]);
              if (showEvenDates || showOddDates) {
                result += getEvenOddString(
                  new Date(firstSessionMatchTargetPeriod[0]),
                  showEvenDates,
                  showOddDates,
                  'single',
                );
              }
              break;
            case firstSessionMatchTargetPeriod.length > 1:
              result =
                dateToDayMonthYearWeekdayString(firstSessionMatchTargetPeriod[0]) +
                ' - ' +
                dateToDayMonthYearWeekdayString(
                  firstSessionMatchTargetPeriod[firstSessionMatchTargetPeriod.length - 1],
                );
              if ((!showEvenDates && showOddDates) || (showEvenDates && !showOddDates)) {
                result += getEvenOddString(
                  new Date(firstSessionMatchTargetPeriod[0]),
                  showEvenDates,
                  showOddDates,
                  'plural',
                );
              }
              break;
          }
          break;

        case sessionsMatches.length > 1:
          switch (true) {
            case firstSessionMatchTargetPeriod.length === 1:
              result =
                dateToDayMonthYearWeekdayString(sessionsMatches[0][0]) +
                ' - ' +
                dateToDayMonthYearWeekdayString(sessionsMatches[sessionsMatches.length - 1][0]);
              if (showEvenDates || showOddDates) {
                result += getEvenOddString(new Date(sessionsMatches[0][0]), showEvenDates, showOddDates, 'plural');
              }
              break;
            case firstSessionMatchTargetPeriod.length > 1:
              result =
                dateToDayMonthYearWeekdayString(sessionsMatches[0][0]) +
                ' - ' +
                dateToDayMonthYearWeekdayString(lastSessionMatchTargetPeriod[lastSessionMatchTargetPeriod.length - 1]);
              if ((!showEvenDates && showOddDates) || (showEvenDates && !showOddDates)) {
                result += getEvenOddString(new Date(sessionsMatches[0][0]), showEvenDates, showOddDates, 'plural');
              }
              break;
          }
          break;
      }
      break;
  }
  return result;
};

// Возвращает сессии, которые должны быть созданы (для создния/редактирования периодами)
const getSessionsToCreate: createSessionsInPeriodType = (
  datesInRange,
  targetPeriod,
  start,
  end,
  currentStaffer,
  resourceId,
  calcWithTimeZoneOffset = false,
  relatedSessionsIds = [],
) => {
  const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
  return targetPeriod.map((day, idx) => {
    const sessionStart = new Date(day).setHours(start.getHours(), start.getMinutes(), 0, 0);
    const sessionEnd = new Date(day).setHours(end.getHours(), end.getMinutes(), 0, 0);
    return {
      id: relatedSessionsIds[idx] || uuidv1(),
      resourceId,
      stafferId: currentStaffer.id,
      title: `${currentStaffer.last_name} ${currentStaffer.first_name[0]}. ${currentStaffer.second_name[0]}.`,
      color: currentStaffer.color,
      start: calcWithTimeZoneOffset ? sessionStart + timeZoneOffset : sessionStart,
      end: calcWithTimeZoneOffset ? sessionEnd + timeZoneOffset : sessionEnd,
    };
  });
};

// Присвоение столбцу таблицы CSS-класса hidden, если его даты нет среди дат для отрисовки
// Присвоение столбцу таблицы CSS-класса custom_highlighted / custom_highlighted_last, если он должен подсвечиваться
export const checkForHideCells: checkForHideCellsType = (args, data) => {
  const { staffSchedulerAPI } = data;

  const datesToRender = staffSchedulerAPI.getOption('eventGroupField');
  if (!datesToRender) return 'custom_hidden';

  const datesToRenderSet = staffSchedulerAPI.getOption('navLinkWeekClick');
  const selectedDate = staffSchedulerAPI.getOption('eventSourceFetch');
  const lastCabinetId = staffSchedulerAPI.getOption('handleWindowResize');
  const isWatchingMatches = staffSchedulerAPI.getOption('schedulerLicenseKey');
  const sessionsMatches = staffSchedulerAPI.getOption('longPressDelay');
  const currentSessionsMatch = staffSchedulerAPI.getOption('selectLongPressDelay');

  if (!datesToRenderSet.has(args.date.getTime())) {
    return 'custom_hidden';
  }

  if (selectedDate) {
    if (selectedDate === args.date.getTime()) {
      if (~~args.resource._resource.id === lastCabinetId) {
        return 'custom_highlighted_last';
      } else {
        return 'custom_highlighted';
      }
    }
  }

  if (isWatchingMatches && sessionsMatches.length > 0) {
    if (sessionsMatches[currentSessionsMatch][0] === args.date.getTime()) {
      if (~~args.resource._resource.id === lastCabinetId) {
        return 'custom_highlighted_last';
      } else {
        return 'custom_highlighted';
      }
    }
  }

  return '';
};

// Получение строки "Число Месяц Год" из объекта даты или числа
export const getDayMonthYearString: dateToStringFuncType = (date) =>
  format(date, 'd MMM yyyy', { locale: russianLocale });

// Получение строки с первой заглавной буквы
export const capitalize: capitalizeType = (string) => string[0].toUpperCase() + string.substring(1);

// Получение дня недели из объекта даты или числа
export const getWeekdayString: dateToStringFuncType = (date) =>
  capitalize(format(date, 'EEEE', { locale: russianLocale }));

// Переключение цвета существующих сессий для определенного сотрудника
export const sessionColorSetter: sessionColorSetterType = (data) => {
  const { currentStaffer, staffSchedulerAPI } = data;

  const allSessions = staffSchedulerAPI.getEvents();

  staffSchedulerAPI.batchRendering(() => {
    allSessions.forEach((session) => {
      if (session._def.extendedProps.stafferId === currentStaffer.id) {
        session.setProp('color', currentStaffer.color);
      }
    });
  });
};

// Перерасчёт модели таблицы и её ширины
export const getTableModelAndWidth: calcAndDispatchModelAndWidthType = (data) => {
  const { dispatch, staffSchedulerAPI } = data;

  const selectedCabinets = staffSchedulerAPI.getOption('resources');

  const model = getSchedulerModel(data);
  const width = selectedCabinets.length * model[0].length * 142 + 52;

  dispatch(setDatesToRender(model[0]));
  dispatch(setTableColumnModel(model[1]));
  dispatch(setSchedulerWidth(width));
};

// Переключение режима отображения
export const viewSwitchHandler: viewSwitchHandlerType = (newView, data) => {
  const { staffSchedulerAPI } = data;

  // Проверка: если происходит переключение на тот же самый режим, никаких действий не нужно
  if (newView !== staffSchedulerAPI.view.type) {
    staffSchedulerAPI.changeView(newView);
    // getSessionsMatches(data);
  }
};

// Когда сессии изменяются любым образом: передаём данные о датах с сессиями сотрудника в календарь, для подсветки дат
export const getDatesToHighlight: sessionsSetType = (data) => {
  const { staffSchedulerAPI, dispatch } = data;

  const currentStaffer = staffSchedulerAPI.getOption('aspectRatio');
  const datesInRange = staffSchedulerAPI.getOption('dayPopoverFormat');

  const datesWithSessions = getDatesWithSessions(datesInRange, currentStaffer.id, staffSchedulerAPI);

  // Перерасчёт дат для подсветки в календаре
  dispatch(setDatesWithSessions(datesWithSessions));
};

// Создание сессии при выделении области
export const selectionHandler: selectionHandlerType = (args, data) => {
  const { staffSchedulerAPI, dispatch } = data;

  const tableColumnModel = staffSchedulerAPI.getOption('header');
  const currentStaffer = staffSchedulerAPI.getOption('aspectRatio');
  const datesInRange = staffSchedulerAPI.getOption('dayPopoverFormat');

  // Событие создаётся в режиме отображения "по дням"
  switch (args.view.type) {
    case viewTypes.DISPLAY_BY_DAY:
      const sessionToBeCreated = {
        id: uuidv1(),
        resourceId: args.resource._resource.id,
        stafferId: currentStaffer.id,
        title: `${currentStaffer.last_name} ${currentStaffer.first_name[0]}. ${currentStaffer.second_name[0]}.`,
        color: currentStaffer.color,
        start: args.start,
        end: args.end,
      };

      staffSchedulerAPI.addEvent(sessionToBeCreated);
      dispatch(setSessionsToCreate([sessionToBeCreated]));
      break;
    // Событие создаётся в режиме отображения "по периодам"
    case viewTypes.DISPLAY_BY_PERIOD:
      const sessionsToBeCreated = getSessionsToCreate(
        datesInRange,
        getTargetPeriod(tableColumnModel, new Date(args.start)),
        args.start,
        args.end,
        currentStaffer,
        args.resource._resource.id,
        false,
      );
      staffSchedulerAPI.addEventSource(sessionsToBeCreated);
      dispatch(setSessionsToCreate(sessionsToBeCreated));
      break;
  }
  // getSessionsMatches(data);
  // getDatesToHighlight(data);
  staffSchedulerAPI.unselect();
};

// Перетаскивание сессии: при перетаскивании периода нужно проверять, не выпадает ли сессия из DateRange. Также нужно проверить, что все даты являются четными/нечетными согласно их новому периоду
export const sessionDropHandler: sessionDropHandlerType = (args, data, staffers) => {
  const { staffSchedulerAPI, dispatch } = data;

  const tableColumnModel = staffSchedulerAPI.getOption('header');
  const showEvenDates = staffSchedulerAPI.getOption('eventSourceFailure');
  const showOddDates = staffSchedulerAPI.getOption('dayHeaderFormat');
  const selectedCabinets = staffSchedulerAPI.getOption('resources');
  const datesInRange = staffSchedulerAPI.getOption('dayPopoverFormat');

  // Если перемещение производится в режиме "по периодам", нужно удалить старые сессии из прошлого периода, создать новые сессии в актуальном периоде
  const timeZoneOffset = new Date().getTimezoneOffset() * 60000;

  if (args.view.type === viewTypes.DISPLAY_BY_PERIOD) {
    const relatedSessions = getSessions(
      datesInRange,
      args.oldEvent._instance.range.start.getDay(),
      selectedCabinets.filter((cabinet) => cabinet.id === ~~args.oldEvent._def.resourceIds[0]),
      args.event._def.extendedProps.stafferId,
      showEvenDates,
      showOddDates,
      args.oldEvent._instance.range,
      staffSchedulerAPI,
      true,
    );

    const sessionsToBeCreated = getSessionsToCreate(
      datesInRange,
      getTargetPeriod(tableColumnModel, new Date(args.event._instance.range.start)),
      args.event._instance.range.start,
      args.event._instance.range.end,
      staffers.find((staffer) => staffer.id === args.event._def.extendedProps.stafferId),
      args.event._def.resourceIds[0],
      true,
      [...relatedSessions.correctEvents.map((session) => session._def.publicId), args.event._def.publicId],
    );

    const [commonSessions, onlyOldSessions, onlyNewSessions] = compareArrayObjectsById(
      [...relatedSessions.correctEvents, args.event],
      sessionsToBeCreated,
    );

    dispatch(setSessionsToDelete(onlyOldSessions.map((oldSession) => ({ id: oldSession._def.publicId }))));
    dispatch(setSessionsToRedact(commonSessions));
    dispatch(setSessionsToCreate(onlyNewSessions));

    staffSchedulerAPI.addEventSource(sessionsToBeCreated);
    relatedSessions.correctEvents.forEach((session) => session.remove());
    args.event.remove();
  } else {
    const sessionToBeCreated = {
      id: args.event._def.publicId,
      resourceId: args.event._def.resourceIds[0],
      stafferId: args.event._def.extendedProps.stafferId,
      start: new Date(args.event._instance.range.start.getTime() + timeZoneOffset),
      end: new Date(args.event._instance.range.end.getTime() + timeZoneOffset),
    };
    dispatch(setSessionsToRedact([sessionToBeCreated]));
  }

  // getDatesToHighlight(data);

  // getSessionsMatches(data);
};

// Удаление рабочей сессии по клику на иконку мусорки
export const sessionClickHandler: sessionClickHandlerType = (args, data, appointments) => {
  const { staffSchedulerAPI, dispatch } = data;

  const showEvenDates = staffSchedulerAPI.getOption('eventSourceFailure');
  const showOddDates = staffSchedulerAPI.getOption('dayHeaderFormat');
  const currentStaffer = staffSchedulerAPI.getOption('aspectRatio');
  const datesInRange = staffSchedulerAPI.getOption('dayPopoverFormat');
  const selectedCabinets = staffSchedulerAPI.getOption('resources');

  switch (args.view.type) {
    // Событие удаляется в режиме отображения "по дням"
    case viewTypes.DISPLAY_BY_DAY:
      const offset = new Date().getTimezoneOffset() * 60000;
      const sessionsWithAppointments = appointments.find((appointment) => {
        return (
          appointment.cabinet === ~~args.event._def.resourceIds[0] &&
          areIntervalsOverlapping(
            {
              start: new Date(appointment.starts_at),
              end: new Date(appointment.ends_at),
            },
            {
              start: new Date(new Date(args.event._instance.range.start).getTime() + offset),
              end: new Date(new Date(args.event._instance.range.end).getTime() + offset),
            },
          )
        );
      });
      if (sessionsWithAppointments) {
        dispatch(setSessionWithTooltipId(~~args.event._def.publicId));
      } else {
        const sessionToBeDeleted = {
          id: args.event._def.publicId,
        };
        dispatch(setSessionsToDelete([sessionToBeDeleted]));
        args.event.remove();
      }
      break;

    // Событие удаляется в режиме отображения "по периодам"
    case viewTypes.DISPLAY_BY_PERIOD:
      const relatedSessions = getSessions(
        datesInRange,
        args.event._instance.range.start.getDay(),
        selectedCabinets.filter((cabinet) => cabinet.id === ~~args.event._def.resourceIds[0]),
        currentStaffer.id,
        showEvenDates,
        showOddDates,
        args.event._instance.range,
        staffSchedulerAPI,
        false,
      );
      const sessionsToBeDeleted = relatedSessions.map((session) => {
        return { id: session._def.publicId };
      });
      dispatch(setSessionsToDelete(sessionsToBeDeleted));
      relatedSessions.forEach((session) => session.remove());

      break;
  }

  // getDatesToHighlight(data);
  // getSessionsMatches(data);
};

// Если происходит ресайз сессии в режиме "по периодам", то изменяется начало и конец всех рабочих сессий периода
export const sessionResizeHandler: sessionResizeHandlerType = (args, data) => {
  const { staffSchedulerAPI, dispatch } = data;

  const showEvenDates = staffSchedulerAPI.getOption('eventSourceFailure');
  const showOddDates = staffSchedulerAPI.getOption('dayHeaderFormat');
  const datesInRange = staffSchedulerAPI.getOption('dayPopoverFormat');
  const selectedCabinets = staffSchedulerAPI.getOption('resources');

  const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
  if (args.view.type === viewTypes.DISPLAY_BY_PERIOD) {
    const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
    const relatedSessions = getSessions(
      datesInRange,
      args.event._instance.range.start.getDay(),
      selectedCabinets.filter((cabinet) => cabinet.id === ~~args.event._def.resourceIds[0]),
      args.event._def.extendedProps.stafferId,
      showEvenDates,
      showOddDates,
      args.oldEvent._instance.range,
      staffSchedulerAPI,
      false,
    );
    if (args.startDelta.milliseconds) {
      relatedSessions.forEach((session) =>
        session.setStart(
          new Date(session._instance.range.start).getTime() + args.startDelta.milliseconds + timeZoneOffset,
        ),
      );
    }
    if (args.endDelta.milliseconds) {
      relatedSessions.forEach((session) =>
        session.setEnd(new Date(session._instance.range.end).getTime() + args.endDelta.milliseconds + timeZoneOffset),
      );
    }
    const sessionsToBeRedacted = [args.event, ...relatedSessions].map((session) => {
      return {
        id: session._def.publicId,
        resourceId: session._def.resourceIds[0],
        stafferId: session._def.extendedProps.stafferId,
        start: new Date(session._instance.range.start.getTime() + timeZoneOffset),
        end: new Date(session._instance.range.end.getTime() + timeZoneOffset),
      };
    });
    dispatch(setSessionsToRedact(sessionsToBeRedacted));
  } else {
    dispatch(
      setSessionsToRedact([
        {
          id: args.event._def.publicId,
          resourceId: args.event._def.resourceIds[0],
          stafferId: args.event._def.extendedProps.stafferId,
          start: new Date(args.event._instance.range.start.getTime() + timeZoneOffset),
          end: new Date(args.event._instance.range.end.getTime() + timeZoneOffset),
        },
      ]),
    );
  }
  // getSessionsMatches(data);
};

export const checkHoliday = (date, nonWorkingWeekdays = undefined, nonWorkingDates = undefined, timeZoneOffset = 0) => {
  const dateWithOffset = new Date(date - timeZoneOffset);
  return (
    nonWorkingWeekdays?.includes(dateWithOffset.getDay()) ||
    nonWorkingDates?.includes(dateWithOffset.toISOString().slice(0, 10))
  );
};

const logFalsyData = (data, funcName) => {
  const falsyInitializationTableDataKeys = [];
  Object.keys(data).forEach((key) => {
    if (data[key] === null || data[key] === undefined) {
      falsyInitializationTableDataKeys.push(key);
    }
  });
  if (falsyInitializationTableDataKeys.length) {
    console.info(
      `В инициализацию данных для таблицы (${funcName}) попали пустые значения: ${falsyInitializationTableDataKeys.join(
        ', ',
      )}`,
    );
  }
};

const mapScheduleDataToSessions = (schedule, staffers, cabinetsDictionary, areSessionsBackgrounded = false) => {
  const cabinetsIDs = new Set(cabinetsDictionary.map((cabinet) => cabinet.id));

  const stafferLookup = {};
  staffers.forEach((staffer) => {
    stafferLookup[staffer.id] = staffer;
  });

  const getStafferTitle = (staffer) => `${staffer.last_name} ${staffer.first_name[0]}. ${staffer.second_name[0]}.`;

  return schedule
    .filter((scheduleItem) => cabinetsIDs.has(scheduleItem.cabinet))
    .map((scheduleItem) => ({
      id: scheduleItem.id,
      resourceId: scheduleItem.cabinet,
      stafferId: scheduleItem.worker,
      title: getStafferTitle(stafferLookup[scheduleItem.worker]),
      color: stafferLookup[scheduleItem.worker].color || '#DEF0FB',
      start: scheduleItem.starts_at,
      end: scheduleItem.ends_at,
      ...(areSessionsBackgrounded && { display: 'background' }),
    }));
};

// Получает сессии табличного формата
export const getTableSessions = (sessions, staffers, cabinets) => {
  const sessionsToSet = mapScheduleDataToSessions(sessions, staffers, cabinets);
  return sessionsToSet;
};

// Инициализация кабинетов: выясняем, на каких кабинетах есть сессии сотрудника, передаём эти кабинеты в таблицу
const getTableCabinets = (sessions, cabinetsDictionary) => {
  const uniqueCabinetsIds = [...new Set(sessions.map((session) => session.resourceId))];
  const uniqueCabinets = cabinetsDictionary.filter((cabinet) =>
    uniqueCabinetsIds.find((cabinetId) => cabinetId === cabinet.id),
  );

  return uniqueCabinets;
};

// Получает массив сессий, возвращает начало и конец диапазона (или null, если сессий нет или не получилось вычислить диапазон)
const getTableDateRange = (sessions, schedulerAPI) => {
  // Если есть сессии - получим уникальный отсортированный список дат, в котором они есть
  if (sessions && sessions.length) {
    const sortedUniqueDates = [
      ...new Set(sessions.map((session) => new Date(session.start).setHours(0, 0, 0, 0))),
    ].sort((a, b) => a - b);

    // Началом диапазона отображения будет первая дата с сессией, которая >= сегодня (в прошлое мы не смотрим)
    const dateRangeStart = sortedUniqueDates.find((date) => date >= new Date().setHours(0, 0, 0, 0));
    const dateRangeEnd = sortedUniqueDates[sortedUniqueDates.length - 1];

    // Если есть начало диапазона - он корректен, передаём таблице данные о нём
    if (dateRangeStart) {
      const dateRangeToSet = [new Date(dateRangeStart), new Date(dateRangeEnd)];
      return dateRangeToSet;
    }
  }

  return null;
};

const calcSessionsMatches = (data) => {
  const { staffSchedulerAPI } = data;

  const showEvenDates = staffSchedulerAPI.getOption('eventSourceFailure');
  const showOddDates = staffSchedulerAPI.getOption('dayHeaderFormat');

  const matchingSessions = getMatchingSessions(data);
  const sessionsMatches = (
    staffSchedulerAPI.view.type === viewTypes.DISPLAY_BY_DAY
      ? matchingSessions
      : getGroupedMatchingSessions(matchingSessions, showEvenDates, showOddDates)
  )
    .map((sessionsGroup) => [
      new Date(sessionsGroup[0]._instance.range.start).setHours(0, 0, 0, 0),
      [...new Set(sessionsGroup.map((session) => session._def.resourceIds[0]))],
    ])
    .sort((a, b) => a[0] - b[0]);

  return sessionsMatches;
};

// Возвращает минимальные и максимальные часы для таблицы
const getSlotMinMaxTime = (workdays, holidays, datesInRange, selectedDate) => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const cuttedDateRange = datesInRange.length > 1 ? datesInRange.slice(0, datesInRange.length - 1) : datesInRange;

  const datesToCalculateBlockersFrom =
    !selectedDate || datesInRange.includes(selectedDate)
      ? cuttedDateRange
      : [...cuttedDateRange, selectedDate].sort((a, b) => a - b);

  const uniqueActualWeekdays = [
    ...new Set(datesToCalculateBlockersFrom.map((date) => weekdaysKeys[new Date(date).getDay()])),
  ];

  const filteredWorkdays = workdays.filter((workday) => uniqueActualWeekdays.includes(workday.weekday));

  const holidaysInDatesToRender = holidays.filter((holiday) =>
    datesToCalculateBlockersFrom.includes(new Date(holiday.date).getTime() + timezoneOffset),
  );

  const minWorkdayStartTime = [
    ...filteredWorkdays.filter((workday) => workday.is_workday).map((workday) => workday.time_start),
    ...holidaysInDatesToRender.filter((holiday) => holiday.is_workday).map((holiday) => holiday.time_start),
  ].sort()[0];

  const maxWorkdayEndTime = [
    ...filteredWorkdays.filter((workday) => workday.is_workday).map((workday) => workday.time_end),
    ...holidaysInDatesToRender.filter((holiday) => holiday.is_workday).map((holiday) => holiday.time_end),
  ]
    .sort()
    .pop();

  return { slotMinTime: minWorkdayStartTime, slotMaxTime: maxWorkdayEndTime };
};

const setMinMaxSlotTime = (datesInRange, selectedDate, workdays, holidays, schedulerAPI) => {
  const { slotMinTime, slotMaxTime } = getSlotMinMaxTime(workdays, holidays, datesInRange, selectedDate);

  schedulerAPI.setOption('slotMinTime', slotMinTime);
  schedulerAPI.setOption('slotMaxTime', slotMaxTime);
  return { slotMinTime, slotMaxTime };
};

// Получение количества видимых колонок таблицы. Завёрнуто в промис, чтобы подхватывать элементы с задержкой. Иначе они не найдутся.
// const getShownColumnsCount = () =>
//   Promise.resolve().then(() => {
//     try {
//       const tableElement = document.getElementById('TableWrapperToScroll');
//       const rowElement = tableElement.querySelectorAll("tr[role='row']")[1];
//       const shownColumnsCount = rowElement.getElementsByClassName('custom_shown').length;

//       return shownColumnsCount;
//     } catch {
//       console.error(
//         'Не удалось получить контейнер колонок для подсчёта количества показываемых колонок (getShownColumnsCount)',
//       );
//     }
//   });

// Получение модели колонок таблицы
const getTableColumnModel = (data) => {
  const { staffSchedulerAPI } = data;

  const showEvenDates = staffSchedulerAPI.getOption('eventSourceFailure');
  const showOddDates = staffSchedulerAPI.getOption('dayHeaderFormat');
  const selectedCabinets = data.selectedCabinets || staffSchedulerAPI.getOption('resources');
  const selectedDate = staffSchedulerAPI.getOption('eventSourceFetch');

  const datesInRange = getFilteredDates(data);

  //Получение массива с рабочими сессиями. Только в актуальном DateRange. Только в актуальных кабинетах.
  const sessions = getSessions(
    datesInRange,
    undefined,
    selectedCabinets,
    undefined,
    showEvenDates,
    showOddDates,
    undefined,
    staffSchedulerAPI,
    false,
  );

  //Получение массива с днями недели, которые есть в рассматриваемом промежутке дат
  const weekdays = [...new Set(datesInRange.map((date) => date.getDay()))];

  //Получение пустой модели в формате: {[День недели]: {} }
  const model = {};
  weekdays.forEach((weekday) => {
    model[weekday] = {};
  });

  //Наполнение модели датами в формате: {[День недели]: {[Дата_1]: '', [Дата_2]: '', [Дата_3]: '' } }
  datesInRange.forEach(
    (date) => (model[date.getDay()][date.getTime()] = `${date.getTime() === selectedDate ? 'SELECTED' : ''}`),
  );

  //Наполнение модели хэшем с сессий в формате: {[День недели]: {[Дата_1]: '19301103140180', [Дата_2]: '4173020021201530' } }
  sessions.forEach((session) => {
    if (model[session._instance.range.start.getDay()]) {
      const sessionHash = `${
        session._def.resourceIds[0]
      }${session._instance.range.start.getHours()}${session._instance.range.start.getMinutes()}${session._instance.range.end.getHours()}${session._instance.range.end.getMinutes()}`;
      model[session._instance.range.start.getDay()][new Date(session._instance.range.start).setHours(0, 0, 0, 0)] +=
        sessionHash;
    }
  });

  //Группировка дат в модели по наличию одинаковых рабочиx сессий в формате {[День недели]: [[Дата_1][Дата_2][Дата_3, Дата_4]] }
  switch (staffSchedulerAPI.view.type) {
    case viewTypes.DISPLAY_BY_DAY:
      Object.entries(model).forEach((weekday) => {
        let datesGroupedBySessions = [];
        Object.entries(weekday[1]).forEach((date) => {
          datesGroupedBySessions = [...datesGroupedBySessions, [parseInt(date[0])]];
        });
        model[weekday[0]] = datesGroupedBySessions;
      });
      break;

    case viewTypes.DISPLAY_BY_PERIOD:
      Object.entries(model).forEach((weekday) => {
        let datesGroupedBySessions = [];
        let initialDateGroup;
        if (showEvenDates && showOddDates) {
          initialDateGroup = [[], []];
          Object.entries(weekday[1]).forEach((date) =>
            new Date(parseInt(date[0])).getDate() % 2 === 0
              ? initialDateGroup[0].push(date)
              : initialDateGroup[1].push(date),
          );
        } else {
          initialDateGroup = [Object.entries(weekday[1])];
        }
        initialDateGroup.forEach((e) =>
          e.forEach((date, dateIndex) => {
            if (dateIndex === 0 || e[dateIndex - 1][1] !== e[dateIndex][1]) {
              datesGroupedBySessions = [...datesGroupedBySessions, [parseInt(date[0])]];
            } else {
              datesGroupedBySessions[datesGroupedBySessions.length - 1].push(parseInt(date[0]));
            }
          }),
        );
        model[weekday[0]] = datesGroupedBySessions;
      });
      break;
  }

  return model;
};

// Получение на основе модели таблицы массива дат для отрисовки в формате [1656882000000, 1656277200000, 1658091600000, 1657486800000...]
const getDatesToRender = (tableColumnModel) => {
  const datesToRender = [];
  Object.entries(tableColumnModel).forEach((weekday) => {
    weekday[1].forEach((datesGroup) => datesToRender.push(parseInt(datesGroup[0])));
  });
  return datesToRender.sort((a, b) => a - b);
};

// Получение блокеров
const getBlockersV2 = (workdays, holidays, filterCabinets, datesToRender) => {
  const resourceIds = filterCabinets.map((cabinet) => cabinet.id);

  let blockersArray = [];
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const blockerSettings = {
    display: 'block',
    resourceIds,
    editable: false,
    startEditable: false,
    durationEditable: false,
    resourceEditable: false,
  };

  const datesToRenderNumerical = datesToRender.map((date) => new Date(date).getTime());

  const uniqueActualWeekdays = [
    ...new Set(datesToRenderNumerical.map((date) => weekdaysKeys[new Date(date).getDay()])),
  ];

  const filteredWorkdays = workdays.filter(
    (workday) => workday.is_workday && uniqueActualWeekdays.includes(workday.weekday),
  );

  const holidaysInDatesToRender = holidays.filter(
    (holiday) =>
      holiday.is_workday && datesToRenderNumerical.includes(new Date(holiday.date).getTime() + timezoneOffset),
  );

  let minWorkdayStartTime;
  let maxWorkdayEndTime;
  if (datesToRenderNumerical.length === 1) {
    if (holidaysInDatesToRender.length) {
      minWorkdayStartTime = holidaysInDatesToRender.map((holiday) => holiday.time_start).sort()[0];
      maxWorkdayEndTime = holidaysInDatesToRender
        .map((holiday) => holiday.time_end)
        .sort()
        .pop();
    } else {
      minWorkdayStartTime = filteredWorkdays.map((workday) => workday.time_start).sort()[0];
      maxWorkdayEndTime = filteredWorkdays
        .map((workday) => workday.time_end)
        .sort()
        .pop();
    }
  } else {
    minWorkdayStartTime = [
      ...filteredWorkdays.map((workday) => workday.time_start),
      ...holidaysInDatesToRender.map((holiday) => holiday.time_start),
    ].sort()[0];
    maxWorkdayEndTime = [
      ...filteredWorkdays.map((workday) => workday.time_end),
      ...holidaysInDatesToRender.map((holiday) => holiday.time_end),
    ]
      .sort()
      .pop();
  }

  datesToRenderNumerical.forEach((date) => {
    const targetDate = new Date(date - timezoneOffset);
    const dateString = targetDate.toISOString();
    const existingHoliday = holidaysInDatesToRender.find(
      (holiday) => holiday.date === dateString.substring(0, dateString.indexOf('T')),
    );

    let earlyBlockerEnd;
    let lateBlockerStart;

    if (existingHoliday) {
      lateBlockerStart = existingHoliday.time_end;
      earlyBlockerEnd = existingHoliday.time_start;
    } else {
      earlyBlockerEnd = workdays.find(
        (workday) => workday.weekday === weekdaysKeys[new Date(targetDate).getDay()],
      ).time_start;
      lateBlockerStart = workdays.find(
        (workday) => workday.weekday === weekdaysKeys[new Date(targetDate).getDay()],
      ).time_end;
    }

    if (minWorkdayStartTime !== earlyBlockerEnd) {
      blockersArray = [
        ...blockersArray,
        {
          start:
            new Date(dateString.substring(0, dateString.indexOf('T')) + `T${minWorkdayStartTime}.000Z`).getTime() +
            timezoneOffset,
          end:
            new Date(dateString.substring(0, dateString.indexOf('T')) + 'T' + earlyBlockerEnd + '.000Z').getTime() +
            timezoneOffset,
          ...blockerSettings,
        },
      ];
    }
    if (maxWorkdayEndTime !== lateBlockerStart) {
      blockersArray = [
        ...blockersArray,
        {
          end:
            new Date(dateString.substring(0, dateString.indexOf('T')) + `T${maxWorkdayEndTime}.000Z`).getTime() +
            timezoneOffset,
          start:
            new Date(dateString.substring(0, dateString.indexOf('T')) + 'T' + lateBlockerStart + '.000Z').getTime() +
            timezoneOffset,
          ...blockerSettings,
        },
      ];
    }
  });

  return blockersArray;
};

const getLastCabinet = (cabinets) => {
  return cabinets.length === 0
    ? 0
    : cabinets.reduce((maxIDcabinet, cabinet) => (maxIDcabinet.id > cabinet.id ? maxIDcabinet : cabinet)).id;
};

const applyStylesToSelectedColumns = (selectedDate) => {
  const tableContainer = document.getElementById('FCWidthWrapper');

  if (!tableContainer) return;

  const selectedDateFormatted = format(selectedDate - TIMEZONE_OFFSET, 'yyyy-MM-dd');

  const applyColumnStyles = (element) => {
    const selectedColumns = element.querySelectorAll(`[data-date="${selectedDateFormatted}"]`);
    selectedColumns.forEach((column, index) => {
      column.classList.add(index === selectedColumns.length - 1 ? 'custom_highlighted_last' : 'custom_highlighted');
    });
  };

  const removeColumnStyles = (element) => {
    const columnsToPurge = element.querySelectorAll('.custom_highlighted, .custom_highlighted_last');
    columnsToPurge.forEach((column) => column.classList.remove('custom_highlighted', 'custom_highlighted_last'));
  };

  const tableHeader = tableContainer.querySelector(TABLE_HEADER_SELECTOR);
  const tableBody = tableContainer.querySelector(TABLE_BODY_SELECTOR);

  if (selectedDate) {
    removeColumnStyles(tableHeader);
    removeColumnStyles(tableBody);
    applyColumnStyles(tableHeader);
    applyColumnStyles(tableBody);
  } else {
    removeColumnStyles(tableHeader);
    removeColumnStyles(tableBody);
  }
};

const applyStylesToColumns = (datesToRender) => {
  const datesSet = new Set(datesToRender.map((date) => format(date - TIMEZONE_OFFSET, 'yyyy-MM-dd')));
  const tableContainer = document.getElementById('FCWidthWrapper');

  const applyStyles = (element) => {
    Array.from(element.children)
      .slice(1)
      .forEach((column) => {
        if (datesSet.has(column.getAttribute('data-date'))) {
          column.classList.remove('custom_hidden');
        } else {
          column.classList.add('custom_hidden');
        }
      });
  };

  if (tableContainer) {
    const tableHeader = tableContainer.querySelector(TABLE_HEADER_SELECTOR);
    const tableBody = tableContainer.querySelector(TABLE_BODY_SELECTOR);

    if (tableHeader) applyStyles(tableHeader);
    if (tableBody) applyStyles(tableBody);
  }
};

export const checkForAnyRelevantSession = (schedule, cabinetsDictionary, currentStafferId) => {
  const relevantCabinetsIDs = cabinetsDictionary.filter((cabinet) => cabinet.is_medical).map((cabinet) => cabinet.id);

  const relevantCurrentStafferSession = schedule.find((session) => {
    const check =
      new Date(session.starts_at).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) &&
      session.worker === currentStafferId &&
      relevantCabinetsIDs.includes(session.cabinet);
    return check;
  });

  return Boolean(relevantCurrentStafferSession);
};

const getDatesWithSessionsV2 = (sessions, workdays) => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const nonWorkingDaysWeekdayNumbers = new Set(workdays.filter((item) => !item.is_workday).map((item) => item.id % 7));

  const datesWithSessions = sessions
    .map((session) => {
      const dateWithOffsetInMS = new Date(session.start).getTime() + timezoneOffset;
      return new Date(dateWithOffsetInMS).setHours(0, 0, 0, 0);
    })
    .filter((timestamp) => {
      const dayOfWeek = new Date(timestamp).getDay();
      return !nonWorkingDaysWeekdayNumbers.has(dayOfWeek);
    });

  return datesWithSessions;
};

// Получает и передаёт все необходимые данные в таблицу
export const handleInitializeTableData = (data) => {
  const {
    filterCabinets,
    cabinetsDictionary,
    staffers,
    currentStaffer,
    schedule,
    appointments,
    workdays,
    holidays,
    selectedDate,
    showEvenDates,
    showOddDates,
    isWatchingMatches,
    currentSessionsMatch,
    dispatch,
    dateRange,
    datesInRange,
    staffSchedulerAPI,
  } = data;

  // Вывод пустых значений в консоль. Очень пригодится чтобы понимать, с какими данными при инициализации есть проблемы
  logFalsyData(data, 'handleInitializeTableData');

  // Обёртка для батчинга всех изменений (чтобы они разом заходили в таблицу)
  staffSchedulerAPI.batchRendering(() => {
    // tableDisplayMode
    const tableDisplayModeToSet = schedule
      .filter((obj) => new Date(obj.starts_at).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0))
      .find((session) => session.worker === currentStaffer.id)
      ? 'displayByPeriod'
      : 'displayByDay';

    dispatch(setTableDisplayMode(tableDisplayModeToSet));

    // currentSessionsMatch
    staffSchedulerAPI.setOption('selectLongPressDelay', currentSessionsMatch);

    // workdays
    dispatch(setWorkdays(workdays));

    // holidays
    dispatch(setHolidays(holidays));

    // showEvenDates
    staffSchedulerAPI.setOption('eventSourceFailure', showEvenDates);

    // showOddDates
    staffSchedulerAPI.setOption('dayHeaderFormat', showOddDates);

    // isWatchingMatches
    staffSchedulerAPI.setOption('schedulerLicenseKey', isWatchingMatches);

    // selectedDate
    dispatch(setSelectedDate(selectedDate));

    // allCabinets
    const relevantCabinetsDictionary = cabinetsDictionary.filter((cabinet) => cabinet.is_medical);
    staffSchedulerAPI.setOption('viewSkeletonRender', relevantCabinetsDictionary);

    // sessions
    const sessionsToSet = getTableSessions(schedule, staffers, relevantCabinetsDictionary);
    staffSchedulerAPI.addEventSource(sessionsToSet);

    const relevantCurrentStafferSessions = sessionsToSet
      .filter((session) => new Date(session.start).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0))
      .filter((session) => session.stafferId === currentStaffer.id);

    // currentStaffer
    staffSchedulerAPI.setOption('aspectRatio', currentStaffer);

    const relevantSessionsExist = Boolean(relevantCurrentStafferSessions.length);

    // dateRange + datesInRange
    const dateRangeToSet = relevantSessionsExist
      ? getTableDateRange(relevantCurrentStafferSessions, staffSchedulerAPI)
      : dateRange;

    const datesInRangeToSet = getFilteredDates({
      ...data,
      datesInRange: eachDayOfInterval({ start: dateRangeToSet[0], end: dateRangeToSet[1] }),
    });

    // selectedCabinets + lastCabinetId
    const selectedCabinetsToSet = relevantSessionsExist
      ? getTableCabinets(relevantCurrentStafferSessions, relevantCabinetsDictionary)
      : filterCabinets;

    // Если у текущего юзера есть сессии сегодня и дальше - надо дать таблице вычисленные диапазон и кабинеты
    if (relevantSessionsExist) {
      dispatch(setDateRangeV2(dateRangeToSet));
      dispatch(setSelectedCabinetsV2(selectedCabinetsToSet));
    }

    // tableColumnModel
    const tableColumnModel = getTableColumnModel({
      ...data,
      datesInRange: datesInRangeToSet,
      selectedCabinets: filterCabinets,
    });
    dispatch(setTableColumnModel(tableColumnModel));

    // datesToRender
    const datesToRender = getDatesToRender(tableColumnModel);
    dispatch(setDatesToRender(datesToRender));

    // Контроль отрисовки колонок таблицы стилями
    applyStylesToColumns(datesToRender);

    // schedulerWidth
    const schedulerWidth = selectedCabinetsToSet.length * datesToRender.length * 142 + 52;

    dispatch(setSchedulerWidth(schedulerWidth));

    // sessionsMatches
    const sessionsMatches = calcSessionsMatches({ ...data, currentStaffer, datesInRange: datesInRangeToSet });
    dispatch(setSessionsMatches(sessionsMatches));

    // Максимальное и минимальное рабочее время
    const { slotMinTime, slotMaxTime } = setMinMaxSlotTime(
      datesInRangeToSet,
      selectedDate,
      workdays,
      holidays,
      staffSchedulerAPI,
    );

    // Блокеры таблицы
    const blockers = getBlockersV2(workdays, holidays, filterCabinets, datesToRender);
    staffSchedulerAPI.addEventSource(blockers);

    // Высота таблицы
    const tableHeight = getTableHeight({ slotMinTime, slotMaxTime });
    staffSchedulerAPI.setOption('contentHeight', tableHeight);

    // datesWithSessions
    const datesWithSessions = getDatesWithSessionsV2(relevantCurrentStafferSessions, workdays);
    dispatch(setDatesWithSessions(datesWithSessions));
  });
};

export const handleDateRangeChange = (data) => {
  const {
    showEvenDates,
    showOddDates,
    selectedDate,
    filterCabinets,
    cabinetsDictionary,
    schedule,
    staffers,
    currentStaffer,
    dateRange,
    datesInRange,
    workdays,
    dispatch,
    staffSchedulerAPI,
  } = data;

  // Вывод пустых значений в консоль. Очень пригодится чтобы понимать, с какими данными при инициализации есть проблемы
  logFalsyData(data, 'handleDateRangeChange');

  // tableColumnModel
  const tableColumnModel = getTableColumnModel({ ...data, selectedCabinets: filterCabinets });
  dispatch(setTableColumnModel(tableColumnModel));

  // datesToRender
  const datesToRender = getDatesToRender(tableColumnModel);
  dispatch(setDatesToRender(datesToRender));

  // Контроль отрисовки колонок таблицы стилями
  applyStylesToColumns(datesToRender);

  // schedulerWidth
  const schedulerWidth = filterCabinets.length * datesToRender.length * 142 + 52;

  dispatch(setSchedulerWidth(schedulerWidth));

  // datesWithSessions
  const relevantCabinetsDictionary = cabinetsDictionary.filter((cabinet) => cabinet.is_medical);
  const tableSessions = getTableSessions(schedule, staffers, relevantCabinetsDictionary);

  const relevantTableSessions = tableSessions
    .filter((session) => session.stafferId === currentStaffer.id)
    .filter((session) => new Date(session.start).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));
  const datesWithSessions = getDatesWithSessionsV2(relevantTableSessions, workdays);
  dispatch(setDatesWithSessions(datesWithSessions));

  // sessionsMatches
  const sessionsMatches = calcSessionsMatches({ ...data, currentStaffer });
  dispatch(setSessionsMatches(sessionsMatches));
};

export const handleSelectedDateChange = (data) => {
  const { cabinetsDictionary, filterCabinets, schedule, staffers, workdays, currentStaffer, dispatch, selectedDate } =
    data;

  // tableColumnModel
  const tableColumnModel = getTableColumnModel({ ...data, selectedCabinets: filterCabinets });
  dispatch(setTableColumnModel(tableColumnModel));

  // datesToRender
  const datesToRender = getDatesToRender(tableColumnModel);
  dispatch(setDatesToRender(datesToRender));

  // Контроль отрисовки колонок таблицы стилями
  applyStylesToColumns(datesToRender);

  // Контроль отрисовки выбранной даты
  applyStylesToSelectedColumns(selectedDate);

  // schedulerWidth
  const schedulerWidth = filterCabinets.length * datesToRender.length * 142 + 52;

  dispatch(setSchedulerWidth(schedulerWidth));

  // datesWithSessions
  const relevantCabinetsDictionary = cabinetsDictionary.filter((cabinet) => cabinet.is_medical);
  const tableSessions = getTableSessions(schedule, staffers, relevantCabinetsDictionary);
  const relevantTableSessions = tableSessions
    .filter((session) => session.stafferId === currentStaffer.id)
    .filter((session) => new Date(session.start).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));

  const datesWithSessions = getDatesWithSessionsV2(relevantTableSessions, workdays);
  dispatch(setDatesWithSessions(datesWithSessions));

  // sessionsMatches
  const sessionsMatches = calcSessionsMatches({ ...data, currentStaffer });
  dispatch(setSessionsMatches(sessionsMatches));

  // scroll to selectedDate
  if (selectedDate) {
    scrollTableToDate(selectedDate, datesToRender, filterCabinets);
  }
};

export const handleEvenOddFiltersChange = (data) => {
  const { cabinetsDictionary, filterCabinets, schedule, staffers, workdays, currentStaffer, selectedDate, dispatch } =
    data;

  // tableColumnModel
  const tableColumnModel = getTableColumnModel({ ...data, selectedCabinets: filterCabinets });
  dispatch(setTableColumnModel(tableColumnModel));

  // datesToRender
  const datesToRender = getDatesToRender(tableColumnModel);
  dispatch(setDatesToRender(datesToRender));

  // Контроль отрисовки колонок таблицы стилями
  applyStylesToColumns(datesToRender);

  // schedulerWidth
  const schedulerWidth = filterCabinets.length * datesToRender.length * 142 + 52;

  dispatch(setSchedulerWidth(schedulerWidth));

  // datesWithSessions
  const relevantCabinetsDictionary = cabinetsDictionary.filter((cabinet) => cabinet.is_medical);
  const tableSessions = getTableSessions(schedule, staffers, relevantCabinetsDictionary);
  const relevantTableSessions = tableSessions
    .filter((session) => session.stafferId === currentStaffer.id)
    .filter((session) => new Date(session.start).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));

  const datesWithSessions = getDatesWithSessionsV2(relevantTableSessions, workdays);
  dispatch(setDatesWithSessions(datesWithSessions));

  // sessionsMatches
  const sessionsMatches = calcSessionsMatches({ ...data, currentStaffer });
  dispatch(setSessionsMatches(sessionsMatches));

  // scroll to selectedDate
  if (selectedDate) {
    setTimeout(() => scrollTableToDate(selectedDate, datesToRender, filterCabinets));
  }
};

export const handleTableDisplayModeChange = (data) => {
  const { filterCabinets, currentStaffer, dispatch } = data;

  // sessionsMatches
  const sessionsMatches = calcSessionsMatches({ ...data, currentStaffer });
  dispatch(setSessionsMatches(sessionsMatches));

  // tableColumnModel
  const tableColumnModel = getTableColumnModel({ ...data, selectedCabinets: filterCabinets });
  dispatch(setTableColumnModel(tableColumnModel));

  // datesToRender
  const datesToRender = getDatesToRender(tableColumnModel);
  dispatch(setDatesToRender(datesToRender));

  // Контроль отрисовки колонок таблицы стилями
  applyStylesToColumns(datesToRender);

  // schedulerWidth
  const schedulerWidth = filterCabinets.length * datesToRender.length * 142 + 52;

  dispatch(setSchedulerWidth(schedulerWidth));
};

export const getDatesToHighlightSet = (sessions, dispatch, staffSchedulerAPI) => {
  if (!staffSchedulerAPI) return;

  const getDateWithTimezoneOffset = (date) => {
    return new Date(date.getTime() + TIMEZONE_OFFSET);
  };

  const dateRange = staffSchedulerAPI.getOption('visibleRange');
  const selectedCabinets = staffSchedulerAPI.getOption('resources');
  const { id: stafferId } = staffSchedulerAPI.getOption('aspectRatio');

  const start = getDateWithTimezoneOffset(new Date()).setHours(0, 0, 0, 0);
  const end = getDateWithTimezoneOffset(new Date(dateRange.end)).setHours(0, 0, 0, 0);

  const cabinetsIDs = new Set(selectedCabinets.map((cabinet) => cabinet.id));
  const datesSet = new Set();

  const filteredSessions = sessions.filter((session) => {
    const eventDate = getDateWithTimezoneOffset(new Date(session._instance.range.start)).setHours(0, 0, 0, 0);
    return eventDate >= start && eventDate <= end;
  });

  filteredSessions.forEach((session) => {
    if (session._def.extendedProps.stafferId !== stafferId) return;
    if (!cabinetsIDs.has(+session._def.resourceIds[0])) return;

    const eventDate = getDateWithTimezoneOffset(new Date(session._instance.range.start)).setHours(0, 0, 0, 0);
    datesSet.add(eventDate);
  });

  dispatch(setDatesToHighlightSet(datesSet));
  staffSchedulerAPI.setOption('googleCalendarApiKey', datesSet);
};
