import React, { useState } from 'react';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import { Calendar } from './Calendar';
import { useDispatch, useSelector } from 'react-redux';
import { setField as setDoctorField } from '../../../features/selections/doctor/actions';
import { useGetAppointmentsQuery } from '../../../services/medcard';
import { compareDesc, format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { RootState } from '../../../store';

const AppointmentCard = styled.div`
  background: #ffffff;
  border: 1px solid #d3d6da;
  border-radius: 6px;
  padding: 24px;
  margin-top: 24px;
  min-height: 128px;
  width: 1344px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #253446;
`;

const EmptyText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #a8aeb5;
  margin-top: 12px;
`;

const NewAppointmentLink = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #577bf9;
  width: max-content;
  cursor: pointer;
  margin-top: 12px;
`;

const AppointmentDate = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #253446;
`;
const AppointmentDoctor = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #515d6b;
  margin-left: 8px;
`;

type Props = {
  patientId: number;
};

export function Appointments({ patientId }: Props) {
  const [calendarVisible, setCalendarVisible] = useState<boolean>(false);
  const { data: appointments = [], isLoading } = useGetAppointmentsQuery(patientId, { skip: !patientId });

  const user = useSelector((state: RootState) => state.common.user);
  const dispatch = useDispatch();

  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', width: '100vw', paddingRight: 60 }}>
      <AppointmentCard>
        <Title>Назначенные визиты</Title>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <div className="spinner" />
          </div>
        ) : !appointments.length ? (
          <EmptyText>Визиты не назначены</EmptyText>
        ) : (
          [...appointments]
            .sort((a, b) => compareDesc(new Date(a.starts_at), new Date(b.starts_at)))
            .map((a) => {
              const startDate = new Date(a.starts_at);
              const endDate = new Date(a.ends_at);
              return (
                <div key={a.id} style={{ display: 'flex', marginTop: 12 }}>
                  <AppointmentDate>
                    <div style={{ marginRight: 8 }}>{format(startDate, 'dd MMM yyyy', { locale: ruLocale })}</div>
                    <div>
                      {format(startDate, 'HH:mm')}-{format(endDate, 'HH:mm')}
                    </div>
                  </AppointmentDate>
                  <AppointmentDoctor>{`${a.doctor.last_name} ${a.doctor.first_name} ${a.doctor.second_name} (${a.doctor.job})`}</AppointmentDoctor>
                </div>
              );
            })
        )}
        <NewAppointmentLink
          onClick={() => {
            setTimeout(() =>
              dispatch(
                setDoctorField(
                  calendarVisible ? 'Выбрать врача' : `${user.last_name} ${user.first_name} ${user.second_name}`,
                ),
              ),
            );
            setCalendarVisible(true);
          }}
        >
          Назначить визит
          <AddIcon color="inherit" fontSize="small" />
        </NewAppointmentLink>
      </AppointmentCard>
      <Calendar calendarVisible={calendarVisible} setCalendarVisible={setCalendarVisible} />
    </div>
  );
}
