import { useMemo } from 'react';
import { SubInvoiceStatus, useGetVisitsQuery } from '../../../../services/billing';

export const useGetInvoicesList = (patientId, currentDateId) => {
  const {
    data: invoices = [],
    isLoading,
    refetch,
  } = useGetVisitsQuery(patientId, {
    skip: !patientId,
    refetchOnMountOrArgChange: true,
  });

  const currentInvoice = useMemo(() => {
    return invoices.find((item) => item.invoice.medical_card_date === currentDateId);
  }, [invoices, currentDateId]);

  const invoiceList = useMemo(() => {
    const invoicesForPay = invoices.filter((item) => item.hasInvoice && item.invoice.status !== SubInvoiceStatus.PAID);

    if (invoicesForPay.length < 2 || !currentInvoice) return invoicesForPay;

    return [currentInvoice, ...invoicesForPay.filter((item) => item.invoice.id !== currentInvoice.invoice.id)];
  }, [invoices, currentInvoice]);

  return {
    invoiceList,
    isInvoicesLoading: isLoading,
    currentInvoiceId: currentInvoice?.invoice?.id,
    refreshInvoiceList: refetch,
  };
};
