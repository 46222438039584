import styled from 'styled-components';

import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const CATEGORY_ROW_WIDTH = 1100;
export const CATEGORY_RIGHT_MARGIN = 40;

export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#BFBFBF',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#577BF9',
        borderColor: '#577BF9',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    backgroundColor: 'white',
  },
  track: {
    border: `1px solid #BFBFBF`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#BFBFBF',
  },
  checked: {},
}))(Switch);

export const Layout = styled.div`
  background-color: #f9f9f9;
  width: 100vw;
  height: 100vh;
  padding-bottom: 15px;
`;

export const CenterPage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  margin-top: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  margin-right: 72px;
  margin-left: 22px;
  padding-bottom: 25px;
  height: calc(100vh - 81px);
  max-width: 1440px;
`;

export const TableLayout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`;
export const TableTitle = styled.div`
  width: 1096px;
`;
export const CategoriesContainer = styled.div`
  display: flex;
  flex: 0;
  flex-direction: row;
  height: 56px;
  align-items: center;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
`;
type CategoryBoxProps = {
  isActive: boolean;
  isEditing: boolean;
};
export const CategoryBox = styled.div<CategoryBoxProps>`
  padding-right: ${({ isEditing }) => (isEditing ? '0px' : '15px')};
  position: relative;
  height: 56px;
  box-shadow: ${({ isActive }) => (isActive ? 'inset 0px -2px 0px #577BF9' : 'none')};
  color: ${({ isActive }) => (isActive ? '#577BF9' : '#515D6B')};
  margin-right: 22px;
  &:hover {
    color: ${({ isActive }) => (isActive ? '#4555E4' : '#7995FA')};
    box-shadow: ${({ isActive }) => (isActive ? 'inset 0px -2px 0px #4555E4' : 'none')};
  }
`;

export const MenuDots = styled.div`
  position: absolute;
  top: 16px;
  right: -15px;
  width: 35px;
  height: 25px;
  display: flex;
  flex: 0;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;
export const Dots = styled.img`
  margin-right: 9px;
  width: 17px;
`;
export const PriceMenu = styled.div`
  position: absolute;
  top: 15px;
  right: 0px;
  width: 190px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  z-index: 17;
`;
export const CategoryMenu = styled.div`
  position: absolute;
  top: 17px;
  right: -200px;
  width: 190px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  z-index: 17;
`;

type MenuItemProps = {
  disabled: boolean;
};
export const MenuItem = styled.div<MenuItemProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${({ disabled }) => (disabled ? '#A8AEB5' : '#515d6b')};
  padding-left: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  flex: 0;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #f6f6fa;
  }
`;

type CategoryProps = {
  width: number;
};
export const Category = styled.div<CategoryProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  height: 56px;
  display: flex;
  flex: 0;
  width: ${({ width }) => width}px;
  align-items: center;
  cursor: pointer;
  color: inherit;
`;

export const TableContainer = styled.div`
  width: 1200px;
  margin-left: -100px;
  margin-top: -48px;
`;
export const Code = styled.div`
  width: 156px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #515d6b;
  padding-left: 20px;
`;
export const Name = styled.div`
  width: 744px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #515d6b;
  padding-left: 20px;
`;
export const Value = styled.div`
  width: 112px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #515d6b;
  text-align: left;
  padding-left: 20px;
`;
export const Edit = styled.div`
  flex: 0;
  width: 84px;
  height: 54px;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #515d6b;
  display: flex;
  position: relative;
`;
type RowProps = {
  colored: boolean;
};
export const Row = styled.div<RowProps>`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  background: ${({ colored }) => (colored ? '#F9F9F9' : 'white')};
  border-bottom: 1px solid #d3d6da;
  width: 100%;
  height: 54px;
`;

export const HeaderLayout = styled.div`
  background: #f9f9f9;
  height: 54px;
  width: 1096px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  border-bottom: 2px solid #7c8590;
  border-top: 2px solid #7c8590;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;
export const HeaderCode = styled.div`
  width: 156px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  padding-left: 20px;
`;

export const HeaderName = styled.div`
  width: 744px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  padding-left: 20px;
`;

export const HeaderValue = styled.div`
  width: 112px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  padding-left: 20px;
  text-align: left;
`;

export const HeaderEdit = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  padding-left: 20px;
  width: 84px;
`;

export const CategoryName = styled.div`
  padding-left: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #253446;
`;

export const ButtonContainer = styled.div`
  margin-top: 19px;
`;

export const ButtonBlue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 16px;
  gap: 4px;
  width: fit-content;
  height: 32px;
  background: #577bf9;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
`;

export const RightSpacer = styled.div`
  width: 15px;
  height: 56px;
`;

export const EditContainer = styled.div`
  margin-left: -8px;
  height: 32px;
  padding-right: 15px;
`;
export const ValueContainer = styled.div`
  margin-left: -12px;
  height: 32px;
`;
export const BottomLine = styled.div`
  position: relative;
  background-color: #a8aeb5;
  top: calc(100vh - 303px);
  left: 1px;
  width: 1096px;
  height: 2px;
  z-index: 20;
`;

export const FixedHeader = styled.div`
  position: relative;
  width: 1096px;
  margin-top: 16px;
  height: 52px;
  z-index: 9;
  & > div {
    position: absolute;
    top: 0;
    left: 2px;
    z-index: 9;
  }
`;
