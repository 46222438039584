import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line  import/no-named-as-default
import toast from 'react-hot-toast';
import { getHours, getMinutes, parse } from 'date-fns';
import { TimeSelect } from './TimeSelect';
import { useEditWorkdayMutation, useGetWorkdaysQuery } from '../../../../services/dictionaries';

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.08), 0 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 3px;
  padding: 16px 24px 24px;
`;

const Heading = styled.p`
  color: #253446;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
`;

const WorkDays = styled.div`
  color: #515d6b;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
`;

const WorkDay = styled.div`
  border: 1px solid #d3d6da;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 168px;
  min-width: 154px;
  width: 100%;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const WorkDayHeader = styled.div<{ isWorking: boolean }>`
  align-items: center;
  background: ${({ isWorking }) => (isWorking ? '#EFF2FF' : '#EFF0F3')};
  display: flex;
  height: 52px;
  justify-content: center;
  text-transform: capitalize;
  border-bottom: 1px solid #d3d6da;

  &:hover {
    background: ${({ isWorking }) => (isWorking ? '#DDE5FE' : '#E5E6EA')};
    cursor: pointer;
  }
`;

const WorkDayBody = styled.div`
  align-items: center;
  display: flex;
  height: 104px;
  justify-content: center;
  padding: 0 4px;
`;
const StyledWorkDayTime = styled.div`
  display: flex;
  align-items: center;
`;

const WorkDayTimeSeparator = styled.div`
  position: relative;
  width: 8px;

  &::after {
    background: #c4c4c4;
    content: '';
    height: 1px;
    left: 1px;
    position: absolute;
    top: 50%;
    width: 6px;
  }
`;

type WorkDayTimeProps = {
  workDayId: number;
  time_start: Date;
  time_end: Date;
};

const WorkDayTime = ({ workDayId, time_start, time_end }: WorkDayTimeProps) => {
  const startDate = new Date(time_start);
  const endDate = new Date(time_end);
  const startHours = getHours(startDate);
  const endHours = getHours(endDate);
  const startMinutes = getMinutes(startDate);
  const endMinutes = getMinutes(endDate);

  const [isError, setIsError] = useState(false);

  const [editWorkday, { isLoading }] = useEditWorkdayMutation();

  useEffect(() => {
    if (isLoading) {
      setIsError(false);
    }
  }, [isLoading]);

  const handleChange = useCallback(
    (part) => (value) => {
      editWorkday({
        id: workDayId,
        time_start: `${part === 'startHours' ? value : startHours}:${part === 'startMinutes' ? value : startMinutes}`,
        time_end: `${part === 'endHours' ? value : endHours}:${part === 'endMinutes' ? value : endMinutes}`,
      })
        .unwrap()
        .then(() => {
          setIsError(false);
          toast.success('Изменения успешно сохранены');
        })
        .catch((error) => {
          setIsError(true);
          toast.error(
            error.status === 409
              ? 'В клинике есть визиты в данные часы работы. Перенесите их в расписании для изменения времени'
              : 'Ошибка сохранения',
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <StyledWorkDayTime>
      <TimeSelect
        hours={startHours}
        minutes={startMinutes}
        setHours={handleChange('startHours')}
        setMinutes={handleChange('startMinutes')}
        minHour={0}
        maxHour={endHours - 1}
        isError={isError}
      />
      <WorkDayTimeSeparator />
      <TimeSelect
        hours={endHours}
        minutes={endMinutes}
        setHours={handleChange('endHours')}
        setMinutes={handleChange('endMinutes')}
        minHour={startHours + 1}
        maxHour={24}
        isError={isError}
      />
    </StyledWorkDayTime>
  );
};

export const Schedule: React.FC = () => {
  const days = [
    {
      label: 'понедельник',
      value: 'MON',
    },
    {
      label: 'вторник',
      value: 'TUE',
    },
    {
      label: 'среда',
      value: 'WED',
    },
    {
      label: 'четверг',
      value: 'THU',
    },
    {
      label: 'пятница',
      value: 'FRI',
    },
    {
      label: 'суббота',
      value: 'SAT',
    },
    {
      label: 'воскресенье',
      value: 'SUN',
    },
  ];
  const { data: schedule = [] } = useGetWorkdaysQuery();
  const [editWorkday] = useEditWorkdayMutation();

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const handleWorkDayClick = (workDay, weekday) => () => {
    editWorkday({
      id: workDay.id,
      is_workday: !workDay.is_workday,
    })
      .unwrap()
      .then(() => {
        toast.success('Изменения успешно сохранены');
      })
      .catch((error) => {
        toast.error(
          error.status === 409
            ? 'В клинике есть визиты в данные часы работы. Перенесите их в расписании для изменения времени'
            : 'Ошибка сохранения',
        );
      });
  };
  return (
    <Container className="schedule">
      <Heading>График работы клиники</Heading>
      <WorkDays>
        {days.map(({ label, value }) => {
          const workDay = schedule.find((day) => day.weekday === value);
          return (
            <WorkDay key={value}>
              <WorkDayHeader onClick={handleWorkDayClick(workDay, value)} isWorking={workDay && workDay.is_workday}>
                {label}
              </WorkDayHeader>
              <WorkDayBody>
                {workDay && workDay.is_workday ? (
                  <WorkDayTime
                    workDayId={workDay.id}
                    time_start={parse(workDay.time_start, 'HH:mm:ss', new Date())}
                    time_end={parse(workDay.time_end, 'HH:mm:ss', new Date())}
                  />
                ) : (
                  'Выходной'
                )}
              </WorkDayBody>
            </WorkDay>
          );
        })}
      </WorkDays>
    </Container>
  );
};
