import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MedcardPlanJournalField } from '../../services/medcardJournal';
import _ from 'lodash';
import { getSubinvoiceCopyWithReorderedSubRows } from './components/VisitServices/helpers';
import { InvoiceRow } from '../../services/invoice';

type TReduxSubInvoice = {
  [key: number]: MedcardPlanJournalField[];
  rowsByPrice?: InvoiceRow[];
};

type TRefundModificator = {
  invoiceToRefundId: number;
  value: number;
} | null;

type TRowDataForReturnOperation = {
  id: number;
  payment: number;
  subInvoiceId: number;
  return_status: 'DEBT' | 'NODEBT';
} | null;

type TSubInvoiceRowsRefetchCallbacks = {
  allRowsCallback?: () => void;
  [key: number]: () => void;
} | null;

export enum RefundTypes {
  NODEBT = 'noDebt',
  WITHDEBT = 'withDebt',
}

type TRefundType = RefundTypes.NODEBT | RefundTypes.WITHDEBT | null;

type TInitialState = {
  subInvoices: {
    [key: number]: TReduxSubInvoice;
  };
  refundModificator: TRefundModificator;
  refundType: TRefundType;
  invalidFieldsNames: string[];
  isRefundPaymentInputDirty: boolean;
  isRefundPaidInputDirty: boolean;
  rowDataForReturnOperation: TRowDataForReturnOperation;
  subInvoiceRowsRefetchCallbacks: TSubInvoiceRowsRefetchCallbacks;
  tableDataIsUpdating: boolean;
  updatingTableRowId: number | null;
};

enum ValuesToUpdateNames {
  PAYMENT = 'payment',
  PAID = 'paid',
  REMAINDER = 'remainder',
  AMOUNTFORPAY = 'amountForPay',
}

type TNewSubInvoiceDataToUpdate = {
  rowId: number;
  subInvoiceId: number;
  subRowId: number | null;
} & Partial<{
  [K in ValuesToUpdateNames as K]: number;
}>;

const initialState: TInitialState = {
  subInvoices: {},
  refundModificator: null,
  refundType: null,
  invalidFieldsNames: [],
  isRefundPaymentInputDirty: false,
  isRefundPaidInputDirty: false,
  rowDataForReturnOperation: null,
  subInvoiceRowsRefetchCallbacks: null,
  tableDataIsUpdating: false,
  updatingTableRowId: null,
};

export const visitsAndPaymentsSlice = createSlice({
  name: 'visitsAndPayments',
  initialState,
  reducers: {
    setSubInvoices: (state, action: PayloadAction<TReduxSubInvoice[]>) => {
      return { ...state, subInvoices: action.payload };
    },
    addSubInvoices: (state, action: PayloadAction<[number, TReduxSubInvoice]>) => {
      const subinvoiceCopyWithReorderedSubRows = getSubinvoiceCopyWithReorderedSubRows(
        action.payload[1],
        action.payload[0],
      );
      return {
        ...state,
        subInvoices: { ...state.subInvoices, [action.payload[0]]: subinvoiceCopyWithReorderedSubRows },
      };
    },
    updateSubInvoice: (state, action: PayloadAction<TNewSubInvoiceDataToUpdate>) => {
      const subInvoicesCopy = _.cloneDeep(state.subInvoices);
      const targetSubInvoice = subInvoicesCopy[action.payload.subInvoiceId];

      if (!targetSubInvoice) {
        console.warn(`Счёт ${action.payload.subInvoiceId} не найден, обновления строки не произойдёт`);
        return state;
      }

      const { rowId, subInvoiceId, subRowId, ...newProperties } = action.payload;
      const targetRow = targetSubInvoice[action.payload.subInvoiceId].find((row) => row.id === action.payload.rowId);

      if (targetRow) {
        if (!subRowId) {
          Object.assign(targetRow, newProperties);
        } else {
          delete newProperties.remainder;
          if (newProperties.payment) {
            newProperties.amountForPay = newProperties.payment;
            delete newProperties.payment;
          }
          targetRow.subRows = targetRow.subRows.map((subRow) =>
            subRow.id === subRowId ? Object.assign({}, subRow, newProperties) : subRow,
          );
          targetRow.paymentOperations = targetRow.paymentOperations.map((paymentOperation) =>
            paymentOperation.id === subRowId ? Object.assign({}, paymentOperation, newProperties) : paymentOperation,
          );
        }
      } else {
        console.warn(`Строка ${rowId} не найдена в счёте ${subInvoiceId}, обновления строки не произойдёт`);
        return state;
      }

      return { ...state, subInvoices: subInvoicesCopy };
    },
    setRefundType: (state, action: PayloadAction<TRefundType>) => {
      return { ...state, refundType: action.payload };
    },
    setRefundModificator: (state, action: PayloadAction<TRefundModificator>) => {
      return { ...state, refundModificator: action.payload };
    },
    setInvalidFieldsNames: (state, action: PayloadAction<string[]>) => {
      return { ...state, invalidFieldsNames: action.payload };
    },
    removeInvalidFieldName: (state, action) => {
      const indexToRemove = state.invalidFieldsNames.indexOf(action.payload);
      if (indexToRemove !== -1) {
        state.invalidFieldsNames.splice(indexToRemove, 1);
      }
    },
    setIsRefundPaymentInputDirty: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isRefundPaymentInputDirty:
          state.isRefundPaymentInputDirty === action.payload ? state.isRefundPaymentInputDirty : action.payload,
      };
    },
    setIsRefundPaidInputDirty: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isRefundPaidInputDirty:
          state.isRefundPaidInputDirty === action.payload ? state.isRefundPaidInputDirty : action.payload,
      };
    },
    setRowDataForReturnOperation: (state, action: PayloadAction<TRowDataForReturnOperation>) => {
      return { ...state, rowDataForReturnOperation: action.payload };
    },
    addSubInvoiceRowsRefetchCallbacks: (state, action: PayloadAction<TSubInvoiceRowsRefetchCallbacks>) => {
      return {
        ...state,
        subInvoiceRowsRefetchCallbacks: { ...state.subInvoiceRowsRefetchCallbacks, ...action.payload },
      };
    },
    setTableDataIsUpdating: (state, action: PayloadAction<boolean>) => {
      return { ...state, tableDataIsUpdating: action.payload };
    },
    setUpdatingTableRowId: (state, action: PayloadAction<number | null>) => {
      return { ...state, updatingTableRowId: action.payload };
    },
    resetRefundData: (state) => {
      return {
        ...initialState,
        subInvoices: state.subInvoices,
        subInvoiceRowsRefetchCallbacks: state.subInvoiceRowsRefetchCallbacks,
        tableDataIsUpdating: state.tableDataIsUpdating,
        updatingTableRowId: state.updatingTableRowId,
      };
    },
  },
});

export const {
  setSubInvoices,
  addSubInvoices,
  updateSubInvoice,
  setRefundType,
  setRefundModificator,
  setInvalidFieldsNames,
  removeInvalidFieldName,
  setIsRefundPaymentInputDirty,
  setIsRefundPaidInputDirty,
  setRowDataForReturnOperation,
  addSubInvoiceRowsRefetchCallbacks,
  setTableDataIsUpdating,
  setUpdatingTableRowId,
  resetRefundData,
} = visitsAndPaymentsSlice.actions;

export const subInvoiceByIdsSelector = (state, subInvoicesIds) => {
  const subInvoices = state.visitsAndPayments.subInvoices;
  let result = [];
  if (subInvoices && Object.keys(subInvoices).length !== 0 && subInvoicesIds.length) {
    subInvoicesIds.forEach((subInvoicesId) => {
      if (subInvoices[subInvoicesId] && subInvoices[subInvoicesId][subInvoicesId]) {
        result = result.concat(subInvoices[subInvoicesId][subInvoicesId]);
      }
    });
  }

  return result;
};

export const refundModificatorSelector = (state, subInvoiceId) => {
  if (!subInvoiceId || !state.visitsAndPayments.refundModificator?.invoiceToRefundId) return undefined;
  return state.visitsAndPayments.refundModificator.invoiceToRefundId == subInvoiceId
    ? state.visitsAndPayments.refundModificator
    : undefined;
};
