/**
 * Возвращает количество недель для года и месяца
 *
 * @param {Number} year - Полный год (например, 2022)
 * @param {Number} month_number - Месяц (число от 1 до 12)
 * @returns {number}
 */

const getWeeksCount = function (year, month_number) {
  const firstOfMonth = new Date(year, month_number - 1, 1);
  let day = firstOfMonth.getDay() || 6;
  day = day === 1 ? 0 : day;
  if (day) {
    day--;
  }
  let diff = 7 - day;
  const lastOfMonth = new Date(year, month_number, 0);
  const lastDate = lastOfMonth.getDate();
  if (lastOfMonth.getDay() === 1) {
    diff--;
  }
  const result = Math.ceil((lastDate - diff) / 7);
  return result + 1;
};

export default getWeeksCount;
