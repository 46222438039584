import React, { useCallback, useState } from 'react';
import { Modal, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Slider from '../../../../uikit/Slider';
import { DropzoneArea } from 'material-ui-dropzone';
import Cropper from 'react-easy-crop';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import { ImageFileIcon } from '../../../../uikit/Icons';
import OutlinedButton from '../../../../uikit/Button/OutlinedButton';
import BlueButton from '../../../../uikit/Button/BlueButton';
import { getCroppedImg } from '../../../../utils/image';
import readFile from '../../../../utils/readFile';

const FormatFileText = styled.div`
  font-size: 14px;
  color: #a8aeb5;
  margin-top: 12px;
`;

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalPaper: {
      position: 'absolute',
      padding: 32,
      width: 474,
      height: 603,
      background: '#FFFFFF',
      boxShadow: '0 0 1 rgba(40, 41, 61, 0.08), 0 0.5 2 rgba(96, 97, 112, 0.16)',
      borderRadius: 8,
    },
    dropzone: {
      padding: '100px 71px',
      background: '#F9F9F9',
      border: '1px dashed #253446',
      backdropFilter: 'blur(10px)',
      borderRadius: '4px',
      '& .MuiDropzoneArea-textContainer': {
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
      '&.MuiDropzoneArea-active': {
        background: '#EFF2FF',
        border: '1px dashed #4555E4',
        backdropFilter: 'blur(10px)',

        '& .text-size': {
          color: '#577BF9',
        },
      },
    },
    uploadLink: {
      color: '#577BF9 !important',
      '&:hover': {
        textDecoration: 'underline !important',
      },
    },
    dropzoneText: {
      fontSize: 18,
    },
    cropzone: {
      position: 'relative',
      height: 410,
      padding: 65,
    },
    cropzoneContainer: {
      borderRadius: 4,
    },
    cropzoneCropArea: {
      color: 'rgba(46, 46, 48, 0.7) !important',
    },
    snackbar: {
      position: 'absolute',
      top: 50,
      width: '100%',
    },
    alert: {
      border: 0,
      background: '#FFFFFF',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
      borderRadius: 4,
      color: '#515D6B',
    },
  }),
);

type Props = {
  setOpen: VoidFunction;
  setLogoImage: VoidFunction;
};

export function LogoModal({ setOpen, setLogoImage }: Props) {
  const classes = useStyles();
  const [uploadFile, setUploadFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.5);
  const [fileError, setFileError] = useState<string>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(uploadFile, croppedAreaPixels);
      setLogoImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels]);

  return (
    <Modal open className={classes.modal} onClose={() => setOpen(false)}>
      <div className={classes.modalPaper}>
        {fileError && (
          <Snackbar
            open
            className={classes.snackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={8000}
            onClose={() => setFileError(null)}
          >
            <Alert
              className={classes.alert}
              icon={<CancelRoundedIcon fontSize="inherit" />}
              variant="outlined"
              severity="error"
            >
              {fileError}
            </Alert>
          </Snackbar>
        )}
        {!!uploadFile ? (
          <div className={classes.cropzone}>
            <Cropper
              classes={{ containerClassName: classes.cropzoneContainer, cropAreaClassName: classes.cropzoneCropArea }}
              image={uploadFile}
              cropShape="round"
              showGrid={false}
              crop={crop}
              zoom={zoom}
              aspect={1}
              restrictPosition={false}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        ) : (
          <DropzoneArea
            dropzoneClass={classes.dropzone}
            dropzoneParagraphClass={classes.dropzoneText}
            acceptedFiles={['image/*']}
            showAlerts={false}
            filesLimit={1}
            maxFileSize={3 * 1012 * 1024}
            Icon={ImageFileIcon}
            dropzoneText={
              <>
                <a className={classes.uploadLink} onClick={(e) => e.preventDefault()}>
                  Загрузите изображение
                </a>
                <div>или перетащите в это окно</div>
                <FormatFileText className="text-size">Допустимый размер: до 3 Мб</FormatFileText>
              </>
            }
            onChange={async (files) => {
              if (files && files.length) {
                const imageDataUrl = await readFile(files[0]);
                setUploadFile(imageDataUrl);
              }
            }}
            onAlert={(message, variant) => {
              if (variant === 'error') {
                if (message.includes('File type not supported')) {
                  setFileError('Файл не является изображением');
                } else if (message.includes('File is too big')) {
                  setFileError('Слишком большой размер изображения');
                }
              }
            }}
          />
        )}
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 36 }}>
          <RemoveIcon size="large" style={{ color: `${uploadFile ? '#253446' : '#D3D6DA'}`, marginRight: 10 }} />
          <Slider
            aria-label="slider"
            value={zoom}
            min={0.1}
            max={3}
            step={0.1}
            disabled={!uploadFile}
            onChange={(e, zoom) => setZoom(zoom)}
          />
          <AddIcon size="large" style={{ color: `${uploadFile ? '#253446' : '#D3D6DA'}`, marginLeft: 10 }} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <OutlinedButton
            width={106}
            height={40}
            style={{ marginRight: '20px' }}
            onClick={() => {
              setOpen(false);
              setUploadFile(null);
            }}
          >
            Отменить
          </OutlinedButton>
          <BlueButton
            width={112}
            height={40}
            disabled={!uploadFile}
            onClick={() => {
              setOpen(false);
              handleCroppedImage();
            }}
          >
            Сохранить
          </BlueButton>
        </div>
      </div>
    </Modal>
  );
}
