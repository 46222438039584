import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { TextField, withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const styles = () => ({
  root: {
    width: 380,
    padding: 11,
  },
  label: {
    '&$focused': {
      color: 'rgb(38,38,38)',
    },
  },
  focused: {},
  underline: {
    borderBottom: '1px solid rgb(128,128,128)',
    '&:before': {
      borderBottom: 'unset !important',
    },
    '&:after': {
      borderBottom: 'unset !important',
    },
  },
});

function ToolBar({ isSearching, searchInMessages, startSearch, endSearch, classes }) {
  return (
    <div>
      {isSearching ? (
        <div
          style={{
            marginTop: 10,
            marginLeft: 16,
            marginBottom: 16,
            width: 370,
          }}
        >
          <TextField
            autoFocus
            fullWidth
            InputLabelProps={{
              classes: { root: classes.label, focused: classes.focused },
            }}
            onChange={(event) => searchInMessages(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div
                    style={{
                      color: '#262626',
                      fontSize: '24px',
                      cursor: 'pointer',
                      marginLeft: -13,
                    }}
                    onClick={endSearch}
                  >
                    <ArrowBack color="inherit" fontSize="inherit" />
                  </div>
                </InputAdornment>
              ),
              classes: {
                root: classes.root,
                underline: classes.underline,
              },
            }}
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            marginTop: 24,
            marginLeft: 16,
          }}
        >
          <div
            style={{
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '120%',
              color: '#253446',
              marginRight: 'auto',
            }}
          >
            Чаты
          </div>
          <div
            onClick={startSearch}
            style={{
              marginRight: '24px',
              fontSize: 22,
              cursor: 'pointer',
              position: 'absolute',
              top: 12,
              right: 0,
            }}
          >
            <SearchIcon fontSize="inherit" />
          </div>
        </div>
      )}
    </div>
  );
}

const StyledToolBar = withStyles(styles)(ToolBar);

export default StyledToolBar;
