export enum ReportName {
  NONE,
  REVENUE,
  DEBT,
  HISTORY,
}
export enum ScreenSize {
  ROW_HEIGHT = 46,
  FOOTER_AND_HEADER = 218,
  TABLE_HEAD = 58,
  DEBTS_FOOTER_AND_HEADER = 266,
}
export enum TableSize {
  MAX_REVENUE_ROWS = 12,
  MAX_DEBTS_ROWS = 11,
}
