import React, { useEffect, useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { format, parse, isValid } from 'date-fns';
import InputMask from 'react-input-mask';

import { Calendar } from '../Calendar';
import { OutlinedInput } from '../OutlinedInput';
import { CalendarWrapper, DatePickerWrapper, Divider, Report, ReportsWrapper, StyledClearIcon } from './styles';

interface DatePickerProps {
  value?: Date | null;
  disableFuture?: boolean;
  onDateChange?: (date: Date) => void;
  onCurrentMonthPick?: (year: number, month: number) => void;
  onCurrentYearPick?: (year: number) => void;
  minDate?: Date;
  maxDate?: Date;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  disableFuture,
  onDateChange,
  onCurrentMonthPick,
  onCurrentYearPick,
  minDate = null,
  maxDate = null,
}) => {
  const [date, setDate] = useState(value ?? null);
  const [inputValue, setInputValue] = useState(date ? format(date, 'dd/MM/yyyy') : '');
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(value ? value.getMonth() : new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(value ? value.getFullYear() : new Date().getFullYear());

  useEffect(() => {
    if (inputValue.includes('_')) {
      return;
    }
    const parsedDate = parse(inputValue, 'dd/MM/yyyy', new Date());
    if (isValid(parsedDate)) {
      if (onDateChange) {
        onDateChange(parsedDate);
      }
      setDate(parsedDate);
    } else {
      setInputValue(date ? format(date, 'dd/MM/yyyy') : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    setDate(value);
    setInputValue(value ? format(value, 'dd/MM/yyyy') : '');
  }, [value]);

  const disableSundays = (date: Date): boolean => date.getDay() === 0;

  const handleDateChange = (newDate: Date) => {
    if (onDateChange) {
      onDateChange(newDate);
    }
    setDate(newDate);
    setInputValue(newDate ? format(newDate, 'dd/MM/yyyy') : '');
    setIsOpen(false);
  };

  const handleClearDate = () => {
    setDate(null);
    onDateChange(null);
    setInputValue('');
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <DatePickerWrapper>
        <InputMask
          mask="99/99/9999"
          value={inputValue}
          onChange={(ev) => {
            setInputValue(ev.target.value);
          }}
          onFocus={() => setIsOpen(true)}
        >
          {() => (
            <div>
              {date && <StyledClearIcon onClick={handleClearDate} />}
              <OutlinedInput placeholder="дд/мм/гггг" disableUnderline={true} filled={!!date} />
            </div>
          )}
        </InputMask>
        <CalendarWrapper isOpen={isOpen}>
          <Calendar
            value={date}
            disableFuture={disableFuture}
            onDateChange={handleDateChange}
            onDisableDates={disableSundays}
            minDate={minDate}
            maxDate={maxDate}
            onMonthChange={setCurrentMonth}
            onYearChange={setCurrentYear}
            selectedColor="#EFF0F3"
          >
            <Divider />
            <ReportsWrapper>
              <Report
                onClick={() => onCurrentMonthPick(currentMonth, currentYear)}
                style={{ borderRight: '1px solid #D3D6DA' }}
              >
                Отчет за месяц
              </Report>
              <Report onClick={() => onCurrentYearPick(currentYear)}>Отчет за год</Report>
            </ReportsWrapper>
          </Calendar>
        </CalendarWrapper>
      </DatePickerWrapper>
    </ClickAwayListener>
  );
};
