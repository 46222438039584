import React, { useState } from 'react';
import { ExpandButtonContent, ArrowIconContainer } from './styles';
export default function ExpandButton({ isExpanded }) {
  const [isHovering, setHovering] = useState(false);

  return (
    <ExpandButtonContent>
      <ArrowIconContainer onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
        <img
          width="10px"
          src={isHovering ? './img/blue/left-arrow.svg' : './img/black/left-arrow.svg'}
          style={{
            transform: `rotate(${isExpanded ? 180 : 0}deg)`,
            transition: '0.2s',
          }}
          alt={'menu-arrow'}
        />
      </ArrowIconContainer>
    </ExpandButtonContent>
  );
}
