import React from "react";
import styled from "styled-components";

type ContainerProps = {
  variant: "outlined" | "filled" | "text";
  disabled: boolean;
};

const Container = styled.div<ContainerProps>`
  cursor: pointer;
  background: ${({ variant }) =>
    variant === "filled" ? "#577bf9" : "transparent"};
  border-radius: 4px;
  border: ${({ variant }) =>
    variant === "filled" || variant === 'text' ? "none" : "1px solid #577bf9"};
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  padding: 4px 16px;
  color: ${({ variant }) => (variant === "filled" ? " #EFF2FF" : "#577BF9")};
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto' }
`;

type ButtonProps = {
  title: string;
  variant: "outlined" | "filled" | "text";
  onClick: () => any;
  disabled?: boolean;
};

function Button({ title, variant, onClick, disabled = false }: ButtonProps) {
  return (
    <Container onClick={onClick} variant={variant} disabled={disabled}>
      {title}
    </Container>
  );
}

export default Button;
