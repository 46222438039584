import {
  LOAD_WEEK,
  SET_USER,
  MOUSE_DOWN,
  MOUSE_UP,
  MOUSE_MOVE,
  SET_ORGANISATION,
  SET_PATIENT,
  SET_STAFFER,
  TITLE_CHANGE,
  DESCRIPTION_CHANGE,
  TASK_SAVED,
  DATE_ERROR,
  CLOSE_ERROR,
  ERROR_MOUSE_UP,
  SHOW_ORGANISATION_MENU,
  CHANGE_ORGANISATION,
  SHOW_ORGANISATION_DIALOG,
  CLOSE_ORGANISATION,
  SET_FILTER,
  LOAD_WEEK_HEADER,
  SET_FILTER_FIELDS,
  SET_FILTER_TYPE,
  SET_PAGINATION,
  NEXT_PAGE,
  PICK_COLOR,
  TASK_CLOSE,
} from "./actions_calendar";
import { SET_WORKDAYS } from "../workday/actions_workdays";
import { format } from "date-fns";
import { FLUSH_STATE } from "../flushState/types";
const INITIAL_STATE = {
  week: [[]],
  head: [[]],
  day: new Date().toDateString(),
  curr_user: {},
  mouse: { x: 0, y: 0 },
  start: { event: null },
  end: { event: null },
  task: { description: "", title: "" },
  newEventStarted: false,
  showTaskDeskription: false,
  contractor: "",
  isContractorPatient: false,
  error: { show: false, message: "" },
  currOrganisation: { id: 0, isNew: true },
  organisationMenu: { show: false, anchorEl: null },
  organisationDialog: { show: false, anchorEl: null },
  filters: {
    doctor: [],
    cabinet: [],
    job: [],
    day: format(new Date(), "yyyy-MM-dd"),
  },
  schedule: {
    headMain: [],
    headSecond: [],
    filterType: "day",
    select: { doctor: "Выбрать врача", cabinet: "Выбрать кабинет" },
    color: "rgb(178,178,178)",
  },
  paginator: { page: 0, length: 0 },
  workdays: { user: {} },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_WEEK:
      return {
        ...state,
        ...{
          week: action.payload.week,
          day: action.payload.day.toDateString(),
        },
      };
    case SET_USER:
      return {
        ...state,
        ...{ curr_user: action.payload },
      };
    case MOUSE_MOVE:
      return {
        ...state,
        ...{ mouse: action.payload },
      };
    case MOUSE_DOWN:
      return {
        ...state,
        ...{ start: action.payload, newEventStarted: true },
      };
    case MOUSE_UP:
      return {
        ...state,
        ...{
          end: action.payload,
          newEventStarted: false,
          showTaskDeskription: true,
        },
      };
    case ERROR_MOUSE_UP:
      return {
        ...state,
        ...{
          start: { event: {} },
          end: { event: {} },
          newEventStarted: false,
          showTaskDeskription: false,
        },
      };
    case SET_STAFFER:
      return {
        ...state,
        ...{ contractor: action.payload, isContractorPatient: false },
      };
    case SET_PATIENT:
      return {
        ...state,
        ...{ contractor: action.payload, isContractorPatient: true },
      };
    case SET_ORGANISATION:
      return {
        ...state,
        ...{ contractor: action.payload, isContractorPatient: false },
      };
    case TITLE_CHANGE:
      return {
        ...state,
        ...{
          task: { title: action.payload, description: state.task.description },
        },
      };
    case DESCRIPTION_CHANGE:
      return {
        ...state,
        ...{ task: { description: action.payload, title: state.task.title } },
      };
    case TASK_SAVED:
      return {
        ...state,
        ...{ showTaskDeskription: false },
      };
    case DATE_ERROR:
      return {
        ...state,
        ...{ error: action.payload },
      };
    case CLOSE_ERROR:
      return {
        ...state,
        ...{ error: action.payload },
      };
    case SHOW_ORGANISATION_DIALOG:
      return {
        ...state,
        ...{
          organisationDialog: {
            show: !state.organisationDialog.show,
            anchorEl: state.organisationMenu.anchorEl,
          },
          organisationMenu: { show: false, anchorEl: null },
        },
      };
    case SHOW_ORGANISATION_MENU:
      return {
        ...state,
        ...{
          currOrganisation: {
            id: action.payload.id,
            isNew: true,
          },
          organisationMenu: {
            show: !state.organisationMenu.show,
            anchorEl: action.payload,
          },
        },
      };
    case CHANGE_ORGANISATION:
      return {
        ...state,
        ...{
          organisationDialog: {
            show: !state.organisationDialog.show,
            anchorEl: state.organisationMenu.anchorEl,
          },
          organisationMenu: { show: false, anchorEl: null },
          currOrganisation: {
            id: state.currOrganisation.id,
            isNew: false,
          },
        },
      };
    case CLOSE_ORGANISATION:
      return {
        ...state,
        ...{
          currOrganisation: { id: 0, isNew: true },
          organisationMenu: { show: false, anchorEl: null },
          organisationDialog: { show: false, anchorEl: null },
        },
      };
    case SET_FILTER:
      return {
        ...state,
        ...{
          filters: { ...state.filters, ...action.payload },
        },
      };
    case LOAD_WEEK_HEADER:
      return {
        ...state,
        ...{
          schedule: { ...state.schedule, ...action.payload },
        },
      };
    case SET_FILTER_FIELDS:
      return {
        ...state,
        ...{
          schedule: action.payload,
        },
      };
    case SET_FILTER_TYPE:
      return {
        ...state,
        ...{
          schedule: action.payload,
        },
      };
    case SET_PAGINATION:
      return {
        ...state,
        ...{
          paginator: { page: state.paginator.page, length: action.payload },
        },
      };
    case NEXT_PAGE:
      return {
        ...state,
        ...{ paginator: action.payload },
      };
    case SET_WORKDAYS:
      return {
        ...state,
        ...{ workdays: { user: action.payload } },
      };
    case PICK_COLOR:
      return {
        ...state,
        ...{ schedule: { ...state.schedule, color: action.payload } },
      };
    case TASK_CLOSE:
      return {
        ...state,
        ...{
          start: { event: null },
          end: { event: null },
          newEventStarted: false,
          showTaskDeskription: false,
        },
      };

    case FLUSH_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
