import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Tabs, Tab, ButtonBase } from '@material-ui/core';
import { ToothHistory } from '../../../services/medcard';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

const BUTTON_WIDTH = 40;
const MAX_BUTTONS = 5;

const Container = styled.div`
  position: relative;
`;

const Layout = styled.div<{ count: number; isTop: boolean; index: number }>`
  position: absolute;
  top: ${({ isTop }) => (isTop ? -56 : 8)}px;
  left: ${({ index }) => -40 * index}px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  display: flex;
  flex: 0;
  flex-direction: row;
  width: ${({ count }) => (count > MAX_BUTTONS ? MAX_BUTTONS * BUTTON_WIDTH : count * BUTTON_WIDTH)}px;
`;

// eslint-disable-next-line react/display-name
const TabScrollButton = forwardRef((props, ref) => {
  const { direction, ...other } = props;

  return (
    <ButtonBase component="div" ref={ref} {...other}>
      {direction === 'left' ? <KeyboardArrowLeft fontSize="medium" /> : <KeyboardArrowRight fontSize="medium" />}
    </ButtonBase>
  );
});

const tabsStyles = makeStyles(() =>
  createStyles({
    indicator: {
      display: 'none',
    },
    root: {
      overflow: 'unset',
      minHeight: 40,
      boxSizing: 'border-box',
      maxWidth: MAX_BUTTONS * BUTTON_WIDTH,
    },
    scroller: {
      borderRadius: 4,
    },
    scrollButtons: {
      position: 'absolute',
      background: '#EFF0F3',
      boxShadow: '0 0 1px rgba(33, 33, 41, 0.04), 0 2px 4px rgba(33, 33, 41, 0.16)',
      borderRadius: '50%',
      top: 12,
      zIndex: 1,
      '&:last-of-type': {
        right: -17,
      },
      '&:first-of-type': {
        left: -17,
      },
      '&.Mui-disabled': {
        display: 'none',
      },
    },
  }),
);

const tabItemStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: '#ffffff',
      opacity: 1,
      outline: 'none !important',
      minHeight: 40,
      textTransform: 'none',
      [theme.breakpoints.up('md')]: {
        minWidth: 40,
      },
      '&:hover': {
        background: '#F6F6FA',
      },
    },
    selected: {
      background: '#F6F6FA',
    },
    wrapper: {
      fontSize: 20,
      fontWeight: 'bold',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  }),
);

type Props = {
  isTop: boolean;
  currentDateId: number;
  toothHistory: ToothHistory[];
  pickDate: ActionCreatorWithNonInferrablePayload;
  setExpanded: ActionCreatorWithNonInferrablePayload;
  hideHistory: VoidFunction;
};

export function ChangeHistory({ isTop, toothHistory, currentDateId, pickDate, setExpanded, hideHistory }: Props) {
  const tabsClasses = tabsStyles();
  const tabItemClasses = tabItemStyles();

  const toothStateIndex = toothHistory.findIndex((h) => h.dateId === currentDateId);

  return (
    <Container>
      <Layout
        count={toothHistory.length}
        isTop={isTop}
        index={toothStateIndex > 0 ? (toothStateIndex >= MAX_BUTTONS ? MAX_BUTTONS - 1 : toothStateIndex) : 0}
      >
        <Tabs
          classes={tabsClasses}
          variant="scrollable"
          scrollButtons="auto"
          ScrollButtonComponent={TabScrollButton}
          value={currentDateId}
          onClick={(e) => e.stopPropagation()}
          onChange={(_, value) => {
            pickDate(value);
            setExpanded(toothHistory.find((h) => h.dateId === value)?.entryId);
            hideHistory();
          }}
        >
          {toothHistory.map((h) => (
            <Tab
              key={h.dateId}
              classes={tabItemClasses}
              label={
                h.toothState || h.toothTreatment ? (
                  <span style={{ color: h.toothTreatment?.iconColor || h.toothState?.iconColor }}>
                    {h.toothTreatment?.icon || h.toothState?.icon}
                  </span>
                ) : (
                  <img src={'./img/black/emptyState.svg'} style={{ marginLeft: 5 }} />
                )
              }
              value={h.dateId}
            />
          ))}
        </Tabs>
      </Layout>
    </Container>
  );
}
