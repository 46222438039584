export const SET_TIME_START = "@appointments/set_time_start";
export const SET_TIME_END = "@appointments/set_time_start";
export const SET_DETAILS_VISIBILITY = "@appointments/set_details_visiblity";
export const LOAD_APPOINTMENTS = "@appointments/load_appointments";
export const PICK_APPOINTMENT = "@appointments/pick_appointments";
export const SET_ERRORS = "@appointments/set_errors";
export const SET_STATUS = "@appointments/set_status";
export const SET_STEP = "@appointments/set_step";
export const SET_SAVED_PATIENT = "@appointments/set_saved_patient";
export const SET_APPOINTMENTS_DETAILS =
  "@appointments/set_appointments_details";
export const SET_RESCHEDULING = "@appointments/set_rescheduling";
export const CANCEL_APPOINTMENT_CREATION = "@CANCEL_APPOINTMENT_CREATION";
