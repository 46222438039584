import {withStyles} from "@material-ui/core/styles";
import {Slider} from "@material-ui/core";

const iOSBoxShadow =
  '0px 2.33333px 4.66667px rgba(0, 35, 11, 0.2);';

const IOSSlider = withStyles({
  root: {
    color: '#577BF9',
    height: 3,
  },
  thumb: {
    height: 22,
    width: 22,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -11,
    marginLeft: -11,
    border: '0.583333px solid #C4C4C4',
    '&:focus, &:hover, &$active': {
      boxShadow: iOSBoxShadow,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
    "&.Mui-disabled": {
      display: 'none'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 3,
    borderRadius: 5,
  },
  rail: {
    height: 3,
    opacity: 1,
    backgroundColor: '#EAE9E9',
    borderRadius: 5,
  },
})(Slider);

export default IOSSlider;
