import React, { useRef, useState } from 'react';
import { Row, Code, Name, Value, Edit, EditContainer } from '../styles';
import TextInput from '../../../uikit/TextInput';
import { connect } from 'react-redux';
import { createPriceEntry, getPrice, updatePriceEntry } from '../operations';
import * as R from 'ramda';
import PriceSlice from '../state';
import { OnTopTooltip } from '../../../components/DoctorFilter/OnTopTooltip';
import { RootState } from '../../../store';

function format(str) {
  const chars = str.replaceAll('.', '').split('');
  const strWithPoints = chars.reduce((acc, char, i) => {
    const spaceOrNothing = (i + 1) % 3 === 0 ? '.' : '';
    return acc + char + spaceOrNothing;
  }, '');

  return strWithPoints[strWithPoints.length - 1] === '.' ? strWithPoints.slice(0, -1) : strWithPoints;
}
const InputWrapper = ({ withPlaceholder = false, placeholder, defaultValue, refProp = {}, ...props }) => {
  return withPlaceholder ? (
    <TextInput ref={refProp} placeholder={placeholder} {...props} />
  ) : (
    <TextInput ref={refProp} defaultValue={defaultValue} {...props} />
  );
};
function NewItemRow({
  item,
  index,
  updatePriceEntry,
  showMenuFor,
  startEditingEntry,
  getPrice,
  createPriceEntry,
  priceSlice,
  onStopEdit,
}) {
  const codeInputRef = useRef({});
  const titleInputRef = useRef({});
  const valueInputRef = useRef({});
  const [error, setError] = useState(false);
  const [value, setValue] = useState(item.code);
  return (
    <Row key={item.id} colored={!!(index % 2)}>
      <Code>
        <EditContainer>
          <OnTopTooltip
            title={
              <div style={{ display: 'flex', columnGap: '4px' }}>
                <img src="./img/red/attention.svg" />
                {'Код уже существует'}
              </div>
            }
            isShown={error}
            light={true}
            arrow
          >
            <InputWrapper
              refProp={codeInputRef}
              autoFocus
              error={error}
              value={value}
              onChange={(e) => {
                let value = e.target.value;
                value = format(value);
                const codeArr = R.pluck('code')(priceSlice.response);
                setError(codeArr.filter((code) => code === value).length > 0);
                setValue(value);
              }}
              placeholder={'000.000.000'}
              withPlaceholder={item.id === 'new entry'}
              defaultValue={item.code}
            />
          </OnTopTooltip>
        </EditContainer>
      </Code>
      <Name>
        <EditContainer>
          <InputWrapper
            refProp={titleInputRef}
            placeholder={'Название позиции'}
            withPlaceholder={item.id === 'new entry'}
            defaultValue={item.title}
          />
        </EditContainer>
      </Name>
      <Value>
        <EditContainer>
          <InputWrapper
            refProp={valueInputRef}
            placeholder={'0.00'}
            withPlaceholder={item.id === 'new entry'}
            textAlign={'left'}
            defaultValue={item.value}
          />
        </EditContainer>
      </Value>
      <Edit>
        <img
          onClick={async () => {
            const entries = {
              code: codeInputRef.current.value,
              title: titleInputRef.current.value,
              value: valueInputRef.current.value,
            };
            if (!error) {
              if (item.id === 'new entry') {
                await createPriceEntry(entries, item, index);
              } else {
                await updatePriceEntry(entries, item);
                onStopEdit(item.id);
              }
              showMenuFor(null);
              startEditingEntry(null);
            }
          }}
          style={{ marginRight: 15 }}
          src={'./img/green/check.svg'}
          alt={'save'}
        />
        <img
          onClick={async () => {
            if (item.id === 'new entry') {
              await getPrice();
            } else {
              onStopEdit(item.id);
            }
            showMenuFor(null);
            startEditingEntry(null);
          }}
          src={'./img/black/close.svg'}
          alt={'cancel'}
        />
      </Edit>
    </Row>
  );
}

export default connect((state: RootState) => ({ priceSlice: state.priceSlice }), {
  getPrice,
  createPriceEntry,
  updatePriceEntry,
  showMenuFor: PriceSlice.actions.showMenuFor,
  startEditingEntry: PriceSlice.actions.startEditingEntry,
})(NewItemRow);
