/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { loadMessages, changeMessageData, loadDialogs, loadSearchResults } from './actions';
import { handleError } from '../legacyState';
import { OPEN_DIALOG } from './types';
import requests, { chatURL } from '../../utils/request';
import io from 'socket.io-client';
import Fuse from 'fuse.js';

export function connectToWS() {
  return async (dispatch, getState) => {
    try {
      const { common } = getState();
      const socket = io(chatURL);
      // socket.on('connect', () => {
      //   console.log(socket.id);
      //   console.log(socket.connected);
      // });
      socket.emit('login', common.chat_jwt);
      socket.on('msg', (data) => {
        dispatch(fetchMessages(data.message.sender.id, data.message.reciever.id));
        dispatch(fetchDialogs());
      });
    } catch (error) {
      alert(error);
    }
  };
}
export function subscribeToEvents() {}
export function disconnectFromWS() {
  return async () => {
    //const { messages } = getState();
  };
}
export function checkMessageAsRead(me, him, index, message) {
  return async (dispatch, getState) => {
    const { common } = getState();
    message = { ...message, ...{ isNew: false } };
    await requests.users.set_message_as_read({ id: me }, { id: him }, index, message, common.chat_jwt, common.user);
  };
}
export function sendMessage(data) {
  return async (dispatch, getState) => {
    const { common } = getState();
    dispatch(changeMessageData(''));

    try {
      await requests.users.new_message({ ...data }, common.chat_jwt);
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}
export function fetchDialogs() {
  return async (dispatch, getState) => {
    try {
      const { common } = getState();
      let responce = await requests.users.load_dialogs(common.user, common.chat_jwt);
      let data = responce.data.map((item) => ({
        ...item,
        ...{ message: item.message.map((el) => JSON.parse(el)) },
      }));
      data = data.map((element) => ({
        ...element,
        ...{
          unReadMessages: element.message.reduce((acc, msg) => {
            return msg.isNew ? (parseInt(msg.reciever.id) === parseInt(common.user.id) ? acc + 1 : acc) : acc;
          }, 0),
        },
      }));
      dispatch(loadDialogs(data));
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function fetchDialog(key) {
  return async (dispatch, getState) => {
    try {
      const { common } = getState();
      let responce = await requests.users.get_dialog(key, common.user, common.chat_jwt);

      let data = responce.data.map((item) => JSON.parse(item));
      dispatch(loadMessages(data));
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function fetchMessages(me, him) {
  return async (dispatch, getState) => {
    try {
      const { common } = getState();
      let responce = await requests.users.load_messages(me, him, common.chat_jwt, common.user);

      let data = responce.data.map((item) => JSON.parse(item));
      dispatch(loadMessages(data));
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function searchInMessages(query) {
  return async (dispatch, getState) => {
    const { messages } = getState();
    if (query === '') {
      dispatch(loadDialogs(messages.dialogs));
    } else {
      var options_dialogs = {
        threshold: 0.3,
        keys: ['message.sender.name', 'message.reciever.name'],
      };
      var book_dialogs = messages.dialogs;
      var fuse_dialogs = new Fuse(book_dialogs, options_dialogs);
      var res_dialogs = await fuse_dialogs.search(query);
      var options_msg = {
        threshold: 0.3,
        keys: ['text'],
      };
      var book_msg = messages.dialogs.reduce((acc, el) => [...acc, ...el.message], []);
      var fuse_msg = new Fuse(book_msg, options_msg);
      var res_msg = await fuse_msg.search(query);
      dispatch(loadSearchResults(res_dialogs, res_msg));
    }
  };
}

export function openDialog(data) {
  return async (dispatch) => {
    await dispatch({
      type: OPEN_DIALOG,
      payload: data,
    });
    await dispatch(fetchDialog(data.id ? data.id : data.reciever.id));
  };
}
