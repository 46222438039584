import print from 'print-js';
import { saveAs } from 'file-saver';

/**
 * Открывает окно печати в Chrome.
 * @blob бинарник pdf документа
 */
export function printBase64Pdf(data: string) {
  print({ printable: data, type: 'pdf', base64: true });
}

/**
 * Открывает окно печати в Chrome.
 * @blob бинарник pdf документа
 */
export function printPdf(blob: Blob) {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    const base64Data = reader.result?.toString();
    const data = base64Data?.split(',')[1];
    if (data) {
      printBase64Pdf(data);
    }
  };
}

/**
 * Скачивает pdf файл.
 * @blob бинарник pdf файла
 * @string имя файла
 */
export function uploadFile(file: Blob, name: string) {
  saveAs(file, name);
}
