export interface IRowProps {
  isColored: boolean;
  isSelected: boolean;
}

export interface IPatientProps {
  data;
  isColored: boolean;
  isFullView: boolean;
  isSelected?: boolean;
  handleClick?;
  editableFields?: Partial<TEditingFields>;
  handleFieldEdit?: (fieldName, value) => void;
  editiableFieldsValues?: TEditiableFieldsValues;
}

const fieldsNames = [
  'card_number',
  'last_name',
  'first_name',
  'second_name',
  'birth_date',
  'phone',
  'email',
  'stream',
  'commentary',
];
type FieldsNames = typeof fieldsNames[number];

export type TEditingFields = {
  [K in FieldsNames]: boolean;
};

export type TEditiableFieldsValues = {
  [K in FieldsNames]: string;
};

export const initialEditingFields: TEditingFields = fieldsNames.reduce((acc, key) => {
  acc[key] = false;
  return acc;
}, {} as TEditingFields);

export const initialEditiableFieldsValues: TEditiableFieldsValues = fieldsNames.reduce((acc, key) => {
  acc[key] = '';
  return acc;
}, {} as TEditiableFieldsValues);
