import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import StateMenu from './StateMenu';
import FormulaState from './state';
import { connect } from 'react-redux';
import { ClickAwayListener, Popover } from '@material-ui/core';
import { FormulaType } from './index';
import {
  DoctorType,
  ToothHistory,
  useAddEntryMutation,
  useGetDatesQuery,
  useGetEntriesQuery,
} from '../../../services/medcard';
import { isToday } from 'date-fns';
import { useAddInvoiceMutation, useAddSubInvoiceMutation, useGetInvoicesQuery } from '../../../services/invoice';
import { EAccountStatus } from '../Account/AccountStatus';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { useAddToothMutation, useDeleteToothMutation, useUpdateToothMutation } from '../../../services/childFormula';
import { last } from 'lodash';
import { ChangeHistory } from '../ChangeHistory';
import { useAddPlanRecordMutation } from '../../../services/medcardJournal';

type ToothHandlerProps = {
  isFocused: boolean;
  createdToday: boolean;
  visible: boolean;
};
const ToothHandler = styled.div<ToothHandlerProps>`
  cursor: pointer;
  background: #ffffff;
  box-sizing: border-box;
  border: ${({ isFocused, createdToday }) =>
    isFocused ? '2px solid #577BF9' : createdToday ? '2px solid #9AB0FB' : '1px solid #515d6b'};
  box-shadow: ${({ isFocused }) => (isFocused ? '0px 0px 4px rgba(87, 123, 249, 0.5)' : 'none')};

  border-radius: 4px;
  height: 40px;
  width: 40px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  &:hover {
    border: ${({ isFocused, createdToday }) =>
      isFocused ? '2px solid #577BF9' : createdToday ? '2px solid #9AB0FB' : '1px solid #577bf9'};
  }
`;
type ToothBlockProps = {
  index: number;
};
const ToothBlock = styled.div<ToothBlockProps>`
  position: relative;
  margin-right: ${({ index }) => (index === 7 ? 32 : 20)}px;
`;
type ToothNumberProps = {
  bottom: boolean;
  selected: boolean;
  createdToday: boolean;
};
const ToothNumber = styled.div<ToothNumberProps>`
  font-style: normal;
  font-weight: ${({ createdToday }) => (createdToday ? '500' : 'normal')};
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  cursor: pointer;
  color: ${({ selected, createdToday }) => (selected ? '#577bf9' : createdToday ? '#7995FA' : '#515d6b')};
  margin-top: ${({ bottom }) => (bottom ? 0 : 16)}px;
  margin-bottom: ${({ bottom }) => (bottom ? 16 : 0)}px;
`;
type ToothStateProps = {
  color: string;
};
const ToothState = styled.div<ToothStateProps>`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: ${({ color }) => color};
`;

const ContextMenuItem = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #515d6b;
  padding: 10px;
  cursor: pointer;
`;

function ToothBlockLower({
  toothIndex,
  toothMenuId,
  index,
  openMenuFor,
  setExpanded,
  pickDate,
  setEmptyToothStateError,
  addCurrentDate,
  teethFormulaState,
  formulaType,
  childFormula,
  patientId,
  doctor,
  dateId,
  allToothHistory,
  historyMenuId,
  setHistoryMenuId,
}: {
  toothIndex: string;
  toothMenuId: string;
  index: number;
  openMenuFor: (id) => void;
  setExpanded: ActionCreatorWithNonInferrablePayload;
  addCurrentDate: ActionCreatorWithNonInferrablePayload;
  pickDate: ActionCreatorWithNonInferrablePayload;
  setEmptyToothStateError: ActionCreatorWithNonInferrablePayload;
  bottom: boolean;
  teethFormulaState: RootState['teethFormulaState'];
  formulaType: FormulaType;
  childFormula: string[];
  patientId: number;
  doctor: DoctorType;
  dateId: number;
  allToothHistory: ToothHistory[];
  historyMenuId: string;
  setHistoryMenuId: Dispatch<SetStateAction<string | null>>;
}) {
  const { expandedId, currentDateId, emptyToothStateError } = teethFormulaState;
  const [addEntry] = useAddEntryMutation();
  const [addInvoice] = useAddInvoiceMutation();
  const [addSubInvoice] = useAddSubInvoiceMutation();
  const { data: dates = [] } = useGetDatesQuery(patientId, { skip: !patientId });
  const { data: entries = [] } = useGetEntriesQuery({ patientId, dateId: currentDateId }, { skip: !patientId });
  const { data: invoices = [] } = useGetInvoicesQuery(
    { dateId: dateId, patientId: patientId },
    { skip: !patientId || !dateId },
  );

  const [addTooth] = useAddToothMutation();
  const [updateTooth] = useUpdateToothMutation();
  const [deleteTooth] = useDeleteToothMutation();
  const [addPlanRecord] = useAddPlanRecordMutation();

  let currentEntry = entries.find((d) => d.toothIndex === toothIndex);
  if (currentEntry) {
    currentEntry = { ...currentEntry, isCurrentDate: currentEntry.dateId === currentDateId };
  }

  const currentToothHistory = allToothHistory[toothIndex] || [];
  const actualToothStatus =
    !dates.find((d) => d.id === currentDateId) && isToday(new Date())
      ? last(currentToothHistory)
      : last(currentToothHistory.filter((h) => h.dateId <= currentDateId));

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const isChild = +toothIndex.split('.')[1] <= 5;
  const isAdult = +toothIndex < 5;
  const hasTooth = !!childFormula.find((e) => e === toothIndex);

  return (
    <ClickAwayListener
      onClickAway={() => {
        // setHistoryMenuId(null);
      }}
    >
      <ToothBlock
        index={index}
        onContextMenu={(e) => {
          e.preventDefault();
          setHistoryMenuId(toothIndex === historyMenuId ? null : toothIndex);
        }}
      >
        <ToothNumber
          selected={currentEntry?.id === expandedId}
          createdToday={currentEntry?.isCurrentDate}
          onClick={() => {
            if (emptyToothStateError) {
              return;
            }
            if (formulaType === FormulaType.Adult || hasTooth) {
              addEntry({
                entry: {
                  doctor: doctor.id,
                  date_id: dateId,
                  formula_type: formulaType,
                  tooth_index: toothIndex,
                  tooth_state_mnemonic: '',
                  tooth_treatment_mnemonic: '',
                },
                patientId: patientId,
              })
                .unwrap()
                .then(({ id, date_id }) => {
                  setTimeout(() => setExpanded(id));
                  if (!dateId) {
                    addCurrentDate(date_id);
                    pickDate(date_id);
                    addInvoice({ date_id })
                      .unwrap()
                      .then(({ id }) => {
                        addSubInvoice({ invoice: id, formula_id: id, status: EAccountStatus.NOT_FILLED });
                      });
                  }
                  if (id) {
                    addPlanRecord({
                      patientId,
                      entryId: id,
                    });
                    if (invoices?.length) {
                      addSubInvoice({ invoice: invoices[0].id, formula_id: id, status: EAccountStatus.NOT_FILLED });
                    }
                  }
                });
            }
          }}
          onContextMenu={(event) => {
            if (formulaType === FormulaType.Child) {
              event.preventDefault();
              setAnchorEl(event.currentTarget);
            }
          }}
          bottom={true}
        >
          {toothIndex}
        </ToothNumber>

        {formulaType === FormulaType.Child && open && (
          <Popover
            style={{ marginLeft: -20, marginTop: -10 }}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ContextMenuItem
              onClick={() => {
                if (isChild) {
                  updateTooth({
                    patientId,
                    toothIndex,
                    newToothIndex: isAdult ? `${(+toothIndex + 4).toFixed(1)}` : `${(+toothIndex - 4).toFixed(1)}`,
                  });
                } else {
                  if (hasTooth) {
                    deleteTooth({
                      patientId,
                      toothIndex,
                    });
                  } else {
                    addTooth({
                      patientId,
                      newToothIndex: toothIndex,
                    });
                  }
                }
                handleClose();
              }}
            >
              {hasTooth && isAdult ? 'Отменить прорезование' : 'Постоянный'}
            </ContextMenuItem>
          </Popover>
        )}

        <ToothHandler
          createdToday={currentEntry?.isCurrentDate}
          visible={formulaType === FormulaType.Adult || hasTooth}
          isFocused={toothIndex === toothMenuId || (currentEntry && currentEntry.id === expandedId)}
          onClick={() => {
            openMenuFor(toothIndex === toothMenuId ? null : toothIndex);
          }}
        >
          <ToothState
            color={
              !!currentToothHistory.length &&
              (actualToothStatus?.toothTreatment?.iconColor || actualToothStatus?.toothState?.iconColor)
            }
          >
            {!!currentToothHistory.length &&
              (actualToothStatus?.toothTreatment?.icon || actualToothStatus?.toothState?.icon)}
          </ToothState>
        </ToothHandler>
        {toothIndex === toothMenuId && !emptyToothStateError && (
          <StateMenu
            actualToothStatus={actualToothStatus}
            currentEntry={currentEntry}
            patientId={patientId}
            doctor={doctor}
            dateId={dateId}
            toothIndex={toothIndex}
            openMenuFor={openMenuFor}
            setEmptyToothStateError={setEmptyToothStateError}
            bottom
            left={index <= 7}
          />
        )}
        {toothIndex === historyMenuId && !!currentToothHistory.length && (
          <ChangeHistory
            isTop={false}
            currentDateId={currentDateId}
            toothHistory={currentToothHistory}
            pickDate={pickDate}
            setExpanded={setExpanded}
            hideHistory={() => setHistoryMenuId(null)}
          />
        )}
      </ToothBlock>
    </ClickAwayListener>
  );
}
export default connect((state: RootState) => ({ teethFormulaState: state.teethFormulaState }), {
  setExpanded: FormulaState.actions.setExpanded,
  pickDate: FormulaState.actions.pickDate,
  addCurrentDate: FormulaState.actions.addCurrentDate,
  setEmptyToothStateError: FormulaState.actions.setEmptyToothStateError,
})(ToothBlockLower);
