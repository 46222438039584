import { SET_DOCTOR_FIELD, LOAD_DOCTORS } from "./types";
import { FLUSH_STATE } from "../../flushState/types";
const INITIAL_STATE = { value: "Выбрать врача", doctors: [] };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DOCTOR_FIELD:
      return {
        ...state,
        ...{ value: action.payload },
      };
    case LOAD_DOCTORS:
      return {
        ...state,
        ...{ doctors: action.payload },
      };
    case FLUSH_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
