import React, { useState } from 'react';
import { Container, Expander, ExpanderContainer, ExpanderLine, Row, Spacer, Title, ExpandButton } from '../style';
import TextInput from '../../../uikit/TextInput';
import { connect } from 'react-redux';
import MedCardPageSlice from '../state';
import TextBlock from '../TextBlock';
import { patchPatientData } from '../operations';
import CitySelect from '../CitySelect';
import CountrySelect from '../CountrySelect';
import { RootState } from '../../../store';

const { patchDetails } = MedCardPageSlice.actions;

function ExtendedInfo({ isExpanded, handleExpand, medCardPageSlice }) {
  const {
    address_city,
    address_country,
    address_house,
    address_street,
    address_apartment,
    insurance_dms,
    insurance_oms,
    insurance_oms_organisation,
    insurance_dms_organisation,
    passport_date,
    passport_issuer,
    passport_number,
    id,
  } = medCardPageSlice.patientDetails;
  const patchCurrentPatientData = patchPatientData(id);

  const [isHovering, setHovering] = useState(false);
  return (
    <>
      <Container isExpanded={isExpanded}>
        <Row>
          <Title>Паспортные данные</Title>
          <div style={{ width: 230 }} />
          <Title>Адрес регистрации</Title>
          <div style={{ width: 293 }} />
          <Title>Страховой полис</Title>
        </Row>
        <div>
          <Row>
            <TextBlock
              name={'passport_number'}
              width={160}
              label={'Серия-Номер'}
              initialValue={passport_number}
              placeholder={'ххх-ххххх'}
              handleBlur={patchCurrentPatientData}
              InputComponent={TextInput}
            />
            <TextBlock
              type={'date'}
              name={'passport_date'}
              width={160}
              label={'Дата выдачи'}
              initialValue={passport_date}
              placeholder={'ДД.ММ.ГГГГ'}
              handleBlur={patchCurrentPatientData}
              InputComponent={TextInput}
            />
            <TextBlock
              name={'address_country'}
              width={188}
              label={'Страна'}
              initialValue={address_country}
              placeholder={'Россия'}
              handleBlur={patchCurrentPatientData}
              InputComponent={CountrySelect}
            />
            <TextBlock
              name={'address_city'}
              width={188}
              label={'Город'}
              initialValue={address_city}
              placeholder={'пример: Москва'}
              handleBlur={patchCurrentPatientData}
              InputComponent={CitySelect}
            />
            <Spacer />
            <TextBlock
              name={'insurance_oms'}
              width={230}
              label={'ОМС, Серия-Номер'}
              initialValue={insurance_oms}
              placeholder={'ххх'}
              handleBlur={patchCurrentPatientData}
              InputComponent={TextInput}
            />
            <TextBlock
              name={'insurance_oms_organisation'}
              width={286}
              label={'Страховая организация ОМС'}
              initialValue={insurance_oms_organisation}
              placeholder={'Название страховой организации'}
              handleBlur={patchCurrentPatientData}
              InputComponent={TextInput}
            />
          </Row>
          <Row>
            <TextBlock
              name={'passport_issuer'}
              width={344}
              label={'Паспорт выдан'}
              initialValue={passport_issuer}
              placeholder={'Укажите место выдачи паспорта'}
              handleBlur={patchCurrentPatientData}
              InputComponent={TextInput}
            />
            <TextBlock
              name={'address_street'}
              width={188}
              label={'Улица'}
              initialValue={address_street}
              placeholder={'Пример: “Ул. Мира”'}
              handleBlur={patchCurrentPatientData}
              InputComponent={TextInput}
            />

            <TextBlock
              name={'address_house'}
              width={113}
              label={'Дом'}
              initialValue={address_house}
              placeholder={'00 корп. 0'}
              handleBlur={patchCurrentPatientData}
              InputComponent={TextInput}
            />
            <div style={{ marginLeft: -16 }}>
              <TextBlock
                name={'address_apartment'}
                width={67}
                label={'Квартира'}
                initialValue={address_apartment}
                placeholder={'0000'}
                handleBlur={patchCurrentPatientData}
                InputComponent={TextInput}
              />
            </div>
            <Spacer />
            <TextBlock
              name={'insurance_dms'}
              width={230}
              label={'ДМС, Серия-Номер'}
              initialValue={insurance_dms}
              placeholder={'ххх'}
              handleBlur={patchCurrentPatientData}
              InputComponent={TextInput}
            />
            <TextBlock
              name={'insurance_dms_organisation'}
              width={287}
              label={'Страховая организация ДМС'}
              initialValue={insurance_dms_organisation}
              placeholder={'Название страховой организации'}
              handleBlur={patchCurrentPatientData}
              InputComponent={TextInput}
            />
          </Row>
        </div>
      </Container>
      <ExpanderContainer>
        <Expander>
          <ExpanderLine />
          <ExpandButton
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            onClick={handleExpand}
          >
            <img
              width="10px"
              src={isHovering ? './img/blue/left-arrow.svg' : './img/black/left-arrow.svg'}
              style={{
                transform: isExpanded ? 'rotate(90deg)' : 'rotate(-90deg)',
              }}
            />
          </ExpandButton>
          <ExpanderLine />
        </Expander>
      </ExpanderContainer>
    </>
  );
}

export default connect((state: RootState) => ({ medCardPageSlice: state.medCardPageSlice }), {
  setPatientDetails: MedCardPageSlice.actions.setPatientDetails,
  patchDetails,
})(ExtendedInfo);
