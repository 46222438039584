import React from 'react';
import { connect } from 'react-redux';
import { ButtonBlue } from '../styles';
import { getMessageText } from './helpers';
import { MessageContainer, Title, Text } from './styles';
import PriceState from '../state';
import { RootState } from '../../../store';

function EmptyMessage({ priceSlice, common, setFirstCategory }) {
  const { price, pickedCategory, response, categories, isLoading, createFirstCategory } = priceSlice;
  const canEdit = common.user.permissions.edit_price || common.user.role === 'SUP';
  // eslint-disable-next-line no-prototype-builtins
  const isCurrentCategoryEmpty = !price.hasOwnProperty(pickedCategory.id);
  const isPriceEmpty = response.length === 0;
  const isAllEntriesShown = pickedCategory.id === 'default';
  const isEmptyCategoryPicked = !canEdit && isCurrentCategoryEmpty && response.length === 0;
  const isEmptyPriceShown = isPriceEmpty && isAllEntriesShown;
  const messageVisibilitySwitch = isEmptyCategoryPicked || isEmptyPriceShown;

  const { title, body } = getMessageText(canEdit, isAllEntriesShown, categories);
  return (
    <>
      {messageVisibilitySwitch && !isLoading && !createFirstCategory && (
        <MessageContainer>
          <img src="./img/grey/empty.svg" />
          <Title>{title}</Title>
          {canEdit && categories.length === 1 ? (
            <ButtonBlue onClick={() => setFirstCategory(true)}>
              {body}
              <img src="./img/white/plus.svg" />
            </ButtonBlue>
          ) : (
            <Text>{body}</Text>
          )}
        </MessageContainer>
      )}
    </>
  );
}

export default connect((state: RootState) => ({ priceSlice: state.priceSlice, common: state.common }), {
  setFirstCategory: PriceState.actions.setCreateFirstCategory,
})(EmptyMessage);
