/* Нужно провести рефакторинг */

import lodash from "lodash";
import {
  startOfWeek,
  startOfMonth,
  addDays,
  isSameMonth,
  format,
  parse,
  differenceInCalendarMonths,
  addMonths,
  isFuture,
  isBefore,
  differenceInCalendarWeeks,
  isWithinInterval,
  isToday,
} from "date-fns";
import ruLocale from "date-fns/locale/ru";

const _ = lodash;

export function initArray(day) {
  return Array(42).fill(day);
}

export function computeDaysOfMonth(array) {
  let monthStart = startOfMonth(array[0]);
  let firstMonday = startOfWeek(monthStart, { weekStartsOn: 1 });
  return _.map(array, (item, index) => {
    let currentDay = addDays(firstMonday, index);
    return {
      day: {
        number: format(currentDay, "dd"),
        id: currentDay.getTime(),
      },
      type: !isSameMonth(monthStart, currentDay) ? 0 : 1,
      picked: false,
      cabinet: {},
      period: format(currentDay, "dd.MM"),
      filtered: false,
      filter_type: "none",
    };
  });
}
export const computeMonth = (start) => {
  let monthStart = startOfMonth(start);
  let firstMonday = startOfWeek(monthStart, { weekStartsOn: 1 });
  let array = new Array(42);
  let days = _.map(array, (item, index) => {
    let currentDay = addDays(firstMonday, index);
    return {
      day: {
        number: format(currentDay, "dd"),
        id: currentDay.getTime(),
      },
      type: !isSameMonth(monthStart, currentDay) ? 0 : 1,
      picked: false,
      cabinet: {},
      period: format(currentDay, "dd.MM"),
      filtered: false,
      filter_type: "none",
    };
  });
  return _.chunk(days, 7);
};

export function chunkByWeeks(days) {
  return _.chunk(days, 7);
}

export function initPeriod(start, end) {
  let delta = differenceInCalendarMonths(end, start);
  return _.map(Array(delta === 0 ? 1 : delta + 1).fill(0), (item, index) =>
    addMonths(start, index)
  );
}

export const loadCalendarMonth = _.flow([
  initArray,
  computeDaysOfMonth,
  chunkByWeeks,
]);

export const loadCalendarPeriod = (start, end) => initPeriod(start, end);

export const loadDays = (start, end) =>
  _.map(loadCalendarPeriod(start, end), loadCalendarMonth);

export const getMonthString = (day) => {
  let monthStart = startOfMonth(day);
  let monthString = format(monthStart, "MMMM yyyy", { locale: ruLocale });
  let formatedMonthString =
    monthString[0].toLocaleUpperCase() +
    monthString.slice(1, monthString.length);
  return formatedMonthString;
};
export const loadWeek = (days) => {
  let flatDays = _.flatten(days);
  let pickedDays = _.filter(flatDays, { picked: true });
  pickedDays = pickedDays.map((item) => parse(parseInt(item.day.id)));
  return _.chunk(pickedDays, 7);
};

export const checkIfWorking = (work_week, date) => {
  delete work_week["id"];
  delete work_week["owner"];
  work_week = Object.keys(work_week).map((item) => {
    if (work_week[item] != null) {
      return item.split("_")[0];
    } else {
      return false;
    }
  });
  work_week = _.filter(_.uniq(work_week), (o) => !!o);
  work_week = work_week.map((item) => {
    switch (item) {
      case "mn":
        return 1;
      case "tu":
        return 2;
      case "wd":
        return 3;
      case "th":
        return 4;
      case "fr":
        return 5;
      case "sa":
        return 6;
      case "su":
        return 0;
      default:
        return 0;
    }
  });
  return _.indexOf(work_week, date) !== -1;
};

export const isEven = (n) => {
  return n % 2 === 0;
};

export const isOdd = (n) => {
  return Math.abs(n % 2) === 1;
};

const OddEvenFilter = (odd, even, n) => {
  if (odd === true) {
    return isEven(n);
  } else if (even === true) {
    return isOdd(n);
  } else {
    return true;
  }
};

export const filterDates = (
  days,
  cabinet,
  end,
  start,
  weekDays,
  isOddDate,
  isEvenDate,
  dates
) => {
  //
  days = days.map((week) =>
    week.map((weeks) =>
      weeks.map((day) => {
        if (
          (isWithinInterval(parse(day.day.id), { start, end }) ||
            format(day.day.id, "dd.MM.yy") === format(start, "dd.MM.yy")) &&
          ((weekDays.indexOf(parseInt(format(parse(day.day.id), "d"))) !== -1 &&
            OddEvenFilter(
              isOddDate,
              isEvenDate,
              parseInt(format(day.day.id, "dd"))
            ) &&
            dates.exclude.indexOf(day.day.id) === -1) ||
            dates.include.indexOf(day.day.id) !== -1)
        ) {
          let odd = isOdd(parseInt(format(day.day.id, "dd")));
          let even = isEven(parseInt(format(day.day.id, "dd")));
          let picked = true;
          let special =
            dates.exclude.indexOf(day.day.id) !== -1 ||
            dates.include.indexOf(day.day.id) !== -1;
          let filtered = isOddDate ? true : isEvenDate ? true : false;
          let period =
            filtered === true &&
            special &&
            ((isOddDate && !even) || (isEvenDate && !odd))
              ? Math.random()
              : format(parse(day.day.id), "d");
          let filter_type = isOddDate ? "odd" : isEvenDate ? "even" : "none";
          day = {
            ...day,
            ...{ picked, period, cabinet, filtered, filter_type },
          };
        } else {
          let picked = false;
          day = {
            ...day,
            ...{ picked },
          };
        }
        return day;
      })
    )
  );
  return days;
};

export const filterAllDays = (days, cabinet, end, start) => {
  days = days.map((week) =>
    week.map((weeks) =>
      weeks.map((day) => {
        if (
          isWithinInterval(parse(day.day.id), { start, end }) ||
          format(day.day.id, "dd.MM.yy") === format(start, "dd.MM.yy")
        ) {
          let picked = true;
          let period = format(parse(day.day.id), "d");
          let filtered = false;
          let filter_type = "none";
          day = {
            ...day,
            ...{ picked, period, cabinet, filtered, filter_type },
          };
        }
        return day;
      })
    )
  );
  return days;
};

export const filterOddEvenDays = (odd, even, days, cabinet, end) => {
  const days_filter = odd ? isEven : even ? isOdd : () => true;
  days = days.map((week) =>
    week.map((weeks) =>
      weeks.map((day) => {
        if (
          (isFuture(parse(day.day.id)) || isToday(parse(day.day.id))) &&
          days_filter(parseInt(format(parse(day.day.id), "d"))) &&
          isBefore(parse(day.day.id), end)
        ) {
          let picked = true;
          let period = format(parse(day.day.id), "d");
          let filtered = true;
          let filter_type = odd ? "odd" : even ? "even" : "none";
          day = {
            ...day,
            ...{ picked, period, cabinet, filtered, filter_type },
          };
        } else if (!days_filter(parseInt(format(parse(day.day.id), "d")))) {
          let picked = false;

          day = { ...day, ...{ picked, cabinet } };
        }
        return day;
      })
    )
  );
  return days;
};

export const filterPeriodOfDays = (
  odd,
  even,
  days,
  page,
  cabinet,
  weekDays,
  end,
  start
) => {
  const days_filter = odd ? isEven : even ? isOdd : (arg) => true;

  days = days.map((week) =>
    week.map((weeks) =>
      weeks.map((day) => {
        if (
          weekDays.indexOf(parseInt(format(parse(day.day.id), "d"))) !== -1 &&
          (isFuture(parse(day.day.id)) || isToday(parse(day.day.id))) &&
          days_filter(parseInt(format(parse(day.day.id), "d"))) &&
          isWithinInterval(parse(day.day.id), { start, end })
        ) {
          let picked = true;
          let period = format(parse(day.day.id), "d");
          let filtered = odd || even;
          let filter_type = odd ? "odd" : even ? "even" : "none";
          day = {
            ...day,
            ...{ picked, period, cabinet, filtered, filter_type },
          };
        } else if (
          weekDays.indexOf(parseInt(format(parse(day.day.id), "d"))) === -1
        ) {
          let picked = false;

          day = { ...day, ...{ picked } };
        }
        return day;
      })
    )
  );
  return days;
};

export const prepHeading = (item) => {
  item = _.uniqBy(item, (el) => el.day.id);
  if (item.length > 1) {
    return (
      format(item[0].day.id, "dd", { locale: ruLocale }).toLocaleUpperCase() +
      " " +
      format(item[0].day.id, "dd ", { locale: ruLocale }) +
      format(item[0].day.id, "MMM", { locale: ruLocale }).slice(0, 3) +
      "." +
      " - " +
      format(item[item.length - 1].day.id, "dd ", { locale: ruLocale }) +
      format(item[item.length - 1].day.id, "MMM", { locale: ruLocale }).slice(
        0,
        3
      ) +
      "."
    );
  } else {
    return (
      format(item[0].day.id, "dd", { locale: ruLocale }).toLocaleUpperCase() +
      " " +
      format(item[0].day.id, "dd ", { locale: ruLocale }) +
      format(item[0].day.id, "MMM", { locale: ruLocale }).slice(0, 3) +
      "."
    );
  }
};

export const prepSubHeading = (item) => {
  item = _.uniqBy(item, (el) => el.day.id);

  let days_odd = _.filter(item, (date) => isOdd(parseInt(date.day.number)));
  let days_even = _.filter(item, (date) => isEven(parseInt(date.day.number)));

  if (days_even.length === item.length && item.length >= 2) {
    return "Четные";
  } else if (days_odd.length === item.length && item.length >= 2) {
    return "Нечетные";
  } else {
    return " ";
  }
};

let id = parseInt(Math.random() * 1000);

function findIfEmpty(arr, index, schedule) {
  let workdays = Object.values(schedule);
  workdays = _.flatten(workdays);
  let el = _.findIndex(
    workdays,
    (day) =>
      format(day.item.starts_at, "yyyy-MM-dd") ===
        format(arr[index].day.id, "yyyy-MM-dd") &&
      day.item.cabinet.id === arr[index].cabinet.id
  );
  return el !== -1
    ? format(workdays[el].starts_at, "HH:mm") +
        format(workdays[el].ends_at, "HH:mm")
    : false;
}
function findIfFull(vizits, tasks, day) {
  if (tasks && vizits) {
    let vis_arr = _.filter(
      Object.values(vizits),
      (item) =>
        format(item.starts_at, "yyyy-MM-dd") ===
          format(day.day.id, "yyyy-MM-dd") && item.cabinet === day.cabinet.name
    );
    let tasks_arr = _.filter(
      Object.values(tasks),
      (item) =>
        format(item.starts_at, "yyyy-MM-dd") ===
        format(day.day.id, "yyyy-MM-dd")
    );
    return vis_arr.length > 0 || tasks_arr.length > 0 ? day.day.id : false;
  } else {
    return false;
  }
}
function getId(arr, index, filtered, filter_type, schedule, vizits, tasks) {
  let w_id = findIfEmpty(arr, index, schedule);
  let isFull = findIfFull(vizits, tasks, arr[index]);

  let working = w_id;

  if (index === 0) {
    return id + working + isFull;
  } else if (filtered) {
    return id + working + isFull;
  } else if (
    differenceInCalendarWeeks(
      parse(arr[index].day.id),
      parse(arr[index - 1].day.id)
    ) > 1
  ) {
    id = parseInt(Math.random() * 1000);
    return id + working + isFull;
  } else {
    return id + working + isFull;
  }
}

function correctHeading(item) {
  switch (item.workday.length) {
    case 0:
      return item.heading;
    case 1:
      return (
        format(item.workday[0].starts_at, "dd", {
          locale: ruLocale,
        }).toLocaleUpperCase() +
        " " +
        format(item.workday[0].starts_at, "dd", { locale: ruLocale }) +
        format(item.workday[0].starts_at, "MMM", { locale: ruLocale }).slice(
          0,
          3
        ) +
        "."
      );
    default:
      return (
        format(item.workday[0].starts_at, "dd", {
          locale: ruLocale,
        }).toLocaleUpperCase() +
        " " +
        format(item.workday[0].starts_at, "dd ", { locale: ruLocale }) +
        format(item.workday[0].starts_at, "MMM", { locale: ruLocale }).slice(
          0,
          3
        ) +
        "." +
        " - " +
        format(item.workday[item.workday.length - 1].starts_at, "dd ", {
          locale: ruLocale,
        }) +
        format(item.workday[item.workday.length - 1].starts_at, "MMM", {
          locale: ruLocale,
        }).slice(0, 3) +
        "."
      );
  }
}

function findIfWorking(items, day) {
  return (
    _.findIndex(
      items,
      (item) =>
        format(item.day.id, "yyyy-MM-dd") ===
        format(day.item.starts_at, "yyyy-MM-dd")
    ) !== -1
  );
}

export const selectWorkdays = (days, doctor, schedule, vizits, tasks, len) => {
  let flat_days = _.flattenDeep(days);
  let picked_days = _.filter(flat_days, { type: 1 });

  picked_days = _.filter(picked_days, { picked: true });
  //
  picked_days = _.sortBy(picked_days, (item) => format(item.day.id, "d"));
  picked_days = picked_days.map((curr, index) => {
    if (String(curr.period).length === 1) {
      let info = getId(
        picked_days,
        index,
        curr.filtered,
        curr.filter_type,
        schedule,
        vizits,
        tasks
      );
      curr = { ...curr, ...{ period: curr.period + info } };
    }
    return curr;
  });

  picked_days = _.groupBy(picked_days, "period");
  picked_days = _.values(picked_days);
  let workdays = schedule;

  let table_body = picked_days.map((item) => {
    return {
      heading: prepHeading(item),
      subheading: item[0].cabinet.name,
      subheading_2: prepSubHeading(item),
      date: parse(item[0].day.id),
      workday: workdays.hasOwnProperty(item[0].cabinet.name)
        ? workdays[item[0].cabinet.name]
            .filter(
              (day) =>
                findIfWorking(item, day) &&
                item[0].cabinet.id === day.item.cabinet.id
            )
            .map((item) => item.item)
        : [],
      dates: item,
      cabinet: item[0].cabinet,
      doctor: doctor,
    };
  });
  table_body = lodash.sortBy(table_body, ["date"]);
  //table_body = lodash.sortBy(table_body, day => format(day.date, "E"));
  table_body = table_body.map((item) => {
    item.heading = correctHeading(item);
    return item;
  });
  let chunk_size = parseInt((window.innerWidth - 250 - 55) / 160);
  return _.chunk(table_body, chunk_size);
};
