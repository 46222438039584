import React, { Dispatch, SetStateAction } from 'react';
import Tabs from '@material-ui/core/Tabs';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { MedcardEntry } from '../../../services/medcard';
import { SubInvoice } from '../../../services/invoice';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
import { SubInvoiceTab } from './SubInvoiceTab';

const tabsStyles = makeStyles(() =>
  createStyles({
    indicator: {
      display: 'none',
    },
    root: {
      borderBottom: '1px solid #e9e9e9',
      overflow: 'unset',
      // display: 'inline-block',
      minHeight: 40,
      boxSizing: 'border-box',
    },
    scrollButtons: {
      width: 40,
      background: '#FFFFFF',
      '&:last-of-type': {
        boxShadow: 'inset 1px 0 0 #E8E8E8',
      },
      '&:first-of-type': {
        boxShadow: '1px 0 0 #E8E8E8',
      },
      '&.Mui-disabled': {
        display: 'none',
      },
    },
    scroller: {
      marginBottom: '-1px !important',
    },
  }),
);

const tabItemStyles = makeStyles(() =>
  createStyles({
    root: {
      border: '1px solid #e9e9e9',
      '&:first-of-type': {
        marginLeft: 24 + 8,
      },
      background: '#FAFAFA',
      opacity: 1,
      outline: 'none !important',
      marginLeft: 8,
      minHeight: 40,
      textTransform: 'none',
      borderRadius: '8px 8px 0 0',
      maxWidth: 'max-content',
      padding: '0 12px',
    },
    selected: {
      borderBottom: '1px solid #ffffff',
      background: '#ffffff',
      '& $wrapper': {
        color: '#577BF9',
      },
    },
    wrapper: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#515D6B',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  }),
);

type Props = {
  subInvoices: SubInvoice[];
  expandedId: number;
  setExpanded: ActionCreatorWithNonInferrablePayload;
  entries: MedcardEntry[];
  patientId: number;
  currentDateId: number;
  setCurrentSubInvoiceId: Dispatch<SetStateAction<number>>;
};

export const SubInvoiceTabs = ({ subInvoices, expandedId, setExpanded, entries, setCurrentSubInvoiceId }: Props) => {
  const tabsClasses = tabsStyles();
  const tabItemClasses = tabItemStyles();

  return (
    <Tabs classes={tabsClasses} value={expandedId} variant="scrollable" scrollButtons="auto">
      {subInvoices.map((inv) => {
        const toothState = entries.find((e) => e.id === inv.formula_id);
        return (
          <SubInvoiceTab
            key={inv.formula_id}
            inv={inv}
            tabItemClasses={tabItemClasses}
            toothState={toothState}
            setExpanded={setExpanded}
            setCurrentSubInvoiceId={setCurrentSubInvoiceId}
          />
        );
      })}
    </Tabs>
  );
};
