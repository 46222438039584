import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL, HttpMethod } from '../utils/request';

export type AdvanceOperationType = 'INC' | 'EXP' | 'RET';

export type Advance = {
  id: number;
  operation_date: string;
  operation_type: AdvanceOperationType;
  payment: number;
  patient: number;
  payment_type: number;
  payer: number;
  paymaster: number;
};
export type ExpandedAdvance = Advance & {
  remainder: number;
};

export enum AdvanceOperation {
  INCOME = 'INC',
  EXPENSE = 'EXP',
  RETURN = 'RET',
}

export const advanceApi = createApi({
  reducerPath: 'advanceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['Advances', 'AdvancesTotal'],
  endpoints: (builder) => ({
    getAdvanceTotal: builder.query<number, number>({
      query: (patientId) => `patient/${patientId}/advances/total`,
      transformResponse: (response: { total_payment: number }) => response.total_payment,
      providesTags: ['AdvancesTotal'],
    }),

    getAdvances: builder.query<ExpandedAdvance[], number>({
      query: (patientId) => `patient/${patientId}/advances`,
      transformResponse: (response: Advance[]) => {
        const getRemainderValue = (itemIndex: number) => {
          const previousAdvances = response.slice(0, itemIndex + 1);
          const result = previousAdvances.reduce((sum, acc) => {
            if (acc.operation_type === AdvanceOperation.INCOME) {
              return sum + Number(acc.payment);
            }
            return sum - Number(acc.payment);
          }, 0);
          return result;
        };
        const transformedAdvances = response.map((res, index) => ({
          ...res,
          payment: +res.payment,
          remainder: getRemainderValue(index),
        }));
        return transformedAdvances.reverse();
      },
      providesTags: [{ type: 'Advances', id: 'LIST' }],
    }),
    addAdvance: builder.mutation<Advance, { patientId: number; record: Partial<Advance> }>({
      query: (data) => {
        const { patientId, record } = data;
        return {
          url: `patient/${patientId}/advances`,
          method: HttpMethod.POST,
          body: record,
        };
      },
      invalidatesTags: [{ type: 'Advances', id: 'LIST' }, 'AdvancesTotal'],
    }),
  }),
});

export const { useGetAdvanceTotalQuery, useGetAdvancesQuery, useAddAdvanceMutation } = advanceApi;
