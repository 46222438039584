import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const CustomTooltip = withStyles(() => ({
  popper: { position: 'absolute', zIndex: 99999999 },
  arrow: {
    color: ({ light }) => (light ? '#FFFFFF' : '#253446'),
  },
  tooltip: {
    backgroundColor: ({ light }) => (light ? '#FFFFFF' : '#253446'),
    border: ({ light }) => (light ? '1px solid #E5E6EA' : ''),
    maxWidth: ({ tooltipWidth }) => (tooltipWidth ? `${tooltipWidth}px` : 'unset'),
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: ({ light }) => (light ? '#253446' : '#FFFFFF'),
  },
}))(Tooltip);

export function OnTopTooltip({ isShown = false, light = false, tooltipWidth = null, children, ...props }) {
  return (
    <>
      {isShown ? (
        <CustomTooltip open={isShown} light={light} tooltipWidth={tooltipWidth} {...props}>
          {children}
        </CustomTooltip>
      ) : (
        children
      )}
    </>
  );
}
