import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

// import {DevTool} from "@hookform/devtools";
import { useDispatch, useSelector } from 'react-redux';
import Body from '../Body';
import { pickBy, identity, isEmpty, values, some, debounce } from 'lodash';
import Header from '../Header';
import { FormErrorsContext } from '../../../index';
import request from '../../../../../../../utils/request';
import TextInput from '../../../../../../../uikit/TextInput';
import ErrorPopup from '../../../../../../../components/ErrorPopup';
import { getMask } from '../../../../../../../utils/masks';

import SuggestionsState from '../../../../../../../components/Suggestions/state';
import WithSuggestions from '../../../../../../../components/Suggestions/WithSuggestions';
import { RootState } from '../../../../../../../store';
import { ErrorMessage, HeaderForm, TableRow } from './styles';
import { IPatientTableProps } from './types';
import { getUniqueStrings, handleSearchPatient } from './helpers';
import { Phone } from '../../../../../../../uikit/Phone';

const MENU_STREAM = 'streamSuggestions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function PatientTable({
  isFullView,
  patient,
  setPatient,
  modalView,
  firstLoad,
  setFirstLoad,
  editableFields = undefined,
  handleFieldEdit = undefined,
  editiableFieldsValues = undefined,
}: IPatientTableProps) {
  const { formState, setValue, getValues, control } = useFormContext();
  const submitButton = useRef<HTMLButtonElement>(null);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [codeDisabled, setCodeDisabled] = useState(false);

  const [tempPatientData, setTempPatientData] = useState({
    birth_date: '',
    email: '',
    stream: '',
  });

  const [patientSelected, setPatientSelected] = useState(false);

  const { formErrors, setFormErrors } = useContext(FormErrorsContext);

  const user = useSelector((state: RootState) => state.common.user);
  const currentPatient = useSelector((state: RootState) => state.appointmentForm.patient);

  const dispatch = useDispatch();

  const { openMenu, closeMenu } = SuggestionsState.actions;

  const onSubmit = (data) => setPatient(pickBy(data, identity));

  const searchPatient = handleSearchPatient(
    getValues,
    setValue,
    setCodeDisabled,
    formErrors,
    setFormErrors,
    setTempPatientData,
    tempPatientData,
    onSubmit,
  );

  const triggerSubmit = debounce(searchPatient, 500);

  useEffect(() => {
    const fetchData = async () => {
      const patients = await request.clinic.load_patients(user.clinic);
      setSuggestions(getUniqueStrings(patients.data.items.map((p) => p.stream).filter((s) => !!s)));
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const searchPatient = await request.clinic.search_patient({
        ...patient,
        clinic: user.clinic.id,
      });

      // Для плавности анимации показывать лоадер через секунду
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (!(searchPatient && searchPatient.status === 200)) {
          setIsLoading(true);
        }
      }, 1000);

      setData(searchPatient.data);
      setIsLoading(false);
    };

    if (modalView && !firstLoad) {
      setPatient(currentPatient);
      setData([currentPatient]);
      setPatientSelected(true);
      setFirstLoad(true);
    } else {
      if (isEmpty(patient)) {
        setData([]);
      } else {
        if (!patientSelected) {
          fetchData();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient, firstLoad]);

  useEffect(() => {
    for (const fieldName in tempPatientData) {
      setValue(fieldName, isFullView ? tempPatientData[fieldName] : '');
    }
    if (some(isFullView ? tempPatientData : patient, identity) && submitButton.current) {
      onSubmit(getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullView]);

  return (
    <>
      {patientSelected ? (
        <Header
          isFullView={isFullView}
          setPatient={setPatient}
          setData={setData}
          setPatientSelected={setPatientSelected}
          setCodeDisabled={setCodeDisabled}
        />
      ) : (
        <HeaderForm>
          <Controller
            render={({ field: { onChange } }) => (
              <TextInput
                placeholder="00000"
                error={formState.errors.id}
                style={{ height: '32px', width: '57px', marginRight: '16px' }}
                disabled={codeDisabled}
                onChange={(e) => {
                  onChange(e.target.value);
                  triggerSubmit('id');
                }}
              />
            )}
            name={'id'}
            control={control}
          />
          <div style={{ position: 'relative' }}>
            <Controller
              render={({ field: { onChange } }) => (
                <TextInput
                  placeholder="Фамилия"
                  error={formErrors.patient.last_name || formErrors.patient.not_unique.last_name}
                  style={{ height: '32px', width: '144px', marginRight: '16px' }}
                  onChange={(e) => {
                    onChange(e.target.value);
                    triggerSubmit('last_name');
                  }}
                />
              )}
              name={'last_name'}
              control={control}
            />
            <ErrorPopup isShown={formErrors.patient.last_name} style={{ bottom: -35 }} />
          </div>
          <div style={{ position: 'relative' }}>
            <Controller
              render={({ field: { onChange } }) => (
                <TextInput
                  placeholder="Имя"
                  error={formErrors.patient.first_name || formErrors.patient.not_unique.first_name}
                  style={{ height: '32px', width: '144px', marginRight: '16px' }}
                  onChange={(e) => {
                    onChange(e.target.value);
                    triggerSubmit('first_name');
                  }}
                />
              )}
              name={'first_name'}
              control={control}
            />
            <ErrorPopup isShown={formErrors.patient.first_name} style={{ bottom: -35 }} />
          </div>
          <Controller
            render={({ field: { onChange } }) => (
              <TextInput
                placeholder="Отчество"
                error={formErrors.patient.not_unique.second_name}
                style={{ height: '32px', width: '144px', marginRight: '16px' }}
                onChange={(e) => {
                  onChange(e.target.value);
                  triggerSubmit('second_name');
                }}
              />
            )}
            name={'second_name'}
            control={control}
          />
          {isFullView && (
            <div style={{ position: 'relative' }}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    placeholder="дд.мм.гг"
                    value={value}
                    error={formErrors.patient.invalid.birth_date || formErrors.patient.not_unique.birth_date}
                    style={{ height: '32px', width: '87px', marginRight: '16px' }}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setValue('birth_date', getMask('birth_date').resolve(e.target.value));
                      triggerSubmit('birth_date');
                    }}
                  />
                )}
                name={'birth_date'}
                control={control}
              />
              <ErrorPopup
                style={{ bottom: -35, width: '100%' }}
                messageStyle={{ width: 'unset', height: 'unset', padding: '7px 15px', textAlign: 'center' }}
                isShown={formErrors.patient.invalid.birth_date || formErrors.patient.not_unique.birth_date}
                text={formErrors.patient.invalid.birth_date ? 'Неверная дата' : undefined}
              />
            </div>
          )}
          <div style={{ position: 'relative' }}>
            <Controller
              render={({ field: { onChange } }) => (
                <div style={{ height: '32px', width: '142px', marginRight: '16px' }}>
                  <Phone
                    error={
                      formErrors.patient.phone ||
                      formErrors.patient.not_unique.phone ||
                      formErrors.patient.invalid.phone
                    }
                    onChange={(value, code) => () => {
                      onChange(value ? `+${code} ${value}` : undefined);
                      setValue('phone', value ? `+${code} ${value}` : undefined);
                      triggerSubmit('phone');
                    }}
                    placeholder="(ххх) ххх хх хх"
                  />
                </div>
              )}
              name={'phone'}
              control={control}
            />
            <ErrorPopup
              style={{ bottom: -35 }}
              isShown={formErrors.patient.phone || formErrors.patient.invalid.phone}
              text={formErrors.patient.phone ? undefined : 'Неверный номер'}
            />
          </div>
          {isFullView && (
            <div style={{ position: 'relative' }}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    placeholder="e-mail@gmail.com"
                    value={value}
                    error={formErrors.patient.invalid.email}
                    style={{ height: '32px', width: '133px', marginRight: '16px', marginLeft: '16px' }}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setValue('email', getMask('email').resolve(e.target.value));
                      triggerSubmit('email');
                    }}
                  />
                )}
                name={'email'}
                control={control}
              />
              <ErrorPopup style={{ bottom: -35 }} isShown={formErrors.patient.invalid.email} text={'Неверная почта'} />
            </div>
          )}
          {isFullView && (
            <WithSuggestions
              name="streamSuggestions"
              suggestions={suggestions}
              value={getValues('stream') || ''}
              handlePick={(value: string) => {
                setValue('stream', value);
                triggerSubmit('stream');
              }}
              canPreventDefault
              // Чтоб меню не закрывалось при вводе текста
              onClickAway={() => dispatch(openMenu(MENU_STREAM))}
            >
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    placeholder="Канал привлечения"
                    value={value}
                    style={{ height: '32px', width: '148px', marginRight: '16px' }}
                    onFocus={() => dispatch(openMenu(MENU_STREAM))}
                    // // Задержка закрытия, что успел отработать handlePick
                    onBlur={() => setTimeout(() => dispatch(closeMenu(MENU_STREAM)), 250)}
                    onChange={(e) => {
                      onChange(e.target.value);
                      triggerSubmit('stream');
                    }}
                  />
                )}
                name={'stream'}
                control={control}
              />
            </WithSuggestions>
          )}
          <Controller
            render={({ field: { onChange } }) => (
              <TextInput
                placeholder="Важная информ."
                style={{ height: '32px', width: '144px', marginRight: '16px' }}
                onChange={(e) => {
                  onChange(e.target.value);
                  triggerSubmit('commentary');
                }}
              />
            )}
            name={'commentary'}
            control={control}
          />
        </HeaderForm>
      )}
      {isEmpty(data) && !isLoading ? (
        <TableRow>Для создания нового пациента заполните поля и нажмите “Сохранить”</TableRow>
      ) : (
        <Body
          isFullView={isFullView}
          data={data}
          isLoading={isLoading}
          patient={patient}
          setPatient={setPatient}
          patientSelected={patientSelected}
          setPatientSelected={setPatientSelected}
          editableFields={editableFields}
          handleFieldEdit={handleFieldEdit}
          editiableFieldsValues={editiableFieldsValues}
        />
      )}
      {some(values(formErrors.patient.not_unique), (f) => !!f) && (
        <ErrorMessage>
          Есть совпадения с базой пациентов. Выберите пациента из базы или введите дату рождения для сохранения нового
          пациента
        </ErrorMessage>
      )}
      {/*{process.env.NODE_ENV === 'development' && <DevTool control={control}/>}*/}
    </>
  );
}
