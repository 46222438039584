import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { endpoints, useCleanupMutation, useGetSubInvoicesQuery } from '../../../services/invoice';
import { EAccountStatus } from '../Account/AccountStatus';
import { EAccountView } from './JornalAccount';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
import { MedcardDate, MedcardEntry } from '../../../services/medcard';
import { Account, TotalAccount } from '../Account';
import { PaymentAccount } from '../Account/PaymentAccount';

type Props = {
  isPayment: boolean;
  accountVisible: boolean;
  expandedId: number;
  setExpanded: ActionCreatorWithNonInferrablePayload;
  setAccountVisible: (visible: boolean) => void;
  entries: MedcardEntry[];
  currentDate: MedcardDate;
  onStopEdit: VoidFunction;
};

export function JornalAccountContent({
  isPayment,
  accountVisible,
  expandedId,
  setExpanded,
  setAccountVisible,
  onStopEdit,
  entries,
  currentDate,
}: Props) {
  const [accountView, setAccountView] = useState<EAccountView>(isPayment ? EAccountView.PAYMENT : EAccountView.PLAIN);
  const [completedForPayment, setCompletedForPayment] = useState<boolean>(false);

  const patient = useSelector((state: RootState) => state.medCardPageSlice.patientDetails);

  const { data: invoices } = endpoints.getInvoices.useQueryState({
    patientId: patient.id,
    dateId: currentDate?.id,
  });

  const { data: subInvoices = [] } = useGetSubInvoicesQuery(invoices?.[0]?.id, { skip: !invoices?.[0]?.id });

  const [cleanupInvoice] = useCleanupMutation();

  useEffect(() => {
    if (accountVisible && !completedForPayment && !isPayment) {
      if (
        subInvoices.find(
          (inv) =>
            inv.status === EAccountStatus.PENDING_PAYMENT ||
            inv.status === EAccountStatus.DEBT ||
            inv.status === EAccountStatus.PAID,
        )
      ) {
        setAccountView((prevState) => (prevState === EAccountView.PAYMENT ? prevState : EAccountView.TOTAL));
        setCompletedForPayment(true);
      }

      if (subInvoices.find((inv) => inv.status === EAccountStatus.FILLED)) {
        setCompletedForPayment(false);
      }

      if (subInvoices.find((inv) => inv.status === EAccountStatus.EDIT)) {
        setCompletedForPayment(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountVisible, subInvoices]);

  useEffect(() => {
    if (!accountVisible) {
      if (!completedForPayment && !isPayment) {
        cleanupInvoice(invoices?.[0]?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountVisible]);

  if (accountView === EAccountView.PLAIN) {
    return (
      <Account
        subInvoices={subInvoices}
        expandedId={expandedId}
        setExpanded={setExpanded}
        entries={entries}
        currentDateId={currentDate?.id}
        setTotalView={() => setAccountView(EAccountView.TOTAL)}
      />
    );
  }

  if (accountView === EAccountView.TOTAL) {
    return (
      <TotalAccount
        entries={entries}
        invoiceId={invoices?.[0]?.id}
        currentDate={currentDate}
        setAccountVisible={setAccountVisible}
        setAccountView={setAccountView}
        setCompletedForPayment={setCompletedForPayment}
        onStopEdit={onStopEdit}
      />
    );
  }

  if (accountView === EAccountView.PAYMENT) {
    return (
      <PaymentAccount
        setAccountVisible={setAccountVisible}
        setPlainView={() => setAccountView(EAccountView.PLAIN)}
        currentDateId={currentDate?.id}
        onStopEdit={onStopEdit}
      />
    );
  }

  return null;
}
