import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import styled from "styled-components";
import { connect } from "react-redux";
import SuggestionsState from "./state";
import { startsWith, toLower } from "ramda";
import { Scrollbars } from "react-custom-scrollbars-2";

const Dialog = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04),
    0px 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  z-index: 17;
`;

const Suggestion = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #515d6b;
  padding-left: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  flex: 0;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #f6f6fa;
  }
`;

function WithSuggestions({
  children,
  suggestionsMenu: { isOpened, menuId },
  closeMenu,
  suggestions,
  handlePick,
  value,
  name,
  canPreventDefault = false,
  onClickAway = () => {},
}: any) {
  const filteredSuggestions = suggestions.filter((item: any) =>
    startsWith(toLower(value), toLower(item))
  );
  const isSuggestionsShown =
    isOpened && menuId === name && filteredSuggestions.length > 0;
  return (
    <>
      <ClickAwayListener
        onClickAway={(e) => {
          if (!canPreventDefault) {
            e.preventDefault();
          }
          e.stopPropagation();
          isSuggestionsShown && closeMenu();
          isSuggestionsShown && onClickAway();
        }}
      >
        <div>
          {children}
          {isSuggestionsShown && (
            <div style={{ position: "relative" }}>
              <Dialog>
                <Scrollbars
                  style={{
                    width: "100%",
                    height:
                      filteredSuggestions.length <= 5
                        ? 32 * filteredSuggestions.length
                        : 160,
                  }}
                >
                  {filteredSuggestions.map((item: any) => (
                    <Suggestion
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handlePick(item);
                        closeMenu();
                      }}
                    >
                      {item}
                    </Suggestion>
                  ))}
                </Scrollbars>
              </Dialog>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
}

export default connect(
  (state: any) => ({
    suggestionsMenu: state.suggestionsMenu,
  }),
  { closeMenu: SuggestionsState.actions.closeMenu }
)(WithSuggestions);
