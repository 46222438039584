import React from 'react';
import NewItemRow from './NewItemRow';
import Entry from './Entry';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
function PriceEntry({ priceSlice, item, groupIndex, itemIndex, arr, editor, onStartEdit, onStopEdit }) {
  return priceSlice.editingEntryID === item.id ? (
    <NewItemRow item={item} index={groupIndex + itemIndex} onStopEdit={onStopEdit} />
  ) : (
    <Entry
      item={item}
      index={groupIndex}
      itemIndex={itemIndex}
      length={arr.length}
      editor={editor}
      onStartEdit={onStartEdit}
      onStopEdit={onStopEdit}
    />
  );
}

export default connect((state: RootState) => ({ priceSlice: state.priceSlice }), {})(PriceEntry);
