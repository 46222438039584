import React from 'react';
import styled from 'styled-components';

interface TextArea extends React.ComponentPropsWithoutRef<'textarea'> {
  error?: boolean;
  transparent?: boolean;
  autoSelect?: boolean;
  textAlign?: string;
  multiline?: boolean;
  underlined?: boolean;
}

const TextareaWithBorder = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0;
  background: #ffffff;
  border: 1px solid rgba(211, 214, 218, 0.5);
  border-radius: 4px;
  color: #515d6b;
  outline: none;
  padding-left: 8px;
  &:hover {
    border: 1px solid #577bf9;
  }
  &:focus {
    border: 1px solid #4555e4;
    box-shadow: 0px 0px 4px rgba(87, 123, 249, 0.5);
  }
  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #d3d6da;
  }
`;

// eslint-disable-next-line react/display-name
export default React.forwardRef<HTMLTextAreaElement, TextArea>((props, ref) => {
  const { autoSelect } = props;
  return (
    <TextareaWithBorder
      ref={ref}
      autoComplete="off"
      autoFocus={autoSelect}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onFocus={(e: any) => {
        autoSelect && e.target.select();
      }}
      {...props}
    />
  );
});
