import React, { FC } from 'react';
import styled from 'styled-components';
import { OptionsMenu } from './OptionsMenu';
import { VisitComments } from '../../../widgets/VisitComments';

type TotalType = {
  totalName: string;
  paymentType: string;
  paid: number;
  remainder: number;
  payment: number;
  customStyle?: boolean;
  handleScrollTop?: () => void;
  invoiceId?: number;
  commentFormHeight?: number;
  isDataLoading?: boolean;
};

type BoxProps = {
  width?: number;
  minWidth?: number;
  padding?: string;
};

type CustomProps = {
  customStyle?: boolean;
  color?: string;
};

const Box = styled.div<BoxProps>`
  flex: ${({ width }) => `0 1 ${width}px`};
  min-width: ${({ minWidth }) => minWidth}px;
  padding: ${({ padding }) => (padding ? padding : '0 12px')};
`;

const Container = styled.div`
  height: 48px;
  padding: 0 12px;
  position: relative;
  :before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 12px;
    right: 12px;
    height: 1px;
    background: #a8aeb5;
  }
  &.active {
    :before {
      height: 100%;
      box-shadow: inset 0 0 0 1px #577bf9;
      inset: 0;
      background: transparent;
    }
  }
`;

const DataContainer = styled.div<CustomProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
  font-size: ${({ customStyle }) => (customStyle ? '12px' : '14px')};
  font-weight: 400;
  color: #515d6b;
`;

const Cell = styled.span<CustomProps>`
  font-weight: ${({ customStyle }) => (customStyle ? '400' : '500')};
  color: ${({ color }) => (color ? color : '#515D6B')};
`;

const TotalTitle = styled.div<CustomProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: ${({ customStyle }) => (customStyle ? '400' : '500')};
  color: ${({ customStyle }) => (!customStyle ? '#253446' : '')};
  width: 100%;
`;

export const TotalData: FC<TotalType> = ({
  totalName,
  paymentType,
  paid,
  remainder,
  payment,
  customStyle,
  handleScrollTop,
  invoiceId,
  commentFormHeight,
  isDataLoading = false,
}) => (
  <Container>
    <DataContainer customStyle={customStyle}>
      <Box width={740} minWidth={128}>
        <TotalTitle customStyle={customStyle}>{totalName}:</TotalTitle>
      </Box>
      <Box width={74} minWidth={73} padding={'0 12px 0 10px'}>
        <Cell customStyle={customStyle}>{isDataLoading ? <div className="spinner small" /> : payment}</Cell>
      </Box>
      <Box width={172} minWidth={156}>
        {paymentType}
      </Box>
      <Box width={132} minWidth={121} />
      <Box width={76} minWidth={76}>
        {isDataLoading ? <div className="spinner small" /> : paid}
      </Box>
      <Box width={72} minWidth={72}>
        <Cell customStyle={customStyle} color={remainder < 0 ? '#C3000A' : '#515D6B'}>
          {isDataLoading ? <div className="spinner small" /> : remainder}
        </Cell>
      </Box>
      <Box width={54} minWidth={54} padding={'0 12px 0 4px'}>
        <OptionsMenu handleScrollTop={handleScrollTop} invoiceId={invoiceId} />
      </Box>
      <VisitComments commentFormHeight={commentFormHeight} invoiceId={invoiceId} scrollTop={handleScrollTop} />
    </DataContainer>
  </Container>
);
