import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  visible: false,
  videoUrl: '',
};

const StudyModalStateSlice = createSlice({
  name: 'StudyModalState',
  initialState: INITIAL_STATE,
  reducers: {
    show: (state, action) => ({
      ...state,
      visible: true,
      videoUrl: action.payload,
    }),
    hide: (state) => ({
      ...state,
      visible: false,
      videoUrl: '',
    }),
  },
});

export default StudyModalStateSlice;
