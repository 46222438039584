import React from "react";
import styled from "styled-components";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {SearchHeader} from "../SearchHeader";
import Column from "../../../PatientPage/components/Column";
import {DiagnosisMKBTemplate} from "./DiagnosisMKBTemplates";
import {Card, CardContent, CardHeader, ClickAwayListener} from "@material-ui/core";
import {Scrollbars} from "react-custom-scrollbars-2";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      root: (props: {isFocused: boolean}) => ({
        width: 650,
        marginBottom: 16,
        border: props.isFocused ? '1px solid #577BF9' : 0,
        boxSizing: 'border-box',
        boxShadow: 'none',
        borderRadius: 4,
      }),
      header: {
        background: '#F9F9F9',
        padding: 0
      },
      headerContent: {
        display: 'flex',
        flex: 1,
        padding: '0 16px',
        borderBottom: '2px solid #7C8590'
      },
      content: {
        padding: '0 16px',
        '&:last-child': {
          paddingBottom: 0
        }
      },
    },
  ));

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: white;
  align-items: center;
  border-bottom: 1px solid #D9D9D9;
  &:last-child {
    border-bottom: 0;
  }
  cursor: pointer;
`;

type Props = {
  data: DiagnosisMKBTemplate[];
  isFocused: boolean;
  onDeleteTemplate: Function;
  setFocus: Function;
}

export function DiagnosisMKBJornalField({data, isFocused, onDeleteTemplate, setFocus}: Props) {
  const classes = useStyles({isFocused});
  return (
    <Card className={classes.root}>
      <CardHeader className={classes.header} onClick={setFocus} title={
        <div className={classes.headerContent}>
          <SearchHeader
            type="text"
            placeholder="Код"
            name="code"
            width={86}
            text="Код"
            noBorder
            searchable={false}
          />
          <SearchHeader
            type="text"
            placeholder="Диагноз"
            name="text"
            text="Диагноз"
            noBorder
            searchable={false}
          />
        </div>
      }/>
      <Scrollbars
        autoHeight
        autoHeightMax={600}
        style={{
          width: "100%",
        }}
      >
        <CardContent className={classes.content}>
          {data.map(template => (
            <Row onClick={() => onDeleteTemplate(template)}>
              <Column
                name="code"
                defaultValue={template.code}
                width={86}
                isEditing={false}
                hasAction
              >
                {template.code}
              </Column>
              <Column
                name="text"
                defaultValue={template.text}
                isEditing={false}
                hasAction
              >
                {template.text}
              </Column>
            </Row>
          ))}
        </CardContent>
      </Scrollbars>
    </Card>
  )
}
