export enum Visit {
  ON_TREATMENT = 'INP',
  CONFIRMED = 'CNF',
  NOT_CONFIRMED = 'SCH',
  IN_CLINIC = 'ARR',
  NO_PAYMENT = 'ABS',
  CANCELED = 'CAN',
  COMPLETED = 'COM',
}
export enum InvoiceStatus {
  ACCOUNT_DEBT = 'DEBT',
  PAID = 'PAID',
  FOR_PAYMENT = 'PENDING_PAYMENT',
  IN_PROGRESS = 'PAYMENT_IN_PROGRESS',
  ON_EDITING = 'ON_EDITING',
}

export enum Totals {
  COMBINED_TYPE = 'Комбинированный',
  TOTAL_INVOICE = 'Итого',
  TOTAL_VISIT = 'Итого к оплате',
}

export enum ModalType {
  PAYMENT_DETAILS = 'payment_details',
  COMMENTS = 'comments',
}
