import styled from 'styled-components';

export const Blinds = styled.div`
  position: absolute;
  display: flex;
  row-gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  z-index: 1201;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(7px);
`;

export const Window = styled.div`
  position: relative;
  width: 456px;
  display: grid;
  row-gap: 24px;
  padding: 24px;
  grid-template-columns: repeat(4, 1fr);
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 1202;
  ::-webkit-scrollbar {
    width: 8px; /* ширина для вертикального скролла */
  }

  /* ползунок скроллбара */
  ::-webkit-scrollbar-thumb {
    background: #e5e6ea;
    border-radius: 8px;
  }
`;

export const Title = styled.h5<{ active?: boolean }>`
  font-weight: normal;
  grid-column: 1 / 3;
  font-size: 14px;
  color: ${({ active = true }) => (active ? '#253446' : '#7C8590')};
`;

export const FormatsWrap = styled.div`
  grid-row: 2 / 2;
  grid-column: 1 / 5;

  & > div {
    margin-right: 0px;
  }
`;

export const ButtonsWrap = styled.div`
  display: flex;
  grid-row: 3 / 3;
  grid-column: 3 / 5;

  & > button {
    position: relative;
    right: 11px;
  }
`;

export const Data = styled.div`
  grid-row: 2 / 2;
  grid-column: 1 / 5;
  display: grid;
  row-gap: 12px;
`;
export const DataContent = styled.div`
  display: grid;
  font-size: 14px;
  grid-template-columns: 1fr 2fr;
  font-weight: 400;
  color: #515d6b;
`;

export const DataTitle = styled.span`
  color: #7c8590;
`;
