import React, { FC, useMemo, useState, useEffect } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import {
  Selector,
  SelectedOption,
  SelectedInput,
  OptionsContainer,
  Option,
  SearchInput,
  Circle,
  Divider,
  Search,
  Category,
  NotFound,
} from './styles';
import Scrollbars from 'react-custom-scrollbars-2';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '../../../CalendarPage/modals/VisitCard/icons/SearchIcon';
import { ClearIcon } from '../../../../uikit/Icons';

interface ValuesState {
  id: number;
  name: string;
  category?: string;
}

interface MultiSelectProps {
  placeholder?: string;
  searchPlaceholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pickedValues?: any;
  width?: number;
  statuses?: boolean;
  search?: boolean;
  name: string;
  optionName: string;
  category?: string;
  onChange: (value: ValuesState[]) => void;
  singleSelect?: boolean;
}

const MultiSelect: FC<MultiSelectProps> = ({
  placeholder,
  searchPlaceholder,
  values,
  pickedValues,
  width,
  search,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  name,
  optionName,
  category,
  onChange,
  singleSelect = false,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState<ValuesState[]>(pickedValues);

  useEffect(() => {
    setSelectedValues(pickedValues);
  }, [pickedValues]);

  const onCancelClick = (event) => {
    event.stopPropagation();
    setSelectedValues([]);
    onChange([]);
    setCategories([]);
    setSearchedValue('');
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchedValues = useMemo(
    () =>
      searchedValue
        ? values.filter((el) => el[optionName].toLowerCase().includes(searchedValue.toLowerCase()))
        : values,
    [values, searchedValue, optionName],
  );

  const categorizedValues = useMemo(() => {
    if (category) {
      const result = {};
      searchedValues.forEach((el) =>
        result[el[category]] ? result[el[category]].push(el) : (result[el[category]] = [el]),
      );
      return result;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValues]);

  const selectedOptions = selectedValues.length ? selectedValues?.map((el) => el?.id) : [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transformedObj = (value: any) => {
    return {
      id: value.id,
      name: value[optionName],
      category: category ? value[category] : '',
    };
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onOptionClick = (value: any) => {
    if (selectedOptions.includes(value.id)) {
      if (category) {
        setCategories([...categories.filter((el) => el !== value[category])]);
      }
      const payload = singleSelect ? [value] : selectedValues.filter((el) => el.id !== value.id);
      setSelectedValues(payload);
      onChange(payload);
    } else {
      const payload = singleSelect ? [transformedObj(value)] : [...selectedValues, transformedObj(value)];
      setSelectedValues(payload);
      onChange(payload);
      if (category) {
        if (
          categorizedValues[value[category]]?.length ===
          [...selectedValues, transformedObj(value)].filter((el) => el.category === value[category])?.length
        ) {
          setCategories([...categories, value[category]]);
        }
      }
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCategoryClick = (value: any) => {
    if (categories.includes(value)) {
      setCategories(categories.filter((el) => el !== value));
      const payload = selectedValues.filter((el) => el.category !== value);
      setSelectedValues(payload);
      onChange(payload);
    } else {
      setCategories([...categories, value]);
      const payload = [
        ...selectedValues.filter((el) => el.category !== value),
        ...categorizedValues[value].map((el) => {
          return {
            id: el.id,
            name: el[optionName],
            category: el[category],
          };
        }),
      ];
      setSelectedValues(payload);
      onChange(payload);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Selector width={width}>
        <SelectedOption focused={isOpen} onClick={() => setOpen(!isOpen)} disabled={false}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {selectedValues?.length ? (
                <ClearIcon onClick={onCancelClick} style={{ color: '#577BF9', fontSize: '16px', marginRight: '4px' }} />
              ) : (
                <></>
              )}
              <SelectedInput
                readOnly
                placeholder={placeholder ? placeholder : 'Выбрать'}
                value={selectedValues?.length ? selectedValues[0]?.name : ''}
                onClick={() => setOpen(true)}
              />
              {selectedValues?.length > 1 ? (
                <div style={{ display: 'flex' }}>
                  <Circle>+{selectedValues?.length - 1}</Circle>
                </div>
              ) : (
                <></>
              )}
            </div>
            <KeyboardArrowDownIcon style={{ fontSize: '20px' }} />
          </div>
        </SelectedOption>
        {isOpen && (
          <OptionsContainer>
            {search && (
              <>
                <Search>
                  <SearchInput
                    value={searchedValue}
                    onChange={(event) => setSearchedValue(event.target.value)}
                    placeholder={searchPlaceholder ? searchPlaceholder : 'Поиск...'}
                  />
                  <SearchIcon />
                </Search>
                <Divider />
              </>
            )}
            {categorizedValues && (
              <Scrollbars style={{ width: '100%', height: '200px' }}>
                {Object.keys(categorizedValues).map((categoryValue) => {
                  return (
                    <div key={categoryValue}>
                      <Category
                        selected={categories.includes(categoryValue)}
                        onClick={() => onCategoryClick(categoryValue)}
                      >
                        {categoryValue}
                      </Category>
                      {categorizedValues[categoryValue].map((value) => (
                        <Option
                          key={value.id}
                          selected={selectedOptions.includes(value.id)}
                          onClick={() => onOptionClick(value)}
                        >
                          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value[optionName]}</div>
                        </Option>
                      ))}
                    </div>
                  );
                })}
              </Scrollbars>
            )}

            {!categorizedValues && (
              <Scrollbars style={{ width: '100%', height: '200px' }}>
                {searchedValues.map((value) => (
                  <Option
                    key={value.id}
                    selected={selectedOptions.includes(value.id)}
                    onClick={() => onOptionClick(value)}
                  >
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value[optionName]}</div>
                  </Option>
                ))}
              </Scrollbars>
            )}
            {!searchedValues.length && search && <NotFound>Нет совпадений</NotFound>}
          </OptionsContainer>
        )}
      </Selector>
    </ClickAwayListener>
  );
};

export default MultiSelect;
