import React, { forwardRef, useCallback, useEffect } from 'react';
import IMask from 'imask';
import TextInput from '../../../uikit/TextInput';
import { debounceValueChange } from './../utils';

type paidCellType = {
  isActivePaymentActions: boolean;
  isActiveRefundActions: boolean;
  value: number;
  handleSetIsPaymentFilled: (condition: boolean) => void;
};
const maskOptions = {
  mask: Number,
};
export const PaymentPaidCell = forwardRef<HTMLInputElement, paidCellType>((props, ref) => {
  const { isActivePaymentActions, isActiveRefundActions, value, handleSetIsPaymentFilled } = props;

  const updatePaidValueCondition = useCallback(() => {
    ref?.current?.value ? handleSetIsPaymentFilled(true) : handleSetIsPaymentFilled(false);
  }, [ref, handleSetIsPaymentFilled]);

  useEffect(() => {
    debounceValueChange(ref, updatePaidValueCondition);
  }, [ref, updatePaidValueCondition]);

  useEffect(() => {
    if (ref && ref.current) {
      IMask(ref.current, maskOptions);
    }
  }, [ref]);

  return (
    <>
      {!isActivePaymentActions ? value : <TextInput style={{ height: 24, fontSize: 12 }} ref={ref} />}
      {isActiveRefundActions && <div style={{ paddingTop: 46 }} />}
    </>
  );
});
PaymentPaidCell.displayName = 'PaymentPaidCell';
