import { debounce } from 'lodash';
import { Totals } from './enums';

export const debounceValueChange = (ref, callback) => {
  const onChange = debounce(callback, 300);
  ref?.current?.addEventListener('keyup', onChange);
  return function cleanup() {
    ref?.current?.removeEventListener('keyup', onChange);
  };
};

export const useGetTotals = (rows) => {
  const totalForPay = rows.reduce((sum, arg) => sum + arg.payment, 0);
  const totalPaid = rows.reduce((sum, arg) => sum + arg.paid, 0);

  const getTotalPaymentType = () => {
    const allPaymentTypes = [];
    rows.forEach((row) => {
      if (row.paymentOperations.length) {
        const rowPaymentTypes = row.paymentOperations.map((item) => item.payment_type);
        allPaymentTypes.push(...rowPaymentTypes);
      }
    });
    if (!allPaymentTypes.length) return null;

    const uniquePaymentTypes = new Set(allPaymentTypes);

    if (uniquePaymentTypes.size > 1) return Totals.COMBINED_TYPE;
    return allPaymentTypes[0];
  };

  const totalPaymentType = getTotalPaymentType();
  return [totalForPay, totalPaid, totalPaymentType];
};
