import {
  SET_WORKDAY_CABINET,
  SET_WORKDAYS_CABINETS,
  SET_STAFFER_WORKDAYS,
  PICK_WORKDAY,
  SET_EVEN,
  SET_ODD,
} from "./actions_workdays";
import { FLUSH_STATE } from "../flushState/types";

const INITIAL_STATE = {
  cabinet: { name: "Выбрать кабинет" },
  cabinets: [],
  pickedWorkdays: [],
  workdays: [],
  odd: false,
  even: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_WORKDAY_CABINET:
      return {
        ...state,
        ...{ cabinet: action.payload },
      };
    case SET_WORKDAYS_CABINETS:
      return {
        ...state,
        ...{ cabinets: action.payload },
      };
    case SET_STAFFER_WORKDAYS:
      return {
        ...state,
        ...{ pickedWorkdays: action.payload },
      };
    case PICK_WORKDAY:
      return {
        ...state,
        ...{ workdays: action.payload },
      };
    case SET_ODD:
      return {
        ...state,
        ...{ odd: action.payload.odd, even: action.payload.even },
      };
    case SET_EVEN:
      return {
        ...state,
        ...{ odd: action.payload.odd, even: action.payload.even },
      };
    case FLUSH_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
