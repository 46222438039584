import styled from "styled-components";

export const Filters = styled.div<{modalView}>`
    height: 65px;
    padding-left: 10px;
    padding-top: 15px;
    padding-right: ${({modalView})=> (modalView ? 5 : 70)}px;
`

export const FiltersWrap = styled.div({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
})

export const Page = styled.div({
    backgroundColor: '#F6F6FA',
    zIndex: -2,
    paddingBottom: 24,
    height: '100vh'
})

export const Navigation = styled.div({
    position: 'relative',
    width: '100vw',
    height: '125px',
    backgroundColor: '#F6F6FA',
    zIndex: 11,
})

export const HeaderWrap = styled.div({
    position: 'fixed',
    backgroundColor: '#F6F6FA',
    width: '100%'
})

export const TableHeaderWrap = styled.div({
    backgroundColor: '#F6F6FA'
})

export const TableHeader = styled.table<{length: number}>`
    display: ${({length})=>(length > 0 ? 'flex' : 'none')};
`

export const TableBody = styled.div<{length: number}>`
    display: ${({length})=>(length > 0 ? 'block' : 'none')};
    margin: 0px;
    overflow-x: auto;

    ::-webkit-scrollbar {
        background-color: transperent;
        width: 24px;
        height: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #D3D6DA;
        border-radius: 8px;
    }
`