import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { Container, Title, Text, Link } from './styledComponents';
import { getEmptyScheduleTable } from './functions';
import { routerConfig } from '../../../navigation/routerConfig';

const EmptyScheduleTable = ({ noDoctorsEvents, noCabinets, areAllWorkdaysAreNonWorking }) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.common.user);

  const { primaryText, secondaryText, linkText } = getEmptyScheduleTable(
    noDoctorsEvents,
    noCabinets,
    areAllWorkdaysAreNonWorking,
  );

  return primaryText || secondaryText || linkText ? (
    <Container>
      <img src="./img/grey/empty.svg" alt="empty box" style={{ marginBottom: 16 }} />
      <Title>{primaryText}</Title>
      <Text>
        <span>{secondaryText}</span>
        {user.permissions.edit_clinic_profile ? (
          <Link onClick={() => navigate(routerConfig.PROFILE.path, { replace: true })}>{linkText}</Link>
        ) : (
          <span>{linkText}</span>
        )}
      </Text>
    </Container>
  ) : (
    <></>
  );
};

export default EmptyScheduleTable;
