import styled from 'styled-components';

export const Content = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  position: relative;
  transition: 0.25s;
`;
export const IconContainer = styled.div<{ expanded: boolean; active: boolean }>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-left: ${({ expanded }) => (expanded ? '16px' : '16px')};
  justify-content: ${({ expanded }) => (expanded ? 'flex-start' : 'flex-start')};
  height: 40px;
  background-color: ${({ active }) => (active ? '#EFF2FF' : 'white')};
`;

export const Icon = styled.img``;
export const BorderLine = styled.div`
  position: absolute;
  height: 40px;
  width: 3px;
  background-color: #577bf9;
  top: 0px;
  right: 0px;
`;
export const Logo = styled.img`
  width: 28px;
  heigth: 28px;
`;
export const LogoContainer = styled.div<{ expanded: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 62px;
  margin-top: 16px;
  flex-direction: row;
  align-items: center;
  padding-left: ${({ expanded }) => (expanded ? '7px' : '7px')};
  justify-content: ${({ expanded }) => (expanded ? 'flex-start' : 'flex-start')};
`;
export const Layout = styled.div<{ expanded: boolean }>`
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100vh;
  z-index: 1200;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px rgba(37, 52, 70, 0.04), 0px 4px 8px rgba(37, 52, 70, 0.16);
  transition: 0.25s;
  transform: ${({ expanded }) => (expanded ? 'translateX(0px)' : 'translateX(205px)')};
`;

export const ExpandButtonContent = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(33, 33, 41, 0.04), 0px 2px 4px rgba(33, 33, 41, 0.16);
  border-radius: 50%;
  top: 54px;
  left: -17px;
`;

export const ArrowIconContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const IconText = styled.div<{ padding?: number; active?: boolean; hover?: boolean }>`
  margin-left: 18px;
  padding-top: ${({ padding }) => padding}px;
  letter-spacing: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  color: ${({ active, hover }) => (active || hover ? '#577BF9' : '#253446')};
`;

export const ClinicName = styled.div`
  letter-spacing: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #253446;
  margin-left: 12px;
`;
export const Spacer = styled.div`
  height: calc(100vh-100px);
`;

export const BottomBlock = styled.div`
  position: absolute;
  left: 0;
  bottom: 16px;
  width: 100%;
`;

export const BottomBlockIcon = styled.div<{ expanded: boolean; active: boolean }>`
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-left: ${({ expanded }) => (expanded ? '16px' : '16px')};
  justify-content: ${({ expanded }) => (expanded ? 'flex-start' : 'flex-start')};
  height: 40px;
`;

export const UserInfo = styled.div`
  overflow: hidden;
  padding-left: 14px;
`;

export const Name = styled.div`
  overflow: hidden;
  letter-spacing: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #253446;
`;

export const Job = styled.div`
  margin-top: 4px;
  overflow: hidden;
  letter-spacing: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #7c8590;
  margin-bottom: 16px;
`;

export const LogoBorder = styled.div<{ expanded: boolean }>`
  display: flex;
  flex: 0;
  flex-direction: row;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ expanded }) => (expanded ? 'flex-start' : 'flex-start')};
  border-radius: 50%;
  border: 1px solid #d3d6da;
`;
