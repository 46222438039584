import React from 'react';
import { RedactIcon } from '../../../uikit/Icons/index';
import { RedactWrapper } from '../styled-components';

const RedactButton = ({ onClick }) => {
  return (
    <RedactWrapper onClick={onClick}>
      <RedactIcon />
    </RedactWrapper>
  );
};

export default RedactButton;
