/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INewScheduleState, ISelectedZoneData, IAppointmentDataForTable } from './types';
import { IAppointment, IDoctor } from './modals/VisitCard/types';
import { CabinetDictionaryItem } from '../../services/dictionaries';
import { CalendarApi } from '@fullcalendar/core';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import {
  mapAppointmentsDataToAppointments,
  calculateDatesToRender,
  getConstraintBlockers,
} from './ScheduleTable/functions';
import { Appointment } from '../../services/appointments';

const initialState: INewScheduleState = {
  schedulerAPI: null,
  dateRange: { start: new Date().setHours(0, 0, 0, 0), end: new Date().setHours(0, 0, 0, 0) },
  datesInRange: [new Date().setHours(0, 0, 0, 0)],
  datesToRender: [],
  selectedDate: null,
  doctors: [],
  cabinets: [],
  initialCabinets: [],
  statuses: [],
  selectedZoneData: null,
  clickedAppointmentId: null,
  cancellingAppointmentData: null,
  readyToDragAppointmentID: 0,
  currentAppointments: [],
  currentSessions: [],
  tableWidth: 0,
  draggingElement: null,
  isCopying: false,
  blockers: [],
  redactingDay: null,
  staffers: [],
  patients: [],
  // constraint: null,
};

const millisecondsIn24hours = 86400000;

export const NewScheduleSlice = createSlice({
  name: 'newSchedule',
  initialState,
  reducers: {
    setSchedulerAPI: (state, action: PayloadAction<CalendarApi>) => {
      return { ...state, schedulerAPI: action.payload };
    },
    setDateRange: (state, action: PayloadAction<{ start: number; end: number }>) => {
      //Через API экземпляра таблицы сетаем visibleRange на action.payload, так передаём данные о датах для отображения в таблицу
      state.schedulerAPI && state.schedulerAPI.setOption('visibleRange', action.payload);
      return {
        ...state,
        dateRange: action.payload,
        datesInRange: eachDayOfInterval(action.payload).map((e) => e.getTime()),
      };
    },
    setDatesToRender: (state, action: PayloadAction<any[]>) => {
      const { doctors, schedulerAPI } = state;
      if (schedulerAPI) {
        // Определение новых дат для отрисовки
        const newDatesToRender =
          doctors.length > 0
            ? calculateDatesToRender(
                schedulerAPI.getEvents(),
                doctors,
                action.payload.holidays,
                action.payload.workdays,
              )
            : [];

        // Определение нового диапазона дат
        const newDateRange = {
          start: doctors.length && newDatesToRender.length ? newDatesToRender[0] : new Date().setHours(0, 0, 0, 0),
          end:
            doctors.length && newDatesToRender.length
              ? newDatesToRender[newDatesToRender.length - 1] + millisecondsIn24hours
              : new Date().setHours(0, 0, 0, 0),
        };

        //Сетаем в таблицу даты, которые будут отрисованы. Свойство titleRangeSeparator не предназначено для хранения этих данных
        //Но оно ничем не используется, а возможность делать свои кастомные свойства отсутствуют
        schedulerAPI.setOption('titleRangeSeparator', newDatesToRender);
        schedulerAPI.setOption('visibleRange', newDateRange);
        return {
          ...state,
          dateRange: newDateRange,
          datesInRange: eachDayOfInterval(newDateRange).map((e) => e.getTime()),
          datesToRender: newDatesToRender,
        };
      }
    },
    setSelectedDate: (state, action: PayloadAction<number>) => {
      state.schedulerAPI.setOption('buttonIcons', action.payload);

      return { ...state, selectedDate: action.payload };
    },
    setDoctors: (state, action: PayloadAction<IDoctor[]>) => {
      return { ...state, doctors: action.payload };
    },
    setCabinets: (state, action: PayloadAction<CabinetDictionaryItem[]>) => {
      return { ...state, cabinets: action.payload };
    },
    setInitialCabinets: (state, action: PayloadAction<CabinetDictionaryItem[]>) => {
      state.schedulerAPI.setOption('viewSkeletonRender', action.payload);

      return {
        ...state,
        initialCabinets: action.payload,
      };
    },
    setStatuses: (state, action: PayloadAction<any>) => {
      return { ...state, statuses: action.payload };
    },
    setSelectedZoneData: (state, action: PayloadAction<ISelectedZoneData | null>) => {
      return { ...state, selectedZoneData: action.payload };
    },
    setClickedAppointmentId: (state, action: PayloadAction<number | null>) => {
      return {
        ...state,
        clickedAppointmentId: action.payload,
      };
    },
    setCancellingAppointmentData: (state, action: PayloadAction<IAppointment | null>) => {
      return {
        ...state,
        cancellingAppointmentData: action.payload,
      };
    },
    setReadyToDragAppointment: (state, action: PayloadAction<number>) => {
      if (state.schedulerAPI) {
        const appointmentToDrag = state.schedulerAPI
          .getEvents()
          .filter((event) => ~~event._def.publicId === action.payload)
          .filter((event) => !event._def.ui.display);
        appointmentToDrag[0] && appointmentToDrag[0].setExtendedProp('isReadyToDrag', true);
      }
      return { ...state, readyToDragAppointmentID: action.payload };
    },
    setCurrentAppointments: (state, action: PayloadAction<Appointment[]>) => {
      const { statuses, doctors, schedulerAPI, initialCabinets } = state;
      const filteredAndFormattedAppointments = mapAppointmentsDataToAppointments(
        action.payload,
        statuses,
        doctors,
        initialCabinets,
      );

      // Обёртка для предотвращения лишних рендеров при множественных операциях в таблице
      schedulerAPI?.batchRendering(function () {
        // Удаляем у экземпляра таблицы все визиты
        schedulerAPI?.getEvents().forEach((event) => (event._def.ui.display ? null : event.remove()));
        schedulerAPI?.addEventSource(filteredAndFormattedAppointments);
      });
      return { ...state, currentAppointments: filteredAndFormattedAppointments };
    },
    setCurrentSessions: (state, action: PayloadAction<IAppointmentDataForTable[]>) => {
      const { schedulerAPI, initialCabinets } = state;

      if (schedulerAPI) {
        const cabinetsIDs = initialCabinets.map((cabinet) => cabinet.id);
        const filteredSessions = action.payload.filter((session) => cabinetsIDs.includes(session.resourceId));

        schedulerAPI.batchRendering(function () {
          schedulerAPI.getEvents().forEach((event) => event._def.ui.display === 'background' && event.remove());
          schedulerAPI.addEventSource(filteredSessions);
        });

        return { ...state, currentSessions: filteredSessions };
      }

      return state;
    },
    setTableWidth: (state, action: PayloadAction<number>) => {
      return { ...state, tableWidth: action.payload };
    },
    setDraggingElement: (state, action: PayloadAction<Partial<IAppointment> | null>) => {
      return { ...state, draggingElement: action.payload };
    },
    setIsCopying: (state, action: PayloadAction<boolean>) => {
      return { ...state, isCopying: action.payload };
    },
    setBlockers: (state, action: PayloadAction<{ workdays: Partial<IAppointment>[]; holidays: any[] }>) => {
      const { schedulerAPI, datesInRange, selectedDate, cabinets, initialCabinets } = state;

      schedulerAPI?.batchRendering(function () {
        schedulerAPI?.getEvents().forEach((event) => event._def.ui.display === 'block' && event.remove());
      });

      //Возможно нестабильное поведение при инициализации блокеров. Посмотрю на новый код какое-то время
      const currentResources = schedulerAPI
        ? schedulerAPI.getOption('resources')
        : cabinets.length
        ? cabinets
        : initialCabinets;
      const currentResourcesIds = currentResources.map((resource) => resource.id);

      // const currentResourcesIds = schedulerAPI.getOption('resources').map((resource) => resource.id);

      const blockers = getConstraintBlockers(
        action.payload.workdays,
        action.payload.holidays,
        [...datesInRange],
        selectedDate,
        currentResourcesIds,
        schedulerAPI,
      );

      schedulerAPI?.addEventSource(blockers);
      return { ...state, blockers };
    },
    setRedactingDay: (state, action: PayloadAction<number>) => {
      return { ...state, redactingDay: action.payload };
    },
    setStaffers: (state, action: PayloadAction<any>) => {
      return { ...state, staffers: action.payload };
    },
    setPatients: (state, action: PayloadAction<any>) => {
      return { ...state, patients: action.payload };
    },
    // setConstraint: (state, action: PayloadAction<{ startTime: string; endTime: string }>) => {
    //   state.schedulerAPI && state.schedulerAPI.setOption('eventConstraint', action.payload)
    //   return { ...state, constraint: action.payload };
    // },
  },
});

export const {
  setSchedulerAPI,
  setDateRange,
  setDatesToRender,
  setSelectedDate,
  setDoctors,
  setCabinets,
  setInitialCabinets,
  setStatuses,
  setSelectedZoneData,
  setClickedAppointmentId,
  setCancellingAppointmentData,
  setReadyToDragAppointment,
  setCurrentAppointments,
  setCurrentSessions,
  setTableWidth,
  setDraggingElement,
  setIsCopying,
  setBlockers,
  setRedactingDay,
  increaseColumnsCounter,
  resetColumnsCounter,
  setStaffers,
  setPatients,
  // setConstraint
} = NewScheduleSlice.actions;
