import { LOAD_PATIENTS, PICK_PATIENT } from "./types";
import { FLUSH_STATE } from "../flushState/types";
import { FORGET_PATIENT } from "../legacyState/index";
const INITIAL_STATE = {
  responce: [],
  picked: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_PATIENTS:
      return {
        ...state,
        ...{ responce: action.payload },
      };
    case PICK_PATIENT:
      return {
        ...state,
        ...{ picked: action.payload },
      };
    case FLUSH_STATE:
      return INITIAL_STATE;
    case FORGET_PATIENT:
      //
      return {
        ...state,
        ...{ picked: {} },
      };
    default:
      return state;
  }
}
