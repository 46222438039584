import EventsWebSocket from '../../websocket';
import { wsUrl } from '../../utils/request';
import { startEditClinicAction, stopEditClinicAction } from '../../websocket/actionCreators';
import { SET_SESSIONS } from '../legacyState';

let socket;

function setSessions(sessions) {
  return (dispatch) => {
    dispatch({
      type: SET_SESSIONS,
      payload: sessions,
    });
  };
}

export const startEditClinic = (userId) => {
  socket.send(startEditClinicAction(userId));
};

export const stopEditClinic = (userId) => {
  socket.send(stopEditClinicAction(userId));
};

const handleWsMessage = (dispatch) => (msg) => {
  if (msg.data) {
    dispatch(setSessions(msg.data));
  }
};

export function startWsEvents(dispatch) {
  socket = new EventsWebSocket(wsUrl.clinic, handleWsMessage(dispatch), true);
}

export function stopWsEvents() {
  socket.disconnect();
}
