import React from 'react';
import OutlinedButton from '../../../../uikit/Button/OutlinedButton';
import { Title, Text, Container } from './styles';

export function EmptyPages({ data, showReset = false, onResetFilters }) {
  return (
    <Container>
      <img src="./img/white/envelop.png" alt={'report'} width={126} height={80} style={{ marginBottom: '37px' }} />
      {data ? (
        <>
          <Title>Нет данных соответствующих запросу</Title>
          <OutlinedButton onClick={onResetFilters} width={183}>
            Сбросить все фильтры
          </OutlinedButton>
        </>
      ) : (
        <>
          <Text>Для формирования отчета укажите данные в фильтрах.</Text>
          {showReset && (
            <OutlinedButton onClick={onResetFilters} width={183}>
              Сбросить все фильтры
            </OutlinedButton>
          )}
        </>
      )}
    </Container>
  );
}
