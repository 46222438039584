import React, { FC } from 'react';
import styled from 'styled-components';
import { InvoiceStatus, Visit } from '../enums';

type visitStatusProps = {
  status: string;
};
type statusDataType = {
  source: string;
  color: string;
  borderColor: string;
  text: string;
};
type StatusBlockProps = {
  color: string;
  border_color?: string;
};
const StatusBlock = styled.div<StatusBlockProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  font-size: 12px;
  margin-right: 10px;
  line-height: 20px;
  border: ${({ border_color }) => (border_color ? `1px solid ${border_color}` : '1px solid transparent;')};
  color: ${({ color }) => (color ? color : '#515D6B')};
`;
const IconBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  margin-right: 9px;
`;

export const VisitStatus: FC<visitStatusProps> = ({ status }) => {
  const getStatus = (): statusDataType => {
    switch (status) {
      case InvoiceStatus.ACCOUNT_DEBT:
        return {
          source: './img/yellow/exclamation-circle.svg',
          color: '#FE9C34',
          borderColor: '#FFEBD6',
          text: 'Задолженность по счету',
        };
      case InvoiceStatus.FOR_PAYMENT:
        return {
          source: './img/red/for-payment.svg',
          color: '#C3000A',
          borderColor: '#FADAE2',
          text: 'К оплате',
        };
      case InvoiceStatus.IN_PROGRESS:
        return {
          source: './img/yellow/in-progress.svg',
          color: '#FE9C34',
          borderColor: '#FFEBD6',
          text: 'В процессе оплаты',
        };
      case InvoiceStatus.ON_EDITING:
        return {
          source: './img/yellow/on-editing.svg',
          color: '#FE9C34',
          borderColor: '#FFEBD6',
          text: 'Счет на редактировании',
        };
      case InvoiceStatus.PAID:
        return {
          source: './img/green/paid-visit.svg',
          color: '#0D853D',
          borderColor: '#EEF9E8',
          text: 'Счет оплачен',
        };
      case Visit.ON_TREATMENT:
        return {
          source: './img/green/on-treatment.svg',
          color: '#0D853D',
          borderColor: '#DCF3D1',
          text: 'На лечении',
        };
      case Visit.NOT_CONFIRMED:
        return {
          source: './img/red/not-confirmed.svg',
          color: '#C3000A',
          borderColor: '#FADAE2',
          text: 'Не подтвержден',
        };
      case Visit.CONFIRMED:
        return {
          source: './img/green/confirmed.svg',
          color: '#0D853D',
          borderColor: '#EEF9E8',
          text: 'Подтвержден',
        };
      case Visit.IN_CLINIC:
        return {
          source: './img/green/in_clinic.svg',
          color: '#0D853D',
          borderColor: '#EEF9E8',
          text: 'В клинике',
        };

      case Visit.NO_PAYMENT:
        return {
          source: './img/grey/no-payment.svg',
          color: '#7C8590',
          borderColor: '#D3D6DA',
          text: 'Без оплаты',
        };
      case Visit.CANCELED:
        return {
          source: './img/grey/canceled.svg',
          color: '#7C8590',
          borderColor: '#D3D6DA',
          text: 'Отменен',
        };
      case Visit.COMPLETED:
        return {
          source: './img/green/paid-visit.svg',
          color: '#0D853D',
          borderColor: '#EEF9E8',
          text: 'Визит окончен',
        };
      default:
        return {
          source: '',
          color: '',
          borderColor: '',
          text: 'No status',
        };
    }
  };
  const { source, color, borderColor, text } = getStatus();
  return (
    <StatusBlock color={color} border_color={borderColor}>
      <IconBlock>
        <img width="17px" src={source} alt="status" />
      </IconBlock>
      <div>{text}</div>
    </StatusBlock>
  );
};
