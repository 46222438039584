export enum JournalFieldType {
  TEXT,
  DIAGNOSIS_MKB,
  TREATMENT_PLAN,
}

export type JournalField = {
  index?: number;
  text: string;
  name: string;
  type: JournalFieldType;
  extended: boolean;
};

export const journalFieldsConfig: JournalField[] = [
  {
    text: 'Жалобы',
    name: 'complaints',
    type: JournalFieldType.TEXT,
    extended: false,
  },
  {
    text: 'Анамнез',
    name: 'anamnesis',
    type: JournalFieldType.TEXT,
    extended: false,
  },
  {
    text: 'Данные объективного обследования',
    name: 'phys_exam_data',
    type: JournalFieldType.TEXT,
    extended: false,
  },
  {
    text: 'Прикус',
    name: 'bite',
    type: JournalFieldType.TEXT,
    extended: true,
  },
  {
    text: 'План обследования',
    name: 'medical_exam_plan',
    type: JournalFieldType.TEXT,
    extended: true,
  },
  {
    text: 'Диагноз',
    name: 'diagnosis',
    type: JournalFieldType.TEXT,
    extended: false,
  },
  {
    text: 'Диагноз по МКБ',
    name: 'DIAGNOSIS_MKB',
    type: JournalFieldType.DIAGNOSIS_MKB,
    extended: true,
  },
  {
    text: 'Запланированный результат лечения',
    name: 'planned_treatment_outcome',
    type: JournalFieldType.TEXT,
    extended: true,
  },
  {
    text: 'План лечения',
    name: 'TREATMENT_PLAN',
    type: JournalFieldType.TREATMENT_PLAN,
    extended: false,
  },
  {
    text: 'Лечение',
    name: 'treatment',
    type: JournalFieldType.TEXT,
    extended: false,
  },
  {
    text: 'Рекомендации',
    name: 'recommendations',
    type: JournalFieldType.TEXT,
    extended: false,
  },
  {
    text: 'Дополнительно',
    name: 'additional_info',
    type: JournalFieldType.TEXT,
    extended: false,
  },
];
