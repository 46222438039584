import React, { useState, useEffect } from 'react';
import { loginSave } from '../../features/legacyState';
import { Navigate, useLocation } from 'react-router-dom';
import { useLoginMutation } from '../../services/users';
import { routerConfig } from '../../navigation/routerConfig';
import { useAppDispatch } from '../../store/hooks';
import { startWsEvents } from '../../features/sync/operations';

const ReAuth = () => {
  const [state, setState] = useState({ authSuccess: false, reauth: false });
  const {
    state: { from },
  } = useLocation();
  const [login] = useLoginMutation();
  const dispatch = useAppDispatch();

  const onReAuth = async () => {
    const username = sessionStorage.getItem('login');
    const password = sessionStorage.getItem('password');
    try {
      const response = await login({ username, password }).unwrap();
      dispatch(loginSave(response));
      setState((prevState) => ({ ...prevState, authSuccess: true }));
    } catch (error) {
      setState({ authSuccess: false, reauth: true });
    }
    startWsEvents(dispatch);
  };

  useEffect(() => {
    (async () => {
      await onReAuth();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {state.authSuccess && <Navigate to={from} />}
      {state.reauth && <Navigate to={routerConfig.ROOT.path} />}
    </div>
  );
};

export default ReAuth;
