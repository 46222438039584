import styled from 'styled-components';
import { AddEntryIcon } from '../../uikit/Icons';
import { TableProps } from './types';
import { IconButton, Menu } from '@material-ui/core';

export const StyledTable = styled.table`
  border-spacing: 0;
  color: #515d6b;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
  overflow: auto;
  th {
    color: #253446;
    font-weight: 500;
    padding: 16px;
    position: sticky;
    top: 0;
    background: #f9f9f9;
    z-index: 1;
  }
  th,
  td {
    border-bottom: 1px solid #d3d6da;
    margin: 0;
    text-align: left;
    white-space: nowrap;
    /* The secret sauce */
    /* Each cell should grow equally */
    // width: 1%;
    /* But "collapsed" cells should be as small as possible */
    &.collapsed {
      width: 0.0000000001%;
    }
  }
  td {
    input {
      font-size: 14px;
      height: auto;
      line-height: 22px;
      padding: 4px 8px;
    }
    &.noData {
      padding: 0;
      border: none;
    }
  }
`;

export const StyledTableRow = styled.tr<{
  isSuccessEditing: boolean;
  rowBackground: string;
  isRowHighlighted: boolean;
}>`
  box-shadow: ${({ isSuccessEditing }) =>
    isSuccessEditing ? 'inset 2px 2px 0px #52c41a, inset -2px -3px 0px #52c41a' : 'none'};
  background-color: ${({ rowBackground }) => (rowBackground ? rowBackground : 'white')};
  transition: box-shadow 0.5s ease-out 0s;
  font-weight: ${({ isRowHighlighted }) => (isRowHighlighted ? 500 : 400)};
  color: ${({ isRowHighlighted }) => (isRowHighlighted ? '#253446' : '#515D6B')};
`;

export const AddEntry = styled.div<{ top: number }>`
  background-color: #577bf9;
  content: '';
  height: 2px;
  left: 32px;
  position: absolute;
  right: 0;
  top: ${({ top }) => `${top}px`};
  z-index: 2;
`;

export const StyledAddEntryIcon = styled(AddEntryIcon)<{ top: number }>`
  content: '';
  height: 32px;
  left: 0;
  position: absolute;
  top: ${({ top }) => `${top - 16}px`};
  width: 32px;
  z-index: 1;
`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TableWrapper = styled.div<Pick<TableProps<any>, 'canAddNewEntry'>>`
  display: block;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: ${({ canAddNewEntry }) => (canAddNewEntry ? '16px' : 0)};
  padding-left: ${({ canAddNewEntry }) => (canAddNewEntry ? '40px' : 0)};
  position: relative;
`;

export const TableCell = styled.td<{ isEditing?: boolean; isLoading?: boolean; isDisabled?: boolean }>`
  background-color: ${({ isDisabled }) => (isDisabled ? '#EFF0F3' : 'inherit')};
  color: ${({ isDisabled }) => (isDisabled ? '#A8AEB5' : 'inherit')};
  padding: ${({ isEditing }) => (isEditing ? '11px 8px' : '16px')};
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'initial')};
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & button:not(:last-child) {
    margin-right: 16px;
  }
`;

export const TableActionButton = styled(IconButton)`
  padding: 0;
  outline: none;

  &:hover {
    background: transparent;
  }

  &:focus {
    outline: none;
  }
`;

export const TableActionMenu = styled(Menu)`
  & .MuiPaper-root {
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
    color: #515d6b;
  }

  & .MuiListItem-button:hover {
    background-color: #f9f9f9;
  }

  & .MuiMenuItem-root {
    font-size: 14px;
    line-height: 22px;
    padding: 5px 12px;

    &.disabled {
      color: #a8aeb5 !important;
    }
  }
`;

export const TableNoData = styled.div`
  color: #a8aeb5;
  font-size: 14px;
  line-height: 16px;
  margin: 35px 0;
  text-align: center;
`;
