import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import TeethFormula from './state';
import EdgeFillet from './EdgeFillet';
import { format } from 'date-fns';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { MedcardDate } from '../../../services/medcard';

const { pickDate } = TeethFormula.actions;
type DateStyleProps = {
  isActive: boolean;
  hasTime?: boolean;
};
const DateTitle = styled.div<DateStyleProps>`
  font-style: normal;
  line-height: 120%;
  font-weight: ${({ isActive }) => (isActive ? '500' : 'normal')};
  font-size: 14px;
  color: ${({ isActive }) => (isActive ? '#253446' : '#515d6b')};
  cursor: pointer;
`;

const DateSubTitle = styled.div`
  font-style: normal;
  line-height: 120%;
  font-size: 10px;
  color: #a8aeb5;
`;

const DateRightDivider = styled.div`
  position: absolute;
  right: -2px;
  top: 10px;
  height: 23px;
  width: 1px;
  background: #d3d6da;
`;

const DateBorder = styled.div<DateStyleProps>`
  position: relative;
  margin-bottom: -1px;
  padding: ${({ hasTime }) => `${hasTime ? 7 : 13}px 14px`};
  background: ${({ isActive }) => (isActive ? '#ffffff' : 'transparent')};
  border-radius: ${({ isActive }) => (isActive ? '6px 6px 0px 0px' : 0)};
  border: ${({ isActive }) => (isActive ? '1px solid #d3d6da' : 'none')};
  border-bottom: none;
`;

function DateBlock({
  isOnly,
  isScroll,
  date: { id, date, timeStart, timeEnd },
  index,
  length,
  activeIndex,
  teethFormulaState: { currentDateId },
  pickDate,
}: {
  isOnly: boolean;
  isScroll: boolean;
  date: MedcardDate;
  index: number;
  length: number;
  activeIndex: number;
  teethFormulaState: { currentDateId: number };
  pickDate: ActionCreatorWithNonInferrablePayload;
}) {
  const isLast = index === length - 1;
  return (
    <DateBorder isActive={currentDateId === id} onClick={() => pickDate(id)} hasTime={!isOnly && timeStart && timeEnd}>
      <EdgeFillet isVisible={isScroll ? activeIndex !== 0 && currentDateId === id : currentDateId === id} />
      <EdgeFillet isVisible={currentDateId === id && !isLast} right />
      <DateTitle isActive={currentDateId === id}>
        <div>{date && format(new Date(date), 'dd.MM.yyyy')}</div>
        {!isOnly && timeStart && timeEnd && (
          <DateSubTitle>
            {format(new Date(timeStart), 'HH:mm')}-{format(new Date(timeEnd), 'HH:mm')}
          </DateSubTitle>
        )}
      </DateTitle>
      {!isLast && index !== activeIndex - 1 && index !== activeIndex && <DateRightDivider />}
    </DateBorder>
  );
}

export default connect((state: RootState) => ({ teethFormulaState: state.teethFormulaState }), { pickDate })(DateBlock);
