import React, { useEffect, useState } from 'react';
import { TimeAxisHour } from './styled-components';
import { getHoursStrings } from './functions';

const TimeAxis = ({ schedulerAPI }) => {
  const [, setKey] = useState(0);

  // Эта логика отслеживает количество строчек в таблице
  // Если количество меняется - компонент перерисовывается и достаёт новые данные из SchedulerAPI о мин-макс времени таблицы
  useEffect(() => {
    const CSSSelector =
      '#ScheduleTableWidthWrapper > div > div.fc-view-harness.fc-view-harness-active > div.fc-timegrid.fc-displayByDay-view.fc-view > table > tbody > tr > td > div > div > div > div.fc-timegrid-slots > table > tbody';
    const targetNode = document.querySelector(CSSSelector);
    const observerOptions = { childList: true };
    const observer = new MutationObserver(() => setKey((prevKey) => prevKey + 1));
    observer.observe(targetNode, observerOptions);

    return () => observer.disconnect();
  }, []);

  const minTime = schedulerAPI?.getOption('slotMinTime').split(':').slice(0, 2).join(':');
  const maxTime = schedulerAPI?.getOption('slotMaxTime').split(':').slice(0, 2).join(':');
  const allAxisHours = minTime && maxTime ? getHoursStrings(minTime, maxTime) : [];
  const getAxisHourTopPos = (index) => index * 66 - index * 1 - (index === allAxisHours.length - 1 ? 15 : 0) + 27;

  return (
    <>
      {allAxisHours.map((axisHour, index) => (
        <TimeAxisHour key={axisHour} top={getAxisHourTopPos(index)}>
          {axisHour}
        </TimeAxisHour>
      ))}
    </>
  );
};

export default TimeAxis;
