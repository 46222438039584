import React, { FC, useCallback, useEffect } from 'react';
import { Column, RowExtendedType, Table, TableAction } from '../../../components/Table';
import { SubRow } from './SubRow';
import { InvoiceRowType, useGetInvoiceRowsQuery } from '../../../services/invoice';
import { RowBlock, TableWrapper } from '../styles';
import { TypographyBox } from '../index';
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { TableSubTitle } from '../../MedcardPage/Account/styles';
import { groupBy } from 'lodash';
import { TotalData } from './TotalData';
import { Totals } from '../enums';
import { VisitDataType } from './VisitServices';
import { useGetTotals } from '../utils';
import { Modal } from '../../../uikit/Modal';
import { CommentsForm } from '../../../uikit/CommentsForm';
import { MedcardEntry } from '../../../services/medcard';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSubInvoices,
  addSubInvoiceRowsRefetchCallbacks,
  refundModificatorSelector,
  setTableDataIsUpdating,
  setUpdatingTableRowId,
} from '../reducer';
import { RootState } from '../../../store';

type ServicesTableType = {
  subInvoiceId: number;
  createdDate: string;
  subIndex: number;
  onScrollTop: () => void;
  subInvoicesQuantity: number;
  columns: Column<VisitDataType>[];
  actions: TableAction<VisitDataType>[];
  currentRowId: number;
  commentForm: null | HTMLElement;
  onCloseCommentForm: () => void;
  showComments: boolean;
  commentFormHeight: number;
  currentRefundRow?: RowExtendedType<VisitDataType>;
};

export const ServicesTable: FC<ServicesTableType> = ({
  subInvoiceId,
  columns,
  actions,
  createdDate,
  subIndex,
  onScrollTop,
  subInvoicesQuantity,
  currentRowId,
  commentForm,
  onCloseCommentForm,
  showComments,
  commentFormHeight,
  currentRefundRow,
}) => {
  const dispatch = useDispatch();
  const { data: invoiceRows = [], refetch } = useGetInvoiceRowsQuery(subInvoiceId, { refetchOnMountOrArgChange: true });
  const [totalForPay, totalPaid, totalPaymentType] = useGetTotals(invoiceRows);
  const subInvoiceRows = useSelector((state: RootState) => state.visitsAndPayments.subInvoices[subInvoiceId]);

  const totalsModificator = useSelector((state: RootState) => refundModificatorSelector(state, subInvoiceId));
  const refundType = useSelector((state: RootState) => state.visitsAndPayments.refundType);

  const invoiceDate = new Date(createdDate);

  const renderRowSubComponent = useCallback(
    (row, rowProps) => {
      const currentRefundRowBackendId = currentRefundRow
        ? currentRefundRow.depth === 0
          ? currentRefundRow.original.paymentOperations[0].id
          : currentRefundRow.original.id
        : null;
      const rowBackendId = row.depth === 0 ? row.original.paymentOperations[0]?.id : row.original.id;
      if (rowBackendId === currentRefundRowBackendId) {
        return <SubRow row={row} rowProps={rowProps} />;
      }
      return null;
    },
    [currentRefundRow],
  );

  const rowsByPrice = 'rowsByPrice';

  useEffect(() => {
    if (invoiceRows.length) {
      const groupedRows = groupBy(invoiceRows, 'type');
      const rowsByPlan = groupBy(groupedRows[InvoiceRowType.TREATMENT_PLAN], 'externalEntry.id');

      dispatch(addSubInvoices([subInvoiceId, { ...rowsByPlan, rowsByPrice: groupedRows[InvoiceRowType.PRICE] }]));

      dispatch(addSubInvoiceRowsRefetchCallbacks({ [subInvoiceId]: refetch }));
      dispatch(setTableDataIsUpdating(false));
      dispatch(setUpdatingTableRowId(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceRows]);

  const renderJournalEntryTitle = (entry: MedcardEntry) => {
    const toothIndex = entry.tooth_index;
    const toothTitle = entry.tooth_state.title_russian;
    const toothLabel = entry.tooth_state_label;

    if (toothIndex || toothTitle) {
      return `${toothIndex} ${toothTitle}`;
    }
    return `${toothLabel}`;
  };

  return (
    <>
      {!subInvoiceRows || !invoiceRows.length ? null : (
        <>
          <div style={{ marginTop: subIndex !== 0 ? '24px' : '0' }}>
            <RowBlock marginBottom="0" padding="6px 12px 0 12px">
              <TypographyBox>{renderJournalEntryTitle(invoiceRows[0]?.entry)}</TypographyBox>
              <div style={{ fontSize: '12px', color: '#A8AEB5', display: 'flex' }}>
                <div style={{ marginRight: 8 }}>Дата создания счета:</div>
                <div style={{ marginRight: 8 }}>{format(invoiceDate, 'dd MMM yyyy', { locale: ruLocale })}</div>
                {format(invoiceDate, 'HH:mm')}
              </div>
            </RowBlock>
          </div>
          <TableWrapper>
            {Object.entries(subInvoiceRows)?.map(([type, rows], index) => {
              if (rows && rows.length) {
                const doctorName = rows[0].externalEntry?.doctor.first_name[0];
                const doctorSurname = rows[0].externalEntry?.doctor.last_name;
                const doctorSecondName = rows[0].externalEntry?.doctor.second_name[0];

                return (
                  <div key={`${type} + ${index}`} data-wrapper="wrapper">
                    <TableSubTitle>
                      {type === rowsByPrice
                        ? 'Прейскурант'
                        : `План лечения: ${doctorSurname} ${doctorName}. ${doctorSecondName}.
                                               (${renderJournalEntryTitle(rows[0]?.externalEntry)})`}
                    </TableSubTitle>
                    <Table<VisitDataType>
                      columns={columns}
                      data={rows}
                      actions={actions}
                      hiddenColumns={['id']}
                      visibleRowCount={12}
                      canAutoHeight={false}
                      canEditEntry={false}
                      renderRowSubComponent={renderRowSubComponent}
                      canFocusRow
                      changeColumnOrder
                      isAllRowsExpanded
                      getHeaderProps={() => ({
                        style: {
                          background: '#fff',
                        },
                      })}
                    />
                  </div>
                );
              }
            })}
          </TableWrapper>
          {subInvoicesQuantity > 1 ? (
            <TotalData
              totalName={Totals.TOTAL_INVOICE}
              paymentType={totalPaymentType}
              paid={totalsModificator ? totalPaid - totalsModificator.value : totalPaid}
              remainder={
                refundType === 'withDebt' && totalsModificator
                  ? totalPaid - totalForPay - totalsModificator.value
                  : totalPaid - totalForPay
              }
              payment={
                currentRefundRow &&
                totalsModificator &&
                refundType === 'noDebt' &&
                invoiceRows.some(
                  (row) =>
                    row.id ===
                    (currentRefundRow.depth === 0 ? currentRefundRow.original.id : currentRefundRow.original.rowId),
                )
                  ? totalForPay - totalsModificator.value
                  : totalForPay
              }
              handleScrollTop={onScrollTop}
              customStyle
            />
          ) : null}
          <Modal anchor={commentForm}>
            <CommentsForm
              handleSetOpenForm={onCloseCommentForm}
              scrollTop={onScrollTop}
              positionId={currentRowId}
              showComments={showComments}
              formHeight={commentFormHeight}
              subInvoiceId={subInvoiceId}
            />
          </Modal>
        </>
      )}
    </>
  );
};
