import React from 'react';
import ArrowButton from './ArrowButton';
import OpenButton from './OpenButton';
import { Flex } from './styled-components';
import { useAppDispatch } from '../../store/hooks';
import { setSelectedDate } from '../../pages/CalendarPage/reducer';

/**
 * Компонент отображения календаря (кнопка с выбранной датой и две стрелки)
 * @param {TextFieldProps} params - параметры, передающиеся DatePicker'ом (оттуда нужна строка с выбранной датой и ссылка inputRef, чтобы Popper открывался в нужном месте)
 * @param {boolean} isOpened - состояние выборщика дат: раскрыт ли он?
 * @param {function} setIsOpened - callback для изменения состояния выборщика дат: раскрыт ли он?
 * @param {number} selectedDate - выбранная в календаре дата, в миллисекундах
 */

const RenderInput = ({ params, selectedDate, isOpened, setIsOpened }) => {
  const dispatch = useAppDispatch();

  const handleClick = (modifier) => {
    const dayInMilliseconds = 86400000;
    const modifySelectedDateByValue = dayInMilliseconds * modifier;

    dispatch(setSelectedDate(selectedDate + modifySelectedDateByValue));
  };

  return (
    <Flex style={{ visibility: isOpened ? 'hidden' : 'unset' }}>
      <ArrowButton id="arrowButtonLeft" handleClick={() => handleClick(-1)} iconSize={10} />
      <OpenButton
        id="openButton"
        params={params}
        isOpened={isOpened}
        handleClick={() => {
          isOpened ? setIsOpened(false) : setIsOpened(true);
        }}
        selectedDate={selectedDate}
      />
      <ArrowButton id="arrowButtonRight" handleClick={() => handleClick(1)} iconSize={10} isRight />
    </Flex>
  );
};

export default RenderInput;
