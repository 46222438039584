import React from 'react';
import { Modal } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import StudyModalState from '../../components/StudyModal/state';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: () => ({
      position: 'absolute',
      width: 1132,
      height: 552,
    }),
  }),
);

export function StudyModal() {
  const dispatch = useDispatch();
  const modalState = useSelector((state: RootState) => state.studyModalState);
  const { hide } = StudyModalState.actions;
  const classes = useStyles();

  return (
    <Modal open={modalState.visible} className={classes.modal} onClose={() => dispatch(hide())}>
      <div className={classes.paper}>
        <embed
          src={modalState.videoUrl}
          wMode="transparent"
          type="video/mp4"
          width="100%"
          height="100%"
          allow="autoplay; encrypted-media; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Modal>
  );
}
