import { createSlice } from "@reduxjs/toolkit";
import {omit} from 'lodash';
import {createSelector} from "reselect";

const INITIAL_STATE = {
  isOpened: false,
  page: 1,
  patient: {
    first_name: "",
    second_name: "",
    last_name: "",
    phone: "",
    birth_date: "",
  },
  doctor: "Выбрать врача",
  cabinet: "Выбрать кабинет",
  time: {},
  allFields: false
};

const AppointmentFormState = createSlice({
  name: "AppointmentForm",
  initialState: INITIAL_STATE,
  reducers: {
    pickExistingPatient: (state, action) => ({
      ...state,
      patient: { ...action.payload, existing: true },
      page: 2,
    }),
    openNextPage: (state) => ({
      ...state,
      patient: { ...state.patient, existing: false },
      page: 2,
    }),
    startCreatingAppointment: (state, action) => ({
      ...state,
      isOpened: true,
      time: action.payload.time,
      cabinet: action.payload.cabinet,
    }),
    changePatientDetails: (state, action) => ({
      ...state,
      patient: { ...state.patient, ...action.payload },
    }),
    pickDoctor: (state, action) => ({
      ...state,
      doctor: action.payload,
    }),
    closeAppointmentForm: (state) => ({
      ...state,
      isOpened: false,
    }),
    cancelCreatingAppointment: (state) => ({
      ...state,
      ...omit(INITIAL_STATE, 'allFields'),
    }),
    changeAllFields: (state, action) => ({
      ...state,
      allFields: action.payload,
    }),
  },
});

const staffSelector = state => state.clinic.staff;

export const doctorSelector = createSelector(
  staffSelector,
  items => items.filter(s => s.role === "DOC")
)

export default AppointmentFormState;
