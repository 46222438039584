import React, {useEffect, useRef, useState} from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: (props: {width: number; height: number}) => ({
      fontWeight: 'normal',
      fontSize: 14,
      color: '#515D6B',
      padding: '11px 8px',
      width: props.width,
      height: props.height
    }),
  }),
);

type Props = {
  text: string;
  width: number;
  height: number;
}

export function TextWithPopover({text, width, height}: Props) {
  const classes = useStyles({width, height});
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const textDiv = useRef(null);
  const timeoutId = useRef(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (textDiv && textDiv.current) {
      if (textDiv.current.scrollWidth > textDiv.current.clientWidth) {
        const currentTarget = event.currentTarget;
        clearInterval(timeoutId.current);
        timeoutId.current = setTimeout(() => {
          setAnchorEl(currentTarget);
        }, 2000)
      }
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    clearInterval(timeoutId.current);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div
        ref={textDiv}
        style={{textOverflow: 'ellipsis',  whiteSpace: 'nowrap', overflow: 'hidden'}}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {text}
      </div>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {text}
      </Popover>
    </>
  );
}
