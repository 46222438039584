import React, { memo } from 'react';
import { SettingsTitle, CustomTooltip } from '../styled-components';
import StaffColor from './StaffColor';
import { useAppSelector } from '../../../../../store/hooks';

export const StaffScheduleColorPicker: React.FC = () => {
  const currentStaffer = useAppSelector((state) => state.staffSchedule.currentStaffer);

  return (
    <>
      <SettingsTitle>Цветовое обозначение</SettingsTitle>
      <CustomTooltip
        open={Boolean(!currentStaffer.color || currentStaffer.color === '#fff')}
        title="Выберите цвет для отображения рабочего времени сотрудника в расписании."
        arrow
        placement="right"
      >
        <StaffColor />
      </CustomTooltip>
    </>
  );
};

export default memo(StaffScheduleColorPicker);
