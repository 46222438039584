import React from "react";
import { connect } from "react-redux";

import StreamHeader from "./StreamHeader";
import SearchHeader from "./SearchHeader";

function StreamHeaderWrapper({ patientsPage, ...props }: any) {
  return (
    <>
      {patientsPage.isCreating ? (
        <StreamHeader {...props} />
      ) : (
        <SearchHeader {...props} />
      )}
    </>
  );
}
export default connect(
  (state: any) => ({ patientsPage: state.patientsPage }),
  {}
)(StreamHeaderWrapper);
