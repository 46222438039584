import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  width: 477px;
  height: 216px;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #919191;
  flex-wrap: wrap;
  button {
    margin: 0;
    border: 0;
    outline: none;
    display: inline-block;
    background: none;
    color: #577bf9;
  }
`;
type EmptyPageProps = {
  source: string;
  marginBottom: number;
  children: ReactNode;
};
export function EmptyPage({ source, children, marginBottom }: EmptyPageProps) {
  return (
    <Container>
      <img src={source} alt={'report'} width={140} height={140} style={{ marginBottom: marginBottom }} />
      <Text>{children}</Text>
    </Container>
  );
}
