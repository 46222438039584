import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { TreatmentPlanItem } from './TreatmentPlanItem';
import { DotsIcon } from '../../../../uikit/Icons';
import { TreatmentPlanTemplate } from './TreatmentPlanTemplates';

const ContentTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #515d6b;
  line-height: 22px;
  padding: 16px;
  border-bottom: 2px solid #d9d9d9;
`;

const ContentSubTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  margin-left: 8px;
`;

const EmptyText = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #a8aeb5;
  line-height: 16px;
  padding: 19px 16px 0 16px;
`;

type Props = {
  style?: CSSProperties;
  title: string;
  subTitle?: string;
  templates: TreatmentPlanTemplate[];
  isDictionary?: boolean;
  onClickMenu: () => void;
  onAddTemplate: () => void;
};

export function TreatmentPlanList({
  style,
  title,
  subTitle,
  templates,
  isDictionary = false,
  onClickMenu,
  onAddTemplate,
}: Props) {
  return (
    <div style={style}>
      <ContentTitle>
        {title}. {subTitle && <ContentSubTitle>{subTitle}</ContentSubTitle>}
      </ContentTitle>
      {templates.length
        ? templates.map((template) => (
            <TreatmentPlanItem
              key={template.id}
              template={template}
              isDictionary={isDictionary}
              onClickMenu={onClickMenu}
              onAddTemplate={onAddTemplate}
              isMenuVisible
            />
          ))
        : !isDictionary && (
            <EmptyText>
              Чтобы добавить в шаблон нажмите на &nbsp;“
              <DotsIcon />“
            </EmptyText>
          )}
    </div>
  );
}
