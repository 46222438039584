import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { uniq } from 'ramda';

import TextInput from '../../../uikit/TextInput';
import { TextCutOFF } from '../../../components/DoctorFilter/styles';
import { ColumnLayout } from './Column';
import StaffState from '../state';
import WithSuggestions from '../../../components/Suggestions/WithSuggestions';
import SuggestionsState from '../../../components/Suggestions/state';

import { RootState } from '../../../store';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
interface ColumnProps {
  children: ReactElement;
  name: string;
  isEditing: boolean;
  setData: ActionCreatorWithNonInferrablePayload;
  patientsPage: RootState['patientsPage'];
  openMenu: (string) => void;
  defaultValue: string;
  width?: number | string;
  fontSize?: number;
  paddingLeft?: number;
  fontWeightBold?: boolean;
  textAlign?: string;
  color?: string;
  flexGrow?: number;
}

function ColumnWithStream({
  children,
  name,
  isEditing,
  setData,
  patientsPage,
  openMenu,
  defaultValue,
  width,
  fontSize = 14,
  paddingLeft = 16,
  fontWeightBold = false,
  textAlign = 'left',
  color = '#515d6b',
  flexGrow = 0,
}: ColumnProps) {
  const suggestions = patientsPage.response;
  return (
    <ColumnLayout
      onClick={() => {
        openMenu(name);
      }}
      hasAction={true}
      width={width}
      fontSize={fontSize}
      paddingLeft={paddingLeft}
      fontWeightBold={fontWeightBold}
      textAlign={textAlign}
      color={color}
      flexGrow={flexGrow}
    >
      {isEditing ? (
        <div style={{ marginLeft: -8, width: Number(width) - 16 }}>
          <WithSuggestions
            value={patientsPage.data[name] ? patientsPage.data[name] : ''}
            name={name}
            suggestions={uniq(
              suggestions.map((patient) => patient.stream).filter((stream: string) => stream.length > 2),
            )}
            handlePick={(value: string) => {
              setData({ [name]: value });
            }}
          >
            <div style={{ height: 32 }}>
              <TextInput
                onFocus={() => {
                  openMenu(name);
                }}
                value={patientsPage.data[name]}
                defaultValue={defaultValue}
                onChange={(e) => setData({ [name]: e.target.value })}
              />
            </div>
          </WithSuggestions>
        </div>
      ) : (
        <TextCutOFF>{children}</TextCutOFF>
      )}
    </ColumnLayout>
  );
}

export default connect((state: RootState) => ({ patientsPage: state.patientsPage }), {
  setData: StaffState.actions.setData,
  openMenu: SuggestionsState.actions.openMenu,
})(ColumnWithStream);
