import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isShown: false,
  message: '',
};

const ErrorsSlice = createSlice({
  name: 'errors',
  initialState: INITIAL_STATE,
  reducers: {
    closeSnack: (state) => ({
      ...state,
      isShown: false,
    }),
    showError: (state, action) => ({
      ...state,
      isShown: true,
      message: action.payload,
    }),
  },
});

export default ErrorsSlice;
