import React from 'react';
import Column from '../../../PatientPage/components/Column';
import { PopupMenu } from '../PopupMenu';
import styled from 'styled-components';
import { TreatmentPlanTemplate } from './TreatmentPlanTemplates';
import { TextWithPopover } from '../TextWithPopover';
import Tooltip from '../../../../components/Tooltip/Tooltip';

const Row = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${({ selected }) => (selected ? '#F6F6FA' : 'white')};
  border-bottom: 1px solid #d9d9d9;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

type Props = {
  template: TreatmentPlanTemplate;
  editor?: string;
  isDictionary?: boolean;
  onClickMenu?: (template) => void;
  onAddTemplate: (template) => void;
  isMenuVisible: boolean;
};

export function TreatmentPlanItem({
  template,
  editor,
  isDictionary,
  onClickMenu,
  onAddTemplate,
  isMenuVisible,
}: Props) {
  return (
    <Row selected={template.selected}>
      {!!editor ? (
        <Tooltip
          title={
            <div style={{ display: 'flex', gap: '5px', alignItems: 'flex-start' }}>
              <img style={{ marginTop: 2 }} src="./img/black/info-circle.svg" alt="attention" />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>{`Редактирует ${editor}`}</div>
                <div>Одновременная работа недоступна</div>
              </div>
            </div>
          }
          placement={'bottom'}
          enterDelay={500}
          enterNextDelay={500}
        >
          <div style={{ display: 'flex', flex: 1 }}>
            <Column name="code" color="#A8AEB5" defaultValue={template.code} width={110} isEditing={false} hasAction>
              {template.code}
            </Column>
            <Column
              name="text"
              color="#A8AEB5"
              defaultValue={template.text}
              width={350}
              flexGrow={1}
              isEditing={false}
              hasAction
            >
              <TextWithPopover text={template.text} width={386} height={70} />
            </Column>
            <Column name="price" color="#A8AEB5" defaultValue={template.price} width={100} isEditing={false} hasAction>
              {template.price}
            </Column>
          </div>
        </Tooltip>
      ) : (
        <div style={{ display: 'flex', flex: 1 }} onClick={() => !template.selected && onAddTemplate(template)}>
          <Column name="code" defaultValue={template.code} width={110} isEditing={false} hasAction>
            {template.code}
          </Column>
          <Column name="text" defaultValue={template.text} width={350} flexGrow={1} isEditing={false} hasAction>
            <TextWithPopover text={template.text} width={386} height={70} />
          </Column>
          <Column name="price" defaultValue={template.price} width={100} isEditing={false} hasAction>
            {template.price}
          </Column>
        </div>
      )}
      {isMenuVisible && (
        <div style={{ margin: '0 16px' }}>
          <PopupMenu
            actions={[
              {
                name: isDictionary ? 'ADD' : 'DELETE',
                text: isDictionary ? 'Добавить в шаблон' : 'Удалить из шаблона',
                handler: () => onClickMenu?.(template),
              },
            ]}
          />
        </div>
      )}
    </Row>
  );
}
