import styled from 'styled-components';

export const FilterLayout = styled.div`
  background: #ffffff;
  border-radius: 4px;
  width: 256px;
  margin-top: 0px;
  padding-bottom: 8px;
`;

export const JobTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #a8aeb5;
  padding-left: 16px;
  height: 32px;
  letter-spacing: 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => (props.picked ? '#F6F6FA' : '#fff')};
  cursor: ${(props) => (props.noSelect ? 'default' : 'pointer')};

  &:hover {
    background-color: ${(props) => (!props.noSelect ? '#F6F6FA' : '#fff')};
  }
`;

export const TextCutOFF = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const JobLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #515d6b;
  padding-left: 16px;
  height: 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 0px;
  background-color: ${(props) => (props.picked ? '#F6F6FA' : '#fff')};
  cursor: pointer;

  &:hover {
    background-color: #f6f6fa;
  }
`;

export const SearchField = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;
export const Spacer = styled.div`
  height: 22px;
  width: 100%;
`;
