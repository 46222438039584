import React from 'react';
import { Dialog } from '../../../../uikit/Dialog';

interface BlockAccountProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  isLoading?: boolean;
}

export const BlockAccount: React.FC<BlockAccountProps> = ({ onSave, ...restProps }) => {
  return (
    <Dialog
      {...restProps}
      onSubmit={onSave}
      title="Вы уверены что хотите заблокировать сотрудника?"
      text="В случае блокировки: удалится расписание, сотрудник не сможет войти в программу."
    />
  );
};
