import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import Header from '../../components/Header/TasksHeader';
import Drawer from '../../components/Drawer';
import HeaderRow from './components/HeaderRow';
import Body from './components/Body';
import { initPatientPage } from './operations';
import CreateButton from './components/CreateButton';
import SaveButtons from './components/SaveButtons';
import Redirection from './components/Redirector';
import PatientsState from './state';
import NewRowAnimation from '../StaffPage/NewRowAnimation';
import { PAGE_HEADER_HEIGHT, PAGE_PADDING, TABLE_ROW_HEIGHT } from '../StaffPage/style';
import { routerConfig } from '../../navigation/routerConfig';
import { patientFormSchema } from './utils';

const Page = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #f9f9f9;
  padding-right: 45px;
  height: 100vh;
  overflow: hidden;
  left: 2.5%;
  position: relative;
`;
const Layout = styled.div`
  width: 100%;
  min-width: 1024px;
  max-width: 1444px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 50px;
`;
const Box = styled.div`
  width: 100%;
  min-width: 1344px;
  max-width: 1444px;
  margin-top: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  height: calc(100vh - 96px);
`;
const TableContainer = styled.div`
  padding-top: 24px;
`;
const AddContainer = styled.div`
  margin-right: auto;
`;
const Actions = styled.div`
  display: flex;
  flex: 0;
  flex-direction: row;
  min-width: 1344px;
  max-width: 1444px;
  margin-left: 24px;
`;

function PatientsPage({ initPatientPage, patientsPage, dropState }) {
  const methods = useForm({
    resolver: yupResolver(patientFormSchema),
  });
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  function handleWindowResize() {
    setInnerHeight(window.innerHeight);
  }
  useEffect(() => {
    initPatientPage();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      dropState();
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [dropState, initPatientPage]);

  return (
    <Page>
      <div
        style={{
          width: '100vw',
          paddingLeft: PAGE_PADDING,
          paddingRight: PAGE_PADDING,
        }}
      >
        <Header text={routerConfig.PATIENTS.title} />
        <Drawer />
      </div>

      <Layout>
        <Box>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Actions>
              <AddContainer style={{ height: 60 }}>
                <CreateButton />
              </AddContainer>
              <FormProvider {...methods}>
                <SaveButtons />
              </FormProvider>
            </Actions>
            <TableContainer>
              <NewRowAnimation
                width={1344}
                isShown={patientsPage.newPatientId !== null}
                rowNumber={patientsPage.patients.allIds.indexOf(patientsPage.newPatientId)}
              />
              <div>
                <FormProvider {...methods}>
                  <HeaderRow />
                </FormProvider>
                <div
                  style={{
                    borderBottom:
                      innerHeight < patientsPage.patients.allIds.length * TABLE_ROW_HEIGHT + PAGE_HEADER_HEIGHT
                        ? 'solid 2px #7C8590'
                        : 'none',
                  }}
                >
                  <Scrollbars
                    style={{
                      width: '100%',
                      height: 'calc(100vh - 235px)',
                    }}
                  >
                    <Body />
                  </Scrollbars>
                </div>
              </div>
            </TableContainer>
          </div>
        </Box>
      </Layout>
      <Redirection />
    </Page>
  );
}

export default connect((state) => ({ common: state.common, patientsPage: state.patientsPage }), {
  initPatientPage,
  dropState: PatientsState.actions.dropState,
})(PatientsPage);
