import Header from '../../components/Header/PageHeader';
import { routerConfig } from '../../navigation/routerConfig';
import Drawer from '../../components/Drawer';
import React, { useEffect, useRef, useState } from 'react';
import { LinkButton } from '../../uikit/Button/LinkButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useAppSelector } from '../../store/hooks';
import { BalanceBlock } from './components/BalanceBlock';
import OutlinedButton from '../../uikit/Button/OutlinedButton';
import { styled, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Scrollbars from 'react-custom-scrollbars-2';
import { EmptyPage } from '../../widgets/EmptyPage';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CenterPage, Container, Layout, NoInvoiceBlock, RowBlock, SubTitle, TableContainer } from './styles';
import { VisitStatus } from './components/VisitStatus';
import { VisitServices } from './components/VisitServices';
import { OptionsMenu } from './components/OptionsMenu';
import { ACTIVE_CLASS, ROOT_CONTAINER } from '../../uikit/Modal/constants';
import { Modal } from '../../uikit/Modal';
import { PrepayInfo } from './components/PrepayInfo';
import { useGetPermissionsQuery } from '../../services/users';
import { useCommentFormHeight, useScroll } from '../../uikit/Modal/hooks';
import { useGetAdvanceTotalQuery } from '../../services/advance';
import { useGetDebtAmountQuery, useGetTotalPaidAmountQuery, useGetVisitsQuery } from '../../services/billing';
import { VisitHeader } from './components/VisitHeader';
import medCardPageSlice from '../../pages/MedcardPage/state';
import { useAddEntryMutation } from '../../services/medcard';
import { useAddInvoiceMutation, useAddSubInvoiceMutation } from '../../services/invoice';
import { useAddPlanRecordMutation } from '../../services/medcardJournal';
import { EAccountStatus } from '../MedcardPage/Account/AccountStatus';
import FormulaState from '../MedcardPage/Formula/state';
import { FormulaType } from '../MedcardPage/Formula';
import { VisitComments } from '../../widgets/VisitComments';

const Accordion = withStyles({
  root: {
    border: '1px solid #e5e6ea',
    borderRadius: '4px',
    boxShadow: 'none',
    overflow: 'hidden',
    boxSizing: 'border-box',
    marginBottom: '16px',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginBottom: '16px',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: (props: { backcolor?: string }) => props.backcolor,
    flexDirection: 'row-reverse',
    fontSize: '14px',
    color: '#253446',
    fontWeight: 400,
    minHeight: 48,
    border: '1px solid transparent',
    borderRadius: '4px',
    '&$expanded': {
      minHeight: 48,
      borderRadius: '4px 4px 0 0',
    },
    '&.Mui-focused': {
      // перебиваем дефолтные стили при открытии модалки с формой комментариев
      backgroundColor: (props: { backcolor: string }) => props.backcolor,
      border: '1px solid #577BF9',
      borderRadius: '4px',
    },
    '&.active': {
      border: '1px solid #577BF9',
      borderRadius: '4px',
    },
  },
  content: {
    margin: '12px 26px 12px 0',
    '&$expanded': {
      margin: '12px 26px 12px 0',
    },
  },
  expanded: {},
  expandIcon: {
    padding: '0',
    margin: '0 26px 0 2px',
    width: '7px',
    height: '11px',
  },
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
})((props: any) => {
  const { classes, ...other } = props;
  return (
    <MuiAccordionSummary
      classes={{
        root: classes.root,
        expandIcon: classes.expandIcon,
        content: classes.content,
        expanded: classes.expanded,
      }}
      {...other}
    />
  );
});

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiAccordionDetails);

export const TypographyBox = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  marginRight: '12px',
});

export function VisitsAndPaymentsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const patient = useSelector((state: RootState) => state.medCardPageSlice.patientDetails);
  const currentPaymentId = useSelector((state: RootState) => state.medCardPageSlice.currentPaymentId);
  const staff = useSelector((state: RootState) => state.staff.responce);
  const { id: userId } = useAppSelector((state) => state.common.user);
  const { data: permissions } = useGetPermissionsQuery(userId);

  const [expanded, setExpanded] = useState<string | boolean | null>(null);
  const [expandedAll, setExpandedAll] = useState<boolean>(false);
  const [paymentDetailsModal, setPaymentDetailsModal] = useState<null | HTMLElement>(null);

  const modalRef = useRef(null);
  const scrollTop = useScroll(modalRef);
  const { modalHeight: commentFormHeight } = useCommentFormHeight(modalRef);

  const { data: visits = [], isLoading } = useGetVisitsQuery(patient.id, {
    skip: !patient.id,
    refetchOnMountOrArgChange: true,
  });

  const { data: advanceTotal } = useGetAdvanceTotalQuery(patient.id, { refetchOnMountOrArgChange: true });
  const { data: debtAmount } = useGetDebtAmountQuery(patient.id, { refetchOnMountOrArgChange: true });
  const { data: totalPaidAmount } = useGetTotalPaidAmountQuery(patient.id, { refetchOnMountOrArgChange: true });

  const { setAccountVisible, setCurrentPaymentId } = medCardPageSlice.actions;
  const { pickDate, addCurrentDate } = FormulaState.actions;

  const [addEntry] = useAddEntryMutation();
  const [addInvoice] = useAddInvoiceMutation();
  const [addSubInvoice] = useAddSubInvoiceMutation();
  const [addPlanRecord] = useAddPlanRecordMutation();

  const handleChange = (panel: string) => {
    if (!expandedAll) {
      if (!expanded || expanded !== panel) {
        setExpanded(panel);
      } else {
        // dispatch(resetRefundData());
        setExpanded(false);
      }
    }
  };
  const handleExpandAll = () => {
    setExpandedAll(!expandedAll);
    setExpanded(null);
  };

  const handlePaymentDetailsOpen = (event: React.MouseEvent<HTMLElement>) => {
    const parentElement = event.currentTarget;
    setPaymentDetailsModal(parentElement);
    parentElement.classList.add(ACTIVE_CLASS);
  };
  const handlePaymentDetailsClose = () => {
    paymentDetailsModal.classList.remove(ACTIVE_CLASS);
    setPaymentDetailsModal(null);
  };
  const getInvoiceAuthor = (employeeId) => {
    return staff.find((employee) => employee.id === employeeId);
  };

  const handleCreateAccount = () => {
    addEntry({
      entry: {
        doctor: userId,
        formula_type: FormulaType.Adult,
        tooth_index: '',
        tooth_state_mnemonic: '',
        tooth_treatment_mnemonic: '',
        tooth_state_label: 'Не указано',
      },
      patientId: patient.id,
    })
      .unwrap()
      .then(({ id, date_id }) => {
        dispatch(addCurrentDate(date_id));
        dispatch(pickDate(date_id));

        addInvoice({ date_id })
          .unwrap()
          .then(({ id: invoiceId }) => {
            addSubInvoice({ invoice: invoiceId, formula_id: id, status: EAccountStatus.NOT_FILLED });
          });

        addPlanRecord({
          patientId: patient.id,
          entryId: id,
        });

        dispatch(setAccountVisible(true));
        navigate(routerConfig.MEDCARD.path);
      });
  };

  useEffect(() => {
    if (!patient.id) {
      navigate(routerConfig.PATIENTS.path, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.id]);

  useEffect(() => {
    if (currentPaymentId) {
      setExpanded(`invoice:${currentPaymentId}`);
    }
    return () => {
      dispatch(setCurrentPaymentId(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaymentId]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '330px',
          }}
        >
          <div className="spinner" />
        </div>
      );
    }

    if (!visits.length) {
      return (
        <EmptyPage source="./img/white/clipboard.png" marginBottom={32}>
          Здесь пока нет визитов и оплат. <br />
          Для создания счета нажмите<button onClick={handleCreateAccount}>сформировать счет</button>
        </EmptyPage>
      );
    }
    return (
      <>
        <RowBlock marginBottom={'25px'} padding={'0 34px 0 20px'}>
          <LinkButton onClick={handleExpandAll}>{expandedAll ? 'Скрыть все счета' : 'Раскрыть все счета'}</LinkButton>
          {permissions['create_account'] && (
            <OutlinedButton width={164} height={32} style={{ marginTop: 0 }} onClick={handleCreateAccount}>
              Сформировать счет
            </OutlinedButton>
          )}
        </RowBlock>
        <CenterPage>
          <Scrollbars
            autoHide
            ref={modalRef}
            style={{
              width: '100%',
              height: 'calc(100vh - 215px)',
            }}
          >
            <Container id={ROOT_CONTAINER}>
              {visits.map(({ appointment, invoice, hasAppointment, hasInvoice }) => {
                const expandedId = hasAppointment ? `appointment:${appointment.id}` : `invoice:${invoice.id}`;
                const invoiceAuthor = getInvoiceAuthor(hasAppointment ? appointment.doctor : invoice.paymaster);

                return (
                  <Accordion square expanded={expanded === expandedId || expandedAll} key={expandedId}>
                    <AccordionSummary
                      backcolor={invoiceAuthor?.color}
                      expandIcon={<ExpandMoreIcon onClick={() => handleChange(expandedId)} />}
                    >
                      <VisitHeader
                        isAppointmentHeader={hasAppointment}
                        employee={invoiceAuthor?.fullname}
                        employeeJob={invoiceAuthor?.job}
                        visitStartedAt={hasAppointment ? appointment.starts_at : ''}
                        visitEndedAt={hasAppointment ? appointment.ends_at : ''}
                        invoiceCreatedAt={hasInvoice ? invoice.created_at : ''}
                      />
                      <VisitStatus status={hasInvoice ? invoice.status : appointment.status} />
                      <div style={{ width: '20px', height: '20px' }}>
                        {hasInvoice && (
                          <OptionsMenu
                            handleScrollTop={scrollTop}
                            invoiceId={invoice.id}
                            formHeight={commentFormHeight}
                          />
                        )}
                      </div>
                      <VisitComments
                        commentFormHeight={commentFormHeight}
                        invoiceId={invoice?.id}
                        scrollTop={scrollTop}
                        cancelReason={appointment?.cancel_reason}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      {!hasInvoice ? (
                        <NoInvoiceBlock>Нет выставленного счета на оплату</NoInvoiceBlock>
                      ) : (
                        <TableContainer>
                          <VisitServices
                            invoiceId={invoice.id}
                            handleScrollTop={scrollTop}
                            commentFormHeight={commentFormHeight}
                            isParentAccordionExpanded={expanded === expandedId || expandedAll}
                          />
                        </TableContainer>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Container>
          </Scrollbars>
        </CenterPage>
      </>
    );
  };

  return (
    <Layout>
      <div style={{ background: '#fff', marginBottom: '24px', position: 'fixed', width: '100%' }}>
        <Header
          text={
            <div>
              {routerConfig.VISITS_AND_PAYMENTS.title}
              <SubTitle>
                Медкарта
                <LinkButton
                  style={{ marginRight: 16 }}
                  disableRipple
                  onClick={() => navigate(routerConfig.MEDCARD.path)}
                >
                  №{patient.id}
                </LinkButton>
                Пациент: {`${patient.last_name} ${patient.first_name} ${patient.second_name}`}
              </SubTitle>
            </div>
          }
        />
      </div>
      <Drawer />
      <div style={{ display: 'flex', paddingLeft: '20px', margin: '80px 0 12px 0' }}>
        <BalanceBlock title="Общая сумма оплат" value={totalPaidAmount} color="#253446" />
        <BalanceBlock title="Аванс" value={advanceTotal} color="#0D853D" handleClick={handlePaymentDetailsOpen} />
        <BalanceBlock title="Задолженность" value={debtAmount} color="#C3000A" />
      </div>
      {renderContent()}
      <Modal anchor={paymentDetailsModal} unSetContainer={true} placementModal="bottom-start">
        <PrepayInfo
          handleSetOpenForm={handlePaymentDetailsClose}
          currentBalance={advanceTotal}
          patientId={patient.id}
        />
      </Modal>
    </Layout>
  );
}
