import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IPatient } from '../pages/CalendarPage/modals/VisitCard/types';
import { HttpMethod, baseURL } from '../utils/request';

export const patientsApi = createApi({
  reducerPath: 'patientsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['Patients'],
  endpoints: (builder) => ({
    getPatients: builder.query<IPatient[], number>({
      query: (clinicId) => ({ url: `clinics/${clinicId}/patients` }),
      providesTags: ['Patients'],
      transformResponse: (response) => response.items,
    }),
    addPatient: builder.mutation<IPatient, Partial<IPatient>>({
      query: (body) => ({
        url: `clinics/${body.clinic}/patients`,
        method: HttpMethod.POST,
        body,
      }),
      invalidatesTags: [{ type: 'Patients', id: 'LIST' }],
    }),
    updatePatient: builder.mutation<IPatient, Partial<IPatient>>({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `clinics/patients/${id}`,
          method: HttpMethod.PATCH,
          body,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Patients', id: arg.id },
        { type: 'Patients', id: 'LIST' },
      ],
    }),
  }),
});

export const { endpoints, useGetPatientsQuery, useAddPatientMutation, useUpdatePatientMutation } = patientsApi;
