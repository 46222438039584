import styled from 'styled-components';

interface OptionProps {
  selected: boolean;
}

interface CategoryProps {
  selected: boolean;
}

interface SelectedInputProps {
  width: number;
}

interface SelectorProps {
  width?: number;
}

interface OptionsContainerProps {
  width?: number;
}

export const Selector = styled.div<SelectorProps>`
  width: ${({ width }) => (width ? width + 'px' : '300px')};
`;

export const SelectedOption = styled.div`
  height: 32px;
  background: #ffffff;
  border: 1px solid #d3d6da;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  cursor: pointer;
  overflow: hidden;
`;

export const SelectedInput = styled.input<SelectedInputProps>`
  border: 0;
  background: #ffffff;
  font-size: 14px;
  color: #515d6b;
  cursor: pointer;
  width: ${({ width }) => (width ? width + 1 + 'ch' : '20ch')};
  overflow-y: hidden;
  &::placeholder {
    color: #a8aeb5;
  }
  &:focus {
    outline: none;
  }
`;

export const OptionsContainer = styled.div<OptionsContainerProps>`
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  padding: 12px 0;
  box-sizing: border-box;
  width: ${({ width }) => (width ? width + 'px' : '300px')};
  margin-top: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 110px);

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: #e5e6ea;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bec1c5;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-corner {
    background-color: #e5e6ea;
  }
`;

export const Search = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
`;

export const SearchInput = styled.input`
  border: 0;
  background: #ffffff;
  font-size: 14px;
  color: #515d6b;
  width: 100%;
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
  &:focus {
    outline: none;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid #f6f6fa;
  margin: 12px 12px 12px;
`;

export const Category = styled.div<CategoryProps>`
  color: ${({ selected }) => (selected ? '#577BF9' : '#A8AEB5')};
  font-size: 14px;
  margin: 12px 12px 2px 12px;
  cursor: pointer;
`;

export const Option = styled.div<OptionProps>`
  font-size: 14px;
  color: #515d6b;
  box-sizing: border-box;
  padding: 0 12px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  white-space: nowrap;
  background: ${({ selected }) => (selected ? '#F6F6FA' : '#FFFFFF')};
  cursor: pointer;
  &:hover {
    background: #eff2ff;
  }
`;

export const Circle = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background: #f6f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #7c8590;
  margin-left: 2px;
`;

export const EmptySelectPlaceholder = styled.div<{ paddingX?: number }>`
  padding: 6px ${({ paddingX }) => (paddingX ? paddingX : 30)}px;
  text-align: center;
`;

export const SecondaryText = styled.span`
  color: #7c8590;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
`;

export const SecondaryLink = styled(SecondaryText)`
  color: #7995fa;
  cursor: pointer;
`;
