import React from "react";
import styled from "styled-components";

const Border = styled.div<{ isEdit: boolean, isFirst?: boolean }>`
  padding: ${({isEdit, isFirst = false}) => isFirst ? 1 : (isEdit ? 1 : 0.5)}px;
  background: ${({isEdit, isFirst = false}) => isEdit ? '#577BF9' : (isFirst ? '#7C8590' : '#D9D9D9')};
  margin-left: ${({isEdit}) => isEdit ? 4 : 0}px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Actions = styled.div`
  flex: 0;
  width: 84px;
  height: 54px;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #515d6b;
  display: flex;
  position: relative;
  margin-left: 18px;
`;

const Plus = styled.img<{currentIndex: number}>`
  position: absolute;
  top: -12px;
  left: ${({currentIndex}) => currentIndex === 0 ? 4 : -12}px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  z-index: 1;
`;

type Props = {
  index: number;
  isEditMode: boolean;
  currentIndex: number;
  setCurrentIndex: Function;
  onAddTemplate: Function;
}

export function TextTemplateItemBorder(
  {
    index,
    isEditMode,
    currentIndex,
    setCurrentIndex,
    onAddTemplate

  }: Props) {
  const isFirst = index === 0;
  return (
    <div style={{position: 'relative'}}>
      {isEditMode && currentIndex === index && <Plus
        currentIndex={currentIndex}
        onClick={() => {
          onAddTemplate();
        }}
        src={"./img/blue/priceadd.svg"}
      />}
      <Border
        isEdit={isEditMode && currentIndex === index}
        isFirst={isFirst}
        onMouseEnter={() => isEditMode && setCurrentIndex(index)}
      />
    </div>
  )
}
