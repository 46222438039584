import React from 'react';
import styled from 'styled-components';

const DebtContainer = styled.div`
  background: #ffffff;
  border: 1px solid rgba(239, 240, 243, 0.7);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 144px;
  padding: 8px 14px;
`;

const DebtTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  color: #515d6b;
`;

const DebtValue = styled.div<{ active: boolean }>`
  font-size: 16px;
  line-height: 120%;
  color: ${({ active }) => (active ? '#c3000a' : '#a8aeb5')};
`;
type DebtProps = {
  debtAmount: number;
};
export function Debt({ debtAmount }: DebtProps) {
  return (
    <DebtContainer>
      <DebtTitle>Задолженность</DebtTitle>
      <DebtValue active={debtAmount > 0}>{debtAmount} ₽</DebtValue>
    </DebtContainer>
  );
}
