import styled from "styled-components";

type HeaderColProps = {
  width: number;
  paddingLeft?: number;
  paddingRight?: number;
};
export const HeaderColumn = styled.div<HeaderColProps>`
  width: ${({width}) => width}px;
  height: 54px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  background: #f9f9f9;
  padding-left: ${({paddingLeft}) => paddingLeft || 8}px;
  padding-right: ${({paddingRight}) => paddingRight || 0}px;
  padding-top: 16px;
  border-bottom: 2px solid #7c8590;
  border-top: 2px solid #7c8590;
`;
