import React, { FC } from 'react';
import { SubTitle, Title } from './styles';
import { LinkButton } from '../../../uikit/Button/LinkButton';
import { routerConfig } from '../../../navigation/routerConfig';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import medCardPageSlice from '../../../pages/MedcardPage/state';

type PaymentHeaderType = {
  id: number;
  name: string;
  middleName: string;
  surname: string;
};

export const PaymentHeader: FC<PaymentHeaderType> = ({ id, name, middleName, surname }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { setAccountVisible } = medCardPageSlice.actions;

  return (
    <div
      style={{
        display: 'flex',
        padding: '16px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <Title>Оплата счета</Title>
        <SubTitle>Медкарта № {id}</SubTitle>
        <SubTitle>
          Пациент: {middleName} {name} {surname}
        </SubTitle>
      </div>
      <LinkButton
        style={{ paddingLeft: 0, marginTop: 6 }}
        onClick={() => {
          navigate(routerConfig.VISITS_AND_PAYMENTS.path);
          dispatch(setAccountVisible(false));
        }}
      >
        Визиты и оплаты
      </LinkButton>
    </div>
  );
};
