import React, { useEffect } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { EmptyPages } from './components/EmptyPages';
import { HistoryFilters } from './components/HistoryFilters';
import { resetHistoryFilter } from './reducer';

export const HistoryPage = () => {
  const dispatch = useAppDispatch();

  const onResetFilters = () => dispatch(resetHistoryFilter());

  useEffect(() => {
    return () => onResetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HistoryFilters />
      <EmptyPages data={null} showReset onResetFilters={onResetFilters} />
    </>
  );
};
