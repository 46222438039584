import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL, HttpMethod } from '../utils/request';

export enum SubInvoiceStatus {
  NOT_FILLED = 'NOT_FILLED',
  FILLED = 'FILLED',
  PENDING_SEND = 'PENDING_SEND',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  EDIT = 'EDIT',
  DEBT = 'DEBT',
  PAID = 'PAID',
}

type AppointmentStatus = 'SCH' | 'CNF' | 'COM' | 'CAN' | 'RES' | 'ARR' | 'PAY' | 'INP' | 'ABS';

type Appointment = {
  id: number;
  starts_at: string;
  ends_at: string;
  description: string;
  status: AppointmentStatus;
  comment: string;
  cancel_reason: string;
  created_at: string;
  doctor: number;
  patient: number;
  clinic: number;
  cabinet: number;
};
type Invoice = {
  id: number;
  created_at: string;
  medical_card_date: number;
  paymaster: number;
  status: SubInvoiceStatus;
};
export type Visit = {
  appointment: Appointment;
  invoice: Invoice;
};
export type TransformedVisit = Visit & {
  hasAppointment: boolean;
  hasInvoice: boolean;
};
type CalculatedRow = {
  invoice_row: number;
  payment: number;
  payment_type: number;
  payer: number;
};
type InitOperationItem = {
  id: number;
  operation_date: string;
  operation_type: 'PAY' | 'RET';
  payment: string;
  discount: number;
  discount_type: 'RUB' | 'PERCENT';
  returned: boolean;
  invoice: number;
  invoice_row: number;
  paymaster: number;
  payer: number;
  payment_type: number;
  total: string;
  return_status: 'DEBT' | 'NODEBT' | null;
};
export type OperationItem = {
  id: number;
  operationDate: string;
  operationType: 'PAY' | 'RET';
  payment: number;
  discount: number;
  discountType: 'RUB' | 'PERCENT';
  returned: boolean;
  invoice: number;
  invoiceRow: number;
  paymaster: number;
  payer: number;
  paymentType: number;
  total: number;
  return_status: 'DEBT' | 'NODEBT' | null;
};

export const billingApi = createApi({
  reducerPath: 'billingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['InvoiceOperations', 'InvoiceRowOperations', 'Debt', 'TotalPaid', 'Visits'],
  endpoints: (builder) => ({
    getVisits: builder.query<TransformedVisit[], number>({
      query: (patientId) => `billing/patients/${patientId}/invoices`,
      transformResponse: (response: Visit[]) => {
        return response
          .map(({ appointment, invoice }) => {
            return {
              appointment,
              invoice,
              hasAppointment: Boolean(Object.keys(appointment).length),
              hasInvoice: Boolean(Object.keys(invoice).length && invoice?.status !== SubInvoiceStatus.NOT_FILLED),
            };
          })
          .filter((item) => item.hasAppointment || item.hasInvoice);
      },
      providesTags: [{ type: 'Visits' }],
    }),
    getDebtAmount: builder.query<number, number>({
      query: (patientId) => `/billing/patients/${patientId}/debt`,
      transformResponse: (response: { debt: number }) => response.debt,
      providesTags: [{ type: 'Debt' }],
    }),
    getTotalPaidAmount: builder.query<number, number>({
      query: (patientId) => `billing/patients/${patientId}/operations/total`,
      transformResponse: (response: { total_payment: number }) => response.total_payment,
      providesTags: [{ type: 'TotalPaid' }],
    }),
    getInvoiceOperations: builder.query<OperationItem[], number>({
      query: (invoiceId) => `billing/invoices/${invoiceId}/operations`,
      transformResponse: (response: InitOperationItem[]) => {
        return response.map((operation) => ({
          id: operation.id,
          operationDate: operation.operation_date,
          operationType: operation.operation_type,
          payment: +operation.payment,
          discount: operation.discount,
          discountType: operation.discount_type,
          returned: operation.returned,
          invoice: operation.invoice,
          invoiceRow: operation.invoice_row,
          paymaster: operation.paymaster,
          payer: operation.payer,
          paymentType: operation.payment_type,
          return_status: operation.return_status,
          total: +operation.total,
        }));
      },
      providesTags: [{ type: 'InvoiceOperations', id: 'LIST' }],
    }),
    getInvoiceRowOperations: builder.query<OperationItem[], number>({
      query: (invoiceRowId) => `/billing/invoice_rows/${invoiceRowId}/operations`,
      transformResponse: (response: InitOperationItem[]) => {
        return response.map((operation) => ({
          id: operation.id,
          operationDate: operation.operation_date,
          operationType: operation.operation_type,
          payment: +operation.payment,
          discount: operation.discount,
          discountType: operation.discount_type,
          returned: operation.returned,
          invoice: operation.invoice,
          invoiceRow: operation.invoice_row,
          paymaster: operation.paymaster,
          payer: operation.payer,
          paymentType: operation.payment_type,
          return_status: operation.return_status,
          total: +operation.total,
        }));
      },
      providesTags: [{ type: 'InvoiceRowOperations', id: 'LIST' }],
    }),
    calculateInvoice: builder.mutation<void, { invoiceId: number; record: CalculatedRow[] }>({
      query: (data) => {
        const { invoiceId, record } = data;
        return {
          url: `billing/invoices/${invoiceId}/calculate`,
          method: HttpMethod.POST,
          body: record,
        };
      },
      invalidatesTags: [
        { type: 'InvoiceOperations', id: 'LIST' },
        { type: 'InvoiceRowOperations', id: 'LIST' },
      ],
    }),
    createRefundOperation: builder.mutation<void, { id: number; payment: number }>({
      query: (body) => {
        return {
          url: 'billing/return_payment_operations',
          method: HttpMethod.POST,
          body,
        };
      },
      invalidatesTags: [
        { type: 'InvoiceOperations', id: 'LIST' },
        { type: 'InvoiceRowOperations', id: 'LIST' },
        { type: 'Debt' },
        { type: 'TotalPaid' },
        { type: 'Visits' },
      ],
    }),
  }),
});
export const {
  useGetDebtAmountQuery,
  useGetTotalPaidAmountQuery,
  useGetVisitsQuery,
  useCalculateInvoiceMutation,
  useGetInvoiceOperationsQuery,
  useGetInvoiceRowOperationsQuery,
  useCreateRefundOperationMutation,
} = billingApi;
