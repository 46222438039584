import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      root: {
        zIndex: 22,
        position: 'absolute',
        bottom: 35,
        right: -10
      },
      tooltip: {
        height: (props: {height: number}) => props.height,
        width: (props: {width: number}) => props.width,
        position: 'relative',
        padding: '5px 8px',
        background: '#253446',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
        borderRadius: '2px',
        color: '#ffffff',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '22px',

        '&::after': {
          content: '""',
          position: 'absolute',
          top: (props: {height: number}) => props.height,
          right: (props: {right: number}) => props.right,
          borderStyle: 'solid',
          borderWidth: '7px 7px 0',
          borderColor: '#253446 transparent',
          display: 'block',
          width: 0,
          zIndex: 0,
        }
      }
    }
  ));

export function ErrorPopup(
  {
    isShown,
    text = '',
    width,
    height,
    right,
    style = {},
  }: {
    isShown: boolean;
    width: number;
    height: number;
    right: number;
    text?: string;
    style?: any;
  }) {
  const classes = useStyles({width, height, right});
  return (
    <>
      {isShown && (
        <div className={classes.root} style={style}>
          <div className={classes.tooltip}>{text}</div>
        </div>
      )}
    </>
  );
}
