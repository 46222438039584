import React, { FC, useState, useEffect } from 'react';
import { groupBy, capitalize } from 'lodash';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ClickAwayListener } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  SearchInput,
  Search,
  Circle,
  Option,
  SelectedOption,
  Selector,
  SelectedInput,
  OptionsContainer,
  Category,
  Divider,
  EmptySelectPlaceholder,
  SecondaryText,
  SecondaryLink,
} from './styles';
import SearchIcon from '../modals/VisitCard/icons/SearchIcon';
import { UserRole, useGetPermissionsQuery } from '../../../services/users';
import { setDoctors } from '../reducer';
import { IDoctor } from '../../CalendarPage/modals/VisitCard/components/DoctorSelect/types';
import { useNavigate } from 'react-router-dom';
import { routerConfig } from '../../../navigation/routerConfig';

interface IDoctorMultiSelectProps {
  initialValue: IDoctor[];
  styles?: React.CSSProperties;
}

const DoctorMultiSelect: FC<IDoctorMultiSelectProps> = ({ initialValue, styles = {} }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [selectedDoctors, setSelectedDoctors] = useState<IDoctor[]>(initialValue);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const doctors = useSelector((state: RootState) => state.staff.responce)
    .filter((el) => el.is_active)
    .filter((el) => el.role === UserRole.Doctor);

  const { common } = useAppSelector((state) => state);

  const { data: permissions } = useGetPermissionsQuery(common.user.id);

  const doctorsWithFullName = doctors?.map((el) => {
    return { ...el, full_name: `${el.last_name} ${el.first_name} ${el.second_name}` };
  });

  const searchedDoctors: [{ job: string; id: number; name: string; full_name: string }] = doctorsWithFullName.filter(
    (el) => el.full_name.toLowerCase().includes(search.toLowerCase()),
  );

  const categories = {};

  searchedDoctors.map((el) => {
    const doctorCategory = capitalize(el.job);
    if (categories[doctorCategory]) {
      categories[doctorCategory].push({
        id: el.id,
        name: el.full_name,
        category: doctorCategory,
      });
    } else {
      categories[doctorCategory] = [
        {
          id: el.id,
          name: el.full_name,
          category: doctorCategory,
        },
      ];
    }
  });

  const selectedOptions = selectedDoctors.map((el) => String(el.id));

  useEffect(() => {
    // Группирую докторов по категориям, привожу их все к единому виду: с заглавной буквы, остальное - маленькими. Например, "Терапевт"
    const allDoctorsGroupedByCategory = groupBy(doctors, (doctor) => capitalize(doctor.job));
    const currentDoctorsGroupedByCategory = groupBy(initialValue, (doctor) => capitalize(doctor.category));
    // Получаю массив строк-категорий у всех докторов, и у текущих
    const allDoctorsGroupedByCategoryKeys = Object.keys(allDoctorsGroupedByCategory);
    const currentDoctorsGroupedByCategoryKeys = Object.keys(currentDoctorsGroupedByCategory);
    // Сравниваю для каждой категории: у текущих врачей набирается столько же врачей в одну категорию, как и у всех врачей? Если да - то категория выделена полностью, она станет выбранной
    const matchingKeys = allDoctorsGroupedByCategoryKeys.filter((key) => {
      return (
        currentDoctorsGroupedByCategoryKeys.includes(key) &&
        allDoctorsGroupedByCategory[key]?.length === currentDoctorsGroupedByCategory[key]?.length
      );
    });

    setSelectedCategories(matchingKeys);
    setSelectedDoctors(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onChange = (doctors) => {
    dispatch(setDoctors(doctors));
    setOpen(false);
  };

  const onOptionClick = async (value: IDoctor, key: string) => {
    if (!selectedOptions.includes(String(value.id))) {
      setSelectedDoctors([...selectedDoctors, value]);
      onChange([...selectedDoctors, value]);

      const filteredDoctors = [...selectedDoctors, value].filter((doctor) => doctor.category === key);
      const fullCategory = filteredDoctors.length === categories[key].length;

      if (fullCategory) {
        setSelectedCategories([...selectedCategories, key]);
      }
    } else {
      const filtered = selectedDoctors.filter((el) => el.id !== value.id);
      const notFullCategory =
        [...selectedDoctors, value].filter((doctor) => doctor.category === key).length !== categories[key].length;

      setSelectedDoctors(filtered);
      onChange(filtered);

      if (notFullCategory) {
        setSelectedCategories(selectedCategories.filter((category) => category !== key));
      }
    }
  };

  const onCategoryClick = (doctors, key) => {
    if (selectedCategories.includes(key)) {
      const filteredCategories = selectedCategories.filter((category) => category !== key);
      const filteredDoctors = selectedDoctors.filter((doctor) => doctor.category !== key);

      setSelectedDoctors(filteredDoctors);
      onChange(filteredDoctors);

      return setSelectedCategories(filteredCategories);
    }

    const newSelected = [];

    doctors.forEach((doctor) => {
      if (!selectedOptions.includes(String(doctor.id))) {
        newSelected.push(doctor);
      }
    });

    setSelectedDoctors([...selectedDoctors, ...newSelected]);
    onChange([...selectedDoctors, ...newSelected]);
    setSelectedCategories([...selectedCategories, key]);
    setOpen(false);
  };

  const onCancelClick = (event) => {
    event.stopPropagation();
    setSelectedCategories([]);
    setSelectedDoctors([]);
    onChange([]);
  };

  const selectedValue = () => {
    if (selectedDoctors.length) {
      const doctorName =
        selectedDoctors[0]?.name.length > 41
          ? doctors.find((doctor) => doctor.id === selectedDoctors[0]?.id)
          : selectedDoctors[0]?.name;

      return doctorName?.second_name
        ? `${doctorName.second_name} ${doctorName.first_name[0]}. ${doctorName.last_name[0]}.`
        : doctorName;
    }
    return '';
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Selector style={{ marginRight: 10, ...styles }}>
        <SelectedOption onClick={() => setOpen(!isOpen)}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {selectedDoctors.length ? (
              <CancelIcon onClick={onCancelClick} style={{ color: '#577BF9', fontSize: '16px', marginRight: '4px' }} />
            ) : (
              <></>
            )}
            <SelectedInput
              disabled
              placeholder="Выбрать врача"
              value={selectedValue()}
              width={selectedValue().length && selectedValue().length}
            />
            {selectedDoctors.length > 1 ? (
              <div style={{ display: 'flex' }}>
                <Circle>+{selectedDoctors.length - 1}</Circle>
              </div>
            ) : (
              <></>
            )}
          </div>
          <KeyboardArrowDownIcon style={{ fontSize: '20px' }} />
        </SelectedOption>
        {isOpen && (
          <OptionsContainer>
            <Search>
              <SearchInput
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                placeholder="Поиск по врачам"
              />
              <SearchIcon />
            </Search>

            <Divider />

            {Object.keys(categories).length === 0 ? (
              <EmptySelectPlaceholder paddingX={30}>
                {permissions?.create_account ? (
                  <>
                    <SecondaryText>Чтобы создать сотрудников перейдите на страницу </SecondaryText>
                    <SecondaryLink onClick={() => navigate(routerConfig.PROFILE.path)}>
                      настройки профиля.
                    </SecondaryLink>
                  </>
                ) : (
                  <SecondaryText>Нет сотрудников.</SecondaryText>
                )}
              </EmptySelectPlaceholder>
            ) : (
              Object.keys(categories).map((key) => (
                <div key={key}>
                  <Category
                    selected={selectedCategories.includes(key)}
                    onClick={() => onCategoryClick(categories[key], key)}
                  >
                    {key}
                  </Category>
                  {categories[key].map((el) => {
                    const doctorName = el.name.length > 41 ? doctors.find((doctor) => doctor.id === el.id) : el.name;
                    return (
                      <Option
                        key={el.id}
                        onClick={() => onOptionClick(el, key)}
                        selected={selectedOptions.includes(String(el.id))}
                      >
                        {doctorName?.second_name
                          ? `${doctorName.second_name} ${doctorName.first_name[0]}. ${doctorName.last_name[0]}.`
                          : doctorName}
                      </Option>
                    );
                  })}
                </div>
              ))
            )}
          </OptionsContainer>
        )}
      </Selector>
    </ClickAwayListener>
  );
};

export default DoctorMultiSelect;
