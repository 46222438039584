import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { DiscountTypeValues, TreatmentPlanJournalField } from '../Template/TreatmentPlan/TreatmentPlanJournalField';
import styled from 'styled-components';
import BlueButton from '../../../uikit/Button/BlueButton';
import { useSelector } from 'react-redux';
import { EAccountStatus } from './AccountStatus';
import { Link } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';
import { useGetPermissionsQuery } from '../../../services/users';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
import { MedcardEntry } from '../../../services/medcard';
import { useGetPlanJournalFieldQuery } from '../../../services/medcardJournal';
import {
  InvoiceRowType,
  useAddInvoiceRowMutation,
  useDeleteInvoiceRowMutation,
  useGetInvoiceRowsQuery,
  useUpdateSubInvoiceMutation,
  useUpdateInvoiceRowMutation,
  SubInvoice,
} from '../../../services/invoice';
import { TreatmentPlanPriceList } from '../Template/TreatmentPlan/TreatmentPlanPriceList';
import { useGetPriceQuery } from '../../../services/price';
import { RootState } from '../../../store';
import { JournalAccountField } from './components/JournalAccountField';
import { DoctorTabs } from '../Template/TreatmentPlan/DoctorTabs';
import { ToothStateTabs } from '../Template/TreatmentPlan/ToothStateTabs';
import { sumBy, uniqBy } from 'lodash';
import { SubInvoiceTabs } from '../SubInvoiceTabs';
// eslint-disable-next-line  import/no-named-as-default
import toast from 'react-hot-toast';

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #253446;
  margin-right: 16px;
`;
const SubTitle = styled.div<{ multiline?: boolean; width?: number }>`
  font-weight: normal;
  font-size: 14px;
  color: #a8aeb5;
  width: ${({ width, multiline = false }) => (multiline ? `${width}px` : 'inherit')};
  text-align: ${({ multiline = false }) => (multiline ? 'center' : 'left')};
`;

const TextTitle = styled.div<{ isDark?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: ${({ isDark }) => (isDark ? '#253446' : '#515D6B')};
  margin-bottom: 8px;
`;

const EmptyBlock = styled.div<{ marginRight: number }>`
  height: 100%;
  border: 1px dashed #d3d6da;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: ${({ marginRight }) => marginRight}px;
  padding: 32px;
`;

const EmptyPlan = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a8aeb5;
  border: 1px solid #d3d6da;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 16px 12px;
  margin-top: -1px;
`;

type Props = {
  subInvoices: SubInvoice[];
  expandedId: number;
  setExpanded: ActionCreatorWithNonInferrablePayload;
  entries: MedcardEntry[];
  currentDateId: number;
  setTotalView: VoidFunction;
};

function getPriceTemplates(price, templates, invoices) {
  return price.map((t) => {
    if (invoices.find((u) => u.code === t.code) && !templates.find((u) => u.code === t.code)) {
      return { ...t, selected: true };
    }
    return t;
  });
}

export function Account({ subInvoices, expandedId, setExpanded, entries, currentDateId, setTotalView }: Props) {
  const patient = useSelector((state: RootState) => state.medCardPageSlice.patientDetails);
  const user = useSelector((state: RootState) => state.common.user);
  const [currentSubInvoiceId, setCurrentSubInvoiceId] = useState<number>(0);
  const [priceCategory, setPriceCategory] = useState('');

  const { data: treatmentPlan = [] } = useGetPlanJournalFieldQuery({ patientId: patient.id, entryId: expandedId });

  const [activeTreatmentPlan, setActiveTreatmentPlan] = useState(null);

  const { data: invoiceRows = [] } = useGetInvoiceRowsQuery(currentSubInvoiceId, { skip: !currentSubInvoiceId });

  const { data: price = [] } = useGetPriceQuery(priceCategory);

  const [addInvoiceRow] = useAddInvoiceRowMutation();
  const [updateInvoiceRow] = useUpdateInvoiceRowMutation();
  const [deleteInvoiceRow] = useDeleteInvoiceRowMutation();

  const [updateSubInvoice] = useUpdateSubInvoiceMutation();

  const { data: permissions } = useGetPermissionsQuery(user.id);

  const currentSubInvoice = subInvoices.find((inv) => inv.id === currentSubInvoiceId);
  const entry = entries.find((e) => e.id === expandedId);

  const doctors = useMemo(() => {
    const docList = uniqBy(
      treatmentPlan
        .filter((p) => !!p.planRows?.length)
        .filter((p) => p.doctor.id !== user.id)
        .map((p) => ({
          label: `${p.doctor.secondName} ${p.doctor.firstName[0]}. ${p.doctor.lastName[0]}.`,
          value: p.doctor.id,
        })),
      'value',
    );
    return [
      {
        label: `${user.last_name} ${user.first_name[0]}. ${user.second_name[0]}.`,
        value: user.id,
      },
      ...docList,
    ];
  }, [treatmentPlan, user]);

  const toothStates = useMemo(() => {
    const toothStateList = uniqBy(
      treatmentPlan
        .filter((p) => !!p.planRows?.length)
        .filter((p) => p.doctor.id === activeTreatmentPlan?.doctor.id)
        .filter((p) => p.entry.id !== entry?.id)
        .map((p) => ({ label: `${p.entry.toothIndex} ${p.entry.toothState}`, value: p.entry.id })),
      'value',
    );
    return activeTreatmentPlan?.doctor.id === entry?.doctor.id &&
      !!treatmentPlan.find((p) => p.entry.id === entry?.id && !!p.planRows?.length)
      ? [
          {
            label: `${entry?.toothIndex || ''} ${entry?.toothStateLabel || entry?.toothState?.title || ''}`,
            value: entry?.id,
          },
          ...toothStateList,
        ]
      : toothStateList;
  }, [treatmentPlan, activeTreatmentPlan, entry]);

  const invoiceHistory = useMemo(() => {
    return activeTreatmentPlan?.planRows?.map((p) => ({
      code: p.code,
      count: sumBy(p.invoiceHistory, 'count'),
      paid: sumBy(p.invoiceHistory, 'paid_for_invoice_row'),
    }));
  }, [activeTreatmentPlan?.planRows]);

  useEffect(() => {
    if (expandedId) {
      setCurrentSubInvoiceId(subInvoices.find((inv) => inv.formula_id === expandedId)?.id);
    } else {
      setCurrentSubInvoiceId(subInvoices?.[0]?.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subInvoices]);

  useEffect(() => {
    setActiveTreatmentPlan(
      treatmentPlan.find((p) => p.doctor.id === user.id && p.entry.id === expandedId && !!p.planRows?.length) ||
        treatmentPlan.find((p) => p.doctor.id === user.id && !!p.planRows?.length) ||
        treatmentPlan.find((p) => !!p.planRows?.length),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedId]);

  return (
    <>
      <div style={{ display: 'flex', padding: 32, alignItems: 'center' }}>
        <Title>Создание счета</Title>
        <SubTitle>{`${patient.last_name} ${patient.first_name} ${patient.second_name} №${patient.id}`}</SubTitle>
      </div>
      <SubInvoiceTabs
        subInvoices={subInvoices}
        patientId={patient.id}
        currentDateId={currentDateId}
        expandedId={expandedId}
        setExpanded={setExpanded}
        entries={entries}
        setCurrentSubInvoiceId={setCurrentSubInvoiceId}
      />
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ padding: 32, maxWidth: 1390, height: '100%', width: '100%' }}>
          {!price.length && !priceCategory ? (
            <EmptyBlock marginRight={0}>
              <TextTitle>Прейскурант еще не создан</TextTitle>
              <SubTitle multiline width={234}>
                Чтобы создать позиции перейдите на страницу <Link to="/price">“Прейскурант”</Link>
              </SubTitle>
            </EmptyBlock>
          ) : (
            <Grid container spacing={2} style={{ height: '100%' }}>
              <Grid item xs={12} sm={6}>
                <Paper elevation={0} square style={{ height: '100%' }}>
                  <Scrollbars>
                    {invoiceRows.length ? (
                      <>
                        <TextTitle isDark>Счет</TextTitle>
                        <JournalAccountField
                          data={invoiceRows}
                          withoutDate
                          readOnly={
                            currentSubInvoice?.status !== EAccountStatus.NOT_FILLED &&
                            currentSubInvoice?.status !== EAccountStatus.FILLED &&
                            currentSubInvoice?.status !== EAccountStatus.EDIT
                          }
                          invoiceHistory={invoiceHistory}
                          onRowDelete={(template) => {
                            if (
                              currentSubInvoice?.status !== EAccountStatus.NOT_FILLED &&
                              currentSubInvoice?.status !== EAccountStatus.FILLED &&
                              currentSubInvoice?.status !== EAccountStatus.EDIT
                            ) {
                              return;
                            }
                            deleteInvoiceRow({
                              subInvoiceId: currentSubInvoiceId,
                              invoiceRowId: template.id,
                            })
                              .unwrap()
                              .then(() => {
                                if (invoiceRows.length === 1) {
                                  updateSubInvoice({
                                    id: currentSubInvoiceId,
                                    status: EAccountStatus.NOT_FILLED,
                                  });
                                }
                              });
                          }}
                          onRowUpdate={(template) => {
                            const history = invoiceHistory?.find((h) => h.code === template.code);

                            const sum = template.count * template.price;

                            const payment =
                              sum -
                              (template.discountType === DiscountTypeValues.RUB
                                ? template.discount
                                : sum * template.discount * 0.01);

                            if (history && payment < history.paid) {
                              toast.error(`По данной позиции оплачено ${history.paid} руб. 
                              Сумма к оплате не может быть меньше этой после редактирования количества или скидки`);
                              return;
                            }

                            updateInvoiceRow({
                              record: {
                                id: template.id,
                                price_value: template.price,
                                count: template.count,
                                discount: template.discount,
                                discount_type: template.discountType,
                                payment,
                              },
                              subInvoiceId: currentSubInvoiceId,
                            });
                          }}
                        />
                      </>
                    ) : (
                      <EmptyBlock marginRight={24}>
                        <TextTitle>Добавьте позиции в счет</TextTitle>
                        <SubTitle width={322} multiline>
                          Чтобы заполнить счет нажмите на позицию в плане лечения или прейскуранте
                        </SubTitle>
                      </EmptyBlock>
                    )}
                  </Scrollbars>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
                <Paper elevation={0} square style={{ height: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Scrollbars>
                      <div>
                        <TextTitle isDark>План лечения</TextTitle>
                        <>
                          {!!doctors?.length && (
                            <DoctorTabs
                              data={doctors}
                              activeTreatmentPlan={activeTreatmentPlan}
                              onDoctorSelect={(value) =>
                                setActiveTreatmentPlan(
                                  treatmentPlan.find((p) =>
                                    p.doctor.id === user.id
                                      ? p.doctor.id === value && p.entry.id === entry?.id && !!p.planRows?.length
                                      : p.doctor.id === value,
                                  ) ||
                                    treatmentPlan.find((p) =>
                                      p.doctor.id === user.id
                                        ? p.doctor.id === value && !!p.planRows?.length
                                        : p.doctor.id === value,
                                    ),
                                )
                              }
                            />
                          )}
                          {!!toothStates?.length && (
                            <ToothStateTabs
                              data={toothStates}
                              activeTreatmentPlan={activeTreatmentPlan}
                              onToothStateSelect={(value) =>
                                setActiveTreatmentPlan(
                                  treatmentPlan.find(
                                    (p) => p.doctor.id === activeTreatmentPlan.doctor.id && p.entry.id === value,
                                  ),
                                )
                              }
                            />
                          )}
                          {activeTreatmentPlan?.planRows ? (
                            <TreatmentPlanJournalField
                              data={activeTreatmentPlan?.planRows}
                              withoutDate
                              readOnly
                              isAddAccount
                              onRowClick={(template) => {
                                if (template.isUsed) {
                                  return;
                                }
                                addInvoiceRow({
                                  record: {
                                    price_code: template.code,
                                    price_title: template.text,
                                    price_value: template.price,
                                    count: template.count - sumBy(template.invoiceHistory, 'count'),
                                    discount: template.discount,
                                    discount_type: template.discountType,
                                    payment: template.payment,
                                    row_type: InvoiceRowType.TREATMENT_PLAN,
                                    external_entry: activeTreatmentPlan.entry.id,
                                  },
                                  subInvoiceId: currentSubInvoiceId,
                                })
                                  .unwrap()
                                  .then(() => {
                                    if (invoiceRows.length === 0) {
                                      updateSubInvoice({
                                        id: currentSubInvoiceId,
                                        status: EAccountStatus.FILLED,
                                      });
                                    }
                                  });
                              }}
                            />
                          ) : (
                            <EmptyPlan>
                              Чтобы добавить позиции в план лечения вернитесь на страницу медицинской карты
                            </EmptyPlan>
                          )}
                        </>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {!(!price.length && !priceCategory) ? (
                          <TreatmentPlanPriceList
                            title="Прейскурант"
                            templates={getPriceTemplates(price, activeTreatmentPlan?.planRows || [], invoiceRows)}
                            isHeaderVisible
                            isMenuVisible={false}
                            onAddTemplate={(template) => {
                              addInvoiceRow({
                                record: {
                                  price_code: template.code,
                                  price_title: template.text,
                                  price_value: template.price,
                                  count: 1,
                                  discount: 0,
                                  discount_type: 'RUB',
                                  payment: template.price,
                                  row_type: InvoiceRowType.PRICE,
                                },
                                subInvoiceId: currentSubInvoiceId,
                              })
                                .unwrap()
                                .then(() => {
                                  if (invoiceRows.length === 0) {
                                    updateSubInvoice({
                                      id: currentSubInvoiceId,
                                      status: EAccountStatus.FILLED,
                                    });
                                  }
                                });
                            }}
                            onCategoryFilter={(value) => {
                              setPriceCategory(value);
                            }}
                          />
                        ) : (
                          <>
                            <TextTitle isDark style={{ marginTop: 24 }}>
                              Прейскурант
                            </TextTitle>
                            <EmptyBlock marginRight={12}>
                              <TextTitle>Прейскурант еще не создан</TextTitle>
                              <SubTitle width={234} multiline>
                                Чтобы создать позиции перейдите на страницу <Link to="/price">“Прейскурант”</Link>
                              </SubTitle>
                            </EmptyBlock>
                          </>
                        )}
                      </div>
                    </Scrollbars>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            padding: '8px 0 32px 0',
            borderTop: '1px solid #D9D9D9',
            flex: 1,
          }}
        >
          {permissions['create_account'] && (
            <BlueButton
              width={205}
              height={40}
              style={{ marginRight: 16 }}
              disabled={
                !subInvoices.find(
                  (inv) =>
                    inv.status === EAccountStatus.FILLED ||
                    inv.status === EAccountStatus.PENDING_SEND ||
                    inv.status === EAccountStatus.EDIT,
                )
              }
              onClick={() => {
                setTotalView();
                subInvoices
                  .filter((inv) => inv.status === EAccountStatus.FILLED)
                  .forEach((inv) => {
                    updateSubInvoice({
                      id: inv.id,
                      status: EAccountStatus.PENDING_SEND,
                    });
                  });
              }}
            >
              Сформировать общий счет
            </BlueButton>
          )}
        </div>
      </div>
    </>
  );
}
