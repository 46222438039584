import React, { cloneElement, MouseEvent, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import TextInput from '../../../uikit/TextInput';
import PatientsState from '../state';
import EmailText from './EmailText';
import { format } from 'date-fns';
import rulocale from 'date-fns/locale/ru';
import { useFormContext } from 'react-hook-form';
import ErrorPopup from '../../../components/ErrorPopup';
import { isObject } from 'lodash';
import { RootState } from '../../../store';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';

type ColumnColProps = {
  width?: number | string;
  fontSize: number;
  paddingLeft: number;
  hasAction: boolean;
  fontWeightBold: boolean;
  textAlign: string;
  color: string;
  flexGrow: number;
};
export const ColumnLayout = styled.div<ColumnColProps>`
  display: flex;
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-direction: column;
  justify-content: center;
  width: ${({ width }) => (width ? (isNaN(+width) ? width : `${width}px`) : '100%')};
  height: 54px;
  font-style: normal;
  font-weight: ${({ fontWeightBold }) => (fontWeightBold ? '500' : 'normal')};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 22px;
  color: ${({ color }) => color};
  padding-left: ${({ paddingLeft }) => paddingLeft}px;
  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'auto')};
  text-align: ${({ textAlign }) => textAlign};
  position: relative;
`;
const InputContainer = styled.div`
  height: 32px;
  margin-left: -8px;
  margin-right: 8px;
  position: relative;
`;

function parseDate(date: string | ReactElement = ''): string | ReactElement {
  let value = isObject(date) ? date.props.children : date;
  const date_arr: string[] = value && value.split('-');
  value =
    value &&
    format(new Date(parseInt(date_arr[0]), parseInt(date_arr[1]) - 1, parseInt(date_arr[2])), 'dd.MM.yyyy', {
      locale: rulocale,
    });
  return isObject(date) ? cloneElement(date, { children: value }) : value;
}

interface ColumnProps {
  children: ReactElement;
  name: string;
  width?: number | string;
  isEditing: boolean;
  hasAction?: boolean;
  onClick?: VoidFunction;
  onEdit?: VoidFunction;
  setData: ActionCreatorWithNonInferrablePayload;
  patientsPage: RootState['patientsPage'];
  defaultValue: string;
  fontSize?: number;
  paddingLeft?: number;
  fontWeightBold?: boolean;
  textAlign?: string;
  color?: string;
  flexGrow?: number;
  value?: string;
}

function Column({
  children,
  name = '',
  width,
  onClick,
  onEdit = undefined,
  isEditing,
  hasAction = false,
  defaultValue,
  fontSize = 14,
  paddingLeft = 16,
  fontWeightBold = false,
  textAlign = 'left',
  color = '#515d6b',
  flexGrow = 0,
  value = undefined,
}: ColumnProps) {
  const { register, formState, trigger } = useFormContext();
  const [isOver, setOver] = useState(false);
  const disabled = name === 'id';
  const hasErrors = formState.errors[name];
  return (
    <ColumnLayout
      hasAction={hasAction && !disabled}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        if (!isEditing) {
          onClick && onClick();
        } else {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      width={width}
      fontSize={fontSize}
      paddingLeft={paddingLeft}
      fontWeightBold={fontWeightBold}
      textAlign={textAlign}
      color={color}
      flexGrow={flexGrow}
    >
      {isEditing ? (
        <InputContainer
          onMouseEnter={() => {
            setOver(true);
          }}
          onMouseLeave={() => {
            setOver(false);
          }}
        >
          <TextInput
            onBlur={async () => {
              await trigger(name);
            }}
            name={name}
            {...register(name)}
            error={formState.errors[name]}
            disabled={disabled}
            defaultValue={name === 'birth_date' ? parseDate(defaultValue) : defaultValue}
            onChange={(e) => onEdit && onEdit(e.target.value)}
            value={value}
          />
          <ErrorPopup isShown={hasErrors && isOver} />
        </InputContainer>
      ) : name === 'email' ? (
        <div>{isEditing ? <EmailText>{children}</EmailText> : children}</div>
      ) : name === 'birth_date' ? (
        children ? (
          parseDate(children)
        ) : (
          ''
        )
      ) : (
        children
      )}
    </ColumnLayout>
  );
}

export default connect((state: RootState) => ({ patientsPage: state.patientsPage }), {
  setData: PatientsState.actions.setData,
})(Column);
