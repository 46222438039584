import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { blue } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import { ColorState, StateBlock, TextState } from './index';
import Tooltip from '../../../../components/Tooltip/Tooltip';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme) =>
  createStyles({
    select: {
      display: 'flex',
      position: 'relative',
      height: 32,
      minWidth: 242,
      background: 'white',
      color: '#515D6B',
      borderColor: ({ error }) => (error ? '#DF194C' : '#D3D6DA'),
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '4px',
      fontSize: 12,
      paddingRight: '0 !important',
      paddingLeft: 8,
      paddingTop: 0,
      paddingBottom: 0,
      '&:hover': {
        borderColor: ({ error }) => (error ? '#DF194C' : '#577bf9'),
      },
      '&:focus': {
        borderRadius: '4px',
        background: 'white',
        borderColor: blue[200],
      },
      '&.Mui-disabled': {
        color: '#A8AEB5',
        background: '#EFF0F3',
      },
    },
    icon: {
      color: '#515D6B',
      height: 24,
      width: 24,
      right: 4,
      top: 4,
      position: 'absolute',
      userSelect: 'none',
      pointerEvents: 'none',
    },
    paper: {
      marginTop: 16,
    },
    list: {
      padding: '8px 0',
      background: 'white',
      '& li': {
        color: '#515D6B',
        fontSize: 12,
        padding: '5px 12px',
      },
      '& li.Mui-selected': {
        background: '#F6F6FA',
      },
    },
  }),
);

type SelectItem = {
  value: string;
  name: string;
  color: string;
};

type Props = {
  className?: string;
  value: string;
  disabled?: boolean;
  setValue: (value) => void;
  items: SelectItem[];
  error: boolean;
};

export const ToothStateSelect = ({ className, value, disabled = false, setValue, items, error }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  const classes = useStyles({ error: error, placeholder: !value });

  // moves the menu below the select input
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + ' ' + classes.icon} />;
  };

  return (
    <Tooltip open={error && !open && hover} title={'Обязательное поле'} placement={'bottom-start'} dark>
      <Select
        style={{ margin: '0 12px' }}
        labelId="select-placeholder-label"
        displayEmpty
        open={open}
        disableUnderline
        classes={{ root: cn(classes.select, className) }}
        IconComponent={iconComponent}
        MenuProps={menuProps}
        value={value || ''}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
        onOpen={() => {
          setOpen(!open);
        }}
        onChange={(e) => setValue(e.target.value)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <MenuItem value={''} style={{ display: 'none' }}>
          <StateBlock>
            <TextState color={'#A8AEB5'}>Выберите цвет</TextState>
          </StateBlock>
        </MenuItem>
        {items.map((v) => (
          <MenuItem key={v.value} value={v.value}>
            <StateBlock>
              <ColorState color={v.color} />
              <TextState>{v.name}</TextState>
            </StateBlock>
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
};
