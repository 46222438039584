import Checkbox from '@material-ui/core/Checkbox';
import { styled as styledMui } from '@material-ui/styles';
import FormGroup from '@material-ui/core/FormGroup';

export const Controls = styledMui(FormGroup)(({ disabled }: { disabled: boolean }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '12px 24px',
  paddingTop: 0,
  '& .MuiTypography-root': {
    fontSize: '12px',
    fontWeight: '400',
    color: disabled ? '#A8AEB5' : '#515D6B',
  },
  '& .MuiFormControlLabel-root': {
    margin: 0,
    padding: 0,
  },
}));
export const CustomCheckbox = styledMui(Checkbox)({
  padding: 0,
  paddingRight: 4,
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      fill: '#577BF9',
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiSvgIcon-root': {
    width: '18px',
    height: '18px',
    fill: '#D3D6DA',
  },
});
