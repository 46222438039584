import React from 'react';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CalendarPage from '../../CalendarPage';

type Props = {
  calendarVisible: boolean;
  setCalendarVisible: (value: boolean) => void;
};

export function Calendar({ calendarVisible, setCalendarVisible }: Props) {
  const handleClose = () => {
    setCalendarVisible(false);
  };

  return (
    <Dialog
      fullScreen
      open={calendarVisible}
      onClose={handleClose}
      style={{ width: '100%', position: 'absolute', top: 0 }}
    >
      <div
        style={{
          background: 'rgba(0, 0, 0, 0.4)',
          color: 'white',
          padding: '20px',
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <CloseIcon onClick={() => setCalendarVisible(false)} />
      </div>
      <div style={{ overflowY: 'scroll' }}>
        <CalendarPage modalView />
      </div>
    </Dialog>
  );
}
