import React from 'react';
import { connect } from 'react-redux';
import Dialog from './Dialog';
import { fetchDialog, fetchMessages, openDialog } from '../../features/messages/operations';
import { Scrollbars } from 'react-custom-scrollbars-2';

function SearchResults({ messages, common, openDialog, fetchDialog, fetchMessages, staff }) {
  return (
    <div>
      <Scrollbars autoHide style={{ width: 414, height: 320 }}>
        <div>
          {/* {messages.search.dialogs.length === 0 ? (
            <div />
          ) : (
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '18px',
                lineHeight: '120%',
                color: '#262626',
                marginBottom: 15,
                marginLeft: 8,
              }}
            >
              {' '}
              Чаты{' '}
            </div>
          )} */}
          {messages.search.dialogs.map((dialog) => {
            const msg = dialog.message || dialog.item.message;
            return (
              <Dialog
                key={Math.random()}
                openDialog={() => {
                  openDialog({
                    fromSearch: false,
                    name:
                      parseInt(msg[msg.length - 1].sender.id) !== parseInt(common.user.id)
                        ? msg[msg.length - 1].sender.name
                        : msg[msg.length - 1].reciever.name,
                    reciever:
                      parseInt(msg[msg.length - 1].sender.id) !== parseInt(common.user.id)
                        ? msg[msg.length - 1].sender
                        : msg[msg.length - 1].reciever,
                    sender:
                      parseInt(msg[msg.length - 1].sender.id) === parseInt(common.user.id)
                        ? msg[msg.length - 1].sender
                        : msg[msg.length - 1].reciever,
                  });
                  fetchDialog(dialog.key);
                  fetchMessages(msg[msg.length - 1].sender.id, msg[msg.length - 1].reciever.id);
                }}
                patient={
                  parseInt(msg[msg.length - 1].sender.id) !== parseInt(common.user.id)
                    ? msg[msg.length - 1].sender.name
                    : msg[msg.length - 1].reciever.name
                }
                message={msg[msg.length - 1]}
                user={common.user}
                unReadMessages={dialog.unReadMessages}
                stafferId={
                  parseInt(msg[msg.length - 1].sender.id) !== parseInt(common.user.id)
                    ? msg[msg.length - 1].sender.id
                    : msg[msg.length - 1].reciever.id
                }
                staff={staff}
              />
            );
          })}
        </div>
        {/* {messages.search.messages.length === 0 ? (
          <div />
        ) : (
          <div
            style={{
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '120%',
              color: '#262626',
              marginBottom: 15,
              marginLeft: 8,
            }}
          >
            {' '}
            Сообщения{' '}
          </div>
        )} */}
        {messages.search.messages.map((msg) => {
          return (
            <Dialog
              key={Math.random()}
              openDialog={() => {
                openDialog({
                  fromSearch: true,
                  date: msg.item.date,
                  name:
                    parseInt(msg.item.sender.id) !== parseInt(common.user.id)
                      ? msg.item.sender.name
                      : msg.item.reciever.name,
                  reciever:
                    parseInt(msg.item.sender.id) !== parseInt(common.user.id) ? msg.item.sender : msg.item.reciever,
                  sender: msg.item.sender.id === common.user.id ? msg.item.sender : msg.item.reciever,
                });
                fetchMessages(msg.item.sender.id, msg.item.reciever.id);
              }}
              patient={
                parseInt(msg.item.sender.id) !== parseInt(common.user.id)
                  ? msg.item.sender.name
                  : msg.item.reciever.name
              }
              message={msg}
              user={common.user}
              unReadMessages={0}
              stafferId={
                parseInt(msg.item.sender.id) !== parseInt(common.user.id) ? msg.item.sender.id : msg.item.reciever.id
              }
              staff={staff}
            />
          );
        })}
      </Scrollbars>
    </div>
  );
}
const mapStateToProps = (state) => ({
  messages: state.messages,
  common: state.common,
  staff: state.staff,
});

export default connect(mapStateToProps, {
  openDialog,
  fetchDialog,
  fetchMessages,
})(SearchResults);
