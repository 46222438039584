import React, { useEffect, useMemo, useState } from 'react';
import { Table } from '../../../../components/Table';
import { Container, Wrapper } from './styles';
import { ScreenSize, TableSize } from '../../enums';

export function ReportTable({ tableData, columns, footer, bottomOffset, mode, noScrollbars = false }) {
  const [tableHeight, setTableHeight] = useState(0);
  useEffect(() => {
    if (tableData.length) {
      const currentTableHeight = document.getElementById('tableHeight');
      currentTableHeight && setTableHeight(currentTableHeight.offsetHeight);
    }
    // eslint-disable-next-line
  }, []);

  const visibleRows = useMemo(
    () =>
      Math.floor(
        (document.body.offsetHeight - ScreenSize.FOOTER_AND_HEADER - ScreenSize.TABLE_HEAD) / ScreenSize.ROW_HEIGHT,
      ),
    [],
  );
  const maxVisibleRows = useMemo(
    () => (visibleRows <= TableSize.MAX_REVENUE_ROWS ? visibleRows : TableSize.MAX_REVENUE_ROWS),
    [visibleRows],
  );
  const maxTableHeight = useMemo(() => document.body.offsetHeight - ScreenSize.FOOTER_AND_HEADER, []);

  return (
    <>
      <Wrapper bottomOffset={bottomOffset}>
        <div id="tableHeight">
          <Container style={{ maxHeight: `calc(100vh - ${mode === 'revenue' ? '218px' : '260px'})` }}>
            <Table<Record<string, unknown>>
              columns={columns}
              data={tableData}
              hiddenColumns={['id']}
              isLoading={false}
              canAutoHeight={visibleRows < tableData.length || tableHeight >= maxTableHeight}
              visibleRowCount={maxVisibleRows}
              getHeaderProps={() => ({
                style: {
                  background: 'white',
                },
              })}
              noScrollbars={noScrollbars}
            />
          </Container>
        </div>
      </Wrapper>

      {footer}
    </>
  );
}
