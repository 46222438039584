import styled from 'styled-components';

interface OptionProps {
  selected: boolean;
}

interface CategoryProps {
  selected: boolean;
}

interface SelectorProps {
  width?: number;
}

interface SelectedOptionProps {
  focused: boolean;
  disabled: boolean;
}

export const Selector = styled.div<SelectorProps>`
  margin-right: 16px;
  position: relative;
  flex: ${({ width }) => `0 1 ${width}px`};
`;

export const SelectedOption = styled.div<SelectedOptionProps>`
  height: 32px;
  background: ${({ disabled }) => (disabled ? '#F6F6FA' : '#ffffff')};
  border: ${({ focused }) => (focused ? '1px solid #577BF9' : '1px solid #d3d6da')};
  box-shadow: ${({ focused }) => (focused ? '0px 0px 4px rgba(87, 123, 249, 0.5)' : 'none')};
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  cursor: pointer;
  overflow-y: hidden;
  :hover {
    border: 1px solid #577bf9;
  }
`;

export const SelectedInput = styled.input`
  border: 0;
  background: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #515d6b;
  cursor: pointer;
  width: 100%;
  overflow-y: hidden;
  text-overflow: ellipsis;
  &::placeholder {
    color: #a8aeb5;
  }
  &:focus {
    outline: none;
  }
`;

export const OptionsContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  padding: 12px 0;
  box-sizing: border-box;
  top: 36px;
  right: 0;
  left: 0;
  max-height: 350px;
  overflow: hidden;
`;

export const Search = styled.div`
  height: 24px;
  display: flex;
  font-style: normal;
  font-weight: normal;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
`;

export const SearchInput = styled.input`
  border: 0;
  background: #ffffff;
  font-size: 14px;
  color: #515d6b;
  width: 100%;
  &::placeholder {
    color: #a8aeb5;
  }
  &:focus {
    outline: none;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid #f6f6fa;
  margin: 12px 12px 12px;
`;

export const Category = styled.div<CategoryProps>`
  color: ${({ selected }) => (selected ? '#577BF9' : '#A8AEB5')};
  font-size: 14px;
  width: max-content;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  padding: 0 12px;
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 4px;
  white-space: nowrap;
`;

export const Option = styled.div<OptionProps>`
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #515d6b;
  box-sizing: border-box;
  padding: 0 12px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  white-space: nowrap;
  background: ${({ selected }) => (selected ? '#F6F6FA' : '#FFFFFF')};
  cursor: pointer;
  &:hover {
    background: #eff2ff;
  }
`;

export const Circle = styled.div`
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background: #f6f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #7c8590;
  margin-left: 2px;
`;

export const NotFound = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #515d6b;
  line-height: 24px;
`;
