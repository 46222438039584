import React from 'react';
import { format, isToday } from 'date-fns';
import CheckIcon from '@material-ui/icons/Check';
import DoneAll from '@material-ui/icons/DoneAll';
import { useSearchStaffQuery } from '../../services/users';
import { useAppSelector } from '../../store/hooks';

export default function Dialog({ message, patient, openDialog, unReadMessages, user, stafferId }) {
  const {
    user: {
      clinic: { id },
    },
  } = useAppSelector((state) => state.common);

  const { data: staffers } = useSearchStaffQuery({ clinic: id });
  const { text, reciever, date, isNew } = message.item || message;

  const currentStaffer = staffers.filter((staffer) => parseInt(staffer.id) === parseInt(stafferId))[0];
  const stafferCharCounter =
    currentStaffer.last_name.length +
    currentStaffer.first_name.length +
    currentStaffer.second_name.length +
    currentStaffer.job.length;

  const stafferName = staffers
    ? stafferCharCounter > 38
      ? `${currentStaffer.last_name} ${currentStaffer.first_name}`
      : `${currentStaffer.last_name} ${currentStaffer.first_name} ${currentStaffer.second_name}`
    : 'Загрузка...';

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: 16,
        paddingRight: 24,
        marginTop: 16,
      }}
      onClick={() => openDialog({ name: patient })}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            marginRight: 'auto',
          }}
        >
          <div
            style={{
              color: '#253446',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '14px',
              marginRight: 5,
            }}
          >
            {`${currentStaffer ? currentStaffer.job : 'Врач'}:`}
          </div>
          <div
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '14px',

              color: '#253446',
            }}
          >
            {stafferName}
          </div>
        </div>
        <div
          style={{
            fontSize: 10,
            fontStyle: 'normal',
            fontWeight: 'normal',
            color: '#A8AEB5',
          }}
        >
          {isToday(new Date(date)) ? 'Сегодня' : format(new Date(date), 'dd.MM.yy')}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 13,
        }}
      >
        <div
          style={{
            color: isNew ? '#A8AEB5' : '#52C41A',
            fontSize: 14,
            marginRight: parseInt(reciever.id) === parseInt(user.id) ? 0 : 10,
          }}
        >
          {parseInt(reciever.id) === parseInt(user.id) ? (
            <div />
          ) : isNew ? (
            <CheckIcon color="inherit" fontSize="inherit" />
          ) : (
            <DoneAll color="inherit" fontSize="inherit" />
          )}
        </div>
        <div
          style={{
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 'normal',
            color: '#515D6B',
            width: 350,
            overflowX: 'hidden',
            marginTop: 5,
          }}
        >
          {text.length >= 40 ? text.slice(0, 40) + '...' : text}
        </div>
        {unReadMessages ? (
          <div
            style={{
              marginTop: 5,
              width: 20,
              height: 20,
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 10,
                background: '#577BF9',
                color: '#fff',
                borderRadius: 10,
                height: 20,
                width: 20,
                margin: 0,
                padding: 0,
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  textAlign: 'center',
                  top: '15%',
                  left: unReadMessages > 99 ? '12%' : unReadMessages >= 10 ? '21%' : '37%',
                }}
              >
                {unReadMessages > 99 ? '99+' : unReadMessages}
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="divider-dashed" />
    </div>
  );
}
