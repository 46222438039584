import React from "react";

type NewRowAnimationProps = {
  isShown: boolean;
  rowNumber: number;
  width: number;
};

export default function NewRowAnimation({
  isShown,
  rowNumber,
  width,
}: NewRowAnimationProps) {
  return (
    <div>
      {isShown && (
        <div
          style={{
            position: "relative",
            width: 0,
            height: 0,
            pointerEvents: "none",
          }}
        >
          <div
            className="new_staffer"
            style={{
              position: "absolute",
              top: 54 * (1 + rowNumber),
              left: 0,
              width: width,
              height: 54,
              zIndex: 8,
            }}
          ></div>
        </div>
      )}
    </div>
  );
}
