/**
 * Прибавляет один 0 к минутам из date.getMinutes(), если он нужен
 *
 * @param {Number} num - количество минут, которое вернулось из date.getMinutes()
 * @returns {String} string
 */

const padTo2Digits = (num) => {
  return String(num).padStart(2, '0');
};

export default padTo2Digits;
