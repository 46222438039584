import React, { useState } from 'react';
import { connect } from 'react-redux';
import PriceState from '../state';
import Button from '../../../components/Buttons';
import TextInput from '../../../uikit/TextInput';
import { updatePriceEntry, changePriceValue } from '../operations';
import {
  Adornment,
  BackDrop,
  BlueRadioButton,
  ButtonsLayout,
  ContainerWithBorder,
  Dialog,
  FormLayout,
  Label,
  RadioLabel,
  Title,
} from './styles';
import { RootState } from '../../../store';

function ChangeValueDialog({ showDialog, priceSlice, updatePriceEntry, onStopEdit }) {
  const [type, setType] = useState('inc');
  const [money, setMoney] = useState('pers');
  const [amount, setAmount] = useState('');

  function checkOverdraft() {
    return money === 'rub' ? parseInt(amount) <= priceSlice.pickedEntry.value : parseInt(amount) <= 100.0;
  }
  function computeNewPriceValue() {
    let newValue: number = priceSlice.pickedEntry.value;
    const oldValue = parseFloat(priceSlice.pickedEntry.value);
    if (money === 'rub') {
      newValue = type === 'inc' ? Math.round(oldValue + parseFloat(amount)) : Math.round(oldValue - parseFloat(amount));
    } else {
      newValue =
        type === 'inc'
          ? Math.round(oldValue + oldValue * (parseFloat(amount) / 100))
          : Math.round(oldValue - oldValue * (parseFloat(amount) / 100));
    }
    return newValue;
  }

  return (
    priceSlice.isDialogShown && (
      <BackDrop>
        <Dialog>
          <Title>
            {priceSlice.priceChangeType === 'all' || priceSlice.priceChangeType === 'category'
              ? `Изменить цену для всех позиций в категории “${priceSlice.pickedCategory.name}”`
              : `Изменить цену для позиции “${priceSlice.pickedCategory.name}”`}
          </Title>
          <Label>Выберите как изменится цена</Label>
          <FormLayout style={{ marginLeft: -10 }}>
            <BlueRadioButton
              size={'small'}
              checked={type === 'inc'}
              onChange={(e) => setType(e.target.value)}
              value="inc"
            />
            <RadioLabel>Увеличение</RadioLabel>
            <BlueRadioButton
              size={'small'}
              checked={type === 'dec'}
              onChange={(e) => setType(e.target.value)}
              value="dec"
            />
            <RadioLabel>Уменьшение</RadioLabel>
          </FormLayout>
          <Label>Выберите в чем изменится цена</Label>
          <FormLayout style={{ marginLeft: -10 }}>
            <BlueRadioButton
              size={'small'}
              checked={money === 'pers'}
              onChange={(e) => setMoney(e.target.value)}
              value="pers"
            />

            <RadioLabel style={{ marginRight: 11 }}>Проценты</RadioLabel>
            <BlueRadioButton
              size={'small'}
              checked={money === 'rub'}
              onChange={(e) => setMoney(e.target.value)}
              value="rub"
            />
            <RadioLabel>Рубли</RadioLabel>
          </FormLayout>
          <FormLayout
            style={{
              width: 254,
              justifyContent: 'space-between',
              marginLeft: 0,
            }}
          >
            <Label>Укажите на сколько</Label>
            <div style={{ width: 100, height: 32 }}>
              <ContainerWithBorder
                error={
                  type === 'dec'
                    ? money === 'rub'
                      ? parseFloat(amount) > priceSlice.pickedEntry.value
                      : parseFloat(amount) > 100.0
                    : false
                }
              >
                <Adornment>{type === 'inc' ? '+' : '-'}</Adornment>
                <TextInput
                  transparent
                  type={'number'}
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
                <Adornment>{money === 'rub' ? 'Р' : '%'}</Adornment>
              </ContainerWithBorder>
            </div>
          </FormLayout>
          <ButtonsLayout
            style={{
              width: 213,
              justifyContent: 'space-between',
              marginLeft: 0,
              marginTop: 24,
            }}
          >
            <Button
              onClick={() => {
                const isValid: boolean = type === 'inc' || checkOverdraft();
                if (isValid) {
                  updatePriceEntry(
                    {
                      ...priceSlice.pickedEntry,
                      value: computeNewPriceValue(),
                      category: priceSlice.pickedEntry.category.id,
                    },
                    priceSlice.pickedEntry,
                  );
                  showDialog({
                    isShown: false,
                    type: '',
                    category: priceSlice.pickedCategory,
                  });
                  setAmount('');
                  onStopEdit(priceSlice.pickedEntry.id);
                }
              }}
              title={'Сохранить'}
              variant="filled"
            />
            <Button
              title={'Отменить'}
              onClick={() => {
                showDialog({
                  isShown: false,
                  type: '',
                  category: priceSlice.pickedCategory,
                });
                onStopEdit(priceSlice.pickedEntry.id);
              }}
              variant="outlined"
            />
          </ButtonsLayout>
        </Dialog>
      </BackDrop>
    )
  );
}

export default connect((state: RootState) => ({ priceSlice: state.priceSlice }), {
  showDialog: PriceState.actions.showDialog,
  updatePriceEntry,
  changePriceValue,
})(ChangeValueDialog);
