import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  categories: [{ id: 1, name: '' }],
  isLoading: true,
  response: [],
  category: {
    paginator: {
      start: 0,
      end: 0,
      fullLength: 0,
    },
  },
  price: {
    0: [
      {
        code: '',
        title: '',
        value: '',
        category: { id: 1, name: '' },
        order: 1.0,
      },
    ],
  },
  createFirstCategory: false,
  isDialogShown: false,
  priceChangeType: '',
  pickedCategory: { id: 'default' },
  pickedEntry: {},
  updateTarget: 55 * 20,
  canEdit: true,
  addCategory: false,
  isSearching: false,
  editingEntryID: null,
  menuShownFor: null,
  animateId: null,
  addBtnPosition: {
    top: 0,
    left: 0,
    lastRow: false,
  },
};

const PriceSlice = createSlice({
  name: 'price',
  initialState: INITIAL_STATE,
  reducers: {
    animateEntry: (state, action) => {
      state.animateId = action.payload;
    },
    setCreateFirstCategory: (state, action) => {
      state.createFirstCategory = action.payload;
    },
    startEditingEntry: (state, action) => {
      state.isSearching = false;
      state.editingEntryID = action.payload;
      state.addBtnPosition = {
        top: 0,
        left: 0,
        lastRow: false,
      };
    },
    showMenuFor: (state, action) => {
      state.isSearching = false;
      state.menuShownFor = action.payload;
    },
    setAddBtnPosition: (state, action) => {
      const { offsetTop, offsetLeft, lastRow, entry, itemIndex } = action.payload;
      state.addBtnPosition = {
        top: offsetTop,
        left: offsetLeft,
        lastRow,
        entry,
        itemIndex,
      };
    },
    setSearching: (state, action) => ({
      ...state,
      isSearching: action.payload,
    }),
    toggleAddCategory: (state) => ({
      ...state,
      isSearching: false,
      toggleAddCategory: !state.addCategory,
    }),
    pickCategory: (state, action) => {
      state.pickedCategory = action.payload;
      state.isSearching = false;
      state.addBtnPosition = {
        top: 0,
        left: 0,
        lastRow: false,
      };
    },
    setEdit: (state, action) => ({
      ...state,
      isSearching: false,
      canEdit: action.payload,
    }),
    toggleEdit: (state) => ({
      ...state,
      isSearching: false,
      canEdit: !state.canEdit,
    }),
    showDialog: (state, action) => ({
      ...state,
      isSearching: false,
      isDialogShown: action.payload.isShown,
      priceChangeType: action.payload.type,
      pickedCategory: action.payload.category,
      pickedEntry: action.payload.entry,
    }),
    loadCategories: (state, action) => ({
      ...state,
      isSearching: false,
      categories: action.payload,
    }),
    loadResponse: (state, action) => ({
      ...state,
      response: action.payload,
    }),
    loadPrice: (state, action) => ({
      ...state,
      price: action.payload,
      isLoading: false,
    }),
    setUpdateTarget: (state) => ({
      ...state,
      updateTarget: state.updateTarget + 55 * 20,
    }),
    changeCategoryPage: (state, action) => ({
      ...state,
      isSearching: false,
      category: {
        paginator: {
          ...state.category.paginator,
          start: action.payload.start,
          end: action.payload.end,
        },
      },
    }),
    startCategoryCreation: (state) => {
      if (state.category.paginator.start > 0 || state.category.paginator.end - state.category.paginator.start > 5) {
        state.category.paginator.start = state.category.paginator.start + 1;
      }
    },
    endCategoryCreation: (state) => {
      if (state.category.paginator.start > 0 || state.category.paginator.end - state.category.paginator.start > 5) {
        state.category.paginator.start = state.category.paginator.start - 1;
      }
    },
    initCategoryPaginator: (state, action) => ({
      ...state,
      category: {
        paginator: {
          start: 0,
          end: action.payload.firstPageLength,
          fullLength: action.payload.length,
        },
      },
    }),
  },
});

export default PriceSlice;
