import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { baseURL, HttpMethod } from '../utils/request';

export const childFormulaApi = createApi({
  reducerPath: 'childFormulaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['ChildFormula'],
  endpoints: (builder) => ({
    getChildFormula: builder.query<string[], number>({
      query: (patientId) => `/patient/${patientId}/child_formula`,
      providesTags: [{ type: 'ChildFormula', id: 'LIST' }],
    }),
    addTooth: builder.mutation<string[], { patientId: number; newToothIndex: string }>({
      query: ({ patientId, newToothIndex }) => {
        return {
          url: `/patient/${patientId}/child_formula`,
          method: HttpMethod.POST,
          body: { new_tooth_index: newToothIndex },
        };
      },
      invalidatesTags: [{ type: 'ChildFormula', id: 'LIST' }],
    }),
    updateTooth: builder.mutation<string[], { patientId: number; toothIndex: string; newToothIndex: string }>({
      query: ({ patientId, toothIndex, newToothIndex }) => {
        return {
          url: `/patient/${patientId}/child_formula/${toothIndex}`,
          method: HttpMethod.PATCH,
          body: { new_tooth_index: newToothIndex },
        };
      },
      invalidatesTags: [{ type: 'ChildFormula', id: 'LIST' }],
    }),
    deleteTooth: builder.mutation<void, { patientId: number; toothIndex: string }>({
      query: ({ patientId, toothIndex }) => {
        return {
          url: `/patient/${patientId}/child_formula/${toothIndex}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: [{ type: 'ChildFormula', id: 'LIST' }],
    }),
  }),
});

export const { useGetChildFormulaQuery, useAddToothMutation, useUpdateToothMutation, useDeleteToothMutation } =
  childFormulaApi;
