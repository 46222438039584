import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { InputLabel } from '@material-ui/core';
import styled from 'styled-components';
import TextInput from '../../../uikit/TextInput';
import TextArea from '../../../uikit/TextArea';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useUpdateUserMutation } from '../../../services/users';
import { isEmpty } from 'lodash';
import { useUpdateClinicMutation } from '../../../services/clinics';

const Body = styled.div`
  background: #ffffff;
  width: 446px;
  padding: 32px 48px;
  border-radius: 4px;
`;

const Title = styled.div`
  color: #253446;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 32px;
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;
  font-size: 14px;
  height: auto;
  line-height: 22px;
  padding: 4px 8px;
  margin-bottom: 20px;
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  font-size: 14px;
  height: auto;
  line-height: 22px;
  padding: 4px 8px;
  margin-bottom: 20px;
`;

export const StyledInputLabel = styled(InputLabel)`
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  margin-bottom: 2px;
`;

export const SaveButton = styled.button`
  background: #577bf9;
  border-radius: 4px;
  border: 0;
  color: #eff2ff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  width: 198px;
`;

export const RequiredLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #e54770;
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const schema = yup.object().shape({
  clinicName: yup.string().when({
    is: (val: string) => val.length === 0,
    then: yup.string().required('Введите название клиники'),
  }),
});

export const ChangeOrganization = () => {
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const user = useSelector((state: RootState) => state.common.user);
  const [updateUser] = useUpdateUserMutation();
  const [updateClinic] = useUpdateClinicMutation();

  const onSubmit = async (values: {
    clinicName: string;
    phone: string;
    email: string;
    licenceNumber: string;
    address: string;
    requisites: string;
  }) => {
    await updateClinic({
      id: user.clinic.id,
      name: values.clinicName,
      address: values.address,
      phone: values.phone,
      email: values.email,
      license: values.licenceNumber,
      requisites: values.requisites,
    }).unwrap();
    await updateUser({
      is_password_reset: false,
      id: user.id,
    }).unwrap();
  };

  return (
    <Body>
      <Title>Заполните данные о клинике</Title>
      <FormProvider {...methods}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <StyledInputLabel>Название клиники</StyledInputLabel>
          <RequiredLabel>* Обязательное поле</RequiredLabel>
        </div>
        <StyledTextInput name="clinicName" error={errors.clinicName} {...register('clinicName')} autoComplete="off" />
        <StyledInputLabel>Телефон</StyledInputLabel>
        <StyledTextInput name="phone" error={errors.phone} {...register('phone')} autoComplete="off" />
        <StyledInputLabel>Email</StyledInputLabel>
        <StyledTextInput name="email" error={errors.email} {...register('email')} autoComplete="off" />
        <StyledInputLabel>Номер лицензии</StyledInputLabel>
        <StyledTextInput
          name="licenceNumber"
          error={errors.licenceNumber}
          {...register('licenceNumber')}
          autoComplete="off"
        />
        <StyledInputLabel>Юридический адрес</StyledInputLabel>
        <StyledTextArea
          style={{ minHeight: 96 }}
          name="address"
          error={errors.address}
          {...register('address')}
          autoComplete="off"
        />
        <StyledInputLabel>Реквизиты клиники</StyledInputLabel>
        <StyledTextArea
          style={{ minHeight: 160 }}
          name="requisites"
          error={errors.requisites}
          {...register('requisites')}
          autoComplete="off"
        />
        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '12px' }}>
          <SaveButton disabled={!isEmpty(errors)} onClick={handleSubmit(onSubmit)}>
            Сохранить и продолжить
          </SaveButton>
        </div>
      </FormProvider>
    </Body>
  );
};
