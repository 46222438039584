import React, { FC } from 'react';

const InClinicIcon: FC = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1162 4.02076C12.78 3.24555 12.2953 2.54365 11.6894 1.95469C11.0815 1.3604 10.3649 0.88872 9.57868 0.565402C8.76083 0.226116 7.89297 0.0546875 6.99833 0.0546875C6.10368 0.0546875 5.23583 0.226116 4.41797 0.563616C3.6269 0.890402 2.91797 1.35826 2.30725 1.9529C1.70176 2.5422 1.2171 3.24401 0.880469 4.01897C0.532254 4.82254 0.355469 5.67612 0.355469 6.55469C0.355469 7.8154 0.657255 9.07076 1.25011 10.2815C1.7269 11.2547 2.39297 12.2029 3.23225 13.1047C4.66618 14.644 6.16975 15.585 6.59654 15.8368C6.71768 15.9084 6.85585 15.946 6.99654 15.9458C7.13583 15.9458 7.27333 15.91 7.39654 15.8368C7.82333 15.585 9.3269 14.644 10.7608 13.1047C11.6001 12.2047 12.2662 11.2547 12.743 10.2815C13.3394 9.07254 13.6412 7.81897 13.6412 6.55647C13.6412 5.6779 13.4644 4.82433 13.1162 4.02076ZM6.99833 14.5761C5.82154 13.8279 1.64118 10.8654 1.64118 6.55647C1.64118 5.1654 2.19654 3.85826 3.20547 2.87254C4.21797 1.88504 5.5644 1.3404 6.99833 1.3404C8.43225 1.3404 9.77868 1.88504 10.7912 2.87433C11.8001 3.85826 12.3555 5.1654 12.3555 6.55647C12.3555 10.8654 8.17511 13.8279 6.99833 14.5761ZM6.99833 3.55469C5.26261 3.55469 3.85547 4.96183 3.85547 6.69754C3.85547 8.43326 5.26261 9.8404 6.99833 9.8404C8.73404 9.8404 10.1412 8.43326 10.1412 6.69754C10.1412 4.96183 8.73404 3.55469 6.99833 3.55469ZM8.41261 8.11183C8.22712 8.29786 8.00667 8.44538 7.76395 8.5459C7.52123 8.64642 7.26103 8.69795 6.99833 8.69754C6.4644 8.69754 5.96261 8.48862 5.58404 8.11183C5.39801 7.92633 5.25049 7.70589 5.14997 7.46317C5.04945 7.22045 4.99792 6.96025 4.99833 6.69754C4.99833 6.16362 5.20725 5.66183 5.58404 5.28326C5.96261 4.90469 6.4644 4.69754 6.99833 4.69754C7.53225 4.69754 8.03404 4.90469 8.41261 5.28326C8.79118 5.66183 8.99833 6.16362 8.99833 6.69754C8.99833 7.23147 8.79118 7.73326 8.41261 8.11183Z"
        fill="#0D853D"
      />
    </svg>
  );
};

export default InClinicIcon;
