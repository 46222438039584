import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import OutlinedButton from '../Button/OutlinedButton';
import BlueButton from '../Button/BlueButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Scrollbars from 'react-custom-scrollbars-2';
import {
  InvoiceComment,
  useAddInvoiceCommentMutation,
  useAddInvoiceRowCommentMutation,
  useDeleteInvoiceCommentMutation,
  useDeleteInvoiceRowCommentMutation,
  useUpdateInvoiceCommentMutation,
  useUpdateInvoiceRowCommentMutation,
} from '../../services/invoice';
import { CommentsList } from './components';
import { CommentsFormOptions } from './types';
import { baseTextAreaHeight, totalMargin } from './constants';

const Container = styled.div`
  width: 508px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  padding-bottom: 16px;
`;

const CommentHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Textarea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  max-height: 133px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 16px 0 12px 0;
  background: #ffffff;
  border: 1px solid rgba(211, 214, 218, 0.5);
  border-radius: 4px;
  color: #515d6b;
  outline: none;
  resize: none;
  padding: 8px;
  line-height: 20px;
  letter-spacing: 0.25px;

  &:hover {
    border: 1px solid #577bf9;
  }

  &:focus {
    border: 1px solid #4555e4;
    box-shadow: 0 0 4px rgba(87, 123, 249, 0.5);
  }

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const CommentsForm: FC<CommentsFormOptions> = ({
  handleSetOpenForm,
  scrollTop,
  showComments,
  invoiceId,
  formHeight,
  positionId,
  subInvoiceId,
}) => {
  const [addInvoiceComment] = useAddInvoiceCommentMutation();
  const [addInvoiceRowComment] = useAddInvoiceRowCommentMutation();
  const [updateInvoiceComment] = useUpdateInvoiceCommentMutation();
  const [updateInvoiceRowComment] = useUpdateInvoiceRowCommentMutation();
  const [deleteInvoiceComment] = useDeleteInvoiceCommentMutation();
  const [deleteInvoiceRowComment] = useDeleteInvoiceRowCommentMutation();

  const [value, setValue] = useState<string>('');
  const [showCommentsCondition, setShowCommentsCondition] = useState<boolean>(showComments);
  const [editingComment, setEditingComment] = useState<InvoiceComment | null>(null);
  const [expandedCommentId, setExpandedCommentId] = useState<number | null>(null);

  const textareaRef = useRef(null);

  const user = useSelector((state: RootState) => state.common.user);

  const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.currentTarget.value);
  };
  const finishCommentEditing = () => {
    setEditingComment(null);
    if (expandedCommentId) {
      setExpandedCommentId(null);
    }
  };

  const saveComment = () => {
    const newComment = {
      text: value,
      user: user.id,
    };
    if (invoiceId) {
      addInvoiceComment({ invoiceId, record: newComment }).unwrap();
    }
    if (positionId) {
      addInvoiceRowComment({ subInvoiceId, invoiceRowId: positionId, record: newComment });
    }
    setShowCommentsCondition(true);
  };

  const updateComment = () => {
    const newComment = {
      id: editingComment.id,
      text: value,
      user: user.id,
    };
    if (invoiceId) {
      updateInvoiceComment({ invoiceId, record: newComment });
    }
    if (positionId) {
      updateInvoiceRowComment({ subInvoiceId, invoiceRowId: positionId, record: newComment });
    }
    finishCommentEditing();
  };

  // индикатор операции удаления
  const deleteFlag = useRef<boolean>(false);

  const deleteComment = () => {
    deleteFlag.current = true;

    if (invoiceId) {
      deleteInvoiceComment({ invoiceId, commentId: editingComment.id });
    }
    if (positionId) {
      deleteInvoiceRowComment({ subInvoiceId, invoiceRowId: positionId, commentId: editingComment.id });
    }

    finishCommentEditing();
  };

  useEffect(() => {
    if (scrollTop) {
      scrollTop?.();
    }
  }, [scrollTop]);

  useEffect(() => {
    const textarea = textareaRef?.current;

    const setTextAreaHeight = (event) => {
      textarea.style.height = 'auto';
      const scrollHeight = event.target.scrollHeight;
      textarea.style.height = `${scrollHeight}px`;

      if (scrollHeight > baseTextAreaHeight) {
        scrollTop();
      }
    };

    if (textarea || editingComment) {
      // устанавливаем фокус при первом рендере или при редактировании
      textarea.focus();

      if (editingComment) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      }

      textarea.addEventListener('keyup', setTextAreaHeight);
      return () => textarea.removeEventListener('keyup', setTextAreaHeight);
    }
  }, [scrollTop, editingComment]);

  return (
    <ClickAwayListener onClickAway={handleSetOpenForm}>
      <Container>
        <Scrollbars autoHeight autoHeightMax={formHeight ? `${formHeight - totalMargin}px` : '300px'}>
          <div style={{ padding: '16px 16px 0 16px' }}>
            {!showCommentsCondition || editingComment ? (
              <>
                <CommentHeader>
                  <span style={{ fontWeight: 400, color: '#253446' }}>Комментарий</span>
                  {editingComment && (
                    <div style={{ marginLeft: 'auto' }} onClick={deleteComment}>
                      <img src="./img/grey/delete.svg" alt="pen" />
                    </div>
                  )}
                </CommentHeader>
                <Textarea
                  ref={textareaRef}
                  value={value}
                  onChange={handleValueChange}
                  onClick={(e) => e.stopPropagation()}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <OutlinedButton width={98} height={32} style={{ marginTop: 0 }} onClick={handleSetOpenForm}>
                    Отменить
                  </OutlinedButton>
                  <BlueButton
                    width={102}
                    height={32}
                    disabled={!value}
                    style={{ marginTop: 0, marginLeft: '16px' }}
                    onClick={editingComment ? updateComment : saveComment}
                  >
                    Сохранить
                  </BlueButton>
                </div>
              </>
            ) : (
              <CommentsList
                invoiceId={invoiceId}
                subInvoiceId={subInvoiceId}
                positionId={positionId}
                expandedCommentId={expandedCommentId}
                setExpandedCommentId={setExpandedCommentId}
                setEditingComment={setEditingComment}
                setValue={setValue}
                handleSetOpenForm={handleSetOpenForm}
                deleteFlag={deleteFlag}
              />
            )}
          </div>
        </Scrollbars>
      </Container>
    </ClickAwayListener>
  );
};
