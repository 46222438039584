import { createStyles, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '14px',
    },
  }),
);

export const BlueButton = styled.button`
  border: 0;
  width: 102px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #577bf9;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export const BlueTextButton = styled.button`
  background: #ffffff;
  padding: 0;
  color: #577bf9;
  border: 0;
  margin-right: 24px;
  &:focus {
    outline: none;
  }
`;

export const Card = styled.div`
  width: 537px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 36px;
  font-weight: normal;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
  color: #515d6b;
  height: 36px;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #253446;
  margin-bottom: 16px;
`;

export const Label = styled.div`
  width: 148px;
  color: #7c8590;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 16px;
  height: 32px;
  align-items: center;
`;

export const Link = styled.div`
  color: #577bf9;
  cursor: pointer;
`;