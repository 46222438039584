import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { DateRangePicker } from '../DateRangePicker';
import MultiSelect from '../MultiSelect';
import { initPatientPage } from '../../../PatientPage/operations';
import { useGetDebtReportMutation } from '../../../../services/reports';
import { BlueButton, Filters, FiltersContainer } from './styles';
import {
  checkAndGetDateString,
  getFirstLastDateOfMonth,
  getFirstLastDateOfYear,
  getFormattedFilter,
  checkForFilterDates,
  checkForFilterResources,
} from '../../helpers';
import {
  setDebtsAndAdvancesFilter,
  setDebtsAndAdvancesReport,
  setDebtsAndAdvancesTableDisplayMode,
} from '../../reducer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export function ReportDebtsFilters() {
  const dispatch = useAppDispatch();
  const patients = useAppSelector((state) => state.patientsPage.response);
  const debtsAndAdvancesFilter = useAppSelector((state) => state.reports.debtsAndAdvancesFilter);
  const [getDebtReport] = useGetDebtReportMutation();

  const debtsSelectValues = useMemo(
    () => [
      { id: 1, title: 'Задолженности и авансы' },
      { id: 2, title: 'Задолженности' },
      { id: 3, title: 'Авансы' },
    ],
    [],
  );

  const patientsSelectValues = useMemo(
    () =>
      patients.map((patient) => {
        return { ...patient, full_name: `${patient.last_name} ${patient.first_name} ${patient.second_name}` };
      }),
    [patients],
  );

  const onFiltersChange = (value) => {
    dispatch(setDebtsAndAdvancesFilter(value));
  };

  const onCurrentMonthPick = (month, year) => {
    const [firstDateOfMonth, lastDateOfMonth] = getFirstLastDateOfMonth(month, year);
    onFiltersChange({
      start_date: firstDateOfMonth,
      end_date: lastDateOfMonth,
    });
  };

  const onCurrentYearPick = (year) => {
    const [firstDateOfYear, lastDateOfYear] = getFirstLastDateOfYear(year);
    onFiltersChange({
      start_date: firstDateOfYear,
      end_date: lastDateOfYear,
    });
  };

  const onSubmit = () => {
    if (!checkForFilterDates(debtsAndAdvancesFilter) && !checkForFilterResources(debtsAndAdvancesFilter)) {
      const formattedFilter = getFormattedFilter(debtsAndAdvancesFilter);
      getDebtReport(formattedFilter)
        .unwrap()
        .then((response) => {
          dispatch(setDebtsAndAdvancesReport(response));
          dispatch(setDebtsAndAdvancesTableDisplayMode(formattedFilter.resources.join(' ')));
        })
        .catch((error) => {
          toast.error(
            error.status === 404
              ? 'Нет данных для формирования отчета'
              : 'При формировании отчёта произошла ошибка. Попробуйте позже.',
          );
        });
    }
  };

  useEffect(() => {
    dispatch(initPatientPage());
  }, [dispatch]);

  return (
    <Filters>
      <FiltersContainer>
        <DateRangePicker
          value={[
            debtsAndAdvancesFilter.start_date ? new Date(Date.parse(debtsAndAdvancesFilter.start_date)) : null,
            debtsAndAdvancesFilter.end_date ? new Date(Date.parse(debtsAndAdvancesFilter.end_date)) : null,
          ]}
          onChange={(dates) =>
            onFiltersChange({
              start_date: dates[0] ? checkAndGetDateString(dates[0]) : null,
              end_date: dates[1] ? checkAndGetDateString(dates[1]) : null,
            })
          }
          onCurrentMonthPick={onCurrentMonthPick}
          onCurrentYearPick={onCurrentYearPick}
        />

        <MultiSelect
          name="patient"
          values={patientsSelectValues}
          pickedValues={debtsAndAdvancesFilter?.patients || []}
          optionName="full_name"
          width={242}
          search
          placeholder="Пациент"
          onChange={(patients) => onFiltersChange({ patients })}
        />
        <MultiSelect
          name="balance"
          optionName="title"
          values={debtsSelectValues}
          pickedValues={debtsAndAdvancesFilter?.resources || []}
          width={242}
          placeholder={debtsSelectValues[0].title}
          onChange={(resources) => {
            onFiltersChange({ resources });
          }}
          search
          singleSelect
        />
        <BlueButton onClick={onSubmit}>Сформировать</BlueButton>
      </FiltersContainer>
    </Filters>
  );
}
