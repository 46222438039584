import { loadUserSchedule, setPagination } from '../calendar/actions_calendar';
import lodash from 'lodash';
import { format, getHours, setDay as datefnsSetDay } from 'date-fns';
import request from '../../utils/request';
import {
  filterDates,
  filterOddEvenDays,
  getMonthString,
  loadDays,
  selectWorkdays,
} from '../../components/DateCalendar/utils';
import { fetchCabinets, fetchStaff } from '../legacyState';
import { setStafferWorkdays, showError } from '../workday/actions_workdays';
import { updateTable } from '../calendar/table';
import ruLocale from 'date-fns/locale/ru';

export const SET_PAGINATOR = 'set_paginator';
export const SET_DAY = 'set_day';
export const SET_DAYS = 'set_days';
export const SET_MONTH_STRING = 'set_month_string';
export const SET_PERIOD = 'set_period';
export const RELOAD_SCHEDULE_STATE = 'RELOAD_SCHEDULE_STATE';
export const SET_WEEKDAYS = 'set_weekdays';
export const SET_REF = 'set_ref';
export const SET_ODD_OR_EVEN = 'set_odd_or_even';
export const INCLUDE_DAY = 'unclude_day';
export const EXCLUDE_DAY = 'exclude_day';
export const FLUSH_REFS = 'flush_refs';
export const SET_INITIAL = 'set_initial';

export function setPaginator(array) {
  return (dispatch) => {
    let arr = lodash.chunk(array, 9);
    let length = arr.length;
    dispatch({
      type: SET_PAGINATOR,
      payload: { page: 0, length },
    });
  };
}

export function incPaginator() {
  return async (dispatch, getState) => {
    const { schedule } = getState();
    let page = schedule.paginator.page + 1;
    if (page > schedule.paginator.length - 1) {
      page = 0;
    }
    await dispatch({
      type: SET_PAGINATOR,
      payload: { page, length: schedule.paginator.length },
    });
  };
}

export function decPaginator() {
  return async (dispatch, getState) => {
    const { schedule } = getState();
    let page = schedule.paginator.page - 1;
    if (page < 0) {
      page = schedule.paginator.length - 1;
    }
    await dispatch({
      type: SET_PAGINATOR,
      payload: { page, length: schedule.paginator.length },
    });
  };
}

export function setDay(day) {
  return {
    type: SET_DAY,
    payload: day,
  };
}

export function setStafferColor(color) {
  return (dispatch, getState) => {
    const { calendar } = getState();
    try {
      request.users.update_user_details({ color }, calendar.workdays.user);
    } catch (error) {
      //
    }
  };
}

export function setDays(days) {
  return {
    type: SET_DAYS,
    payload: days,
  };
}

export function setMonthString(string) {
  return {
    type: SET_MONTH_STRING,
    payload: string,
  };
}

export function loadPeriod() {
  return (dispatch, getState) => {
    const { schedule } = getState();
    let days = loadDays(schedule.period.start, schedule.period.end);
    let monthString = getMonthString(schedule.period.start);
    dispatch(setMonthString(monthString));
    dispatch(setDays(days));
  };
}

export function reloadState() {
  return {
    type: RELOAD_SCHEDULE_STATE,
    payload: true,
  };
}

export function setPeriod(period) {
  return async (dispatch) => {
    await dispatch({
      type: SET_PERIOD,
      payload: period,
    });
    await dispatch(loadPeriod());
  };
}

export function resetPaginator() {
  return async (dispatch, getState) => {
    const { schedule } = getState();
    let paginator = schedule.paginator;
    paginator.page = 0;
    await dispatch({
      type: SET_PAGINATOR,
      payload: { ...paginator },
    });
  };
}

export function resetWeekDays() {
  return {
    type: SET_WEEKDAYS,
    payload: [],
  };
}

export function pickAllDays() {
  return async (dispatch, getState) => {
    const { schedule, workday, workdays, calendar, appointments, tasks } = getState();
    let days = schedule.days;
    if (workday.cabinet.name !== 'Выбрать кабинет') {
      const { cabinet } = workday;
      let working_days = [0, 1, 2, 3, 4, 5, 6].filter((day) => {
        let day_name = format(datefnsSetDay(new Date(), day), 'eeee', {
          locale: ruLocale,
        }).toLowerCase();
        let date = workdays.schedule[day_name][0];
        return (
          getHours(date.starts_at) !== getHours(date.ends_at) ||
          (getHours(date.starts_at) === 3 && getHours(date.ends_at) === 3)
        );
      });
      days = filterDates(
        days,
        cabinet,
        schedule.period.end,
        schedule.period.start,
        schedule.initial ? working_days : schedule.weekDays,
        schedule.isOdd,
        schedule.isEven,
        schedule.dates,
      );
      let selectedWorkdays = selectWorkdays(
        days,
        calendar.workdays.user,
        schedule.array,
        appointments.responce.reduce((acc, item) => ({ [Math.random()]: item, ...acc }), {}),
        tasks.responce.reduce((acc, item) => ({ [[Math.random()]]: item, ...acc }), {}),
      );
      dispatch(updateTable(selectedWorkdays));
      dispatch(setPagination(selectedWorkdays.length));
      dispatch(setDays(days));
      dispatch(setStafferWorkdays(days));
    } else {
      dispatch(showError('Ошибка. Кабинет не выбран'));
    }
  };
}

export function pickAOddEvenDays() {
  return async (dispatch, getState) => {
    const { schedule, workday, calendar } = getState();
    let days = schedule.days;
    if (workday.cabinet.name !== 'Выбрать кабинет') {
      const { odd, even, cabinet } = workday;
      days = filterOddEvenDays(odd, even, days, cabinet, schedule.period.end);
      let selectedWorkdays = selectWorkdays(days, calendar.workdays.user, schedule.array);
      dispatch(updateTable(selectedWorkdays));
      dispatch(setPagination(selectedWorkdays.length));
      dispatch(setDays(days));
      dispatch(setStafferWorkdays(days));
      dispatch(resetWeekDays());
    } else {
      dispatch(showError('Ошибка. Кабинет не выбран'));
    }
  };
}

export function setInitial(initial) {
  return {
    type: SET_INITIAL,
    payload: { initial },
  };
}

export function setWeekDays(day) {
  return async (dispatch, getState) => {
    const { schedule, workdays } = getState();
    let weekDays = schedule.weekDays;
    let day_name = format(datefnsSetDay(new Date(), day), 'eeee', {
      locale: ruLocale,
    }).toLowerCase();
    let date = workdays.schedule[day_name][0];
    let isWorking =
      getHours(date.starts_at) !== getHours(date.ends_at) ||
      (getHours(date.starts_at) === 3 && getHours(date.ends_at) === 3);
    let index = weekDays.indexOf(day);
    if (isWorking) {
      if (index !== -1) {
        weekDays.splice(index, 1);
      } else {
        weekDays.push(day);
      }
    } else {
      dispatch(showError('Выбран выходной день'));
    }
    await dispatch(setInitial(false));
    await dispatch({ type: SET_WEEKDAYS, payload: weekDays });
  };
}

export function loadAllShedule(user) {
  return async (dispatch, getState) => {
    const { clinic, common } = getState();
    await dispatch(fetchStaff());
    await dispatch(fetchCabinets());
    let filters = {
      doctor: [user.id],
      cabinet: clinic.cabinets.map((item) => item.id),
      job: lodash.uniq(clinic.staff.map((item) => item.job)),
      day: format(new Date(), 'yyyy-MM-dd'),
    };
    let responce = await request.clinic.get_schedule(common.user.clinic, filters);
    responce = responce.data.items.map((item) => {
      return {
        date: {
          formated: format(new Date(item.starts_at), 'dd.MM.yy'),
          timestamp: item.starts_at,
        },
        cabinet: item.worker.last_name + ' ' + item.worker.first_name[0] + '. ' + item.worker.second_name[0] + '.',
        staffer: format(new Date(item.starts_at), 'dd.MM.yy'),
        item: item,
      };
    });
    dispatch(loadUserSchedule(responce));
  };
}

export function setRef(element) {
  return (dispatch, getState) => {
    let { schedule } = getState();
    if (element != null) {
      let refs = { ...schedule.refs, [element.id]: element };
      dispatch({ type: SET_REF, payload: refs });
    }
  };
}

export function flushRefs() {
  return { type: FLUSH_REFS, payload: true };
}

export function setOddOrEven(odd, even) {
  return {
    type: SET_ODD_OR_EVEN,
    payload: { odd, even },
  };
}

export function includeDay(day, picked) {
  //
  return (dispatch, getState) => {
    let { schedule } = getState();
    let include = [...schedule.dates.include];
    let exclude = [...schedule.dates.exclude];
    let indexOfExclude = exclude.indexOf(day);
    let indexOfInclude = include.indexOf(day);
    if (indexOfInclude === -1 && indexOfExclude === -1) {
      if (picked === true) {
        //
        exclude.push(day);
      } else {
        //
        include.push(day);
      }
    } else if (indexOfExclude !== -1) {
      exclude.splice(indexOfExclude, 1);
      include.push(day);
    } else if (indexOfInclude !== -1) {
      include.splice(indexOfExclude, 1);
      exclude.push(day);
    }
    //
    dispatch({ type: INCLUDE_DAY, payload: { include, exclude } });
  };
}

export function excludeDay(day) {
  return (dispatch, getState) => {
    let { schedule } = getState();
    let include = [...schedule.dates.include];
    let exclude = [...schedule.dates.exclude];
    let indexOfExclude = exclude.indexOf(day);

    if (indexOfExclude === -1) {
      exclude.push(day);
    }

    dispatch({ type: EXCLUDE_DAY, payload: { include, exclude } });
  };
}

export function resetWeekdays() {
  return { type: SET_WEEKDAYS, payload: [] };
}
