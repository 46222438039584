import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL, HttpMethod } from '../utils/request';

export interface Clinic {
  address: string;
  requisites: string;
  email: string;
  id: number;
  license: string;
  logo: string;
  name: string;
  phone: string;
}

export const clinicApi = createApi({
  reducerPath: 'clinicsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['Clinic'],
  endpoints: (builder) => ({
    getClinic: builder.query<Clinic, void>({
      query: () => 'clinics',
      providesTags: [{ type: 'Clinic', id: 'LIST' }],
    }),
    addClinic: builder.mutation<Clinic, Partial<Clinic>>({
      query: (data) => {
        return {
          url: 'clinics',
          method: HttpMethod.POST,
          body: data,
        };
      },
      invalidatesTags: [{ type: 'Clinic', id: 'LIST' }],
    }),
    updateClinic: builder.mutation<Clinic, Partial<Clinic>>({
      query: (data) => {
        const { id, ...body } = data;
        return {
          url: `clinics/${id}`,
          method: HttpMethod.PATCH,
          body,
        };
      },
      invalidatesTags: [{ type: 'Clinic', id: 'LIST' }],
    }),
  }),
});

export const { endpoints, useGetClinicQuery, useAddClinicMutation, useUpdateClinicMutation } = clinicApi;
