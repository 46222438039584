import React from 'react';
import { Status } from '../../../uikit/Status/Status';

export enum EAccountStatus {
  // Не выставлен
  NOT_FILLED = 'NOT_FILLED',
  // Выставлен
  FILLED = 'FILLED',
  // Ожидает отправку
  PENDING_SEND = 'PENDING_SEND',
  // Ожидает оплату
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  // На редактировании
  EDIT = 'EDIT',
  // Задолженность по счету
  DEBT = 'DEBT',
  // Оплачен
  PAID = 'PAID',
}

const getStatusProps = (status: EAccountStatus) => {
  switch (status) {
    case EAccountStatus.NOT_FILLED:
      return {
        text: 'Не выставлен',
        color: '#E54770',
        backgroundColor: '#FDEDF1',
      };
    case EAccountStatus.PENDING_SEND:
      return {
        text: 'Ожидает отправку',
        color: '#7C8590',
        backgroundColor: '#F6F6FA',
      };
    case EAccountStatus.PENDING_PAYMENT:
      return {
        text: 'Ожидает оплату',
        color: '#FAAD14',
        backgroundColor: '#FFFBE6',
      };
    case EAccountStatus.EDIT:
      return {
        text: 'На редактировании',
        color: '#FE9C34',
        backgroundColor: '#FFF6EB',
      };
    case EAccountStatus.PAID:
      return {
        text: 'Оплачен',
        color: '#52C41A',
        backgroundColor: '#EEF9E8',
      };
    case EAccountStatus.DEBT:
      return {
        text: 'Задолженность по счету',
        color: '#FE9C34',
        backgroundColor: '#FFF6EB',
      };
  }
};

type Props = {
  status: EAccountStatus;
};

export function AccountStatus({ status }: Props) {
  return <Status {...getStatusProps(status)} />;
}
