import React from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default styled(({ children, className, dark = false, ...props }: TooltipProps & { dark?: boolean }) => (
  <Tooltip
    // eslint-disable-next-line react/no-children-prop
    children={children}
    classes={className ? { popper: className } : {}}
    enterDelay={2000}
    enterNextDelay={2000}
    placement="top"
    arrow
    {...props}
  />
))`
  & .MuiTooltip-tooltip {
    background: ${({ dark }) => (dark ? '#253446' : '#FFFFFF')};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    padding: 5px 8px;
    color: ${({ dark }) => (dark ? '#FFFFFF' : '#515D6B')};
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }

  & .MuiTooltip-arrow {
    color: ${({ dark }) => (dark ? '#253446' : '#FFFFFF')};
  }
`;
