import React, { FC, memo } from 'react';
import { VisitDate } from './VisitDate';
import { TypographyBox } from '../index';

type VisitHeaderType = {
  isAppointmentHeader: boolean;
  employee: string;
  employeeJob: string;
  visitStartedAt: string;
  visitEndedAt: string;
  invoiceCreatedAt: string;
};
export const VisitHeader: FC<VisitHeaderType> = memo(
  ({ isAppointmentHeader, employeeJob, visitStartedAt, visitEndedAt, invoiceCreatedAt, employee }) => {
    const invoiceAuthorJobTitle = isAppointmentHeader ? 'Врач' : employeeJob;
    return (
      <>
        <TypographyBox>{isAppointmentHeader ? 'Визит' : 'Счет'}</TypographyBox>
        <div style={{ marginRight: 'auto', display: 'flex' }}>
          {isAppointmentHeader ? (
            <VisitDate startDate={visitStartedAt} endDate={visitEndedAt} />
          ) : (
            <VisitDate startDate={invoiceCreatedAt} />
          )}
          <div>
            {invoiceAuthorJobTitle}: {employee}
          </div>
        </div>
      </>
    );
  },
);
VisitHeader.displayName = 'VisitHeader';
