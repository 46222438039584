import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, ClickAwayListener } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { DiagnosisMKBList } from './DiagnosisMKBList';
import { SearchHeader } from '../SearchHeader';
import request from '../../../../utils/request';
import { ToothStateType } from '../../Formula/StateMenu';
import { uniqBy } from 'lodash';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'absolute',
      border: '1px solid #D3D6DA',
      boxSizing: 'border-box',
      boxShadow: '0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16)',
      borderRadius: 4,
      marginRight: 16,
      marginBottom: 24,
      marginLeft: 8,
      transition: 'all 500ms ease-in-out',
      width: 'calc(100% - 24px)',
      top: 40,
      zIndex: 1,
    },
    header: {
      background: '#F9F9F9',
      padding: 0,
    },
    headerContent: {
      display: 'flex',
      padding: '0 16px',
      borderBottom: '2px solid #7C8590',
    },
    text: {
      color: '#A8AEB5',
      fontWeight: 'normal',
      fontSize: 14,
      marginRight: 8,
    },
    content: {
      padding: '0 16px 12px 16px',
      '&:last-child': {
        paddingBottom: 12,
      },
    },
    noCompleted: {
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '120%',
      color: '#A8AEB5',
      padding: '20px 14px 10px',
    },
  }),
);

type Props = {
  doctorId: number;
  usedTemplates: DiagnosisMKBTemplate[];
  toothState: ToothStateType;
  onAddTemplate: (template: DiagnosisMKBTemplate) => void;
  clearFocus: VoidFunction;
};

export type DiagnosisMKBTemplate = {
  id: number;
  code: string;
  text: string;
  selected: boolean;
};

function getTemplates(templates, usedTemplates) {
  return templates.map((t) => {
    if (usedTemplates.find((u) => u.code === t.code)) {
      return { ...t, selected: true };
    }
    return t;
  });
}

export function DiagnosisMKBTemplates({ doctorId, usedTemplates, toothState, onAddTemplate, clearFocus }: Props) {
  const classes = useStyles();

  const [dictionaryTemplates, setDictionaryTemplates] = useState<DiagnosisMKBTemplate[]>([]);
  const [templates, setTemplates] = useState<DiagnosisMKBTemplate[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<DiagnosisMKBTemplate>(null);

  const dicTemplates = dictionaryTemplates.filter((d) => !templates.find((t) => t.id === d.id));

  useEffect(() => {
    const fetchData = async () => {
      // Для плавности анимации показывать лоадер через секунду
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (!(dataTemplates && dataTemplates.status === 200)) {
          setIsLoading(true);
        }
      }, 1000);

      const dataTemplates = await request.users.mkb_templates(doctorId, toothState.mnemo, searchQuery);
      const dataDicTemplates = await request.users.load_dictionary_mkb(toothState.mnemo, searchQuery);

      setDictionaryTemplates(dataDicTemplates.data);
      setTemplates(
        dataDicTemplates.data.reduce((result, value) => {
          const template = dataTemplates.data.find((d) => value.id === d.mkb_diagnosis);
          if (template) {
            result.push({
              ...value,
              id: template.mkb_diagnosis,
              templateId: template.id,
            });
          }
          return result;
        }, []),
      );
      setIsLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <ClickAwayListener onClickAway={clearFocus}>
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          title={
            <div className={classes.headerContent}>
              <SearchHeader
                type="text"
                placeholder="Код"
                name="code"
                width={100}
                text="Код"
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                noBorder
              />
              <SearchHeader
                type="text"
                placeholder="Диагноз"
                name="text"
                text="Диагноз"
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                noBorder
              />
            </div>
          }
        />
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '397px',
            }}
          >
            <div className="spinner" />
          </div>
        ) : (
          <Scrollbars
            autoHeight
            autoHeightMax={397}
            style={{
              width: '100%',
            }}
          >
            <CardContent className={classes.content}>
              <DiagnosisMKBList
                title="Шаблоны"
                style={{ marginBottom: 20 }}
                templates={getTemplates(templates, usedTemplates)}
                subTitle={toothState.title}
                onClickMenu={async (template) => {
                  await request.users.delete_mkb_templates(template.templateId);
                  setTemplates(templates.filter((t) => t.id !== template.id));
                  setDictionaryTemplates(uniqBy([...dictionaryTemplates, template], 'id'));
                }}
                onAddTemplate={(template) => onAddTemplate(template)}
              />
              <DiagnosisMKBList
                isDictionary
                title="Справочник МКБ"
                templates={getTemplates(dicTemplates, usedTemplates)}
                onClickMenu={async (template) => {
                  const addTemplate = await request.users.add_mkb_templates(doctorId, toothState.mnemo, template.id);
                  setTemplates([...templates, { ...template, templateId: addTemplate.data.id }]);
                }}
                onAddTemplate={(template) => onAddTemplate(template)}
              />
            </CardContent>
          </Scrollbars>
        )}
      </Card>
    </ClickAwayListener>
  );
}
