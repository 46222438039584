import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PriceSlice from "../state";
import { addNewRowFromSearch, addRowToPrice } from "../operations";
const { setAddBtnPosition, startEditingEntry } = PriceSlice.actions;

const Container = styled.div`
  position: relative;
  height: 0;
  width: 0;
`;
type LayoutProps = {
  lastRow: boolean;
  top: number;
  left: number;
};
const Layout = styled.div<LayoutProps>`
  position: absolute;
  top: ${({ top, lastRow }) => (lastRow ? top + 29 + 55 : top + 29.5)}px;
  left: ${({ left }) => left - 30}px;
  width: 1106px;
  height: 2px;
  background-color: #577bf9;
  z-index: 1;
`;
const Plus = styled.img<LayoutProps>`
  position: absolute;
  top: ${({ top, lastRow }) =>
    lastRow ? top + 29 - 16 + 55 : top + 29 - 16}px;
  left: ${({ left }) => left - 30 - 32}px;
  cursor: pointer;
  z-index: 15;
`;

function AddEntry({
  priceSlice,
  addNewRowFromSearch,
  startEditingEntry,
  addRowToPrice,
  common,
  scrollTop,
}: any) {
  const {
    canEdit,
    isSearching,
    price,
    animateId,
    addBtnPosition: { top, left, lastRow, entry, itemIndex },
  } = priceSlice;

  function scrollToEntry(entry: any) {
    let element: any = document.getElementById(entry.id);
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }

  async function addNewEntry() {
    if (priceSlice.isSearching) {
      await addNewRowFromSearch(entry.category.id, entry.id, "before");
      startEditingEntry("new entry");
      scrollToEntry(entry);
    } else {
      await addRowToPrice(entry.category.id, itemIndex, "before");

      startEditingEntry("new entry");
    }
  }
  async function addNewEntryAfterCurrent() {
    if (priceSlice.isSearching) {
      await addNewRowFromSearch(entry.category.id, entry.id, "after");
      startEditingEntry("new entry");

      scrollToEntry(entry);
    } else {
      await addRowToPrice(entry.category.id, itemIndex, "after");

      startEditingEntry("new entry");
    }
  }

  function handleClick() {
    lastRow ? addNewEntryAfterCurrent() : addNewEntry();
  }
  const searchLock = isSearching === true && Object.keys(price).length === 0;
  return (
    <>
      {canEdit && !searchLock && animateId === null && top > 0 && left > 80 && (
        <Container>
          <Layout lastRow={lastRow} top={top - scrollTop} left={left} />
          <Plus
            lastRow={lastRow}
            top={top - scrollTop}
            left={left}
            onClick={handleClick}
            src={"./img/blue/priceadd.svg"}
          />
        </Container>
      )}
    </>
  );
}

export default connect(
  (state: any) => ({ common: state.common, priceSlice: state.priceSlice }),
  {
    setAddBtnPosition,
    startEditingEntry,
    addNewRowFromSearch,
    addRowToPrice,
  }
)(AddEntry);
