import React, { useState } from 'react';
import Chat from '../../../../components/Chat/Chat.js';
import { useAppSelector } from '../../../../store/hooks';
import { ReportName } from '../../enums';
import { performPrint } from '../../helpers';
import ExportModal from '../ExportModal';
import { BackButton, ChatContainer, LayoutHeader, LoadPrintBox, Title } from './styles';

type Props = {
  title: string;
  onBackButtonClick: VoidFunction;
  reportName: ReportName;
};

export function ReportHeader({ title, onBackButtonClick, reportName }: Props) {
  const isExpanded = useAppSelector((state) => state.drawerState.isExpanded);
  const { revenueFilter, debtsAndAdvancesFilter, reportDisplayMode } = useAppSelector((state) => state.reports);
  const [showExportWindow, setShowExportWindow] = useState<boolean>(false);
  const toggleExportWindow = () => setShowExportWindow(!showExportWindow);

  return (
    <LayoutHeader>
      {showExportWindow && <ExportModal reportType={reportName} cancel={toggleExportWindow} />}
      <BackButton src="./img/black/go-back.svg" onClick={() => onBackButtonClick()} />
      <LoadPrintBox>
        <Title>{title}</Title>
        <div>
          <img
            src="./img/grey/upload.svg"
            alt={'upload'}
            onClick={toggleExportWindow}
            width={18}
            height={18}
            style={{ marginLeft: '8px' }}
          />
          <img
            src="./img/grey/printer.svg"
            alt={'printer'}
            onClick={() => performPrint({ mode: reportDisplayMode, revenueFilter, debtsAndAdvancesFilter })}
            width={18}
            height={18}
            style={{ marginLeft: '4px' }}
          />
        </div>
      </LoadPrintBox>
      <ChatContainer isExpanded={isExpanded}>
        <Chat />
      </ChatContainer>
    </LayoutHeader>
  );
}
