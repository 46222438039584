import { createSlice } from '@reduxjs/toolkit';
import { groupBy, prop, map, compose, head, omit, isEmpty, filter, keys } from 'ramda';

export const getIds = map(prop('id'));
export const groupByIds = compose(map(head), groupBy(prop('id')));
export const dropEmptyProps = (object) => {
  const emptyPropsKeys = filter((key) => isEmpty(object[key].trim()), keys(object));
  return omit(emptyPropsKeys, object);
};
export const normalizePatient = (response) => ({
  byId: groupByIds(response),
  allIds: getIds(response),
});

const INITIAL_STATE = {
  patients: {
    byId: {
      0: {},
    },
    allIds: [],
  },
  response: [],
  searchQuery: {},
  isCreating: false,
  isSearching: false,
  isLoading: false,
  data: {},
  isRedirectingToSchedule: false,
  isDialogOpened: false,
  currentStafferId: '',
  tipId: '',
  newPatientId: null,
  errors: {},
};

const PatientPage = createSlice({
  name: 'PatientPage',
  initialState: INITIAL_STATE,
  reducers: {
    loadResponse: (state, action) => {
      state.response = action.payload;
    },
    loadPatients: (state, action) => {
      state.patients = normalizePatient(action.payload);
      state.isDialogOpened = false;
      state.currentStafferId = '';
      state.newPatientId = null;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    pushNewPatient: (state, action) => {
      state.response.unshift(action.payload);
      state.patients = normalizePatient(state.response);
      state.searchQuery = {};
      state.isSearching = false;
    },
    closeTip: (state) => {
      state.tipId = '';
    },
    patientCreated: (state, action) => {
      state.isCreating = false;
      state.newPatientId = action.payload.id;
      state.response.unshift(action.payload);
      state.patients = normalizePatient(state.response);
      state.searchQuery = {};
      state.isSearching = false;
    },
    searchFor: (state, action) => {
      state.isSearching = true;
      state.searchQuery = dropEmptyProps({
        ...state.searchQuery,
        ...action.payload,
      });
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    setNewPatient: (state, action) => {
      state.newPatientId = action.payload;
      state.searchQuery = {};
      state.isSearching = false;
    },
    dropSearch: (state) => {
      state.searchQuery = {};
      state.isSearching = false;
    },
    setCreatingPatient: (state, action) => {
      state.isCreating = action.payload;
      state.isSearching = false;
    },
    setData: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    redirectToSchedule: (state) => {
      state.isRedirectingToSchedule = true;
    },
    dropInputFields: (state) => {
      state.data = {};
    },
    openDialog: (state, action) => {
      state.isDialogOpened = true;
      state.currentStafferId = action.payload;
    },
    closeDialog: (state) => {
      state.isDialogOpened = false;
      state.currentStafferId = '';
    },
    setError: (state, action) => {
      state.errors = {
        ...state.errors,
        ...action.payload,
      };
    },
    dropErrors: (state) => {
      state.errors = {};
    },
    dropState: () => {
      return INITIAL_STATE;
    },
  },
});

export default PatientPage;
