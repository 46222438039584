import { RESIZE_END_TIME, RESIZE_START_TIME, RESIZE_ENDED } from "./types";

const INITIAL_STATE = {
  mouse: { x: 0, y: 0 },
  resize: {},
  resizing: false,
  top: false,
  type: "",
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESIZE_END_TIME:
      return {
        ...state,
        ...{
          resize: { [action.payload.id]: true },
          top: action.payload.top,
          resizing: true,
          type: action.payload.type,
        },
      };
    case RESIZE_START_TIME:
      return {
        ...state,
        ...{
          resize: { [action.payload.id]: true },
          top: action.payload.top,
          resizing: true,
          type: action.payload.type,
        },
      };
    case RESIZE_ENDED:
      return {
        ...state,
        ...{ resize: {}, top: false, resizing: false, type: "" },
      };
    default:
      return state;
  }
}
