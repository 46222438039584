import React from 'react';

import Patient from './Patient';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { TEditiableFieldsValues, TEditingFields } from './Patient/types';

interface IBodyProps {
  isFullView: boolean;
  data;
  isLoading: boolean;
  patient;
  setPatient: (patient) => void;
  patientSelected: boolean;
  setPatientSelected: (isSelect: boolean) => void;
  editableFields?: Partial<TEditingFields>;
  handleFieldEdit?: (fieldName, value) => void;
  editiableFieldsValues?: TEditiableFieldsValues;
}

export default function Body({
  isFullView,
  data,
  isLoading,
  patient,
  setPatient,
  patientSelected,
  setPatientSelected,
  editableFields = undefined,
  handleFieldEdit = undefined,
  editiableFieldsValues = undefined,
}: IBodyProps) {
  return isLoading ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '330px',
      }}
    >
      <div className="spinner" />
    </div>
  ) : (
    <Scrollbars
      autoHeight
      autoHeightMax={330}
      style={{
        width: '100%',
      }}
    >
      {!patientSelected ? (
        data.map((patient, index: number) => (
          <Patient
            key={patient.patientId}
            data={patient}
            isColored={index % 2 != 0}
            handleClick={() => {
              setPatient(patient);
              setPatientSelected(true);
            }}
            isFullView={isFullView}
          />
        ))
      ) : (
        <Patient
          key={patient.patientId}
          data={patient}
          isColored={false}
          isFullView={isFullView}
          editableFields={editableFields}
          handleFieldEdit={handleFieldEdit}
          editiableFieldsValues={editiableFieldsValues}
          isSelected
        />
      )}
    </Scrollbars>
  );
}
