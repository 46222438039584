import styled from 'styled-components';
import { Dialog, InputLabel, FormControlLabel } from '@material-ui/core';

import TextInput from '../../../../uikit/TextInput';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { Radio } from '../../../../components/Radio';

export const StyledDialog = styled(Dialog)`
  & .MuiBackdrop-root {
    backdrop-filter: blur(4px);
    background-color: rgba(81, 93, 107, 0.6);
  }

  & .MuiDialog-paper {
    width: 552px;
    height: 700px;
    box-shadow: none;
    overflow-y: hidden;
  }

  & .MuiDialogTitle-root {
    background: #f9f9f9;
    padding: 24px 24px 16px;

    h2 {
      font-size: 20px;
      line-height: 28px;
    }
  }

  & .MuiDialogContent-root {
    background: #f9f9f9;
    overflow-y: hidden;
  }

  & .MuiDialogActions-root {
    background: #ffffff;
    filter: drop-shadow(0px -4px 8px rgba(155, 154, 154, 0.2));
    padding: 16px 24px;
  }

  & .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 16px;
  }
`;

export const Block = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled.div`
  color: #253446;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 12px;
`;

export const PermissionBlock = styled.div`
  background-color: #fff;
  border: 1px solid #d3d6da;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 24px;
`;

export const PermissionTitle = styled(Title)`
  margin-bottom: 24px;
`;

export const Label = styled.div`
  color: #515d6b;
  font-size: 14px;
  line-height: 22px;
`;

export const HighlightedLabel = styled(Label)`
  font-weight: 500;
`;

export const StyledTextInput = styled(TextInput)`
  width: 228px;
  font-size: 14px;
  height: auto;
  line-height: 22px;
  margin-top: 24px;
  padding: 4px 8px;
`;

export const StyledInputLabel = styled(InputLabel)`
  color: #515d6b;
  font-size: 14px;
  line-height: 22px;
  transform: none;
`;

export const StyledTooltip = styled(Tooltip)`
  max-width: 240px;
`;

export const Account = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PasswordIcon = styled.span`
  position: absolute;
  bottom: 4px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SwitchFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  margin-right: 0;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    padding: 4px;
  }
`;

export const RadioFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: -4px;
  }
`;

export const Error = styled.div`
  font-size: 10px;
  color: #c3000a;
  font-style: normal;
  font-weight: normal;
  width: 228px;
`;

export const Errors = styled.div`
  display: flex;
  //align-items: center;
  padding-top: 2px;
  justify-content: space-between;
  height: 24px;
`;
