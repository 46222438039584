import React, { useState } from "react";

import { TextCutOFF } from "../../../components/DoctorFilter/styles";
import TextInput from "../../../uikit/TextInput";
import { ClickAwayListener } from "@material-ui/core";

export default function EmailText({ children }: any) {
  const [selected, SetSelected] = useState(false);
  return (
    <>
      {selected ? (
        <ClickAwayListener onClickAway={() => SetSelected(false)}>
          <div style={{ width: 100, height: 32, marginLeft: -8 }}>
            <TextInput autoSelect transparent value={children} />
          </div>
        </ClickAwayListener>
      ) : (
        <div onClick={() => SetSelected(true)}>
          <TextCutOFF>{children}</TextCutOFF>{" "}
        </div>
      )}
    </>
  );
}
