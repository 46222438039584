import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { DateRangePicker } from '../DateRangePicker';
import MultiSelect from '../MultiSelect';
import { initPatientPage } from '../../../PatientPage/operations';
import { BlueButton, Filters, FiltersContainer } from './styles';
import {
  checkAndGetDateString,
  getFirstLastDateOfMonth,
  getFirstLastDateOfYear,
  getCurrentTimeString,
  getFormattedHistoryFilter,
  checkForFilterDates,
  checkForFilterPatient,
} from '../../helpers';
import { setHistoryFilter } from '../../reducer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import request from '../../../../utils/request';
import { uploadFile } from '../../../../utils/print';
import { UserRole } from '../../../../services/users';

export function HistoryFilters() {
  const dispatch = useAppDispatch();
  const patients = useAppSelector((state) => state.patientsPage.response);
  const staff = useAppSelector((state) => state.staff);
  const historyFilter = useAppSelector((state) => state.reports.historyFilter);

  const patientsSelectValues = useMemo(
    () =>
      patients.map((patient) => {
        return { ...patient, full_name: `${patient.last_name} ${patient.first_name} ${patient.second_name || ''}` };
      }),
    [patients],
  );

  const doctors = useMemo(() => {
    return staff.responce.filter((doctor) => doctor.role === UserRole.Doctor);
  }, [staff]);

  const onFiltersChange = (value) => {
    dispatch(setHistoryFilter(value));
  };

  const onCurrentMonthPick = (month, year) => {
    const [firstDateOfMonth, lastDateOfMonth] = getFirstLastDateOfMonth(month, year);
    onFiltersChange({
      start_date: firstDateOfMonth,
      end_date: lastDateOfMonth,
    });
  };

  const onCurrentYearPick = (year) => {
    const [firstDateOfYear, lastDateOfYear] = getFirstLastDateOfYear(year);
    onFiltersChange({
      start_date: firstDateOfYear,
      end_date: lastDateOfYear,
    });
  };

  const onSubmit = async () => {
    if (!checkForFilterDates(historyFilter) && !checkForFilterPatient(historyFilter)) {
      const formattedFilter = getFormattedHistoryFilter(historyFilter);

      try {
        const result = await request.clinic.download_history_patient(formattedFilter);
        uploadFile(result.data, `${getCurrentTimeString()}-history-client.xlsx`);
      } catch (_) {
        toast.error('При формировании отчёта произошла ошибка. Попробуйте позже.');
      }
    }
  };

  useEffect(() => {
    dispatch(initPatientPage());
  }, [dispatch]);

  return (
    <Filters>
      <FiltersContainer>
        <DateRangePicker
          value={[
            historyFilter.start_date ? new Date(Date.parse(historyFilter.start_date)) : null,
            historyFilter.end_date ? new Date(Date.parse(historyFilter.end_date)) : null,
          ]}
          onChange={(dates) =>
            onFiltersChange({
              start_date: dates[0] ? checkAndGetDateString(dates[0]) : null,
              end_date: dates[1] ? checkAndGetDateString(dates[1]) : null,
            })
          }
          onCurrentMonthPick={onCurrentMonthPick}
          onCurrentYearPick={onCurrentYearPick}
        />

        <MultiSelect
          name="patient"
          values={patientsSelectValues}
          pickedValues={historyFilter?.patients || []}
          optionName="full_name"
          width={242}
          search
          placeholder="Пациент"
          onChange={(patients) => onFiltersChange({ patients })}
        />

        <MultiSelect
          name="doctor"
          values={doctors}
          pickedValues={historyFilter?.doctor_id || []}
          optionName="fullname"
          width={174}
          search
          category="job"
          placeholder="Врач"
          onChange={(doctor_id) => onFiltersChange({ doctor_id })}
        />

        <BlueButton onClick={onSubmit}>Сформировать Excel-файл</BlueButton>
      </FiltersContainer>
    </Filters>
  );
}
