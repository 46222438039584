import { MedcardEntry } from '../../../../services/medcard';

export const renderJournalEntryTitle = (entry: MedcardEntry) => {
  const toothIndex = entry.tooth_index;
  const toothTitle = entry.tooth_state.title_russian;
  const toothLabel = entry.tooth_state_label;

  if (toothIndex || toothTitle) {
    return `${toothIndex} ${toothTitle}`;
  }
  return `${toothLabel}`;
};
