import React, { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import OutlinedButton from '../../../uikit/Button/OutlinedButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Column, Table } from '../../../components/Table';
import {
  OperationItem,
  useGetInvoiceOperationsQuery,
  useGetInvoiceRowOperationsQuery,
} from '../../../services/billing';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useGetPayersQuery } from '../../../services/dictionaries';
import request from '../../../utils/request';
import { printPdf } from '../../../utils/print';

const Container = styled.div`
  min-height: 168px;
  width: 950px;
  background: #ffffff;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
`;
const TableBox = styled.div`
  margin: 20px 0;
`;

type paymentsProps = {
  handleCloseForm: () => void;
  scrollTop?: () => void;
  invoiceId?: number;
  rowId?: number;
};
export const PaymentDetails: FC<paymentsProps> = ({ handleCloseForm, scrollTop, invoiceId, rowId }) => {
  const staff = useSelector((state: RootState) => state.staff.responce);
  const { data: payers = [] } = useGetPayersQuery();
  const { data: invoicePaymentDetails = [] } = useGetInvoiceOperationsQuery(invoiceId, {
    skip: !invoiceId,
    refetchOnMountOrArgChange: true,
  });
  const { data: invoiceRowDetails = [] } = useGetInvoiceRowOperationsQuery(rowId, {
    skip: !rowId,
    refetchOnMountOrArgChange: true,
  });

  const columns: Column[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Дата',
        accessor: 'operationDate',
        disableFilters: true,
        Cell: ({ value }) => {
          return format(new Date(value), 'dd MMM yyyy', { locale: ruLocale });
        },
        width: 96,
      },
      {
        Header: 'Время',
        accessor: 'time',
        width: 64,
        disableFilters: true,
        Cell: ({ row }) => {
          return format(new Date(row.values.operationDate), 'HH:MM');
        },
      },
      {
        Header: 'Кассир',
        accessor: 'paymaster',
        width: 180,
        disableFilters: true,
        Cell: ({ value }) => {
          const employee = staff.find((staffer) => staffer.id === value);
          return `${employee?.last_name} ${employee?.first_name[0]}. ${employee?.second_name[0]}.`;
        },
      },
      {
        Header: 'Плательщик',
        accessor: 'payer',
        width: 180,
        disableFilters: true,
        Cell: ({ value }) => {
          const payer = payers.find((p) => p.id === value);
          return payers.length ? payer?.title : 'Загрузка...';
        },
      },
      {
        Header: 'К оплате',
        accessor: 'total',
        width: 88,
        disableFilters: true,
        Cell: ({ row }) => {
          if (row.original.operationType === 'RET') {
            return null;
          }
          return row.values.total;
        },
      },
      {
        Header: 'Оплачено',
        accessor: 'payment',
        width: 88,
        disableFilters: true,
        Cell: ({ row }) => {
          if (row.original.operationType === 'RET') {
            return null;
          }
          return row.values.payment;
        },
      },
      {
        Header: 'Возврат',
        accessor: 'refund',
        width: 88,
        disableFilters: true,
        Cell: ({ row }) => {
          const baseRefundValue = 0;
          if (row.original.operationType === 'RET') {
            return <span>{-row.values.payment}</span>;
          }
          return baseRefundValue;
        },
      },
      {
        Header: 'Остаток',
        accessor: 'remainder',
        width: 118,
        disableFilters: true,
        Cell: ({ row }) => {
          if (row.original.operationType === 'RET') {
            return null;
          }
          return row.values.payment - row.values.total;
        },
      },
    ],
    [payers, staff],
  );

  useEffect(() => {
    scrollTop();
  }, [scrollTop]);

  const tableData = invoiceId
    ? invoicePaymentDetails
    : [...invoiceRowDetails].sort((a, b) => new Date(a.operationDate).getTime() - new Date(b.operationDate).getTime());

  const handlePrint = async () => {
    handleCloseForm();
    const result = rowId
      ? await request.clinic.print_operations_for_row(rowId)
      : await request.clinic.print_operations(invoiceId);
    printPdf(result.data);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseForm}>
      <Container>
        <span style={{ fontWeight: 500, fontSize: '16px' }}>Детали оплаты</span>
        <div>
          <TableBox>
            <Table<OperationItem>
              columns={columns}
              data={tableData}
              hiddenColumns={['id']}
              // isLoading={isLoading}
              visibleRowCount={6}
              canAutoHeight
              getHeaderProps={() => ({
                style: {
                  background: '#F9F9F9',
                },
              })}
            />
          </TableBox>
          {invoicePaymentDetails.length || invoiceRowDetails.length ? (
            <OutlinedButton width={98} height={32} style={{ marginTop: 0 }} noBorder onClick={handlePrint}>
              Распечатать
            </OutlinedButton>
          ) : null}
        </div>
      </Container>
    </ClickAwayListener>
  );
};
