import {
  SAVE_EVENT,
  SAVE_PATIENT,
  SAVE_APPOINTMENT,
  FORGET_APPOINTMENT,
  SAVE_PAIER,
} from "./types";

export default function (
  state = {
    isRedirectedToPayments: false,
    appointment: {},
    patient: {},
    event: {},
    paier: "empty",
  },
  action
) {
  switch (action.type) {
    case SAVE_PAIER:
      return {
        ...state,
        ...{ paier: action.payload },
      };
    case SAVE_PATIENT:
      return {
        ...state,
        ...{ patient: action.payload },
      };
    case SAVE_EVENT:
      return {
        ...state,
        event: action.payload,
      };
    case SAVE_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
        isRedirectedToPayments: true,
      };
    case FORGET_APPOINTMENT:
      return {
        ...state,
        appointment: {},
        isRedirectedToPayments: false,
      };
    default:
      return state;
  }
}
