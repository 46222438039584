import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import HeaderWithSearch from '../../../../../PatientPage/components/HeaderWithSearch';
import StreamHeaderWrapper from '../../../../../PatientPage/components/StreamHeaderWrapper';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
  }),
);

interface IHeaderprops {
  isFullView: boolean;
  setPatient: (patient) => void;
  setData: (data) => void;
  setPatientSelected: (isSelect: boolean) => void;
  setCodeDisabled: (isDisable: boolean) => void;
}

export default function Header({ isFullView, setPatient, setData, setPatientSelected, setCodeDisabled }: IHeaderprops) {
  const classes = useStyles();
  return (
    <div
      className={classes.header}
      onClick={() => {
        setPatient({});
        setPatientSelected(false);
        setData([]);
        setCodeDisabled(false);
      }}
    >
      <HeaderWithSearch
        type="text"
        placeholder="Номер карты"
        name="id"
        width={81}
        text="№ карты"
        multiline
        paddingLeft={24}
      />
      <HeaderWithSearch type="text" placeholder="Фамилия" name="last_name" width={160} text="Фамилия" />
      <HeaderWithSearch type="text" placeholder="Имя" name="first_name" width={160} text="Имя" />
      <HeaderWithSearch type="text" placeholder="Отчество" name="second_name" width={160} text="Отчество" />
      {isFullView && (
        <HeaderWithSearch
          multiline
          type="birth_date"
          placeholder="дд.мм.гггг"
          name="birth_date"
          width={103}
          text="Дата рождения"
        />
      )}
      <HeaderWithSearch type="phone" placeholder="+7(ххх)ххх хх хх" name="phone" width={147} text="Телефон" />
      {isFullView && (
        <HeaderWithSearch type="email" placeholder="email@gmail.com" name="email" width={149} text="E-mail" />
      )}
      {isFullView && (
        <StreamHeaderWrapper
          type="text"
          placeholder="Канал привлечения"
          name="stream"
          width={164}
          text="Канал привлечения"
        />
      )}
      <HeaderWithSearch
        type="text"
        placeholder="Важная информ."
        name="commentary"
        width={184}
        text="Важная информация"
        paddingRight={24}
      />
    </div>
  );
}
