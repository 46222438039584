import { sortBy } from 'ramda';
import request from '../../utils/request';
import StaffPage from './state';
import { toggleChat } from '../../features/messages/actions';
import { fetchMessages, openDialog } from '../../features/messages/operations';

const { loadStaff, pushNewStaffer, setNewStaffer, setCreatingStaffer, loadResponse } = StaffPage.actions;

export function openChat(staffer) {
  return async (dispatch, getState) => {
    const {
      common: { user },
    } = getState();
    dispatch(
      openDialog({
        name: `${staffer.second_name} ${staffer.first_name}`,
        reciever: {
          id: staffer.id,
          name: `${staffer.second_name} ${staffer.first_name}`,
        },
        sender: {
          id: user.id,
          name: `${user.second_name} ${user.first_name}`,
        },
      }),
    );
    dispatch(fetchMessages(user.id, staffer.id));
    dispatch(toggleChat());
  };
}

export function initStaffPage() {
  return async (dispatch, getState) => {
    const { common } = getState();
    const response = await request.clinic.get_staff(common.user.clinic);
    let data = response.data.items;
    dispatch(loadResponse([...data]));
    if (common.user.is_superuser) {
      dispatch(
        loadStaff(
          sortBy(
            (item) => !item.is_active,
            data.sort((a, b) => b.id - a.id),
          ),
        ),
      );
    } else {
      dispatch(
        loadStaff(
          sortBy(
            (item) => !item.is_active,
            data.filter((staffer) => staffer.is_active).sort((a, b) => b.id - a.id),
          ),
        ),
      );
    }
  };
}
export function searchForStaffer() {
  return async (dispatch, getState) => {
    const { staffPage, common } = getState();
    const response = await request.clinic.search_staffer({
      ...staffPage.searchQuery,
      clinic: common.user.clinic.id,
    });
    const staffers = sortBy(
      (item) => !item.is_active,
      response.data.items.sort((a, b) => b.id - a.id),
    );
    if (
      // eslint-disable-next-line no-prototype-builtins
      staffPage.searchQuery.hasOwnProperty('phone') ||
      // eslint-disable-next-line no-prototype-builtins
      staffPage.searchQuery.hasOwnProperty('email')
    ) {
      staffers.length > 0 && dispatch(loadStaff(staffers));
    } else {
      dispatch(loadStaff(staffers));
    }
  };
}
export function updateStafferInfo(stafferID, data) {
  return async (dispatch) => {
    await request.users.update_user_details(data, {
      id: stafferID,
    });

    await dispatch(initStaffPage());
  };
}

export function toggleStafferActivityStatus(stafferId, isActive) {
  return async (dispatch, getState) => {
    await request.users.update_user_details(
      { is_active: isActive },
      {
        id: stafferId,
      },
    );
    await dispatch(initStaffPage());
  };
}

export function createNewStaffer() {
  return async (dispatch, getState) => {
    const { staffPage, common } = getState();
    const newStaffer = {
      ...staffPage.staffer,
      clinic: common.user.clinic.id,
      role: 'DOC',
      username: parseInt(Math.random() * 100000),
      password: parseInt(Math.random() * 100000),
    };
    const isUniq = !!!staffPage.response
      .map((staffer) => `${staffer.first_name}${staffer.second_name}${staffer.phone}${staffer.job}`)
      .filter(
        (identif) =>
          identif === `${newStaffer.first_name}${newStaffer.second_name}${newStaffer.phone}${newStaffer.job}`,
      ).length;
    if (isUniq) {
      const response = await request.clinic.add_staff(newStaffer);
      const staffer = { ...response.data, isNew: true };
      await dispatch(setCreatingStaffer(false));
      await dispatch(pushNewStaffer(staffer));
      await dispatch(setNewStaffer(staffer.id));
    } else {
      console.log('not uniq');
    }
  };
}
