import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TextTemplate } from './TextTemplates';
import { Actions, TextTemplateItemBorder } from './TextTemplateItemBorder';
import TextInput from '../../../../uikit/TextInput';
import { PopupMenu } from '../PopupMenu';
import { TextEllipsis } from '../TextEllipsis';
import { debounce } from 'lodash';
import { JournalItemFormContext } from '../../Jornal/JornalItem';

export const Row = styled.div<{ isTemplateAdded?: boolean }>`
  display: flex;
  position: relative;
  min-height: 54px;
  font-size: 14px;
  font-weight: 400;
  color: #515d6b;
  background: ${({ isTemplateAdded = false }) => (isTemplateAdded ? '#F6F6FA' : '#FFFFFF')};
  align-items: center;
  justify-content: space-between;
  line-height: 120%;
  cursor: pointer;
  padding: 10px 16px;
`;

const PickRegion = styled.div<{ textHeight: number }>`
  position: absolute;
  height: 4px;
  width: 100%;
  background: transparent;
  top: ${({ textHeight }) => textHeight - 4}px;
  left: 0;
`;

type Props = {
  index: number;
  isEditMode: boolean;
  journalFieldName: string;
  template: TextTemplate;
  currentIndex: number;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
  onAddTemplate: (template) => void;
  onEditTemplate: (template) => void;
  onDeleteTemplate: (template) => void;
};

export function TextTemplateItem({
  index,
  isEditMode,
  template,
  currentIndex,
  setCurrentIndex,
  onAddTemplate,
  onEditTemplate,
  onDeleteTemplate,
}: Props) {
  const [isTemplateSelect, setIsTemplateSelect] = useState<boolean>(false);
  const [isTemplateEdit, setIsTemplateEdit] = useState<boolean>(false);
  const [itemValue, setItemValue] = useState<string>(template.text);

  const { setTextValue } = useContext(JournalItemFormContext);

  const itemRef = useRef(null);
  const [textHeight, setTextHeight] = useState(50);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (itemRef && itemRef.current) {
      setTextHeight(itemRef.current.clientHeight);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnEditTemplate = useCallback(
    debounce((value) => onEditTemplate({ ...template, text: value, completed: false }), 500),
    [template],
  );

  const handleTemplateChange = (e) => {
    setItemValue(e.target.value);
    debouncedOnEditTemplate(e.target.value);
  };

  return (
    <>
      {!isTemplateEdit && template.completed ? (
        <Row
          isTemplateAdded={!isEditMode && isTemplateSelect}
          onClick={() => {
            if (!isEditMode) {
              setTextValue(template.text);
              setIsTemplateSelect(true);
            }
          }}
          ref={itemRef}
        >
          <div style={{ maxWidth: 540 }}>
            <TextEllipsis text={template.text} max={372} setTextHeight={setTextHeight} />
          </div>
          {isEditMode && (
            <div style={{ marginLeft: 16 }}>
              <PopupMenu
                actions={[
                  {
                    name: 'EDIT',
                    text: 'Редактировать',
                    handler: () => {
                      setIsTemplateEdit(true);
                      onEditTemplate({ ...template, text: itemValue, dirty_text: itemValue, completed: false });
                    },
                  },
                  {
                    name: 'DELETE',
                    text: 'Удалить из шаблона',
                    handler: onDeleteTemplate,
                  },
                ]}
              />
            </div>
          )}
          <PickRegion onMouseEnter={() => isEditMode && setCurrentIndex(index)} textHeight={textHeight} />
        </Row>
      ) : (
        <Row style={{ paddingRight: 0 }}>
          <TextInput
            style={{ height: 32, marginLeft: -8 }}
            placeholder="Введите текст"
            value={itemValue}
            maxLength={500}
            onChange={handleTemplateChange}
          />
          <Actions>
            <img
              onClick={() => {
                if (itemValue) {
                  onEditTemplate({
                    ...template,
                    text: itemValue,
                    dirty_text: itemValue,
                    is_new: false,
                    completed: true,
                  });
                } else {
                  onDeleteTemplate();
                }
                setIsTemplateEdit(false);
              }}
              style={{ marginRight: 12 }}
              src={'./img/green/check.svg'}
              alt="save"
            />
            <img
              onClick={() => {
                if (!template.is_new && template.dirty_text) {
                  onEditTemplate({ ...template, text: template.dirty_text, completed: true });
                  setItemValue(template.dirty_text);
                } else {
                  onDeleteTemplate();
                }
                setIsTemplateEdit(false);
              }}
              src={'./img/black/close.svg'}
              alt="cancel"
            />
          </Actions>
        </Row>
      )}
      <TextTemplateItemBorder
        index={index}
        isEditMode={isEditMode}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        onAddTemplate={onAddTemplate}
      />
    </>
  );
}
