import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  debtsAndAdvancesReport: null,
  debtsAndAdvancesFilter: {},
  debtsAndAdvancesTableDisplayMode: '',
  revenueReport: null,
  revenueFilter: {},
  historyFilter: {},
  reportDisplayMode: '',
};

export const ReportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setDebtsAndAdvancesReport: (state, action) => {
      return { ...state, debtsAndAdvancesReport: action.payload };
    },
    setDebtsAndAdvancesFilter: (state, action) => {
      return { ...state, debtsAndAdvancesFilter: { ...state.debtsAndAdvancesFilter, ...action.payload } };
    },
    resetDebtsAndAdvancesFilter: (state) => {
      return { ...state, debtsAndAdvancesFilter: {} };
    },
    setDebtsAndAdvancesTableDisplayMode: (state, action) => {
      return { ...state, debtsAndAdvancesTableDisplayMode: action.payload };
    },
    setRevenueReport: (state, action) => {
      return { ...state, revenueReport: action.payload };
    },
    setRevenueFilter: (state, action) => {
      return { ...state, revenueFilter: { ...state.revenueFilter, ...action.payload } };
    },
    resetRevenueFilter: (state) => {
      return { ...state, revenueFilter: {} };
    },
    setHistoryFilter: (state, action) => {
      return { ...state, historyFilter: { ...state.historyFilter, ...action.payload } };
    },
    resetHistoryFilter: (state) => {
      return { ...state, historyFilter: {} };
    },
    setReportDisplayMode: (state, action) => {
      return { ...state, reportDisplayMode: action.payload };
    },
  },
});

export const {
  setDebtsAndAdvancesReport,
  setDebtsAndAdvancesFilter,
  resetDebtsAndAdvancesFilter,
  setDebtsAndAdvancesTableDisplayMode,
  setRevenueReport,
  setRevenueFilter,
  resetRevenueFilter,
  setHistoryFilter,
  resetHistoryFilter,
  setReportDisplayMode,
} = ReportSlice.actions;
