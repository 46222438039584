/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import OutlinedButton from '../../../uikit/Button/OutlinedButton';
import BlueButton from '../../../uikit/Button/BlueButton';
import AntSwitch from '../../../uikit/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { RowExtendedType } from '../../../components/Table/types';
import { VisitDataType } from './VisitServices';
import request from '../../../utils/request';
import { printPdf } from '../../../utils/print';
import { useCreateRefundOperationMutation } from '../../../services/billing';
import { useDispatch, useSelector } from 'react-redux';
import { resetRefundData, setTableDataIsUpdating, setUpdatingTableRowId } from '../reducer';
import { toast } from 'react-hot-toast';
import { useGetPayersQuery, useGetPaymentTypesQuery } from '../../../services/dictionaries';

const StyledFormControlLabel = withStyles(() => ({
  root: {
    margin: 0,
  },
}))(FormControlLabel);
const ActionButtons = styled.div`
  display: flex;
  background: #ffffff;
  justify-content: space-between;
  align-items: center;
  padding: 16px 70px 16px 28px;
`;
const RefundContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1300;
`;
type RefundFooterProps = {
  onCloseFooter: () => void;
  rowRefund: RowExtendedType<VisitDataType>;
  disableSubmit: boolean;
};

export const RefundFooter: React.FC<RefundFooterProps> = ({ onCloseFooter, rowRefund = {}, disableSubmit = false }) => {
  const dispatch = useDispatch();
  const [isPrinted, setIsPrinted] = useState<boolean>(true);
  const [createReturnPaymentOperation] = useCreateRefundOperationMutation();

  const { data: paymentTypes = [] } = useGetPaymentTypesQuery();
  const { data: payers = [] } = useGetPayersQuery();

  const rowDataForReturnOperation = useSelector((state) => state.visitsAndPayments.rowDataForReturnOperation);
  const subInvoices = useSelector((state) => state.visitsAndPayments.subInvoices);
  const patient = useSelector((state) => state.medCardPageSlice.patientDetails);

  const subInvoiceToRefund = rowDataForReturnOperation?.subInvoiceId
    ? subInvoices[rowDataForReturnOperation.subInvoiceId]
    : null;

  const calcRows = rowDataForReturnOperation?.subInvoiceId
    ? {
        [rowDataForReturnOperation.subInvoiceId]: Object.values(subInvoiceToRefund)
          .reduce((acc, cur) => [...acc, ...cur], [])
          .map((row) => {
            return {
              invoice_row: row.id,
              sub_invoice_id: rowDataForReturnOperation.subInvoiceId,
              operations: row.paymentOperations.map((po) => {
                return {
                  payment: po.payment,
                  payment_type: paymentTypes.find((type) => type.title === po.payment_type)?.id,
                  payer: payers.find((payer) => payer.title === po.payer)?.id,
                };
              }),
            };
          }),
      }
    : null;

  const subInvoiceRowRefetchCallback = useSelector((state) => {
    return rowDataForReturnOperation
      ? state.visitsAndPayments.subInvoiceRowsRefetchCallbacks[rowDataForReturnOperation.subInvoiceId]
      : null;
  });

  const allRowsRefetchCallback = useSelector((state) =>
    rowDataForReturnOperation ? state.visitsAndPayments.subInvoiceRowsRefetchCallbacks.allRowsCallback : null,
  );

  const switchPrint = () => setIsPrinted(!isPrinted);
  const onClose = () => onCloseFooter();
  const makeRefund = async () => {
    onCloseFooter();

    //Тут происходит запрос на бек с добавлением возвратной операции
    const { subInvoiceId, ...queryPayload } = rowDataForReturnOperation;
    dispatch(setTableDataIsUpdating(true));
    dispatch(setUpdatingTableRowId(queryPayload.id));

    try {
      await createReturnPaymentOperation(queryPayload).unwrap();
      subInvoiceRowRefetchCallback();
      allRowsRefetchCallback();
      if (isPrinted) {
        const result = await request.clinic.print_invoice_refunds(patient.id, calcRows, undefined);
        printPdf(result.data);
      }
    } catch (error) {
      toast.error('Создания возврата не произошло из-за технических неполадок');
      console.error(error);
      dispatch(setTableDataIsUpdating(false));
      dispatch(setUpdatingTableRowId(null));
    } finally {
      dispatch(resetRefundData());
    }
  };

  return (
    <RefundContainer>
      <ActionButtons>
        <StyledFormControlLabel
          control={<AntSwitch checked={isPrinted} onChange={switchPrint} />}
          label="Распечатать счет"
        />
        <div style={{ display: 'flex' }}>
          <OutlinedButton style={{ marginTop: 0, padding: '0px 16px' }} onClick={onClose}>
            Отмена
          </OutlinedButton>
          <BlueButton
            style={{ marginTop: 0, marginLeft: 16, padding: '0px 16px' }}
            onClick={makeRefund}
            disabled={disableSubmit}
          >
            Произвести возврат
          </BlueButton>
        </div>
      </ActionButtons>
    </RefundContainer>
  );
};
