import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { setShowEvenDates, setShowOddDates } from '../../../reducer';
import { useAppDispatch } from '../../../../../../../store/hooks';
import { Controls, CustomCheckbox } from './components';

const CalendarControls = ({ isDisabled }) => {
  const dispatch = useAppDispatch();
  const onChange = (e) => {
    const checkboxesValues = { EVENDATES: 'evenDates', ODDDATES: 'oddDates' };
    switch (e.target.value) {
      case checkboxesValues.EVENDATES:
        dispatch(setShowEvenDates(e.target.checked));
        break;
      case checkboxesValues.ODDDATES:
        dispatch(setShowOddDates(e.target.checked));
        break;
    }
  };

  return (
    <Controls disabled={isDisabled}>
      <FormControlLabel
        control={<CustomCheckbox disabled={isDisabled} />}
        label="четные"
        value="evenDates"
        onChange={onChange}
      />
      <FormControlLabel
        style={{ marginLeft: 15 }}
        control={<CustomCheckbox disabled={isDisabled} />}
        label="нечетные"
        value="oddDates"
        onChange={onChange}
      />
    </Controls>
  );
};

export default CalendarControls;
