export const haveIntersection = (arr1, arr2) => {
  const numSet = new Set(arr1);
  for (const num of arr2) {
    if (numSet.has(num)) {
      return true;
    }
  }
  return false;
};

export const isWorkday = (day) => day.is_workday;
export const getStartTime = (day) => day.time_start;
export const getEndTime = (day) => day.time_end;

export const filterWorkdays = (workdays, uniqueWeekdays) => {
  return workdays.filter((workday) => uniqueWeekdays.includes(workday.id));
};
