export const getEmptyScheduleTable = (noDoctorsEvents, noCabinets, areAllWorkdaysAreNonWorking) => {
  let primaryText;
  let secondaryText;
  let linkText;

  // Если нет кабинетов
  if (noCabinets) {
    primaryText = 'Не задано медицинских кабинетов для приёма';
    secondaryText = 'Чтобы добавить новые кабинеты, перейдите на страницу ';
    linkText = 'настройки профиля.';
    // Если есть фильтр по докторам, но нет рабочих сессий с такими докторами
  } else if (noDoctorsEvents || areAllWorkdaysAreNonWorking) {
    primaryText = 'Не задано рабочее расписание';
    secondaryText = 'Для настройки графика работы врача перейдите на страницу ';
    linkText = 'настройки расписания.';
  } else {
    primaryText = null;
    secondaryText = null;
    linkText = null;
  }
  return { primaryText, secondaryText, linkText };
};
