import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#577BF9'
    },
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        color: '#515D6B',
        fontSize: '14px',
        lineHeight: '22px',
        padding: 0,
      }
    },
  }
});
