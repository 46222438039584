import React from 'react';
import styled from 'styled-components';

type ValueProps = {
  color?: string;
};
type ContainerProps = {
  cursor?: string;
};

const Container = styled.div<ContainerProps>`
  border: 1px solid #e5e6ea;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 8px 14px;
  margin-right: 16px;
  flex: 0 0 144px;
  background: #fff;
  cursor: ${({ cursor }) => (cursor ? cursor : '')};
  &.active {
    border: 1px solid #577bf9;
    border-radius: 4px;
  }
`;

const Title = styled.div`
  font-size: 12px;
  line-height: 120%;
  color: #515d6b;
  margin-bottom: 4px;
`;

const Value = styled.div<ValueProps>`
  font-size: 16px;
  line-height: 120%;
  font-weight: 400;
  color: #253446; //#c3000a;
  color: ${({ color }) => (color ? color : '#253446')};
`;

type Props = {
  title: string;
  value: number;
  color?: string;
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export function BalanceBlock({ title, value, color, handleClick }: Props) {
  return (
    <Container onClick={handleClick && handleClick} cursor={handleClick ? 'pointer' : ''}>
      <Title>{title}</Title>
      <Value color={value !== 0 ? color : '#A8AEB5'}>{value} ₽</Value>
    </Container>
  );
}
