import { combineReducers } from 'redux';
import LoginReducers from '../features/clinic/reducer_login';
import ClinicReducers from '../features/clinic/reducer_clinic';
import UserReducers from '../features/storage/reducer_user';
import CalendarReducers from '../features/calendar/reducer_calendar';
import TableReducers from '../features/calendar/reducer_table';
import WorkdayReducers from '../features/workday/workday_reducer';
import ScheduleReducer from '../features/schedule/schedule_reducer';
import TestReducer from '../features/schedule/reducers';
import CabinetSelectionsReducer from '../features/selections/cabinet/reducers';
import DoctorSelectionsReducer from '../features/selections/doctor/reducers';
import NewCalendarReducer from '../features/calendar/reducers';
import TasksReducers from '../features/tasks/reducers';
import AppointmentsReducer from '../features/appointments/reducers';
import StaffReducer from '../features/staff/reducers';
import PatientsReducers from '../features/patients/reducers';
import ResizeReducers from '../features/resize/reducers';
import WordaysReducers from '../features/workday/reducers';
import DragReducers from '../features/drag/reducers';
import MessagesReducers from '../features/messages/reducers';
import StorageReducer from '../features/storage/reducers';
import ErrorsReducer from '../features/errors/reducers';
import WorkdaysReducer from '../features/clinicSchedule/state';
import EventSlice from '../pages/CalendarPage/Event/state';
import AppointmentsSlice from '../pages/CalendarPage/Appointments/state';
import TasksSlice from '../pages/CalendarPage/Tasks/state';
import ScheduleSlice from '../pages/CalendarPage/Workdays/state';
import CalendarSlice from '../pages/CalendarPage/Calendar/state';
import ErrorsSlice from '../components/Error/state';
import { StaffScheduleSlice } from '../features/staff/components/StaffScheduleSetup/reducer';
import { NewScheduleSlice } from '../pages/CalendarPage/reducer';
import { ReportSlice } from '../pages/ReportPage/reducer';
import StudyModalStateSlice from '../components/StudyModal/state';
import FreezeStateSlice from '../components/FreezeModal/state';
import PaymentAccountSlice from '../pages/MedcardPage/Account/state';
import { visitsAndPaymentsSlice } from '../pages/VisitsAndPaymentsPage/reducer';

import DrawerState from '../components/Drawer/state';
import MedCardPageSlice from '../pages/MedcardPage/state';
import PriceSlice from '../pages/PricePage/state';
import TeethFormulaState from '../pages/MedcardPage/Formula/state';
import StaffPage from '../pages/StaffPage/state';
import PatientsPage from '../pages/PatientPage/state';
import SuggestionsMenu from '../components/Suggestions/state';
import AppointmentFormState from '../pages/CalendarPage/modals/AppointmentForm/state';

import { clinicApi } from '../services/clinics';
import { userApi } from '../services/users';
import { dictionaryApi } from '../services/dictionaries';
import { medcardApi } from '../services/medcard';
import { medcardJournalApi } from '../services/medcardJournal';
import { invoiceApi } from '../services/invoice';
import { priceApi } from '../services/price';
import { childFormulaApi } from '../services/childFormula';
import { staffScheduleApi } from '../services/staffSchedule';
import { appointmentsApi } from '../services/appointments';
import { advanceApi } from '../services/advance';
import { billingApi } from '../services/billing';
import { reportsApi } from '../services/reports';
import { patientsApi } from '../services/patients';

const rootReducer = combineReducers({
  common: LoginReducers,
  clinic: ClinicReducers,
  user: UserReducers,
  calendar: CalendarReducers,
  table: TableReducers,
  workday: WorkdayReducers,
  schedule: ScheduleReducer,
  newSchedule: TestReducer,
  cabinet: CabinetSelectionsReducer,
  doctor: DoctorSelectionsReducer,
  newCalendar: NewCalendarReducer,
  tasks: TasksReducers,
  appointments: AppointmentsReducer,
  staff: StaffReducer,
  patients: PatientsReducers,
  resize: ResizeReducers,
  workdays: WordaysReducers,
  drag: DragReducers,
  messages: MessagesReducers,
  storage: StorageReducer,
  errors: ErrorsReducer,
  appointmentsSlice: AppointmentsSlice.reducer,
  calendarSlice: CalendarSlice.reducer,
  scheduleSlice: ScheduleSlice.reducer,
  workdaysSlice: WorkdaysReducer.reducer,
  eventSlice: EventSlice.reducer,
  errorsSlice: ErrorsSlice.reducer,
  appointmentForm: AppointmentFormState.reducer,
  tasksSlice: TasksSlice.reducer,
  drawerState: DrawerState.reducer,
  medCardPageSlice: MedCardPageSlice.reducer,
  priceSlice: PriceSlice.reducer,
  teethFormulaState: TeethFormulaState.reducer,
  staffPage: StaffPage.reducer,
  patientsPage: PatientsPage.reducer,
  suggestionsMenu: SuggestionsMenu.reducer,
  staffSchedule: StaffScheduleSlice.reducer,
  reworkedSchedule: NewScheduleSlice.reducer,
  studyModalState: StudyModalStateSlice.reducer,
  freezeModalState: FreezeStateSlice.reducer,
  PaymentAccountSlice: PaymentAccountSlice.reducer,
  reports: ReportSlice.reducer,
  visitsAndPayments: visitsAndPaymentsSlice.reducer,

  [clinicApi.reducerPath]: clinicApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [dictionaryApi.reducerPath]: dictionaryApi.reducer,
  [medcardApi.reducerPath]: medcardApi.reducer,
  [medcardJournalApi.reducerPath]: medcardJournalApi.reducer,
  [invoiceApi.reducerPath]: invoiceApi.reducer,
  [priceApi.reducerPath]: priceApi.reducer,
  [childFormulaApi.reducerPath]: childFormulaApi.reducer,
  [staffScheduleApi.reducerPath]: staffScheduleApi.reducer,
  [appointmentsApi.reducerPath]: appointmentsApi.reducer,
  [advanceApi.reducerPath]: advanceApi.reducer,
  [billingApi.reducerPath]: billingApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [patientsApi.reducerPath]: patientsApi.reducer,
});
export default rootReducer;
