import React, { FC, memo, useState } from 'react';
import { TableActionButton, TableActionMenu } from '../../../components/Table/styles';
import { DotsIcon } from '../../../uikit/Icons';
import { MenuItem } from '@material-ui/core';
import { CommentsForm } from '../../../uikit/CommentsForm';
import { Modal } from '../../../uikit/Modal';

type InvoiceOptionsMenuType = {
  invoiceId: number;
  onDeleteInvoice: (invoiceId: number) => void;
  handleScrollTop?: () => void;
  marginNone?: boolean;
  enableDeleteAccount?: boolean;
};

export const InvoiceOptionsMenu: FC<InvoiceOptionsMenuType> = memo(
  ({ invoiceId, handleScrollTop, marginNone, enableDeleteAccount, onDeleteInvoice }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [commentForm, setCommentForm] = useState<null | HTMLElement>(null);

    const handleDeleteInvoice = () => {
      onDeleteInvoice(invoiceId);
    };
    const handleCloseMenu = () => setAnchorEl(null);
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleOpenModal = (setAnchorState: (element: HTMLElement) => void) => {
      if (anchorEl) {
        const parentElement = anchorEl.offsetParent as HTMLElement;
        setAnchorState(parentElement);
        parentElement.style.border = '1px solid #577BF9';
        handleCloseMenu();
      }
    };
    const handleCommentFormClose = () => {
      commentForm.style.border = '';
      setCommentForm(null);
    };

    return (
      <>
        <TableActionButton
          style={{ marginLeft: marginNone ? 'none' : 'auto' }}
          size="small"
          onClick={handleOpenMenu}
          disableRipple
        >
          <DotsIcon />
        </TableActionButton>
        <TableActionMenu
          id="action-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => handleOpenModal(setCommentForm)}>Добавить комментарий</MenuItem>
          {enableDeleteAccount && <MenuItem onClick={handleDeleteInvoice}>Удалить счет</MenuItem>}
        </TableActionMenu>
        <Modal anchor={commentForm}>
          <CommentsForm handleSetOpenForm={handleCommentFormClose} scrollTop={handleScrollTop} invoiceId={invoiceId} />
        </Modal>
      </>
    );
  },
);
InvoiceOptionsMenu.displayName = 'InvoiceOptionsMenu';
