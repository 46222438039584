import {
  CONNECTED_SUCCESS,
  CLOSE_DIALOG,
  MESSAGE_DATA,
  LOAD_MESSAGES,
  TOGGLE_CHAT,
  LOAD_DIALOGS,
  LOAD_SEARCH_RESULTS,
} from "./types";

export function connectedToServer(socket) {
  return {
    type: CONNECTED_SUCCESS,
    payload: socket,
  };
}

export function closeDialog() {
  return {
    type: CLOSE_DIALOG,
    payload: true,
  };
}

export function loadMessages(data) {
  return {
    type: LOAD_MESSAGES,
    payload: data,
  };
}

export function loadDialogs(data) {
  return {
    type: LOAD_DIALOGS,
    payload: data,
  };
}

export function toggleChat() {
  return {
    type: TOGGLE_CHAT,
    payload: true,
  };
}

export function changeMessageData(data) {
  return {
    type: MESSAGE_DATA,
    payload: data,
  };
}
export function loadSearchResults(dialogs, messages) {
  return {
    type: LOAD_SEARCH_RESULTS,
    payload: { dialogs, messages },
  };
}
