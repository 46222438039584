import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import FormulaState from '../Formula/state';
import { JornalItem } from './JornalItem';
import { AlertDialog } from '../Template/AlertDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppSelector } from '../../../store/hooks';
import {
  MedcardDate,
  useDeleteDateMutation,
  useDeleteEntryMutation,
  useGetEntriesQuery,
} from '../../../services/medcard';
import { RootState } from '../../../store';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
import { JornalAccount } from './JornalAccount';
import { useDeleteInvoiceMutation, useGetInvoicesQuery } from '../../../services/invoice';
import { FormulaType } from '../Formula';
import { useGetPlanJournalFieldQuery } from '../../../services/medcardJournal';
import medCardPageSlice from '../../../pages/MedcardPage/state';

const { setExpanded, deleteCurrentDate, setEmptyToothStateError } = FormulaState.actions;
const { setAccountVisible } = medCardPageSlice.actions;

type Props = {
  currentDate: MedcardDate;
  formulaType: FormulaType;
  teethFormulaState: RootState['teethFormulaState'];
  showPayment: boolean;
  accountVisible: boolean;
  common: RootState['common'];
  setAccountVisible: (visible: boolean) => void;
  setExpanded: ActionCreatorWithNonInferrablePayload;
  setEmptyToothStateError: ActionCreatorWithNonInferrablePayload;
  onStartEdit?: (invoiceId: number) => void;
  onStopEdit?: (invoiceId: number) => void;
  onEditJournal?: (data: unknown) => void;
  session: {
    session_user_id: number;
    session_user_name: string;
  };
};

function Jornal({
  formulaType,
  currentDate,
  teethFormulaState,
  showPayment,
  accountVisible,
  setAccountVisible,
  setExpanded,
  setEmptyToothStateError,
  common,
  onStartEdit,
  onStopEdit,
  onEditJournal,
  session,
}: Props) {
  const { expandedId, currentDateId, emptyToothStateError } = teethFormulaState;

  const patient = useAppSelector((state) => state.medCardPageSlice.patientDetails);
  const { data: entries = [] } = useGetEntriesQuery(
    { patientId: patient.id, dateId: currentDateId },
    { skip: !patient.id },
  );
  const { data: invoices } = useGetInvoicesQuery(
    {
      patientId: patient.id,
      dateId: currentDate?.id,
    },
    { skip: !patient.id || !currentDate?.id },
  );

  const [deleteEntry] = useDeleteEntryMutation();
  const [deleteDate] = useDeleteDateMutation();
  const [deleteInvoice] = useDeleteInvoiceMutation();

  const [alertVisible, setAlertVisible] = useState<boolean>(false);

  const { data: treatmentPlan = [] } = useGetPlanJournalFieldQuery(
    { patientId: patient.id, entryId: entries[0]?.id },
    { skip: !entries[0]?.id },
  );

  const methods = useForm();

  const openJornalAccount = useCallback(() => {
    setAccountVisible(true);
    onStartEdit(invoices?.[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices?.[0]?.id]);

  const handleStopEdit = useCallback(() => {
    onStopEdit(invoices?.[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices?.[0]?.id]);

  return (
    <FormProvider {...methods}>
      {entries.map((entry) => (
        <JornalItem
          key={entry.id}
          treatmentPlan={treatmentPlan}
          formulaType={formulaType}
          entry={entry}
          patientId={patient.id}
          expandedId={expandedId}
          emptyToothStateError={emptyToothStateError}
          setExpanded={setExpanded}
          setAlertVisible={setAlertVisible}
          setEmptyToothStateError={setEmptyToothStateError}
          openJornalAccount={openJornalAccount}
          user={common.user}
          currentDate={currentDate}
          onEditJournal={onEditJournal}
          session={session}
        />
      ))}
      <AlertDialog
        open={alertVisible}
        onOK={() => {
          setAlertVisible(false);
          const entryId = entries.find((e) => e.id === expandedId).id;
          const isLastEntry = entries.length === 1;
          deleteEntry({ patientId: patient.id, entryId })
            .unwrap()
            .then(() => {
              setEmptyToothStateError(false);
              if (isLastEntry) {
                if (invoices?.[0]?.id) {
                  deleteInvoice(invoices[0].id);
                }
                deleteDate({ patientId: patient.id, dateId: currentDateId });
                deleteCurrentDate(currentDateId);
              }
            });
        }}
        onCancel={() => setAlertVisible(false)}
      />
      <JornalAccount
        isPayment={showPayment}
        expandedId={expandedId}
        setExpanded={setExpanded}
        accountVisible={accountVisible || showPayment}
        setAccountVisible={setAccountVisible}
        entries={entries}
        currentDate={currentDate}
        onStopEdit={handleStopEdit}
      />
    </FormProvider>
  );
}

export default connect(
  (state: RootState) => ({
    teethFormulaState: state.teethFormulaState,
    showPayment: state.medCardPageSlice.showPayment,
    accountVisible: state.medCardPageSlice.accountVisible,
    common: state.common,
  }),
  { setExpanded, deleteCurrentDate, setAccountVisible, setEmptyToothStateError },
)(Jornal);
