import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { AutocompleteItem } from './AutocompleteItem';
import { SelectItem } from './Autocomplete';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { PaymentTypesCodes } from '../../enums';

type ListBoxProps = {
  groupedOptions: SelectItem[];
  getOptionProps: ({ option, index }: { option: SelectItem; index: number }) => Record<string, unknown>;
  editable?: boolean;
  listBoxWidth?: number;
  maxLength?: number;
  onEditItem?: (value) => void;
  onDeleteItem?: (id) => void;
};
const useStyles = makeStyles(() =>
  createStyles({
    thumb: {
      borderRadius: 'inherit',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    track: {
      width: '4px !important',
      top: '2px',
      right: '4px',
      bottom: '2px',
      borderRadius: '3px',
    },
    listbox: {
      boxShadow: '0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16)',
      cursor: 'pointer',
      borderRadius: 4,
      width: (props: { listBoxWidth: number }) => (props.listBoxWidth ? props.listBoxWidth : 188),
      padding: '8px 0',
      background: 'white',
      listStyle: 'none',
      overflow: 'auto',
      margin: '4px 0 0 -8px !important',
      fontWeight: 400,
      '& li': {
        color: '#515D6B',
        fontSize: 12,
        padding: '5px 12px',
        height: '32px',
      },
      '& li:hover': {
        background: '#F6F6FA',
      },
    },
  }),
);

export const ListBox = ({
  groupedOptions,
  getOptionProps,
  editable,
  listBoxWidth,
  maxLength,
  onEditItem,
  onDeleteItem,
}: ListBoxProps) => {
  const advanceTotal = useSelector((state: RootState) => state.PaymentAccountSlice.advanceAmount);

  const classes = useStyles({ listBoxWidth });

  return (
    <>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} id={'listBox'}>
          <Scrollbars
            autoHide
            autoHeight
            autoHeightMax={'300px'}
            renderTrackVertical={(props) => <div {...props} className={classes.track} />}
            renderThumbVertical={(props) => <div {...props} className={classes.thumb} />}
            style={{
              width: '100%',
            }}
          >
            {groupedOptions.map((option, index) => (
              <AutocompleteItem
                key={option.id}
                liProps={getOptionProps({ option, index })}
                title={option.title}
                maxLength={maxLength}
                editable={editable && !option.code}
                onEditItem={(title) => onEditItem?.({ id: option.id, title })}
                onDeleteItem={() => onDeleteItem?.(option.id)}
                disabled={
                  (!advanceTotal && option.code === PaymentTypesCodes.PREPAID) ||
                  option.code === PaymentTypesCodes.COMBINED
                }
              />
            ))}
          </Scrollbars>
        </ul>
      ) : null}
    </>
  );
};
