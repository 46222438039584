import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ClickAwayListener } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';

const Container = styled.div`
  align-items: center;
  color: #515d6b;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

const Select = styled.div<{ open: boolean }>`
  background: #ffffff;
  border: 1px solid #d3d6da;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 3px;
  text-align: center;
  width: 32px;
  height: 32px;

  &:hover {
    border-color: #577bf9;
    cursor: ${({ open }) => (open ? 'default' : 'pointer')};
  }
`;

const StyledHourSelect = styled(Select)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${({ open }) => (open ? '88px' : '32px')};
  justify-content: ${({ open }) => (open ? 'space-between' : 'center')};
  margin-right: 4px;
`;

const HourControl = styled.span<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? '#D3D6DA' : '#515D6B')};
  cursor: pointer;
  display: inline-flex;
  text-align: center;

  &:hover {
    color: ${({ disabled }) => (disabled ? '#D3D6DA' : '#253446')};
  }
`;

type HourSelectProps = {
  open: boolean;
  onHourChange: (hour: number) => void;
  hours: number;
  onClick: () => void;
  onClickOutside: THandleHourOutsideClick;
  minHour: number;
  maxHour: number;
  changeValuesImmediatly?: boolean;
};
const HourSelect = (props: HourSelectProps) => {
  const { onClick, open, onHourChange, hours, onClickOutside, minHour, maxHour, changeValuesImmediatly } = props;

  const onArrowClick = (mode: 'plus' | 'minus') => {
    switch (mode) {
      case 'plus':
        hours < maxHour && onHourChange(hours + 1);
        changeValuesImmediatly && onClickOutside();
        break;
      case 'minus':
        hours > minHour && onHourChange(hours - 1);
        changeValuesImmediatly && onClickOutside();
        break;
      default:
        console.error('В функцию onArrowClick можно передать только plus или minus');
        break;
    }
  };
  return (
    <ClickAwayListener
      onClickAway={() => {
        open && onClickOutside(true);
      }}
    >
      <StyledHourSelect open={open} onClick={() => !open && onClick()}>
        {open && (
          <HourControl disabled={hours === maxHour} onClick={() => onArrowClick('plus')}>
            <KeyboardArrowUp />
          </HourControl>
        )}
        {String(hours).padStart(2, '0')}
        {open && (
          <HourControl disabled={hours === minHour} onClick={() => onArrowClick('minus')}>
            <KeyboardArrowDown />
          </HourControl>
        )}
      </StyledHourSelect>
    </ClickAwayListener>
  );
};

const StyledMinuteSelect = styled(Select)<{ minutes: number }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: ${({ open }) => (open ? 'space-between' : 'center')};
  height: ${({ open }) => (open ? '60px' : '32px')};
  margin-top: ${({ open, minutes }) => (open && minutes === 0 ? '28px' : '0')};
  margin-bottom: ${({ open, minutes }) => (open && minutes === 30 ? '28px' : '0')};
`;
const MinuteControl = styled.span`
  display: inline-flex;
  &:hover {
    color: #577bf9;
    cursor: pointer;
  }
`;
type MinuteSelectProps = {
  open: boolean;
  onClick: () => void;
  onMinutesChange: (minutes: number) => void;
  minutes: number;
  onClickOutside: () => void;
};
const MinuteSelect = (props: MinuteSelectProps) => {
  const { onClick, open, minutes, onClickOutside, onMinutesChange } = props;
  return (
    <ClickAwayListener onClickAway={onClickOutside}>
      <StyledMinuteSelect open={open} onClick={onClick} minutes={minutes}>
        {open && minutes === 30 && <MinuteControl onClick={() => onMinutesChange(0)}>00</MinuteControl>}
        <MinuteControl>{String(minutes).padStart(2, '0')}</MinuteControl>
        {open && minutes === 0 && <MinuteControl onClick={() => onMinutesChange(30)}>30</MinuteControl>}
      </StyledMinuteSelect>
    </ClickAwayListener>
  );
};

type TimeSelectProps = {
  hours: number;
  minutes: number;
  setHours: (hours: number) => void;
  setMinutes: (minutes: number) => void;
  minHour: number;
  maxHour: number;
  isError: boolean;
  changeValuesImmediatly?: boolean;
};
type THandleHourOutsideClick = (forceClose?: boolean) => void;

export const TimeSelect: React.FC<TimeSelectProps> = (props) => {
  const { hours, minutes, setHours, setMinutes, minHour, maxHour, isError, changeValuesImmediatly } = props;
  const [openHour, setOpenHour] = useState(false);
  const [openMinute, setOpenMinute] = useState(false);

  const [timeHours, setTimeHours] = useState(hours);

  useEffect(() => {
    if (isError && timeHours !== hours) {
      setTimeHours(hours);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours, isError]);

  const handleHourClick = () => {
    setOpenHour((open) => !open);
    setOpenMinute(false);
  };

  const handleMinuteClick = () => {
    setOpenMinute((open) => !open);
    setOpenHour(false);
  };

  const onHourChange = (hour) => {
    setTimeHours(hour);
    changeValuesImmediatly && setHours(hour);
  };

  const handleHourOutsideClick: THandleHourOutsideClick = (forceClose = false) => {
    (!changeValuesImmediatly || forceClose) && setOpenHour(false);
    if (timeHours !== hours) {
      setHours(timeHours);
    }
  };

  const handleMinuteOutsideClick = () => {
    setOpenMinute(false);
  };

  return (
    <Container>
      <HourSelect
        open={openHour}
        onClick={handleHourClick}
        onHourChange={onHourChange}
        hours={timeHours}
        onClickOutside={handleHourOutsideClick}
        minHour={minHour}
        maxHour={maxHour}
        changeValuesImmediatly={changeValuesImmediatly}
      />
      <MinuteSelect
        open={openMinute}
        onClick={handleMinuteClick}
        onMinutesChange={setMinutes}
        minutes={minutes}
        onClickOutside={handleMinuteOutsideClick}
      />
    </Container>
  );
};
