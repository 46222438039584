import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { tail } from 'lodash';
import { baseURL, HttpMethod } from '../utils/request';
import { EAccountStatus } from '../pages/MedcardPage/Account/AccountStatus';
import { MedcardPlanJournalField } from './medcardJournal';
import { MedcardEntry } from './medcard';

export enum InvoiceRowType {
  TREATMENT_PLAN = 'TREATMENT_PLAN',
  PRICE = 'PRICE',
}

type InvoiceRequest = {
  patientId: number;
  dateId: number;
};

type InvoiceRowsByStatusRequest = {
  invoiceId: number;
  status: EAccountStatus;
};

export type InvoiceComment = {
  id: number;
  text: string;
  created_at: string;
  parent?: number;
  user: number;
};

export type Invoice = {
  id: number;
  date_id: number;
  created_at: string;
  comments: InvoiceComment[];
};

export type SubInvoice = {
  id: number;
  invoice: number;
  formula_id: number;
  created_at: string;
  status: EAccountStatus;
};

type OperationInitial = {
  operation_date: string;
  id: number;
  executor: string;
  favor_code: string;
  favor_title: string;
  price: number;
  count: number;
  discount: number;
  discount_type: 'RUB' | 'PERCENT';
  payment_type: string;
  payer: string;
  total: number;
  payment: number;
  returned: number | null;
  return_status: 'DEBT' | 'NODEBT';
};

export type Operation = {
  id: number | string;
  rowId: number;
  amountForPay: number | null;
  payment: number | null;
  payment_type: number | string | null;
  payer: number | string | null;
  isNew: boolean;
};

type InvoiceRowInitial = {
  id: number;
  created_at: string;
  price_code: string;
  price_title: string;
  price_value: number;
  count: number;
  discount: number;
  discount_type: 'RUB' | 'PERCENT';
  payment: number;
  paid: number;
  service_date: string;
  row_type: InvoiceRowType;
  sub_invoice: number;
  entry: number;
  external_entry: number;
  payer: number;
  payment_type: number;
  remainder: number;
  payment_operations: OperationInitial[];
  comments: InvoiceComment[];
};

export type InvoiceRow = {
  id: number;
  code: string;
  text: string;
  price: number;
  count: number;
  discount: number;
  discountType: 'RUB' | 'PERCENT';
  payment: number;
  paid: number;
  serviceDate: string;
  type: InvoiceRowType;
  subInvoiceId: number;
  entry: MedcardEntry;
  externalEntry: MedcardEntry;
  payer?: number;
  paymentType?: number;
  remainder?: number;
  paymentOperations: Operation[];
  subRows: Operation[];
  comments: InvoiceComment[];
};

export const invoiceApi = createApi({
  reducerPath: 'invoiceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['Invoices', 'SubInvoices', 'InvoiceRows', 'InvoiceCommonRows', 'InvoiceComments', 'InvoiceRowComments'],
  endpoints: (builder) => ({
    getInvoices: builder.query<Invoice[], InvoiceRequest>({
      query: ({ patientId, dateId }) => `patient/invoices?date_id=${dateId}&patient=${patientId}`,
      providesTags: [{ type: 'Invoices', id: 'LIST' }],
    }),
    addInvoice: builder.mutation<Invoice, Partial<Invoice>>({
      query: (data) => {
        return {
          url: 'patient/invoices',
          method: HttpMethod.POST,
          body: data,
        };
      },
      invalidatesTags: [{ type: 'Invoices', id: 'LIST' }],
    }),
    deleteInvoice: builder.mutation<void, number>({
      query: (invoiceId) => {
        return {
          url: `patient/invoices/${invoiceId}`,
          method: HttpMethod.DELETE,
        };
      },
    }),
    sendInvoice: builder.mutation<void, number>({
      query: (invoiceId) => {
        return {
          url: `/patient/send_invoice/${invoiceId}`,
          method: HttpMethod.POST,
          body: {},
        };
      },
      invalidatesTags: [
        { type: 'SubInvoices', id: 'LIST' },
        { type: 'Invoices', id: 'LIST' },
      ],
    }),
    getAllSubInvoices: builder.query<SubInvoice[], void>({
      query: () => `/patient/sub_invoices`,
      providesTags: [{ type: 'SubInvoices' }],
    }),
    getSubInvoices: builder.query<SubInvoice[], number>({
      query: (invoiceId) => `/patient/sub_invoices?invoice=${invoiceId}`,
      providesTags: [{ type: 'SubInvoices', id: 'LIST' }],
    }),
    addSubInvoice: builder.mutation<SubInvoice, Partial<SubInvoice>>({
      query: (data) => {
        return {
          url: 'patient/sub_invoices',
          method: HttpMethod.POST,
          body: data,
        };
      },
      invalidatesTags: [{ type: 'SubInvoices', id: 'LIST' }],
    }),
    updateSubInvoice: builder.mutation<SubInvoice, Partial<SubInvoice>>({
      query: (data) => {
        return {
          url: `patient/sub_invoices/${data.id}`,
          method: HttpMethod.PATCH,
          body: data,
        };
      },
      invalidatesTags: [{ type: 'SubInvoices', id: 'LIST' }],
    }),
    deleteSubInvoice: builder.mutation<void, number>({
      query: (subInvoiceId) => {
        return {
          url: `patient/sub_invoices/${subInvoiceId}`,
          method: HttpMethod.DELETE,
        };
      },
    }),

    getInvoiceRows: builder.query<InvoiceRow[], number>({
      query: (subInvoiceId) => `patient/sub_invoices/${subInvoiceId}/rows`,
      providesTags: [{ type: 'InvoiceRows', id: 'LIST' }],
      transformResponse: (response: InvoiceRowInitial[]) => {
        return response.map((p) => {
          const operations = p.payment_operations.map((operation: OperationInitial) => {
            return {
              id: operation.id,
              amountForPay: operation.total,
              payment: operation.payment,
              payer: operation.payer,
              payment_type: operation.payment_type,
              rowId: p.id,
              isNew: false,
              returned: operation.returned,
              return_status: operation.return_status,
            };
          });

          return {
            id: p.id,
            code: p.price_code,
            text: p.price_title,
            price: +p.price_value,
            count: p.count,
            discount: p.discount,
            discountType: p.discount_type,
            payment: +p.payment,
            subInvoiceId: p.sub_invoice,
            type: p.row_type,
            entry: p.entry,
            externalEntry: p.external_entry,
            remainder: +p.remainder,
            paid: +p.paid,
            payer: p.payer,
            paymentType: p.payment_type,
            comments: p.comments,
            paymentOperations: operations,
            subRows: tail(operations),
          };
        });
      },
    }),

    getInvoiceAllRows: builder.query<InvoiceRow[], number>({
      query: (invoiceId) => `/patient/invoices/${invoiceId}/all_sub_invoice_rows`,
      providesTags: [{ type: 'InvoiceCommonRows', id: 'LIST' }],
      transformResponse: (response: InvoiceRowInitial[]) => {
        return response.map((p) => ({
          id: p.id,
          code: p.price_code,
          text: p.price_title,
          price: +p.price_value,
          count: p.count,
          discount: p.discount,
          discountType: p.discount_type,
          payment: +p.payment,
          paid: +p.paid,
          serviceDate: p.service_date,
          createDate: p.created_at,
          subInvoiceId: p.sub_invoice,
          type: p.row_type,
          entry: p.entry,
          externalEntry: p.external_entry,
          paymentOperations: [],
          subRows: [],
        }));
      },
    }),

    getInvoiceAllRowsByStatus: builder.query<InvoiceRow[], InvoiceRowsByStatusRequest>({
      query: ({ invoiceId, status }) =>
        `/patient/invoices/${invoiceId}/all_sub_invoice_rows?sub_invoice_status=${status}`,
      providesTags: [{ type: 'InvoiceCommonRows', id: 'LIST' }],
      transformResponse: (response: InvoiceRowInitial[]) => {
        return response.map((p) => {
          const operations = p.payment_operations.map((operation: OperationInitial) => {
            return {
              id: operation.id,
              amountForPay: operation.total,
              payment: operation.payment,
              payer: operation.payer,
              payment_type: operation.payment_type,
              rowId: p.id,
              isNew: false,
              returned: operation.returned,
              return_status: operation.return_status,
            };
          });
          return {
            id: p.id,
            code: p.price_code,
            text: p.price_title,
            price: +p.price_value,
            count: p.count,
            discount: p.discount,
            discountType: p.discount_type,
            payment: +p.payment,
            serviceDate: p.service_date,
            createDate: p.created_at,
            subInvoiceId: p.sub_invoice,
            type: p.row_type,
            entry: p.entry,
            externalEntry: p.external_entry,
            payer: p.payer,
            paymentType: p.payment_type,
            remainder: +p.remainder,
            paid: +p.paid,
            comments: p.comments,
            paymentOperations: operations,
            subRows: [],
          };
        });
      },
    }),

    addInvoiceRow: builder.mutation<MedcardPlanJournalField, { subInvoiceId: number; record: InvoiceRow }>({
      query: (data) => {
        const { subInvoiceId, record } = data;
        return {
          url: `patient/sub_invoices/${subInvoiceId}/rows`,
          method: HttpMethod.POST,
          body: record,
        };
      },
      invalidatesTags: [
        { type: 'InvoiceRows', id: 'LIST' },
        { type: 'InvoiceCommonRows', id: 'LIST' },
      ],
    }),
    updateInvoiceRow: builder.mutation<InvoiceRow, { subInvoiceId: number; record: Partial<MedcardPlanJournalField> }>({
      query: (data) => {
        const { subInvoiceId, record } = data;
        return {
          url: `patient/sub_invoices/${subInvoiceId}/rows/${record.id}`,
          method: HttpMethod.PATCH,
          body: record,
        };
      },
      invalidatesTags: [
        { type: 'InvoiceRows', id: 'LIST' },
        { type: 'InvoiceCommonRows', id: 'LIST' },
      ],
    }),
    deleteInvoiceRow: builder.mutation<void, { subInvoiceId: number; invoiceRowId: number }>({
      query: (data) => {
        const { subInvoiceId, invoiceRowId } = data;
        return {
          url: `patient/sub_invoices/${subInvoiceId}/rows/${invoiceRowId}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: [
        { type: 'InvoiceRows', id: 'LIST' },
        { type: 'InvoiceCommonRows', id: 'LIST' },
      ],
    }),

    getInvoiceComments: builder.query<InvoiceComment[], number>({
      query: (invoiceId) => `patient/invoices/${invoiceId}/comments`,
      providesTags: [{ type: 'InvoiceComments', id: 'LIST' }],
    }),

    addInvoiceComment: builder.mutation<InvoiceComment, { invoiceId: number; record: Partial<InvoiceComment> }>({
      query: (data) => {
        const { invoiceId, record } = data;
        return {
          url: `patient/invoices/${invoiceId}/comments`,
          method: HttpMethod.POST,
          body: [record],
        };
      },
      invalidatesTags: [
        { type: 'InvoiceComments', id: 'LIST' },
        { type: 'Invoices', id: 'LIST' },
      ],
    }),
    updateInvoiceComment: builder.mutation<InvoiceComment, { invoiceId: number; record: Partial<InvoiceComment> }>({
      query: (data) => {
        const { invoiceId, record } = data;
        return {
          url: `patient/invoices/${invoiceId}/comments`,
          method: HttpMethod.PATCH,
          body: [record],
        };
      },
      invalidatesTags: [
        { type: 'InvoiceComments', id: 'LIST' },
        { type: 'Invoices', id: 'LIST' },
      ],
    }),
    deleteInvoiceComment: builder.mutation<void, { invoiceId: number; commentId: number }>({
      query: (data) => {
        const { invoiceId, commentId } = data;
        return {
          url: `patient/invoices/${invoiceId}/comments`,
          method: HttpMethod.DELETE,
          body: [commentId],
        };
      },
      invalidatesTags: [
        { type: 'InvoiceComments', id: 'LIST' },
        { type: 'Invoices', id: 'LIST' },
      ],
    }),

    getInvoiceRowComments: builder.query<InvoiceComment[], { subInvoiceId: number; invoiceRowId: number }>({
      query: ({ subInvoiceId, invoiceRowId }) => `patient/sub_invoices/${subInvoiceId}/rows/${invoiceRowId}/comments`,
      providesTags: [{ type: 'InvoiceRowComments', id: 'LIST' }],
    }),

    addInvoiceRowComment: builder.mutation<
      InvoiceComment,
      { subInvoiceId: number; invoiceRowId: number; record: Partial<InvoiceComment> }
    >({
      query: (data) => {
        const { subInvoiceId, invoiceRowId, record } = data;
        return {
          url: `patient/sub_invoices/${subInvoiceId}/rows/${invoiceRowId}/comments`,
          method: HttpMethod.POST,
          body: [record],
        };
      },
      invalidatesTags: [
        { type: 'InvoiceRowComments', id: 'LIST' },
        { type: 'InvoiceRows', id: 'LIST' },
      ],
    }),

    updateInvoiceRowComment: builder.mutation<
      InvoiceComment,
      { subInvoiceId: number; invoiceRowId: number; record: Partial<InvoiceComment> }
    >({
      query: (data) => {
        const { subInvoiceId, invoiceRowId, record } = data;
        return {
          url: `patient/sub_invoices/${subInvoiceId}/rows/${invoiceRowId}/comments`,
          method: HttpMethod.PATCH,
          body: [record],
        };
      },
      invalidatesTags: [
        { type: 'InvoiceRowComments', id: 'LIST' },
        { type: 'InvoiceRows', id: 'LIST' },
      ],
    }),

    deleteInvoiceRowComment: builder.mutation<void, { subInvoiceId: number; invoiceRowId: number; commentId: number }>({
      query: (data) => {
        const { subInvoiceId, invoiceRowId, commentId } = data;
        return {
          url: `patient/sub_invoices/${subInvoiceId}/rows/${invoiceRowId}/comments`,
          method: HttpMethod.DELETE,
          body: [commentId],
        };
      },
      invalidatesTags: [
        { type: 'InvoiceRowComments', id: 'LIST' },
        { type: 'InvoiceRows', id: 'LIST' },
      ],
    }),

    cleanup: builder.mutation<void, number>({
      query: (invoiceId) => {
        return {
          url: `patient/invoices/${invoiceId}/cleanup`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: [
        { type: 'SubInvoices', id: 'LIST' },
        { type: 'InvoiceRows', id: 'LIST' },
        { type: 'InvoiceCommonRows', id: 'LIST' },
      ],
    }),

    abort: builder.mutation<void, number>({
      query: (invoiceId) => {
        return {
          url: `patient/invoices/${invoiceId}/abort`,
          method: HttpMethod.GET,
        };
      },
      invalidatesTags: [
        { type: 'SubInvoices', id: 'LIST' },
        { type: 'InvoiceRows', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoiceAllRowsQuery,
  useGetInvoiceAllRowsByStatusQuery,
  useAddInvoiceMutation,
  useDeleteInvoiceMutation,
  useSendInvoiceMutation,

  useGetSubInvoicesQuery,
  useAddSubInvoiceMutation,
  useUpdateSubInvoiceMutation,

  useGetInvoiceRowsQuery,
  useAddInvoiceRowMutation,
  useUpdateInvoiceRowMutation,
  useDeleteInvoiceRowMutation,

  useGetInvoiceCommentsQuery,
  useGetInvoiceRowCommentsQuery,
  useAddInvoiceCommentMutation,
  useAddInvoiceRowCommentMutation,
  useDeleteInvoiceCommentMutation,
  useUpdateInvoiceCommentMutation,
  useUpdateInvoiceRowCommentMutation,
  useDeleteInvoiceRowCommentMutation,

  useCleanupMutation,
  useAbortMutation,
  endpoints,
} = invoiceApi;
