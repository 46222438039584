import React from 'react';
import { styled as styledMui } from '@material-ui/styles';
import CalendarPicker from '@mui/lab/CalendarPicker';
import PickersDay from '@mui/lab/PickersDay';
import { renderDayType, CustomCalendarPropsType } from './types';
import { handleDateChange } from './functions';

export const CustomCalendar = styledMui(CalendarPicker)(
  ({ disabled, highlightColor, weekdaysToHighlight }: CustomCalendarPropsType) => ({
    padding: '24px 24px',
    width: 'auto !important',
    color: disabled ? '#A8AEB5' : '#515D6B',
    maxHeight: '270px !important',
    '& .Mui-disabled.Mui-selected': {
      color: 'rgba(0, 0, 0, 0.38) !important',
    },
    '& .Mui-selected': {
      color: 'rgb(81, 93, 107) !important',
    },
    '& .MuiCalendarPicker-viewTransitionContainer': {
      marginTop: '16px',
      overflow: 'hidden',
    },
    '& .PrivatePickersSlideTransition-root': {
      minHeight: 'auto',
      height: '100%',
      '& .css-i6bazn': {
        position: 'relative',
      },
    },
    '& .MuiTypography-root': {
      width: '28px',
      height: '24px',
      fontWeight: '500',
      color: disabled ? '#A8AEB5' : '#515D6B',
      borderRadius: '4px',
      '&:nth-child(1)': {
        backgroundColor: `${weekdaysToHighlight['1'] ? `${highlightColor} !important` : 'unset !important'}`,
        '&::after': {
          content: '"н"',
          display: 'inline-block',
        },
      },
      '&:nth-child(2)': {
        backgroundColor: `${weekdaysToHighlight['2'] ? `${highlightColor} !important` : 'unset !important'}`,
        '&::after': {
          content: '"т"',
          display: 'inline-block',
        },
      },
      '&:nth-child(3)': {
        backgroundColor: `${weekdaysToHighlight['3'] ? `${highlightColor} !important` : 'unset !important'}`,
        '&::after': {
          content: '"р"',
          display: 'inline-block',
        },
      },
      '&:nth-child(4)': {
        backgroundColor: `${weekdaysToHighlight['4'] ? `${highlightColor} !important` : 'unset !important'}`,
        '&::after': {
          content: '"т"',
          display: 'inline-block',
        },
      },
      '&:nth-child(5)': {
        backgroundColor: `${weekdaysToHighlight['5'] ? `${highlightColor} !important` : 'unset !important'}`,
        '&::after': {
          content: '"т"',
          display: 'inline-block',
        },
      },
      '&:nth-child(6)': {
        backgroundColor: `${weekdaysToHighlight['6'] ? `${highlightColor} !important` : 'unset !important'}`,
        '&::after': {
          content: '"б"',
          display: 'inline-block',
        },
      },
      '&:nth-child(7)': {
        backgroundColor: `${weekdaysToHighlight['0'] ? `${highlightColor} !important` : 'unset !important'}`,
        '&::after': {
          content: '"с"',
          display: 'inline-block',
        },
      },
    },
    '& .MuiPickersDay-root': {
      backgroundColor: disabled ? '#F6F6FA !important' : 'white !important',
      width: '24px',
      height: '20px',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '22px',
      marginLeft: '2px',
      borderRadius: '4px',
      color: disabled ? '#A8AEB5' : '#515D6B',
      outline: 'none',
      '&.Mui-selected': {
        fontWeight: 'normal',
        '&.highlighted': {
          backgroundColor: `${highlightColor} !important`,
        },
      },
    },
    '& .css-1dozdou': {
      margin: 0,
      padding: 0,
      justifyContent: 'space-around',
      fontFamily: 'Roboto',
      fontSize: '14px',
      '& .css-l0iinn': {
        margin: 0,
        fontWeight: '400',
        '& .MuiButtonBase-root': {
          display: 'none',
        },
      },
      '& .css-1v994a0': {
        marginLeft: '20px',
      },
      '& .PrivatePickersFadeTransitionGroup-root': {
        width: '80px',
        '& .css-1v994a0': {
          textAlign: 'center',
        },
      },
    },
    '& .css-k008qs': {
      transform: 'rotate(270deg)',
      position: 'absolute',
      width: '60px',
      left: '25%',
      '& .MuiIconButton-root.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          color: '#A8AEB5',
        },
      },
      '& .MuiIconButton-root': {
        outline: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiSvgIcon-root': {
        color: '#253446',
      },
    },
    '& .MuiSvgIcon-root': {
      width: '15px',
      height: '15px',
    },
    '& .highlighted': {
      backgroundColor: `${highlightColor} !important`,
    },
  }),
);
export const renderDay: renderDayType = (
  day,
  selectedDates,
  pickersDayProps,
  datesWithSessions,
  selectedDate,
  datesToRender,
  selectedCabinets,
  dispatch,
) => {
  const isHighlighted =
    datesWithSessions && !pickersDayProps.disabled && datesWithSessions.has(new Date(day as Date).getTime());
  const isSelected = day.getTime() === selectedDate;
  return (
    <PickersDay
      style={{ border: `${isSelected ? '1px solid #577BF9' : 'unset'}` }}
      onClick={() => handleDateChange(day, selectedDate, datesToRender, selectedCabinets, dispatch)}
      className={`${isHighlighted ? 'highlighted' : ''}`}
      {...pickersDayProps}
    />
  );
};
