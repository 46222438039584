import React from 'react';
import styled from 'styled-components';
import Chat from '../Chat/Chat.js';
import { connect } from 'react-redux';
import { FilterSelector } from '../../features/schedule/selectors';
import { useNavigate } from 'react-router-dom';

const Layout = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  padding-right: 80px;
  justify-content: space-between;
`;

const BackButton = styled.img`
  width: 17px;
  margin-left: 20px;
`;
const ChatContainer = styled.div`
  transform: translateX(${({ isExpanded }) => (isExpanded ? '-200px' : '0px')});
  transition: 0.25s;
`;

function Header({ drawerState: { isExpanded }, modalView }) {
  const navigate = useNavigate();

  return (
    <Layout>
      <div
        style={{
          display: 'flex',
        }}
      >
        {!modalView && <BackButton onClick={() => navigate(-1)} src="./img/black/go-back.svg" />}
        <div
          style={{
            fontWeight: 500,
            fontSize: '18px',
            color: '#253446',
            marginLeft: '35px',
          }}
        >
          Расписание
        </div>
      </div>
      {!modalView && (
        <ChatContainer isExpanded={isExpanded}>
          <Chat />
        </ChatContainer>
      )}
    </Layout>
  );
}

export default connect(
  (state) => ({
    drawerState: state.drawerState,
    staff: state.staff,
    clinic: state.clinic,
    filters: FilterSelector(state),
    schedule_calendar: state.calendarSlice,
    doctor: state.doctor,
  }),
  {},
)(Header);
