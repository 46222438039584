import axios from 'axios';

const CANCELED_ERROR = 'canceled';
const REQUEST_TIMEOUT = 30000;
const NOT_CONNECT_SERVICE_MESSAGE = 'Нет связи с сервисом';

const erorrCodeMessage = {
  500: NOT_CONNECT_SERVICE_MESSAGE,
  ['Network Error']: NOT_CONNECT_SERVICE_MESSAGE,
  [CANCELED_ERROR]: NOT_CONNECT_SERVICE_MESSAGE,
};

export const fetchAxios = axios.create();

fetchAxios.interceptors.request.use(
  (request) => {
    const controller = new AbortController();
    const req = {
      ...request,
      signal: controller.signal,
    };

    setTimeout(() => {
      controller.abort();
    }, REQUEST_TIMEOUT);
    return req;
  },
  (error) => {
    return Promise.reject(error);
  },
);

fetchAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.message !== CANCELED_ERROR) error = error.toJSON();
    return Promise.reject(new Error(erorrCodeMessage[error.status || error.message]));
  },
);
