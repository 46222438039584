import React, { useEffect } from 'react';
import CalendarPicker from '@mui/lab/CalendarPicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ru from 'date-fns/locale/ru';
import styled from 'styled-components';
import { styled as styledMui } from '@material-ui/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ThemeProvider, createTheme } from '@mui/material/styles';

interface CalendarWrapperProps {
  isDisabled: boolean;
}

const CalendarWrapper = styled.div<CalendarWrapperProps>`
  position: relative;
  background: ${({ isDisabled }) => (isDisabled ? '#F6F6FA' : 'white')};
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  max-width: 240px;
`;
const CalendarFooter = styled.div`
  position: relative;
  margin-top: -12px;
`;

interface CustomCalendarProps {
  selectedColor?: string;
  disabled: boolean;
}

export const CustomCalendar = styledMui(CalendarPicker)(({ selectedColor, disabled }: CustomCalendarProps) => ({
  padding: '24px 24px',
  width: 'auto !important',
  color: disabled ? '#A8AEB5' : '#515D6B',
  maxHeight: '270px !important',
  '& .MuiCalendarPicker-viewTransitionContainer': {
    marginTop: '16px',
    overflow: 'hidden',
  },
  '& .PrivatePickersSlideTransition-root': {
    minHeight: 'auto',
    height: '100%',
    '& .css-i6bazn': {
      position: 'relative',
    },
  },
  '& .MuiTypography-root': {
    width: '28px',
    height: '24px',
    fontWeight: '500',
    color: disabled ? '#A8AEB5' : '#515D6B',
    '&:nth-child(1)': {
      '&::after': {
        content: '"н"',
        display: 'inline-block',
      },
    },
    '&:nth-child(2)': {
      '&::after': {
        content: '"т"',
        display: 'inline-block',
      },
    },
    '&:nth-child(3)': {
      '&::after': {
        content: '"р"',
        display: 'inline-block',
      },
    },
    '&:nth-child(4)': {
      '&::after': {
        content: '"т"',
        display: 'inline-block',
      },
    },
    '&:nth-child(5)': {
      '&::after': {
        content: '"т"',
        display: 'inline-block',
      },
    },
    '&:nth-child(6)': {
      '&::after': {
        content: '"б"',
        display: 'inline-block',
      },
    },
    '&:nth-child(7)': {
      '&::after': {
        content: '"с"',
        display: 'inline-block',
      },
    },
  },
  '& .MuiPickersDay-root': {
    backgroundColor: disabled ? '#F6F6FA' : 'white',
    width: '24px',
    height: '20px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '22px',
    marginLeft: '2px',
    borderRadius: '4px',
    color: disabled ? '#A8AEB5' : '#515D6B',
    outline: 'none',
    '&.Mui-selected': {
      backgroundColor: disabled ? '#F6F6FA' : selectedColor ? selectedColor : 'white !important',
      color: disabled ? '#A8AEB5' : '#515D6B',
      fontWeight: 'normal',
      '&:hover': {
        backgroundColor: '#EFF0F3',
      },
    },
    '&.MuiPickersDay-today': {
      border: 'none',
    },
  },
  '& .css-1dozdou': {
    margin: 0,
    padding: 0,
    justifyContent: 'space-around',
    fontFamily: 'Roboto',
    fontSize: '14px',
    '& .css-l0iinn': {
      margin: 0,
      fontWeight: '400',
      '& .MuiButtonBase-root': {
        display: 'none',
      },
    },
    '& .css-1v994a0': {
      marginLeft: '20px',
    },
    '& .PrivatePickersFadeTransitionGroup-root': {
      width: '80px',
      '& .css-1v994a0': {
        textAlign: 'center',
      },
    },
  },
  '& .css-k008qs': {
    transform: 'rotate(270deg)',
    position: 'absolute',
    width: '60px',
    left: '25%',
    '& .MuiIconButton-root.Mui-disabled': {
      '& .MuiSvgIcon-root': {
        color: '#A8AEB5',
      },
    },
    '& .MuiIconButton-root': {
      outline: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiSvgIcon-root': {
      color: '#253446',
    },
  },
  '& .MuiSvgIcon-root': {
    width: '15px',
    height: '15px',
  },
}));
const YearControls = styled.div`
  position: absolute;
  right: 27%;
  top: 12px;
`;
const YearControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 52px;
  justify-content: space-between;
`;

interface CalendarProps {
  onDisableDates?: (date: Date) => boolean;
  value?: Date | null;
  onDateChange?: (date: Date) => void;
  minDate?: Date;
  selectedColor?: string;
  isDisabled?: boolean;
}

export const Calendar: React.FC<CalendarProps> = ({
  children,
  onDisableDates,
  value,
  onDateChange,
  minDate,
  selectedColor,
  isDisabled = false,
}) => {
  const [date, setDate] = React.useState(value ?? new Date());

  useEffect(() => {
    value && setDate(value);
  }, [value]);

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  const isDisabledPreviousYear = minDate && minDate.getFullYear() === date.getFullYear();

  const theme = createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableTouchRipple: true,
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CalendarWrapper isDisabled={isDisabled}>
        <YearControls>
          <YearControlsWrapper>
            <KeyboardArrowUpIcon
              style={{
                width: 15,
                height: 15,
                color: isDisabled ? '#A8AEB5' : '#253446',
                cursor: isDisabled ? 'auto' : 'pointer',
              }}
              onClick={() => {
                if (isDisabled) {
                  return;
                }
                setDate(new Date(date.getFullYear() + 1, date.getMonth(), date.getDate()));
              }}
            />

            <KeyboardArrowDownIcon
              style={{
                width: 15,
                height: 15,
                color: isDisabledPreviousYear || isDisabled ? '#A8AEB5' : '#253446',
                cursor: isDisabledPreviousYear || isDisabled ? 'auto' : 'pointer',
              }}
              onClick={() => {
                if (isDisabled) {
                  return;
                }
                if (isDisabledPreviousYear) {
                  return;
                }
                setDate(new Date(date.getFullYear() - 1, date.getMonth(), date.getDate()));
              }}
            />
          </YearControlsWrapper>
        </YearControls>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
          <CustomCalendar
            disabled={isDisabled}
            date={date}
            onChange={(date) => handleDateChange(date)}
            views={['day']}
            shouldDisableDate={onDisableDates}
            minDate={minDate}
            selectedColor={selectedColor}
          />
        </LocalizationProvider>
        <CalendarFooter>{children}</CalendarFooter>
      </CalendarWrapper>
    </ThemeProvider>
  );
};
