//Режимы отображения расписания (нужны для работы с API компонента)
export const views = {
  displayByDay: {
    type: 'resourceTimeGrid',
  },
};

//Перевод одиночной даты в {start: дата, end: дата + 1 день}
//Компонент календаря принимает диапазон дат для отображения только в таком формате
export const getVisibleRange = (date) => {
  return { start: new Date(date), end: new Date(date) };
};

export const weekdaysKeys = {
  '0': 'SUN',
  '1': 'MON',
  '2': 'TUE',
  '3': 'WED',
  '4': 'THU',
  '5': 'FRI',
  '6': 'SAT',
};

export const weekdaysStrings = {
  '0': 'воскресений',
  '1': 'понедельников',
  '2': 'вторников',
  '3': 'сред',
  '4': 'четвергов',
  '5': 'пятниц',
  '6': 'суббот',
};
