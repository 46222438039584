import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { EventApi, CalendarApi } from '@fullcalendar/core';
import type { modelType, staffScheduleState, dateRangeType } from './components/StaffSchedulerTable/types';
import { CabinetDictionaryItem } from '../../../../services/dictionaries';
import { Staffer } from '../../../../services/users';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import { getSchedulerMutatingValues } from './components/StaffSchedulerTable//functions';
import { addDays } from 'date-fns';

const initialState: staffScheduleState = {
  staffSchedulerAPI: {} as CalendarApi,
  tableColumnModel: {},
  datesToRender: [],
  schedulerWidth: 0,
  currentStaffer: {} as Staffer,
  dateRange: [new Date(), null],
  datesInRange: [],
  selectedCabinets: [],
  lastCabinetId: 0,
  datesWithSessions: [],
  showEvenDates: false,
  showOddDates: false,
  selectedDate: 0,
  sessionsMatches: [],
  currentSessionsMatch: 0,
  isWatchingMatches: false,
  sessionWithTooltipId: null,
  sessionsToCreate: [],
  sessionsToRedact: [],
  sessionsToDelete: [],
  workdays: [],
  holidays: [],
  tableDisplayMode: 'displayByDay',
  datesToHighlightSet: null,
};

export const StaffScheduleSlice = createSlice({
  name: 'staffSchedule',
  initialState,
  reducers: {
    reset: () => initialState,
    setSchedulerAPI: (state, action: PayloadAction<CalendarApi>) => {
      return { ...state, staffSchedulerAPI: action.payload };
    },
    setTableColumnModel: (state, action: PayloadAction<modelType>) => {
      state.staffSchedulerAPI.setOption('header', action.payload);
      return { ...state, tableColumnModel: action.payload };
    },
    setDatesToRender: (state, action: PayloadAction<number[]>) => {
      state.staffSchedulerAPI.setOption('eventGroupField', action.payload);
      state.staffSchedulerAPI.setOption('navLinkWeekClick', new Set(action.payload));

      return { ...state, datesToRender: action.payload };
    },
    setSchedulerWidth: (state, action: PayloadAction<number>) => {
      state.staffSchedulerAPI.setOption('eventLongPressDelay', action.payload);
      return { ...state, schedulerWidth: action.payload };
    },
    setCurrentStaffer: (state, action: PayloadAction<Staffer>) => {
      return { ...state, currentStaffer: action.payload };
    },
    setDateRange: (state, action: PayloadAction<dateRangeType>) => {
      const datesInRange = action.payload[1]
        ? eachDayOfInterval({ start: action.payload[0], end: action.payload[1] })
        : [];
      const data =
        action.payload[1] && Object.keys(state.staffSchedulerAPI).length !== 0
          ? getSchedulerMutatingValues({ ...state, datesInRange })
          : {};
      return {
        ...state,
        dateRange: action.payload,
        datesInRange,
        ...data,
      };
    },
    setDateRangeV2: (state, action: PayloadAction<dateRangeType>) => {
      const tablePayload = { start: action.payload[0], end: action.payload[1] };
      const datesInRange = action.payload[1] ? eachDayOfInterval(tablePayload) : [];

      if (Object.keys(state.staffSchedulerAPI).length) {
        state.staffSchedulerAPI.setOption('visibleRange', tablePayload);
        state.staffSchedulerAPI.setOption('dayPopoverFormat', datesInRange);
      }

      return {
        ...state,
        dateRange: action.payload,
        datesInRange,
      };
    },
    setDateRangeExtended: (state, action: PayloadAction<dateRangeType>) => {
      const tablePayload = { start: action.payload[0], end: action.payload[1] };
      const tablePayloadExtended = { start: action.payload[0], end: addDays(new Date(action.payload[1]), 1) };
      const datesInRange = action.payload[1] ? eachDayOfInterval(tablePayload) : [];

      if (Object.keys(state.staffSchedulerAPI).length) {
        state.staffSchedulerAPI.setOption('visibleRange', tablePayloadExtended);
        state.staffSchedulerAPI.setOption('dayPopoverFormat', datesInRange);
      }

      return {
        ...state,
        dateRange: action.payload,
        datesInRange,
      };
    },
    setSelectedCabinets: (state, action: PayloadAction<CabinetDictionaryItem[]>) => {
      const data =
        Object.keys(state.staffSchedulerAPI).length !== 0
          ? getSchedulerMutatingValues({ ...current(state), selectedCabinets: action.payload })
          : {};
      return {
        ...state,
        selectedCabinets: action.payload,
        lastCabinetId:
          action.payload.length === 0
            ? 0
            : action.payload.reduce((maxIDcabinet, cabinet) => (maxIDcabinet.id > cabinet.id ? maxIDcabinet : cabinet))
                .id,
        ...data,
      };
    },
    setSelectedCabinetsV2: (state, action: PayloadAction<CabinetDictionaryItem[]>) => {
      const lastCabinetId = action.payload.length
        ? action.payload.reduce((maxIDcabinet, cabinet) => (maxIDcabinet.id > cabinet.id ? maxIDcabinet : cabinet)).id
        : 0;

      state.staffSchedulerAPI.setOption('resources', action.payload);
      state.staffSchedulerAPI.setOption('handleWindowResize', lastCabinetId);

      return {
        ...state,
        selectedCabinets: action.payload,
        lastCabinetId,
      };
    },
    setDatesWithSessions: (state, action: PayloadAction<number[]>) => {
      return { ...state, datesWithSessions: action.payload };
    },
    setDatesToHighlightSet: (state, action: PayloadAction<Set<number>>) => {
      return { ...state, datesToHighlightSet: action.payload };
    },
    setShowEvenDates: (state, action: PayloadAction<boolean>) => {
      state.staffSchedulerAPI.setOption('eventSourceFailure', action.payload);

      return {
        ...state,
        showEvenDates: action.payload,
      };
    },
    setShowOddDates: (state, action: PayloadAction<boolean>) => {
      state.staffSchedulerAPI.setOption('dayHeaderFormat', action.payload);

      return {
        ...state,
        showOddDates: action.payload,
      };
    },
    setSelectedDate: (state, action: PayloadAction<number>) => {
      state.staffSchedulerAPI.setOption('eventSourceFetch', action.payload);
      return {
        ...state,
        selectedDate: action.payload,
      };
    },
    setSessionsMatches: (state, action: PayloadAction<[EventApi, EventApi][]>) => {
      const payload = action.payload
        .map((sessionsGroup) => [
          new Date(sessionsGroup[0]._instance.range.start).setHours(0, 0, 0, 0),
          [...new Set(sessionsGroup.map((session) => session._def.resourceIds[0]))],
        ])
        .sort((a, b) => a[0] - b[0]);

      state.staffSchedulerAPI.setOption('longPressDelay', payload);

      return {
        ...state,
        sessionsMatches: payload,
        currentSessionsMatch: 0,
      };
    },
    setIsWatchingMatches: (state, action: PayloadAction<boolean>) => {
      state.staffSchedulerAPI.setOption('schedulerLicenseKey', action.payload);
      state.staffSchedulerAPI.setOption('selectLongPressDelay', 0);

      return { ...state, isWatchingMatches: action.payload, currentSessionsMatch: 0 };
    },
    setCurrentSessionsMatch: (state, action: PayloadAction<number>) => {
      state.staffSchedulerAPI.setOption('selectLongPressDelay', action.payload);

      return { ...state, currentSessionsMatch: action.payload };
    },
    setSessionWithTooltipId: (state, action: PayloadAction<number | null>) => {
      return { ...state, sessionWithTooltipId: action.payload };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSessionsToCreate: (state, action: PayloadAction<any[]>) => {
      return { ...state, sessionsToCreate: [...state.sessionsToCreate, ...action.payload] };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSessionsToRedact: (state, action: PayloadAction<any[]>) => {
      const uuidv1StringLength = 36;
      const sessionsToRedactPayload = [
        ...state.sessionsToRedact,
        ...action.payload.filter((session) => session.id.length < uuidv1StringLength),
      ];
      const sessionsToCreatePayload = [
        ...state.sessionsToCreate,
        ...action.payload.filter((session) => session.id.length === uuidv1StringLength),
      ];

      // Получение массива редактируемых сессий с уникальными id (чтобы не повторялись одни и те же сессии - редактированные ранее, и поздние)
      const SessionsToRedactPayloadMap = new Map(sessionsToRedactPayload.map((pos) => [pos.id, pos]));
      const uniqueSessionsToRedactPayload = [...SessionsToRedactPayloadMap.values()];

      // Получение массива сессий, которые будут созданы (без повторений, актуальные с учётом редактирования)
      const SessionsToCreatePayloadMap = new Map(sessionsToCreatePayload.map((pos) => [pos.id, pos]));
      const uniqueSessionsToCreatePayload = [...SessionsToCreatePayloadMap.values()];

      return {
        ...state,
        sessionsToRedact: uniqueSessionsToRedactPayload,
        sessionsToCreate: uniqueSessionsToCreatePayload,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSessionsToDelete: (state, action: PayloadAction<any[]>) => {
      // На сервер уходит запрос только тех сессий, которые там уже были. Иначе никакого смысла в этом нет - нельзя удалить того, чего нет
      const filteredSessionsToDelete = action.payload.filter((session) => session.id.length < 36);

      // Если сессии были в массиве созданных - их надо оттуда удалить
      const filteredSessionsToCreate = state.sessionsToCreate.filter(
        (sessionToCreate) => !action.payload.find((sessionToDelete) => sessionToDelete.id === sessionToCreate.id),
      );

      // Если сессии были в массиве редактированных - их надо оттуда удалить
      const filteredSessionsToRedact = state.sessionsToRedact.filter(
        (sessionToRedact) => !action.payload.find((sessionToDelete) => sessionToDelete.id === sessionToRedact.id),
      );
      return {
        ...state,
        sessionsToDelete: [...state.sessionsToDelete, ...filteredSessionsToDelete],
        sessionsToCreate: filteredSessionsToCreate,
        sessionsToRedact: filteredSessionsToRedact,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setWorkdays: (state, action: PayloadAction<any[]>) => {
      state.staffSchedulerAPI.setOption('resourceAreaLabelContent', action.payload);

      return { ...state, workdays: action.payload };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setHolidays: (state, action: PayloadAction<any[]>) => {
      state.staffSchedulerAPI.setOption('resourceAreaWidthEditable', action.payload);

      return { ...state, holidays: action.payload };
    },
    setTableDisplayMode: (state, action: PayloadAction<'displayByDay' | 'displayByPeriod'>) => {
      state.staffSchedulerAPI.changeView(action.payload);

      return { ...state, tableDisplayMode: action.payload };
    },
  },
});

export const {
  reset,
  setSchedulerAPI,
  setTableColumnModel,
  setDatesToRender,
  setSchedulerWidth,
  setCurrentStaffer,
  setDateRange,
  setDateRangeV2,
  setSelectedCabinets,
  setSelectedCabinetsV2,
  setDatesWithSessions,
  setShowEvenDates,
  setShowOddDates,
  setSelectedDate,
  setSessionsMatches,
  setIsWatchingMatches,
  setCurrentSessionsMatch,
  setSessionWithTooltipId,
  setSessionsToCreate,
  setSessionsToRedact,
  setSessionsToDelete,
  setWorkdays,
  setHolidays,
  setTableDisplayMode,
  setDateRangeExtended,
  setDatesToHighlightSet,
} = StaffScheduleSlice.actions;
