/* eslint-disable @typescript-eslint/no-unused-vars */
import store from '../../../../store';
import { reset } from './reducer';

// Данные о расписании с бека -> сессии для таблицы расписания
export const mapScheduleDataToSessions = (schedule, staffers, cabinetsDictionary, areSessionsBackgrounded = false) => {
  const cabinetsIDs = new Set(cabinetsDictionary.map((cabinet) => cabinet.id));

  const stafferLookup = {};
  staffers.forEach((staffer) => {
    stafferLookup[staffer.id] = staffer;
  });

  return schedule
    .filter((scheduleItem) => cabinetsIDs.has(scheduleItem.cabinet))
    .map((scheduleItem) => {
      const thisSessionStaffer = stafferLookup[scheduleItem.worker];
      return {
        id: scheduleItem.id,
        resourceId: scheduleItem.cabinet,
        stafferId: scheduleItem.worker,
        title: `${thisSessionStaffer?.last_name || 'Неизвестно'} ${thisSessionStaffer?.first_name[0] || 'Н'}. ${
          thisSessionStaffer?.second_name[0] || 'Н'
        }.`,
        color: thisSessionStaffer?.color || '#DEF0FB',
        start: scheduleItem.starts_at,
        end: scheduleItem.ends_at,
        ...(areSessionsBackgrounded && { display: 'background' }),
      };
    });
};

// Cессии для таблицы расписания -> данные о расписании с бека
export const mapSessionsToScheduleData = (staffSchedulerAPI, currentUserId) => {
  return staffSchedulerAPI.getEvents().map((session) => ({
    starts_at: session._instance.range.start.toISOString(),
    ends_at: session._instance.range.end.toISOString(),
    cabinet: ~~session._def.resourceIds[0],
    worker: session._def.extendedProps.stafferId,
    clinic: currentUserId,
  }));
};

// Сохранение расписания при клике на "Сохранить"
export const saveSchedulerData = async (setScheduleBulk, updateUser, onClose, dispatch) => {
  const state = store.getState();
  const currentClinicId = state.common.user.clinic.id;
  const currentStaffer = state.staffSchedule.currentStaffer;
  const sessionsToCreate = state.staffSchedule.sessionsToCreate.map((sessionToCreate) => {
    return {
      starts_at: new Date(sessionToCreate.start).toISOString(),
      ends_at: new Date(sessionToCreate.end).toISOString(),
      cabinet: ~~sessionToCreate.resourceId,
      worker: sessionToCreate.stafferId,
      clinic: currentClinicId,
    };
  });
  const sessionsToRedact = state.staffSchedule.sessionsToRedact.map((sessionToRedact) => {
    return {
      id: ~~sessionToRedact.id,
      starts_at: new Date(sessionToRedact.start).toISOString(),
      ends_at: new Date(sessionToRedact.end).toISOString(),
      cabinet: ~~sessionToRedact.resourceId,
      worker: sessionToRedact.stafferId,
      clinic: currentClinicId,
    };
  });
  const sessionsToDelete = state.staffSchedule.sessionsToDelete.map((sessionToDelete) => ~~sessionToDelete.id);
  const finalPayload = [...sessionsToCreate, ...sessionsToDelete, ...sessionsToRedact];

  updateUser({ id: currentStaffer.id, color: currentStaffer.color });
  finalPayload.length && setScheduleBulk({ id: currentClinicId, data: finalPayload });
  dispatch(reset(null));
  onClose();
};
