import Input from '@material-ui/core/Input';
import { styled as styledMui } from '@material-ui/styles';

interface OutlinedInputProps {
  inputIsVisible?: boolean;
  filled?: boolean;
}

export const OutlinedInput = styledMui(Input)(({ inputIsVisible = true, filled = false }: OutlinedInputProps) => ({
  border: '1px solid #D3D6DA',
  padding: filled ? '8px 12px 8px 25px' : '8px 12px',
  borderRadius: '4px',
  width: '116px',
  height: '32px',
  fontSize: '14px',
  boxSizing: 'border-box',
  color: '#515D6B',
  background: 'white',
  '& .MuiInputBase-input': {
    padding: 0,
    margin: 0,
    visibility: inputIsVisible ? 'visible' : 'hidden',
  },
  '&.Mui-focused': {
    border: '1px solid #577BF9',
    filter: 'drop-shadow(0px 0px 4px rgba(87, 123, 249, 0.5))',
  },
  '&:hover': {
    border: '1px solid #577BF9',
  },
}));
