import Header from '../../components/Header/PageHeader';
import { LinkButton } from '../../uikit/Button/LinkButton';
import Drawer from '../../components/Drawer';
import React, { useState } from 'react';
import { ReportHeader } from './components/ReportHeader';
import { ReportBody } from './components/ReportBody';
import { ReportName } from './enums';
import { Box, CenterPage, Layout, ReportContainer, Title } from './styles';
import { setReportDisplayMode } from './reducer';
import { useAppDispatch } from '../../store/hooks';

const ReportTitle = {
  [ReportName.REVENUE]: 'Выручка клиники',
  [ReportName.DEBT]: 'Задолженности и авансы',
  [ReportName.HISTORY]: 'История изменений',
};

export function ReportPage() {
  const dispatch = useAppDispatch();
  const [reportName, setReportName] = useState<ReportName>(ReportName.NONE);

  return (
    <Layout>
      {reportName === ReportName.NONE || reportName === ReportName.HISTORY ? (
        <Header text={reportName === ReportName.HISTORY ? 'История изменений' : 'Отчеты'} />
      ) : (
        <ReportHeader
          reportName={reportName}
          title={ReportTitle[reportName]}
          onBackButtonClick={() => {
            setReportName(ReportName.NONE);
            dispatch(setReportDisplayMode(''));
          }}
        />
      )}
      <Drawer />
      <ReportContainer>
        {reportName === ReportName.NONE ? (
          <CenterPage>
            <Box>
              <Title>Кассовые операции</Title>
              <LinkButton
                style={{ paddingLeft: 0, paddingBottom: 0 }}
                disableRipple
                onClick={() => {
                  setReportName(ReportName.REVENUE);
                  dispatch(setReportDisplayMode('revenue'));
                }}
              >
                {ReportTitle[ReportName.REVENUE]}
              </LinkButton>
              <LinkButton
                style={{ paddingLeft: 0, paddingBottom: 0 }}
                disableRipple
                onClick={() => {
                  setReportName(ReportName.DEBT);
                  dispatch(setReportDisplayMode('debts_and_advances'));
                }}
              >
                {ReportTitle[ReportName.DEBT]}
              </LinkButton>
            </Box>
            <Box>
              <LinkButton
                style={{ paddingLeft: 0, paddingBottom: 0 }}
                disableRipple
                onClick={() => {
                  setReportName(ReportName.HISTORY);
                  dispatch(setReportDisplayMode('history'));
                }}
              >
                {ReportTitle[ReportName.HISTORY]}
              </LinkButton>
            </Box>
          </CenterPage>
        ) : (
          <ReportBody reportName={reportName} />
        )}
      </ReportContainer>
    </Layout>
  );
}
