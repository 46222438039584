import styled from 'styled-components';

type ButtonProps = {
  disabled?: boolean;
};

export const Filters = styled.div`
  margin: 24px 0;
  height: 32px;
`;
export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
`;
export const BlueButton = styled.button<ButtonProps>`
  width: 132px;
  height: 32px;
  padding: 5px 16px;
  border-radius: 4px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  background: ${({ disabled }) => (disabled ? '#EFF0F3' : '#577bf9')};
  color: ${({ disabled }) => (disabled ? '#A8AEB5' : '#ffffff')};
  border: ${({ disabled }) => (disabled ? '1px solid #D3D6DA' : '1px solid white')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  outline: none !important;
`;

export const OutlineButton = styled(BlueButton)`
  background: none;
  color: ${({ disabled }) => (disabled ? '#EFF0F3' : '#577bf9')};
`;
