import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup } from '@material-ui/core';

import AntSwitch from '../../../../uikit/Switch';
import { InfoIcon } from '../../../../uikit/Icons';
import { UserPermissions, UserRole } from '../../../../services/users';

import { PermissionBlock, PermissionTitle, SwitchFormControlLabel } from './styles';
import { LabelWithHint } from './LabelWithHint';

type PermissionsProps = {
  permissions: UserPermissions;
  disabled?: boolean;
  userRole: UserRole;
  isSuperUser: boolean;
};

type PermissionItems = {
  title: string;
  items: PermissionItem[];
};

type PermissionItem = {
  permission: string;
  label: React.ReactNode;
  hint?: React.ReactNode;
  relatedTo?: string[];
  relations?: string[];
  roles: UserRole[];
};

const permissionItems: PermissionItems[] = [
  {
    title: 'Редактирование профиля клиники',
    items: [
      {
        permission: 'edit_clinic_profile',
        label: (
          <>
            <div style={{ marginBottom: '4px' }}>{'Редактирование профиля клиники'}</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <InfoIcon style={{ marginRight: '6px' }} />
              <span style={{ color: '#515D6B', opacity: 0.8, fontSize: '12px' }}>
                {'Настройка клиники приостанавливает работу во всей программе'}
              </span>
            </div>
          </>
        ),
        hint: (
          <>
            <div>{'Добавление или редактирование:'}</div>
            <ul style={{ margin: 0 }}>
              <li>{'Реквизитов клиники'}</li>
              <li>{'Логотипа'}</li>
              <li>{'Графика работы клиники'}</li>
              <li>{'Кабинетов'}</li>
              <li>{'Сотрудников'}</li>
              <li>{'Таблицы обозначений для зубной формулы'}</li>
              <li>{'Справочника МКБ'}</li>
            </ul>
          </>
        ),
        roles: [UserRole.Doctor, UserRole.Administrator],
      },
    ],
  },
  {
    title: 'Сотрудники',
    items: [
      {
        permission: 'edit_staff',
        label: 'Добавление и редактирование сотрудников',
        hint: 'Дает право создавать сотрудников, настраивать доступы к различным разделам программы и расписания, а так же блокировать аккаунты',
        relations: ['edit_staff_schedule', 'view_staff_contacts', 'view_staff_access'],
        roles: [UserRole.Doctor, UserRole.Administrator],
      },
      {
        permission: 'edit_staff_schedule',
        label: 'Редактирование расписания сотрудников',
        hint: 'Позволят вносить изменения в график работы сотрудников',
        relatedTo: ['edit_staff'],
        roles: [UserRole.Doctor, UserRole.Administrator],
      },
      {
        permission: 'view_staff_contacts',
        label: 'Просмотр контактов сотрудников',
        hint: 'Позволяет увидеть доступы других пользователей в программе',
        relatedTo: ['edit_staff'],
        roles: [UserRole.Doctor, UserRole.Administrator],
      },
      {
        permission: 'view_staff_access',
        label: 'Просмотр доступов сотрудников',
        hint: 'Возможность увидеть номера телефонов и Email в общей таблице сотрудников',
        relatedTo: ['edit_staff'],
        roles: [UserRole.Doctor, UserRole.Administrator],
      },
    ],
  },
  {
    title: 'Счета',
    items: [
      {
        permission: 'create_account',
        label: 'Создание счета',
        hint: 'Этот доступ позволяет выставлять счета',
        relations: ['view_payment'],
        roles: [UserRole.Administrator],
      },
      {
        permission: 'delete_account',
        label: 'Удаление счета',
        hint: 'Этот доступ позволяет удалять счет вне зависимости от того, кто его создал',
        roles: [UserRole.Administrator, UserRole.Doctor],
      },
      {
        permission: 'edit_account',
        label: 'Редактирование счета',
        hint: 'Редактирование счетов, созданные другими пользователями',
        roles: [UserRole.Administrator],
      },
      {
        permission: 'edit_payment',
        label: 'Расчет пациента',
        hint: 'Возможность брать оплату и закрывать счета',
        relations: ['view_payment'],
        roles: [UserRole.Administrator, UserRole.Doctor],
      },
      {
        permission: 'view_payment',
        label: 'Просмотр оплат',
        hint: 'Дает право на просмотр счетов пациентов, без возможности их выставления',
        relatedTo: ['create_account', 'edit_payment'],
        roles: [UserRole.Administrator, UserRole.Doctor],
      },
    ],
  },
  {
    title: 'Пациенты',
    items: [
      {
        permission: 'view_patient_contacts',
        label: 'Просмотр контактов пациентов',
        hint: 'Возможность увидеть номера телефонов и Email в общей базе пациентов',
        roles: [UserRole.Administrator, UserRole.Doctor],
      },
      {
        permission: 'view_patient_docs',
        label: 'Просмотр данных паспорта и страхового полиса',
        hint: 'Позволяет увидеть паспортные данные, адрес регистрации и страховой полис пациента в медицинской карте',
        roles: [UserRole.Administrator, UserRole.Doctor],
      },
      {
        permission: 'view_patient_medcard',
        label: 'Просмотр записей в медицинской карте',
        hint: 'Дает право просматривать информацию о состоянии здоровья в медицинской карте (зубная формула и дневники лечения)',
        roles: [UserRole.Administrator],
      },
    ],
  },
  {
    title: 'Прейскурант',
    items: [
      {
        permission: 'edit_price',
        label: 'Добавление и редактирование прейскуранта',
        hint: 'Дает право на добавление, изменение и удаление позиций в прейскуранте',
        roles: [UserRole.Administrator, UserRole.Doctor],
      },
    ],
  },
  {
    title: 'Отчеты',
    items: [
      {
        permission: 'create_report',
        label: 'Создание отчетов',
        hint: 'Дает право создавать, печатать и сохранять отчеты',
        roles: [UserRole.Administrator, UserRole.Doctor],
      },
    ],
  },
];

export const Permissions = React.memo((props: PermissionsProps) => {
  const { control, setValue } = useFormContext();
  const { permissions: defaultPermissions, disabled, userRole, isSuperUser } = props;
  const [permissions, setPermissions] = useState(defaultPermissions);

  useEffect(() => {
    setPermissions(defaultPermissions);
  }, [defaultPermissions]);

  useEffect(() => {
    setValue('permissions', permissions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const handlePermissionChange = (name: string, items: PermissionItem[], relations: string[] = []) => {
    if (disabled) {
      return;
    }
    const relatedItems = items.filter(({ permission }) => relations.includes(permission));
    const relatedPermissions: UserPermissions = relatedItems.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.permission]:
          cur.relatedTo.filter((perm) => perm !== name).some((perm) => permissions[perm]) || !permissions[name],
      }),
      {},
    );
    setPermissions((old) => ({ ...old, ...relatedPermissions, [name]: !old[name] }));
  };

  return (
    <>
      {permissionItems.map(({ title, items }) => (
        <PermissionBlock key={title}>
          <PermissionTitle>{title}</PermissionTitle>
          <FormGroup>
            {items
              .filter(({ roles }) => roles.includes(userRole) || isSuperUser)
              .map(
                ({ permission, label, hint, relatedTo, relations }) =>
                  Object.prototype.hasOwnProperty.call(permissions, permission) && (
                    <SwitchFormControlLabel
                      key={permission}
                      control={
                        <Controller
                          name="permissions"
                          control={control}
                          render={() => (
                            <AntSwitch
                              checked={permissions[permission]}
                              disabled={
                                isSuperUser ||
                                disabled ||
                                (relatedTo && permissions[permission] && relatedTo.some((perm) => permissions[perm]))
                              }
                              onChange={() => handlePermissionChange(permission, items, relations)}
                            />
                          )}
                        />
                      }
                      label={<LabelWithHint label={label} hint={hint} disabled={isSuperUser || disabled} />}
                    />
                  ),
              )}
          </FormGroup>
        </PermissionBlock>
      ))}
    </>
  );
});

Permissions.displayName = 'Permissions';
