import React, { FC, useState } from 'react';
import { TableActionButton, TableActionMenu } from '../../../components/Table/styles';
import { DotsIcon } from '../../../uikit/Icons';
import { MenuItem } from '@material-ui/core';
import { CommentsForm } from '../../../uikit/CommentsForm';
import { PaymentDetails } from './PaymentDetails';
import { Modal } from '../../../uikit/Modal';
import { ACTIVE_CLASS } from '../../../uikit/Modal/constants';
import { ModalType } from '../enums';

type ActionsMenuType = {
  label: string;
  onClick: () => void;
};

type OptionsMenuType = {
  handleScrollTop?: () => void;
  invoiceId: number;
  formHeight?: number;
};

export const OptionsMenu: FC<OptionsMenuType> = ({ handleScrollTop, formHeight, invoiceId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [parentContainer, setParentContainer] = useState<null | HTMLElement>(null);
  const [modalType, setModalType] = useState<ModalType | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (type: ModalType) => () => {
    if (anchorEl) {
      const parentElement = anchorEl.offsetParent as HTMLElement;
      parentElement.classList.add(ACTIVE_CLASS);
      setModalType(type);
      setParentContainer(parentElement);
    }
  };

  const handleCloseModal = () => {
    parentContainer.classList.remove(ACTIVE_CLASS);
    setParentContainer(null);
    setModalType(null);
  };

  const actions: ActionsMenuType[] = [
    {
      label: 'Детали оплаты',
      onClick: handleOpenModal(ModalType.PAYMENT_DETAILS),
    },
    {
      label: 'Добавить комментарий',
      onClick: handleOpenModal(ModalType.COMMENTS),
    },
  ];

  return (
    <>
      <TableActionButton size={'small'} disableRipple onClick={handleMenuOpen}>
        <DotsIcon />
      </TableActionButton>
      <TableActionMenu id="action-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {actions.map(({ label, onClick }, idx) => (
          <MenuItem
            key={idx}
            onClick={() => {
              onClick();
              handleMenuClose();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </TableActionMenu>
      <Modal anchor={parentContainer}>
        {modalType === ModalType.COMMENTS ? (
          <CommentsForm
            handleSetOpenForm={handleCloseModal}
            scrollTop={handleScrollTop}
            invoiceId={invoiceId}
            formHeight={formHeight}
          />
        ) : (
          <PaymentDetails handleCloseForm={handleCloseModal} scrollTop={handleScrollTop} invoiceId={invoiceId} />
        )}
      </Modal>
    </>
  );
};
