import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type ServiceTitleProps = {
  value: string;
  width?: number;
};

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      fontSize: 14,
      fontWeight: 'normal',
      padding: 4,
      borderRadius: 4,
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
      background: '#253446',
      maxWidth: 360,
      marginTop: '-2px',
    },
  }),
);

const Title = styled.div<{ width: number }>`
  width: ${({ width }) => (width ? width : 360)}px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const ServiceTitle: FC<ServiceTitleProps> = ({ value, width }) => {
  const classes = useStyles();
  const textDiv = useRef(null);
  return (
    <>
      {textDiv?.current?.scrollWidth > textDiv?.current?.clientWidth ? (
        <Tooltip classes={classes} title={value} placement="bottom-start">
          <Title ref={textDiv} width={width}>
            {value}
          </Title>
        </Tooltip>
      ) : (
        <Title ref={textDiv} width={width}>
          {value}
        </Title>
      )}
    </>
  );
};
