import React, { useState } from "react";
import TextInput from "../../../../uikit/TextInput";
import { getTextWidth } from "../../../../utils";
import { connect } from "react-redux";

function EditCategory({ onBlur, value, priceSlice }: any) {
  const [inputValue, setValue] = useState(value);
  const notUniq =
    priceSlice.categories.filter(
      (category: any) => category.name === inputValue && category.name !== value
    ).length > 0;
  const isEmpty = inputValue.length === 0;
  return (
    <div
      style={{
        width: getTextWidth(value) + 20,
        height: 32,
        marginTop: 10,
      }}
    >
      <TextInput
        onChange={(e: any) => setValue(e.target.value)}
        autoFocus
        error={notUniq || isEmpty}
        onBlur={onBlur}
        value={inputValue}
      />
    </div>
  );
}

export default connect((state: any) => ({ priceSlice: state.priceSlice }))(
  EditCategory
);
