import styled from 'styled-components';
import { ClearIcon } from '../../../../uikit/Icons';

export const CalendarWrapper = styled.div<{isOpen: boolean}>`
    position: absolute;
    z-index: 1;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    margin-top: 3px;
`;

export const DatePickerWrapper = styled.div`
    position: relative;
`;

export const Divider = styled.div`
    border-top: 1px solid #d3d6da;
    margin-top: 20px;
`;

export const ReportsWrapper = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #577bf9;
    display: flex;
`;
export const Report = styled.div`
    width: 144px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    margin: 7px 0;
    cursor: pointer;
`;

export const StyledClearIcon = styled(ClearIcon)`
    position: absolute;
    left: 8px;
    top: 9px;
    z-index: 101;
    cursor: pointer;
`;