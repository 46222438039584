import React, { CSSProperties } from 'react';

export default function ErrorPopup({
  isShown,
  text = '',
  style = {},
  messageStyle = {},
}: {
  isShown: boolean;
  text?: string;
  style?: CSSProperties;
  messageStyle?: CSSProperties;
}) {
  return (
    <>
      {isShown && (
        <div
          style={{
            zIndex: 22,
            position: 'absolute',
            bottom: -25,
            left: 0,
            height: 25,
            width: 70,
            ...style,
          }}
        >
          <div className="message-tip" style={{ ...messageStyle }}>
            {text || 'Обязательное поле'}
          </div>
        </div>
      )}
    </>
  );
}
