import React, { useState } from "react";
import TextInput from "../../../../uikit/TextInput";
import { createNewCategory } from "../../operations";
import PriceState from "../../state";
import { connect } from "react-redux";

function NewCategory({
  createNewCategory,
  setFirstCategory,
  startCategoryCreation,
  endCategoryCreation,
  priceSlice,
}: any) {
  const [isCreating, toggleCreation] = useState(false);
  const { createFirstCategory } = priceSlice;

  const [value, setValue] = useState("empty");
  const hasErrors =
    value === "" ||
    priceSlice.categories.find((category: any) => category.name === value);
  return (
    <>
      {(isCreating || createFirstCategory) && (
        <div style={{ width: 150, height: 32 }}>
          <TextInput
            autoFocus
            withPlaceholder
            placeholder={"Новая категория"}
            error={hasErrors}
            onChange={(e: any) => {
              setValue(e.target.value);
            }}
            onBlur={(e: any) => {
              toggleCreation(false);
              if (e.target.value === "") {
                setFirstCategory(false);
              }
              setValue("empty");
              endCategoryCreation();
              if (e.target.value !== "" && !hasErrors) {
                createNewCategory({ name: e.target.value, clinic: 1 });
              }
            }}
          />
        </div>
      )}
      {(!isCreating || !createFirstCategory) && (
        <img
          onClick={() => {
            startCategoryCreation();
            toggleCreation(true);
          }}
          src={"./img/black/plus.svg"}
          alt={"add"}
        />
      )}
    </>
  );
}

export default connect(
  (state: any) => ({ common: state.common, priceSlice: state.priceSlice }),
  {
    createNewCategory,
    setFirstCategory: PriceState.actions.setCreateFirstCategory,
    startCategoryCreation: PriceState.actions.startCategoryCreation,
    endCategoryCreation: PriceState.actions.endCategoryCreation,
  }
)(NewCategory);
