/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { DatePicker } from '../DatePicker';
import styled from 'styled-components';

const DateRangePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div<{ mr?: number }>`
  margin-right: ${(props) => (props.mr ? `${props.mr}px` : '6px')};
  color: #253446;
  font-weight: 400;
  font-size: 14px;
`;

interface DateRangePickerProps {
  value?: (Date | null | undefined)[];
  minDates?: Date[];
  onChange?: (value: [Date, Date | null]) => void;
  isDisabled?: boolean;
  nonWorkingDates?: string[];
  nonWorkingWeekdays?: number[];
}

export const DateRangePicker = React.forwardRef<HTMLDivElement, DateRangePickerProps>(
  (
    {
      value,
      minDates,
      onChange,
      isDisabled = false,
      nonWorkingDates = undefined,
      nonWorkingWeekdays = undefined,
      ...rest
    },
    ref,
  ) => {
    const [dateRange, setDateRange] = useState(value);

    useEffect(() => setDateRange(value), [value]);

    const handleChange = (range: [Date, Date]): void => {
      if (onChange) {
        onChange(range);
      }
    };

    return (
      <DateRangePickerWrapper {...rest} ref={ref}>
        <DatePickerWrapper>
          <Label>c</Label>
          <DatePicker
            value={dateRange[0]}
            onDateChange={(newDate) => handleChange([newDate, dateRange[1]])}
            minDate={minDates[0]}
            isDisabled={isDisabled}
            nonWorkingDates={nonWorkingDates}
            nonWorkingWeekdays={nonWorkingWeekdays}
          />
        </DatePickerWrapper>
        <DatePickerWrapper>
          <Label mr={12}>до</Label>
          <DatePicker
            value={dateRange[1]}
            onDateChange={(newDate) => handleChange([dateRange[0], newDate])}
            minDate={minDates[1]}
            isDisabled={isDisabled}
            nonWorkingDates={nonWorkingDates}
            nonWorkingWeekdays={nonWorkingWeekdays}
          />
        </DatePickerWrapper>
      </DateRangePickerWrapper>
    );
  },
);
