import { useState, useRef, useCallback } from 'react';

/**
 * Хук для отслеживания наведения курсора мыши на JSX-элемент
 */

// Использование:
// function App() {
//   const [hoverRef, isHovered] = useHover();
//   return (
//     <div ref={hoverRef}>
//         {isHovered ? '😁' : '☹️'}
//     </div>
//     );
// }

export const useHover = () => {
  const [value, setValue] = useState(false);

  const handleMouseOver = useCallback(() => setValue(true), []);
  const handleMouseOut = useCallback(() => setValue(false), []);

  const ref = useRef();

  const callbackRef = useCallback(
    (node) => {
      if (ref.current) {
        ref.current.removeEventListener('mouseover', handleMouseOver);
        ref.current.removeEventListener('mouseout', handleMouseOut);
      }

      ref.current = node;

      if (ref.current) {
        ref.current.addEventListener('mouseover', handleMouseOver);
        ref.current.addEventListener('mouseout', handleMouseOut);
      }
    },
    [handleMouseOver, handleMouseOut],
  );

  return [callbackRef, value];
};

export default useHover;
