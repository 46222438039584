import React from "react";
import Dialog from "./Dialog";
import { connect } from "react-redux";
import { fetchDialog, openDialog } from "../../features/messages/operations";
import SearchResults from "./SearchResults";

function DialogsContent({ isSearching, messages, common, staff, openDialog }) {
  return isSearching ? (
    <SearchResults />
  ) : (
    messages.dialogs
      .filter(
        (item) =>
          item.message[item.message.length - 1].sender.id === common.user.id ||
          item.message[item.message.length - 1].reciever.id === common.user.id
      )
      .map((dialog) => {
        let lastMessage = dialog.message[dialog.message.length - 1];
        let { sender, reciever } = lastMessage;
        let amIsenderOrReciever =
          parseInt(sender.id) !== parseInt(common.user.id);
        return (
          <Dialog
            key={Math.random()}
            openDialog={() =>
              openDialog({
                fromSearch: false,
                name: amIsenderOrReciever ? sender.name : reciever.name,
                reciever: amIsenderOrReciever ? sender : reciever,
                sender: amIsenderOrReciever ? reciever : sender,
                id: dialog.key,
              })
            }
            patient={amIsenderOrReciever ? sender.name : reciever.name}
            message={lastMessage}
            user={common.user}
            unReadMessages={dialog.unReadMessages}
            stafferId={amIsenderOrReciever ? sender.id : reciever.id}
            staff={staff}
          />
        );
      })
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    messages: state.messages,
    staff: state.staff,
  };
};

export default connect(mapStateToProps, {
  fetchDialog,
  openDialog,
})(DialogsContent);
