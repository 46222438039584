import { BorderSelect } from '../../pages/MedcardPage/Template/Select';
import countryData from './data';
import React, { useEffect, useState } from 'react';
import TextInput from '../TextInput';
import styled from 'styled-components';
import { getMask } from '../../utils/masks';
import { CancelIcon } from '../Icons';

const PhoneContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 100%;
  .MuiInput-root {
    max-width: 120px;
  }
  .MuiSelect-select {
    padding-right: 10px !important;
  }
`;
const CountryCode = styled.button`
  color: black;
  font-size: 14px;
  min-height: 32px;
  min-width: 24px;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 4px;
  height: 100%;
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: 1px solid #eee;
  border-radius: 4px;
  height: 22px;
`;

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerField?: any;
  onFocus?: () => void;
  onBlur?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (value: string, code: string) => (e?: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  placeholder?: string;
  underlined?: boolean;
  error?: boolean;
}

export function Phone({
  value,
  registerField,
  onFocus,
  onBlur,
  onChange,
  underlined = false,
  placeholder = '',
  error = false,
}: IProps) {
  const [country, setCountry] = useState<string>('ru');
  const [countryCode, setCountryCode] = useState<string>('7');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [visibleSelect, setVisibleSelect] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      const arr = value.split(' ');

      setCountryCode(`${+arr[0]}`);
      setPhoneNumber(`${arr[1]} ${arr[2] || ''}`);
    }
  }, [value]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setCountryCode(countryData.find((d) => d.iso2 === country)!.dialCode);
  }, [country]);

  useEffect(() => {
    onChange(phoneNumber, countryCode)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, phoneNumber]);

  return (
    <PhoneContainer>
      {visibleSelect ? (
        <>
          <BorderSelect
            value={country}
            setValue={(value) => {
              setCountry(value);
              setVisibleSelect(false);
            }}
            items={countryData.map((d) => ({ value: d.iso2, name: `+${d.dialCode} ${d.name}` }))}
          />
          <CloseButton onClick={() => setVisibleSelect(false)}>
            <CancelIcon style={{ width: 12, height: 12 }} />
          </CloseButton>
        </>
      ) : (
        <>
          <CountryCode onClick={() => setVisibleSelect(true)}>+{countryCode}</CountryCode>
          <TextInput
            value={phoneNumber}
            error={error}
            onFocus={onFocus}
            onBlur={(e) => onBlur?.(e)}
            placeholder={placeholder}
            {...registerField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPhoneNumber(getMask('phone').resolve(e.target.value));
            }}
            underlined={underlined}
          />
        </>
      )}
    </PhoneContainer>
  );
}
