import React from 'react';
import { Code, Edit, Name, Row, Value } from '../styles';
import { addNewRowFromSearch, addRowToPrice } from '../operations';
import PriceState from '../state';
import { connect } from 'react-redux';
import EditOptions from './EditOptions';
import NewPriceEntryButton from '../NewPriceEntryButton';
import { DotsIcon } from '../../../uikit/Icons';
function Entry({
  item,
  index,
  itemIndex,
  showDialog,
  length,
  priceSlice,
  showMenuFor,
  startEditingEntry,
  editor,
  onStartEdit,
  onStopEdit,
}) {
  const { canEdit, menuShownFor, animateId } = priceSlice;
  return (
    <Row
      id={item.id}
      key={item.id}
      className={item.id === animateId ? 'new_staffer' : ''}
      colored={!!((parseInt(index) + parseInt(itemIndex)) % 2)}
    >
      <Code>
        <NewPriceEntryButton index={index} itemIndex={itemIndex} item={item} categoryArrayLength={length} />
        {item.code}
      </Code>
      <Name>{item.title}</Name>
      <Value>{item.value}</Value>
      <Edit>
        <DotsIcon
          onClick={() => {
            showMenuFor(menuShownFor === item.id ? null : item.id);
          }}
        />
        <EditOptions
          item={item}
          showMenuFor={showMenuFor}
          menuShownFor={menuShownFor}
          canEdit={canEdit}
          startEditingEntry={startEditingEntry}
          showDialog={showDialog}
          priceSlice={priceSlice}
          editor={editor}
          onStartEdit={onStartEdit}
          onStopEdit={onStopEdit}
        />
      </Edit>
    </Row>
  );
}

export default connect((state: RootState) => ({ priceSlice: state.priceSlice }), {
  showDialog: PriceState.actions.showDialog,
  setAddBtnPosition: PriceState.actions.setAddBtnPosition,
  startEditingEntry: PriceState.actions.startEditingEntry,
  showMenuFor: PriceState.actions.showMenuFor,
  addNewRowFromSearch,
  addRowToPrice,
})(Entry);
