import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -156px;

  background-color: #f6f6fa;
  z-index: 999;
`;

function LoadingScreen() {
  return (
    <Container>
      <div className="spinner" />
    </Container>
  );
}

export default LoadingScreen;
