export function getMessageText(canEdit: boolean, isAllEntriesShown: boolean, categories: object[]) {
  let text = { title: '', body: '' };
  if (canEdit && categories.length === 1) {
    text = {
      title: 'Здесь еще нет категорий прейскуранта',
      body: 'Добавить категорию',
    };
  } else if (canEdit && isAllEntriesShown) {
    text = {
      title: 'Здесь еще не добавили позиции прейскуранта',
      body: 'Для добавления позиций прейскуранта необходимо перейти в соответствующую категорию или создать новую нажав на “+”.',
    };
  } else {
    text = {
      title: 'Здесь еще не добавили позиции прейскуранта',
      body: 'Для добавления позиций прейскуранта необходимо иметь доступ к редактированию прейскуранта.',
    };
  }
  return text;
}
