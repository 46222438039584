import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Select as SelectMUI, MenuItem } from '@mui/material';
import { ISelectProps } from './types';
import { SelectActiveValueCSS, SelectCSS, SelectMouseUpCSS, OptionStyles } from './styles';

const Select = ({ values, placeholder, onChange }: ISelectProps) => {
  const [currentValue, setCurrentValue] = useState<string>('');
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const classes = SelectCSS();
  const classesActive = SelectActiveValueCSS();
  const classesOptions = OptionStyles();

  const updateSelectValue = (e) => {
    onChange && onChange(e.target.value);
    setCurrentValue(e.target.value);
  };

  const updateSelectclasses = () => {
    if (currentValue !== '') {
      return classesActive.root;
    }
    return classes.root;
  };

  return (
    <SelectMUI
      value={currentValue}
      onChange={updateSelectValue}
      displayEmpty
      className={updateSelectclasses()}
      IconComponent={KeyboardArrowDownIcon}
      style={showOptions ? SelectMouseUpCSS : {}}
      onMouseUp={() => setShowOptions(!showOptions)}
    >
      <MenuItem value="" disabled={true} className={classesOptions.root} style={{ display: 'none' }}>
        {placeholder}
      </MenuItem>
      {values.map((value, index) => {
        return (
          <MenuItem key={index} value={value} className={classesOptions.root} disabled={value === currentValue}>
            {value}
          </MenuItem>
        );
      })}
    </SelectMUI>
  );
};

export default Select;
