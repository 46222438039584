import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, CardHeader, ClickAwayListener } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SearchHeader } from '../SearchHeader';
import { TreatmentPlanList } from './TreatmentPlanList';
import { TreatmentPlanPriceList } from './TreatmentPlanPriceList';
import { TreatmentPlanItem } from './TreatmentPlanJournalField';
import request from '../../../../utils/request';
import { useAppSelector } from '../../../../store/hooks';
import { ToothStateType } from '../../Formula/StateMenu';
import { uniqBy, mapKeys } from 'lodash';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'absolute',
      border: '1px solid #D3D6DA',
      boxSizing: 'border-box',
      boxShadow: '0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16)',
      borderRadius: 4,
      marginRight: 16,
      marginBottom: 24,
      marginLeft: 8,
      transition: 'all 500ms ease-in-out',
      width: 'calc(100% - 24px)',
      top: 16,
      zIndex: 1,
    },
    header: {
      background: '#F9F9F9',
      padding: 0,
    },
    headerContent: {
      display: 'flex',
      padding: '0 16px',
      borderBottom: '2px solid #7C8590',
    },
    text: {
      color: '#A8AEB5',
      fontWeight: 'normal',
      fontSize: 14,
      marginRight: 8,
    },
    content: {
      padding: '0 16px 12px 16px',
      '&:last-child': {
        paddingBottom: 12,
      },
    },
    noCompleted: {
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '120%',
      color: '#A8AEB5',
      padding: '20px 14px 10px',
    },
  }),
);

type Props = {
  doctorId: number;
  usedTemplates: TreatmentPlanItem[];
  toothState: ToothStateType;
  onAddTemplate: (template) => void;
  canFocused?: boolean;
  clearFocus: VoidFunction;
};

export type TreatmentPlanTemplate = {
  id: number;
  code: string;
  text: string;
  price: number;
  category: number;
  selected: boolean;
};

function getTemplates(templates, usedTemplates) {
  return templates.map((t) => {
    if (usedTemplates.find((u) => u.code === t.code)) {
      return { ...t, selected: true };
    }
    return t;
  });
}

export function TreatmentPlanTemplates({
  doctorId,
  usedTemplates,
  toothState,
  onAddTemplate,
  canFocused = false,
  clearFocus,
}: Props) {
  const classes = useStyles();

  const [dictionaryTemplates, setDictionaryTemplates] = useState<TreatmentPlanTemplate[]>([]);
  const [templates, setTemplates] = useState<TreatmentPlanTemplate[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<TreatmentPlanTemplate>(null);
  const [category, setCategory] = useState<number>(0);

  const price = useRef<TreatmentPlanTemplate[]>([]);

  const dicTemplates = dictionaryTemplates.filter(
    (d) => !templates.find((t) => t.id === d.id) && (!category || d.category === category),
  );

  const user = useAppSelector((state) => state.common.user);

  useEffect(() => {
    const fetchData = async () => {
      // Для плавности анимации показывать лоадер через секунду
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (!(dataTemplates && dataTemplates.status === 200)) {
          setIsLoading(true);
        }
      }, 1000);

      const dataTemplates = await request.users.healing_plan(doctorId, toothState?.mnemo, searchQuery);
      const dataDicTemplates = searchQuery
        ? await request.clinic.search_price_item({
            ...mapKeys(searchQuery, (_, key) => {
              switch (key) {
                case 'text':
                  return 'title';
                case 'price':
                  return 'value';
                default:
                  return key;
              }
            }),
            clinic: user.clinic.id,
          })
        : await request.clinic.load_price(user.clinic);

      setDictionaryTemplates(
        dataDicTemplates.data.items.map((p) => ({
          id: p.id,
          code: p.code,
          text: p.title,
          price: +p.value,
          category: p.category,
          selected: false,
        })),
      );

      price.current = dataDicTemplates.data.items.map((p) => ({
        id: p.id,
        code: p.code,
        text: p.title,
        price: +p.value,
        category: p.category,
        selected: false,
      }));

      setTemplates(
        dataDicTemplates.data.items.reduce((result, value) => {
          const template = dataTemplates.data.find((d) => value.id === d.price.id);
          if (template) {
            console.log(template);
            result.push({
              ...template.price,
              price: template.price.value,
              text: template.price.title,
              templateId: template.id,
            });
          }
          return result;
        }, []),
      );
      setIsLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleCategoryFilter = (category) => {
    setDictionaryTemplates(category ? price.current.filter((t) => t.category === category) : price.current);
    setCategory(category);
  };

  return (
    <ClickAwayListener onClickAway={() => canFocused && clearFocus()}>
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          title={
            <>
              <div className={classes.headerContent}>
                <SearchHeader
                  type="text"
                  placeholder="Код"
                  name="code"
                  width={100}
                  text="Код"
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  noBorder
                />
                <SearchHeader
                  type="text"
                  placeholder="Наименование"
                  name="text"
                  width={360}
                  text="Наименование"
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  noBorder
                />
                <SearchHeader
                  type="text"
                  placeholder="Цена"
                  name="price"
                  width={132}
                  text="Цена"
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  noBorder
                />
              </div>
            </>
          }
        />
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '397px',
            }}
          >
            <div className="spinner" />
          </div>
        ) : (
          <Scrollbars
            autoHeight
            autoHeightMax={397}
            style={{
              width: '100%',
            }}
          >
            <CardContent className={classes.content}>
              <TreatmentPlanList
                style={{ marginBottom: 20 }}
                title="Шаблон"
                subTitle={toothState?.title}
                templates={getTemplates(templates, usedTemplates)}
                onClickMenu={async (template) => {
                  await request.users.delete_healing_plan(template.templateId);
                  setTemplates(templates.filter((t) => t.id !== template.id));
                  setDictionaryTemplates(uniqBy([...dictionaryTemplates, template], 'id'));
                }}
                onAddTemplate={onAddTemplate}
              />
              <TreatmentPlanPriceList
                title="Прейскурант"
                templates={getTemplates(dicTemplates, usedTemplates)}
                onClickMenu={async (template) => {
                  const addTemplate = await request.users.add_healing_plan(doctorId, toothState?.mnemo, template.id);
                  setTemplates([...templates, { ...template, templateId: addTemplate.data.id }]);
                }}
                onAddTemplate={(template) => onAddTemplate(template)}
                onCategoryFilter={handleCategoryFilter}
              />
            </CardContent>
          </Scrollbars>
        )}
      </Card>
    </ClickAwayListener>
  );
}
