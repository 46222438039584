import React, { useState, useEffect } from 'react';
import { DatePicker } from '../DatePicker';
import { DatePickerWrapper, DateRangePickerWrapper, Space } from './styles';

interface DateRangePickerProps {
  value?: (Date | null | undefined)[];
  onChange?: (value: Date[]) => void;
  onCurrentMonthPick?: (year: number, month: number) => void;
  onCurrentYearPick?: (year: number) => void;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  onChange,
  onCurrentMonthPick,
  onCurrentYearPick,
}) => {
  const [dateRange, setDateRange] = useState(value ?? [null, null]);

  useEffect(() => {
    setDateRange(value);
  }, [value]);

  const handleChange = (range: [Date, Date]): void => {
    if (onChange) {
      onChange(range);
    }
    setDateRange(range);
  };

  return (
    <DateRangePickerWrapper>
      <DatePickerWrapper>
        <DatePicker
          value={dateRange[0]}
          maxDate={dateRange[1]}
          disableFuture
          onDateChange={(newDate) => handleChange([newDate, dateRange[1]])}
          onCurrentMonthPick={onCurrentMonthPick}
          onCurrentYearPick={onCurrentYearPick}
        />
      </DatePickerWrapper>
      <Space>-</Space>
      <DatePickerWrapper>
        <DatePicker
          value={dateRange[1]}
          minDate={dateRange[0]}
          disableFuture
          onDateChange={(newDate) => handleChange([dateRange[0], newDate])}
          onCurrentMonthPick={onCurrentMonthPick}
          onCurrentYearPick={onCurrentYearPick}
        />
      </DatePickerWrapper>
    </DateRangePickerWrapper>
  );
};
