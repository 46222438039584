import React, { useEffect, useRef, useState } from 'react';
import { ToothStateSelect } from './ToothStateSelect';
import styled from 'styled-components';
import { ToothMenuType } from '../../../MedcardPage/Formula/StateMenu';
import { Scrollbars } from 'react-custom-scrollbars-2';
import TextInput from '../../../../uikit/TextInput';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import {
  ToothStateDictionaryItem,
  useAddToothStateMutation,
  useDeleteToothStateMutation,
  useEditToothStateMutation,
  useGetToothStateDictionaryQuery,
} from '../../../../services/dictionaries';
import { Popover } from '@material-ui/core';
import OutlinedButton from '../../../../uikit/Button/OutlinedButton';
import BlueButton from '../../../../uikit/Button/BlueButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const ROW_HEIGHT = 55;

export const StateBlock = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px;
`;

export const ColorState = styled.div<{ color: string }>`
  height: 14px;
  width: 14px;
  background: ${({ color }) => color};
  border-radius: 4px;
  margin-right: 8px;
`;

export const TextState = styled.div<{ color?: string }>`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  color: ${({ color }) => color || '#515D6B'};
`;
//---------------------------------------------
const Tabs = styled.div`
  display: flex;
  flex: 0;
  flex-direction: row;
  position: relative;
  padding: 17px 20px;
`;

const Tab = styled.div<{ isActive: boolean }>`
  position: relative;
  margin-right: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? '#577BF9' : '#515d6b')};

  &:hover {
    color: #577bf9;
  }
`;

const TabBorder = styled.div`
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #577bf9;
`;
//----------------------------------------------------------------------

export const HeaderColumn = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  min-width: ${({ width }) => (width ? `${width}px` : 'inherit')};
  height: ${ROW_HEIGHT - 1}px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  background: #fff;
  padding-left: 20px;
  padding-top: 16px;
  border-bottom: 1px solid #d3d6da;
  border-top: 1px solid #d3d6da;
  border-right: 1px solid #d3d6da;

  &:last-child {
    border-right: 0;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: #fff;
  border-bottom: 1px solid #d3d6da;
`;

export const Column = styled.div<{ editMode?: boolean; width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  min-width: ${({ width }) => (width ? `${width}px` : 'inherit')};
  height: ${ROW_HEIGHT - 0.5}px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: ${({ editMode = false }) => (editMode ? 'center' : 'start')};
  border-right: 1px solid #d3d6da;
  &:last-of-type {
    border-right: 0;
  }
`;

const PickRegion = styled.div`
  position: absolute;
  height: 4px;
  width: 100%;
  background: transparent;
  left: 0;
  z-index: 1;
`;

const Border = styled.div<{ currentIndex: number; y: number }>`
  position: absolute;
  padding: 1px;
  background: #577bf9;
  box-sizing: border-box;
  cursor: pointer;
  top: ${({ currentIndex, y }) => 105 + (currentIndex === 0 ? 0 : y)}px;
  left: -10px;
  width: 1152px;
  z-index: 1;
`;

const Plus = styled.img<{ currentIndex: number; y: number }>`
  position: absolute;
  top: ${({ currentIndex, y }) => 90 + (currentIndex === 0 ? 0 : y)}px;
  left: -40px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  z-index: 2;
`;

const ActionImage = styled.img<{ size: number }>`
  position: absolute;
  top: 16px;
  right: 10px;
  cursor: pointer;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  z-index: 2;
`;

const Container = styled.div`
  position: relative;
  background: white;
  border-radius: 4px;
  width: 1142px;
  padding-bottom: 4px;
  box-sizing: border-box;
`;

const ColumnContent = styled.div`
  margin: 0 20px;
`;

const ModalTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
`;

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      boxShadow: 'none',
      border: '2px solid #eee',
    },
  }),
);

enum EIconType {
  NO_TREATMENT = 'NO_TREATMENT',
  TREATMENT = 'TREATMENT',
  PATHOLOGIC = 'PATHOLOGIC',
  TEMP = 'TEMP',
}

const iconStates = [
  {
    value: EIconType.NO_TREATMENT,
    name: 'Не требует лечения',
    color: '#515D6B',
  },
  {
    value: EIconType.TREATMENT,
    name: 'Завершенное лечение',
    color: '#0D853D',
  },
  {
    value: EIconType.PATHOLOGIC,
    name: 'Патологическое состояние',
    color: '#C3000A',
  },
  {
    value: EIconType.TEMP,
    name: 'Временное состояние',
    color: '#FF8C11',
  },
];

export const getIconState = (value) => iconStates.find((s) => s.value === value) || iconStates[0];

type ToothState = ToothStateDictionaryItem & { isNew: boolean };

export function ToothStateSettings() {
  const classes = useStyles();
  const [tabNumber, setTabNumber] = useState(ToothMenuType.STATE);
  const [tooths, setTooths] = useState<ToothState[]>([]);
  const [treatments, setTreatments] = useState<ToothState[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [y, setY] = useState<number>(0);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [plusVisible, setPlusVisible] = useState<boolean>(true);

  const [validation, setValidation] = useState({
    title_russian: false,
    title_latin: false,
    icon_letter: false,
    icon_type_mnemonic: false,
  });

  const { data, isLoading } = useGetToothStateDictionaryQuery(tabNumber);
  const [addToothState] = useAddToothStateMutation();
  const [editToothState] = useEditToothStateMutation();
  const [deleteToothState] = useDeleteToothStateMutation();
  const theadRef = useRef();

  const toothStates = tabNumber === ToothMenuType.STATE ? tooths : treatments;

  const setToothStates = (data) => {
    return tabNumber === ToothMenuType.STATE ? setTooths(data) : setTreatments(data);
  };

  const [activeIndex, setActiveIndex] = useState<number>(-1);

  useEffect(() => {
    if (data && !toothStates.length) {
      setToothStates(data.map((d) => ({ ...d, isNew: false })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getNextOrder = (nextIndex) => {
    if (toothStates.length) {
      const prevIndex = nextIndex > 0 ? nextIndex - 1 : 0;
      if (nextIndex === 0 && prevIndex === 0) {
        return toothStates[nextIndex].sort_order / 2;
      }
      if (nextIndex === toothStates.length) {
        return toothStates[nextIndex - 1].sort_order + 1;
      }
      return (toothStates[prevIndex].sort_order + toothStates[nextIndex].sort_order) / 2;
    } else {
      return 1.0;
    }
  };

  const handleAdd = (index: number) => {
    const order = Math.trunc(getNextOrder(index));
    toothStates.splice(currentIndex, 0, {
      id: null,
      type: tabNumber,
      sort_order: order,
      mnemonic: '',
      title_russian: '',
      title_latin: '',
      icon_letter: '',
      icon_type_mnemonic: null,
      is_editable: true,
      isNew: true,
    });
    setToothStates([...toothStates]);
    setEditMode(true);
  };

  const handleFieldChange = (index, field) => (e) => {
    const newValue = e.target ? e.target.value : e;
    toothStates.splice(index, 1, {
      ...toothStates[index],
      [field]: newValue,
    });
    setToothStates([...toothStates]);
    setValidation((prev) => ({
      ...prev,
      [field]: !newValue,
    }));
  };

  const handleOKClick = (index) => {
    const currentToothState = toothStates[index];
    setValidation({
      title_russian: !currentToothState.title_russian,
      title_latin: !currentToothState.title_latin,
      icon_letter: !currentToothState.icon_letter,
      icon_type_mnemonic: !currentToothState.icon_type_mnemonic,
    });
    if (
      currentToothState.title_russian &&
      currentToothState.title_latin &&
      currentToothState.icon_letter &&
      currentToothState.icon_type_mnemonic
    ) {
      toothStates.splice(index, 1, {
        ...currentToothState,
        isNew: false,
      });
      setEditMode(false);

      if (currentToothState.id) {
        editToothState(currentToothState);
      } else {
        addToothState(currentToothState);
      }
    }
  };

  const handleDelete = (index) => {
    deleteToothState(toothStates[index].id);
    toothStates.splice(index, 1);
    setToothStates([...toothStates]);
    setEditMode(false);
    setValidation({ title_russian: false, title_latin: false, icon_letter: false, icon_type_mnemonic: false });
  };

  const handleEdit = (index) => {
    setEditMode(true);
    setCurrentIndex(index);
  };

  const handleCancel = (index) => {
    if (toothStates[index].isNew) {
      handleDelete(index);
    } else {
      setEditMode(false);
    }
  };

  const handleTabClick = (tabNumber) => {
    setTabNumber(tabNumber);
    if (toothStates[currentIndex].isNew) {
      handleDelete(currentIndex);
    }
    setEditMode(false);
  };

  return (
    <Container>
      {plusVisible && !editMode && (
        <>
          <Plus
            currentIndex={currentIndex}
            y={y}
            onClick={() => {
              handleAdd(0);
            }}
            src="./img/blue/priceadd.svg"
          />
          <Border currentIndex={currentIndex} y={y} />
        </>
      )}
      <Tabs>
        <Tab
          onClick={() => handleTabClick(ToothMenuType.STATE)}
          isActive={tabNumber === ToothMenuType.STATE}
          className={tabNumber === ToothMenuType.STATE ? 'active-tab' : ''}
        >
          Состояние зуба
          {tabNumber === ToothMenuType.STATE && <TabBorder />}
        </Tab>
        <Tab
          onClick={() => handleTabClick(ToothMenuType.TREATMENT)}
          isActive={tabNumber === ToothMenuType.TREATMENT}
          className={tabNumber === ToothMenuType.TREATMENT ? 'active-tab' : ''}
        >
          Проведенное лечение
          {tabNumber === ToothMenuType.TREATMENT && <TabBorder />}
        </Tab>
      </Tabs>

      <div style={{ position: 'relative' }} ref={theadRef}>
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <HeaderColumn width={310}>Наименование состояния</HeaderColumn>
          <HeaderColumn width={310}>Наименование состояния на латыни</HeaderColumn>
          <HeaderColumn width={128}>Обозначение</HeaderColumn>
          <HeaderColumn width={370}>Цветовое обозначение состояния</HeaderColumn>
        </div>
        <PickRegion
          onMouseEnter={() => {
            if (!editMode) {
              setCurrentIndex(0);
            }
          }}
        />
      </div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: `${ROW_HEIGHT * 7}px`,
          }}
        >
          <div className="spinner" />
        </div>
      ) : (
        <Scrollbars
          autoHeight
          autoHeightMax={ROW_HEIGHT * 7}
          style={{
            width: '100%',
          }}
          onScrollStart={() => setPlusVisible(false)}
          onScrollStop={() => {
            if (!editMode) {
              setCurrentIndex(0);
            }
            setTimeout(() => setPlusVisible(true), 300);
          }}
        >
          <div style={{ position: 'relative' }}>
            {toothStates &&
              toothStates.map((t, index) => (
                <Row key={t.sort_order}>
                  {!editMode && (
                    <PickRegion
                      onMouseEnter={(ev) => {
                        setY(
                          ev.currentTarget.getBoundingClientRect().bottom -
                            theadRef.current.getBoundingClientRect().bottom,
                        );
                        setCurrentIndex(index + 1);
                      }}
                      style={{ bottom: 0 }}
                    />
                  )}
                  <Column width={310} editMode={editMode && index === currentIndex}>
                    {editMode && index === currentIndex ? (
                      validation.title_russian ? (
                        <Tooltip
                          title={'Обязательное поле'}
                          placement={'bottom-start'}
                          disableHoverListener={!validation.title_russian}
                          disableFocusListener={!validation.title_russian}
                          disableTouchListener={!validation.title_russian}
                          enterDelay={500}
                          enterNextDelay={500}
                          dark
                        >
                          <TextInput
                            style={{ height: 32, margin: '0 12px' }}
                            value={t.title_russian}
                            error={validation.title_russian}
                            onChange={handleFieldChange(index, 'title_russian')}
                            autoFocus
                          />
                        </Tooltip>
                      ) : (
                        <TextInput
                          style={{ height: 32, margin: '0 12px' }}
                          value={t.title_russian}
                          error={validation.title_russian}
                          onChange={handleFieldChange(index, 'title_russian')}
                          autoFocus
                        />
                      )
                    ) : (
                      <ColumnContent>{t.title_russian}</ColumnContent>
                    )}
                  </Column>
                  <Column width={310} editMode={editMode && index === currentIndex}>
                    {editMode && index === currentIndex ? (
                      validation.title_latin ? (
                        <Tooltip
                          title={'Обязательное поле'}
                          placement={'bottom-start'}
                          disableHoverListener={!validation.title_latin}
                          disableFocusListener={!validation.title_latin}
                          disableTouchListener={!validation.title_latin}
                          enterDelay={500}
                          enterNextDelay={500}
                          dark
                        >
                          <TextInput
                            style={{ height: 32, margin: '0 12px' }}
                            value={t.title_latin}
                            error={validation.title_latin}
                            onChange={handleFieldChange(index, 'title_latin')}
                            autoFocus
                          />
                        </Tooltip>
                      ) : (
                        <TextInput
                          style={{ height: 32, margin: '0 12px' }}
                          value={t.title_latin}
                          error={validation.title_latin}
                          onChange={handleFieldChange(index, 'title_latin')}
                          autoFocus
                        />
                      )
                    ) : (
                      <ColumnContent>{t.title_latin}</ColumnContent>
                    )}
                  </Column>
                  <Column width={128} editMode={editMode && index === currentIndex}>
                    {editMode && index === currentIndex ? (
                      validation.icon_letter ? (
                        <Tooltip
                          title={'Обязательное поле'}
                          placement={'bottom-start'}
                          disableHoverListener={!validation.icon_letter}
                          disableFocusListener={!validation.icon_letter}
                          disableTouchListener={!validation.icon_letter}
                          enterDelay={500}
                          enterNextDelay={500}
                          dark
                        >
                          <TextInput
                            style={{
                              height: 32,
                              margin: '0 12px',
                              fontWeight: 500,
                              color: getIconState(t.icon_type_mnemonic).color,
                            }}
                            value={t.icon_letter}
                            error={validation.icon_letter}
                            maxLength={2}
                            onChange={handleFieldChange(index, 'icon_letter')}
                            autoFocus
                          />
                        </Tooltip>
                      ) : (
                        <TextInput
                          style={{
                            height: 32,
                            margin: '0 12px',
                            fontWeight: 500,
                            color: getIconState(t.icon_type_mnemonic).color,
                          }}
                          value={t.icon_letter}
                          error={validation.icon_letter}
                          maxLength={2}
                          onChange={handleFieldChange(index, 'icon_letter')}
                          autoFocus
                        />
                      )
                    ) : (
                      <span
                        style={{ color: getIconState(t.icon_type_mnemonic).color, fontWeight: 500, margin: '0 20px' }}
                      >
                        {t.icon_letter}
                      </span>
                    )}
                  </Column>
                  <Column width={374}>
                    {editMode && index === currentIndex ? (
                      <ToothStateSelect
                        value={t.icon_type_mnemonic || ''}
                        items={
                          tabNumber === ToothMenuType.STATE
                            ? iconStates.filter((is) => is.value !== EIconType.TREATMENT)
                            : iconStates.filter(
                                (is) => is.value !== EIconType.NO_TREATMENT && is.value !== EIconType.PATHOLOGIC,
                              )
                        }
                        error={validation.icon_type_mnemonic}
                        setValue={handleFieldChange(index, 'icon_type_mnemonic')}
                      />
                    ) : (
                      <StateBlock>
                        <ColorState color={getIconState(t.icon_type_mnemonic).color} />
                        <TextState>{getIconState(t.icon_type_mnemonic).name}</TextState>
                      </StateBlock>
                    )}
                  </Column>
                  {editMode && index === currentIndex ? (
                    <>
                      <ActionImage
                        size={21}
                        style={{ marginRight: 32 }}
                        src={`./img/${
                          toothStates[index].title_russian &&
                          toothStates[index].title_latin &&
                          toothStates[index].icon_letter &&
                          toothStates[index].icon_type_mnemonic
                            ? 'green'
                            : 'grey'
                        }/check.svg`}
                        onClick={() => handleOKClick(index)}
                      />
                      <ActionImage size={21} src="./img/red/close.svg" onClick={() => handleCancel(index)} />
                    </>
                  ) : (
                    <>
                      <ActionImage
                        size={24}
                        style={{ marginRight: 36 }}
                        src="./img/grey/edit_row.svg"
                        onClick={() => handleEdit(index)}
                      />
                      <ActionImage size={24} src="./img/grey/delete.svg" onClick={() => setActiveIndex(index)} />
                    </>
                  )}
                </Row>
              ))}
          </div>
        </Scrollbars>
      )}
      <Popover
        open={activeIndex > -1}
        anchorEl={theadRef.current}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClose={() => {}}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '12px 16px' }}>
          <ModalTitle>Удалить состояние зуба?</ModalTitle>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12 }}>
            <OutlinedButton
              height={24}
              width={41}
              style={{ marginTop: 0, marginRight: 8, border: '1px solid #D9D9D9' }}
              onClick={() => {
                setActiveIndex(-1);
              }}
            >
              Нет
            </OutlinedButton>
            <BlueButton
              height={24}
              width={35}
              style={{ marginTop: 0 }}
              onClick={() => {
                if (activeIndex > -1) {
                  handleDelete(activeIndex);
                }
                setActiveIndex(-1);
              }}
            >
              Да
            </BlueButton>
          </div>
        </div>
      </Popover>
    </Container>
  );
}
