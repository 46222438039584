import React from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

interface TextInput extends React.ComponentPropsWithoutRef<'input'> {
  error?: boolean;
  transparent?: boolean;
  autoSelect?: boolean;
  textAlign?: string;
  multiline?: boolean;
  underlined?: boolean;
  withPlaceholder?: boolean;
  mask?: string;
}

const BaseInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  outline: none;
  padding-left: 8px;
`;

const DisabledInput = styled(BaseInput)`
  background: #eff0f3;
  border: 1px solid #d3d6da;
  border-radius: 4px;
  color: #a8aeb5;
  pointer-events: none;
  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #a8aeb5;
  }
`;

export const InputUnderlined = styled(BaseInput)`
  border: none;
  background: transparent;
  border-bottom: 1px solid #d3d6da;
  color: #515d6b;
  &:hover {
    border: none;
    border-bottom: 1px solid #577bf9;
  }
  &:focus {
    border: none;
    border-bottom: 1px solid #4555e4;
  }
  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #a8aeb5;
  }
`;

const TransparentInput = styled(BaseInput)`
  border: none;
  background: transparent;
  color: #515d6b;
`;
type InputWithBorderProps = {
  error: boolean;
  textAlign: string;
};
export const InputWithBorder = styled(BaseInput)<InputWithBorderProps>`
  text-align: ${({ textAlign }) => textAlign};
  background: #ffffff;
  border: ${({ error }) => (error ? '1px solid #C3000A' : '1px solid #d3d6da')};
  box-shadow: ${({ error }) => (error ? '0px 0px 4px rgba(245, 34, 45, 0.5)' : 'none')};
  border-radius: 4px;
  color: #515d6b;
  &:hover {
    border: ${({ error }) => (error ? '1px solid #C3000A' : '1px solid #577bf9')};
  }
  &:focus {
    border: ${({ error }) => (error ? '1px solid #C3000A' : '1px solid #4555e4')};
    box-shadow: ${({ error }) =>
      error ? '0px 0px 4px rgba(245, 34, 45, 0.5)' : '0px 0px 4px rgba(87, 123, 249, 0.5)'};
  }
  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #a8aeb5;
  }
`;

interface MaskedInputProps extends TextInput {
  inputComponent: React.ReactElement;
}

export const MaskedInput: React.FC<MaskedInputProps> = ({ inputComponent, ...restProps }) => (
  <InputMask {...restProps}>{() => inputComponent}</InputMask>
);

// eslint-disable-next-line react/display-name
export default React.forwardRef<HTMLInputElement, TextInput>((props, ref) => {
  const { autoSelect, error, textAlign, transparent, underlined, disabled, mask } = props;

  let input;
  switch (true) {
    case transparent:
      input = (
        <TransparentInput
          autoComplete="off"
          ref={ref}
          {...props}
          autoFocus={autoSelect}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onFocus={(e: any) => {
            autoSelect && e.target.select();
          }}
        />
      );
      break;
    case underlined:
      input = (
        <InputUnderlined
          ref={ref}
          autoComplete="off"
          autoFocus={autoSelect}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onFocus={(e: any) => {
            autoSelect && e.target.select();
          }}
          {...props}
        />
      );
      break;
    case disabled:
      input = <DisabledInput ref={ref} autoComplete="off" {...props} />;
      break;
    default:
      input = (
        <InputWithBorder
          error={error}
          ref={ref}
          textAlign={textAlign}
          autoComplete="off"
          autoFocus={autoSelect}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onFocus={(e: any) => {
            autoSelect && e.target.select();
          }}
          {...props}
        />
      );
  }
  return mask ? <MaskedInput inputComponent={input} {...props} /> : input;
});
