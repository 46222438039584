import styled from 'styled-components';

export const LayoutHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 56px;
  padding-right: 70px;
`;

export const BackButton = styled.img`
  width: 17px;
  margin-left: 20px;
  margin-right: 35px;
`;

export const Title = styled.div`
  letter-spacing: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #253446;
`;

export const ChatContainer = styled.div<{isExpanded: boolean}>`
  transform: translateX(${({ isExpanded }) => (isExpanded ? '-200px' : '0px')});
  transition: 0.25s;
`;

export const LoadPrintBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;