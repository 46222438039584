import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Switch from '../../../uikit/Switch';
import OutlinedButton from '../../../uikit/Button/OutlinedButton';
import BlueButton from '../../../uikit/Button/BlueButton';
import medCardPageSlice from '../../../pages/MedcardPage/state';
import { RootState } from '../../../store';

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px 15px 28px;
  background: white;
  flex: 1;
`;

type FooterOperationsProps = {
  onSetAccountVisible: (condition: boolean) => void;
  handleSetIsPrepayWarning?: (condition: boolean) => void;
  overPayAmount?: number;
  onCalculateInvoice: (print: boolean) => void;
  onStopEdit: VoidFunction;
  calculateLoading: boolean;
};

export const FooterActions: FC<FooterOperationsProps> = ({
  onSetAccountVisible,
  handleSetIsPrepayWarning,
  overPayAmount,
  onCalculateInvoice,
  onStopEdit,
  calculateLoading,
}) => {
  const dispatch = useDispatch();

  const [print, setPrint] = useState<boolean>(true);

  const showPayment = useSelector((state: RootState) => state.medCardPageSlice.showPayment);
  const { setPaymentVisible } = medCardPageSlice.actions;

  const handleCalculateAccount = () => {
    if (overPayAmount > 0) {
      handleSetIsPrepayWarning(true);
      return;
    }
    onCalculateInvoice(print);
  };
  const handleClosePaymentModal = () => {
    if (showPayment) {
      dispatch(setPaymentVisible(false));
    } else {
      onSetAccountVisible(false);
    }
    onStopEdit?.();
  };

  return (
    <FooterContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Switch checked={print} onClick={() => setPrint(!print)} />

        <div style={{ color: '#253446', fontSize: 16, fontWeight: 'normal' }}>Распечатать счет</div>
      </div>
      <div style={{ display: 'flex' }}>
        <OutlinedButton
          width={178}
          height={32}
          style={{ marginRight: 16, marginTop: 0 }}
          onClick={handleClosePaymentModal}
          disabled={calculateLoading}
        >
          Вернуться в медкарту
        </OutlinedButton>

        <BlueButton
          width={108}
          height={32}
          style={{ marginTop: 0 }}
          onClick={handleCalculateAccount}
          disabled={calculateLoading}
        >
          Рассчитать
        </BlueButton>
      </div>
    </FooterContainer>
  );
};
