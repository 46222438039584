/* eslint-disable @typescript-eslint/no-explicit-any */
import { compose, filter, head, map, omit, toPairs } from 'ramda';

const fontStyle = 'normal 16px Roboto';

export const findEmptyKeys = compose<object, Array<Array<any>>, Array<Array<any>>, Array<any>>(
  map(head),
  filter((entry: Array<any>) => entry[1] === ''),
  toPairs,
);
export const omitEmptyKeys = (obj: object): object => omit(findEmptyKeys(obj), obj);

export function getTextWidth(text: string, font: string = fontStyle): number {
  const canvas = document.createElement('canvas') as HTMLCanvasElement;
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}
