import React from 'react';
import styled from 'styled-components';
import Chat from '../Chat/Chat.js';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 56px;
  padding-right: 80px;
  position: fixed;
  width: 100%;
  background: #F9F9F9;
  z-index: 50;

  &:after {
    content: '';
    position: absolute;
    left: -50px;
    top: 0;
    width: 50px;
    height: 56px;
    background: #F9F9F9;
`;

const Title = styled.div`
  letter-spacing: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #253446;
  margin-right: auto;
`;

const BackButton = styled.img`
  width: 17px;
  margin-left: 20px;
  margin-right: 35px;
`;
const ChatContainer = styled.div`
  transform: translateX(${({ isExpanded }) => (isExpanded ? '-200px' : '0px')});
  transition: 0.25s;
`;

function Header({ drawerState: { isExpanded }, text }) {
  const pageTitle = `Медицинская карта - ${text}`;
  const navigate = useNavigate();
  return (
    <Layout>
      <BackButton src="./img/black/go-back.svg" onClick={() => navigate(-1)} />
      <Title>{pageTitle}</Title>
      <ChatContainer isExpanded={isExpanded}>
        <Chat />
      </ChatContainer>
    </Layout>
  );
}

export default connect(
  (state) => ({
    drawerState: state.drawerState,
  }),
  {},
)(Header);
