export const STAFF_COLORS = {
  '#FAE6F8': [],
  '#FBE6DE': [],
  '#DEF0FB': [],
  '#F6FBDE': [],
  '#E5FBDE': [],
  '#D3DFF5': [],
  '#DFE9DF': [],
  '#F2DCE0': [],
  '#EEDCEA': [],
  '#E3E3F7': [],
  '#F6D7C2': [],
  '#FBDCD8': [],
  '#D0E7F8': [],
  '#EBEFFE': [],
  '#D7F0EF': [],
  '#C4F1DF': [],
  '#DAEED6': [],
  '#DFE5BE': [],
  '#E9E5CF': [],
  '#E3D9CE': [],
  '#F7F0E6': [],
  '#F3EAE8': [],
  '#E0E0E2': [],
  '#D9DDDF': [],
  '#FFEDED': [],
  '#FFE8BE': [],
  '#DCF8FC': [],
  '#E1FCE2': [],
  '#FDE6F7': [],
  '#E9FDFA': [],
  '#D3E1E3': [],
  '#E8DBDB': [],
  '#FDF3D2': [],
  '#E4DBDB': [],
  '#FFD6D6': [],
};

export const TIME_LINE = [
  '9:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
];

export const WEEK_DAY_NAMES = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

export const WEEK_DAY_NAMES_PLURAL = [
  'Воскресенья',
  'Понедельники',
  'Вторники',
  'Среды',
  'Четверги',
  'Пятницы',
  'Субботы',
];
