import { useCallback, useState } from 'react';

/**
 * Хук для хранения boolean-флагов и удобного их переключения
 */

// Использование:
// function App() {
//   const [isTextChanged, setIsTextChanged] = useToggle();
//   return (
//     <button onClick={setIsTextChanged}>{isTextChanged ? 'isTextChanged равен true' : 'isTextChanged равен false'}</button>
//    );
// }

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState((state) => !state), []);
  return [state, toggle];
};

export default useToggle;
