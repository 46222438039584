import styled from 'styled-components';
import { styled as styledMui } from '@material-ui/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FCWidthWrapperType, SessionSpanType } from './types';
import { DeleteIcon } from '../../../../../../uikit/Icons';

export const SchedulerStyleWrapper = styled.div`
  margin-top: -20px;
  height: calc(100vh - 173px);
  overflow: auto;

  #FCWidthWrapper
    > div
    > div.fc-view-harness.fc-view-harness-active
    > div.fc-view.fc-timegrid
    > table
    > thead
    > tr
    > th
    > div
    > div
    > table,
  #FCWidthWrapper
    > div
    > div.fc-view-harness.fc-view-harness-active
    > div.fc-view.fc-timegrid
    > table
    > tbody
    > tr
    > td
    > div
    > div
    > div
    > div.fc-timegrid-slots
    > table,
  #FCWidthWrapper
    > div
    > div.fc-view-harness.fc-view-harness-active
    > div.fc-view.fc-timegrid
    > table
    > tbody
    > tr
    > td
    > div
    > div
    > div
    > div.fc-timegrid-cols
    > table,
  #FCWidthWrapper
    > div
    > div.fc-view-harness.fc-view-harness-active
    > div.fc-view.fc-timegrid
    > table
    > tbody
    > tr
    > td
    > div
    > div
    > div {
    width: 100% !important;
  }

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: #e5e6ea;
    margin-top: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a8aeb5;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-corner {
    background-color: #e5e6ea;
  }
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    margin-bottom: 0 !important;
  }
  .fc-timegrid.fc-displayByDay-view.fc-view > table > thead > tr > th > div > div > table > thead > tr:nth-child(1),
  .fc-timegrid.fc-displayByPeriod-view.fc-view > table > thead > tr > th > div > div > table > thead > tr:nth-child(1),
  .fc-license-message,
  .custom_hidden {
    display: none;
  }
  .fc-media-screen .fc-timegrid-cols {
    border-bottom: 1px solid #ddd;
  }
  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    font-size: 10px;
    color: #878f99;
  }
  .fc-col-header-cell-cushion {
    display: block;
    width: 100%;
    padding: 0 !important;
  }

  .fc-timegrid.fc-displayByDay-view.fc-view > table > thead > tr > th > div > div > table > colgroup > col,
  .fc-timegrid.fc-displayByPeriod-view.fc-view > table > thead > tr > th > div > div > table > colgroup > col,
  .fc-timegrid-slots > table > colgroup > col,
  .fc-timegrid-cols > table > colgroup > col {
    width: 52px !important;
    position: sticky !important;
  }
  .fc-timegrid-axis-frame::before {
    content: '';
    width: 52px;
    height: 32px;
    position: absolute;
    top: 21px;
    left: 0;
    background-image: url('/img/grey/clock.svg');
    background-repeat: no-repeat;
    background-position: center center;
    border-bottom: 1px solid #d3d6da;
  }
  .fc-day-today {
    background-color: inherit !important;
  }

  .fc .fc-scrollgrid table {
    border-right-style: unset;
  }

  .fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink,
  .fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-minor,
  .fc-timegrid-axis {
    vertical-align: baseline;
    border: none !important;
  }
  .fc-scrollgrid.fc-scrollgrid-liquid {
    border-left: none !important;
    border-top: none !important;
  }
  .fc-timegrid-slot-label-frame.fc-scrollgrid-shrink-frame {
    text-align: center;
    margin-top: -5px;
  }
  .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    font-size: 14px;
    font-weight: 500;
    color: #7c8590;
    padding: 0 !important;
  }
  .fc-timegrid-slots > table > tbody > tr {
    height: 32px;
  }
  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    box-shadow: none !important;
  }
  .fc-timegrid-event {
    position: absolute;
    left: -2px;
    right: -4px;
    border-radius: 0px;
    margin-bottom: 0px;
    border: none !important;
    background-color: unset !important;
  }
  .fc-event-main {
    position: relative;
    top: 1px;
    padding: 0 !important;
  }
  .fc-timegrid-col-frame > div:nth-child(3) > div,
  .fc-timegrid-col-frame > div:nth-child(1) > div,
  .fc-timegrid-bg-harness,
  .fc-highlight,
  .fc-timegrid-col-bg {
    transition: all 0.5s;
  }
  .fc-highlight {
    background-color: rgba(51, 50, 255, 0.3) !important;
  }
  .fc-scroller.fc-scroller-liquid-absolute {
    overflow: visible !important;
  }
  // Базовые настройки рамок для выделения стобцов
  th.custom_highlighted > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion,
  td.custom_highlighted > div.fc-timegrid-col-frame,
  th.custom_highlighted_last > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion,
  td.custom_highlighted_last > div.fc-timegrid-col-frame {
    position: relative;
  }
  th.custom_highlighted > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::after,
  td.custom_highlighted > div.fc-timegrid-col-frame::after,
  th.custom_highlighted > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::before,
  td.custom_highlighted > div.fc-timegrid-col-frame::before,
  th.custom_highlighted_last > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::before,
  td.custom_highlighted_last > div.fc-timegrid-col-frame::after,
  td.custom_highlighted_last > div.fc-timegrid-col-frame::before {
    content: '';
    position: absolute;
    filter: drop-shadow(rgba(87, 123, 249, 0.5) 0px 0px 4px);
    z-index: 10;
  }
  th.custom_highlighted > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::after,
  td.custom_highlighted > div.fc-timegrid-col-frame::after,
  td.custom_highlighted_last > div.fc-timegrid-col-frame::after {
    right: -1px;
  }
  th.custom_highlighted > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::before,
  td.custom_highlighted > div.fc-timegrid-col-frame::before,
  td.custom_highlighted_last > div.fc-timegrid-col-frame::before,
  th.custom_highlighted_last > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::before {
    left: -1px;
  }
  td.custom_highlighted > div.fc-timegrid-col-frame::after,
  td.custom_highlighted > div.fc-timegrid-col-frame::before,
  td.custom_highlighted_last > div.fc-timegrid-col-frame::after,
  td.custom_highlighted_last > div.fc-timegrid-col-frame::before {
    top: 0px;
    width: 1px;
    height: 100%;
    background-color: rgb(87, 123, 249);
  }
  th.custom_highlighted > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::before,
  th.custom_highlighted_last > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::before {
    top: 0;
    width: calc(100% + 2px);
    height: calc(100% + 1px);
    border: 1px solid rgb(87, 123, 249);
    border-bottom: unset;
  }
  // Если выделенный столбец не единственный, но первый - у него не должно быть правой границы
  th:not(.custom_highlighted)
    + th.custom_highlighted
    > div.fc-scrollgrid-sync-inner
    > span.fc-col-header-cell-cushion::before {
    border-right: unset;
  }
  td:not(.custom_highlighted) + td.custom_highlighted > div.fc-timegrid-col-frame::after {
    content: unset;
  }
  // Если выделенный столбец не первый, то у него не должно быть левой и правой границы
  th.custom_highlighted
    + th.custom_highlighted
    > div.fc-scrollgrid-sync-inner
    > span.fc-col-header-cell-cushion::before {
    border-left: unset;
    border-right: unset;
  }
  td.custom_highlighted + td.custom_highlighted > div.fc-timegrid-col-frame::before,
  td.custom_highlighted + td.custom_highlighted > div.fc-timegrid-col-frame::after {
    content: unset;
  }
  // Если выделенный столбец последний из всей группы, у него должна быть граница справа
  th.custom_highlighted
    + th.custom_highlighted_last
    > div.fc-scrollgrid-sync-inner
    > span.fc-col-header-cell-cushion::before {
    border-left: unset;
  }
  td.custom_highlighted + td.custom_highlighted_last > div.fc-timegrid-col-frame::before {
    content: unset;
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid #ddd;
  }

  .fc-view-harness.fc-view-harness-active > div.fc-timegrid.fc-displayByDay-view.fc-view > table > tbody > tr > td,
  .fc-view-harness.fc-view-harness-active > div.fc-timegrid.fc-displayByPeriod-view.fc-view > table > tbody > tr > td {
    border: none;
  }
  .fc-view-harness.fc-view-harness-active > div.fc-timegrid.fc-displayByDay-view.fc-view > table > thead > tr > th,
  .fc-view-harness.fc-view-harness-active > div.fc-timegrid.fc-displayByPeriod-view.fc-view > table > thead > tr > th {
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-right: none;
  }
  .fc-view-harness.fc-view-harness-active
    > div.fc-timegrid.fc-displayByDay-view.fc-view
    > table
    > thead
    > tr
    > th
    > div
    > div
    > table
    > thead
    > tr:nth-child(2),
  .fc-view-harness.fc-view-harness-active
    > div.fc-timegrid.fc-displayByPeriod-view.fc-view
    > table
    > thead
    > tr
    > th
    > div
    > div
    > table
    > thead
    > tr:nth-child(2) {
    background-color: #f9f9f9;
  }
  div.fc-view-harness.fc-view-harness-active
    > div.fc-timegrid.fc-displayByDay-view.fc-view
    > table
    > thead
    > tr
    > th
    > div
    > div,
  div.fc-view-harness.fc-view-harness-active
    > div.fc-timegrid.fc-displayByPeriod-view.fc-view
    > table
    > thead
    > tr
    > th
    > div
    > div {
    padding-top: 20px;
    background-color: #f9f9f9;
  }
  div.fc-view-harness.fc-view-harness-active
    > div.fc-timegrid.fc-displayByDay-view.fc-view
    > table
    > thead
    > tr
    > th
    > div
    > div
    > table,
  div.fc-view-harness.fc-view-harness-active
    > div.fc-timegrid.fc-displayByPeriod-view.fc-view
    > table
    > thead
    > tr
    > th
    > div
    > div
    > table {
    border-top: 1px solid #ddd;
  }
  .fc-view-harness.fc-view-harness-active
    > div.fc-timegrid.fc-displayByDay-view.fc-view
    > table
    > tbody
    > tr
    > td
    > div,
  .fc-view-harness.fc-view-harness-active
    > div.fc-timegrid.fc-displayByPeriod-view.fc-view
    > table
    > tbody
    > tr
    > td
    > div {
    height: calc(100% + 20px);
  }
`;
export const TableUpperCell = styled.div`
  position: relative;
  height: 32px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d3d6da;

  &:last-child {
    border-bottom: none;
  }
`;
export const FCWidthWrapper = styled.div<FCWidthWrapperType>`
  width: ${(props) => (props.width ? `${props.width}px` : 'unset')};
`;
export const TableUpperSpan = styled.span`
  font-weight: 400;
  color: #253446;
`;
export const CabinetSpan = styled(TableUpperSpan)`
  font-size: 14px;
`;
export const SessionWrapper = styled.div<{ bc: string }>`
  width: calc(100% - 1px);
  height: 100% !important;
  padding: 0 0 0 4px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${(props) => (props.bc ? `${props.bc}ff` : ' rgba(51, 170, 51, 0.2)')};
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: repeating-linear-gradient(#ffffff00, #ffffff00 31px, #d3d6da 32px);
  }
`;
export const SessionSpan = styled.span<SessionSpanType>`
  color: #878f99;
  font-weight: 400;
  font-size: ${(props) => (props.fz ? `${props.fz}px` : 'unset')};
`;
export const SDeleteIcon = styled(DeleteIcon)`
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
`;
export const ScheduleViewControls = styledMui(RadioGroup)({
  position: 'absolute',
  top: '-50px',
  right: `25px`,
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'baseline',
  '& .MuiTypography-root': {
    fontSize: '12px',
    fontWeight: '400',
    color: '#515D6B',
  },
  '& .MuiFormControlLabel-root': {
    margin: 0,
    padding: 0,
  },
});
export const CustomRadio = styledMui(Radio)({
  padding: 0,
  paddingRight: 4,
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      fill: '#577BF9',
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiSvgIcon-root': {
    width: '14px',
    height: '14px',
    fill: '#D3D6DA',
  },
});
export const MatchesSwitcher = styled.div<{ positionX: number }>`
  position: absolute;
  top: -16px;
  left: ${(props) => (props.positionX ? props.positionX : 0)}px;
  padding: 0 8px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: #577bf9;
  z-index: 11;

  & > span {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    margin: 5px;
    white-space: nowrap;
  }
`;
export const SessionTooltip = styled.div<{ isBelow: boolean }>`
  width: 156px;
  height: 74px;
  position: absolute;
  top: ${(props) => (props.isBelow ? '34px' : '-84px')};
  right: -8px;
  background: #ffffff;
  border: 1px solid #e5e6ea;
  border-radius: 2px;
  padding: 5px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    ${(props) => (props.isBelow ? 'top: -8px' : 'bottom: -8px')};
    ${(props) => (props.isBelow ? 'transform: rotate(180deg)' : '')};
    right: 13px;

    border: 6px solid transparent;
    border-top: 8px solid #ffffff;
    border-bottom: 0 solid #ffffff;

    z-index: 2;
  }
`;
export const SessionTooltipSpan = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #515d6b;
  display: flex;
  flex-direction: column;
  cursor: auto;
`;
export const SessionTooltipDeleteAnchor = styled(SessionTooltipSpan)`
  color: #c3000a;
  text-decoration: underline;
  cursor: pointer;
`;
