import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { PriceMenu } from '../styles';
import Item from '../Categories/EditCategory/Item';
import { deletePriceEntry } from '../operations';
import { connect } from 'react-redux';

function EditOptions({
  item,
  menuShownFor,
  showMenuFor,
  canEdit,
  startEditingEntry,
  showDialog,
  priceSlice,
  deletePriceEntry,
  editor,
  onStartEdit,
  onStopEdit,
}) {
  return (
    <>
      {menuShownFor === item.id && (
        <ClickAwayListener onClickAway={() => showMenuFor(null)}>
          <PriceMenu>
            <Item
              disabled={!canEdit}
              onClick={() => {
                if (!!editor) {
                  showMenuFor(null);
                  return;
                }
                startEditingEntry(item.id);
                onStartEdit(item.id);
              }}
              text="Редактировать"
              editor={editor}
            />
            <Item
              disabled={!canEdit}
              onClick={() => {
                if (!!editor) {
                  showMenuFor(null);
                  return;
                }
                onStartEdit(item.id);
                showDialog({
                  isShown: true,
                  type: 'single',
                  category: {
                    id: priceSlice.pickedCategory.id,
                    name: item.title,
                  },
                  entry: item,
                });
              }}
              text="Изменить цену"
              editor={editor}
            />
            <Item
              disabled={!canEdit}
              onClick={() => {
                if (!!editor) {
                  showMenuFor(null);
                  return;
                }
                onStartEdit(item.id);
                deletePriceEntry(item.id, () => onStopEdit(item.id));
              }}
              text="Удалить"
              editor={editor}
            />
          </PriceMenu>
        </ClickAwayListener>
      )}
    </>
  );
}

export default connect(null, {
  deletePriceEntry,
})(EditOptions);
