import React, { FC } from 'react';

const VisitOverIcon: FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z"
        fill="#0D853D"
      />
      <path
        d="M8 2.1875C4.79063 2.1875 2.1875 4.79063 2.1875 8C2.1875 11.2094 4.79063 13.8125 8 13.8125C11.2094 13.8125 13.8125 11.2094 13.8125 8C13.8125 4.79063 11.2094 2.1875 8 2.1875ZM11.0219 5.71406L7.73125 10.2766C7.68526 10.3408 7.62463 10.3931 7.55439 10.4291C7.48414 10.4652 7.40631 10.4841 7.32734 10.4841C7.24838 10.4841 7.17054 10.4652 7.1003 10.4291C7.03006 10.3931 6.96943 10.3408 6.92344 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97187 7.37813 6.12187 7.45625 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59219 10.0297 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0219 5.71406Z"
        fill="white"
      />
      <path
        d="M10.9221 5.51562H10.1893C10.0299 5.51562 9.87834 5.59219 9.78459 5.72344L7.32833 9.12969L6.21583 7.58594C6.12208 7.45625 5.97208 7.37813 5.81114 7.37813H5.07832C4.97676 7.37813 4.91739 7.49375 4.97676 7.57656L6.92364 10.2766C6.96963 10.3408 7.03027 10.3931 7.10051 10.4291C7.17075 10.4652 7.24858 10.4841 7.32755 10.4841C7.40652 10.4841 7.48435 10.4652 7.55459 10.4291C7.62484 10.3931 7.68546 10.3408 7.73146 10.2766L11.0221 5.71406C11.083 5.63125 11.0237 5.51562 10.9221 5.51562Z"
        fill="#0D853D"
      />
    </svg>
  );
};

export default VisitOverIcon;
