import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-named-as-default
import Container from './Container';

type BlueButtonProps = {
  width: number;
  disabled: boolean;
};
const BlueButton = styled.div<BlueButtonProps>`
  margin-top: 24px;
  margin-left: 0px;
  border-radius: 4px;
  background: ${({ disabled }) => (disabled ? '#EFF0F3' : '#577bf9')};
  color: ${({ disabled }) => (disabled ? '#A8AEB5' : '#ffffff')};
  border: ${({ disabled }) => (disabled ? '1px solid #D3D6DA' : '1px solid white')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
`;

// eslint-disable-next-line react/display-name
export default function ({ children, ...props }) {
  return (
    <BlueButton disabled={props.disabled} {...props}>
      <Container height={props.height} width={props.width}>
        {children}
      </Container>
    </BlueButton>
  );
}
