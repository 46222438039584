import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import OutlinedButton from '../../../uikit/Button/OutlinedButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Column, Table } from '../../../components/Table';
import BlueButton from '../../../uikit/Button/BlueButton';
import { PaymentTypeSelect } from './PaymentTypeSelect';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { PaymentCellContainer } from './PaymentCellContainer';
import { PaymentPaidCell } from './PaymentPaidCell';
import { PaymentRemainderCell } from './PaymentRemainderCell';
import { PaymentRefundCell } from './PaymentRefundCell';
import { PaymentPayerCell } from './PaymentPayerCell';
import {
  Advance,
  AdvanceOperation,
  ExpandedAdvance,
  useAddAdvanceMutation,
  useGetAdvancesQuery,
} from '../../../services/advance';
import {
  PayerCodes,
  PaymentTypeCodes,
  useAddPayerMutation,
  useGetPayersQuery,
  useGetPaymentTypesQuery,
} from '../../../services/dictionaries';
import request from '../../../utils/request';
import { printPdf } from '../../../utils/print';

const Container = styled.div`
  min-height: 168px;
  width: 1012px;
  background: #ffffff;
  padding: 24px 14px 24px 24px;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
`;
const TableBox = styled.div`
  margin: 20px 0;
  table {
    font-size: 12px;
    th,
    td {
      padding: 12px 16px;
    }
    td {
      input {
        font-size: 12px;
      }
    }
  }
`;
const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type paymentsProps = {
  handleSetOpenForm: () => void;
  currentBalance: number;
  patientId: number;
};
export const PrepayInfo: FC<paymentsProps> = ({ handleSetOpenForm, currentBalance, patientId }) => {
  const [isActivePaymentActions, setIsActivePaymentActions] = useState<boolean>(false);
  const [isActiveRefundActions, setIsActiveRefundActions] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<number>(null);
  const [isPaymentFilled, setIsPaymentFilled] = useState<boolean>(false);
  const [isRefundFilled, setIsRefundFilled] = useState<boolean>(false);
  const [isPayerFilled, setIsPayerFilled] = useState<boolean>(true);
  const [isBalanceChanged, setIsBalanceChanged] = useState<boolean>(false);
  const [showRefundResult, setShowRefundResult] = useState<boolean>(false);
  const [paymentData, setPaymentData] = useState<ExpandedAdvance[]>([]);
  const user = useSelector((state: RootState) => state.common.user);
  const payer = useRef(null);
  const refund = useRef(null);
  const paid = useRef(null);

  const { data: advances = [] } = useGetAdvancesQuery(patientId);
  const { data: paymentTypes = [] } = useGetPaymentTypesQuery();
  const { data: payers = [] } = useGetPayersQuery();

  const [addAdvance] = useAddAdvanceMutation();
  const [addPayer] = useAddPayerMutation();

  const defaultPayer = payers.find((payer) => payer.code === PayerCodes.PATIENT);
  const baseAmountValue = 0;

  const paymentDataWithoutFirstItem = useMemo(() => {
    return paymentData.filter((paymentItem, index) => index !== 0);
  }, [paymentData]);

  const newPaymentRow = {
    id: Math.floor(Math.random() * 100) + 1,
    operation_date: new Date(),
    operation_type: AdvanceOperation.INCOME,
    payment: null,
    patient: patientId,
    payment_type: null,
    payer: defaultPayer,
    paymaster: user,
    remainder: paymentData[0]?.remainder || baseAmountValue,
  };

  const handleAddPayment = () => {
    setIsActivePaymentActions(true);
    setPaymentData([newPaymentRow, ...paymentData]);
    if (isBalanceChanged) {
      setIsBalanceChanged(false);
    }
    if (isActiveRefundActions) {
      setIsActiveRefundActions(false);
    }
    if (showRefundResult) {
      setShowRefundResult(false);
    }
  };
  const handleAddRefund = () => {
    setIsActiveRefundActions(true);
    if (isBalanceChanged) {
      setIsBalanceChanged(false);
    }
    if (showRefundResult) {
      setShowRefundResult(false);
    }
  };

  const handleCancelCalculations = () => {
    if (isActiveRefundActions) {
      setIsActiveRefundActions(false);
    } else {
      setPaymentData(paymentDataWithoutFirstItem);
      if (isActivePaymentActions) {
        setIsActivePaymentActions(false);
      }
    }
  };
  const getCurrentPayerType = () => {
    const currentPaymentType = paymentTypes.find((type) => type.id === paymentType)?.code;
    const individualTypes = new Set([
      `${PaymentTypeCodes.BANK}`,
      `${PaymentTypeCodes.CARD}`,
      `${PaymentTypeCodes.CASH}`,
    ]);
    const insuranceTypes = new Set([`${PaymentTypeCodes.INS_REQ}`, `${PaymentTypeCodes.INS_VOL}`]);
    if (!currentPaymentType) return '';
    if (currentPaymentType === PaymentTypeCodes.PREPAID) return PayerCodes.PATIENT;
    if (individualTypes.has(currentPaymentType)) return PayerCodes.INDIVIDUAL;
    if (insuranceTypes.has(currentPaymentType)) return PayerCodes.INSURANCE;
  };

  const addNewAdvance = async (name: string, newAdvance: Partial<Advance>) => {
    const existedPayer = payers.find((payer) => payer.title === name);
    if (!existedPayer) {
      const newPayer = await addPayer({ title: name, payer_type: getCurrentPayerType() });
      addAdvance({ patientId, record: { ...newAdvance, payer: newPayer.data.id } });
      return;
    }
    await addAdvance({ patientId, record: { ...newAdvance, payer: existedPayer.id } });
  };

  const handleFinishOperations = () => {
    const payerName = payer.current && payer.current.value;
    const paidAmount = paid.current && paid.current.value;
    const refundAmount = refund.current && refund.current.value;

    const newIncomeAdvance = {
      operation_type: AdvanceOperation.INCOME,
      payment: paidAmount,
      patient: patientId,
      payment_type: paymentType,
      payer: defaultPayer.id,
      paymaster: user.id,
    };
    const newRefundRow = {
      operation_type: AdvanceOperation.RETURN,
      payment: refundAmount,
      patient: patientId,
      payment_type: paymentType,
      payer: defaultPayer.id,
      paymaster: user.id,
    };
    if (isActivePaymentActions) {
      addNewAdvance(payerName, newIncomeAdvance).then(() => {
        setIsActivePaymentActions(false);
        setIsBalanceChanged(true);
      });
    }
    if (isActiveRefundActions) {
      addAdvance({ patientId, record: newRefundRow })
        .unwrap()
        .then(() => {
          setShowRefundResult(true);
          setIsBalanceChanged(true);
        });
    }
    if (paymentType) {
      setPaymentType(null);
    }
  };
  const renderRowWithEmptySubCell = (rowIndex, value) => {
    return (
      <>
        <div>{value}</div>
        {rowIndex === 0 && isActiveRefundActions && <div style={{ paddingTop: 46 }} />}
      </>
    );
  };

  const printAdvances = async () => {
    const result = await request.clinic.print_advances(patientId);
    printPdf(result.data);
  };

  const columns: Column[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Дата',
        accessor: 'operation_date',
        disableFilters: true,
        width: 96,
        Cell: ({ row, value }) => {
          const date = format(new Date(value), 'dd MMM yyyy', { locale: ruLocale });
          return renderRowWithEmptySubCell(row.index, date);
        },
      },
      {
        Header: 'Время',
        accessor: 'time',
        width: 64,
        disableFilters: true,
        Cell: ({ row }) => {
          const date = format(new Date(row.original.operation_date), 'HH:mm');
          return renderRowWithEmptySubCell(row.index, date);
        },
      },
      {
        Header: 'Кассир',
        accessor: 'paymaster',
        width: 180,
        disableFilters: true,
        Cell: ({ row, value }) => {
          const paymasterName = `${value?.last_name} ${value?.first_name[0]}. ${value?.second_name[0]}.`;
          return renderRowWithEmptySubCell(row.index, paymasterName);
        },
      },
      {
        Header: 'Плательщик',
        accessor: 'payer',
        width: 180,
        disableFilters: true,
        Cell: ({ row, value }) => {
          return (
            <PaymentCellContainer rowIndex={row.index} value={value.title}>
              <PaymentPayerCell
                isActivePaymentActions={isActivePaymentActions}
                isActiveRefundActions={isActiveRefundActions}
                value={value.title}
                handleSetIsPayerFilled={setIsPayerFilled}
                ref={payer}
              />
            </PaymentCellContainer>
          );
        },
      },
      {
        Header: 'Способ оплаты',
        accessor: 'payment_type',
        width: 180,
        disableFilters: true,
        Cell: ({ row, value }) => {
          return (
            <PaymentCellContainer rowIndex={row.index} value={value?.title}>
              {!isActivePaymentActions ? (
                value?.title
              ) : (
                <PaymentTypeSelect
                  values={paymentTypes}
                  width={148}
                  placeholder="Способ оплаты"
                  handleSetPaymentType={setPaymentType}
                />
              )}
              {isActiveRefundActions && (
                <div style={{ paddingTop: 24 }}>
                  {showRefundResult ? (
                    paymentData[0].payment_type.title
                  ) : (
                    <PaymentTypeSelect
                      values={paymentTypes}
                      width={148}
                      placeholder="Способ оплаты"
                      handleSetPaymentType={setPaymentType}
                    />
                  )}
                </div>
              )}
            </PaymentCellContainer>
          );
        },
      },
      {
        Header: 'Приход',
        accessor: 'payment',
        width: 88,
        disableFilters: true,
        Cell: ({ row, value }) => {
          const advanceValue = row.original.operation_type === AdvanceOperation.INCOME ? value : baseAmountValue;
          return (
            <PaymentCellContainer rowIndex={row.index} value={advanceValue}>
              <PaymentPaidCell
                isActivePaymentActions={isActivePaymentActions}
                isActiveRefundActions={isActiveRefundActions}
                value={advanceValue}
                handleSetIsPaymentFilled={setIsPaymentFilled}
                ref={paid}
              />
            </PaymentCellContainer>
          );
        },
      },
      {
        Header: 'Расход',
        accessor: 'refund',
        width: 88,
        disableFilters: true,
        Cell: ({ row }) => {
          const refundValue = row.original.payment;
          const operationType = row.original.operation_type;
          const valueCondition =
            operationType === AdvanceOperation.EXPENSE || operationType === AdvanceOperation.RETURN;
          const advanceValue = valueCondition ? refundValue : baseAmountValue;

          return (
            <PaymentCellContainer rowIndex={row.index} value={advanceValue}>
              <PaymentRefundCell
                value={advanceValue}
                isActiveRefundActions={isActiveRefundActions}
                currentBalance={currentBalance}
                showRefundResult={showRefundResult}
                handleSetIsRefundFilled={setIsRefundFilled}
                ref={refund}
                refundTotal={paymentData[0]?.payment}
              />
            </PaymentCellContainer>
          );
        },
      },
      {
        Header: 'Остаток',
        accessor: 'remainder',
        width: 88,
        disableFilters: true,
        Cell: ({ row, value }) => {
          return (
            <PaymentRemainderCell
              currentBalance={currentBalance}
              rowIndex={row.index}
              value={value}
              isBalanceChanged={isBalanceChanged}
              isActiveRefundActions={isActiveRefundActions}
              showRefundResult={showRefundResult}
              refundAmount={refund.current && refund.current.value}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActivePaymentActions, isActiveRefundActions, showRefundResult, paymentData, currentBalance],
  );
  const disabledPaymentCondition = !isPaymentFilled || !paymentType || !isPayerFilled;
  const disabledRefundCondition = !isRefundFilled || !paymentType;
  const buttonsCondition = isActivePaymentActions || (isActiveRefundActions && !showRefundResult);

  useEffect(() => {
    if (advances.length) {
      setPaymentData(advances);
    }
  }, [advances]);

  return (
    <ClickAwayListener onClickAway={handleSetOpenForm}>
      <Container>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontWeight: 500, fontSize: '16px' }}>Аванс</span>
          <OutlinedButton
            width={110}
            height={20}
            style={{ margin: '0 16px 0 30px' }}
            noBorder
            onClick={handleAddPayment}
            disabled={buttonsCondition}
          >
            <span style={{ fontSize: '18px', marginRight: '8px' }}>+</span> Внести аванс
          </OutlinedButton>
          <OutlinedButton
            width={141}
            height={20}
            style={{ marginTop: 0 }}
            noBorder
            onClick={handleAddRefund}
            disabled={buttonsCondition}
          >
            Произвести возврат
          </OutlinedButton>
        </div>
        <div>
          <TableBox>
            <Table<ExpandedAdvance>
              columns={columns}
              data={showRefundResult ? paymentDataWithoutFirstItem : paymentData}
              hiddenColumns={['id']}
              visibleRowCount={isActiveRefundActions ? 4 : 7}
              canAutoHeight
              getHeaderProps={() => ({
                style: {
                  background: '#F9F9F9',
                },
              })}
            />
          </TableBox>
          <FooterButtons>
            <OutlinedButton width={98} height={32} style={{ marginTop: 0 }} noBorder onClick={printAdvances}>
              Распечатать
            </OutlinedButton>
            {buttonsCondition ? (
              <div style={{ display: 'flex' }}>
                <OutlinedButton
                  style={{ marginTop: 0, padding: '0px 16px', fontSize: '16px', boxSizing: 'border-box' }}
                  height={40}
                  onClick={handleCancelCalculations}
                >
                  Отмена
                </OutlinedButton>
                <BlueButton
                  style={{ marginTop: 0, marginLeft: 16, padding: '0px 16px', fontSize: '16px' }}
                  height={40}
                  disabled={isActivePaymentActions ? disabledPaymentCondition : disabledRefundCondition}
                  onClick={handleFinishOperations}
                >
                  {isActivePaymentActions ? 'Внести аванс' : 'Произвести возврат'}
                </BlueButton>
              </div>
            ) : null}
          </FooterButtons>
        </div>
      </Container>
    </ClickAwayListener>
  );
};
