import styled from 'styled-components';

export const HeaderForm = styled.form`
  display: flex;
  flex: 1;
  padding: 9px 24px;
  background-color: #f9f9f9;
  border-top: 2px solid #7c8590;
  border-bottom: 2px solid #7c8590;
`;

export const TableRow = styled.div`
  display: flex;
  flex: 1;
  padding: 10px 24px 7px;
  border-bottom: 2px solid #7c8590;
  color: #a8aeb5;
  font-size: 14px;
  font-weight: 400;
`;
export const ErrorMessage = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  line-height: 22px;
  color: #df194c;
  padding: 12px 24px 0;
  font-size: 14px;
  width: 680px;
  font-weight: 400;
`;
