import { PICK_WORKDAY, SET_WIDTH, SET_SCHEDULE, WorkdayState } from "./types";
import { FLUSH_STATE } from "../flushState/types";

const INITIAL_STATE: WorkdayState = {
  current: {},
  responce: [],
  width: 0,
  schedule: {},
  max_day_length: 12
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SCHEDULE:
      return {
        ...state,
        ...{ schedule: action.payload as WorkdayState['schedule'] }
      };
    case PICK_WORKDAY:
      return {
        ...state,
        ...{ current: action.payload }
      };
    case FLUSH_STATE:
      return INITIAL_STATE;
    case SET_WIDTH:
      return {
        ...state,
        ...{ width: action.payload }
      };
    default:
      return state;
  }
}
