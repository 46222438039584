import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  time_start: 0,
  current: {},
  creating_event: false,
  resize_side: "",
  resizing_event: false,
  type: "null",
  eventType: "null",
  reschedule_event: false,
  offset: {
    top: 0,
    left: 0,
    x: 0,
    y: 0,
    height: 0,
  },
};

const EventSlice = createSlice({
  name: "event",
  initialState: INITIAL_STATE,
  reducers: {
    startCreating: (state, action) => ({
      ...state,
      creating_event: true,
      time_start: action.payload.timeStart,
      offset: {
        top: action.payload.top,
        left: action.payload.left,
        x: 0,
        y: 0,
        height: 0,
      },
    }),
    startResizing: (state, action) => ({
      ...state,
      resizing_event: true,
      resize_side: action.payload.type,
      time_start: action.payload.timeStart,
      eventType: action.payload.event,
      offset: {
        top: action.payload.top,
        left: action.payload.left,
        x: action.payload.x,
        y: action.payload.y,
        height: action.payload.height,
      },
    }),
    startRescheduling: (state, action) => ({
      ...state,
      reschedule_event: true,
      type: action.payload.type,
      eventType: action.payload.event,
      time_start: action.payload.timeStart,
      offset: {
        top: action.payload.top,
        left: action.payload.left,
        x: action.payload.x,
        y: action.payload.y,
        height: action.payload.height,
      },
    }),
    setCurrentEvent: (state, action) => ({
      ...state,
      current: action.payload,
    }),
    resetEventState: (state) => ({
      ...state,
      ...INITIAL_STATE,
    }),
  },
});

export default EventSlice;
