import React, { memo } from 'react';
import { SchedulerCalendar } from './SchedulerCalendar';
import CalendarControls from './CalendarControls';
import { SettingsTitle } from '../../styled-components';
import { useAppSelector } from '../../../../../../store/hooks';
import { checkHoliday } from '../StaffSchedulerTable/functions';

/**
 * Компонент статичного календаря расписания сотрудника
 */

const StaffSchedulerCalendar: React.FC = () => {
  const { selectedCabinets, currentStaffer } = useAppSelector((state) => state.staffSchedule);
  const nonWorkingDates = useAppSelector((state) =>
    state.staffSchedule.holidays.filter((holiday) => !holiday.is_workday).map((holiday) => holiday.date),
  );
  const nonWorkingWeekdays = useAppSelector((state) =>
    state.staffSchedule.workdays
      .filter((weekday) => !weekday.is_workday)
      .map((weekday) => (weekday.id === 7 ? 0 : weekday.id)),
  );

  const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
  const isDisabled = !currentStaffer.schedule && selectedCabinets.length === 0;

  return (
    <>
      <SettingsTitle style={{ marginTop: 24 }}>Календарь</SettingsTitle>
      <SchedulerCalendar
        onDisableDates={(date) => checkHoliday(date, nonWorkingWeekdays, nonWorkingDates, timeZoneOffset)}
        isDisabled={isDisabled}
      >
        <CalendarControls isDisabled={isDisabled} />
      </SchedulerCalendar>
    </>
  );
};

export default memo(StaffSchedulerCalendar);
