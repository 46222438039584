/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ReactComponent as DotsSVG } from './svg/ellipsis.svg';
import { ReactComponent as ImageFileSVG } from './svg/image-file.svg';
import { ReactComponent as CloseSVG } from './svg/close.svg';
import { ReactComponent as GreenCheckSVG } from './svg/greencheck.svg';
import { ReactComponent as AddEntrySVG } from './svg/add.svg';
import { ReactComponent as HiddenSVG } from './svg/hidden.svg';
import { ReactComponent as VisibleSVG } from './svg/visible.svg';
import { ReactComponent as InfoSVG } from './svg/info.svg';
import { ReactComponent as BlockSVG } from './svg/block.svg';
import { ReactComponent as LoginInfoSVG } from './svg/loginInfo.svg';
import { ReactComponent as ClearSVG } from './svg/clear.svg';
import { ReactComponent as DeleteSVG } from './svg/delete.svg';
import { ReactComponent as WarningSVG } from './svg/warning.svg';
import { ReactComponent as RightArrowSVG } from './svg/rightArrow.svg';
import { ReactComponent as LeftArrowSVG } from './svg/leftArrow.svg';
import { ReactComponent as DisabledRightArrowSVG } from './svg/disabledRightArrow.svg';
import { ReactComponent as DisabledLeftArrowSVG } from './svg/disabledLeftArrow.svg';
import { ReactComponent as RedactIconSVG } from './svg/redactIcon.svg';

export const DotsIcon = (props: any) => <DotsSVG {...props} className={'svg-icon'} />;
export const ImageFileIcon = (props: any) => <ImageFileSVG {...props} className={'svg-icon'} />;
export const OkIcon = (props: any) => <GreenCheckSVG {...props} className={'svg-icon'} />;
export const CancelIcon = (props: any) => <CloseSVG {...props} className={'svg-icon'} />;
export const AddEntryIcon = (props: any) => <AddEntrySVG {...props} />;
export const HiddenIcon = (props: any) => <HiddenSVG {...props} />;
export const VisibleIcon = (props: any) => <VisibleSVG {...props} />;
export const InfoIcon = (props: any) => <InfoSVG {...props} />;
export const BlockIcon = (props: any) => <BlockSVG {...props} className={'svg-icon'} />;
export const LoginInfoIcon = (props: any) => <LoginInfoSVG {...props} />;
export const ClearIcon = (props: any) => <ClearSVG {...props} />;
export const DeleteIcon = (props: any) => <DeleteSVG {...props} />;
export const WarningIcon = (props: any) => <WarningSVG {...props} />;
export const RightArrowIcon = (props: any) => <RightArrowSVG {...props} />;
export const LeftArrowIcon = (props: any) => <LeftArrowSVG {...props} />;
export const DisabledRightArrowIcon = (props: any) => <DisabledRightArrowSVG {...props} />;
export const DisabledLeftArrowIcon = (props: any) => <DisabledLeftArrowSVG {...props} />;
export const RedactIcon = (props: any) => <RedactIconSVG {...props} />;
