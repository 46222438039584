import React, { memo } from 'react';
import { MultiSelect } from '../../../../../uikit/MultiSelect';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { setSelectedCabinetsV2 } from '../reducer';
import { SettingsTitle, CustomTooltip } from '../styled-components';
import { useGetCabinetsDictionaryQuery, CabinetDictionaryItem } from '../../../../../services/dictionaries';

export const StaffScheduleCabinetsPicker = () => {
  const dispatch = useAppDispatch();

  const dateRange = useAppSelector((state) => state.staffSchedule.dateRange);
  const selectedCabinets = useAppSelector((state) => state.staffSchedule.selectedCabinets);
  const isWatchingMatches = useAppSelector((state) => state.staffSchedule.isWatchingMatches);
  const sessionsMatches = useAppSelector((state) => state.staffSchedule.sessionsMatches);
  const currentSessionsMatch = useAppSelector((state) => state.staffSchedule.currentSessionsMatch);
  const currentStaffer = useAppSelector((state) => state.staffSchedule.currentStaffer);

  const { data: cabinetsDictionary = [] as CabinetDictionaryItem[] } = useGetCabinetsDictionaryQuery();

  const isDisabled = !currentStaffer.color || currentStaffer.color === '#ffffff' || dateRange.some((date) => !date);

  return (
    <>
      <SettingsTitle style={{ marginTop: 24 }}>Выбор кабинета</SettingsTitle>
      <CustomTooltip
        open={Boolean(currentStaffer.color && dateRange[1] && selectedCabinets.length === 0)}
        title="Для добавления расписания сотруднику необходимо выбрать кабинет."
        arrow
        placement="right"
      >
        <MultiSelect
          textField="title"
          data={cabinetsDictionary}
          onChange={(cabinets) => dispatch(setSelectedCabinetsV2(cabinets))}
          placeholder={!selectedCabinets.length ? 'Поиск по кабинетам' : ''}
          searchPlaceholder="Поиск по кабинетам"
          isDisabled={isDisabled}
          initialValue={selectedCabinets}
          isWatchingMatches={isWatchingMatches}
          sessionsMatches={sessionsMatches}
          currentSessionsMatch={currentSessionsMatch}
          closeOnSelect
        />
      </CustomTooltip>
    </>
  );
};

export default memo(StaffScheduleCabinetsPicker);
