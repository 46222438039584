import { SET_DOCTOR, FETCH_STAFF_SUCCESS, SELECT_DOCTOR } from './types';
const INITIAL_STATE = {
  current: {},
  responce: [],
  selected: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DOCTOR:
      return {
        ...state,
        ...{ current: action.payload },
      };
    case SELECT_DOCTOR:
      return {
        ...state,
        ...{ selected: action.payload },
      };
    case FETCH_STAFF_SUCCESS:
      return {
        ...state,
        ...{ responce: action.payload },
      };
    default:
      return state;
  }
}
