import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  max-width: 332px;
  min-width: 312px;
  height: 186px;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`;
export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
`;
export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #a8aeb5;
  flex-wrap: wrap;
  padding: 0 40px;
  margin-top: 12px;
`;