import styled from "styled-components";

export const PAGE_PADDING = 45;
export const STAFF_TABLE_WIDTH = 1134;
export const TABLE_HEADER_HEIGHT = 52;
export const TABLE_ROW_HEIGHT = 52;
export const PAGE_HEADER_HEIGHT = 257;
export const ROW_LEFT_MARGIN = 8;
export const INPUT_MARGIN = 16;
export const INPUT_HEIGHT = 32;

type HeaderColProps = {
  width: number;
};
export const HeaderColumn = styled.div<HeaderColProps>`
  width: ${({ width }) => width}px;
  height: 54px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  background: #f9f9f9;
  padding-left: 8px;
  padding-top: 16px;
  border-bottom: 2px solid #7c8590;
  border-top: 2px solid #7c8590;
`;
