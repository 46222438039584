import React from 'react';
import Tab from '@material-ui/core/Tab';
import { AccountStatus, EAccountStatus } from '../Account/AccountStatus';
import { Counter } from '../../../uikit/Counter/Counter';
import { useGetInvoiceRowsQuery } from '../../../services/invoice';

export function SubInvoiceTab({ inv, tabItemClasses, toothState, setExpanded, setCurrentSubInvoiceId }) {
  const { data: invoiceRows = [] } = useGetInvoiceRowsQuery(inv.id);
  const count = invoiceRows.length;

  return (
    <Tab
      disableRipple
      classes={tabItemClasses}
      value={inv.formula_id}
      label={
        <div style={{ display: 'flex' }}>
          <span style={{ marginRight: 8 }}>
            {(toothState?.toothStateLabel && `${toothState?.toothIndex + ' ' || ''}${toothState?.toothStateLabel}`) ||
              (toothState?.toothState
                ? `${toothState?.toothIndex ? toothState?.toothIndex + ' ' : ''}${toothState?.toothState.title}`
                : `${toothState?.toothIndex ? toothState?.toothIndex + ' ' : ''}Состояние не указано`)}
          </span>
          {inv.status === EAccountStatus.FILLED && !!count ? (
            <Counter count={count} />
          ) : (
            <AccountStatus status={inv.status} />
          )}
        </div>
      }
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setExpanded(inv.formula_id);
        setCurrentSubInvoiceId(inv.id);
      }}
    />
  );
}
