import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { routerConfig } from '../../navigation/routerConfig';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'flex',
      backgroundColor: '#fff',
    },
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 24,
      justifyContent: 'center',
    },
    title: {
      fontWeight: 400,
      fontSize: 24,
      color: '#253446',
    },
    text: {
      fontWeight: 400,
      fontSize: 18,
      color: '#7C8590',
    },
    textLine: {
      margin: 0,
      padding: 0,
    },
  }),
);
export const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2182_85481)">
            <path
              d="M70 0C31.3108 0 0 31.3078 0 70C0 108.689 31.3078 140 70 140C108.689 140 140 108.692 140 70C140 31.3105 108.692 0 70 0ZM70 129.062C37.3559 129.062 10.9375 102.647 10.9375 70C10.9375 37.3557 37.3535 10.9375 70 10.9375C102.644 10.9375 129.062 37.3535 129.062 70C129.062 102.644 102.647 129.062 70 129.062Z"
              fill="#E5E6EA"
            />
            <path
              d="M93.9488 86.2197L77.7334 70.0043L93.9488 53.7889C96.0844 51.6533 96.0847 48.1908 93.9491 46.055C91.813 43.9192 88.3505 43.9194 86.2152 46.055L69.9995 62.2704L53.7839 46.055C51.6486 43.9192 48.1855 43.9192 46.05 46.055C43.9144 48.1908 43.9144 51.6533 46.0502 53.7889L62.2656 70.0043L46.0502 86.2197C43.9144 88.3555 43.9141 91.818 46.05 93.9536C48.1863 96.0897 51.6489 96.0889 53.7839 93.9536L69.9995 77.7382L86.2152 93.9536C88.3502 96.0891 91.8133 96.0894 93.9491 93.9536C96.0849 91.8178 96.0847 88.3552 93.9488 86.2197Z"
              fill="#E5E6EA"
            />
          </g>
          <defs>
            <clipPath id="clip0_2182_85481">
              <rect width="140" height="140" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <div className={classes.title}>Страница не найдена</div>

        <div className={classes.text} style={{ textAlign: 'center' }}>
          <p className={classes.textLine}>Страница на которую Вы хотели перейти не найдена</p>
          <p className={classes.textLine}>Возможно она устарела, была удалена или был введен некорректный адрес</p>
        </div>

        <div className={classes.text}>
          Перейти на{' '}
          <Link to={routerConfig.ROOT.path} replace>
            главную страницу
          </Link>
        </div>
      </div>
    </div>
  );
};
