import styled from 'styled-components';
import { getTableWidth } from './functions';

// Обёртка для прямого применения CSS на таблицу
export const ScheduleTableStylesWrapper = styled.div<{
  elementWidth: number | null;
  screenWidth: number;
  datesInRange: number[];
  maxTime: string;
  //isDragging: boolean;
}>`
  width: ${(props) => getTableWidth(props.elementWidth, props.screenWidth)};
  height: calc(100vh - 125px) !important;
  overflow: scroll;
  border-top: 1px solid #ddd;
  //pointer-events: ${(props) => (props.isDragging ? 'none' : 'auto')};

  // Установка самого позднего рабочего часа на шкала времени таблицы, вниз слева
  #ScheduleTableWidthWrapper > div {
    position: relative;

    &::after {
      content: '${({ maxTime, elementWidth }) => (Boolean(elementWidth) ? maxTime : '')}';
      position: absolute;
      bottom: -2px;
      left: 7px;
      font-size: 14px;
      font-weight: 500;
      color: #7c8590;
    }
  }

  // Выключение ненужных стилей визитов/сессий
  .fc-v-event {
    border: none;
    background-color: unset;
  }
  .fc-timegrid-event {
    border-radius: 4px;
    box-shadow: unset;
  }

  // Выключение подсветки сегодняшнего дня
  .fc-day-today {
    background-color: inherit !important;
  }
  .fc-license-message,
  .custom_hidden {
    display: none;
  }

  // Стили для скроллбаров
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: #e5e6ea;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a8aeb5;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-corner {
    background-color: #e5e6ea;
  }

  // Выключение лишнего margin сверху от таблицы
  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }

  // Выключение лишней строки с днями недели в режиме множества дат
  .fc-timegrid.fc-displayByDay-view.fc-view > table > thead > tr > th > div > div > table > thead > tr:nth-child(1) {
    display: none;
  }

  // Раздвигание дня недели-числа-месяца и кабинетов в разные углы в шапке таблицы
  .fc-col-header-cell-cushion {
    width: 100%;

    & > div {
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
    }
  }

  // Стили для выделения выбранной даты рамкой
  th.custom_highlighted > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion,
  td.custom_highlighted > div.fc-timegrid-col-frame,
  th.custom_highlighted_last > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion,
  td.custom_highlighted_last > div.fc-timegrid-col-frame {
    width: 100%;
    position: relative;
  }
  th.custom_highlighted > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::after,
  td.custom_highlighted > div.fc-timegrid-col-frame::after,
  th.custom_highlighted > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::before,
  td.custom_highlighted > div.fc-timegrid-col-frame::before,
  th.custom_highlighted_last > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::before,
  td.custom_highlighted_last > div.fc-timegrid-col-frame::after,
  td.custom_highlighted_last > div.fc-timegrid-col-frame::before {
    content: '';
    position: absolute;
    filter: drop-shadow(rgba(87, 123, 249, 0.5) 0px 0px 4px);
    z-index: 10;
  }
  th.custom_highlighted > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::after,
  td.custom_highlighted > div.fc-timegrid-col-frame::after,
  td.custom_highlighted_last > div.fc-timegrid-col-frame::after {
    right: -1px;
  }
  th.custom_highlighted > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::before,
  td.custom_highlighted > div.fc-timegrid-col-frame::before,
  td.custom_highlighted_last > div.fc-timegrid-col-frame::before,
  th.custom_highlighted_last > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::before {
    left: -1px;
  }
  td.custom_highlighted > div.fc-timegrid-col-frame::after,
  td.custom_highlighted > div.fc-timegrid-col-frame::before,
  td.custom_highlighted_last > div.fc-timegrid-col-frame::after,
  td.custom_highlighted_last > div.fc-timegrid-col-frame::before {
    top: 0px;
    width: 1px;
    height: 100%;
    background-color: rgb(87, 123, 249);
  }
  th.custom_highlighted > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::before,
  th.custom_highlighted_last > div.fc-scrollgrid-sync-inner > span.fc-col-header-cell-cushion::before {
    top: 0;
    width: calc(100% + 2px);
    height: calc(100% + 1px);
    border: 1px solid rgb(87, 123, 249);
    border-bottom: unset;
  }
  // Если выделенный столбец не единственный, но первый - у него не должно быть правой границы
  th:not(.custom_highlighted)
    + th.custom_highlighted
    > div.fc-scrollgrid-sync-inner
    > span.fc-col-header-cell-cushion::before {
    border-right: unset;
  }
  td:not(.custom_highlighted) + td.custom_highlighted > div.fc-timegrid-col-frame::after {
    content: unset;
  }
  // Если выделенный столбец не первый, то у него не должно быть левой и правой границы
  th.custom_highlighted
    + th.custom_highlighted
    > div.fc-scrollgrid-sync-inner
    > span.fc-col-header-cell-cushion::before {
    border-left: unset;
    border-right: unset;
  }
  td.custom_highlighted + td.custom_highlighted > div.fc-timegrid-col-frame::before,
  td.custom_highlighted + td.custom_highlighted > div.fc-timegrid-col-frame::after {
    content: unset;
  }
  // Если выделенный столбец последний из всей группы, у него должна быть граница справа
  th.custom_highlighted
    + th.custom_highlighted_last
    > div.fc-scrollgrid-sync-inner
    > span.fc-col-header-cell-cushion::before {
    border-left: unset;
  }
  td.custom_highlighted + td.custom_highlighted_last > div.fc-timegrid-col-frame::before {
    content: unset;
  }

  // Стилизация визита в тот момент, когда его перетягивают/перетаскивают по таблице
  .fc-timegrid-col-frame > div:nth-child(3) > div > a > div > div {
    background-color: #eff2ff;
    border: 1px solid #577bf9;
    border-top: 4px solid #577bf9;
    overflow: hidden;
  }

  // Плавность анимации при работе с визитами
  .fc-timegrid-col-frame > div:nth-child(3) > div,
  .fc-timegrid-col-frame > div:nth-child(1) > div,
  .fc-timegrid-bg-harness,
  .fc-highlight,
  .fc-timegrid-col-bg {
    transition: all 0.5s;
  }

  // Стили для левого столбца таблицы с часами
  .fc-timegrid.fc-displayByDay-view.fc-view > table > thead > tr > th > div > div > table > colgroup > col,
  .fc-timegrid-slots > table > colgroup > col,
  .fc-timegrid-cols > table > colgroup > col {
    width: 50px !important;
  }
  .fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink,
  .fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-minor,
  .fc-timegrid-axis {
    vertical-align: baseline;
    border: none;
  }
  div.fc-timegrid.fc-view > table > thead > tr > th > div > div > table > thead > tr > th:nth-child(2),
  .fc-scrollgrid.fc-scrollgrid-liquid {
    ${(props) => {
      if (props.datesInRange.length < 2) {
        return 'border-left: none;';
      }
    }}
  }
  .fc-scrollgrid.fc-scrollgrid-liquid {
    border-top: none;
  }
  .fc-timegrid-slot-label-frame.fc-scrollgrid-shrink-frame {
    text-align: center;
    margin-top: -3px;
  }
  .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: #7c8590;
  }

  // Обнуление background для шапки таблицы (она должна быть цвета фона)
  .fc .fc-scrollgrid-section-sticky > * {
    background: #f6f6fa;
  }

  // Подсветка даты и кабинета в шапке таблицы, если дата является выбранной
  .fc-col-header-cell.fc-resource.custom_highlighted span,
  .fc-col-header-cell.fc-resource.custom_highlighted_last span,
  .fc-col-header-cell.fc-resource.custom_today span {
    color: #577bf9;
  }

  // Установка дефолтного цвета для даты и кабинета в шапке таблицы
  .fc-col-header-cell.fc-resource span {
    color: #253446;
  }

  // Стили для индикатора "Сейчас"
  .fc-timegrid-now-indicator-line {
    border-color: #e54770;
    border-width: 2px 0 0;
  }
  .fc-timegrid-now-indicator-arrow {
    left: 44px;
    border-left-color: #e54770;
    margin-top: -4px;
  }

  // Стили для фикса бага с закрытием выборщика дат: выборщик дат суёт в эти элементы свою ширину. Перезаписываю её на нужную с !important
  div.fc-view-harness.fc-view-harness-active
    > div.fc-timegrid.fc-displayByDay-view.fc-view
    > table
    > thead
    > tr
    > th
    > div
    > div
    > table,
  div.fc-view-harness.fc-view-harness-active
    > div.fc-timegrid.fc-displayByDay-view.fc-view
    > table
    > tbody
    > tr
    > td
    > div
    > div
    > div,
  div.fc-view-harness.fc-view-harness-active
    > div.fc-timegrid.fc-displayByDay-view.fc-view
    > table
    > tbody
    > tr
    > td
    > div
    > div
    > div
    > div.fc-timegrid-slots
    > table,
  div.fc-view-harness.fc-view-harness-active
    > div.fc-timegrid.fc-displayByDay-view.fc-view
    > table
    > tbody
    > tr
    > td
    > div
    > div
    > div
    > div.fc-timegrid-cols
    > table {
    width: ${(props) => (props.elementWidth ? `${props.elementWidth}px !important` : 'unset !important')};
  }

  #root > div:nth-child(2) {
    display: none;
  }

  .fc-scroller.fc-scroller-liquid-absolute {
    overflow: hidden !important;
  }

  .fc-timegrid-slots > table > tbody > tr {
    max-height: 10px !important;
    line-height: normal !important;
  }

  .fc .fc-timegrid-slot {
    height: unset !important;
    line-height: normal !important;
  }

  .fc table {
    font-size: 0.9em;
  }

  .fc .fc-scroller {
    overflow: hidden !important;
  }

  .fc
    .fc-scrollgrid-section.fc-scrollgrid-section-liquid
    > .fc-scrollgrid
    > .fc-scrollgrid-body
    > .fc-scrollgrid-sticky-frame {
    overflow-x: hidden !important;
  }

  .fc .fc-scrollgrid-section.fc-scrollgrid-section-liquid > .fc-scrollgrid > .fc-scrollgrid-body {
    overflow-x: scroll !important;
  }

  #timeAxisContainer {
    position: absolute !important;

    top: -1px;
    bottom: 0;
    left: 0;

    width: 52px;

    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;

    z-index: 3;
    background-color: #f6f6fa;
  }

  #topLeftLittleBlocker {
    position: fixed;
    top: 126px;
    left: 0;
    width: 52px;
    height: 29px;
    background-color: #f6f6fa;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;

    z-index: 4;
  }
`;

// Обёртка для задания ширины таблице (без 2 оберток не работают скроллы)
export const ScheduleTableWidthWrapper = styled.div<{ elementWidth: number | null }>`
  width: ${(props) => (props.elementWidth ? `${props.elementWidth}px !important` : 'unset !important')};
`;

export const Wrapper = styled.div<{
  isHovered?: boolean;
  isSmall?: boolean;
  isDraggingOrResizing?: boolean;
  isHidden?: boolean;
  isIrrelevant?: boolean;
  isBackground?: boolean;
}>`
  padding: ${(props) => (props.isBackground ? '0px 5px 6px 4px' : '0px 5px 6px 4px')};
  display: flex;
  flex-direction: column;
`;

// Обёртка для контента в ячейке с визитом
export const AppointmentWrapper = styled(Wrapper)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  right: -4px;

  display: ${(props) => (props.isHidden ? 'none' : 'flex')};

  border: ${(props) =>
    !props.isIrrelevant && (props.isHovered || props.isDraggingOrResizing)
      ? '1px solid #577BF9'
      : '1px solid transparent'};
  border-top: ${(props) => `4px solid ${props.isIrrelevant ? '#7C8590' : '#577bf9'}`};
  ${(props) => (props.isSmall ? 'border-bottom: unset;' : '')};
  border-radius: 4px;
  ${(props) =>
    props.isHovered && !props.isIrrelevant && props.isSmall
      ? 'border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;'
      : ''};

  background-color: ${(props) =>
    props.isIrrelevant || props.isHovered || props.isDraggingOrResizing
      ? `${props.isIrrelevant ? '#F9F9F9' : '#EFF2FF'}`
      : '#fff'};

  transition: 0.3s all ease-in-out;
`;

// Выпадающая из визита часть, с дополнительной информацией по визиту
export const InfoPopup = styled.div<{ isOpened: boolean; isIrrelevant: boolean }>`
  position: absolute;
  bottom: ${(props) => (props.isOpened ? '-35px' : '0')};
  left: ${(props) => (props.isIrrelevant || !props.isOpened ? '0' : '-1px')};
  right: ${(props) => (props.isIrrelevant || !props.isOpened ? '0' : '-1px')};

  display: flex;

  padding-top: 8px;
  padding-left: 4px;
  height: ${(props) => (props.isOpened ? '40px' : '0')};

  flex-direction: column;

  border: ${(props) => (props.isIrrelevant || !props.isOpened ? 'unset' : '1px solid #577BF9;')};
  border-top: unset;
  border-radius: 4px;

  box-shadow: 0px 2px 4px 0px #25344629, 0px 0px 1px 0px #2534460a;
  background-color: ${(props) => (props.isIrrelevant ? '#F9F9F9' : '#EFF2FF')};

  z-index: -1;
  overflow: hidden;
  transition: 0.3s all ease-in-out;
`;
// Текст в ячейках с визитом / рабочей сессией
export const Info = styled.span<{ color?: string; fontSize?: number }>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  color: ${(props) => (props.color ? props.color : '#000')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : 10)}px;
`;
// Обёртка для имени пациента в карточке визита
export const PatientNameWrapper = styled.div`
  margin-top: ${(props) => (props.isTiny ? '0px' : '2px')};
  line-height: 1.1 !important;
  width: 80%;
  min-height: ${(props) => (props.isTiny ? 'unset' : '28px')};
  display: flex;
`;
// Обёртка для позиционирования иконок
export const IconWrapper = styled.div<{ right: string; isTiny: boolean }>`
  position: absolute;
  top: ${(props) => (props.isTiny ? '-3px' : '0px')};
  right: ${(props) => props.right};
  ${(props) => (props.isTiny ? 'transform: scale(.5)' : '')};
`;
export const ResourceSpan = styled.span<{ isPrimary?: boolean; isSelected?: boolean }>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: ${(props) => (props.isPrimary ? '500' : '400')};
`;
// Обёртка для блокеров
export const BlockerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2px;
  right: -4px;
  background-image: repeating-linear-gradient(-45deg, #eff0f3 0, #eff0f3 24px, #f9f9f9 24px, #f9f9f9 48px);
  cursor: no-drop;
`;

export const Blocker = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2px;
  right: -5px;
  z-index: 999;
  pointer-events: auto;
`;

export const TimeAxisHour = styled.span<{ top?: number }>`
  position: absolute !important;

  top: ${({ top }) => (top ? `${top}px` : 'unset')};
  left: 0;

  width: 100%;

  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #7c8590;
`;
