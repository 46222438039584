import React, { ChangeEvent, FC, memo, useEffect, useRef, useState } from 'react';
import TextInput from '../../../../uikit/TextInput';
import { getMask } from '../../../../utils/masks';
import { PaymentTypeCodes } from '../../../../services/dictionaries';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import PaymentAccountSlice from '../state';
import { Modal } from '../../../../uikit/Modal';
import { ValidationMessage } from '../../../../uikit/ValidationMessage';

export type AmountInputProps = {
  value: number;
  onUpdateInvoice?: (value: string | number, rowId: number, property: string) => void;
  rowId: number;
  property?: string;
  rowPaymentType?: string;
  isPaidInput?: boolean;
  onUpdateOperationPaidValue?: (rowId: number, operationId: string | number, paidAmount: number) => void;
  operationId?: string | number;
};

export const AmountInput: FC<AmountInputProps> = memo(
  ({
    onUpdateInvoice,
    value,
    rowId,
    property,
    rowPaymentType,
    isPaidInput,
    onUpdateOperationPaidValue,
    operationId,
  }) => {
    const dispatch = useDispatch();

    const [inputValue, setInputValue] = useState<number>(0);
    const [anchorModalError, setAnchorModalError] = useState<null | HTMLElement>(null);

    const advanceAmount = useSelector((state: RootState) => state.PaymentAccountSlice.advanceAmount);
    const { setAdvanceAmount } = PaymentAccountSlice.actions;

    const ref = useRef(null);

    const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
      const currentValue = +getMask('count').resolve(`${+e.target.value}`);
      setInputValue(currentValue);
    };

    const getNewAdvanceAmount = () => {
      if (value > inputValue) {
        return advanceAmount + (value - inputValue);
      }
      if (inputValue > value) {
        return advanceAmount - inputValue;
      }
      return advanceAmount;
    };

    const checkAdvanceAmount = () => {
      if (advanceAmount < inputValue) {
        setAnchorModalError(ref.current);
        return;
      }
      if (anchorModalError) setAnchorModalError(null);
      const newAdvanceAmount = getNewAdvanceAmount();
      dispatch(setAdvanceAmount(newAdvanceAmount));
      onUpdateOperationPaidValue(rowId, operationId, inputValue);
    };

    const handleSetBaseValue = () => {
      if (isPaidInput) {
        if (rowPaymentType && rowPaymentType === PaymentTypeCodes.PREPAID) {
          checkAdvanceAmount();
          return;
        }
        onUpdateOperationPaidValue(rowId, operationId, inputValue);
      }
      onUpdateInvoice && onUpdateInvoice(inputValue, rowId, property);
    };

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <>
        <TextInput
          style={{ height: 24, fontSize: 12, padding: '4px' }}
          value={inputValue}
          onBlur={handleSetBaseValue}
          onChange={handleChangeValue}
          error={anchorModalError}
          ref={ref}
        />
        <Modal anchor={anchorModalError} placementModal={'bottom-start'} unSetContainer={true} zIndex={1400}>
          <ValidationMessage arrowMarginLeft={16} arrowMarginRight={196}>
            Сумма больше чем в авансе
          </ValidationMessage>
        </Modal>
      </>
    );
  },
);
AmountInput.displayName = 'AmountInput';
