import React, { useState, useRef } from 'react';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { sendMessage, fetchMessages } from '../../features/messages/operations';
import { changeMessageData } from '../../features/messages/actions';
import { withStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars-2';
const styles = () => ({
  root: {
    // padding: 12,
    paddingTop: 5,
    paddingLeft: 12,
    paddingRight: 12,
    overflow: 'hidden',
  },
  label: {
    '&$focused': {},
  },
  focused: {},
  underline: {
    border: 'none',
    borderRadius: '0px',
    '&:after': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
  },
});
function TextInput({ messages, classes, sendMessage, fetchMessages, changeMessageData }) {
  const [rows, setRows] = useState(1);
  let scrollArea = useRef(null);
  function changeData(e) {
    changeMessageData(e.target.value);
    if (e.target.value.length > 40) {
      let count = e.target.value.length / 40;
      setRows(count + 1);
    } else if (e.target.value.length < 40) {
      setRows(1);
    }
  }
  async function postAndFetchMessage() {
    let text = messages.current.text + '';
    text = text.trim();
    if (text !== '' && text !== 'undefined') {
      await sendMessage({
        sender: messages.dialog.sender,
        reciever: messages.dialog.reciever,
        date: new Date(),
        text: messages.current.text,
        isNew: true,
      });
      await fetchMessages(messages.dialog.sender.id, messages.dialog.reciever.id);
      setRows(1);
    }
  }

  async function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      postAndFetchMessage();
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        padding: 8,
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: '1px solid rgba(145, 145, 145, 0.1)',
      }}
    >
      <div
        style={{
          marginRight: 20,
          width: 330,
          height: rows > 1 ? 80 : 45,
          border: '1px solid rgba(145, 145, 145, 0.5)',
          borderRadius: '10px',
          boxSizing: 'content-box',
        }}
      >
        <Scrollbars
          ref={scrollArea}
          autoHide
          style={{
            width: 330,
            overflow: 'hidden',
            height: rows > 1 ? 80 : 45,
          }}
        >
          <TextField
            fullWidth
            inputProps={{
              id: 'chat_message',
              style: {
                fontSize: 13,
                overflow: 'hidden',
              },
            }}
            InputLabelProps={{
              classes: { root: classes.label, focused: classes.focused },
            }}
            // eslint-disable-next-line
            InputProps={{
              classes: {
                root: classes.root,
                underline: classes.underline,
              },
            }}
            multiline
            rows={rows}
            value={messages.current.text}
            onChange={changeData}
            onKeyDown={handleKeyDown}
          />
        </Scrollbars>
      </div>
      <img
        id={'send_message'}
        style={{
          marginTop: 5,
          marginLeft: -10,
          userSelect: 'none',
          pointerEvents: messages.current.text && messages.current.text.length ? 'auto' : 'none',
        }}
        width="40px"
        src={`./img/${messages.current.text && messages.current.text.length ? 'blue' : 'grey'}/send-message.svg`}
        onClick={() => postAndFetchMessage()}
        alt="Отправить"
      />
    </div>
  );
}

const StyledTextInput = withStyles(styles)(TextInput);

const mapStateToProps = (state) => ({ messages: state.messages });

export default connect(mapStateToProps, { sendMessage, fetchMessages, changeMessageData })(StyledTextInput);
