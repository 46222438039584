import { pingAction } from './actionCreators';

const PING_FAIL_COUNT = 3;
const PING_DELAY = 30 * 1000;

export default class PingWebSocket {
  websocket = null;
  pingCount = 0;
  pingHandler = null;

  constructor(websocket) {
    this.websocket = websocket;
  }

  interval = () => {
    this.pingCount += 1;
    if (this.pingCount > PING_FAIL_COUNT) {
      this.websocket.reconnect();
    }
    this.websocket.send(pingAction());
  };

  start = () => {
    this.stop();
    this.pingHandler = setInterval(this.interval, PING_DELAY);
  };

  stop = () => {
    if (this.pingHandler) {
      clearInterval(this.pingHandler);
      this.pingHandler = null;
    }
    this.clearCount();
  };

  clearCount = () => {
    this.pingCount = 0;
  };
}
