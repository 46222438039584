import WithSuggestions from "../../components/Suggestions/WithSuggestions";
import TextInput from "../../uikit/TextInput";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SuggestionsState from "../../components/Suggestions/state";
import { pluck, compose } from "ramda";
import countries from "../../utils/country.json";
import MedcardState from "./state";

const { patchDetails } = MedcardState.actions;

function CountrySelect({
  value,
  onBlur,
  closeMenu,
  placeholder,
  openMenu,
  patchDetails,
}: any) {
  useEffect(() => () => closeMenu(), [true]);
  const [adsChannel, setAdsChannel] = useState(value);
  const getSuggestions: any = compose<any[], string[]>(pluck("country"));
  const suggestions = getSuggestions(countries);
  return (
    <div style={{ width: "100%" }} onClick={() => openMenu("country")}>
      <WithSuggestions
        name={"country"}
        suggestions={suggestions}
        value={adsChannel === value ? "" : adsChannel}
        handlePick={(value: string) => {
          setAdsChannel(value);
          onBlur({
            target: { value },
          });
          patchDetails({ address_country: value });
        }}
      >
        <div style={{ height: 30 }}>
          <TextInput
            placeholder={placeholder}
            value={adsChannel}
            onBlur={onBlur}
            onChange={(e: any) => setAdsChannel(e.target.value)}
          />
        </div>
      </WithSuggestions>
    </div>
  );
}

export default connect((state: any) => ({}), {
  openMenu: SuggestionsState.actions.openMenu,
  closeMenu: SuggestionsState.actions.closeMenu,
  patchDetails,
})(CountrySelect);
