import { createSlice } from '@reduxjs/toolkit';
import { groupBy, prop, map, compose, head, omit, isEmpty, filter, keys, sortBy } from 'ramda';

export const getIds = map(prop('id'));
export const groupByIds = compose(map(head), groupBy(prop('id')));
export const dropEmptyProps = (object) => {
  const emptyPropsKeys = filter((key) => isEmpty(object[key].trim()), keys(object));
  return omit(emptyPropsKeys, object);
};
export const normalizeStaff = (response) => ({
  byId: groupByIds(response),
  allIds: getIds(response),
});

const INITIAL_STATE = {
  response: [],
  staff: {
    byId: {
      0: {},
    },
    allIds: [],
  },
  searchQuery: {},
  isCreating: false,
  data: {},
  isRedirectingToSchedule: false,
  isDialogOpened: false,
  currentStafferId: '',
  tipId: '',
  newStafferId: null,
  errors: {},
  isSearching: false,
  staffer: {},
};

const StaffPage = createSlice({
  name: 'StaffPage',
  initialState: INITIAL_STATE,
  reducers: {
    setStaffer: (state, action) => {
      state.staffer = action.payload;
    },
    setNewStaffer: (state, action) => {
      state.newStafferId = action.payload;
      state.isSearching = false;
    },
    loadResponse: (state, action) => {
      state.response = action.payload;
    },
    loadStaff: (state, action) => {
      let normalizedData = normalizeStaff(action.payload);
      state.staff = normalizedData;
      state.isDialogOpened = false;
      state.currentStafferId = '';
      state.newStafferId = null;
    },
    pushNewStaffer: (state, action) => {
      let staff = Object.values(state.staff.byId);
      staff = [action.payload, ...staff];
      staff = sortBy(
        (item) => !item.is_active,
        staff.sort((a, b) => b.id - a.id),
      );
      state.staff = normalizeStaff(staff);
      state.tipId = action.payload.id;
      state.isSearching = false;
    },
    closeTip: (state) => {
      state.tipId = '';
    },
    searchFor: (state, action) => {
      state.isCreating = false;
      state.isSearching = true;
      state.searchQuery = dropEmptyProps({
        ...state.searchQuery,
        ...action.payload,
      });
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    dropSearch: (state) => {
      state.searchQuery = {};
      state.isSearching = false;
    },
    setCreatingStaffer: (state, action) => {
      state.isCreating = action.payload;
      state.isSearching = false;
    },
    setData: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    redirectToSchedule: (state) => {
      state.isRedirectingToSchedule = true;
    },
    dropInputFields: (state) => {
      state.data = {};
      state.isSearching = false;
    },
    openDialog: (state, action) => {
      state.isDialogOpened = true;
      state.currentStafferId = action.payload;
    },
    closeDialog: (state) => {
      state.isDialogOpened = false;
      state.currentStafferId = '';
    },
    setError: (state, action) => {
      state.errors = {
        ...state.errors,
        ...action.payload,
      };
    },
    dropErrors: (state) => {
      state.errors = {};
    },
    dropState: () => {
      return INITIAL_STATE;
    },
  },
});

export default StaffPage;
