import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { debounce, isEmpty, omit, uniqBy } from 'lodash';
import styled from 'styled-components';
import request from '../../../utils/request';
import TextInput from '../../../uikit/TextInput';
import { Grid, Paper } from '@material-ui/core';
import { DiagnosisMKBTemplates, TextTemplates } from '../Template';
import Switch from '../../../uikit/Switch';
import { JournalField, journalFieldsConfig, JournalFieldType } from './journalFieldsConfig';
import { DiagnosisMKBJornalField } from '../Template/DiagnosisMKB/DiagnosisMKBJornalField';
import { ErrorPopup } from './ErrorPopup';
import { TreatmentPlanTemplates } from '../Template/TreatmentPlan/TreatmentPlanTemplates';
import { DiscountTypeValues, TreatmentPlanJournalField } from '../Template/TreatmentPlan/TreatmentPlanJournalField';
import { JornalTextItem } from './JornalTextItem';
import OutlinedButton from '../../../uikit/Button/OutlinedButton';
import { MedcardDate, MedcardEntry, useUpdateEntryMutation } from '../../../services/medcard';
import { FormulaType } from '../Formula';
import {
  TreatmentPlanJournal,
  useAddMKBRecordMutation,
  useAddPlanRecordMutation,
  useAddPlanRecordRowMutation,
  useDeleteMKBRecordMutation,
  useDeletePlanRecordMutation,
  useDeletePlanRecordRowMutation,
  useGetMKBJournalFieldQuery,
  useGetTextJournalFieldsQuery,
  useUpdatePlanRecordRowMutation,
  useUpdateTextJournalFieldsMutation,
} from '../../../services/medcardJournal';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { format, isToday } from 'date-fns';
import { DoctorTabs } from '../Template/TreatmentPlan/DoctorTabs';
import { ToothStateTabs } from '../Template/TreatmentPlan/ToothStateTabs';
import { useAddSubInvoiceMutation, useGetInvoicesQuery } from '../../../services/invoice';
import { EAccountStatus } from '../Account/AccountStatus';
import { printPdf } from '../../../utils/print';
import { useGetPermissionsQuery } from '../../../services/users';

const FieldLabel = styled.div`
  margin-bottom: 8px;
  margin-top: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #253446;
`;

const JornalEntry = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #d3d6da;
  border-radius: 0px 0px 6px 6px;
  background-color: white;
  height: 54px;
  padding-left: 24px;
  padding-right: 20px;
  box-sizing: border-box;
`;

const TeethNumber = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #253446;
  margin-right: 8px;
`;

type DiagnosProps = {
  color: string;
};
const Diagnos = styled.div<DiagnosProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  display: flex;
  align-items: flex-end;
  color: ${({ color }) => (color ? color : '#253446')};
  margin-right: 20px;
`;

const BaseLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #7c8590;
  margin-right: 8px;
`;

const DoctorText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #253446;
  margin-right: auto;
`;

type JornalFieldsProps = {
  expanded: boolean;
};
const JornalFields = styled.div<JornalFieldsProps>`
  overflow: hidden;
  box-sizing: border-box;
  height: ${({ expanded }) => (expanded ? '100%' : '0px')};
  padding-left: 24px;
`;

const EmptyJornalFields = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #515d6b;
  padding: 24px;
`;

const ArrowButton = styled.div<{ expanded: boolean }>`
  height: 28px;
  background: ${({ expanded }) => (expanded ? '#577bf9' : 'none')};
  border-radius: 4px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ToothStateError = styled.div`
  font-weight: normal;
  font-size: 10px;
  line-height: 22px;
  color: #c3000a;
`;

type Props = {
  treatmentPlan: TreatmentPlanJournal[];
  formulaType: FormulaType;
  entry: MedcardEntry;
  patientId: number;
  expandedId: number | null;
  emptyToothStateError: boolean;
  setExpanded: ActionCreatorWithNonInferrablePayload;
  setEmptyToothStateError: ActionCreatorWithNonInferrablePayload;
  setAlertVisible: Dispatch<SetStateAction<boolean>>;
  openJornalAccount: VoidFunction;
  user: RootState['common']['user'];
  currentDate: MedcardDate;
  onEditJournal?: (data: unknown) => void;
  session: {
    session_user_id: number;
    session_user_name: string;
  };
};

export const JournalItemFormContext = React.createContext(null);

export const FIELD_WIDTH = 650;
export const FIELD_HEIGHT = 40;

export function JornalItem({
  treatmentPlan,
  formulaType,
  entry,
  patientId,
  expandedId,
  emptyToothStateError,
  setExpanded,
  setAlertVisible,
  openJornalAccount,
  setEmptyToothStateError,
  user,
  currentDate,
  onEditJournal,
  session,
}: Props) {
  const [currentJournalField, setCurrentJournalField] = useState<JournalField | null>(null);

  const [isAllFields, setIsAllFields] = useState<boolean>(false);
  const [extendedFields, setExtendedFields] = useState({});
  const [allFieldsErrorVisible, setAllFieldsErrorVisible] = useState(false);
  const [deleteErrorVisible, setDeleteErrorVisible] = useState('');
  const [emptyToothStateErrorVisible, setEmptyToothStateErrorVisible] = useState(false);

  const { data: permissions } = useGetPermissionsQuery(user.id);

  const [updateEntry] = useUpdateEntryMutation();

  const { data: textJournalFields } = useGetTextJournalFieldsQuery({ patientId, entryId: entry.id });
  const [updateTextJournalFields] = useUpdateTextJournalFieldsMutation();

  const { data: diagnosisMKB } = useGetMKBJournalFieldQuery({ patientId, entryId: entry.id });
  const [addMKBRecord] = useAddMKBRecordMutation();
  const [deleteMKBRecord] = useDeleteMKBRecordMutation();

  const [activeTreatmentPlan, setActiveTreatmentPlan] = useState(null);

  const [addPlanRecord] = useAddPlanRecordMutation();
  const [deletePlanRecord] = useDeletePlanRecordMutation();

  const [addPlanRecordRow] = useAddPlanRecordRowMutation();
  const [updatePlanRecordRow] = useUpdatePlanRecordRowMutation();
  const [deletePlanRecordRow] = useDeletePlanRecordRowMutation();

  const [addSubInvoice] = useAddSubInvoiceMutation();

  const { data: invoices = [] } = useGetInvoicesQuery({
    dateId: entry.dateId,
    patientId: patientId,
  });

  const doctors = useMemo(() => {
    const docList = uniqBy(
      treatmentPlan
        .filter((p) => !!p.planRows?.length)
        .filter((p) => p.doctor.id !== user.id)
        .map((p) => ({
          label: `${p.doctor.secondName} ${p.doctor.firstName[0]}. ${p.doctor.lastName[0]}.`,
          value: p.doctor.id,
        })),
      'value',
    );
    return [
      {
        label: `${user.last_name} ${user.first_name[0]}. ${user.second_name[0]}.`,
        value: user.id,
      },
      ...docList,
    ];
  }, [treatmentPlan, user]);

  const toothStates = useMemo(() => {
    const docList = uniqBy(
      treatmentPlan
        .filter((p) => !!p.planRows?.length)
        .filter((p) => (activeTreatmentPlan ? activeTreatmentPlan.doctor.id === p.doctor.id : user.id === p.doctor.id))
        .filter((p) => p.entry.id !== entry.id)
        .map((p) => ({ label: `${p.entry.toothIndex} ${p.entry.toothState}`, value: p.entry.id })),
      'value',
    );
    return (activeTreatmentPlan ? activeTreatmentPlan.doctor.id === entry.doctor.id : user.id === entry.doctor.id)
      ? [
          {
            label: `${entry.toothIndex || ''} ${entry.toothStateLabel || entry.toothState?.title || ''}`,
            value: entry.id,
          },
          ...docList,
        ]
      : docList;
  }, [treatmentPlan, activeTreatmentPlan, user, entry]);

  const handleTemplateFocus = (field: JournalField) => {
    setCurrentJournalField(field);
  };

  const handleUpdateField = (name, value) =>
    updateTextJournalFields({
      fields: { ...textJournalFields, [name]: value },
      request: { patientId, entryId: entry.id },
    });

  const debouncedHandleUpdateField = useMemo(
    () =>
      debounce((name, value) => {
        handleUpdateField(name, value);
        onEditJournal({ type: JournalFieldType.TEXT });
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [textJournalFields],
  );

  const handleChangeField = (value: string, name: string, extended: boolean, debounced = true) => {
    if (extended) {
      if (value.trim()) {
        setExtendedFields((prev) => ({ ...prev, [name]: value }));
      } else {
        setExtendedFields((prev) => omit(prev, [name]));
      }
    }
    if (debounced) {
      debouncedHandleUpdateField(name, value);
    } else {
      handleUpdateField(name, value);
      onEditJournal({ type: JournalFieldType.TEXT });
    }
  };

  const expanded = entry.id === expandedId;

  useEffect(() => {
    if (!entry.toothState && !entry.toothStateLabel) {
      setExpanded(entry.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry.toothState, entry.toothStateLabel]);

  useEffect(() => {
    if (expandedId) {
      setActiveTreatmentPlan(
        treatmentPlan.find(
          (p) =>
            p.doctor.id === entry.doctor.id &&
            p.entry.id === expandedId &&
            (p.entry.tooth_state_label === entry.tooth_state_label ||
              (p.entry.tooth_index == entry.tooth_index &&
                p.entry.tooth_state?.icon_letter === entry.tooth_state?.icon_letter)),
        ),
      );
    }
  }, [
    entry.doctor.id,
    entry.tooth_index,
    entry.tooth_state?.icon_letter,
    entry.tooth_state_label,
    expandedId,
    treatmentPlan,
  ]);

  //TODO: определиться как вычислять
  const viewOnly = false;

  const accountNotEmpty = treatmentPlan.some(
    (p) => p.doctor.id === user.id && p.entry.id === entry.id && p.planRows.some((t) => t.isUsed),
  );

  const isCurrentTextTemplate = useMemo(
    () => currentJournalField && currentJournalField.type === JournalFieldType.TEXT,
    [currentJournalField],
  );

  const isCurrentDiagnosisMKBTemplate = useMemo(
    () => currentJournalField && currentJournalField.type === JournalFieldType.DIAGNOSIS_MKB,
    [currentJournalField],
  );

  const isCurrentTreatmentPlanTemplate = useMemo(
    () => currentJournalField && currentJournalField.type === JournalFieldType.TREATMENT_PLAN,
    [currentJournalField],
  );

  const renderJornalFields = () => {
    return journalFieldsConfig
      .filter((field) => isAllFields || !field.extended)
      .map((field, index, array) => {
        switch (field.type) {
          case JournalFieldType.TEXT:
            return (
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6}>
                  <Paper elevation={0} square>
                    <div key={field.name}>
                      <FieldLabel>{field.text}</FieldLabel>
                      <JornalTextItem
                        value={textJournalFields ? textJournalFields[field.name] : ''}
                        readOnly={viewOnly}
                        focused={isCurrentTextTemplate && currentJournalField.name === field.name}
                        onFocus={() => handleTemplateFocus({ ...field, index })}
                        onChange={(value) => handleChangeField(value, field.name, field.extended)}
                      />
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
                  <Paper elevation={0} square>
                    {isCurrentTextTemplate && currentJournalField.name === field.name && (
                      <TextTemplates
                        isBottom={
                          array.length - 1 === index || array.length - 2 === index || array.length - 3 === index
                        }
                        doctorId={user.id}
                        journalField={currentJournalField}
                        toothState={entry.toothState}
                        clearFocus={() => setCurrentJournalField(null)}
                      />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            );
          case JournalFieldType.TREATMENT_PLAN:
            return (
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6}>
                  <Paper elevation={0} square>
                    <div key={field.name}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FieldLabel>{field.text}</FieldLabel>
                        <div
                          style={{ marginRight: 10, marginTop: 8 }}
                          onClick={async () => {
                            const result = await request.clinic.print_treatment_plan(
                              patientId,
                              expandedId,
                              activeTreatmentPlan.id,
                            );
                            printPdf(result.data);
                          }}
                        >
                          <img src="./img/black/printer.svg" />
                        </div>
                      </div>
                      <>
                        {!!doctors?.length && (
                          <DoctorTabs
                            data={doctors}
                            activeTreatmentPlan={activeTreatmentPlan}
                            onDoctorSelect={(value) => {
                              setActiveTreatmentPlan(
                                treatmentPlan.find((p) => p.doctor.id === value && !!p.planRows?.length),
                              );
                            }}
                          />
                        )}
                        {!!toothStates?.length && (
                          <ToothStateTabs
                            data={toothStates}
                            activeTreatmentPlan={activeTreatmentPlan}
                            onToothStateSelect={(value) => {
                              setActiveTreatmentPlan(
                                treatmentPlan.find(
                                  (p) =>
                                    (activeTreatmentPlan
                                      ? p.doctor.id === activeTreatmentPlan.doctor.id
                                      : p.doctor.id === user.id) && p.entry.id === value,
                                ),
                              );
                            }}
                          />
                        )}
                        {isEmpty(activeTreatmentPlan?.planRows) ? (
                          (
                            activeTreatmentPlan
                              ? activeTreatmentPlan.doctor.id === user.id
                              : entry.doctor.id === user.id
                          ) ? (
                            <div style={{ width: FIELD_WIDTH, height: 52, marginBottom: 16 }}>
                              <TextInput
                                placeholder="Чтобы создать план лечения кликните на данное поле"
                                style={{
                                  textAlign: 'center',
                                  marginTop: -2,
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                  ...(isCurrentTreatmentPlanTemplate ? { border: '1px solid #4555e4' } : {}),
                                }}
                                readOnly
                                onClick={() => {
                                  if (currentDate && isToday(new Date(currentDate.date))) {
                                    handleTemplateFocus({ ...field, index });
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <div style={{ width: FIELD_WIDTH, height: 52, marginBottom: 16 }}>
                              <TextInput
                                placeholder="Нет доступных планов лечения"
                                style={{
                                  textAlign: 'center',
                                  marginTop: -2,
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                }}
                                readOnly
                              />
                            </div>
                          )
                        ) : (
                          <TreatmentPlanJournalField
                            patientId={patientId}
                            entryId={entry.id}
                            data={activeTreatmentPlan?.planRows}
                            selected={isCurrentTreatmentPlanTemplate}
                            readOnly={activeTreatmentPlan.doctor.id !== entry.doctor.id}
                            hasTotalTitle
                            canFocused
                            onRowClick={(template) => {
                              deletePlanRecordRow({
                                planRowId: template.id,
                                request: {
                                  patientId,
                                  entryId: entry.id,
                                  planId: activeTreatmentPlan.id,
                                },
                              })
                                .unwrap()
                                .then(() => {
                                  if (activeTreatmentPlan.planRows.length === 1) {
                                    deletePlanRecord({
                                      planId: activeTreatmentPlan.id,
                                      request: {
                                        patientId,
                                        entryId: entry.id,
                                      },
                                    });
                                  }
                                });
                              onEditJournal({ type: JournalFieldType.TREATMENT_PLAN });
                            }}
                            onRowUpdate={(template) => {
                              const sum = template.count * template.price;
                              const [day, month, year] = template.date.split('.');

                              updatePlanRecordRow({
                                record: {
                                  id: template.id,
                                  date: format(new Date(year, month - 1, day), 'yyyy-MM-dd'),
                                  price_code: template.code,
                                  price_title: template.text,
                                  price_value: template.price,
                                  count: template.count,
                                  discount: template.discount,
                                  discount_type: template.discountType,
                                  payment:
                                    sum -
                                    (template.discountType === DiscountTypeValues.RUB
                                      ? template.discount
                                      : sum * template.discount * 0.01),
                                  is_used: template.isUsed,
                                  plan: activeTreatmentPlan.id,
                                },
                                request: {
                                  patientId,
                                  entryId: entry.id,
                                  planId: activeTreatmentPlan.id,
                                },
                              });
                              onEditJournal({ type: JournalFieldType.TREATMENT_PLAN });
                            }}
                            setFocus={() => {
                              if (currentDate && isToday(new Date(currentDate.date))) {
                                setCurrentJournalField(
                                  journalFieldsConfig.find((f) => f.type === JournalFieldType.TREATMENT_PLAN),
                                );
                              }
                            }}
                          />
                        )}
                      </>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
                  <Paper elevation={0} square>
                    {isCurrentTreatmentPlanTemplate && (
                      <TreatmentPlanTemplates
                        doctorId={user.id}
                        usedTemplates={treatmentPlan}
                        toothState={entry.toothState}
                        onAddTemplate={(template) => {
                          if (treatmentPlan.find((p) => p.doctor.id === entry.doctor.id && p.entry.id === entry.id)) {
                            addPlanRecordRow({
                              record: {
                                date: format(new Date(), 'yyyy-MM-dd'),
                                price_code: template.code,
                                price_title: template.text,
                                price_value: template.price,
                                count: 1,
                                discount: 0,
                                discount_type: 'RUB',
                                payment: template.price,
                                is_used: false,
                                plan: activeTreatmentPlan.id,
                              },
                              request: {
                                patientId,
                                entryId: entry.id,
                                planId: activeTreatmentPlan.id,
                              },
                            });
                          } else {
                            addPlanRecord({
                              patientId,
                              entryId: entry.id,
                            })
                              .unwrap()
                              .then(({ id }) => {
                                addPlanRecordRow({
                                  record: {
                                    date: format(new Date(), 'yyyy-MM-dd'),
                                    price_code: template.code,
                                    price_title: template.text,
                                    price_value: template.price,
                                    count: 1,
                                    discount: 0,
                                    discount_type: 'RUB',
                                    payment: template.price,
                                    is_used: false,
                                    plan: id,
                                  },
                                  request: {
                                    patientId,
                                    entryId: entry.id,
                                    planId: id,
                                  },
                                });
                              });
                          }
                          onEditJournal({ type: JournalFieldType.TREATMENT_PLAN });
                        }}
                        canFocused
                        clearFocus={() => setCurrentJournalField(null)}
                      />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            );
          case JournalFieldType.DIAGNOSIS_MKB:
            return (
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6}>
                  <Paper elevation={0} square>
                    <div key={field.name}>
                      <FieldLabel>{field.text}</FieldLabel>
                      {isEmpty(diagnosisMKB) ? (
                        <div style={{ width: FIELD_WIDTH, height: FIELD_HEIGHT, marginBottom: 16 }}>
                          <TextInput
                            style={isCurrentDiagnosisMKBTemplate ? { border: '1px solid #4555e4' } : {}}
                            readOnly
                            onClick={() => handleTemplateFocus({ ...field, index })}
                          />
                        </div>
                      ) : (
                        <DiagnosisMKBJornalField
                          data={diagnosisMKB}
                          onDeleteTemplate={(template) => {
                            deleteMKBRecord({
                              mkbId: template.id,
                              request: {
                                patientId,
                                entryId: entry.id,
                              },
                            });
                            onEditJournal({ type: JournalFieldType.DIAGNOSIS_MKB });
                          }}
                          isFocused={isCurrentDiagnosisMKBTemplate}
                          setFocus={() =>
                            setCurrentJournalField(
                              journalFieldsConfig.find((f) => f.type === JournalFieldType.DIAGNOSIS_MKB),
                            )
                          }
                        />
                      )}
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
                  <Paper elevation={0} square>
                    {isCurrentDiagnosisMKBTemplate && (
                      <DiagnosisMKBTemplates
                        doctorId={user.id}
                        usedTemplates={diagnosisMKB}
                        toothState={entry.toothState}
                        onAddTemplate={(template) => {
                          if (!template.selected) {
                            addMKBRecord({
                              record: {
                                code: template.code,
                                text: template.text,
                              },
                              request: {
                                patientId,
                                entryId: entry.id,
                              },
                            });
                            onEditJournal({ type: JournalFieldType.DIAGNOSIS_MKB });
                          }
                        }}
                        clearFocus={() => setCurrentJournalField(null)}
                      />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            );
        }
      });
  };

  return (
    <>
      <JornalEntry>
        {entry.toothIndex && (
          <TeethNumber style={{ marginTop: entry.toothState || entry.toothStateLabel ? 0 : 24 }}>
            {entry.toothIndex}
          </TeethNumber>
        )}
        {!entry.toothState && !entry.toothStateLabel ? (
          <div style={{ width: 161, height: 32, marginRight: 24, marginTop: 24 }}>
            <TextInput
              autoFocus
              name="title"
              error={emptyToothStateErrorVisible}
              placeholder="Укажите состояние"
              onChange={(e) => {
                setEmptyToothStateErrorVisible(!e.target.value);
              }}
              onBlur={(e) => {
                setEmptyToothStateError(!e.target.value);
                if (!e.target.value) {
                  setEmptyToothStateErrorVisible(!e.target.value);
                  return;
                }
                if (entry.doctor.id === user.id) {
                  updateEntry({
                    entry: {
                      id: entry.id,
                      formula_type: formulaType,
                      tooth_index: entry.toothIndex,
                      tooth_state_label: e.target.value,
                      tooth_state_mnemonic: entry.toothState ? entry.toothState.mnemo : '',
                      tooth_treatment_mnemonic: entry.toothTreatment ? entry.toothTreatment.mnemo : '',
                    },
                    patientId: patientId,
                  })
                    .unwrap()
                    .then(() => {
                      if (!invoices.find((inv) => inv.formula_id === entry.id)) {
                        addSubInvoice({ formula_id: entry.id, status: EAccountStatus.NOT_FILLED });
                        addPlanRecord({
                          patientId,
                          entryId: entry.id,
                        });
                      }
                    });
                }
              }}
            />
            {emptyToothStateErrorVisible && <ToothStateError>Обязательное поле</ToothStateError>}
          </div>
        ) : (
          <Diagnos color={entry.toothState && entry.toothState.iconColor}>
            {entry.toothState ? entry.toothState.title : entry.toothStateLabel}
          </Diagnos>
        )}
        {entry.toothTreatment && (
          <>
            <BaseLabel style={{ marginTop: entry.toothState || entry.toothStateLabel ? 0 : 24 }}>
              Проведенное лечение:
            </BaseLabel>
            <Diagnos
              color={entry.toothTreatment.iconColor}
              style={{ marginTop: entry.toothState || entry.toothStateLabel ? 0 : 24 }}
            >
              {entry.toothTreatment.title}
            </Diagnos>
          </>
        )}
        <BaseLabel style={{ marginTop: entry.toothState || entry.toothStateLabel ? 0 : 24 }}>Врач</BaseLabel>
        <DoctorText
          style={{ marginTop: entry.toothState || entry.toothStateLabel ? 0 : 24 }}
        >{`${entry.doctor.lastName} ${entry.doctor.firstName} ${entry.doctor.secondName}`}</DoctorText>
        {expanded && (
          <>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: 20, position: 'relative' }}>
              <BaseLabel>Все поля</BaseLabel>
              <Switch
                className={
                  (!entry.toothState && !entry.toothStateLabel) || !isEmpty(extendedFields) || !isEmpty(diagnosisMKB)
                    ? 'disabled'
                    : ''
                }
                checked={isAllFields}
                onClick={() => {
                  if (!entry.toothState && !entry.toothStateLabel) {
                    return;
                  }
                  if (isEmpty(extendedFields) && isEmpty(diagnosisMKB)) {
                    setIsAllFields(!isAllFields);

                    if (isAllFields) {
                      if (currentJournalField && currentJournalField.extended) {
                        setCurrentJournalField(null);
                      }
                    }
                  } else {
                    setAllFieldsErrorVisible(true);
                    setTimeout(() => {
                      setAllFieldsErrorVisible(false);
                    }, 3000);
                  }
                }}
              />
              {
                <ErrorPopup
                  isShown={allFieldsErrorVisible}
                  width={170}
                  height={32}
                  right={20}
                  text="Были внесены данные"
                />
              }
            </div>
            <div
              style={{ marginRight: 21 }}
              onClick={async () => {
                const result = await request.clinic.print_medcard(patientId, expandedId);
                printPdf(result.data);
              }}
            >
              <img src="./img/black/printer.svg" />
            </div>
            <div style={{ position: 'relative' }}>
              <div
                style={{ marginRight: 21 }}
                onClick={() => {
                  if (accountNotEmpty || entry.doctor.id !== user.id) {
                    if (accountNotEmpty) {
                      setDeleteErrorVisible('Удалить запись невозможно, выставлен счет');
                    } else {
                      setDeleteErrorVisible('Запись создана другим врачом');
                    }

                    setTimeout(() => {
                      setDeleteErrorVisible('');
                    }, 3000);
                  } else {
                    setAlertVisible(true);
                  }
                }}
              >
                <img src={`./img/${accountNotEmpty ? 'grey' : 'black'}/delete.svg`} />
              </div>
              <ErrorPopup isShown={!!deleteErrorVisible} width={210} height={54} right={34} text={deleteErrorVisible} />
            </div>
          </>
        )}
        <div
          onClick={() => {
            if (emptyToothStateError) {
              return;
            }
            if (entry.toothState || entry.toothStateLabel) {
              setExpanded(expandedId === null ? entry.id : null);
            }
          }}
          style={{ width: 28, height: 28 }}
        >
          <ArrowButton expanded={expanded}>
            <img
              src={expanded ? 'img/white/open.svg' : 'img/grey/open.svg'}
              alt="arrow button"
              width="22px"
              height="22px"
              style={{
                transform: `rotate(${expanded ? '180deg' : '0'})`,
              }}
            />
          </ArrowButton>
        </div>
      </JornalEntry>
      <JournalItemFormContext.Provider
        value={{
          setTextValue: currentJournalField
            ? (value) =>
                handleChangeField(
                  `${textJournalFields ? textJournalFields[currentJournalField.name] : ''} ${value}`,
                  currentJournalField.name,
                  currentJournalField.extended,
                  false,
                )
            : () => {
                /**empty*/
              },
        }}
      >
        {entry.toothState || entry.toothStateLabel ? (
          <JornalFields expanded={expanded}>
            <form style={{ paddingBottom: permissions['create_account'] ? 0 : 20 }}>
              {renderJornalFields()}
              {permissions['create_account'] && (
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <OutlinedButton
                    style={{ marginBottom: 20 }}
                    width={68}
                    height={40}
                    disabled={!!session?.session_user_id}
                    onClick={openJornalAccount}
                  >
                    Счет
                  </OutlinedButton>
                  {!!session?.session_user_id && (
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                        fontSize: 14,
                        color: '#C3000A',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{`Редактирует ${session?.session_user_name}`}</div>
                        <div>Одновременная работа недоступна</div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </form>
          </JornalFields>
        ) : (
          <EmptyJornalFields>
            Поля для заполнения дневника лечения появятся после ввода названия состояния
          </EmptyJornalFields>
        )}
      </JournalItemFormContext.Provider>
    </>
  );
}
