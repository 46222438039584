/* eslint-disable no-case-declarations */
import React from 'react';
import {
  TableUpperCell,
  CabinetSpan,
  TableUpperSpan,
  SessionWrapper,
  SessionSpan,
  SDeleteIcon,
  MatchesSwitcher,
  SessionTooltip,
  SessionTooltipSpan,
  SessionTooltipDeleteAnchor,
} from './styled-components';
import { ColumnHeaderType, SessionContentType } from './types';
import {
  getDayMonthYearString,
  getWeekdayString,
  sessionClickHandler,
  capitalize,
  getEvenOddString,
  scrollTableToDate,
  getMissingCabinets,
  getTargetPeriod,
} from './functions';
import { viewTypes, weekDaysNames } from './settings';
import {
  RightArrowIcon,
  LeftArrowIcon,
  DisabledRightArrowIcon,
  DisabledLeftArrowIcon,
} from '../../../../../../uikit/Icons';
import { setCurrentSessionsMatch, setSessionWithTooltipId } from '../../reducer';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { useGetCabinetsDictionaryQuery, CabinetDictionaryItem } from '../../../../../../services/dictionaries';
import { ClickAwayListener } from '@material-ui/core';
import { BlockerWrapper } from '../../../../../../pages/CalendarPage/ScheduleTable/styled-components';

//Переключатель совпадений
export const SessionsMatchesSwitcher = () => {
  const dispatch = useAppDispatch();
  const { data: cabinetsDictionary = [] as CabinetDictionaryItem[] } = useGetCabinetsDictionaryQuery();
  const { sessionsMatches, currentSessionsMatch, datesToRender, staffSchedulerAPI } = useAppSelector(
    (state) => state.staffSchedule,
  );

  const selectedCabinets = staffSchedulerAPI.getOption('resources');

  const positionX = (selectedCabinets.length * 142) / 2 - 45;
  return (
    <MatchesSwitcher positionX={positionX}>
      {currentSessionsMatch > 0 ? (
        <LeftArrowIcon
          onClick={() => {
            dispatch(setCurrentSessionsMatch(currentSessionsMatch - 1));
            getMissingCabinets(
              sessionsMatches[currentSessionsMatch - 1][1],
              selectedCabinets,
              cabinetsDictionary,
              dispatch,
            );
            scrollTableToDate(sessionsMatches[currentSessionsMatch - 1][0], datesToRender, selectedCabinets);
          }}
        />
      ) : (
        <DisabledLeftArrowIcon />
      )}
      <span>{`${currentSessionsMatch + 1} из ${sessionsMatches.length}`}</span>
      {currentSessionsMatch < sessionsMatches.length - 1 ? (
        <RightArrowIcon
          onClick={() => {
            dispatch(setCurrentSessionsMatch(currentSessionsMatch + 1));
            getMissingCabinets(
              sessionsMatches[currentSessionsMatch + 1][1],
              selectedCabinets,
              cabinetsDictionary,
              dispatch,
            );
            scrollTableToDate(sessionsMatches[currentSessionsMatch + 1][0], datesToRender, selectedCabinets);
          }}
        />
      ) : (
        <DisabledRightArrowIcon />
      )}
    </MatchesSwitcher>
  );
};

//Компонент шапки колонки таблицы
export const ColumnHeader: ColumnHeaderType = (args, data) => {
  const { staffSchedulerAPI } = data;

  const currentSessionsMatch = staffSchedulerAPI.getOption('selectLongPressDelay');
  const sessionsMatches = staffSchedulerAPI.getOption('longPressDelay');
  const isWatchingMatches = staffSchedulerAPI.getOption('schedulerLicenseKey');
  const selectedCabinets = staffSchedulerAPI.getOption('resources');
  const tableColumnModel = staffSchedulerAPI.getOption('header');
  const showEvenDates = staffSchedulerAPI.getOption('eventSourceFailure');
  const showOddDates = staffSchedulerAPI.getOption('dayHeaderFormat');
  const datesToRender = new Set(staffSchedulerAPI.getOption('eventGroupField'));

  const displaySwitcherCheck =
    sessionsMatches.length > 1 && sessionsMatches[currentSessionsMatch][0] !== undefined
      ? isWatchingMatches &&
        ~~args.resource._resource.id === selectedCabinets[0].id &&
        args.date.getTime() === sessionsMatches[currentSessionsMatch][0]
      : false;

  switch (args.view.type) {
    case viewTypes.DISPLAY_BY_DAY:
      return (
        <>
          <TableUpperCell>
            {displaySwitcherCheck && <SessionsMatchesSwitcher />}
            <CabinetSpan>{args.resource._resource.title}</CabinetSpan>
          </TableUpperCell>
          <TableUpperCell>
            <TableUpperSpan>{getDayMonthYearString(args.date)}</TableUpperSpan>
          </TableUpperCell>
          <TableUpperCell>
            <TableUpperSpan>{`${capitalize(getWeekdayString(args.date))}${getEvenOddString(
              args.date,
              showEvenDates,
              showOddDates,
              'single',
            )}`}</TableUpperSpan>
          </TableUpperCell>
        </>
      );

    case viewTypes.DISPLAY_BY_PERIOD:
      if (datesToRender.has(args.date.getTime())) {
        const targetGroup = getTargetPeriod(tableColumnModel, args.date);
        return (
          <>
            <TableUpperCell>
              {displaySwitcherCheck && <SessionsMatchesSwitcher />}
              <CabinetSpan>{args.resource._resource.title}</CabinetSpan>
            </TableUpperCell>
            <TableUpperCell>
              {targetGroup.length === 1 ? (
                <TableUpperSpan>{getDayMonthYearString(args.date)}</TableUpperSpan>
              ) : (
                <TableUpperSpan>
                  {getDayMonthYearString(targetGroup[0])} - {getDayMonthYearString(targetGroup[targetGroup.length - 1])}
                </TableUpperSpan>
              )}
            </TableUpperCell>
            <TableUpperCell>
              {targetGroup.length === 1 ? (
                <TableUpperSpan>{`${getWeekdayString(args.date)}${getEvenOddString(
                  args.date,
                  showEvenDates,
                  showOddDates,
                  'single',
                )}`}</TableUpperSpan>
              ) : (
                <TableUpperSpan>{`${weekDaysNames[args.date.getDay()]}${getEvenOddString(
                  args.date,
                  showEvenDates,
                  showOddDates,
                  'plural',
                )}`}</TableUpperSpan>
              )}
            </TableUpperCell>
          </>
        );
      }
  }
};

//Содержимое рабочей сессии сотрудника
export const SessionContent: SessionContentType = (args, data, appointments) => {
  if (args.event._def.ui.display === 'block') {
    return <BlockerWrapper />;
  } else {
    return (
      <SessionWrapper bc={args.backgroundColor}>
        <SessionSpan fz={12}>{args.event._def.title}</SessionSpan>
        <SessionSpan fz={10}>{args.timeText}</SessionSpan>

        {data.sessionWithTooltipId === ~~args.event._def.publicId && (
          <ClickAwayListener onClickAway={() => data.dispatch(setSessionWithTooltipId(null))}>
            <SessionTooltip
              isBelow={
                `${args.event._instance.range.start.getHours()}` + `${args.event._instance.range.start.getMinutes()}` <
                '1330'
              }
            >
              <SessionTooltipSpan>В этой рабочей сессии присутствуют визиты.</SessionTooltipSpan>
              <SessionTooltipDeleteAnchor
                onClick={() => {
                  args.event.remove();
                  data.dispatch(setSessionWithTooltipId(null));
                }}
              >
                Всё равно удалить
              </SessionTooltipDeleteAnchor>
            </SessionTooltip>
          </ClickAwayListener>
        )}
        <SDeleteIcon onClick={() => sessionClickHandler(args, data, appointments)} />
      </SessionWrapper>
    );
  }
};
