import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { baseURL, HttpMethod } from '../utils/request';
import { format } from 'date-fns';

type MedcardJournalRequest = {
  patientId: number;
  entryId: number;
};

type TreatmentPlanRequest = MedcardJournalRequest & {
  planId: number;
};

type MedcardJournalTextFields = {
  formula_id: number;
  additional_info: string;
  anamnesis: string;
  bite: string;
  complaints: string;
  diagnosis: string;
  medical_exam_plan: string;
  phys_exam_data: string;
  planned_treatment_outcome: string;
  recommendations: string;
  treatment: string;
};

type MedcardMKbJournalField = {
  id: number;
  text: string;
  code: string;
};

type InvoiceHistoryItem = {
  id: number;
  count: number;
  date: string;
  invoice: number;
  invoice_row: number;
};

export type MedcardPlanJournalField = {
  id: number;
  price_code: string;
  price_title: string;
  price_value: number;
  count: number;
  discount: number;
  discount_type: 'RUB' | 'PERSENT';
  payment: number;
  is_used: boolean;
  date: string;
  plan?: number;
  invoice_history: InvoiceHistoryItem[];
};

export type TreatmentPlanJournal = {
  id: number;
  doctor: {
    id: number;
    first_name: string;
    last_name: string;
    second_name: string;
  };
  entry: {
    id: number;
    tooth_index: string;
    tooth_state_label?: string;
    tooth_state?: {
      icon_letter: string;
      title_russian: string;
    };
  };
  plan_rows: MedcardPlanJournalField[];
  status: string;
};

export const medcardJournalApi = createApi({
  reducerPath: 'medcardJournalApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['MedcardJournalTextFields', 'MedcardMKBJournalField', 'MedcardPlanJournalField'],
  endpoints: (builder) => ({
    getTextJournalFields: builder.query<MedcardJournalTextFields, MedcardJournalRequest>({
      query: ({ patientId, entryId }) => `patient/${patientId}/medcard/entries/${entryId}/fields`,
      providesTags: ['MedcardJournalTextFields'],
    }),
    updateTextJournalFields: builder.mutation<
      MedcardJournalTextFields,
      { fields: Partial<MedcardJournalTextFields>; request: MedcardJournalRequest }
    >({
      query: (data) => {
        const {
          fields,
          request: { patientId, entryId },
        } = data;
        return {
          url: `patient/${patientId}/medcard/entries/${entryId}/fields`,
          method: HttpMethod.PATCH,
          body: { ...fields },
        };
      },
      onQueryStarted({ fields, request: { patientId, entryId } }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          medcardJournalApi.util.updateQueryData('getTextJournalFields', { patientId, entryId }, () => ({ ...fields })),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    getMKBJournalField: builder.query<{ items: MedcardMKbJournalField[] }, MedcardJournalRequest>({
      query: ({ patientId, entryId }) => `patient/${patientId}/medcard/entries/${entryId}/fields/mkb`,
      providesTags: [{ type: 'MedcardMKBJournalField', id: 'LIST' }],
      transformResponse: (response: { items: MedcardMKbJournalField[] }) => response.items,
    }),
    addMKBRecord: builder.mutation<
      MedcardMKbJournalField,
      { record: Partial<MedcardMKbJournalField>; request: MedcardJournalRequest }
    >({
      query: (data) => {
        const {
          record,
          request: { patientId, entryId },
        } = data;
        return {
          url: `patient/${patientId}/medcard/entries/${entryId}/fields/mkb`,
          method: HttpMethod.POST,
          body: { ...record },
        };
      },
      invalidatesTags: [{ type: 'MedcardMKBJournalField', id: 'LIST' }],
    }),
    deleteMKBRecord: builder.mutation<MedcardMKbJournalField, { mkbId: number; request: MedcardJournalRequest }>({
      query: (data) => {
        const {
          mkbId,
          request: { patientId, entryId },
        } = data;
        return {
          url: `patient/${patientId}/medcard/entries/${entryId}/fields/mkb/${mkbId}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: [{ type: 'MedcardMKBJournalField', id: 'LIST' }],
    }),
    getPlanJournalField: builder.query<TreatmentPlanJournal[], MedcardJournalRequest>({
      query: ({ patientId, entryId }) => `patient/${patientId}/medcard/entries/${entryId}/fields/plan`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ entry: { id } }) => ({ type: 'MedcardPlanJournalField' as const, id })),
              'MedcardPlanJournalField',
            ]
          : ['MedcardPlanJournalField'],
      transformResponse: (response: TreatmentPlanJournal[]) => {
        return (
          response
            //.filter(r => !isEmpty(r.plan_rows));
            .map((p) => ({
              id: p.id,
              // Не стал менять местами last_name и second_name в хуке
              doctor: {
                id: p.doctor.id,
                firstName: p.doctor.first_name,
                lastName: p.doctor.last_name,
                secondName: p.doctor.second_name,
              },
              entry: {
                id: p.entry.id,
                toothIndex: p.entry.tooth_index || '',
                toothState: p.entry.tooth_state_label || p.entry.tooth_state.title_russian,
              },
              planRows: p.plan_rows.map((r) => ({
                id: r.id,
                date: format(new Date(r.date), 'dd.MM.yyyy'),
                code: r.price_code,
                text: r.price_title,
                price: +r.price_value,
                count: r.count,
                discount: r.discount,
                discountType: r.discount_type,
                payment: +r.payment,
                isUsed: r.is_used,
                invoiceHistory: r.invoice_history.map((inv) => ({
                  ...inv,
                  invoiceRowId: inv.invoice_row,
                })),
              })),
            }))
        );
      },
    }),
    addPlanRecord: builder.mutation<MedcardPlanJournalField, MedcardJournalRequest>({
      query: ({ patientId, entryId }) => {
        return {
          url: `patient/${patientId}/medcard/entries/${entryId}/fields/plan`,
          method: HttpMethod.POST,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'MedcardPlanJournalField', id: arg.entryId }],
    }),
    addPlanRecordRow: builder.mutation<
      MedcardPlanJournalField,
      { record: Partial<MedcardPlanJournalField>; request: TreatmentPlanRequest }
    >({
      query: (data) => {
        const {
          record,
          request: { patientId, entryId, planId },
        } = data;
        return {
          url: `patient/${patientId}/medcard/entries/${entryId}/fields/plan/${planId}/rows`,
          method: HttpMethod.POST,
          body: { ...record },
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'MedcardPlanJournalField', id: arg.request.entryId }],
    }),
    updatePlanRecord: builder.mutation<
      MedcardPlanJournalField,
      { record: Partial<MedcardPlanJournalField>; request: MedcardJournalRequest }
    >({
      query: (data) => {
        const {
          record,
          request: { patientId, entryId },
        } = data;
        return {
          url: `patient/${patientId}/medcard/entries/${entryId}/fields/plan/${record.id}`,
          method: HttpMethod.PATCH,
          body: { ...record },
        };
      },
    }),
    updatePlanRecordRow: builder.mutation<
      MedcardPlanJournalField,
      { record: Partial<MedcardPlanJournalField>; request: TreatmentPlanRequest }
    >({
      query: (data) => {
        const {
          record,
          request: { patientId, entryId, planId },
        } = data;
        return {
          url: `patient/${patientId}/medcard/entries/${entryId}/fields/plan/${planId}/rows/${record.id}`,
          method: HttpMethod.PATCH,
          body: { ...record },
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'MedcardPlanJournalField', id: arg.request.entryId }],
    }),
    deletePlanRecord: builder.mutation<MedcardPlanJournalField, { planId: number; request: MedcardJournalRequest }>({
      query: (data) => {
        const {
          planId,
          request: { patientId, entryId },
        } = data;
        return {
          url: `patient/${patientId}/medcard/entries/${entryId}/fields/plan/${planId}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'MedcardPlanJournalField', id: arg.request.entryId }],
    }),
    deletePlanRecordRow: builder.mutation<
      MedcardPlanJournalField,
      { planRowId: number; request: TreatmentPlanRequest }
    >({
      query: (data) => {
        const {
          planRowId,
          request: { patientId, entryId, planId },
        } = data;
        return {
          url: `patient/${patientId}/medcard/entries/${entryId}/fields/plan/${planId}/rows/${planRowId}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'MedcardPlanJournalField', id: arg.request.entryId }],
    }),
  }),
});

export const {
  useGetTextJournalFieldsQuery,
  useUpdateTextJournalFieldsMutation,
  useGetMKBJournalFieldQuery,
  useAddMKBRecordMutation,
  useDeleteMKBRecordMutation,
  useGetPlanJournalFieldQuery,
  useAddPlanRecordMutation,
  useDeletePlanRecordMutation,
  useAddPlanRecordRowMutation,
  useUpdatePlanRecordRowMutation,
  useDeletePlanRecordRowMutation,
} = medcardJournalApi;
