import React, { useCallback, memo } from 'react';
import { DateRangePicker } from '../../../../../uikit/DateRangePicker';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { setDateRangeExtended, setDateRangeV2 } from '../reducer';
import { SettingsTitle, CustomTooltip } from '../styled-components';

export const StaffScheduleDateRangePicker = () => {
  const dispatch = useAppDispatch();
  const currentStaffer = useAppSelector((state) => state.staffSchedule.currentStaffer);
  const dateRange = useAppSelector((state) => state.staffSchedule.dateRange);
  const datesInRange = useAppSelector((state) => state.staffSchedule.datesInRange);
  const staffSchedulerAPI = useAppSelector((state) => state.staffSchedule.staffSchedulerAPI);

  const nonWorkingDates = useAppSelector((state) =>
    state.staffSchedule.holidays.filter((holiday) => !holiday.is_workday).map((holiday) => holiday.date),
  );
  const nonWorkingWeekdays = useAppSelector((state) =>
    state.staffSchedule.workdays
      .filter((weekday) => !weekday.is_workday)
      .map((weekday) => (weekday.id === 7 ? 0 : weekday.id)),
  );

  const handleChange = useCallback(
    (range) => {
      if (Object.keys(staffSchedulerAPI).length) {
        if (Boolean(dateRange[1])) {
          dispatch(setDateRangeExtended(range));
        } else {
          dispatch(setDateRangeV2(range));
        }
      }
    },
    [dispatch, dateRange, staffSchedulerAPI],
  );

  return (
    <>
      <SettingsTitle style={{ marginTop: 34 }}>Задать расписание</SettingsTitle>
      <CustomTooltip
        open={currentStaffer.color && currentStaffer.color !== '#fff' && !dateRange[1]}
        disableHoverListener={true}
        title="Задайте временной интервал для настройки расписания сотрудника."
        arrow
        placement="right"
      >
        <DateRangePicker
          value={datesInRange.length ? [datesInRange[0], datesInRange[datesInRange.length - 1]] : [dateRange[0], null]}
          minDates={[
            new Date(),
            new Date(datesInRange[0] ? datesInRange[0].getTime() + 24 * 60 * 60 * 1000 : new Date()),
          ]}
          isDisabled={!currentStaffer.color || currentStaffer.color === '#fff'}
          onChange={(range) => {
            handleChange(range);
          }}
          nonWorkingDates={nonWorkingDates}
          nonWorkingWeekdays={nonWorkingWeekdays}
        />
      </CustomTooltip>
    </>
  );
};

export default memo(StaffScheduleDateRangePicker);
