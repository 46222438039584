import {
  SET_REF,
  START_SELECTING_TIME_RANGE,
  END_SELECTING_TIME_RANGE,
  MOUSE_MOVE,
  FLUSH_STATE,
  HIDE_BOX
} from "./types";
import { FLUSH_STATE as FLUSH_STATE_ALL } from "../flushState/types";
const INITIAL_STATE = {
  refs: {},
  timeRange: { visible: false, start: {}, end: {} },
  mouse: { x: 0, y: 0 }
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HIDE_BOX:
      return {
        ...state,
        ...{ timeRange: { visible: false, start: {}, end: {} } }
      };
    case SET_REF:
      return {
        ...state,
        ...{ refs: { ...state.refs, ...action.payload } }
      };
    case FLUSH_STATE:
      return INITIAL_STATE;

    case START_SELECTING_TIME_RANGE:
      return {
        ...state,
        ...{
          timeRange: { visible: true, start: action.payload, end: {} }
        }
      };
    case END_SELECTING_TIME_RANGE:
      return {
        ...state,
        ...{
          timeRange: { ...state.timeRange, ...{ end: action.payload } }
        }
      };
    case MOUSE_MOVE:
      return {
        ...state,
        ...{
          mouse: action.payload
        }
      };
    case FLUSH_STATE_ALL:
      return INITIAL_STATE;
    default:
      return state;
  }
}
