import TextInput from '../../../../uikit/TextInput';
import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type Props = {
  name: string;
  value: string;
  onChange: VoidFunction;
  onBlur?: VoidFunction;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '32px !important',
      marginBottom: 8,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
);

export function TextInputEllipsis({ name, value, onChange, onBlur }: Props) {
  const classes = useStyles();
  const [readOnly, setReadOnly] = useState<boolean>(false);

  return (
    <TextInput
      className={classes.root}
      name={name}
      value={value}
      readOnly={readOnly ? 'readonly' : ''}
      onChange={onChange}
      onClick={() => setReadOnly(false)}
      onBlur={(e) => {
        onBlur?.(e);
        setReadOnly(true);
      }}
    />
  );
}
