import {
  LOAD_TASKS,
  RELOAD_STATE,
  LOAD_DOCUMENTS,
  CHANGE_DOCUMENT_STATUS,
} from "../legacyState";
import defaults from "../../utils/defaults";
import { FLUSH_STATE } from "../flushState/types";
const INITIAL_STATE = { tasks: { items: [defaults.task] }, documents: [] };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_TASKS:
      return {
        ...state,
        ...{ tasks: action.payload },
      };
    case LOAD_DOCUMENTS:
      return {
        ...state,
        ...{ documents: action.payload },
      };
    case RELOAD_STATE:
      return {
        ...state,
        ...{ tasks: action.payload.user.tasks },
      };
    case CHANGE_DOCUMENT_STATUS:
      return {
        ...state,
        ...{ documents: action.payload },
      };
    case FLUSH_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
