import styled from 'styled-components';

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #253446;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const InputLabel = styled.label`
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #253446;
  margin: 0;
`;

export const UploadLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border: 1px dashed #828282;
  border-radius: 50%;
  background: white;
  cursor: pointer;
`;

export const UploadTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #577bf9;
`;

export const LogoWrapper = styled.div`
  margin-top: 24px;
  margin-left: 24px;
  min-width: 160px;
  min-height: 160px;
  max-width: 160px;
  max-height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 1px dashed #d3d6da;

  & > img {
    height: 100%;
    width: auto;
    display: inline;
    margin: 0 auto;
  }
`;

export const AddNewLogo = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(81, 93, 107, 0.6);
  backdrop-filter: blur(8px);
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;
