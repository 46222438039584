import styled from 'styled-components';

export const Form = styled.form`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  width: 576px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 24px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #253446;
  margin-bottom: 16px;
`;

export const SaveButton = styled.button`
  width: 102px;
  height: 32px;
  background: #577bf9;
  border-radius: 4px;
  color: #ffffff;
  border: 0;
  &:disabled {
    background: #eff0f3;
    border: 1px solid #d3d6da;
    border-radius: 4px;
    color: #a8aeb5;
  }
`;

export const CancelButton = styled.button`
  width: 82px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #577bf9;
  border-radius: 4px;
  color: #577bf9;
  margin-right: 20px;
`;