import { createSlice } from '@reduxjs/toolkit';
import { isToday } from 'date-fns';

const INITIAL_STATE = {
  dates: [],
  currentDateId: 0,
  expandedId: null,
  emptyToothStateError: false,
};

export function entryExpandReducer(state, action) {
  state.expandedId = action.payload;
}

export function changeDateReducer(state, action) {
  state.currentDateId = action.payload;
  state.expandedId = null;
}

export function setDatesReducer(state, action) {
  state.dates = action.payload;
}

export function addCurrentDateReducer(state, action) {
  state.dates = state.dates.map((d) => (isToday(new Date(d.date)) ? { id: action.payload, date: d.date } : d));
}

export function deleteCurrentDateReducer(state, action) {
  state.dates = state.dates.filter((d) => d.id !== action.payload);
}

export function setEmptyToothStateErrorReducer(state, action) {
  state.emptyToothStateError = action.payload;
}

const TeethFormula = createSlice({
  name: 'TeethFormula',
  initialState: INITIAL_STATE,
  reducers: {
    setDates: setDatesReducer,
    addCurrentDate: addCurrentDateReducer,
    deleteCurrentDate: deleteCurrentDateReducer,
    setExpanded: entryExpandReducer,
    pickDate: changeDateReducer,
    setEmptyToothStateError: setEmptyToothStateErrorReducer,
  },
});

export default TeethFormula;
