import React, { FC } from 'react';

const ConfirmedIcon: FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3703_106644)">
        <path
          d="M15.8281 9.68706C16.2768 5.84757 13.5274 2.37057 9.68794 1.92188C5.84844 1.4732 2.37144 4.22258 1.92276 8.06208C1.47408 11.9016 4.22346 15.3786 8.06296 15.8273C11.9025 16.2759 15.3795 13.5266 15.8281 9.68706ZM3.10223 8.19991C3.47475 5.01223 6.36242 2.72885 9.5501 3.10136C12.7378 3.47387 15.0212 6.36155 14.6487 9.54923C14.2761 12.7369 11.3885 15.0203 8.20079 14.6478C5.01311 14.2753 2.72972 11.3876 3.10223 8.19991Z"
          fill="#0D853D"
        />
        <path
          d="M15.8281 9.68706C16.2768 5.84757 13.5274 2.37057 9.68794 1.92188C5.84844 1.4732 2.37144 4.22258 1.92276 8.06208C1.47408 11.9016 4.22346 15.3786 8.06296 15.8273C11.9025 16.2759 15.3795 13.5266 15.8281 9.68706ZM3.10223 8.19991C3.47475 5.01223 6.36242 2.72885 9.5501 3.10136C12.7378 3.47387 15.0212 6.36155 14.6487 9.54923C14.2761 12.7369 11.3885 15.0203 8.20079 14.6478C5.01311 14.2753 2.72972 11.3876 3.10223 8.19991Z"
          fill="#0D853D"
        />
        <path
          d="M10.8515 11.9272L11.7737 11.1972C11.8391 11.1458 11.8936 11.0819 11.9343 11.0093C11.9749 10.9367 12.0007 10.8568 12.0104 10.7742C12.03 10.6058 11.9826 10.4398 11.8777 10.3072L11.0924 9.31403C11.0409 9.24867 10.9771 9.19411 10.9045 9.1535C10.8319 9.11289 10.752 9.08702 10.6694 9.07739C10.501 9.05771 10.335 9.10517 10.2024 9.21002L9.47611 9.78429C9.1584 9.59287 8.87792 9.34558 8.64821 9.05436C8.41716 8.76364 8.24043 8.43363 8.1265 8.08019L8.85275 7.50592C8.91812 7.45446 8.97267 7.3906 9.01328 7.318C9.05389 7.2454 9.07976 7.16549 9.08939 7.08287C9.10907 6.91448 9.06161 6.74848 8.95676 6.61588L8.17229 5.62201C8.12074 5.55656 8.05675 5.50195 7.98401 5.46133C7.91128 5.4207 7.83123 5.39486 7.74846 5.38528C7.58008 5.3656 7.41407 5.41306 7.28148 5.51791L6.36023 6.24638C6.14799 6.4135 6.00957 6.66239 5.97819 6.93088C5.97158 6.98752 5.96988 7.04239 5.97294 7.09702C6.02297 8.16315 6.4581 9.26564 7.19675 10.2007C7.93472 11.1348 8.90649 11.8116 9.93519 12.1088C10.2495 12.199 10.5917 12.1312 10.8515 11.9272Z"
          fill="#0D853D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.91594 8.17598C2.53131 11.4673 4.88765 14.4472 8.17896 14.8318C11.4703 15.2165 14.4502 12.8601 14.8348 9.56882C15.2195 6.27751 12.8631 3.29758 9.5718 2.91295C6.28049 2.52833 3.30056 4.88467 2.91594 8.17598ZM11.7726 11.1965L10.8504 11.9264C10.5906 12.1305 10.2484 12.1983 9.93406 12.108C8.90535 11.8108 7.93358 11.1341 7.19562 10.1999C6.45697 9.26492 6.02184 8.16243 5.97181 7.09629C5.96875 7.04166 5.97044 6.9868 5.97706 6.93016C6.00844 6.66167 6.14686 6.41277 6.3591 6.24565L7.28034 5.51719C7.41294 5.41234 7.57895 5.36488 7.74733 5.38456C7.83009 5.39413 7.91015 5.41998 7.98288 5.46061C8.05562 5.50123 8.1196 5.55584 8.17116 5.62129L8.95563 6.61516C9.06048 6.74775 9.10794 6.91376 9.08826 7.08214C9.07863 7.16477 9.05276 7.24468 9.01215 7.31728C8.97154 7.38988 8.91699 7.45374 8.85162 7.50519L8.12537 8.07947C8.23929 8.43291 8.41602 8.76292 8.64707 9.05364C8.87679 9.34486 9.15727 9.59215 9.47498 9.78357L10.2012 9.2093C10.3338 9.10445 10.4998 9.05699 10.6682 9.07667C10.7508 9.0863 10.8308 9.11217 10.9034 9.15278C10.976 9.19339 11.0398 9.24794 11.0913 9.31331L11.8766 10.3065C11.9815 10.4391 12.0289 10.6051 12.0092 10.7735C11.9996 10.8561 11.9737 10.936 11.9331 11.0086C11.8925 11.0812 11.838 11.1451 11.7726 11.1965Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3703_106644">
          <rect width="16" height="16" fill="white" transform="translate(17.75 1.85547) rotate(96.6654)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConfirmedIcon;
