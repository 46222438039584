import React, { useState } from 'react';
import TextInput from '../../../../uikit/TextInput';
import { Actions } from './TextTemplateItemBorder';
import { Row } from './TextTemplateItem';
import styled from 'styled-components';

const HeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #515d6b;
  line-height: 120%;
  margin-top: 20px;
  margin-left: 8px;
`;
const HeaderSubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #a8aeb5;
  line-height: 120%;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
`;

type Props = {
  onAddTemplate: VoidFunction;
};

export function EmptyTextTemplates({ onAddTemplate }: Props) {
  const [itemValue, setItemValue] = useState<string>('');

  return (
    <div>
      <HeaderTitle>Создайте свой шаблон</HeaderTitle>
      <HeaderSubTitle>Шаблоны помогают быстрее заполнить медицинскую карту</HeaderSubTitle>
      <Row style={{ paddingRight: 0 }}>
        <TextInput
          style={{ height: 32, marginLeft: -8 }}
          placeholder="Введите текст"
          value={itemValue}
          maxLength={500}
          onChange={(e) => {
            setItemValue(e.target.value);
          }}
        />
        <Actions>
          <img
            onClick={() => {
              onAddTemplate(itemValue);
            }}
            style={{ marginRight: 12 }}
            src={'./img/green/check.svg'}
            alt="save"
          />
        </Actions>
      </Row>
    </div>
  );
}
