import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  array: [],
};

const ScheduleSlice = createSlice({
  name: "schedule",
  initialState: INITIAL_STATE,
  reducers: {
    loadSchedule: (state, action) => ({
      ...state,
      array: action.payload,
    }),
  },
});

export default ScheduleSlice;
