/* eslint-disable react/display-name */
import React, { useState, useEffect, forwardRef, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Input from '@material-ui/core/Input';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Close from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import { styled as styledMui } from '@material-ui/styles';
import styled from 'styled-components';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { OutlinedInput } from '../OutlinedInput';

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          background: 'white',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popperDisablePortal: {
          margin: '4px 0px !important',
        },
        listbox: {
          minHeight: 50,
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            width: 4,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
            borderLeft: '4px solid #D3D6DA',
            height: '73px',
            borderRadius: 4,
          },
        },
        popupIndicator: {
          marginRight: 5,
        },
        option: {
          '&.Mui-focused': {
            background: '#EFF2FF !important',
          },
        },
      },
    },
  },
});

const CloseIcon = styledMui(Close)({
  width: '20px',
  height: '20px',
  color: '#515D6B',
  borderLeft: 'solid 1px #E5E6EA',
  marginLeft: '4px',
  paddingLeft: '4px',
});
const SearchIcon = styledMui(Search)({
  color: '#D3D6DA',
  position: 'absolute',
  right: 12,
  top: 12,
  height: '20px',
});
const Chip = styled.div`
  display: flex;
  align-items: center;
  background: #f6f6fa;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  padding: 4px 8px;
  width: fit-content;
`;
const Option = styled.li`
  font-size: 14px;
  height: 24px;
  color: #515d6b;
  margin-top: 4px;
  &:nth-child(2) {
    margin-top: 44px;
  }
  &:hover {
    background: #f6f6fa !important;
  }
`;
const Divider = styled.div`
  border-bottom: 1px solid #f6f6fa;
  height: 32px;
  margin: 12px;
`;
const SearchInput = styled.div`
  position: fixed;
  background: white;
  height: 44px;
  width: 100%;
  top: 0;
`;

// todo: вынести в общий файл с типами
type SomeObject = { [k: string]: unknown };

interface MultiSelectProps {
  data: string[] | SomeObject[];
  textField?: string;
  placeholder: string;
  searchPlaceholder: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any[]) => void;
  isDisabled?: boolean;
  closeOnSelect?: boolean;
}

export const MultiSelect = forwardRef<HTMLInputElement, MultiSelectProps>(
  (
    {
      data: dataProps,
      placeholder,
      searchPlaceholder,
      onChange,
      textField,
      isDisabled = false,
      closeOnSelect = false,
      initialValue,
      isWatchingMatches,
      sessionsMatches,
      currentSessionsMatch,
      ...rest
    },
    ref,
  ) => {
    const data = [textField ? { [textField]: '' } : '', ...dataProps];
    const [value, setValue] = useState(initialValue);
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
          document.removeEventListener('click', handleClickOutside, true);
          setIsOpen(false);
        }
      };
      if (isOpen) {
        document.addEventListener('click', handleClickOutside, true);
      }
    }, [isOpen]);

    const handleChange = (_, value) => {
      closeOnSelect && setIsOpen(false);
      setValue(value);
      onChange(value);
    };

    return (
      <div ref={inputRef}>
        <ThemeProvider theme={theme}>
          <Autocomplete
            ref={ref}
            {...rest}
            multiple
            disabled={isDisabled}
            open={isOpen}
            value={initialValue}
            onChange={handleChange}
            options={data}
            getOptionLabel={(option) => (textField ? option[textField] : option)}
            clearIcon={null}
            popupIcon={<ExpandMore style={{ backgroundColor: isDisabled ? '#F6F6FA' : 'white', width: 20 }} />}
            disablePortal
            inputValue={search}
            filterOptions={(options, state) =>
              options.filter((option) =>
                textField
                  ? option[textField].toLowerCase().includes(state.inputValue.toLowerCase()) || option[textField] === ''
                  : option.toLowerCase().includes(state.inputValue.toLowerCase()) || option === '',
              )
            }
            renderTags={(value, getTagProps) => (
              <div>
                {value.map((option, index) => (
                  <Chip key={textField ? option[textField] : option} {...getTagProps({ index })}>
                    {textField ? option[textField] : option}
                    {isWatchingMatches &&
                    sessionsMatches[currentSessionsMatch][1].includes(option.id.toString()) ? null : (
                      <CloseIcon
                        onClick={(ev) => {
                          getTagProps({ index }).onDelete(ev);
                        }}
                      />
                    )}
                  </Chip>
                ))}
              </div>
            )}
            renderOption={(props, option) => {
              if (textField ? option[textField] === '' : option === '') {
                return (
                  <SearchInput>
                    <Input
                      style={{ position: 'fixed', padding: '6px 12px', fontSize: '14px' }}
                      placeholder={searchPlaceholder}
                      value={search}
                      onChange={(ev) => setSearch(ev.target.value)}
                      onClick={(ev) => ev.target.focus()}
                      disableUnderline={true}
                    />
                    <SearchIcon />
                    <Divider />
                  </SearchInput>
                );
              }
              return (
                <Option
                  {...props}
                  key={textField ? option[textField] : option}
                  style={{
                    backgroundColor: value.includes(option) && '#f6f6fa',
                    pointerEvents:
                      isWatchingMatches && sessionsMatches[currentSessionsMatch][1].includes(option.id.toString())
                        ? 'none'
                        : 'initial',
                  }}
                >
                  {textField ? option[textField] : option}
                </Option>
              );
            }}
            renderInput={({ inputProps, InputProps }) => (
              <OutlinedInput
                disabled={isDisabled}
                style={{
                  width: '100%',
                  padding: value.length === 0 ? '4px 8px' : 0,
                  backgroundColor: isDisabled ? '#F6F6FA' : 'white',
                }}
                {...InputProps}
                inputProps={inputProps}
                disableUnderline={true}
                inputIsVisible={!search}
                readOnly={value.length > 0}
                onClick={!isDisabled && (() => setIsOpen(!isOpen))}
                placeholder={isOpen || value.length > 0 ? '' : placeholder}
              />
            )}
          />
        </ThemeProvider>
      </div>
    );
  },
);
