import WithSuggestions from '../../components/Suggestions/WithSuggestions';
import TextInput from '../../uikit/TextInput';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SuggestionsState from '../../components/Suggestions/state';
import { pluck, compose } from 'ramda';
import cities from '../../utils/city.json';
import { RootState } from '../../store';

function CitySelect({ value, onBlur, placeholder, openMenu, closeMenu, medCardPageSlice }) {
  useEffect(
    () => () => closeMenu(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const [adsChannel, setAdsChannel] = useState(value);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getSuggestions = compose<any[], string[]>(pluck('city'));
  const suggestions = getSuggestions(cities);

  return (
    <div
      style={{ width: '100%' }}
      onClick={() => {
        medCardPageSlice.patientDetails.address_country === 'Россия' && openMenu('city');
      }}
    >
      <WithSuggestions
        name={'city'}
        suggestions={suggestions}
        value={adsChannel === value ? '' : adsChannel}
        handlePick={(value: string) => {
          setAdsChannel(value);
          onBlur({
            target: { value },
          });
        }}
      >
        <div style={{ height: 30 }}>
          <TextInput
            placeholder={placeholder}
            onBlur={onBlur}
            value={adsChannel}
            onChange={(e) => setAdsChannel(e.target.value)}
          />
        </div>
      </WithSuggestions>
    </div>
  );
}

export default connect((state: RootState) => ({ medCardPageSlice: state.medCardPageSlice }), {
  openMenu: SuggestionsState.actions.openMenu,
  closeMenu: SuggestionsState.actions.closeMenu,
})(CitySelect);
