export const SET_DOCTOR = '@staff/set_doctor';
export const FETCH_STAFF_SUCCESS = '@staff/load_staff_success';
export const SELECT_DOCTOR = '@staff/select_staffers';

/**
 * Данные сотрудника
 */
export type TStaffData = {
  email: string;
  first_name: string;
  id: number;
  job: string;
  last_name: string;
  phone: string;
  second_name: string;
  is_active: boolean;
  editor: string;
};

/**
 * Модель колонки таблицы расписания сотрудника
 */
export interface StaffScheduleColumnView {
  /** Период в строковом представлении */
  period: string;
  /** Начальная дата периода */
  startDate: Date;
  /** Конечная дата периода */
  endDate: Date;
  /** Номер дня недели, соответствующего периоду */
  weekDay: number;
}

/**
 * Параметры обновления графика сотрудника
 */
export interface HandleScheduleUpdateParams {
  /** Строка со временем начала рабочего дня ("09:00") */
  startTime: string;
  /** Дата начала рабочего периода */
  startDate: Date;
  /** Дата конца рабочего периода */
  endDate: Date;
  /** Код кабинета */
  cabinetCode: string;
  /** Номер дня недели, соответствующего периоду */
  weekDay: number;
}
