import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ACTIVE_CLASS, MODAL, ROOT_CONTAINER } from './constants';
import { RowExtendedType } from '../../components/Table';

export const useScroll = (modalRef) => {
  const scrollTop = useCallback(() => {
    const modal = document.getElementById(MODAL);
    if (!modal) {
      return;
    }
    const { clientHeight } = modalRef.current.getValues();
    const modalOffSetTop = modal.getBoundingClientRect().top;
    const container = document.getElementById(ROOT_CONTAINER);
    const containerOffSetTop = container.getBoundingClientRect().top;
    const offSetTop = modalOffSetTop - containerOffSetTop;
    if (clientHeight >= offSetTop + modal.offsetHeight) return;
    modalRef.current.scrollTop(offSetTop + modal.offsetHeight - clientHeight);
  }, [modalRef]);
  return scrollTop;
};

export const useCommentFormHeight = (modalRef) => {
  const [modalHeight, setModalHeight] = useState<number>(300);

  const clientHeight = modalRef?.current?.getValues()?.clientHeight;

  useEffect(() => {
    if (modalRef && modalRef.current) {
      setModalHeight(clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientHeight, modalRef.current]);

  return { modalHeight, setModalHeight };
};

export const useCommentForm = () => {
  const [commentForm, setCommentForm] = useState<null | HTMLElement>(null);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<number | null>(null);

  const onShowCommentFormHandler = useCallback(
    (row: RowExtendedType<Record<string, unknown>>) => {
      const anchor = row.parentRowElement;
      setCommentForm(anchor);
      anchor.classList.add(ACTIVE_CLASS);
      setCurrentId(row.values.id);
    },
    [setCommentForm, setCurrentId],
  );

  const handleCommentModalClose = useCallback(() => {
    commentForm.classList.remove(ACTIVE_CLASS);
    setCommentForm(null);
    setShowComments(false);
  }, [commentForm, setCommentForm, setShowComments]);

  const handleCommentListOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>, elementId: number) => {
      if (commentForm) {
        handleCommentModalClose();
      }
      const parentElement = event.currentTarget.offsetParent;
      setCommentForm(parentElement);
      parentElement.classList.add(ACTIVE_CLASS);
      setShowComments(true);
      setCurrentId(elementId);
    },
    [commentForm, handleCommentModalClose, setCommentForm, setShowComments, setCurrentId],
  );

  return useMemo(
    () => ({
      commentForm,
      onShowCommentFormHandler,
      handleCommentModalClose,
      showComments,
      currentId,
      handleCommentListOpen,
    }),
    [commentForm, onShowCommentFormHandler, handleCommentModalClose, showComments, currentId, handleCommentListOpen],
  );
};
