import {
  LOGIN_SAVE,
  SET_CABINET,
  PICK_TASK,
  LOAD_SUGGESTIONS,
  UPDATE_PERMISSIONS,
  SET_REDIRECTED,
  UPDATE_USER,
  SET_STUDY_MODE,
  SET_SESSIONS,
} from '../legacyState';
import { SAVE_PATIENT } from '../calendar/actions_calendar';
import { User } from '../../services/users';

type LoginState = {
  isAuth: boolean;
  user: User | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patient: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cabinet: any;
  redirected: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  task: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  suggestions: any[];
  isStudyMode: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sessions: any[];
};

const INITIAL_STATE: LoginState = {
  isAuth: false,
  user: null,
  patient: {},
  cabinet: '',
  redirected: false,
  task: { index: 0 },
  suggestions: [],
  isStudyMode: !!localStorage.getItem('is_study_mode'),
  sessions: [],
};
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_REDIRECTED:
      return {
        ...state,
        ...{ redirected: action.payload },
      };
    case LOGIN_SAVE:
      return {
        ...state,
        ...action.payload.data,
        ...{ isAuth: action.payload.isAuth },
      };
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload.data,
      };
    case SET_CABINET:
      return {
        ...state,
        ...{ cabinet: action.payload },
      };
    case PICK_TASK:
      return {
        ...state,
        ...{ task: action.payload },
      };
    case LOAD_SUGGESTIONS:
      return {
        ...state,
        ...{ suggestions: action.payload },
      };
    case SAVE_PATIENT:
      return {
        ...state,
        ...{ patient: { ...action.payload } },
      };
    case UPDATE_PERMISSIONS:
      return {
        ...state,
        ...{ user: { ...state.user, ...{ permissions: action.payload } } },
      };
    case SET_STUDY_MODE:
      return {
        ...state,
        ...{ isStudyMode: action.payload },
      };
    case SET_SESSIONS:
      return {
        ...state,
        ...{ sessions: action.payload },
      };
    default:
      return state;
  }
}
