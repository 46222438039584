import { PayerCodes, PaymentTypeCodes } from '../../../../services/dictionaries';

export const getCurrentPayerType = (currentPaymentType: string) => {
  const individualTypes = new Set([`${PaymentTypeCodes.BANK}`, `${PaymentTypeCodes.CARD}`, `${PaymentTypeCodes.CASH}`]);
  const insuranceTypes = new Set([`${PaymentTypeCodes.INS_REQ}`, `${PaymentTypeCodes.INS_VOL}`]);
  if (!currentPaymentType) return '';
  if (currentPaymentType === PaymentTypeCodes.PREPAID) return PayerCodes.PATIENT;
  if (individualTypes.has(currentPaymentType)) return PayerCodes.INDIVIDUAL;
  if (insuranceTypes.has(currentPaymentType)) return PayerCodes.INSURANCE;
};
