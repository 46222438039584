import { SET_DOCTOR, FETCH_STAFF_SUCCESS, SELECT_DOCTOR } from './types';

export function setDoctor(doctor) {
  return { type: SET_DOCTOR, payload: doctor };
}

export function selectDoctor(doctor) {
  return { type: SELECT_DOCTOR, payload: doctor };
}

export function loadResponce(data) {
  return {
    type: FETCH_STAFF_SUCCESS,
    payload: data,
  };
}
