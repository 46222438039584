/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars, react-hooks/exhaustive-deps */

import React, { useRef, LegacyRef, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { ResourceSourceInput } from '@fullcalendar/resource';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ColumnHeader, SessionContent } from './components';
import { SchedulerStyleWrapper, FCWidthWrapper, ScheduleViewControls, CustomRadio } from './styled-components';
import {
  viewSwitchHandler,
  checkForHideCells,
  sessionColorSetter,
  sessionDropHandler,
  getTableModelAndWidth,
  selectionHandler,
  sessionResizeHandler,
  getSessionsMatches,
  getDatesToHighlight,
  halfColumnScroll,
  getDatesWithSessionsV3,
  getDatesToHighlightSet,
} from './functions';
import { views, headerToolbar, slotLabelFormat, constraint, getVisibleRange } from './settings';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { setSchedulerAPI, setTableDisplayMode } from '../../reducer';
import { InitialSession } from './types';

const StaffSchedulerTable: React.FC<InitialSession[]> = ({
  appointments,
  staffers,
  schedule,
  currentStaffer,
  isTableHidden,
}) => {
  const tableDisplayMode = useAppSelector((state) => state.staffSchedule.tableDisplayMode);

  const calendarRef: LegacyRef<FullCalendar> = useRef(null);
  const dispatch = useAppDispatch();

  const data = { dispatch };

  //Если не выбрана вторая дата в выборщике дат или не выбран кабинет - таблица будет спрятана
  const hidingStyle = { display: isTableHidden ? 'none' : 'block' };

  // Помещение API экземпляра таблицы в Redux. Делается один раз, после получения рефа таблицы.
  useEffect(() => {
    if (calendarRef.current) {
      dispatch(setSchedulerAPI(calendarRef?.current?.getApi()));
    }
    return () => dispatch(setSchedulerAPI(null));
  }, [calendarRef]);

  return (
    <>
      <ScheduleViewControls
        value={tableDisplayMode}
        style={hidingStyle}
        onChange={(e) => dispatch(setTableDisplayMode(e.target.value))}
      >
        <FormControlLabel value="displayByDay" control={<CustomRadio />} label="по дням" />
        <FormControlLabel
          value="displayByPeriod"
          style={{ marginLeft: 16 }}
          control={<CustomRadio />}
          label="по периодам"
        />
      </ScheduleViewControls>

      <SchedulerStyleWrapper id="TableWrapperToScroll" style={hidingStyle}>
        <div id="FCWidthWrapper">
          <FullCalendar
            timeZone="local"
            eventOrder="start"
            slotMinTime="09:00:00"
            slotMaxTime="23:00:00"
            eventConstraint={constraint}
            selectConstraint={constraint}
            plugins={[interactionPlugin, resourceTimeGridPlugin]}
            ref={calendarRef}
            locale={ruLocale}
            views={views}
            headerToolbar={headerToolbar}
            slotLabelFormat={slotLabelFormat}
            contentHeight={994}
            expandRows={true}
            datesAboveResources={true}
            editable={true}
            eventStartEditable={true}
            eventResizableFromStart={true}
            eventDurationEditable={true}
            eventResourceEditable={true}
            selectable={true}
            dragScroll={true}
            stickyHeaderDates={true}
            allDaySlot={false}
            eventOverlap={false}
            selectOverlap={false}
            eventContent={(args) =>
              SessionContent(args, { ...data, staffSchedulerAPI: calendarRef?.current?.getApi() }, appointments)
            }
            resourceLabelContent={(args) =>
              ColumnHeader(args, { ...data, staffSchedulerAPI: calendarRef?.current?.getApi() })
            }
            select={(args) => selectionHandler(args, { ...data, staffSchedulerAPI: calendarRef?.current?.getApi() })}
            eventDrop={(args) =>
              sessionDropHandler(args, { ...data, staffSchedulerAPI: calendarRef?.current?.getApi() }, staffers)
            }
            eventResize={(args) =>
              sessionResizeHandler(args, { ...data, staffSchedulerAPI: calendarRef?.current?.getApi() })
            }
            selectAllow={(args) => halfColumnScroll(args)}
            eventsSet={(args) =>
              setTimeout(() => getDatesToHighlightSet(args, dispatch, calendarRef?.current?.getApi()))
            }
          />
        </div>
      </SchedulerStyleWrapper>
    </>
  );
};

export default React.memo(StaffSchedulerTable);
