import React from "react";
import {DotsIcon} from "../../../uikit/Icons";
import {Menu, MenuItem} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      menu: {
        borderRadius: 4,
        boxShadow: '0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16)',
      },
      menuList: {
        padding: '8px 0',
        lineHeight: 22,
        '& .MuiMenuItem-root': {
          color: '#515D6B',
          fontSize: 14,
          padding: 12,
          '&:hover': {
            background: '#f6f6fa'
          }
        },
        '& .Mui-focusVisible': {
          background: 'white'
        }
      }
    }
  ));

type Action = {
  name: string;
  text: string;
  handler: Function;
}

type Props = {
  actions: Action[];
}

export function PopupMenu({actions}: Props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <DotsIcon
        aria-controls="popup-menu"
        aria-haspopup="true"
        onClick={handleClick}
      />
      <Menu
        classes={{ paper: classes.menu, list: classes.menuList }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {actions.map((action) =>
          <MenuItem key={action.name} disableTouchRipple onClick={() => {
            action.handler();
            handleClose();
          }}>{action.text}</MenuItem>
        )}
      </Menu>
    </>
  );
}
