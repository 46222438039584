import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PriceSlice from "../state";
const { setAddBtnPosition, startEditingEntry } = PriceSlice.actions;

type LayoutProps = {
  lastRow: boolean;
};
const Layout = styled.div<LayoutProps>`
  position: absolute;
  top: ${({ lastRow }) => (lastRow ? "39px" : "-27px")};
  left: -20px;
  width: 1096px;
  height: 15px;
  z-index: 6;
`;

function PickRegion({
  priceSlice,
  entry,
  index,
  itemIndex,
  onClick,
  setAddBtnPosition,
  lastRow = false,
}: any) {
    const selfRef = useRef({} as any);

    useEffect(() => {
      const responseFilter = priceSlice.response.filter(res => res.category === priceSlice.pickedCategory.id);
      responseFilter.length === 0 &&
      parseInt(index) + parseInt(itemIndex) === 0 &&
      setAddBtnPosition({
        offsetTop: selfRef.current.offsetTop,
        offsetLeft: selfRef.current.offsetLeft,
        lastRow,
        entry,
        itemIndex,
      });
  }, [priceSlice.response.length, priceSlice.pickedCategory.id]);
  return (
    <div
      ref={selfRef}
      style={{ position: "relative" }}
      onMouseEnter={() =>
        setAddBtnPosition({
          offsetTop: selfRef.current.offsetTop,
          offsetLeft: selfRef.current.offsetLeft,
          lastRow,
          entry,
          itemIndex,
        })
      }
      onClick={onClick}
    >
      <Layout lastRow={lastRow} />
    </div>
  );
}

export default connect((state: any) => ({ priceSlice: state.priceSlice }), {
  setAddBtnPosition: setAddBtnPosition,
})(PickRegion);
