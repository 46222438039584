import React from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ru from 'date-fns/locale/ru';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  ArrowWrapper,
  CalendarFooter,
  CalendarWrapper,
  CustomCalendar,
  YearControls,
  YearControlsWrapper,
} from './styles';
import { PickerOnChangeFn } from '@mui/lab/internal/pickers/hooks/useViews';
import getWeeksCount from '../../../../utils/getWeeksCount';

interface CalendarProps {
  disableFuture?: boolean;
  onDisableDates?: (newDate: Date) => boolean;
  value?: Date | null;
  onDateChange?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  onMonthChange?: () => null;
  onYearChange?: () => null;
  selectedColor?: string;
}

export const Calendar: React.FC<CalendarProps> = ({
  children,
  disableFuture,
  onDisableDates,
  value,
  onDateChange,
  minDate = null,
  maxDate = null,
  onMonthChange = () => null,
  onYearChange = () => null,
  selectedColor,
}) => {
  //При переключении месяцев/открытии календаря производится перерасчёт высоты контейнера с днями месяца:
  //Иногда в календаре может отображаться 5 недель (строк с датами), а иногда - 6
  //Средствами CSS эту проблему не решить
  const CalculateDaysContainerHeight = (date) => {
    setTimeout(() => {
      const weeksCount = getWeeksCount(new Date(date).getFullYear(), new Date(date).getMonth() + 1);
      const targetElements = document.getElementsByClassName('MuiCalendarPicker-root');
      targetElements[0].style.height = `${weeksCount === 5 ? weeksCount * 45 : weeksCount * 41}px`;
    });
  };

  const handleDateChange = (newDate: Date) => {
    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  const theme = createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableTouchRipple: true,
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CalendarWrapper>
        <YearControls>
          <YearControlsWrapper>
            <ArrowWrapper>
              <KeyboardArrowUpIcon
                style={{ width: 15, height: 15 }}
                onClick={() => {
                  onYearChange(value.getFullYear() + 1);
                  onDateChange(new Date(value.getFullYear() + 1, value.getMonth(), value.getDate()));
                }}
              />
            </ArrowWrapper>
            <ArrowWrapper>
              <KeyboardArrowDownIcon
                style={{
                  width: 15,
                  height: 15,
                }}
                onClick={() => {
                  onYearChange(value.getFullYear() - 1);
                  onDateChange(new Date(value.getFullYear() - 1, value.getMonth(), value.getDate()));
                }}
              />
            </ArrowWrapper>
          </YearControlsWrapper>
        </YearControls>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
          <CustomCalendar
            date={value}
            onChange={handleDateChange as PickerOnChangeFn<unknown>}
            views={['day']}
            shouldDisableDate={onDisableDates as (day: unknown) => boolean}
            selectedColor={selectedColor}
            minDate={minDate}
            maxDate={maxDate}
            disableFuture={disableFuture}
            onMonthChange={(date) => {
              CalculateDaysContainerHeight(date);
              onMonthChange(date.getMonth());
              onYearChange(date.getFullYear());
            }}
          />
        </LocalizationProvider>
        <CalendarFooter>{children}</CalendarFooter>
      </CalendarWrapper>
    </ThemeProvider>
  );
};
