import React, { FC } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { routerConfig } from '../../../navigation/routerConfig';

const Container = styled.div`
  width: 100%;
  background: white;
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 100;
  box-shadow: 0 -5px 10px -5px #d9d9d9;
`;

const SaveButton = styled.button`
  background: #577bf9;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: 0;
  width: 210px;
  border-radius: 4px;
  height: 32px;
  margin-right: 72px;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const Footer: FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <SaveButton onClick={() => navigate(routerConfig.SCHEDULE.path)}>Завершить редактирование</SaveButton>
    </Container>
  );
};

export default Footer;
