import Input from '@material-ui/core/Input';
import { styled as styledMui } from '@material-ui/styles';

interface OutlinedInputProps {
  inputIsVisible?: boolean;
}

export const OutlinedInput = styledMui(Input)(({ inputIsVisible = true }: OutlinedInputProps) => ({
  border: '1px solid #D3D6DA',
  padding: '8px 7px',
  borderRadius: '4px',
  width: '92px',
  fontSize: '14px',
  boxSizing: 'border-box',
  color: '#515D6B',
  background: 'white',
  marginBottom: '3px',
  '& .MuiInputBase-input': {
    padding: 0,
    margin: 0,
    visibility: inputIsVisible ? 'visible' : 'hidden',
  },
  '&.Mui-focused': {
    border: '1px solid #577BF9',
    filter: 'drop-shadow(0px 0px 4px rgba(87, 123, 249, 0.5))',
  },
}));
