import styled from 'styled-components';
import { IRowProps } from './types';

export const Row = styled.div<IRowProps>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${({ isColored }) => (isColored ? '#F9F9F9' : 'white')};
  border-bottom: 1px solid #d3d6da;

  &:hover {
    background-color: ${({ isSelected }) => (!isSelected ? '#EFF2FF' : 'white')};

    .hover-tip {
      background-color: ${({ isSelected }) => (!isSelected ? '#EFF2FF !important' : 'white')};
    }
  }
`;

export const ColumnContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px;
`;

export const Col = styled.div<{ ml: number; w?: number | string; h?: number | string }>`
  margin-left: ${({ ml }) => `${ml}px`};
  ${({ w }) => (w ? `width: ${w}${typeof w === 'number' ? 'px' : ''}` : '')};
  ${({ h }) => (h ? `height: ${h}${typeof h === 'number' ? 'px' : ''}` : '')};
  overflow: hidden;
`;

export const EditWrapper = styled.button`
  height: 25px;
  width: 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #d3d6da;
  border-radius: 4px;
  background: #fff;
  outline: none !important;
`;
