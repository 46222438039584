import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import DoneAll from '@material-ui/icons/DoneAll';
import { format } from 'date-fns';

export default function MessageBubble({ text, left, date, isNew }) {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: left ? 'flex-start' : 'flex-end',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            margin: 5,
            marginLeft: left ? 16 : 0,
            marginRight: left ? 0 : 16,
            minWidth: 30,
            maxWidth: 300,
            // maxHeight: 300,
            minHeight: 30,
            backgroundColor: left ? '#EFF2FF' : '#EFF0F3',
            padding: 14,
            color: 'rgb(38,38,38)',
            borderRadius: 30,
            borderBottomLeftRadius: left ? 10 : 30,
            borderBottomRightRadius: left ? 30 : 10,
            fontSize: 12,
            wordWrap: 'break-word',
            fontStyle: 'normal',
            fontWeight: 'normal',
          }}
        >
          {text}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: left ? 'flex-start' : 'flex-end',
        }}
      >
        <div
          style={{
            fontSize: 9,
            fontStyle: 'normal',
            fontWeight: 'normal',
            color: 'rgba(38, 38, 38, 0.6)',
            marginLeft: 16,
          }}
        >
          {format(new Date(date), 'HH:mm')}
        </div>
        {!left && (
          <div
            style={{
              color: isNew ? 'rgba(145, 145, 145, 0.7)' : '#5BB459',
              fontSize: 14,
              marginLeft: 5,
              marginTop: -6,
              marginRight: 16,
            }}
          >
            {isNew ? <CheckIcon color="inherit" fontSize="inherit" /> : <DoneAll color="inherit" fontSize="inherit" />}
          </div>
        )}
      </div>
    </div>
  );
}
