import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Modal } from '@material-ui/core';
import { useGetUserQuery } from '../../services/users';
import { ChangeLogin } from './components/ChangeLogin';
import { ChangeOrganization } from './components/ChangeOrganization';

export enum EStep {
  LOGIN,
  ORGANIZATION,
}

const WelcomeModal = () => {
  const user = useSelector((state: RootState) => state.common.user);
  const { data } = useGetUserQuery(user?.id, { skip: !user?.id });

  const [step, setStep] = useState<EStep>(EStep.LOGIN);

  if (!data?.is_password_reset) {
    return null;
  }

  return (
    <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} open={true}>
      <div>
        {step === EStep.LOGIN && <ChangeLogin setStep={setStep} />}
        {step === EStep.ORGANIZATION && <ChangeOrganization />}
      </div>
    </Modal>
  );
};

export default WelcomeModal;
