import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  array: [],
  timeData: {},
};

const TasksSlice = createSlice({
  name: "tasks",
  initialState: INITIAL_STATE,
  reducers: {
    startCreatingTask: (state, action) => ({
      ...state,
      timeData: { ...action.payload },
    }),
    cancelCreatingTask: (state) => ({ ...state }),
    saveTaskTimeData: (state, action) => ({
      ...state,
      timeData: { ...action.payload },
    }),
    loadTasks: (state, action) => ({
      ...state,
      array: [...state.array, ...action.payload],
    }),
    createNewTask: (state, action) => ({
      ...state,
      array: [...state.array, action.payload],
    }),
  },
});

export default TasksSlice;
