import styled from "styled-components";

export const MessageContainer = styled.div`
  padding-top: 56px;
  padding-bottom: 26px;
  padding-left: 22px;
  padding-right: 72px;
  width: 100%;
  height: 100%;
  display: flex;
  row-gap: 16px;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #253446;
  margin-top: 16px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #a8aeb5;
  width: 304px;
  margin-top: 16px;
`;