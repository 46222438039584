import styled from 'styled-components';

export const DateRangePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Space = styled.div`
  width: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  color: #d3d6da;
`;