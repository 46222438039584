import React from "react";
import {createStyles, withStyles} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import {Theme} from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";

export const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #E8E8E8',
    minHeight: 'auto',
    height: 40,
  },
  indicator: {
    backgroundColor: '#577BF9',
    bottom: 0,
  },
  scrollButtons: {
    width: 40,
    background: '#FFFFFF',
    '&:last-of-type': {
      boxShadow: 'inset 1px 0 0 #E8E8E8',
    },
    '&:first-of-type': {
      boxShadow: '1px 0 0 #E8E8E8',
    },
    '&.Mui-disabled': {
      display: 'none'
    }
  }
})(Tabs);

interface StyledTabProps {
  label: string;
}

export const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: 'normal',
      fontSize: 14,
      textTransform: 'none',
      minWidth: 72,
      minHeight: 40,
      marginRight: 24,
      '&:hover': {
        color: '#577BF9',
        opacity: 1,
      },
      '&$selected': {
        color: '#577BF9',
        fontWeight: 500,
        outline: 'none',
      },
      '&:focus': {
        color: '#577BF9',
      },
      padding: '6px 2px'
    },
    selected: {},
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);
