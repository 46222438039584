import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { InputLabel } from '@material-ui/core';
import styled from 'styled-components';
import TextInput from '../../../uikit/TextInput';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useUpdateCredentialsMutation, useUpdateUserMutation } from '../../../services/users';
import { EStep } from '../index';

const Body = styled.div`
  background: #ffffff;
  width: 490px;
  padding: 32px;
  border-radius: 8px;
`;

const Title = styled.div`
  color: #253446;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 12px;
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #515d6b;
  margin-bottom: 32px;
`;

export const StyledTextInput = styled(TextInput)`
  width: 272px;
  font-size: 14px;
  height: auto;
  line-height: 22px;
  padding: 4px 8px;
`;

export const StyledInputLabel = styled(InputLabel)`
  color: #7c8590;
  font-size: 14px;
  line-height: 22px;
  transform: none;
`;

export const FormItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

export const SaveButton = styled.button`
  background: #577bf9;
  border-radius: 4px;
  border: 0;
  color: #eff2ff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  width: 102px;
`;

export const Error = styled.div`
  font-size: 10px;
  color: #c3000a;
  font-style: normal;
  font-weight: normal;
  width: 272px;
  float: right;
  padding-top: 2px;
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const schema = yup.object().shape({
  id: yup.mixed().nullable(),
  role: yup.string(),
  username: yup.string().when({
    is: (val: string) => val.length === 0,
    then: yup.string().when('id', {
      is: (val) => !val,
      then: yup.string().required('Обязательное поле'),
      otherwise: yup.string().min(0),
    }),
    otherwise: yup.string().min(5, 'Не менее 6 символов'),
  }),
  password: yup.string().when({
    is: (val: string) => val.length === 0,
    then: yup.string().when('id', {
      is: (val) => !val,
      then: yup.string().required('Обязательное поле'),
      otherwise: yup.string().min(0),
    }),
    otherwise: yup.string().min(6, 'Не менее 6 символов'),
  }),
  confirmedUsername: yup.string().when({
    is: (val: string) => val.length === 0,
    then: yup.string().when('id', {
      is: (val) => !val,
      then: yup.string().required('Обязательное поле'),
      otherwise: yup.string().min(0),
    }),
    otherwise: yup
      .string()
      .oneOf([yup.ref('username'), null], 'Логин не совпадает')
      .required(),
  }),
  confirmedPassword: yup.string().when({
    is: (val: string) => val.length === 0,
    then: yup.string().when('id', {
      is: (val) => !val,
      then: yup.string().required('Обязательное поле'),
      otherwise: yup.string().min(0),
    }),
    otherwise: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Пароль не совпадает')
      .required(),
  }),
});

type Props = {
  setStep: React.Dispatch<React.SetStateAction<EStep>>;
};

export const ChangeLogin = ({ setStep }: Props) => {
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const user = useSelector((state: RootState) => state.common.user);
  const [updateCredentials] = useUpdateCredentialsMutation();
  const [updateUser] = useUpdateUserMutation();

  const onSubmit = async (values: { password: string; username: string }) => {
    await updateCredentials({
      id: user.id,
      new_password: values.password,
      new_username: values.username,
    }).unwrap();
    if (user.is_superuser) {
      setStep(EStep.ORGANIZATION);
    } else {
      await updateUser({
        is_password_reset: false,
        id: user.id,
      }).unwrap();
    }
  };

  return (
    <Body>
      <Title>Добро пожаловать!</Title>
      <Subtitle>Пожалуйста укажите новый пароль</Subtitle>
      <FormProvider {...methods}>
        <FormItem>
          <StyledInputLabel>Логин</StyledInputLabel>
          <StyledTextInput name="username" error={errors.username} {...register('username')} autoComplete="off" />
        </FormItem>
        <Error>{errors?.username?.message}</Error>
        <FormItem>
          <StyledInputLabel>Подтвердите логин</StyledInputLabel>
          <StyledTextInput
            name="confirmedUsername"
            error={errors.confirmedUsername}
            {...register('confirmedUsername')}
            autoComplete="off"
          />
        </FormItem>
        <Error>{errors?.confirmedUsername?.message}</Error>
        <FormItem>
          <StyledInputLabel>Пароль</StyledInputLabel>
          <StyledTextInput
            name="password"
            error={errors.password}
            type="password"
            autoComplete="new-password"
            {...register('password')}
          />
        </FormItem>
        <Error>{errors?.password?.message}</Error>
        <FormItem>
          <StyledInputLabel>Подтвердите пароль</StyledInputLabel>
          <StyledTextInput
            name="confirmedPassword"
            error={errors.confirmedPassword}
            type="password"
            autoComplete="new-password"
            {...register('confirmedPassword')}
          />
        </FormItem>
        <Error>{errors?.confirmedPassword?.message}</Error>
        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '32px' }}>
          <SaveButton onClick={handleSubmit(onSubmit)}>Сохранить</SaveButton>
        </div>
      </FormProvider>
    </Body>
  );
};
