import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isExpanded: false,
};

const DrawerStateSlice = createSlice({
  name: 'DrawerState',
  initialState: INITIAL_STATE,
  reducers: {
    toggleExpantion: (state) => ({
      ...state,
      isExpanded: !state.isExpanded,
    }),
    setExpantion: (state, action) => ({
      ...state,
      isExpanded: action.payload,
    }),
  },
});

export default DrawerStateSlice;
