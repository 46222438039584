import React, { useEffect } from 'react';
import { Modal } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import TextInput from '../../uikit/TextInput';
import TextArea from '../../uikit/TextArea';
import OutlinedButton from '../../uikit/Button/OutlinedButton';
import BlueButton from '../../uikit/Button/BlueButton';
import { getMask } from '../../utils/masks';
import { DevTool } from '@hookform/devtools';
// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const formSchema = yup.object().shape({
  full_name: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required(),
  message: yup.string().required(),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: () => ({
      position: 'absolute',
      width: 568,
      padding: 32,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      transition: 'height 1s ease-in-out 500ms, width 50ms linear',
    }),
    title: {
      fontSize: 18,
      fontWeight: 400,
      marginBottom: 20,
    },
    label: {
      fontWeight: 400,
      fontSize: 14,
      color: '#253446',
    },
  }),
);

type Props = {
  onClose: VoidFunction;
};

export function ContactUsModal({ onClose }: Props) {
  const user = useSelector((state: RootState) => state.common.user);
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });
  const {
    register,
    handleSubmit,
    formState: { isValid },
    setValue,
    control,
    clearErrors,
    setError,
  } = methods;
  const classes = useStyles();

  useEffect(() => {
    setValue('full_name', `${user.last_name} ${user.first_name} ${user.second_name}`);
    setValue('email', user.email);
    setValue('phone', user.phone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (/* values */) => {
    toast.success('Спасибо за обращение! Мы скоро свяжемся с Вами');
    onClose();
    // await updateCredentials({
    //   id: user.id,
    //   new_password: values.password,
    //   new_username: values.username,
    // }).unwrap();
  };

  return (
    <Modal open className={classes.modal}>
      <div className={classes.paper}>
        <div className={classes.title}>Связаться с нами</div>
        <FormProvider {...methods}>
          <div className={classes.label}>Полное имя</div>
          <TextInput style={{ height: 32 }} {...register('full_name')} />
          <div style={{ display: 'flex', flex: 1, width: '100%', gap: 24, margin: '20px 0' }}>
            <div style={{ width: '50%' }}>
              <div className={classes.label}>Email</div>
              <TextInput
                style={{ height: 32 }}
                {...register('email')}
                onChange={(e) => {
                  const value = getMask('email').resolve(e.target.value);
                  setValue('email', value);
                  if (value) {
                    clearErrors('email');
                  } else {
                    setError('required', { type: 'required' });
                  }
                }}
              />
            </div>
            <div style={{ width: '50%' }}>
              <div className={classes.label}>Телефон</div>
              <TextInput
                style={{ height: 32 }}
                {...register('phone')}
                onChange={(e) => {
                  const value = getMask('rusPhone').resolve(e.target.value);
                  setValue('phone', value);
                  if (value) {
                    clearErrors('phone');
                  } else {
                    setError('required', { type: 'required' });
                  }
                }}
              />
            </div>
          </div>
          <div className={classes.label}>Сообщение</div>
          <TextArea multiline rows={4} maxLength={3000} {...register('message')} />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <OutlinedButton width={97} style={{ marginRight: '20px' }} onClick={onClose}>
              Отменить
            </OutlinedButton>
            <BlueButton width={103} disabled={!isValid} onClick={handleSubmit(onSubmit)}>
              Отправить
            </BlueButton>
          </div>
        </FormProvider>
        {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
      </div>
    </Modal>
  );
}
