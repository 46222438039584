/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import {
  StyledWorkDayTime,
  WorkDayTimeSeparator,
  RedactingWrapper,
  RedactingHeader,
  RedactingSubheader,
  RedactingButtons,
  RedactingButton,
  MakeNonWorkingDayWrapper,
  MakeNonWorkingDayButton,
} from './styled-components';
import { TimeSelect } from '../../../ProfilePage/components/Schedule/TimeSelect';
import { format, add, addDays, parse } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setBlockers, setRedactingDay } from '../../reducer';
import russianLocale from 'date-fns/locale/ru';
import { toast } from 'react-hot-toast';
import {
  useAddHolidayMutation,
  useEditHolidayMutation,
  useDeleteHolidayMutation,
} from '../../../../services/dictionaries';
import { weekdaysKeys, weekdaysStrings } from '../../ScheduleTable/settings';
import {
  checkEventsOverlapWithTimeInterval,
  getEventsByDate,
  getMinMaxTime,
  // setNewBlockersIntoTable
} from '../../ScheduleTable/functions';

const RedactingModal = ({ currentDate, cabinets, holidays, workdays }) => {
  const dispatch = useAppDispatch();

  const [createHoliday] = useAddHolidayMutation();
  const [updateHoliday] = useEditHolidayMutation();
  const [deleteHoliday] = useDeleteHolidayMutation();

  const schedulerAPI = useAppSelector((state) => state.reworkedSchedule.schedulerAPI);

  const minMaxTime = getMinMaxTime(workdays, holidays);

  const slotMinTime = minMaxTime.slotMinTime || schedulerAPI.getOption('slotMinTime');
  const slotMaxTime = minMaxTime.slotMaxTime || schedulerAPI.getOption('slotMaxTime');

  const [isOpened, setIsOpened] = useState(true);
  const [isError, setIsError] = useState(false);
  const [startHours, setStartHours] = useState(Number(slotMinTime.substring(0, slotMinTime.indexOf(':'))));
  const [endHours, setEndHours] = useState(Number(slotMaxTime.substring(0, slotMaxTime.indexOf(':'))));
  const [startMinutes, setStartMinutes] = useState(
    Number(slotMinTime.substring(slotMinTime.indexOf(':') + 1, slotMinTime.lastIndexOf(':'))),
  );
  const [endMinutes, setEndMinutes] = useState(
    Number(slotMaxTime.substring(slotMaxTime.indexOf(':') + 1, slotMaxTime.lastIndexOf(':'))),
  );
  const currentDateString = format(currentDate, 'd MMMM yyyy', { locale: russianLocale }); //Например, 1 декабря 2022. Это для формы и предупреждений
  const currentDatePayloadString = new Date(currentDate.getTime() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 10); //Например, 2022-12-06. Это для запроса на сервер
  const currentDateWeekdayString = weekdaysStrings[new Date(currentDate).getDay()]; //Название дня недели текущей даты, в родительском падеже, множественном числе. Нужно для предупреждений
  const weekdaySettingForCurrentDay = workdays.find(
    (workday) => workday.weekday === weekdaysKeys[new Date(currentDate).getDay()],
  );
  const successMessage = `Настройки для ${currentDateString} успешно изменены`;
  const serverErrorMessage = `По техническим причинам не удалось изменить настройки для ${currentDateString}`;
  const resetWarningMessage = `Время начала и конца рабочего дня для ${currentDateString} совпадают с настройками для всех ${currentDateWeekdayString}. Начало и конец этого рабочего дня будут определяться настройками для всех ${currentDateWeekdayString}.`;
  const creatingWarningMessage = `${currentDateString} больше не управляется настройками для всех ${currentDateWeekdayString}.`;

  const handleChange = (part) => (value) => {
    switch (part) {
      case 'startHours':
        setStartHours(value);
        break;

      case 'startMinutes':
        setStartMinutes(value);
        break;

      case 'endHours':
        setEndHours(value);
        break;

      case 'endMinutes':
        setEndMinutes(value);
        break;
    }
  };

  const handleClose = () => {
    dispatch(setRedactingDay(null));
    setIsOpened(false);
  };

  const handleSave = (e, makeNonWorkingDayMode = false) => {
    let payload;
    // Если рассматриваемый день придется сделать нерабочим:
    // Не нужна будет валидация дат, payload.time_start и payload.time_end будут null
    // Но будет нужна проверка на наличие визитов на этом дне
    if (makeNonWorkingDayMode) {
      // Вычисление сессий или визитов, которые блокируют сокращение рабочего дня сверху
      const makeNonWorkingDayBlockers = checkEventsOverlapWithTimeInterval(
        getEventsByDate(schedulerAPI?.getEvents(), currentDate),
        currentDate,
        addDays(currentDate, 1),
      );
      if (makeNonWorkingDayBlockers.length) {
        toast.error(
          'Для деактивации данного рабочего дня удалите или перенесите рабочие сессии и визиты на другой день',
        );
      } else {
        payload = {
          date: currentDatePayloadString,
          time_start: '00:00:00', //позже будет null
          time_end: '00:00:00', //позже будет null
          is_workday: false,
        };
      }
      // Для остальных случаев:
      // Нужна будет валидация дат, т.к для нас принципиально, какие даты будут отправлены
    } else {
      if (startHours * 60 + startMinutes > endHours * 60 + endMinutes) {
        toast.error('Начало рабочего дня не может быть позже, чем его конец');
      } else if (startHours * 60 + startMinutes === endHours * 60 + endMinutes) {
        toast.error('Начало рабочего дня не может совпадать с его концом');
      } else if (startHours === 0 && endHours === 0 && startMinutes === 0 && endMinutes === 0) {
        toast.error('Укажите начало и конец рабочего дня');
      } else {
        // Вычисление сессий или визитов, которые блокируют сокращение рабочего дня сверху
        const blockersAbove = checkEventsOverlapWithTimeInterval(
          getEventsByDate(schedulerAPI?.getEvents(), currentDate),
          currentDate,
          add(currentDate, { hours: startHours, minutes: startMinutes }),
        );
        // Вычисление сессий или визитов, которые блокируют сокращение рабочего дня снизу
        const blockersBelow = checkEventsOverlapWithTimeInterval(
          getEventsByDate(schedulerAPI?.getEvents(), currentDate),
          add(currentDate, { hours: endHours, minutes: endMinutes }),
          addDays(currentDate, 1),
        );

        if (blockersAbove.length) {
          toast.error(
            'Не удаётся сократить этот рабочий день от его начала: есть визиты или сессии в сокращаемом периоде',
          );
          return;
        }

        if (blockersBelow.length) {
          toast.error(
            'Не удаётся сократить этот рабочий день от его конца: есть визиты или сессии в сокращаемом периоде',
          );
          return;
        }

        payload = {
          date: currentDatePayloadString,
          time_start: `${startHours < 10 ? '0' + startHours : startHours}:${
            startMinutes < 30 ? '0' + startMinutes : startMinutes
          }:00`,
          time_end: `${endHours < 10 ? '0' + endHours : endHours}:${
            endMinutes < 30 ? '0' + endMinutes : endMinutes
          }:00`,
          is_workday: true,
        };
      }
    }
    if (payload) {
      const existingHoliday = holidays.find((holiday) => holiday.date === payload.date);

      if (existingHoliday) {
        // Если у даты, которую мы собираемся редактировать, совпадают начало и конец дня с теми, что есть в настройках на этот день недели - запись удаляется.
        if (
          (weekdaySettingForCurrentDay.time_start === payload.time_start &&
            weekdaySettingForCurrentDay.time_end === payload.time_end) ||
          (!weekdaySettingForCurrentDay.is_workday && !payload.is_workday)
        ) {
          deleteHoliday(existingHoliday.id)
            .unwrap()
            .then(() => {
              toast(resetWarningMessage, { icon: '⚠️', duration: 6000 });
              // TODO: некорректно работает расчет новых блокеров
              // setNewBlockersIntoTable(schedulerAPI, payload, slotMinTime, slotMaxTime, cabinets);
            })
            .catch(() => toast.error(serverErrorMessage));
        } else {
          updateHoliday({ id: existingHoliday.id, ...payload })
            .unwrap()
            .then(() => {
              toast.success(successMessage);
              toast(creatingWarningMessage, { icon: '⚠️', duration: 3000 });
              // setNewBlockersIntoTable(schedulerAPI, payload, slotMinTime, slotMaxTime, cabinets);
            })
            .catch(() => toast.error(serverErrorMessage));
        }
      } else {
        // Если у даты, которую мы собираемся создать, совпадают начало и конец дня с теми, что есть в настройках на этот день недели - запись не создаётся.
        if (
          weekdaySettingForCurrentDay.time_start === payload.time_start &&
          weekdaySettingForCurrentDay.time_end === payload.time_end &&
          weekdaySettingForCurrentDay.is_workday === payload.is_workday
        ) {
          toast(resetWarningMessage, { icon: '⚠️', duration: 6000 });
        } else {
          createHoliday(payload)
            .unwrap()
            .then(() => {
              toast.success(successMessage);
              toast(creatingWarningMessage, { icon: '⚠️', duration: 3000 });
              // setNewBlockersIntoTable(schedulerAPI, payload, slotMinTime, slotMaxTime, cabinets);
            })
            .catch(() => toast.error(serverErrorMessage));
        }
      }
      handleClose();
    }
  };

  return (
    <Modal open={isOpened} onClose={handleClose}>
      <RedactingWrapper>
        <RedactingHeader>Настройка рабочего дня</RedactingHeader>
        <RedactingSubheader>Настройте рабочее время на {currentDateString}</RedactingSubheader>

        <StyledWorkDayTime>
          <TimeSelect
            hours={startHours}
            minutes={startMinutes}
            setHours={handleChange('startHours')}
            setMinutes={handleChange('startMinutes')}
            minHour={0}
            maxHour={24}
            isError={isError}
            changeValuesImmediatly
          />
          <WorkDayTimeSeparator />
          <TimeSelect
            hours={endHours}
            minutes={endMinutes}
            setHours={handleChange('endHours')}
            setMinutes={handleChange('endMinutes')}
            minHour={0}
            maxHour={24}
            isError={isError}
            changeValuesImmediatly
          />
        </StyledWorkDayTime>
        <RedactingButtons>
          <RedactingButton onClick={handleClose} style={{ marginRight: '16px' }}>
            Отменить
          </RedactingButton>
          <RedactingButton onClick={(e) => handleSave(e, false)} primary>
            Сохранить
          </RedactingButton>
        </RedactingButtons>
        <MakeNonWorkingDayWrapper>
          <MakeNonWorkingDayButton onClick={(e) => handleSave(e, true)}>Сделать день нерабочим</MakeNonWorkingDayButton>
        </MakeNonWorkingDayWrapper>
      </RedactingWrapper>
    </Modal>
  );
};

export default RedactingModal;
