import { STAFF_COLORS } from '../../../../constants';
import { setCurrentStaffer } from '../../reducer';
import { AppDispatch } from '../../../../../../store';
import { Staffer } from '../../../../../../services/users';

export function sortStaffersByColors(staffersArr, currentStaffer) {
  // Убираю из массива сотрудников текущего. Его не нужно отображать в tooltip - для него и так есть галочка
  const filteredStaffersArr = staffersArr.filter((currStaffer) => currStaffer.id !== currentStaffer.id);
  return Object.fromEntries(
    Object.entries(STAFF_COLORS).map((e) => {
      return [e[0], [...filteredStaffersArr.filter((staffer) => staffer.color === e[0])]];
    }),
  );
}
export const handleColorChange = (newColor: string, staffer: Partial<Staffer>, dispatch: AppDispatch): void => {
  dispatch(setCurrentStaffer({ ...staffer, color: newColor }));
};

export const handleChange = (_, expanded, sortedStaffers, stafferColor, setSortedStaffers): void => {
  if (Object.keys(sortedStaffers).includes(stafferColor) && !expanded) {
    setSortedStaffers({
      [stafferColor]: sortedStaffers[stafferColor],
      ...sortedStaffers,
    });
  }
};
