import React, { memo } from 'react';
import { Heading, Title, Name, Job } from '../styled-components';
import { useAppSelector } from '../../../../../store/hooks';

export const StaffScheduleHeading = () => {
  const { first_name, second_name, last_name, job } = useAppSelector((state) => state.staffSchedule.currentStaffer);
  return (
    <>
      <Heading>
        <div style={{ display: 'flex' }}>
          <Title>Настройка расписания сотрудника</Title>
          <Name>
            {last_name} {first_name} {second_name}
          </Name>
          <Job>{job}</Job>
        </div>
      </Heading>
    </>
  );
};

export default memo(StaffScheduleHeading);
