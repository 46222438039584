import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { ColumnWidth, DiscountTypeItems, DiscountTypeValues, TreatmentPlanItem } from './TreatmentPlanJournalField';
import Column from '../../../PatientPage/components/Column';
import TextInput from '../../../../uikit/TextInput';
import { getMask } from '../../../../utils/masks';
import { BorderSelect } from '../Select';
import styled from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TextWithPopover } from '../TextWithPopover';
import { TableActionButton, TableActionMenu } from '../../../../components/Table/styles';
import { DotsIcon } from '../../../../uikit/Icons';
import { MenuItem } from '@material-ui/core';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import Popover from '@material-ui/core/Popover';
import { SearchHeader } from '../SearchHeader';
import { LinkButton } from '../../../../uikit/Button/LinkButton';
import { format, isToday, parse } from 'date-fns';
import russianLocale from 'date-fns/locale/ru';
import { useDispatch } from 'react-redux';
import medCardPageSlice from '../../state';
import { useNavigate } from 'react-router-dom';
import { routerConfig } from '../../../../navigation/routerConfig';

const useStyles = makeStyles(() =>
  createStyles({
    discountSum: {
      borderBottomRightRadius: '0 !important',
      borderTopRightRadius: '0 !important',
    },
    discountType: {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderLeft: 0,
    },
    headerContent: {
      display: 'flex',
      flex: 1,
      padding: 0,
      width: '100%',
    },
  }),
);

const Row = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${({ selected = false }) => (selected ? '#F6F6FA' : 'white')};
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #253446;
  margin-bottom: 20px;
`;

const RowDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: white;
  align-items: center;
`;

type Props = {
  item: TreatmentPlanItem;
  setItem: (item) => void;
  textColumnWidth: number;
  withoutDate?: boolean;
  readOnly?: boolean;
  isAddAccount?: boolean;
  totalDiscount: number;
  onRowClick: (template) => void;
};

export function TreatmentPlanJournalFieldItem({
  item,
  setItem,
  textColumnWidth,
  withoutDate = false,
  readOnly = false,
  isAddAccount = false,
  totalDiscount,
  onRowClick,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [anchorDetailsEl, setAnchorDetailsEl] = React.useState<HTMLButtonElement | null>(null);
  const openDetails = Boolean(anchorDetailsEl);

  const { setCurrentPaymentId } = medCardPageSlice.actions;

  const [discountValue, setDiscountValue] = useState(0);

  useEffect(() => {
    setDiscountValue(item.discount);
  }, [item.discount]);

  const updateItemDiscount = useMemo(
    () =>
      debounce((item, value) => {
        setItem({ ...item, discount: +getMask('count').resolve(`${+value}`) });
      }, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleRowClick = () => {
    if (readOnly && !isAddAccount) {
      return;
    }
    if (isAddAccount) {
      onRowClick(item);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Row selected={item.isUsed}>
        {!withoutDate && (
          <Column
            name="date"
            defaultValue={item.date}
            width={ColumnWidth.date}
            fontSize={12}
            paddingLeft={8}
            isEditing={false}
            hasAction
            onClick={handleRowClick}
          >
            {item.date}
          </Column>
        )}
        <Column
          name="code"
          defaultValue={item.code}
          width={94}
          fontSize={12}
          isEditing={false}
          hasAction
          onClick={handleRowClick}
        >
          {item.code}
        </Column>
        <Column
          name="text"
          defaultValue={item.text}
          width={textColumnWidth}
          fontSize={12}
          isEditing={false}
          hasAction
          onClick={handleRowClick}
        >
          <TextWithPopover text={item.text} width={386} height={70} />
        </Column>
        <Column name="count" defaultValue={item.count} width={55} fontSize={12} isEditing={false} hasAction>
          {readOnly || !isToday(parse(item.date, 'dd.MM.yyyy', new Date())) ? (
            !!item.invoiceHistory?.length ? (
              <Tooltip
                dark
                title={
                  <>
                    {item.invoiceHistory.map((inv) => (
                      <div key={inv.id}>
                        {`Выставлено ${inv.count} позиц., ${format(new Date(inv.date), 'd MMM yyyy', {
                          locale: russianLocale,
                        })}`}
                      </div>
                    ))}
                  </>
                }
                enterDelay={500}
                enterNextDelay={500}
                placement="bottom-start"
              >
                <div style={{ background: '#F6F6FA', height: '100%', display: 'flex', alignItems: 'center' }}>
                  {item.count}
                </div>
              </Tooltip>
            ) : (
              item.count
            )
          ) : !!item.invoiceHistory?.length ? (
            <Tooltip
              dark
              title={
                <>
                  {item.invoiceHistory.map((inv) => (
                    <div key={inv.id}>
                      {`Выставлено ${inv.count} позиц., ${format(new Date(inv.date), 'd MMM yyyy', {
                        locale: russianLocale,
                      })}`}
                    </div>
                  ))}
                </>
              }
              enterDelay={500}
              enterNextDelay={500}
              placement="bottom-start"
            >
              <div style={{ background: '#F6F6FA', height: '100%', display: 'flex', alignItems: 'center' }}>
                {item.isUsed ? (
                  item.count
                ) : (
                  <TextInput
                    value={item.count}
                    style={{ height: 22, fontSize: 12 }}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      setItem({ ...item, count: +getMask('count_without_zero').resolve(e.target.value) || 1 })
                    }
                  />
                )}
              </div>
            </Tooltip>
          ) : (
            <TextInput
              value={item.count}
              style={{ height: 22, fontSize: 12 }}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setItem({ ...item, count: +getMask('count_without_zero').resolve(e.target.value) || 1 })}
            />
          )}
        </Column>
        <Column name="price" defaultValue={item.price} width={66} fontSize={12} isEditing={false} hasAction>
          {getMask('number_format').resolve(`${item.price}`)}
        </Column>
        <Column
          name="discount"
          defaultValue={item.discount}
          width={readOnly ? 58 : 108}
          fontSize={12}
          isEditing={false}
          hasAction
        >
          {readOnly || item.isUsed || !isToday(parse(item.date, 'dd.MM.yyyy', new Date())) ? (
            `${getMask('number_format').resolve(`${item.discount}`)} ${
              DiscountTypeItems.find(({ value }) => value === item.discountType)?.name
            }`
          ) : (
            <div style={{ display: 'flex' }}>
              <TextInput
                className={classes.discountSum}
                value={discountValue}
                style={{ height: 22, width: 47, fontSize: 12 }}
                disabled={!!totalDiscount}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  updateItemDiscount(item, e?.target?.value);
                  setDiscountValue(e?.target?.value);
                }}
              />
              <BorderSelect
                className={classes.discountType}
                value={item.discountType}
                disabled={!!totalDiscount}
                setValue={(value) => {
                  const getDiscount = () => {
                    if (value !== item.discountType) return 0;
                    if (value === DiscountTypeValues.PERCENT && value > 100) return 100;
                    return item.discount;
                  };

                  setItem({
                    ...item,
                    discountType: value,
                    discount: getDiscount(),
                  });
                }}
                items={DiscountTypeItems}
              />
            </div>
          )}
        </Column>
        <Column name="text" defaultValue={item.payment} width={72} fontSize={12} isEditing={false} hasAction>
          {getMask('number_format').resolve(`${item.payment}`)}
        </Column>
        {!readOnly && !isAddAccount && (
          <>
            <TableActionButton
              style={{ position: 'absolute', right: 4 }}
              size="small"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              disableRipple
            >
              <DotsIcon />
            </TableActionButton>
            <TableActionMenu id="action-menu" anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
              {!isAddAccount && (
                <MenuItem
                  disabled={!item.invoiceHistory.length}
                  onClick={(event) => {
                    handleMenuClose();
                    setAnchorDetailsEl(event.currentTarget);
                  }}
                >
                  Посмотреть детализацию
                </MenuItem>
              )}
              {!readOnly && (
                <MenuItem
                  disabled={!!item.invoiceHistory.length || !isToday(parse(item.date, 'dd.MM.yyyy', new Date()))}
                  onClick={() => {
                    onRowClick(item);
                    handleMenuClose();
                  }}
                >
                  Удалить
                </MenuItem>
              )}
            </TableActionMenu>
          </>
        )}
      </Row>
      <Popover
        open={openDetails}
        anchorEl={anchorDetailsEl}
        onClose={() => setAnchorDetailsEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        // style={{marginTop: '8px'}}
      >
        <div style={{ padding: 24 }}>
          <Title>Детализация</Title>
          <div className={classes.headerContent}>
            <SearchHeader
              type="text"
              placeholder="Дата"
              name="code"
              width={104}
              fontSize={12}
              text="Дата"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="Время"
              name="code"
              width={62}
              fontSize={12}
              text="Время"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="Врач"
              name="code"
              width={180}
              fontSize={12}
              text="Врач"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="Счет"
              name="code"
              width={112}
              fontSize={12}
              text="Счет"
              noBorder
              searchable={false}
            />
          </div>
          {item.invoiceHistory.map((inv) => (
            <RowDetails key={inv.id}>
              <>
                <Column name="date" defaultValue={''} width={104} fontSize={12} isEditing={false} hasAction>
                  {format(new Date(inv.date), 'd MMM yyyy', { locale: russianLocale })}
                </Column>
                <Column name="time" defaultValue={''} width={62} fontSize={12} isEditing={false} hasAction>
                  {format(new Date(inv.date), 'hh:mm')}
                </Column>
                <Column name="doctor" defaultValue={''} width={180} fontSize={12} isEditing={false} hasAction>
                  {`${inv.doctor.last_name} ${inv.doctor.first_name[0]}. ${inv.doctor.second_name[0]}.`}
                </Column>
                <Column name="account" defaultValue={''} width={112} fontSize={12} isEditing={false} hasAction>
                  <LinkButton
                    style={{ fontSize: 12, padding: 0, margin: 0 }}
                    disableRipple
                    onClick={() => {
                      dispatch(setCurrentPaymentId(inv.invoice));
                      setAnchorDetailsEl(null);
                      navigate(routerConfig.VISITS_AND_PAYMENTS.path);
                    }}
                  >
                    Перейти в счет
                  </LinkButton>
                </Column>
              </>
            </RowDetails>
          ))}
        </div>
      </Popover>
    </>
  );
}
