import {
  CONNECTED_SUCCESS,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  MESSAGE_DATA,
  LOAD_MESSAGES,
  TOGGLE_CHAT,
  LOAD_DIALOGS,
  LOAD_SEARCH_RESULTS
} from "./types";
import { FLUSH_STATE } from "../flushState/types";
const INITIAL_STATE = {
  messages: [],
  dialog: null,
  current: {},
  open: false,
  dialogs: [],
  search: { dialogs: [], messages: [] },
  socket: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONNECTED_SUCCESS:
      return {
        ...state,
        ...{ socket: action.payload }
      };
    case LOAD_DIALOGS:
      return {
        ...state,
        ...{
          dialogs: action.payload,
          search: { dialogs: action.payload, messages: [] }
        }
      };
    case OPEN_DIALOG:
      return {
        ...state,
        ...{
          dialog: action.payload
        }
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        ...{ dialog: null }
      };
    case MESSAGE_DATA:
      return {
        ...state,
        ...{ current: { text: action.payload } }
      };
    case LOAD_MESSAGES:
      return {
        ...state,
        ...{ messages: action.payload }
      };
    case TOGGLE_CHAT:
      return {
        ...state,
        ...{ open: !state.open }
      };
    case LOAD_SEARCH_RESULTS:
      return {
        ...state,
        ...{ search: { ...action.payload } }
      };
    case FLUSH_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
