import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import React from 'react';
import { capitalize } from 'lodash';
import { Wrapper, Title, Icon } from './styled-components';
import { useHover } from '../../hooks/useHover';

/**
 * Кнопка с иконкой и текущей выбранной датой
 * @param {string} id - id для элемента, HTML-атрибут
 * @param {TextFieldProps} params - параметры, передающиеся DatePicker'ом (оттуда нужна строка с выбранной датой и ссылка inputRef, чтобы Popper открывался в нужном месте)
 * @param {boolean} isOpened - состояние выборщика дат: раскрыт ли он?
 * @param {function} handleClick - callback, вызывающийся по клику
 */

const OpenButton = ({ id, params, isOpened, handleClick, selectedDate }) => {
  const [hoverRef, isHovered] = useHover();

  const titleText = selectedDate ? format(selectedDate, 'EEEE d MMMM', { locale: ru }) : '';

  return (
    <Wrapper
      id={id}
      ref={hoverRef}
      onClick={handleClick}
      isHighlighted={isOpened || isHovered}
      style={{ margin: '0 10px' }}
    >
      <Icon
        ref={params.inputRef}
        src={isOpened || isHovered ? './img/blue/calendar-icon.svg' : './img/black/calendar-icon.svg'}
        alt="calendar_icon"
      />
      {Boolean(titleText) && <Title isHighlighted={isOpened || isHovered}>{capitalize(titleText)}</Title>}
    </Wrapper>
  );
};

export default OpenButton;
