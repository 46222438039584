import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL, HttpMethod } from '../utils/request';

export interface Appointment {
  id?: number;
  starts_at: string;
  ends_at: string;
  description?: string;
  status: string;
  comment?: string;
  cancel_reason?: string;
  doctor: number;
  patient: number;
  clinic: number;
  cabinet: number;
}

export const appointmentsApi = createApi({
  reducerPath: 'appointmentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['Appointments'],
  endpoints: (builder) => ({
    getAppointments: builder.query<Appointment[], number>({
      query: (id) => ({ url: `clinics/${id}/appointments` }),
      providesTags: ['Appointments'],
    }),
    getAppointmentById: builder.query<Appointment, { id: number; clinicId: number }>({
      query: ({ id, clinicId }) => ({ url: `/clinics/${clinicId}/appointments/${id}` }),
      providesTags: (result, error, { id }) => [{ type: 'Appointments', id }],
    }),
    createAppointment: builder.mutation({
      query: ({ id, data }: { id: number; data: Appointment }) => {
        return {
          url: `clinics/${id}/appointments`,
          method: HttpMethod.POST,
          body: data,
        };
      },
      invalidatesTags: ['Appointments'],
    }),
    deleteAppointment: builder.mutation({
      query: ({ clinicId, appointmentId }: { clinicId: number; appointmentId: number }) => {
        return {
          url: `clinics/${clinicId}/appointments/${appointmentId}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: ['Appointments'],
    }),
    updateAppointment: builder.mutation<Appointment, Partial<Appointment>>({
      query: (data) => {
        const { id, clinic, ...body } = data;
        return {
          url: `clinics/${clinic}/appointments/${id}`,
          method: HttpMethod.PATCH,
          body,
        };
      },
      invalidatesTags: ['Appointments'],
    }),
  }),
});

export const {
  endpoints,
  useGetAppointmentsQuery,
  useGetAppointmentByIdQuery,
  useCreateAppointmentMutation,
  useDeleteAppointmentMutation,
  useUpdateAppointmentMutation,
} = appointmentsApi;
