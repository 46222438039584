import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { toggleChat } from "../../features/messages/actions";
import { fetchStaff } from "../../features/staff/operations";
import {
  connectToWS,
  subscribeToEvents,
} from "../../features/messages/operations";
import Budge from "./Budge";
import ChatWindow from "./ChatWindow";

const ImageWrapper = styled.div`
    position: relative;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D3D6DA;
    border-radius: 4px;
    cursor: pointer;
    background: #FFFFFF;
`;

function Chat({ toggleChat, common, connectToWS, fetchStaff }) {
  useEffect(() => {
    fetchStaff();
  }, []);

  return (
    <div>
      <ImageWrapper
        id={"chat-icon"}
        onClick={toggleChat}
      >
        <img
          src="./img/black/chat-icon-new.svg"
          width="18px"
          height="18px"
          alt="start chat"
        />
        <Budge />
      </ImageWrapper>
      <ChatWindow />
    </div>
  );
}

const mapStateToProps = (state) => {
  return { common: state.common };
};

export default connect(mapStateToProps, {
  toggleChat,
  connectToWS,
  subscribeToEvents,
  fetchStaff,
})(Chat);
