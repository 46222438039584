import { format } from 'date-fns';
import { toast } from 'react-hot-toast';
import rulocale from 'date-fns/locale/ru';
import request from '../../utils/request';
import PatientPage from './state';

const { loadPatients, loadResponse, patientCreated, setCreatingPatient, dropSearch, setIsLoading } =
  PatientPage.actions;

export function initPatientPage() {
  return async (dispatch, getState) => {
    const { common } = getState();
    dispatch(setIsLoading(true));
    const response = await request.clinic.load_patients(common.user.clinic);
    const sortedPatients = response.data.items.sort((a, b) => b.id - a.id);
    dispatch(loadResponse(sortedPatients));
    dispatch(loadPatients(sortedPatients));
    dispatch(setIsLoading(false));
  };
}
export function searchForPatient() {
  return async (dispatch, getState) => {
    const { patientsPage, common } = getState();
    const response = await request.clinic.search_patient({
      ...patientsPage.searchQuery,
      clinic: common.user.clinic.id,
    });
    const patients = response.data.sort((a, b) => b.id - a.id);
    //  Если при поиске есть полное совпадение телефона или email пациента,
    //  то показываем этого пациента без учета других полей поиска

    dispatch(loadPatients(patients));
  };
}

export function parseDate(date) {
  const date_arr = date.split('.');
  return !!!date
    ? null
    : format(new Date(parseInt(date_arr[2]), parseInt(date_arr[1]) - 1, parseInt(date_arr[0])), 'yyyy-MM-dd', {
        locale: rulocale,
      });
}

export function updatePatientInfo(patientID, patientData) {
  return async (dispatch) => {
    let data = { ...patientData, id: patientID };
    // Если меняем дату рождения, то нужно привести дату к формату yyyy-MM-DD
    data =
      typeof patientData['birth_date'] !== 'undefined'
        ? { ...data, birth_date: parseDate(patientData['birth_date']) }
        : { ...data };
    await request.clinic.update_patient(data);
    await dispatch(initPatientPage());
  };
}

export function createNewPatient(formData) {
  return async (dispatch, getState) => {
    const { common } = getState();
    const patientBirthDate = formData['birth_date'];
    let reqBody =
      typeof patientBirthDate !== 'undefined'
        ? { ...formData, birth_date: parseDate(patientBirthDate) }
        : { ...formData };

    try {
      const response = await request.clinic.add_patient(common.user.clinic, {
        ...reqBody,
        clinic: common.user.clinic.id,
        balance: 0,
      });
      await dispatch(patientCreated(response.data));
      toast.success('Пациент успешно создан');
    } catch (error) {
      const e = error?.response;
      console.error(error);
      if (e?.status === 409) {
        toast.error('Пациент с такими данными уже существует в базе');
        dispatch(setCreatingPatient(false));
        dispatch(dropSearch());
        dispatch(initPatientPage());
        return;
      }
      toast.error('Пациент не был создан по техническим причинам, попробуйте позже');
    }
  };
}
