import React, { FC, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styled from 'styled-components';
import Popper from '@mui/material/Popper';
import { DictionaryType } from '../../../services/dictionaries';

interface MultiSelectProps {
  placeholder?: string;
  values?: DictionaryType[];
  width?: number;
  handleSetPaymentType?: (paymentType: number) => void;
}

interface OptionProps {
  selected: boolean;
}

interface SelectorProps {
  width?: number;
}

interface SelectedOptionProps {
  focused: boolean;
  disabled: boolean;
}

const PopperContainer = styled(Popper)({
  zIndex: 100,
  paddingTop: '4px !important',
});
export const Selector = styled.div<SelectorProps>`
  position: relative;
  width: ${({ width }) => `${width}px`};
`;
export const SelectedOption = styled.div<SelectedOptionProps>`
  height: 24px;
  background: ${({ disabled }) => (disabled ? '#F6F6FA' : '#ffffff')};
  border: ${({ focused }) => (focused ? '1px solid #577BF9' : '1px solid #d3d6da')};
  box-shadow: ${({ focused }) => (focused ? '0px 0px 4px rgba(87, 123, 249, 0.5)' : 'none')};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  overflow-y: hidden;

  :hover {
    border: 1px solid #577bf9;
  }
`;
export const SelectedInput = styled.input`
  border: 0;
  background: inherit;
  font-size: 12px;
  color: #515d6b;
  cursor: pointer;
  width: 100%;
  overflow-y: hidden;
  text-overflow: ellipsis;

  &::placeholder {
    color: #a8aeb5;
  }

  &:focus {
    outline: none;
  }
`;
export const OptionsContainer = styled.div`
  width: 172px;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 12px 0 8px 0;
  box-sizing: border-box;
  overflow-x: hidden;
`;
export const Option = styled.div<OptionProps>`
  font-size: 12px;
  color: #515d6b;
  box-sizing: border-box;
  padding: 0 12px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  white-space: nowrap;
  background: ${({ selected }) => (selected ? '#F6F6FA' : '#FFFFFF')};
  cursor: pointer;

  &:hover {
    background: #eff2ff;
  }
`;

export const PaymentTypeSelect: FC<MultiSelectProps> = ({ placeholder, values, width, handleSetPaymentType }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openOptionsList = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeOptionsList = () => setAnchorEl(null);
  const onOptionClick = (event: React.MouseEvent<HTMLElement>, value: DictionaryType) => {
    event.stopPropagation();
    setInputValue(value.title);
    handleSetPaymentType(value.id);
    closeOptionsList();
  };

  return (
    <ClickAwayListener onClickAway={closeOptionsList}>
      <Selector width={width} onClick={openOptionsList}>
        <SelectedOption focused={Boolean(anchorEl)} disabled={false}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <SelectedInput
                disabled
                placeholder={placeholder ? placeholder : 'Выбрать'}
                value={inputValue}
                onChange={closeOptionsList}
              />
            </div>
            <KeyboardArrowDownIcon style={{ fontSize: '20px' }} />
          </div>
        </SelectedOption>
        <PopperContainer
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          modifiers={[
            {
              name: 'flip',
              enabled: false,
              options: {
                altBoundary: false,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'offset',
              options: {
                offset: () => [-6, 0],
              },
            },
          ]}
          placement={'bottom-start'}
        >
          <OptionsContainer>
            {values.map((value) => {
              return (
                <Option key={value.id} selected={value.title === inputValue} onClick={(e) => onOptionClick(e, value)}>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value.title}</div>
                </Option>
              );
            })}
          </OptionsContainer>
        </PopperContainer>
      </Selector>
    </ClickAwayListener>
  );
};
