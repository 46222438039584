import React, { forwardRef, RefObject, useCallback, useEffect, useState } from 'react';
import TextInput from '../../../uikit/TextInput';
import { debounceValueChange } from './../utils';
import { Modal } from '../../../uikit/Modal';
import { ValidationMessage } from '../../../uikit/ValidationMessage';
import IMask from 'imask';

type refundCellType = {
  value: number;
  isActiveRefundActions: boolean;
  currentBalance: number;
  showRefundResult: boolean;
  refundTotal?: number;
  handleSetIsRefundFilled: (condition: boolean) => void;
};
const maskOptions = {
  mask: Number,
};

export const PaymentRefundCell = forwardRef<RefObject<HTMLInputElement>, refundCellType>((props, ref) => {
  const { isActiveRefundActions, showRefundResult, value, handleSetIsRefundFilled, currentBalance, refundTotal } =
    props;
  const [refundSumError, setRefundSumError] = useState<boolean>(false);

  const updateRefundValueCondition = useCallback(() => {
    if (ref?.current?.value > currentBalance) {
      setRefundSumError(true);
    }
    if (ref?.current?.value < currentBalance && refundSumError) {
      setRefundSumError(false);
    }
    handleSetIsRefundFilled(false);
    ref?.current?.value <= currentBalance && ref?.current?.value > 0
      ? handleSetIsRefundFilled(true)
      : handleSetIsRefundFilled(false);
  }, [ref, currentBalance, handleSetIsRefundFilled, refundSumError]);

  useEffect(() => {
    debounceValueChange(ref, updateRefundValueCondition);
  }, [ref, updateRefundValueCondition]);

  useEffect(() => {
    if (ref && ref.current) {
      IMask(ref.current, maskOptions);
    }
  }, [ref]);

  return (
    <>
      <div>{value}</div>
      {isActiveRefundActions && (
        <div style={{ paddingTop: 24 }}>
          {showRefundResult ? (
            refundTotal
          ) : (
            <TextInput style={{ height: 24, fontSize: 12 }} ref={ref} error={refundSumError} />
          )}
        </div>
      )}
      {refundSumError && (
        <Modal anchor={ref.current} unSetContainer={true}>
          <ValidationMessage>Сумма больше чем было оплачено</ValidationMessage>
        </Modal>
      )}
    </>
  );
});
PaymentRefundCell.displayName = 'PaymentRefundCell';
