import React from 'react';
import { styled as styledMui } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Done from '@material-ui/icons/Done';
import Tooltip from '../../../../../../components/Tooltip/Tooltip';
import { ColorBox } from './styled-components';
import { Staffer } from '../../../../../../services/users';

export const Wrapper = styledMui(Accordion)({
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #D3D6DA',
  borderRadius: '4px',
  boxShadow: 'none',
  width: '236px',
  alignItems: 'center',
  '&.Mui-expanded': {
    position: 'absolute',
    filter: 'drop-shadow(0px 2px 4px rgba(40, 41, 61, 0.04)) drop-shadow(0px 8px 16px rgba(96, 97, 112, 0.16))',
  },
});
export const Collapsed = styledMui(AccordionSummary)({
  position: 'static',
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 30,
    paddingLeft: 16,
    margin: 0,
  },
  '& .Mui-expanded': {
    margin: 0,
    minHeight: 'unset',
    paddingBottom: 0,
    paddingTop: 16,
  },
  '&.Mui-expanded': {
    minHeight: 'unset',
  },
  '& .MuiButtonBase-root': {
    position: 'absolute',
    bottom: 0,
  },
  '& .MuiIconButton-root': {
    padding: 0,
  },
});
export const Expanded = styledMui(AccordionDetails)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: 'auto',
  paddingTop: 0,
  paddingRight: 16,
  paddingBottom: 30,
  paddingLeft: 16,
  margin: 0,
});
export const Box: React.FC<{
  color: string;
  setSelectedColor: (color: string) => void;
  isSelected: boolean;
  staffers: Array<Staffer>;
}> = ({ color, setSelectedColor, isSelected, staffers }) => {
  const content = (
    <ColorBox
      isOccupied={staffers && !!staffers.length}
      isSelected={isSelected}
      color={color}
      onClick={() => setSelectedColor(color)}
    >
      {isSelected && <Done style={{ color: 'rgba(0, 0, 0, 0.2)' }} />}
    </ColorBox>
  );
  const tooltipText = (
    <div style={{ whiteSpace: 'pre-line' }}>
      {staffers && staffers.map((s) => `${s.last_name} ${s.first_name[0]}. ${s.second_name[0]}.\n`)}
    </div>
  );
  return staffers && staffers.length ? (
    <Tooltip title={tooltipText} placement={'top'} enterDelay={500} enterNextDelay={500}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
