import React from 'react';
import { Dialog } from '../../../../uikit/Dialog';
import { useAppSelector } from '../../../../store/hooks';

interface BlockAppWarningProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
  isLoading?: boolean;
}

export const BlockAppWarning: React.FC<BlockAppWarningProps> = ({ onContinue, ...restProps }) => {
  const {
    user: { id },
    sessions,
  } = useAppSelector((state) => state.common);
  const editors = sessions.filter((s) => s.session_user_id !== id);

  const text = `${
    !editors?.length
      ? 'Ваша работа на странице настройки графика сотрудника приостановит работу во всей программе.'
      : `Сотрудник ${editors[0]?.session_user_name} сейчас работает на странице настройки графика сотрудника.
  Ваш вход на страницу приостановит работу во всей программе и удалит все данные внесенные этим сотрудником.`
  } Продолжить вход?`;

  return <Dialog {...restProps} onSubmit={onContinue} text={text} submitText="Войти" />;
};
