import { IconContainer, Icon, IconText, BorderLine } from "./styles";
import React, { useState } from "react";
import DrawerState from "./state";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function DrawerItem({
  expanded,
  fileName,
  pathName,
  setExpantion,
  text,
  padding,
  width,
  id
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isHovering, setHover] = useState(false);
  const isActive = location.pathname === pathName;

  return (
    <IconContainer
      id={id}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      expanded={expanded}
      onClick={(e) => {
        e.stopPropagation();
        setExpantion(false);
        navigate(pathName);
      }}
      active={isActive}
    >
      <Icon
        width={width}
        src={
          isActive || isHovering
            ? `./img/blue/${fileName}`
            : `./img/black/${fileName}`
        }
      />

      <IconText padding={padding} hover={isHovering} active={isActive}>
        {text}
      </IconText>

      {isActive && <BorderLine />}
    </IconContainer>
  );
}
export default connect(() => ({}), {
  setExpantion: DrawerState.actions.setExpantion,
})(DrawerItem);
