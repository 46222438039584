import React, { useState, useRef } from 'react';
import {
  CategoriesContainer,
  Category,
  CategoryBox,
  MenuDots,
  Dots,
  CategoryMenu,
  MenuItem,
  RightSpacer,
} from '../styles';
import EditCategory from './EditCategory';
import NewCategory from './NewCategory';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { connect } from 'react-redux';
import { updateCategory, deleteCategory, nextCategoryPage, previousCategoryPage } from '../operations';
import { getTextWidth } from '../../../utils';
import PriceState from '../state';
import Item from './EditCategory/Item';
import { OnTopTooltip } from '../../../components/DoctorFilter/OnTopTooltip';
import request from '../../../utils/request';
import { printPdf } from '../../../utils/print';
import { RootState } from '../../../store';

function Categories({
  common,
  pickCategory,
  priceSlice,
  updateCategory,
  deleteCategory,
  nextCategoryPage,
  previousCategoryPage,
}) {
  const { pickedCategory, createFirstCategory } = priceSlice;
  const [editCategory, setCategoryEdit] = useState('');
  const categoriesRef = useRef({} as HTMLDivElement);
  const [isMenuShown, setMenu] = useState(false);
  const categories = priceSlice.categories.slice(
    priceSlice.category.paginator.start,
    priceSlice.category.paginator.end,
  );
  async function handleCategoryEdit(e) {
    const isUniq =
      priceSlice.categories.filter((category) => category.name === e.target.value && category.name !== '').length === 0;
    if (isUniq) {
      await updateCategory({ name: e.target.value }, editCategory);
    }
    setCategoryEdit('default');
  }

  const handlePrint = async () => {
    const result = await request.clinic.print_price(common.user.clinic, pickedCategory.id);
    printPdf(result.data);
  };

  return (
    <CategoriesContainer ref={categoriesRef}>
      {priceSlice.category.paginator.start !== 0 && (
        <>
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              height: 56,
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <img
              style={{ transform: 'rotate(180deg)' }}
              src={'./img/black/right.svg'}
              onClick={() => {
                previousCategoryPage();
              }}
            />
          </div>
          <div style={{ width: 20 }} />
        </>
      )}
      {((categories.length > 1 && categories[0].id === 'default') ||
        (createFirstCategory && categories.length === 1)) && (
        <CategoryBox isEditing={false} isActive={pickedCategory.id === 'default'}>
          <Category
            width={getTextWidth('Все категории')}
            onClick={() => {
              pickCategory({ id: 'default' });
            }}
          >
            Все категории
          </Category>
          {pickedCategory.id === 'default' && (
            <MenuDots onClick={() => setMenu(!isMenuShown)}>
              <Dots src={'./img/black/ellipsis.svg'} alt={'menu-icon'} />
            </MenuDots>
          )}
          {pickedCategory.id === 'default' && isMenuShown && (
            <ClickAwayListener onClickAway={() => setMenu(!isMenuShown)}>
              <CategoryMenu>
                <MenuItem disabled={false} onClick={handlePrint}>
                  Распечатать
                </MenuItem>
              </CategoryMenu>
            </ClickAwayListener>
          )}
        </CategoryBox>
      )}
      {categories.map(({ id, name }: { id: string; name: string }) => {
        if (id === 'default') return;
        return (
          <CategoryBox key={id} isEditing={editCategory === id} isActive={pickedCategory.id === id}>
            {priceSlice.canEdit && editCategory === id ? (
              <EditCategory value={name} onBlur={handleCategoryEdit} />
            ) : (
              <Category
                width={getTextWidth(name)}
                onClick={() => {
                  pickCategory({ id, name });
                }}
              >
                {name}
              </Category>
            )}
            <RightSpacer />
            {editCategory !== id && pickedCategory.id === id && (
              <MenuDots onClick={() => setMenu(!isMenuShown)}>
                <Dots src={'./img/black/ellipsis.svg'} alt={'menu-icon'} />
              </MenuDots>
            )}
            {editCategory !== id && pickedCategory.id === id && isMenuShown && (
              <ClickAwayListener onClickAway={() => setMenu(!isMenuShown)}>
                <CategoryMenu>
                  <MenuItem disabled={false} onClick={handlePrint}>
                    Распечатать
                  </MenuItem>
                  <Item
                    tooltipPlacement={'right'}
                    disabled={!priceSlice.canEdit}
                    onClick={() => {
                      priceSlice.canEdit && setCategoryEdit(id);
                      priceSlice.canEdit && setMenu(false);
                    }}
                    text={'Редактировать'}
                  />
                  <OnTopTooltip
                    title={'Сначала удалите позиции прейскуранта'}
                    arrow
                    isShown={
                      // eslint-disable-next-line no-prototype-builtins
                      !priceSlice.canEdit || priceSlice.price.hasOwnProperty(id)
                    }
                  >
                    <MenuItem
                      disabled={
                        !priceSlice.canEdit ||
                        // eslint-disable-next-line no-prototype-builtins
                        priceSlice.price.hasOwnProperty(id)
                      }
                      onClick={() => {
                        deleteCategory(id);
                        setMenu(!isMenuShown);
                        pickCategory({ id: 'default', name: 'Весь прейскурант' });
                      }}
                    >
                      Удалить
                    </MenuItem>
                  </OnTopTooltip>
                </CategoryMenu>
              </ClickAwayListener>
            )}
          </CategoryBox>
        );
      })}
      {priceSlice.category.paginator.end < priceSlice.category.paginator.fullLength ? (
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            height: 56,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <img
            src={'./img/black/right.svg'}
            onClick={() => {
              nextCategoryPage();
            }}
          />
        </div>
      ) : (
        ((categories.length > 1 && priceSlice.canEdit) || createFirstCategory) && <NewCategory />
      )}
    </CategoriesContainer>
  );
}

export default connect((state: RootState) => ({ priceSlice: state.priceSlice, common: state.common }), {
  updateCategory,
  deleteCategory,
  showDialog: PriceState.actions.showDialog,
  pickCategory: PriceState.actions.pickCategory,
  nextCategoryPage,
  previousCategoryPage,
})(Categories);
