import { SET_DOCTOR_FIELD,LOAD_DOCTORS } from "./types";

export function setField(data) {
  return {
    type: SET_DOCTOR_FIELD,
    payload: data
  };
}

export function loadDoctors(data) {
  return {
    type:LOAD_DOCTORS,
    payload:data
  }
}