import React, { useState } from "react";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";

const FormLayout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
`;

const RadioLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #515d6b;
`;

const BlueRadioButton = withStyles({
  root: {
    color: "#D3D6DA",
    cursor: "pointer",
    "&$checked": {
      color: "#577BF9",
    },
    "&:hover": {
      color: "#577BF9",
    },
  },
  checked: {},
})((props: any) => <Radio color="default" {...props} />);

function PickGender({ name, onBlur, value }: any) {
  const [gender, setGender] = useState(
    value === "M" || value === "F" ? value : "M"
  );
  return (
    <FormLayout style={{ marginLeft: -10, marginTop: -4 }}>
      <BlueRadioButton
        size={"small"}
        checked={gender === "M"}
        onChange={() => {
          setGender("M");
          onBlur({ target: { value: "M" } });
        }}
      />
      <RadioLabel>Муж.</RadioLabel>
      <BlueRadioButton
        size={"small"}
        checked={gender === "F"}
        onChange={() => {
          setGender("F");
          onBlur({ target: { value: "F" } });
        }}
      />
      <RadioLabel>Жен.</RadioLabel>
    </FormLayout>
  );
}

export default PickGender;
