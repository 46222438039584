import React, { FC } from 'react';
import { getMask } from '../../../utils/masks';

type remainderCellType = {
  rowIndex: number;
  value: number;
  isBalanceChanged: boolean;
  isActiveRefundActions: boolean;
  currentBalance: number;
  showRefundResult: boolean;
  refundAmount: number;
};
export const PaymentRemainderCell: FC<remainderCellType> = ({
  rowIndex,
  value,
  isBalanceChanged,
  isActiveRefundActions,
  currentBalance,
  showRefundResult,
  refundAmount,
}) => {
  const refundResult = Number(currentBalance) - Number(refundAmount);
  const cellColorCondition = isBalanceChanged && rowIndex === 0 && !showRefundResult;
  const subCellVisionCondition = isActiveRefundActions && rowIndex === 0;

  const subCellColorCondition = () => {
    if (isBalanceChanged && rowIndex === 0 && showRefundResult) return '#0D853D';
    if (refundResult < 0) return '#C3000A';
    return '';
  };

  return (
    <>
      <span style={{ color: cellColorCondition ? '#0D853D' : '' }}>{getMask('number_format').resolve(`${value}`)}</span>
      {subCellVisionCondition && (
        <div style={{ paddingTop: 24, color: subCellColorCondition() }}>
          {refundResult < 0 && <span>-</span>}
          {getMask('number_format').resolve(`${refundResult}`)}
        </div>
      )}
    </>
  );
};
