import { createSelector } from "reselect";
import lodash from "lodash";
import {
  format,
  subHours,
  parse,
  differenceInCalendarDays,
  addDays,
  isToday,
  isFuture,
  getHours,
} from "date-fns";
import { ResponceSelector } from "../appointments/selectors";

import ruLocale from "date-fns/locale/ru";

const _ = lodash;

export const FilterSelector = (state) => state.newSchedule.filters;
export const ScheduleResponceSelector = (state) => state.newSchedule.responce;
const DaySelector = (state) => state.newSchedule.day;
const CabinetSelector = (state) => state.clinic.cabinets;
const CalendarLengthSelector = (state) => state.newSchedule.length;
const AllTasksSelector = (state) => state.tasks.responce;
const workingDaysSelector = (state) => state.workdays.schedule;

const checkIfWorkingDay = (day, workdays) => {
  let day_name = format(day, "eeee", {
    locale: ruLocale,
  }).toLowerCase();
  if (isFuture(day) || isToday(day)) {
    let date = workdays[day_name][0];
    return getHours(date.starts_at) !== getHours(date.ends_at);
  } else {
    let date = workdays[day_name].filter(
      (time) => parse(time.created_at) < parse(day)
    );

    date = date[0];
    return getHours(date.starts_at) !== getHours(date.ends_at);
  }
};
// eslint-disable-next-line
const getNextDay = (day, workdays) => {
  if (checkIfWorkingDay(day, workdays)) {
    return day;
  } else {
    day = addDays(day, 1);
    return getNextDay(day, workdays);
  }
};

export const FilterTypeSelector = createSelector(FilterSelector, (filters) => {
  return Object.keys(filters).reduce((accumulator, key) => {
    return filters[key].length === 1
      ? accumulator + "-" + key
      : accumulator + "";
  }, "day");
});

export const ScheduleCalendarSelector = createSelector(
  [
    ScheduleResponceSelector,
    FilterTypeSelector,
    DaySelector,
    FilterSelector,
    CabinetSelector,
    ResponceSelector,
    CalendarLengthSelector,
    AllTasksSelector,
    workingDaysSelector,
  ],
  (
    responce,
    filterType,
    day,
    filters,
    cabinets,
    appointments,
    len,
    tasks,
    workdays
  ) => {
    switch (filterType) {
      case "day":
        let day_filter = new Date(filters.day);
        let days = _.chain(responce)
          .filter(
            (item) =>
              format(subHours(item.starts_at, 3), "dd.MM.yy") ===
              format(day_filter, "dd.MM.yy")
          )
          .groupBy((item) => item.cabinet.id)
          .values(_)
          .map((item) => ({
            heading: item[0].cabinet.name,
            subheading: null,
            cabinet: item[0].cabinet.name,
            date: subHours(item[0].starts_at, 3),
            workday: item,
          }))
          .chunk(len)
          .value();
        let arr = [];
        cabinets
          .filter((cabinet) => cabinet.is_medical)
          .forEach((item) => {
            if (_.findIndex(days[0], { heading: item.name }) === -1) {
              arr.push({
                heading: item.name,
                cabinet: item.name,
                subheading: null,
                date: parse(filters.day),
                workday: [],
              });
            }
          });

        days.push(arr);
        if (days.length > 1) {
          days[0] = [...days[0], ...days[1]];
          days[0] = _.sortBy(days[0], "heading");
        } else {
          days[0] = _.sortBy(days[0], "heading");
        }
        days = _.uniqBy(days, (item) => item.cabinet);

        return days[0].length >= 1 ? days : [];
      case "day-job":
        let days_by_job = _.chain(responce)
          .filter(
            (item) =>
              filters.doctor.indexOf(item.worker.id) !== -1 &&
              filters.cabinet.indexOf(item.cabinet.id) !== -1 &&
              filters.job.indexOf(item.worker.job) !== -1
          )
          .groupBy(
            (item) => item.cabinet.id + format(item.starts_at, "dd.MM.yy")
          )
          .values(_)
          .map((item) => ({
            heading: {
              text:
                String(
                  format(subHours(item[0].starts_at, 3), "dd", {
                    locale: ruLocale,
                  })
                ).toLocaleUpperCase() +
                " " +
                format(subHours(item[0].starts_at, 3), "dd ") +
                format(subHours(item[0].starts_at, 3), "MMM", {
                  locale: ruLocale,
                }).slice(0, 3) +
                ".",
              isToday: isToday(item[0].starts_at),
            },
            subheading: item[0].cabinet.name,
            cabinet: item[0].cabinet.name,
            date: subHours(item[0].starts_at, 3),
            workday: item,
          }))
          .value();
        let appoint_days_by_job = appointments.filter(
          (item) => filters.job.indexOf(item.doctor.job) !== -1
        );
        appoint_days_by_job.forEach((day) => {
          if (
            days_by_job.filter(
              (workday) =>
                format(subHours(workday.date, 3), "dd.MM.yy") ===
                format(subHours(day.starts_at, 3), "dd.MM.yy")
            ).length === 0
          ) {
            days_by_job.push({
              heading: {
                text:
                  String(
                    format(subHours(day.starts_at, 3), "dd", {
                      locale: ruLocale,
                    })
                  ).toLocaleUpperCase() +
                  " " +
                  format(subHours(day.starts_at, 3), "dd ") +
                  format(subHours(day.starts_at, 3), "MMM", {
                    locale: ruLocale,
                  }).slice(0, 3) +
                  ".",
                isToday: isToday(day.starts_at),
              },
              subheading: day.cabinet,
              cabinet: day.cabinet,
              date: subHours(day.starts_at, 3),
              workday: [],
            });
          }
        });

        days_by_job = _.sortBy(days_by_job, (day) => day.date);
        days_by_job = days_by_job.filter((day) => {
          let day_name = format(day.date, "eeee", {
            locale: ruLocale,
          }).toLowerCase();
          if (isFuture(day.date) || isToday(day.date)) {
            let date = workdays[day_name][0];
            return getHours(date.starts_at) !== getHours(date.ends_at);
          } else {
            let date = workdays[day_name].filter(
              (time) => parse(time.created_at) < parse(day.date)
            );
            date = date[0];
            return getHours(date.starts_at) !== getHours(date.ends_at);
          }
        });
        return _.chunk(days_by_job, len);

      case "day-cabinet":
        let days_a = _.chain(responce)
          .filter(
            (item) =>
              filters.doctor.indexOf(item.worker.id) !== -1 &&
              filters.cabinet.indexOf(item.cabinet.id) !== -1 &&
              filters.job.indexOf(item.worker.job) !== -1
          )
          .groupBy((item) => format(item.starts_at, "dd.MM.yy"))
          .values(_)
          .value();
        let start_day = addDays(new Date(), -20);
        let end_day = addDays(new Date(), 20);
        let days_arr = new Array(differenceInCalendarDays(end_day, start_day))
          .fill(start_day)
          .map((day, index) => {
            return addDays(day, index);
          });
        days_a = _.chain(days_a)
          .map((item) => ({
            heading: {
              text:
                String(
                  format(subHours(item[0].starts_at, 3), "dd", {
                    locale: ruLocale,
                  })
                ).toLocaleUpperCase() +
                " " +
                format(subHours(item[0].starts_at, 3), "dd ") +
                format(subHours(item[0].starts_at, 3), "MMM", {
                  locale: ruLocale,
                }).slice(0, 3) +
                ".",
              isToday: isToday(item[0].starts_at),
            },
            subheading: null,
            date: subHours(item[0].starts_at, 3),
            cabinet: item[0].cabinet.name,
            workday: item,
          }))
          .value();
        days_a = days_arr.map((day) => {
          let found_days = days_a.filter(
            (el) => format(el.date, "dd.MM.yy") === format(day, "dd.MM.yy")
          );
          if (found_days.length === 0) {
            return {
              heading: {
                text:
                  String(
                    format(day, "dd", { locale: ruLocale })
                  ).toLocaleUpperCase() +
                  " " +
                  format(day, "dd ") +
                  format(day, "MMM", { locale: ruLocale }).slice(0, 3) +
                  ".",
                isToday: isToday(day),
              },
              subheading: null,
              date: subHours(day, 3),
              cabinet: cabinets.filter(
                (cabinet) => cabinet.id === filters.cabinet[0]
              )[0].name,
              workday: [],
            };
          } else {
            return found_days[0];
          }
        });
        days_a = days_a.filter((day) => {
          let day_name = format(day.date, "eeee", {
            locale: ruLocale,
          }).toLowerCase();
          if (isFuture(day.date) || isToday(day.date)) {
            let date = workdays[day_name][0];
            return getHours(date.starts_at) !== getHours(date.ends_at);
          } else {
            let date = workdays[day_name].filter(
              (time) => parse(time.created_at) < parse(day.date)
            );

            date = date[0];
            return getHours(date.starts_at) !== getHours(date.ends_at);
          }
        });
        return _.chunk(days_a, len);
      case "day-doctor":
        let default_days = _.chain(responce)
          .filter(
            (item) =>
              filters.doctor.indexOf(item.worker.id) !== -1 &&
              filters.cabinet.indexOf(item.cabinet.id) !== -1 &&
              filters.job.indexOf(item.worker.job) !== -1
          )
          .groupBy(
            (item) =>
              format(subHours(item.starts_at, 3), "dd.MM.yy") + item.cabinet.id
          )
          .values(_)
          .map((item) => ({
            heading: {
              text:
                String(
                  format(subHours(item[0].starts_at, 3), "dd", {
                    locale: ruLocale,
                  })
                ).toLocaleUpperCase() +
                " " +
                format(subHours(item[0].starts_at, 3), "dd ") +
                format(subHours(item[0].starts_at, 3), "MMM", {
                  locale: ruLocale,
                }).slice(0, 3) +
                ".",
              isToday: isToday(item[0].starts_at),
            },
            subheading: item[0].cabinet.name,
            date: subHours(item[0].starts_at, 3),
            cabinet: item[0].cabinet.name,
            workday: item,
          }))
          .value();
        let appoint_days = appointments.filter(
          (item) => filters.doctor.indexOf(item.doctor.id) !== -1
        );
        let tasks_days = tasks.filter(
          (item) => filters.doctor.indexOf(item.addressee.id) !== -1
        );
        appoint_days.forEach((day) => {
          if (
            default_days.filter(
              (workday) =>
                format(workday.date, "dd.MM.yy") + workday.cabinet ===
                format(subHours(day.starts_at, 3), "dd.MM.yy") + day.cabinet
            ).length === 0
          ) {
            default_days.push({
              heading: {
                text:
                  String(
                    format(subHours(day.starts_at, 3), "dd", {
                      locale: ruLocale,
                    })
                  ).toLocaleUpperCase() +
                  " " +
                  format(subHours(day.starts_at, 3), "dd ") +
                  format(subHours(day.starts_at, 3), "MMM", {
                    locale: ruLocale,
                  }).slice(0, 3) +
                  ".",
                isToday: isToday(day.starts_at),
              },
              subheading: day.cabinet,
              cabinet: day.cabinet,
              date: subHours(day.starts_at, 3),
              workday: [],
            });
          }
        });
        tasks_days.forEach((day) => {
          if (
            default_days.filter(
              (workday) =>
                format(workday.date, "dd.MM.yy") ===
                format(subHours(day.starts_at, 3), "dd.MM.yy")
            ).length === 0
          ) {
            default_days.push({
              heading: {
                text:
                  String(
                    format(subHours(day.starts_at, 3), "dd", {
                      locale: ruLocale,
                    })
                  ).toLocaleUpperCase() +
                  " " +
                  format(subHours(day.starts_at, 3), "dd ") +
                  format(subHours(day.starts_at, 3), "MMM", {
                    locale: ruLocale,
                  }).slice(0, 3) +
                  ".",
                isToday: isToday(day.starts_at),
              },
              subheading: "",
              cabinet: "",
              date: subHours(day.starts_at, 3),
              workday: [],
            });
          }
        });
        default_days = _.sortBy(default_days, (day) => day.date);
        default_days = default_days.filter((day) => {
          let day_name = format(day.date, "eeee", {
            locale: ruLocale,
          }).toLowerCase();
          if (isFuture(day.date) || isToday(day.date)) {
            let date = workdays[day_name][0];
            return getHours(date.starts_at) !== getHours(date.ends_at);
          } else {
            let date = workdays[day_name].filter(
              (time) => parse(time.created_at) < parse(day.date)
            );
            date = date[0];
            return getHours(date.starts_at) !== getHours(date.ends_at);
          }
        });
        return _.chunk(default_days, len);
      default:
        let days_last = _.chain(responce)
          .filter(
            (item) =>
              filters.doctor.indexOf(item.worker.id) !== -1 &&
              filters.cabinet.indexOf(item.cabinet.id) !== -1 &&
              filters.job.indexOf(item.worker.job) !== -1
          )
          .groupBy(
            (item) =>
              format(subHours(item.starts_at, 3), "dd.MM.yy") + item.cabinet.id
          )
          .values(_)
          .map((item) => ({
            heading:
              String(
                format(subHours(item[0].starts_at, 3), "dd", {
                  locale: ruLocale,
                })
              ).toLocaleUpperCase() +
              " " +
              format(subHours(item[0].starts_at, 3), "dd ") +
              format(subHours(item[0].starts_at, 3), "MMM", {
                locale: ruLocale,
              }).slice(0, 3) +
              ".",
            subheading: item[0].cabinet.name,
            date: subHours(item[0].starts_at, 3),
            cabinet: item[0].cabinet.name,
            workday: item,
          }))
          .value();
        days_last = days_last.filter((day) => {
          let day_name = format(day.date, "eeee", {
            locale: ruLocale,
          }).toLowerCase();
          if (isFuture(day.date) || isToday(day.date)) {
            let date = workdays[day_name][0];
            return getHours(date.starts_at) !== getHours(date.ends_at);
          } else {
            let date = workdays[day_name].filter(
              (time) => parse(time.created_at) < parse(day.date)
            );
            date = date[0];
            return getHours(date.starts_at) !== getHours(date.ends_at);
          }
        });
        return _.chunk(days_last, len);
    }
  }
);
