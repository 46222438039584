/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import styled from 'styled-components';
import { format } from 'date-fns';
import InputMask from 'react-input-mask';

import { Calendar } from '../Calendar';
import { OutlinedInput } from '../OutlinedInput';
import { checkHoliday } from '../../features/staff/components/StaffScheduleSetup/components/StaffSchedulerTable/functions';

interface CalendarWrapperProps {
  isOpen: boolean;
}
const CalendarWrapper = styled.div<CalendarWrapperProps>`
  position: absolute;
  z-index: 2;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const DatePickerWrapper = styled.div`
  position: relative;
`;

interface DatePickerProps {
  value?: Date | null;
  onDateChange?: (date: Date) => void;
  minDate?: Date;
  isDisabled?: boolean;
  nonWorkingDates?: string[];
  nonWorkingWeekdays?: number[];
}

export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onDateChange,
  minDate,
  isDisabled = false,
  nonWorkingDates = undefined,
  nonWorkingWeekdays = undefined,
}) => {
  const [date, setDate] = useState(value);
  const [inputValue, setInputValue] = useState(date ? format(date, 'dd/MM/yyyy') : '');
  const [isOpen, setIsOpen] = useState(false);

  const timeZoneOffset = new Date().getTimezoneOffset() * 60000;

  useEffect(() => {
    setDate(value);
    if (value) {
      setInputValue(format(value, 'dd/MM/yyyy'));
    }
  }, [value]);

  const handleDateChange = (newDate: Date) => {
    if (onDateChange) {
      onDateChange(newDate);
    }
    setDate(newDate);
    setInputValue(newDate ? format(newDate, 'dd/MM/yyyy') : '');
    setIsOpen(false);
  };

  const handleFocus = (ev): void => {
    if (isDisabled) {
      ev.target.blur();
    } else {
      setIsOpen(true);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <DatePickerWrapper>
        <InputMask disabled={isDisabled} mask="99/99/9999" value={inputValue} onFocus={handleFocus}>
          {() => (
            <OutlinedInput
              style={{ backgroundColor: isDisabled ? '#F6F6FA' : 'white' }}
              disabled={isDisabled}
              placeholder="дд/мм/гггг"
              disableUnderline={true}
            />
          )}
        </InputMask>
        <CalendarWrapper isOpen={isOpen}>
          <Calendar
            value={date}
            onDateChange={handleDateChange}
            onDisableDates={(date) => checkHoliday(date, nonWorkingWeekdays, nonWorkingDates, timeZoneOffset)}
            minDate={minDate}
            selectedColor="#EFF0F3"
          />
        </CalendarWrapper>
      </DatePickerWrapper>
    </ClickAwayListener>
  );
};
