import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
`;
export const Wrapper = styled.div<{ isHighlighted: boolean; disabled: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  background: ${(props) => (props.disabled ? '#F6F6FA' : '#ffffff')};
  border: 1px solid ${(props) => (props.isHighlighted ? '#577BF9' : '#D3D6DA')};
  border-radius: 4px;
  box-sizing: border-box;
  cursor: ${(props) => (props.disabled ? 'unset' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
`;
export const Icon = styled.img<{ isRight?: boolean; iconSize?: number }>`
  width: ${(props) => (props.iconSize ? props.iconSize : 14)}px;
  margin-right: 10px;
  margin: 0 auto;
  transform: ${(props) => (props.isRight ? 'rotate(180deg)' : 'inherit')};
`;
export const Title = styled.span<{ isHighlighted: boolean }>`
  width: 221px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: normal;
  color: ${(props) => (props.isHighlighted ? '#577BF9' : '#253446')};
`;
export const StylesWrapper = styled.div`
  .css-1n2mv2k {
    display: none;
  }
`;
export const TodayButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;

  & > button {
    font-size: 14px;
    color: #577bf9;
    border: none;
    background: unset;
    outline: none;
  }
`;
export const DragTarget = styled.div<{ z: number }>`
  z-index: ${(props) => (props.z ? props.z : 9999)};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: red;
`;
