export const PICK_WORKDAY = "@workdays/pick"
export const SET_WIDTH = "@workdays/set_width"
export const SET_SCHEDULE = "@workdays/set_schedule"

export interface WorkdayState {
  current: object;
  responce: Array<any>;
  width: number;
  schedule: {[key: string]: Array<WorkDaySchedule>};
  max_day_length: number;
}

export interface WorkDaySchedule {
  id: number;
  clinic: number;
  created_at: Date;
  starts_at: string;
  ends_at: string;
}
