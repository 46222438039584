import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import TextArea from '../../../uikit/TextArea';
import TextInput from '../../../uikit/TextInput';
import { TextLong } from '../Template/TextLong';
import { TextEllipsis } from '../Template/TextEllipsis';
import styled from 'styled-components';
import { FIELD_HEIGHT, FIELD_WIDTH } from './JornalItem';

const JournalFieldText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 120%;
  color: #515d6b;
`;

type Props = {
  value: string;
  readOnly: boolean;
  focused: boolean;
  onFocus: (e: SyntheticEvent) => void;
  onChange: (value: string) => void;
};

export const MAX_SYMBOLS = 372;

export function JornalTextItem({ value, readOnly, focused, onFocus, onChange }: Props) {
  const [textValue, setTextValue] = useState<string>(value);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isMultiLine, setIsMultiLine] = useState<boolean>(false);

  const textAreaRef = useRef(null);
  const inputRef = useRef(null);

  const changeTextHeight = () => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (value !== textValue) {
      setTextValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (editMode) {
      changeTextHeight();
    }
  }, [editMode, isMultiLine]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      setIsMultiLine(inputRef.current.scrollWidth > inputRef.current.clientWidth);
    } else if (textAreaRef && textAreaRef.current) {
      setIsMultiLine(textAreaRef.current.scrollHeight > textAreaRef.current.clientHeight);
    }
  }, [textValue]);

  return readOnly ? (
    <JournalFieldText>
      <TextEllipsis text={textValue} max={MAX_SYMBOLS} />
    </JournalFieldText>
  ) : editMode ? (
    isMultiLine ? (
      <TextArea
        ref={textAreaRef}
        multiline
        value={textValue}
        style={{ width: FIELD_WIDTH, overflowY: 'hidden', padding: 8 }}
        onFocus={onFocus}
        onChange={(e) => {
          const newValue = e.target.value;
          changeTextHeight();
          setTextValue(newValue);
          onChange(newValue);
        }}
        onBlur={() => {
          setEditMode(false);
        }}
      />
    ) : (
      <TextInput
        ref={inputRef}
        autoFocus
        value={textValue}
        style={{ width: FIELD_WIDTH, height: FIELD_HEIGHT, border: `1px solid ${focused ? '#4555e4' : '#D3D6DA'}` }}
        onFocus={onFocus}
        onChange={(e) => {
          const newValue = e.target.value;
          setTextValue(newValue);
          onChange(newValue);
        }}
        onBlur={() => setEditMode(false)}
      />
    )
  ) : (
    <TextLong text={textValue} width={FIELD_WIDTH} focused={focused} setEditMode={setEditMode} />
  );
}
