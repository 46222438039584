import React, { FC, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ClickAwayListener } from '@material-ui/core';
import { VisitStatusIcon, statuses } from '../../../../global/visitStatus';
import { IVisitStatusSelectProps } from './types';
import { Options, Option, SelectedOption, Selector, VisitStatusWrap, Select } from './styles';

const VisitStatusSelect: FC<IVisitStatusSelectProps> = ({ visitStatus, register, setValue }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState({ id: '', name: '' });

  useEffect(() => {
    const defaultStatus = statuses.find((el) => el.id === visitStatus);
    setStatus(defaultStatus);
    setValue('status', visitStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <Selector value={status.id} onClick={() => setOpen(!isOpen)}>
          <Select>
            <VisitStatusWrap>
              <VisitStatusIcon value={status.id} />
            </VisitStatusWrap>
            <SelectedOption value={status.id}>{status.name}</SelectedOption>
            <input type="hidden" {...register('status')} />
          </Select>
          <KeyboardArrowDownIcon style={{ color: '#515D6B', fontSize: '17px' }} />
        </Selector>
        {isOpen && (
          <Options>
            {statuses.map((value) => {
              return (
                <Option
                  selected={status.id === value.id}
                  key={value.id}
                  onClick={() => {
                    setStatus(value);
                    setValue('status', value.id);
                    setOpen(false);
                  }}
                >
                  <VisitStatusWrap>
                    <VisitStatusIcon value={value.id} />
                  </VisitStatusWrap>
                  <div>{value.name}</div>
                </Option>
              );
            })}
          </Options>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default VisitStatusSelect;
