import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import Column from './Column';
import MenuColumn from './MenuColumn';
import SuggestionsMenu from '../../../components/Suggestions/state';
import ColumnWithStream from './ColumnWithStream';
import WithHoverTip from './WithHoverTip';
import { useGetPermissionsQuery } from '../../../services/users';
import { RootState } from '../../../store';
import { patientFormSchema } from '../utils';

type RowProps = {
  isColored: boolean;
};
const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${({ isColored }) => (isColored ? '#F9F9F9' : 'white')};
  border-bottom: 1px solid #d3d6da;
`;

function dropNull(obj) {
  const keys = Object.keys(obj);
  const notEmptyKeys = [];
  keys.forEach((key: string) => {
    if (!!obj[key]) {
      notEmptyKeys.push(key);
    }
  });
  return notEmptyKeys.reduce((acc: object, item: string) => ({ ...acc, [item]: obj[item] }), {});
}

function Patient({
  id,
  isColored,
  patientsPage,
  handleClick,
  common,
  editor,
  onStartEdit,
  onStopEdit,
}: {
  id: number;
  isColored: boolean;
  patientsPage: RootState['patientsPage'];
  openMenu: VoidFunction;
  closeMenu: VoidFunction;
  index: number;
  common: RootState['common'];
  editor: string;
  handleClick: VoidFunction;
  onStartEdit: (patientId: number) => void;
  onStopEdit: (patientId: number) => void;
}) {
  const {
    first_name = '',
    second_name = '',
    last_name = '',
    phone = '',
    email = '',
    birth_date = '',
    stream = '',
    commentary = '',
  } = dropNull(patientsPage.patients.byId[id]);

  const methods = useForm({
    resolver: yupResolver(patientFormSchema),
  });

  const [isEditing, setEditing] = useState(false);
  const { isCreating } = patientsPage;
  const { data: permissions } = useGetPermissionsQuery(common.user.id);

  return (
    <FormProvider {...methods}>
      <Row key={id} isColored={isColored}>
        <Column
          onClick={handleClick}
          name={'id'}
          defaultValue={id}
          isEditing={!isCreating && isEditing}
          width={80}
          hasAction
        >
          {id}
        </Column>
        <Column
          onClick={handleClick}
          name={'last_name'}
          defaultValue={last_name}
          isEditing={!isCreating && isEditing}
          width={196}
          hasAction
        >
          {last_name}
        </Column>
        <Column
          onClick={handleClick}
          name={'first_name'}
          defaultValue={first_name}
          isEditing={!isCreating && isEditing}
          width={130}
          hasAction
        >
          {first_name}
        </Column>
        <Column
          onClick={handleClick}
          name={'second_name'}
          defaultValue={second_name}
          isEditing={!isCreating && isEditing}
          width={140}
          hasAction
        >
          {second_name}
        </Column>
        <Column
          onClick={handleClick}
          name={'birth_date'}
          defaultValue={birth_date}
          isEditing={!isCreating && isEditing}
          width={103}
          hasAction
        >
          {birth_date}
        </Column>
        <Column
          onClick={handleClick}
          name={'phone'}
          defaultValue={permissions['view_patient_contacts'] ? phone : '*'.repeat(phone.length)}
          isEditing={!isCreating && isEditing}
          width={174}
          hasAction
        >
          {permissions['view_patient_contacts'] ? phone : '*'.repeat(phone.length)}
        </Column>
        <Column
          hasAction
          onClick={handleClick}
          name={'email'}
          defaultValue={permissions['view_patient_contacts'] ? email : '*'.repeat(email.length)}
          isEditing={!isCreating && isEditing}
          width={150}
        >
          {permissions['view_patient_contacts'] ? email : '*'.repeat(email.length)}
        </Column>
        <ColumnWithStream
          onClick={handleClick}
          name={'stream'}
          defaultValue={stream}
          isEditing={!isCreating && isEditing}
          width={164}
          hasAction
        >
          {stream}
        </ColumnWithStream>
        <Column
          onClick={handleClick}
          name={'commentary'}
          defaultValue={commentary}
          isEditing={!isCreating && isEditing}
          width={160}
          hasAction
        >
          <WithHoverTip
            isOverflown={commentary && commentary.length > 20 && !isEditing}
            isColored={isColored}
            defaultValue={commentary}
          >
            {commentary}
          </WithHoverTip>
        </Column>
        <MenuColumn
          id={id}
          isEditing={!isCreating && isEditing}
          setEditing={setEditing}
          editor={editor}
          onStartEdit={onStartEdit}
          onStopEdit={onStopEdit}
        />
      </Row>
    </FormProvider>
  );
}

export default connect((state: RootState) => ({ common: state.common, patientsPage: state.patientsPage }), {
  openMenu: SuggestionsMenu.actions.openMenu,
  closeMenu: SuggestionsMenu.actions.closeMenu,
})(Patient);
