import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { HeaderColumn } from '../style';
import TextInput from '../../../uikit/TextInput';
import PatientState from '../state';
import { OnTopTooltip } from '../../../components/DoctorFilter/OnTopTooltip';
import { searchForPatient } from '../operations';
import SuggestionsState from '../../../components/Suggestions/state';
import { getMask } from '../../../utils/masks';
import { RootState } from '../../../store';
import { Phone } from '../../../uikit/Phone';

function CreateHeader({
  width,
  name,
  type,
  placeholder,
  patientsPage,
  dropInputFields,
  searchFor,
  searchForPatient,
  openMenu,
  tooltipWidth = null,
}) {
  const { register, formState, trigger, reset } = useFormContext();
  const hasErrors = formState.errors[name];
  const input_mask = getMask(type);

  useEffect(
    () => () => {
      dropInputFields();
      reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleBlur = () => async () => {
    await trigger(name);
  };

  return (
    <HeaderColumn style={{ paddingTop: 11 }} width={width}>
      <OnTopTooltip
        title={hasErrors?.message}
        arrow
        tooltipWidth={tooltipWidth}
        isShown={patientsPage.errors[name] || hasErrors}
      >
        <div style={{ height: 32, marginLeft: 0, width: width - 16 }}>
          {type === 'phone' ? (
            <Phone
              onBlur={handleBlur}
              onChange={(value, code) => () => {
                searchFor({ [name]: `+${code} ${value.trim()}` });
                searchForPatient();
              }}
              registerField={register(name)}
              error={patientsPage.errors[name] || hasErrors}
            />
          ) : (
            <TextInput
              name={name}
              onBlur={handleBlur(name)}
              defaultValue={!!patientsPage.searchQuery[name] ? patientsPage.searchQuery[name] : ''}
              {...register(name)}
              placeholder={placeholder}
              error={patientsPage.errors[name] || hasErrors}
              disabled={name === 'id'}
              onFocus={() => {
                openMenu(name);
              }}
              value={patientsPage.data[name]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = input_mask.resolve(e.target.value);
                const isFio = name === 'last_name' || name === 'first_name' || name === 'second_name';
                searchFor({
                  [name]:
                    isFio && value.length > 1 ? `${value[0].toUpperCase()}${value.slice(1)}`.trim() : value.trim(),
                });
                searchForPatient();
              }}
            />
          )}
        </div>
      </OnTopTooltip>
    </HeaderColumn>
  );
}

export default connect((state: RootState) => ({ patientsPage: state.patientsPage }), {
  setData: PatientState.actions.setData,
  searchFor: PatientState.actions.searchFor,
  searchForPatient,
  dropInputFields: PatientState.actions.dropInputFields,
  openMenu: SuggestionsState.actions.openMenu,
})(CreateHeader);
