import React, { useState } from 'react';
import { HeaderCode, HeaderEdit, HeaderName, HeaderLayout, HeaderValue } from '../styles';
import TextInput from '../../../uikit/TextInput';
import { connect } from 'react-redux';
import { searchForPriceItem } from '../operations';
import { RootState } from '../../../store';
function HeaderRow({ searchForPriceItem, priceSlice, common }) {
  const [isMouseOver, handleMouseOver] = useState('');
  const [queries, setQueries] = useState({ code: '', title: '', value: '' });
  const { price, pickedCategory, response } = priceSlice;
  const canEdit = common.user.permissions.edit_price || common.user.is_superuser;
  const headerSwith =
    (!(pickedCategory.id === 'default' && response.length === 0) &&
      // eslint-disable-next-line no-prototype-builtins
      (price.hasOwnProperty(pickedCategory.id) || canEdit)) ||
    (pickedCategory.id === 'default' && response.length !== 0);
  return (
    <>
      {headerSwith && (
        <HeaderLayout>
          <HeaderCode onMouseEnter={() => handleMouseOver('code')} onMouseLeave={() => handleMouseOver('')}>
            {isMouseOver === 'code' || (queries.code.length > 0 && priceSlice.isSearching) ? (
              <div style={{ marginLeft: -10 }}>
                <TextInput
                  onChange={(e) => {
                    setQueries({ ...queries, code: e.target.value });
                    searchForPriceItem({ ...queries, code: e.target.value });
                  }}
                  placeholder={'Поиск'}
                  underlined
                />
              </div>
            ) : (
              'Код'
            )}
          </HeaderCode>
          <HeaderName onMouseEnter={() => handleMouseOver('name')} onMouseLeave={() => handleMouseOver('')}>
            {isMouseOver === 'name' || (queries.title.length > 0 && priceSlice.isSearching) ? (
              <div style={{ marginLeft: -10 }}>
                <TextInput
                  onChange={(e) => {
                    setQueries({ ...queries, title: e.target.value });
                    searchForPriceItem({ ...queries, title: e.target.value });
                  }}
                  placeholder={'Поиск'}
                  underlined
                />
              </div>
            ) : (
              'Наименование'
            )}
          </HeaderName>
          <HeaderValue onMouseEnter={() => handleMouseOver('value')} onMouseLeave={() => handleMouseOver('')}>
            {isMouseOver === 'value' || (queries.value.length > 0 && priceSlice.isSearching) ? (
              <div style={{ marginLeft: -10 }}>
                <TextInput
                  placeholder={'Поиск'}
                  onChange={(e) => {
                    setQueries({ ...queries, value: e.target.value });
                    searchForPriceItem({ ...queries, value: e.target.value });
                  }}
                  underlined
                />
              </div>
            ) : (
              'Цена'
            )}
          </HeaderValue>
          <HeaderEdit />
        </HeaderLayout>
      )}
    </>
  );
}

export default connect((state: RootState) => ({ common: state.common, priceSlice: state.priceSlice }), {
  searchForPriceItem,
})(HeaderRow);
