import React, { ChangeEvent, FC, memo, useState } from 'react';
import styled from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextInput from '../../../../uikit/TextInput';
import { InvoiceProperties } from '../enums';
import { DiscountTypeSelect } from './DiscountTypeSelect';
import { Modal } from '../../../../uikit/Modal';
import { ValidationMessage } from '../../../../uikit/ValidationMessage';

import { getMask } from '../../../../utils/masks';
import { DiscountTypeItems, DiscountTypeValues } from '../constants';

const useStyles = makeStyles(() =>
  createStyles({
    selectFullWidth: {
      width: 100,
    },
    discountSum: {
      background: 'green',
      '&.activeInput': {
        border: '1px solid #4555e4',
        boxShadow: '0px 0px 4px rgba(87, 123, 249, 0.5)',
      },
    },
  }),
);
const DiscountContainer = styled.div`
  margin: 0;
  display: flex;
  position: relative;
`;
type DiscountInputsProps = {
  totalPrice: number;
  discountType: string;
  value: number;
  subInvoiceId: number;
  rowId: number;
  onUpdateInvoice?: (value: string, rowId: number, property: string) => void;
};

export const DiscountInputs: FC<DiscountInputsProps> = memo(
  ({ totalPrice, discountType, value, subInvoiceId, rowId, onUpdateInvoice }) => {
    const [anchorModalError, setAnchorModalError] = useState<null | HTMLElement>(null);
    const [discountSum, setDiscountSum] = useState<number>(value);
    const classes = useStyles();
    const selectValue = DiscountTypeItems.find((item) => item.value === discountType)?.name;
    const inputSumId = `input${subInvoiceId}${rowId}`;
    const handleDiscountSumChange = (e: ChangeEvent<HTMLInputElement>) => {
      const errorRubCondition = discountType === DiscountTypeValues.RUB && +e.target.value > totalPrice;
      const errorPercentCondition = discountType === DiscountTypeValues.PERCENT && +e.target.value > 100;
      setDiscountSum(+getMask('count').resolve(`${+e.target.value}`));
      if (errorRubCondition || errorPercentCondition) {
        const activeInput = document.getElementById(inputSumId);
        setAnchorModalError(activeInput);
        return;
      }
      setAnchorModalError(null);
    };

    return (
      <DiscountContainer>
        <label style={{ margin: 0 }}>
          <TextInput
            className={classes.discountSum}
            error={anchorModalError}
            id={inputSumId}
            value={discountSum}
            style={{ height: 24, fontSize: 12, width: 97 }}
            onBlur={(e) => {
              if (anchorModalError) return;
              onUpdateInvoice(e.target.value, rowId, InvoiceProperties.DISCOUNT_SUM);
            }}
            onChange={handleDiscountSumChange}
          />
          <DiscountTypeSelect
            items={DiscountTypeItems}
            value={selectValue}
            inputId={inputSumId}
            handleUpdateInvoice={onUpdateInvoice}
            rowId={rowId}
          />
        </label>
        <Modal anchor={anchorModalError} placementModal={'bottom-start'} unSetContainer={true} zIndex={1400}>
          <ValidationMessage arrowMarginLeft={15} arrowMarginRight={156}>
            Скидка не более 100%
          </ValidationMessage>
        </Modal>
      </DiscountContainer>
    );
  },
);
DiscountInputs.displayName = 'DiscountInputs';
