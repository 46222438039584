import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ReportRevenueFilters } from './components/ReportRevenueFilters';
import { ReportTable } from './components/ReportTable';
import { EmptyPages } from './components/EmptyPages';
import { Column } from '../../components/Table';
import RevenueReportTableFooter from './components/ReportTable/RevenueReportTableFooter';
import { revenueColumns } from './helpers';
import { resetRevenueFilter } from './reducer';

export function RevenueReportPage() {
  const dispatch = useAppDispatch();
  const revenueReport = useAppSelector((state) => state.reports.revenueReport);
  const columns: Column<Record<string, unknown>>[] = useMemo(() => revenueColumns, []);
  const onResetFilters = () => dispatch(resetRevenueFilter(null));

  return (
    <>
      <ReportRevenueFilters />
      {revenueReport && revenueReport.length ? (
        <ReportTable
          tableData={revenueReport}
          columns={columns}
          footer={<RevenueReportTableFooter tableData={revenueReport} />}
          bottomOffset={215}
          mode={'revenue'}
          noScrollbars
        />
      ) : (
        <EmptyPages data={revenueReport} onResetFilters={onResetFilters} />
      )}
    </>
  );
}
