import React, { useState, useEffect } from 'react';
import Column from '../../../../../../PatientPage/components/Column';
import WithHoverTip from '../../../../../../PatientPage/components/WithHoverTip';
import ColumnWithStream from '../../../../../../PatientPage/components/ColumnWithStream';
import { Col, ColumnContainer, EditWrapper, Row } from './styles';
import {
  TEditingFields,
  initialEditingFields,
  IPatientProps,
  TEditiableFieldsValues,
  initialEditiableFieldsValues,
} from './types';
import { DeleteIcon, RedactIcon } from '../../../../../../../uikit/Icons';
import Tooltip from '../../../../../../../components/Tooltip/Tooltip';
// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast';

export default function Patient({
  data,
  isColored,
  isFullView,
  handleClick,
  editableFields = undefined,
  handleFieldEdit = undefined,
  editiableFieldsValues = undefined,
  isSelected = false,
}: IPatientProps) {
  const { id: card_number, first_name, last_name, second_name, phone, email, birth_date, stream, commentary } = data;
  const [editingFields, setEditingFields] = useState<TEditingFields>(initialEditingFields);
  const [areFieldsDirty, setAreFieldsDirty] = useState<TEditingFields>(initialEditingFields);
  const [localEditiableFieldsValues, setLocalEditiableFieldsValues] =
    useState<TEditiableFieldsValues>(initialEditiableFieldsValues);

  const handleEditClick = (fieldName) => {
    if (editingFields[fieldName]) {
      setEditingFields((prev) => {
        return { ...prev, [fieldName]: false };
      });
      handleFieldEdit && handleFieldEdit(fieldName, '');
      toast.success('Редактирование отменено. Пациент не будет изменён после сохранения визита');
    } else {
      setEditingFields((prev) => {
        return { ...prev, [fieldName]: true };
      });
      if (!areFieldsDirty[fieldName]) {
        handleFieldEdit && handleFieldEdit(fieldName, data[fieldName]);
        setAreFieldsDirty((prev) => {
          return { ...prev, [fieldName]: true };
        });
      } else {
        handleFieldEdit && handleFieldEdit(fieldName, localEditiableFieldsValues[fieldName]);
      }
      toast.success('Поле редактируется. Новые данные пациента сохранятся после сохранения визита');
    }
  };

  const handleEdit = (fieldName, value) => {
    handleFieldEdit && handleFieldEdit(fieldName, value);
    setLocalEditiableFieldsValues((prev) => {
      return { ...prev, [fieldName]: value };
    });
  };

  const getEditButton = (fieldName) => {
    if (editableFields && editableFields[fieldName]) {
      return (
        <EditWrapper onClick={() => handleEditClick(fieldName)}>
          {editingFields[fieldName] ? (
            <Tooltip title="Отменить изменения" placement={'bottom-start'} enterDelay={500} enterNextDelay={500}>
              <DeleteIcon />
            </Tooltip>
          ) : (
            <Tooltip title="Редактировать поле" placement={'bottom-start'} enterDelay={500} enterNextDelay={500}>
              <RedactIcon />
            </Tooltip>
          )}
        </EditWrapper>
      );
    }
  };

  useEffect(() => {
    return () => {
      setEditingFields(initialEditingFields);
      setAreFieldsDirty(initialEditingFields);
      setLocalEditiableFieldsValues(initialEditiableFieldsValues);
    };
  }, []);

  return (
    <Row key={card_number} isColored={isColored} onClick={handleClick} isSelected={isSelected}>
      <Column name="card_number" defaultValue={card_number} width={81} isEditing={false} hasAction>
        <Col ml={16}>{card_number}</Col>
      </Column>
      <Column name="last_name" defaultValue={last_name} width={160} isEditing={false} hasAction>
        <Col ml={isSelected ? 0 : 8}>
          <WithHoverTip
            isOverflown={last_name && last_name.length > 20}
            isColored={isColored}
            defaultValue={last_name}
            isSelected={!isSelected}
          >
            {last_name}
          </WithHoverTip>
        </Col>
      </Column>
      <Column name="first_name" defaultValue={first_name} width={160} isEditing={false} hasAction>
        <Col ml={isSelected ? 0 : 8}>
          <WithHoverTip
            isOverflown={first_name && first_name.length > 20}
            isColored={isColored}
            defaultValue={first_name}
            isSelected={!isSelected}
          >
            {first_name}
          </WithHoverTip>
        </Col>
      </Column>
      <Column name="second_name" defaultValue={second_name} width={160} isEditing={false} hasAction>
        <Col ml={isSelected ? 0 : 8}>
          <WithHoverTip
            isOverflown={second_name && second_name.length > 20}
            isColored={isColored}
            defaultValue={second_name}
            isSelected={!isSelected}
          >
            {second_name}
          </WithHoverTip>
        </Col>
      </Column>
      {isFullView && (
        <Column name="birth_date" defaultValue={birth_date} width={103} isEditing={false} hasAction>
          <Col ml={isSelected ? 0 : 8}>{birth_date}</Col>
        </Column>
      )}
      <Column name="phone" defaultValue={phone} width={147} isEditing={false} hasAction>
        <Col ml={isSelected ? 0 : 8}>{phone}</Col>
      </Column>
      {isFullView && (
        <Column name="email" defaultValue={email} width={149} isEditing={false} hasAction>
          <Col ml={isSelected ? 0 : 8}>
            <WithHoverTip
              isOverflown={email && email.length > 20}
              isColored={isColored}
              defaultValue={email}
              isSelected={!isSelected}
            >
              {email}
            </WithHoverTip>
          </Col>
        </Column>
      )}
      {isFullView && (
        <ColumnWithStream name="stream" defaultValue={stream} width={164} isEditing={false}>
          <Col ml={isSelected ? 0 : 8}>
            <WithHoverTip
              isOverflown={stream && stream.length > 20}
              isColored={isColored}
              defaultValue={stream}
              isSelected={!isSelected}
            >
              {stream}
            </WithHoverTip>
          </Col>
        </ColumnWithStream>
      )}

      <ColumnContainer>
        <Column
          name="commentary"
          defaultValue={commentary}
          width={150}
          isEditing={editingFields['commentary']}
          onEdit={(value) => handleEdit('commentary', value)}
          value={editiableFieldsValues ? editiableFieldsValues['commentary'] : undefined}
          hasAction
        >
          <Col ml={0} w={'100%'}>
            {commentary && commentary.length > 15 ? (
              <Tooltip title={commentary} placement={'bottom-start'} enterDelay={500} enterNextDelay={500}>
                <span>{commentary && `${commentary.substring(0, 14)}...`}</span>
              </Tooltip>
            ) : (
              <span>{commentary}</span>
            )}
          </Col>
        </Column>
        {getEditButton('commentary')}
      </ColumnContainer>
    </Row>
  );
}
