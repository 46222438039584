import {
  CABINETS_GET,
  LOAD_PROFILE,
  LOAD_WORKDAYS,
  SAVE_PROFILE,
  LOAD_APPOINTMENTS,
  UPDATE_APPOINTMENTS,
  RELOAD_STATE,
  HAS_ERRORS,
  LOAD_STAFF,
  LOAD_ORGANISATIONS,
  LOAD_PATIENTS,
  LOAD_PRICE,
  RELOAD_LOGO,
  SET_STAFFER_SEARCH_QUERY,
  SET_PATIENT_SEARCH_QUERY,
} from '../legacyState';
import { UPDATE_WORHOURS } from '../workday/actions_workdays';
import { FLUSH_STATE } from '../flushState/types';
const INITIAL_STATE = {
  cabinets: [],
  profile: {},
  workdays: {},
  appointments: [],
  patients: [],
  price: [],
  staff: [],
  profile_has_errors: false,
  organisations: [],
  staffQuery: {
    first_name: '',
    second_name: '',
    last_name: '',
    phone: '',
    email: '',
    job: '',
  },
  patientQuery: {
    first_name: '',
    second_name: '',
    last_name: '',
    phone: '',
    email: '',
    birth_date: '',
    date: '',
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_STAFFER_SEARCH_QUERY:
      return {
        ...state,
        staffQuery: {
          ...state.staffQuery,
          [action.payload.name]: action.payload.value,
        },
      };
    case SET_PATIENT_SEARCH_QUERY:
      return {
        ...state,
        patientQuery: {
          ...state.patientQuery,
          [action.payload.name]: action.payload.value,
        },
      };
    case CABINETS_GET:
      return {
        ...state,
        ...{ cabinets: action.payload },
      };
    case LOAD_PROFILE:
      return {
        ...state,
        ...{ profile: action.payload },
      };
    case RELOAD_LOGO:
      return {
        ...state,
        ...{ profile: action.payload },
      };
    case SAVE_PROFILE:
      return {
        ...state,
        ...{ profile: action.payload },
      };
    case LOAD_WORKDAYS:
      return {
        ...state,
        ...{ workdays: action.payload },
      };
    case UPDATE_WORHOURS:
      return {
        ...state,
        ...{ workdays: action.payload },
      };

    case LOAD_APPOINTMENTS:
      return {
        ...state,
        ...{ appointments: action.payload.items },
      };
    case UPDATE_APPOINTMENTS:
      return {
        ...state,
        ...{ appointments: action.payload },
      };
    case RELOAD_STATE:
      return {
        ...state,
        ...{ appointments: action.payload.clinic.appointments },
      };
    case HAS_ERRORS:
      return {
        ...state,
        ...{ profile_has_errors: action.payload },
      };
    case LOAD_STAFF:
      return {
        ...state,
        ...{ staff: action.payload },
      };
    case LOAD_PATIENTS:
      return {
        ...state,
        ...{ patients: action.payload },
      };
    case LOAD_PRICE:
      return {
        ...state,
        ...{ price: action.payload },
      };
    case LOAD_ORGANISATIONS:
      return {
        ...state,
        ...{ organisations: action.payload.items },
      };
    case FLUSH_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
