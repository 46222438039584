import React, { useCallback, useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SearchHeader } from '../SearchHeader';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars-2';
import TextInput from '../../../../uikit/TextInput';
import { BorderSelect } from '../Select';
import { getMask } from '../../../../utils/masks';
import { TreatmentPlanJournalFieldItem } from './TreatmentPlanJournalFieldItem';
import Column from '../../../PatientPage/components/Column';
import { isToday, parse } from 'date-fns';

export const useStyles = makeStyles(() =>
  createStyles({
    root: (props: { selected: boolean }) => ({
      width: 650,
      marginTop: -2,
      marginBottom: 16,
      border: props.selected ? '1px solid #577BF9' : 0,
      boxSizing: 'border-box',
      boxShadow: 'none',
      borderRadius: 4,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    }),
    header: {
      background: '#F9F9F9',
      padding: 0,
    },
    headerContent: {
      display: 'flex',
      flex: 1,
      padding: 0,
      borderBottom: '2px solid #7C8590',
    },
    content: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    footerContent: {
      display: 'flex',
      alignItems: 'center',
      background: '#F9F9F9',
      fontWeight: 500,
      fontSize: 12,
      color: '#253446',
    },
    discountSum: {
      borderBottomRightRadius: '0 !important',
      borderTopRightRadius: '0 !important',
    },
    discountType: {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderLeft: 0,
    },
  }),
);

type InvoiceHistoryItem = {
  id: number;
  count: number;
  paid: number;
  date: string;
  invoiceRowId: number;
};

export type TreatmentPlanItem = {
  id: number;
  date: string;
  code: string;
  text: string;
  count: number;
  price: number;
  discount: number;
  discountType: string; // руб. или %
  payment: number;
  isUsed?: boolean;
  invoiceHistory: InvoiceHistoryItem[];
};

type Props = {
  data: TreatmentPlanItem[];
  withoutDate?: boolean;
  readOnly?: boolean;
  selected?: boolean;
  isAddAccount?: boolean;
  totalDiscount: number;
  onRowClick: (template) => void;
  onRowUpdate?: (template) => void;
  hasTotalTitle?: boolean;
  canFocused?: boolean;
  setFocus?: VoidFunction;
};

export const DiscountTypeValues = {
  RUB: 'RUB',
  PERCENT: 'PERCENT',
};

export const DiscountTypeItems = [
  {
    value: DiscountTypeValues.RUB,
    name: 'руб.',
  },
  {
    value: DiscountTypeValues.PERCENT,
    name: '%',
  },
];

export const ColumnWidth = {
  date: 66,
  text: 180,
};

export function TreatmentPlanJournalField({
  data,
  withoutDate = false,
  readOnly = false,
  selected = false,
  isAddAccount = false,
  onRowClick,
  onRowUpdate,
  hasTotalTitle = false,
  canFocused = false,
  setFocus,
}: Props) {
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [totalDiscountType, setTotalDiscountType] = useState<string>(DiscountTypeValues.RUB);
  const [totalDisabled, setTotalDisabled] = useState<boolean>(false);

  const classes = useStyles({ selected: canFocused && selected });

  const getTotalCount = () => {
    const sum = data.reduce((prev, current) => prev + current.count, 0);
    return getMask('number_format').resolve(`${sum}`);
  };

  const getTotalPrice = () => {
    const sum = data.reduce((prev, current) => prev + current.count * current.price, 0);
    return getMask('number_format').resolve(`${sum}`);
  };

  const getTotalDiscount = () => {
    const sum = data.reduce(
      (prev, current) =>
        prev +
        (current.discountType === DiscountTypeValues.RUB
          ? current.discount
          : current.count * current.price * current.discount * 0.01),
      0,
    );
    return getMask('number_format').resolve(`${sum}`);
  };

  const getTotalPayment = () => {
    const sum = data.reduce((prev, current) => prev + current.payment, 0);
    return getMask('number_format').resolve(`${sum}`);
  };

  const getItemsDiscount = useCallback(
    (totalDiscount: number) => {
      const totalPrice = data.reduce((prev, current) => prev + current.count * current.price, 0);
      if (!totalPrice) {
        return;
      }
      const percent = (totalDiscount * 100) / totalPrice;
      const discounts = orderBy(data, (d) => d.price * d.count).reduce(
        (prev, current) => ({ ...prev, [current.id]: Math.trunc(current.price * current.count * percent * 0.01) }),
        {},
      );
      const sumDiscounts = Object.values(discounts).reduce((prev, value) => prev + value, 0);
      const discountKeys = Object.keys(discounts);
      for (let i = 0; i < totalDiscount - sumDiscounts; i++) {
        const index = i < discountKeys.length - 1 ? i : i - discountKeys.length;
        discounts[discountKeys[index]] = discounts[discountKeys[index]] + 1;
      }
      return discounts;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalDiscount, data.length],
  );

  const getDiscount = (itemId, totalDiscount, totalDiscountType) => {
    return totalDiscountType === DiscountTypeValues.PERCENT ? totalDiscount : getItemsDiscount(totalDiscount)[itemId];
  };

  const textColumnWidth = withoutDate
    ? readOnly
      ? ColumnWidth.text + ColumnWidth.date + 40
      : ColumnWidth.text + ColumnWidth.date
    : readOnly
    ? ColumnWidth.text + 40
    : ColumnWidth.text;

  const setItem = (item: TreatmentPlanItem) => {
    if (!readOnly) {
      onRowUpdate?.(item);

      const disabled = !totalDiscount && item.discount > 0;
      setTotalDisabled(disabled);
      if (disabled) {
        setTotalDiscountType(DiscountTypeValues.RUB);
      }
    }
  };

  const updateData = ({ totalDiscount, totalDiscountType: updatedTotalDiscountType }) => {
    data.forEach((item) => {
      const discount =
        totalDiscount > 0 && updatedTotalDiscountType === totalDiscountType
          ? getDiscount(item.id, totalDiscount, updatedTotalDiscountType)
          : 0;

      setItem({
        ...item,
        discount: discount,
        discountType: updatedTotalDiscountType,
      });
    });

    setTotalDiscount(updatedTotalDiscountType === totalDiscountType ? totalDiscount : 0);
    setTotalDiscountType(updatedTotalDiscountType);
    setTotalDisabled(false);
  };

  useEffect(() => {
    const sum = data.reduce(
      (prev, current) =>
        prev +
        (current.discountType === DiscountTypeValues.RUB
          ? current.discount
          : current.count * current.price * current.discount * 0.01),
      0,
    );
    setTotalDiscount(sum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (totalDiscount) {
      updateData({ totalDiscount, totalDiscountType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        onClick={() => canFocused && setFocus?.()}
        title={
          <div className={classes.headerContent}>
            {!withoutDate && (
              <SearchHeader
                type="text"
                placeholder="Дата"
                name="date"
                width={ColumnWidth.date}
                fontSize={12}
                paddingLeft={4}
                text="Дата"
                noBorder
                searchable={false}
              />
            )}
            <SearchHeader
              type="text"
              placeholder="Код"
              name="code"
              width={94}
              fontSize={12}
              text="Код"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="Наименование"
              fontSize={12}
              name="text"
              width={textColumnWidth}
              text="Наименование"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="Кол-во"
              fontSize={12}
              name="count"
              width={55}
              text="Кол-во"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="Цена"
              fontSize={12}
              name="price"
              width={66}
              text="Цена"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="Скидка"
              fontSize={12}
              name="discount"
              width={readOnly ? 58 : 108}
              text="Скидка"
              noBorder
              searchable={false}
            />
            <SearchHeader
              type="text"
              placeholder="К оплате"
              fontSize={12}
              name="payment"
              width={72}
              text="К оплате"
              noBorder
              searchable={false}
            />
          </div>
        }
      />
      <Scrollbars
        autoHeight
        autoHeightMax={600}
        style={{
          width: '100%',
        }}
      >
        <CardContent className={classes.content}>
          {data.map((item) => (
            <TreatmentPlanJournalFieldItem
              key={item.id}
              item={item}
              setItem={setItem}
              textColumnWidth={textColumnWidth}
              withoutDate={withoutDate}
              readOnly={readOnly}
              isAddAccount={isAddAccount}
              totalDiscount={totalDiscount}
              onRowClick={onRowClick}
            />
          ))}
          <div className={classes.footerContent}>
            <Column
              name="text"
              defaultValue=""
              fontSize={12}
              width={`calc(100% - 55px - 66px - ${readOnly ? 58 : 108}px - 72px - ${readOnly ? 18 : 8}px)`}
              isEditing={false}
              textAlign={hasTotalTitle ? 'right' : 'left'}
              hasAction
              fontWeightBold
            >
              {hasTotalTitle ? 'Итого:' : 'Стоимость:'}
            </Column>
            <Column
              name="count"
              defaultValue=""
              width={55}
              fontSize={12}
              paddingLeft={readOnly ? 16 : 24}
              isEditing={false}
              hasAction
              fontWeightBold
            >
              {getTotalCount()}
            </Column>
            <Column name="price" defaultValue="" width={66} fontSize={12} isEditing={false} hasAction fontWeightBold>
              {getTotalPrice()}
            </Column>
            <Column
              name="discount"
              defaultValue=""
              width={readOnly ? 58 : 108}
              fontSize={12}
              isEditing={false}
              hasAction
              fontWeightBold
            >
              {readOnly ||
              !!data.find((d) => !!d.invoiceHistory.length) ||
              data.find((d) => !isToday(parse(d.date, 'dd.MM.yyyy', new Date()))) ? (
                `${getMask('number_format').resolve(`${totalDiscount}`)} ${
                  DiscountTypeItems.find(({ value }) => value === totalDiscountType)?.name
                }`
              ) : (
                <div style={{ display: 'flex' }}>
                  <TextInput
                    className={classes.discountSum}
                    value={totalDisabled ? getTotalDiscount() : totalDiscount}
                    style={{ height: 22, width: 47, fontSize: 12 }}
                    disabled={totalDisabled}
                    onChange={(e) =>
                      updateData({ totalDiscount: +getMask('count').resolve(`${+e.target.value}`), totalDiscountType })
                    }
                    onClick={(e) => e.stopPropagation()}
                  />
                  <BorderSelect
                    className={classes.discountType}
                    value={totalDiscountType}
                    disabled={totalDisabled}
                    setValue={(value) => updateData({ totalDiscount, totalDiscountType: value })}
                    items={DiscountTypeItems}
                  />
                </div>
              )}
            </Column>
            <Column
              name="payment"
              defaultValue=""
              width={72}
              fontSize={12}
              isEditing={false}
              hasAction
              fontWeightBold
              color={readOnly ? '#515d6b' : '#E54770'}
            >
              {getTotalPayment()}
            </Column>
          </div>
        </CardContent>
      </Scrollbars>
    </Card>
  );
}
