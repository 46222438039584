import React from 'react';
import { connect } from 'react-redux';

import CreateHeader from './CreateHeader';
import SearchHeader from './SearchHeader';
import { RootState } from '../../../store';

function HeaderWithSearch({ patientsPage, name, ...props }) {
  return (
    <>{patientsPage.isCreating ? <CreateHeader name={name} {...props} /> : <SearchHeader name={name} {...props} />}</>
  );
}

export default connect((state: RootState) => ({ patientsPage: state.patientsPage }), {})(HeaderWithSearch);
