import lodash from 'lodash';
export const LOAD_WEEK = 'load_week';
export const SET_USER = 'set_user';
export const MOUSE_UP = 'mouse_up';
export const ERROR_MOUSE_UP = 'error_mouse_up';
export const MOUSE_MOVE = 'mouse_move';
export const MOUSE_DOWN = 'mouse_down';
export const SET_STAFFER = 'set_staffer';
export const SET_ORGANISATION = 'set_organisation';
export const SET_PATIENT = 'set_patient';
export const DESCRIPTION_CHANGE = 'descriptions_change';
export const TITLE_CHANGE = 'title_change';
export const TASK_SAVED = 'task_saved';
export const DATE_ERROR = 'date_error';
export const CLOSE_ERROR = 'close_error';
export const SAVE_PATIENT = 'save_patient';
export const SHOW_ORGANISATION_MENU = 'show_organisation_menu';
export const CHANGE_ORGANISATION = 'change_organisation';
export const SHOW_ORGANISATION_DIALOG = 'show_organisation_dialog';
export const CLOSE_ORGANISATION = 'close_organistation';
export const SET_FILTER = 'set_filter';
export const LOAD_WEEK_HEADER = 'load_week_header';
export const SET_FILTER_FIELDS = 'set_filter_fields';
export const SET_FILTER_TYPE = 'set_filter_type';
export const SET_PAGINATION = 'set_pagination';
export const NEXT_PAGE = 'next_page';
export const PICK_COLOR = 'pick_color';
export const TASK_CLOSE = 'task_close';
export const LOAD_SCHEDULE = 'load_schedule';
const _ = lodash;

export function setPagination(len) {
  return {
    type: SET_PAGINATION,
    payload: len,
  };
}

export function setPatient(staffer) {
  let data = `${staffer.last_name[0]} ${staffer.first_name[0]}. ${staffer.second_name}`;
  return {
    type: SET_PATIENT,
    payload: data,
  };
}

export function setCurrentPatient(patient) {
  return async (dispatch) => {
    await dispatch(setPatient(patient));
    await dispatch({
      type: SAVE_PATIENT,
      payload: patient,
    });
  };
}

export function closeError() {
  return {
    type: CLOSE_ERROR,
    payload: { show: false, message: '' },
  };
}

export function loadUserSchedule(data) {
  return async (dispatch, getState) => {
    const { calendar } = getState();
    let filterType = calendar.schedule.filterType;

    switch (filterType) {
      case 'day':
        data = _.groupBy(data, (item) => item.staffer);
        break;
      case 'day-doctor':
        data = _.groupBy(data, (item) => item.date.formated);
        break;
      default:
        break;
    }

    dispatch({
      type: LOAD_SCHEDULE,
      payload: data,
    });
  };
}
