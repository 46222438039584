import React, { FC } from 'react';

const CommentIcon: FC = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4519_212789)">
        <path
          d="M12.2499 10.9374H5.68741L3.49991 12.6874L2.18741 11.3749C0.437561 10.9374 0.437561 10.1249 0.437561 9.62494C0.437561 8.62494 0.500004 5.6 0.500004 4C0.500004 2.4 0.749913 1.31244 1.74991 1.31244H12.2499C13.8499 1.31244 13.5 2.82348 13.5 3.5V9C13.5 10.2 13.1249 10.9374 12.2499 10.9374Z"
          fill="white"
        />
        <g clipPath="url(#clip1_4519_212789)">
          <path
            d="M3.70648 13.125C3.58318 13.1252 3.46358 13.0815 3.36769 13.0014L1.85311 11.4457C1.33064 11.4183 0.836789 11.1906 0.468041 10.8069C0.325482 10.6604 0.213825 10.4851 0.14008 10.2918C0.0663363 10.0985 0.0321118 9.89152 0.0395788 9.68389V2.6883C0.0421946 2.21095 0.226761 1.75393 0.553217 1.41639C0.879672 1.07885 1.32168 0.88802 1.78335 0.885315H12.2859C12.7414 0.896084 13.1747 1.09078 13.4932 1.42775C13.8116 1.76471 13.9899 2.21717 13.9898 2.6883V9.68389C13.9898 10.1621 13.806 10.6207 13.479 10.9588C13.152 11.2969 12.7085 11.4869 12.246 11.4869H5.39046L4.14491 12.9911C4.0168 13.084 3.86284 13.131 3.70648 13.125ZM1.97268 10.6523C2.02102 10.6522 2.06889 10.6622 2.11341 10.6817C2.15793 10.7011 2.19819 10.7297 2.23176 10.7657L3.71645 12.2802L4.8823 10.9305C5.00468 10.8048 5.16828 10.7313 5.34065 10.7245H12.246C12.5103 10.7245 12.7637 10.6159 12.9506 10.4227C13.1375 10.2295 13.2424 9.96743 13.2424 9.69419V2.6883C13.2424 2.41505 13.1375 2.153 12.9506 1.95978C12.7637 1.76657 12.5103 1.65802 12.246 1.65802H1.74348C1.47921 1.65802 1.22578 1.76657 1.03891 1.95978C0.85204 2.153 0.747044 2.41505 0.747044 2.6883V9.68389C0.740599 9.78924 0.756928 9.89476 0.794846 9.99277C0.832765 10.0908 0.891323 10.1788 0.966262 10.2505C1.23548 10.5142 1.60187 10.6513 1.97268 10.6523Z"
            fill="#7C8590"
          />
          <path
            d="M7.15992 7.34036H3.23394C3.14531 7.32535 3.06472 7.27825 3.00658 7.20748C2.94843 7.1367 2.9165 7.04687 2.9165 6.95401C2.9165 6.86115 2.94843 6.77131 3.00658 6.70054C3.06472 6.62976 3.14531 6.58267 3.23394 6.56765H7.15992C7.21411 6.55847 7.26956 6.56159 7.32246 6.57678C7.37536 6.59197 7.42445 6.61888 7.4663 6.65563C7.50814 6.69238 7.54174 6.7381 7.56479 6.78963C7.58784 6.84115 7.59979 6.89724 7.59979 6.95401C7.59979 7.01077 7.58784 7.06686 7.56479 7.11839C7.54174 7.16991 7.50814 7.21563 7.4663 7.25238C7.42445 7.28914 7.37536 7.31604 7.32246 7.33123C7.26956 7.34643 7.21411 7.34954 7.15992 7.34036Z"
            fill="#7C8590"
          />
          <path
            d="M10.725 5.53205H3.27464C3.22727 5.53347 3.18011 5.52458 3.13607 5.50593C3.09203 5.48727 3.05203 5.45925 3.01851 5.42356C2.985 5.38788 2.95867 5.34529 2.94115 5.29839C2.92363 5.2515 2.91531 5.20129 2.91664 5.15085C2.91535 5.09992 2.92365 5.04925 2.94106 5.0018C2.95847 4.95435 2.98464 4.91111 3.01801 4.87461C3.05139 4.83811 3.09128 4.80911 3.13536 4.78931C3.17944 4.76951 3.2268 4.75932 3.27464 4.75934H10.725C10.7728 4.75932 10.8202 4.76951 10.8643 4.78931C10.9084 4.80911 10.9483 4.83811 10.9817 4.87461C11.015 4.91111 11.0412 4.95435 11.0586 5.0018C11.076 5.04925 11.0843 5.09992 11.083 5.15085C11.0844 5.20129 11.076 5.2515 11.0585 5.29839C11.041 5.34529 11.0146 5.38788 10.9811 5.42356C10.9476 5.45925 10.9076 5.48727 10.8636 5.50593C10.8195 5.52458 10.7724 5.53347 10.725 5.53205Z"
            fill="#7C8590"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4519_212789">
          <rect width="14" height="14" fill="white" />
        </clipPath>
        <clipPath id="clip1_4519_212789">
          <rect width="14" height="12.25" fill="white" transform="translate(0 0.875)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CommentIcon;
