import React, { FC, useState } from 'react';
import { Modal } from '@material-ui/core';
import TextArea from '../../../../../../uikit/TextArea';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { useUpdateAppointmentMutation } from '../../../../../../services/appointments';
import { ICommentModalProps } from './types';
import { Form, Card, Title, CancelButton, SaveButton } from './styles';
import { setClickedAppointmentId, setCancellingAppointmentData } from '../../../../reducer';
import { toast } from 'react-hot-toast';
import { getFilteredEvents } from '../../../../ScheduleTable/functions';

const CommentModal: FC<ICommentModalProps> = ({ appointment, handleStopEdit }) => {
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [updateAppointment] = useUpdateAppointmentMutation();
  const { register, handleSubmit } = useForm();
  const currentClinicId = useAppSelector((state) => state.common.user.clinic.id);
  const schedulerAPI = useAppSelector((state) => state.reworkedSchedule.schedulerAPI);

  const onTextChange = (event) => {
    return event.target.value ? setDisabled(false) : setDisabled(true);
  };

  const onCancel = () => {
    dispatch(setClickedAppointmentId(appointment.id));
    dispatch(setCancellingAppointmentData(null));
  };

  const onSubmit = async ({ cancel_reason }) => {
    updateAppointment({
      id: appointment.id,
      clinic: currentClinicId,
      cancel_reason,
      status: 'CAN',
    })
      .unwrap()
      .then(() => getFilteredEvents(schedulerAPI?.getEvents(), 'appointments', appointment.id)[0].remove())
      .catch(() => {
        toast.error(`Произошла техническая ошибка, попробуйте редактировать визит позже`);
      });
    dispatch(setCancellingAppointmentData(null));
    handleStopEdit(appointment.id);
  };

  return (
    <Modal open id="comment-modal">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Title>Добавьте комментарий</Title>
          <TextArea
            {...register('cancel_reason')}
            multiline
            rows={5}
            placeholder="Оставить комментарий..."
            style={{ marginBottom: '16px' }}
            onChange={onTextChange}
            defaultValue={appointment.cancel_reason}
          />
          <div style={{ float: 'right' }}>
            <CancelButton type="button" onClick={onCancel}>
              Отмена
            </CancelButton>
            <SaveButton disabled={disabled}>Сохранить</SaveButton>
          </div>
        </Card>
      </Form>
    </Modal>
  );
};

export default CommentModal;
