import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import RenderInput from './RenderInput';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import getWeeksCount from '../../utils/getWeeksCount';
import { TodayButton } from './styled-components';
import { DatePicker, PickersDay } from '@mui/x-date-pickers';
import ruLocale from 'date-fns/locale/ru';
import { useAppSelector } from '../../store/hooks';
import { useGetHolidaysQuery, useGetWorkdaysQuery } from '../../services/dictionaries';
import { isWorkingDay } from '../../pages/CalendarPage/ScheduleTable/functions';

const theme = createTheme({
  components: {
    //Изменение ширины всплывающего выборщика дат
    MuiPaper: {
      styleOverrides: {
        root: {
          position: 'absolute',
          top: '-24px',
          left: '-56px',
          transition: 'unset !important',
          zIndex: 999,
          '.css-epd502': {
            width: '332px',
            maxHeight: '320px',

            '& > div': {
              maxHeight: '320px',
              overflow: 'hidden',
            },
          },
          '& .unactive': {
            color: '#c0c0c0',
          },
        },
      },
    },
    //Контейнер со стрелками-переключателями и текущим месяцем
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          maxHeight: '38px',
          minHeight: '38px',
          margin: '0px 0px 8px 0px',
          padding: '0 18px',
          position: 'relative',
          borderBottom: '1px solid #d3d6da',
        },
        labelContainer: {
          marginLeft: 'auto',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontWeight: '400',
          textTransform: 'capitalize',
          color: '#253446',
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: '332px',
          //Кнопки-переключатели месяцев
          '.MuiButtonBase-root': {
            outline: 'none',
            '&:hover': {
              backgroundColor: 'unset',
            },
          },
          //Размеры для иконок-стрелок переключателей месяцев
          '.css-i4bv87-MuiSvgIcon-root': {
            width: '0.8em',
            height: '0.8em',
          },
          //Контейнер с переключателями месяца
          '.MuiPickersArrowSwitcher-root': {
            position: 'absolute',
            left: '5px',
            right: '5px',
            justifyContent: 'space-between',
          },
        },
      },
    },
    //Убирание эффекта серых кругов у кнопок переключения месяца
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    //Ячейки с датами
    MuiPickersDay: {
      styleOverrides: {
        root: {
          width: '36px',
          height: '30px',
          margin: '0 4.5px',
          fontSize: '14px',
          borderRadius: '3px',

          '&.unactive': {
            color: '#c0c0c0 !important',
          },

          '&.Mui-selected': {
            backgroundColor: '#577BF9 !important',
            fontWeight: 'normal',
            color: '#fff !important',

            '&.unactive': {
              backgroundColor: '#c0c0c0 !important',
            },
          },

          '&.MuiPickersDay-today': {
            border: '1px solid rgb(87, 123, 249)',

            '&.unactive': {
              border: '1px solid #c0c0c0',
            },
          },

          '&:hover': {
            color: '#577BF9',
          },
        },
      },
    },
    MuiDayPicker: {
      styleOverrides: {
        //Строка с днями недели
        weekContainer: {
          margin: '0',
        },
        //Строка с днями недели
        header: {
          '& > span:nth-child(1)::after': {
            content: '"н"',
          },
          '& > span:nth-child(2)::after': {
            content: '"т"',
          },
          '& > span:nth-child(3)::after': {
            content: '"р"',
          },
          '& > span:nth-child(4)::after': {
            content: '"т"',
          },
          '& > span:nth-child(5)::after': {
            content: '"т"',
          },
          '& > span:nth-child(6)::after': {
            content: '"б"',
          },
          '& > span:nth-child(7)::after': {
            content: '"с"',
          },
        },
        //Отдельный день недели
        weekDayLabel: {
          width: '36px',
          height: '30px',
          margin: '0px 4.5px',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontWeight: '400',
          color: '#253446',
          textTransform: 'lowercase',
        },
        //Контейнер контейнера месяца
        slideTransition: {
          overflow: 'hidden',
        },
        //Контейнер месяца
        monthContainer: {
          paddingBottom: '8px',
          borderBottom: '1px solid #d3d6da',
        },
      },
    },
  },
});

/**
 * Компонент выбора дат в расписании, может переиспользоваться
 * @param {Date} initialValue - изначальное выбранное значение календаря
 * @param {Date} selectedDate - выбранная дата (та, что выделяет в таблице синей рамкой)
 * @param {function} onChange - callback, вызывается при изменении выбранной даты, принимает дату, переведённую в милисекунды
 * @param {function} style - доп. стили
 */
const NewDatePicker = ({ selectedDate, onChange, style = {} }) => {
  const [value, setValue] = useState(null);
  const [isOpened, setIsOpened] = useState(false);

  const { data: holidays = [], isLoading: areHolidaysLoading } = useGetHolidaysQuery();
  const { data: workdays = [], isLoading: areWorkdaysLoading } = useGetWorkdaysQuery();

  const doctors = useAppSelector((state) => state.reworkedSchedule.doctors);
  const isDragging = useAppSelector((state) => !!state.reworkedSchedule.draggingElement);

  //При переключении месяцев/открытии календаря производится перерасчёт высоты контейнера с днями месяца:
  //Иногда в календаре может отображаться 5 недель (строк с датами), а иногда - 6
  //Средствами CSS эту проблему не решить
  const CalculateDaysContainerHeight = (date) => {
    setTimeout(() => {
      const weeksCount = getWeeksCount(new Date(date).getFullYear(), new Date(date).getMonth() + 1);
      const targetElements = document.getElementsByClassName('PrivatePickersSlideTransition-root');
      targetElements[0].style.minHeight = `${weeksCount * 30 + 9}px`;
    });
  };

  const handleClickToday = () => {
    const todayDate = new Date().setHours(0, 0, 0, 0);
    setValue(selectedDate === todayDate ? null : todayDate);
    onChange(selectedDate === todayDate ? null : todayDate);
    setIsOpened(false);
  };

  const handleDayClick = (day) => {
    const pickedDate = selectedDate === day.getTime() || value === day.getTime() ? null : day.getTime();
    setValue(pickedDate);
    onChange(pickedDate);
    setIsOpened(false);
  };

  // Перерасчёт размеров коробки с датами при её открытии
  useEffect(() => {
    if (isOpened) {
      CalculateDaysContainerHeight(value);
      if (isDragging) {
        setTimeout(() => {
          const popperElement = document.getElementById('datePickerPaper');
          popperElement?.addEventListener('mouseleave', () => setIsOpened(false));
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  return (
    <div style={style}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
        <ThemeProvider theme={theme}>
          <DatePicker
            views={['day']}
            label="Выберите дату"
            inputFormat="EEEE d MMMM"
            orientation="portrait"
            value={value}
            open={isOpened}
            disableMaskedInput
            closeOnSelect={false}
            onChange={() => null}
            onClose={() => setIsOpened(false)}
            onMonthChange={(month) => CalculateDaysContainerHeight(month)}
            PaperProps={{ id: 'datePickerPaper' }}
            renderInput={(params) => (
              <RenderInput params={params} selectedDate={selectedDate} isOpened={isOpened} setIsOpened={setIsOpened} />
            )}
            components={{
              ActionBar: () => {
                const isTodayButtonDisabled = Boolean(
                  !areHolidaysLoading &&
                    !areWorkdaysLoading &&
                    doctors &&
                    doctors.length &&
                    !isWorkingDay(new Date(new Date().setHours(0, 0, 0, 0)), holidays, workdays),
                );
                return (
                  <TodayButton>
                    <button
                      id="todayButton"
                      onClick={handleClickToday}
                      className={isTodayButtonDisabled ? 'unactive' : ''}
                      disabled={isTodayButtonDisabled}
                    >
                      Сегодня
                    </button>
                  </TodayButton>
                );
              },
            }}
            renderDay={(day, selectedDates, pickersDayProps) => {
              const isWorkingDayFlag =
                !areHolidaysLoading && !areWorkdaysLoading && isWorkingDay(day, holidays, workdays);
              return (
                <PickersDay
                  {...pickersDayProps}
                  onClick={() => handleDayClick(day)}
                  className={isWorkingDayFlag ? '' : 'unactive'}
                />
              );
            }}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
};

export default NewDatePicker;
