import React, { forwardRef, useCallback, useEffect } from 'react';
import TextInput from '../../../uikit/TextInput';
import { debounceValueChange } from './../utils';

type payerCellType = {
  value: string;
  isActivePaymentActions: boolean;
  isActiveRefundActions: boolean;
  handleSetIsPayerFilled?: (condition: boolean) => void;
};
export const PaymentPayerCell = forwardRef<HTMLInputElement, payerCellType>((props, ref) => {
  const { isActivePaymentActions, isActiveRefundActions, value, handleSetIsPayerFilled } = props;

  const updatePayerValueCondition = useCallback(() => {
    ref?.current?.value ? handleSetIsPayerFilled(true) : handleSetIsPayerFilled(false);
  }, [ref, handleSetIsPayerFilled]);

  useEffect(() => {
    debounceValueChange(ref, updatePayerValueCondition);
  }, [ref, updatePayerValueCondition]);

  return (
    <>
      {!isActivePaymentActions ? (
        value
      ) : (
        <TextInput style={{ height: 24, fontSize: 12 }} ref={ref} defaultValue={'Пациент'} />
      )}
      {isActiveRefundActions && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 24, fontWeight: 500 }}>Возврат:</div>
      )}
    </>
  );
});
PaymentPayerCell.displayName = 'PaymentPayerCell';
