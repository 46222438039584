import React, { useState, useEffect } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { STAFF_COLORS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { Wrapper, Collapsed, Expanded, Box } from './components';
import { ColorRow } from './styled-components';
import { sortedStaffersType } from './types';
import { sortStaffersByColors, handleColorChange, handleChange } from './functions';
import { useGetStaffByClinicQuery } from '../../../../../../services/staffSchedule';

/**
 * Компонент цветового обозначения
 * @returns реакт-элемент
 */

const StaffColor = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
  const dispatch = useAppDispatch();
  const currentClinicId = useAppSelector((state) => state.common.user.clinic.id);
  const currentStaffer = useAppSelector((state) => state.staffSchedule.currentStaffer);
  const [sortedStaffers, setSortedStaffers] = useState<sortedStaffersType>(STAFF_COLORS);
  const { data: staffers = [] } = useGetStaffByClinicQuery(currentClinicId);

  useEffect(() => {
    const staffersSortedByColor = sortStaffersByColors(staffers, currentStaffer);
    setSortedStaffers(
      !currentStaffer.color || currentStaffer.color === '#fff'
        ? staffersSortedByColor
        : { [currentStaffer.color]: staffersSortedByColor[currentStaffer.color], ...staffersSortedByColor },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStaffer.color]);

  return (
    <div style={{ height: '68px' }} ref={ref}>
      <Wrapper
        onChange={(_, expanded) => handleChange(_, expanded, sortedStaffers, currentStaffer.color, setSortedStaffers)}
      >
        <Collapsed expandIcon={<ExpandMore />}>
          <ColorRow
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {Object.entries(sortedStaffers)
              .slice(0, 7)
              .map((e) => (
                <Box
                  key={e[0]}
                  color={e[0]}
                  setSelectedColor={(newColor) => handleColorChange(newColor, currentStaffer, dispatch)}
                  isSelected={currentStaffer.color === e[0]}
                  staffers={e[1]}
                />
              ))}
          </ColorRow>
        </Collapsed>
        <Expanded>
          <ColorRow>
            {Object.entries(sortedStaffers)
              .slice(7)
              .map((e) => (
                <Box
                  key={e[0]}
                  color={e[0]}
                  setSelectedColor={(newColor) => handleColorChange(newColor, currentStaffer, dispatch)}
                  isSelected={currentStaffer.color === e[0]}
                  staffers={e[1]}
                />
              ))}
          </ColorRow>
        </Expanded>
      </Wrapper>
    </div>
  );
});

StaffColor.displayName = 'StaffColor';

export default StaffColor;
