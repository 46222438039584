import styled from 'styled-components';
import { ColorBlockPropsType } from './types';

export const ColorBox = styled.div<ColorBlockPropsType>`
  width: 28px;
  height: 28px;
  background: ${({ color }) => color};
  border-radius: 2px;
  cursor: pointer;
  border: ${({ isSelected, isOccupied }) => (isSelected || isOccupied ? `2px solid rgba(0, 0, 0, 0.1)` : '')};
  margin-top: 4px;
`;
export const ColorRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 220px;
`;
