import {
  FETCH_SCHEDULE_SUCCESS,
  SET_SCHEDULE_FILTER,
  SET_CALENDAR_LENGTH,
  SET_PAGINATOR,
} from "./types";
import { FLUSH_STATE } from "../flushState/types";
const INITIAL_STATE = {
  responce: [],
  filters: {},
  paginator: { page: 0, length: 0, state: "start", start: 0 },
  day: new Date().toDateString(),
  length: 9,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SCHEDULE_SUCCESS:
      return {
        ...state,
        ...{ responce: action.payload },
      };
    case SET_SCHEDULE_FILTER:
      return {
        ...state,
        ...{ filters: { ...state.filters, ...action.payload } },
      };
    case SET_CALENDAR_LENGTH:
      return {
        ...state,
        ...{ length: action.payload },
      };
    case SET_PAGINATOR:
      return {
        ...state,
        ...{ paginator: action.payload },
      };
    case FLUSH_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
