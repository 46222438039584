import axios from 'axios';
import hostConfig from '../host.config.json';

const getHostObject = (hostname) =>
  Object.prototype.hasOwnProperty.call(hostConfig, hostname) ? hostConfig[hostname] : hostConfig['default'];

const hostObject = getHostObject(window.location.hostname);
export const chatURL = hostObject.chat;
export const baseURL = hostObject.base;
export const wsUrl = hostObject.ws;

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

const users = {
  login: (username, password) =>
    axios({
      url: '/users/login',
      baseURL: baseURL,
      method: HttpMethod.POST,
      data: {
        login: username,
        password: password,
      },
    }),
  create_task: (data) =>
    axios({
      url: '/users/tasks/',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: data,
    }),
  load_tasks: (user, date) =>
    axios({
      url: '/users/tasks/',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      params: { date: date, id: user.id },
    }),
  load_documents: (user) =>
    axios({
      url: `/users/${user.id}/documents/`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  load_week_tasks: (user, date) =>
    axios({
      url: '/users/tasks/week/',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      params: { date: date, id: user.id },
    }),
  update_task: (task, data) =>
    axios({
      url: `/users/tasks/${task.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.PATCH,
      data: data,
    }),
  update_document: (document) =>
    axios({
      url: `/users/documents/${document.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.PATCH,
      data: { ...document, ...{ user: document.user.id } },
    }),
  add_document: (user, document) =>
    axios({
      url: `/users/${user.id}/documents/`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: { ...document, ...{ user: user.id } },
    }),
  get_permissions: (user) =>
    axios({
      url: `/users/${user.id}/permissions`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  set_permissions: (user, data) =>
    axios({
      url: `/users/${user.id}/permissions`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: data,
    }),
  update_credentials: (user, data) =>
    axios({
      url: `/users/${user.id}/credentials/`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: data,
    }),
  get_profile: (userId) =>
    axios({
      url: `/users/${userId}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  update_profile: (user, data) =>
    axios({
      url: `/users/${user.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.PATCH,
      data: data,
    }),
  create_schedule: (data) =>
    axios({
      url: '/users/workdays/',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: data,
    }),
  update_user_details: (data, user) =>
    axios({
      url: `/users/${user.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.PATCH,
      data: data,
    }),
  load_dialogs: (user, chat_jwt) =>
    axios({
      url: '/users/dialogs/',
      baseURL: chatURL,
      method: HttpMethod.GET,
      params: { id: user.id },
      headers: { chatjwt: chat_jwt },
    }),
  get_dialog: (id, user, chat_jwt) =>
    axios({
      url: '/users/dialog/',
      baseURL: chatURL,
      method: HttpMethod.GET,
      params: { key: id, id: user.id },
      headers: { chatjwt: chat_jwt },
    }),
  new_message: (data, chat_jwt) =>
    axios({
      url: '/users/messages/',
      baseURL: chatURL,
      method: HttpMethod.POST,
      data: data,
      headers: { chatjwt: chat_jwt },
    }),
  load_messages: (me, him, chat_jwt, user) =>
    axios({
      url: '/users/messages/',
      baseURL: chatURL,
      method: HttpMethod.GET,
      params: { sender: me, reciever: him, id: user.id },
      headers: { chatjwt: chat_jwt },
    }),
  set_message_as_read: (me, him, index, message, chat_jwt, user) =>
    axios({
      url: '/users/messages/update/',
      baseURL: chatURL,
      method: HttpMethod.POST,
      data: { sender: me, reciever: him, id: user.id, index, message },
      headers: { chatjwt: chat_jwt },
    }),
  text_templates: (doctorId, journalFieldName, toothStateMnemo) =>
    axios({
      url: '/users/templates',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      params: {
        doctor: doctorId,
        journal_field: journalFieldName.toUpperCase(),
        tooth_state: toothStateMnemo,
      },
    }),
  add_text_template: (doctorId, journalFieldName, toothStateMnemo, text, dirty_text, is_new, completed, order) =>
    axios({
      url: '/users/templates',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: {
        doctor: doctorId,
        journal_field: journalFieldName.toUpperCase(),
        tooth_state: toothStateMnemo,
        text,
        dirty_text,
        is_new: is_new,
        completed,
        order,
      },
    }),
  update_text_template: (
    doctorId,
    journalFieldName,
    toothStateMnemo,
    templateId,
    text,
    dirty_text,
    is_new,
    completed,
  ) =>
    axios({
      url: `/users/templates/${templateId}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.PATCH,
      data: {
        doctor: doctorId,
        journal_field: journalFieldName.toUpperCase(),
        tooth_state: toothStateMnemo,
        text,
        dirty_text,
        is_new,
        completed,
      },
    }),
  delete_text_template: (doctorId, journalFieldName, toothStateMnemo, templateId) =>
    axios({
      url: `/users/templates/${templateId}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.DELETE,
      data: {
        doctor: doctorId,
        journal_field: journalFieldName.toUpperCase(),
        tooth_state: toothStateMnemo,
      },
    }),
  load_dictionary_mkb: (toothStateMnemo, searchQuery) =>
    axios({
      url: '/dictionary/mkb',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      params: {
        tooth_state: toothStateMnemo,
        ...searchQuery,
      },
    }),
  upload_dictionary_mkb: (file) => {
    const form = new FormData();
    form.append('file', file);
    return axios({
      url: '/dictionary/mkb/upload',
      baseURL: baseURL,
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Token ${sessionStorage.getItem('access_token')}`,
      },
      method: HttpMethod.PUT,
      data: form,
    });
  },
  upload_dictionary_mkb_confirm: () =>
    axios({
      url: '/dictionary/mkb/upload/confirm',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  upload_dictionary_mkb_cancel: () =>
    axios({
      url: '/dictionary/mkb/upload/cancel',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  mkb_templates: (doctorId, toothStateMnemo, searchQuery) =>
    axios({
      url: '/users/templates/mkb',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      params: {
        doctor: doctorId,
        tooth_state: toothStateMnemo,
        ...searchQuery,
      },
    }),
  add_mkb_templates: (doctorId, toothStateMnemo, mkbTemplateId) =>
    axios({
      url: '/users/templates/mkb',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: {
        doctor: doctorId,
        tooth_state: toothStateMnemo,
        mkb_diagnosis: mkbTemplateId,
      },
    }),
  delete_mkb_templates: (mkbTemplateId) =>
    axios({
      url: `/users/templates/mkb/${mkbTemplateId}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.DELETE,
    }),
  healing_plan: (doctorId, toothStateMnemo, searchQuery) =>
    axios({
      url: '/users/templates/price',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      params: {
        doctor: doctorId,
        tooth_state: toothStateMnemo,
        ...searchQuery,
      },
    }),
  add_healing_plan: (doctorId, toothStateMnemo, templateId) =>
    axios({
      url: '/users/templates/price',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: {
        doctor: doctorId,
        tooth_state: toothStateMnemo,
        price: templateId,
      },
    }),
  delete_healing_plan: (templateId) =>
    axios({
      url: `/users/templates/price/${templateId}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.DELETE,
    }),
};

const clinic = {
  load_working_schedule: () =>
    axios({
      url: '/dictionary/workdays',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  create_new_clinic_workday: (clinic, data) =>
    axios({
      url: `/clinics/${clinic.id}/workdays/`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: data,
    }),
  update_schedule: (data) =>
    axios({
      url: `/clinics/schedule/${data.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      data: data,
      method: HttpMethod.PATCH,
    }),
  get_cabinets: () =>
    axios({
      url: '/dictionary/cabinet',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  get_staff: async (clinic) =>
    axios({
      url: `${baseURL}/clinics/${clinic.id || clinic}/staff`,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  add_cabinet: (clinic, data) =>
    axios({
      url: '/dictionary/cabinet',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: data,
    }),
  add_price: (clinic, data) =>
    axios({
      url: `/clinics/${clinic.id}/price/`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: data,
    }),
  update_price_category: (data, id) =>
    axios({
      url: `/clinics/priceCategory/${id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.PATCH,
      data: data,
    }),
  add_price_category: (clinic, data) =>
    axios({
      url: `/clinics/${clinic.id}/priceCategory/`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: data,
    }),
  add_patient: (clinic, data) => {
    return axios({
      url: `/clinics/${clinic.id}/patients`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: data,
    });
  },
  update_patient: (data) =>
    axios({
      url: `/clinics/patients/${data.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.PATCH,
      data: data,
    }),
  create_workdays: () => {
    /**empty*/
  },
  upload_image: (clinic, data) =>
    axios({
      url: `/clinics/${clinic.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.PATCH,
      data: data,
    }),
  get_workdays: () => {
    /**empty*/
  },
  get_profile_info: (clinic) =>
    axios({
      url: `/clinics/${clinic.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  update_price_item: (data, priceItem) =>
    axios({
      url: `/clinics/price/${priceItem.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      data: data,
      method: HttpMethod.PATCH,
    }),
  update_profile_info: (clinic, data) =>
    axios({
      url: `/clinics/${clinic.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      data: data,
      method: HttpMethod.PATCH,
    }),
  update_workdays: (data) =>
    axios({
      url: `/dictionary/workdays/${data.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      data: data,
      method: HttpMethod.PUT,
    }),
  delete_cabinet: (id) =>
    axios({
      url: `/dictionary/cabinet/${id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.DELETE,
    }),
  delete_price: (id) =>
    axios({
      url: `/clinics/price/${id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.DELETE,
    }),
  delete_price_category: (id) =>
    axios({
      url: `/clinics/priceCategory/${id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.DELETE,
    }),
  get_appointments: (clinicId) =>
    axios({
      url: `/clinics/${clinicId}/appointments/`,
      baseURL: baseURL,
      method: HttpMethod.GET,
    }),
  create_appointment: ({ data, clinicId }) =>
    axios({
      url: `/clinics/${clinicId}/appointments`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      data: data,
      method: HttpMethod.POST,
    }),
  update_appointment: ({ clinicId, appointmentId, data }) =>
    axios({
      url: `/clinics/${clinicId}/appointments/${appointmentId}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      data: data,
      method: HttpMethod.PATCH,
    }),
  delete_appointment: ({ clinicId, appointmentId }) =>
    axios({
      url: `/clinics/${clinicId}/appointments/${appointmentId}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.DELETE,
    }),
  get_organisations: (clinic) =>
    axios({
      url: `/clinics/${clinic.id}/organisations`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  add_organisation: (clinic, data) =>
    axios({
      url: `/clinics/${clinic.id}/organisations/`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      data: data,
      method: HttpMethod.POST,
    }),
  update_organisation: (organisation, data) =>
    axios({
      url: `/clinics/organisations/${organisation.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      data: data,
      method: HttpMethod.PATCH,
    }),
  delete_organisation: (organisation) =>
    axios({
      url: `/clinics/organisations/${organisation.id}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.DELETE,
    }),
  search_patient: (data) =>
    axios({
      url: '/clinics/search/patients',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      params: data,
      method: HttpMethod.GET,
    }),
  search_staffer: (data) =>
    axios({
      url: '/clinics/search/staffers',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      params: data,
      method: HttpMethod.GET,
    }),
  search_price_item: (data) =>
    axios({
      url: '/clinics/search/price',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      params: data,
      method: HttpMethod.GET,
    }),
  update_price: (data) =>
    axios({
      url: '/clinics/price/update/',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      data: data,
      method: HttpMethod.POST,
    }),
  add_staff: (data) =>
    axios({
      url: `/users/`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      data: data,
      method: HttpMethod.POST,
    }),
  load_patients: (clinic) =>
    axios({
      url: `/clinics/${clinic.id}/patients`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  load_price: (clinic) =>
    axios({
      url: `/clinics/${clinic.id}/price/`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  load_price_categories: (clinic) =>
    axios({
      url: `/clinics/${clinic.id}/priceCategory/`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  get_schedule: (clinic) =>
    axios({
      url: `/clinics/${clinic.id}/schedule`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  create_new_schedule: (clinic, filters) =>
    axios({
      url: `/clinics/${clinic.id}/schedule/`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: filters,
    }),
  get_new_schedule: (clinic, filters) =>
    axios({
      url: `/clinics/${clinic.id}/appointments?doctor=${filters.doctor.join(',')}&cabinet=${filters.cabinet.join(',')}
      ${filters.status ? `&status=${filters.status}` : ''}`,
      baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  get_medical_card: (patientId) =>
    axios({
      url: `patient/${patientId}/medcard`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  get_patient: (patientId) =>
    axios({
      url: `clinics/patients/${patientId}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),
  get_patient_info: (patientId) =>
    axios({
      url: `patient/${patientId}/info`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
    }),

  put_patient: (patientId, data) =>
    axios({
      url: `clinics/patients/${patientId}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.PUT,
      data,
    }),
  patch_patient: (patientId, data) =>
    axios({
      url: `clinics/patients/${patientId}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.PATCH,
      data,
    }),
  print_medcard: (patientId, entryId) =>
    axios({
      url: `patient/${patientId}/medcard/entries/${entryId}/print`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      responseType: 'blob',
    }),
  print_treatment_plan: (patientId, entryId, planId) =>
    axios({
      url: `patient/${patientId}/medcard/entries/${entryId}/fields/plan/${planId}/print`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      responseType: 'blob',
    }),
  print_invoice: (patientId, calculatedRows, advanceId) =>
    axios({
      url: `patient/${patientId}/sub_invoices_rows/print${advanceId ? `?advance_id=${advanceId}` : ''}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: Object.entries(calculatedRows).map(([key, rows]) => ({
        sub_invoice_row_id: +key,
        invoice_row_id: rows.map((r) => r.invoice_row),
      })),
      responseType: 'blob',
    }),
  print_invoice_refunds: (patientId, calculatedRows, advanceId) =>
    axios({
      url: `patient/${patientId}/sub_invoices_rows_refund/print${advanceId ? `?advance_id=${advanceId}` : ''}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      data: Object.entries(calculatedRows).map(([key, rows]) => ({
        sub_invoice_row_id: +key,
        invoice_row_id: rows.map((r) => r.invoice_row),
      })),
      responseType: 'blob',
    }),
  print_price: (clinic, categoryId) =>
    axios({
      url: `clinics/${clinic.id}/price/print${categoryId ? `?category_id=${categoryId}` : ''}`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      responseType: 'blob',
    }),
  get_revenue_report: (data) =>
    axios({
      url: '/reports/total_revenue',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      responseType: 'blob',
      data,
    }),
  get_debts_and_advances_report: (data) =>
    axios({
      url: '/reports/debts_and_advances',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      responseType: 'blob',
      data,
    }),
  print_advances: (patientId) =>
    axios({
      url: `/patient/${patientId}/advances/print`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      responseType: 'blob',
    }),
  print_advance_return: (patientId) =>
    axios({
      url: `/patient/${patientId}/advance_return/print`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      responseType: 'blob',
    }),
  download_history_patient: (data) =>
    axios({
      url: '/history/patients',
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.POST,
      responseType: 'blob',
      data,
    }),
  print_operations: (invoiceId) =>
    axios({
      url: `/billing/invoices/${invoiceId}/operations/print`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      responseType: 'blob',
    }),
  print_operations_for_row: (invoiceRowId) =>
    axios({
      url: `/billing/invoice_rows/${invoiceRowId}/operations/print`,
      baseURL: baseURL,
      headers: { authorization: `Token ${sessionStorage.getItem('access_token')}` },
      method: HttpMethod.GET,
      responseType: 'blob',
    }),
};

export default {
  users,
  clinic,
};
