import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { BlueButton, Container } from './CreateButton';
import PatientState from '../state';
import { createNewPatient, initPatientPage } from '../operations';
import { RootState } from '../../../store';

type BlueButton = {
  width: number;
};

const OutlinedButton = styled.div<BlueButton>`
  margin-top: 24px;
  width: ${({ width }) => width}px;
  border: 1px solid #577bf9;
  border-radius: 4px;
  color: #577bf9;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
`;

function SaveButtons({ patientsPage, setCreatingPatient, initPatientPage, dropErrors, dropSearch, createNewPatient }) {
  const { formState, trigger } = useFormContext();
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);

  const handleSubmit = async () => {
    setSaveButtonClicked(true);
    await trigger();
  };

  useEffect(() => {
    if (formState.isValid && saveButtonClicked) {
      createNewPatient({ ...patientsPage.data });
      setSaveButtonClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isValid, saveButtonClicked]);

  return (
    patientsPage.isCreating && (
      <>
        <div style={{ marginRight: 14 }}>
          <BlueButton width={102} onClick={handleSubmit}>
            <Container>Сохранить</Container>
          </BlueButton>
        </div>
        <div style={{ marginRight: 34 }}>
          <OutlinedButton
            width={102}
            onClick={() => {
              setCreatingPatient(false);
              dropSearch();
              dropErrors();
              initPatientPage();
            }}
          >
            <Container>Отменить</Container>
          </OutlinedButton>
        </div>
      </>
    )
  );
}

export default connect((state: RootState) => ({ patientsPage: state.patientsPage }), {
  setCreatingPatient: PatientState.actions.setCreatingPatient,
  dropErrors: PatientState.actions.dropErrors,
  dropSearch: PatientState.actions.dropSearch,
  createNewPatient,
  initPatientPage,
})(SaveButtons);
