import React, { useEffect, useRef, useState } from 'react';
import Header from '../../components/Header/PageHeader';
import Drawer from '../../components/Drawer';
import { Layout, Container, TableLayout, TableTitle, CenterPage, FixedHeader } from './styles';
import { getPrice, getPriceCategories } from './operations';
import PriceState from './state';
import { connect } from 'react-redux';
import Categories from './Categories';
import PriceTable from './PriceTable';
import ChangeValueDialog from './ChangeValueDialog';
import EditSwitcher from './EditSwitcher';
import HeaderRow from './PriceTable/HeaderRow';
import EmptyMessage from './EmptyMessage';
import { useGetPermissionsQuery } from '../../services/users';
import { routerConfig } from '../../navigation/routerConfig';
import request, { wsUrl } from '../../utils/request';
import { printPdf } from '../../utils/print';
import { RootState } from '../../store';
import { startEditPriceAction, stopEditPriceAction } from '../../websocket/actionCreators';
import EventsWebSocket from '../../websocket';

function PricePage({
  getPriceCategories,
  getPrice,
  setEdit,
  common,
  isPrintActive,
  createFirstCategory,
  categories,
  setFirstCategory,
}) {
  const { data: permissions } = useGetPermissionsQuery(common.user.id);

  const socket = useRef();
  const [sessions, setSessions] = useState([]);

  const handleWsMessage = (msg) => {
    if (msg.data) {
      setSessions(msg.data);
    }
  };

  const handleStartEdit = (priceId) => {
    socket.current?.send(startEditPriceAction(priceId));
  };

  const handleStopEdit = (priceId) => {
    socket.current?.send(stopEditPriceAction(priceId));
  };

  useEffect(() => {
    socket.current = new EventsWebSocket(wsUrl.prices, handleWsMessage);
    return () => socket.current.disconnect();
  }, []);

  const handlePrint = async () => {
    const result = await request.clinic.print_price(common.user.clinic);
    printPdf(result.data);
  };

  useEffect(() => {
    (async () => {
      await getPriceCategories();
      await getPrice();
      setEdit(permissions['edit_price'] || common.user.is_superuser);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions]);

  if (createFirstCategory && categories.length > 1) {
    setFirstCategory(false);
  }
  return (
    <Layout>
      <Header text={routerConfig.PRICE.title} print={true} isPrintActive={isPrintActive} onPrint={handlePrint} />
      <Drawer />
      <CenterPage>
        <Container>
          <EmptyMessage />
          <EditSwitcher />
          <TableLayout style={!createFirstCategory && categories.length > 1 ? { zIndex: 3 } : { zIndex: 1 }}>
            <TableTitle>
              <Categories />
            </TableTitle>
            <FixedHeader>
              <div>
                <table>
                  <HeaderRow />
                </table>
              </div>
            </FixedHeader>
            <PriceTable sessions={sessions} onStartEdit={handleStartEdit} onStopEdit={handleStopEdit} />
          </TableLayout>
        </Container>
      </CenterPage>
      <ChangeValueDialog onStopEdit={handleStopEdit} />
    </Layout>
  );
}

export default connect(
  (state: RootState) => ({
    common: state.common,
    isPrintActive: state.priceSlice.response.length !== 0,
    createFirstCategory: state.priceSlice.createFirstCategory,
    categories: state.priceSlice.categories,
  }),
  {
    getPrice,
    getPriceCategories,
    setEdit: PriceState.actions.setEdit,
    setFirstCategory: PriceState.actions.setCreateFirstCategory,
  },
)(PricePage);
