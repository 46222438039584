import React from 'react';
import Modal from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close';
import styled from 'styled-components';

import OutlinedButton from '../Button/OutlinedButton';
import BlueButton from '../Button/BlueButton';

const Window = styled.div`
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  border-radius: 8px 8px 0px 0px;
  margin-top: 3%;
  height: 95%;
`;

const Content = styled.div`
  flex: 1;
  height: 100%;
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  background: #ffffff;
  padding: 16px 24px;
  z-index: 1;
`;

interface LightBoxProps {
  isOpen: boolean;
  confirmButtonText?: string;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave: (args: any) => void;
}

export const LightBox: React.FC<LightBoxProps> = ({
  isOpen,
  confirmButtonText = 'Сохранить',
  onClose,
  onSave,
  children,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Window>
        <Close
          style={{ color: 'white', position: 'absolute', top: '1.5%', right: 20, cursor: 'pointer' }}
          onClick={onClose}
        />
        <Content>{children}</Content>
        <Footer>
          <OutlinedButton style={{ marginTop: 0, padding: '0px 16px' }} onClick={onClose}>
            Отменить
          </OutlinedButton>
          <BlueButton style={{ marginTop: 0, marginLeft: 16, padding: '0px 16px' }} onClick={onSave}>
            {confirmButtonText}
          </BlueButton>
        </Footer>
      </Window>
    </Modal>
  );
};
