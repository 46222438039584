import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL, HttpMethod } from '../utils/request';
import { User, Staffer } from './users';

export interface ScheduleItem {
  id: number;
  worker: User;
  starts_at: string;
  ends_at: string;
  created_at: string;
  cabinet: number;
  clinic: number;
}

export const staffScheduleApi = createApi({
  reducerPath: 'staffScheduleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['Schedule', 'Users'],
  endpoints: (builder) => ({
    getScheduleByClinicId: builder.query<ScheduleItem[], number>({
      query: (id) => ({ url: `clinics/${id}/schedule` }),
      providesTags: ['Schedule'],
    }),
    setSchedule: builder.mutation({
      query: ({ id, data }: { id: number; data: ScheduleItem[] }) => {
        return {
          url: `users/${id}/schedule`,
          method: HttpMethod.POST,
          body: data,
        };
      },
      invalidatesTags: ['Schedule'],
    }),
    deleteScheduleItem: builder.mutation({
      query: ({ clinicId, scheduleItemId }: { clinicId: number; scheduleItemId: number }) => {
        return {
          url: `clinics/${clinicId}/schedule/${scheduleItemId}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: ['Schedule'],
    }),
    updateUser: builder.mutation<User, Partial<User>>({
      query: (data) => {
        const { id, ...body } = data;
        return {
          url: `users/${id}`,
          method: HttpMethod.PATCH,
          body,
        };
      },
      invalidatesTags: ['Users'],
    }),
    getStaffByClinic: builder.query<Staffer[], number>({
      query: (clinicId) => {
        return {
          url: `clinics/${clinicId}/staff`,
          method: HttpMethod.GET,
        };
      },
      transformResponse: (response: { items: Staffer[] }) => {
        return response.items;
      },
      providesTags: ['Users'],
    }),
    setScheduleBulk: builder.mutation({
      query: ({ id, data }: { id: number; data: ScheduleItem[] }) => {
        return {
          url: `clinics/${id}/schedule_bulk`,
          method: HttpMethod.POST,
          body: data,
        };
      },
      invalidatesTags: ['Schedule'],
    }),
  }),
});

export const {
  endpoints,
  useGetScheduleByClinicIdQuery,
  useSetScheduleMutation,
  useDeleteScheduleItemMutation,
  useUpdateUserMutation,
  useGetStaffByClinicQuery,
  useSetScheduleBulkMutation,
} = staffScheduleApi;
