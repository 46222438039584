import React, { useState, useEffect } from "react";
import { uniq } from "ramda";

import { HeaderColumn } from "../style";
import TextInput from "../../../uikit/TextInput";
import { connect } from "react-redux";
import PatientsPageState from "../state";
import { getMask } from "../../../utils/masks";
import { getFieldValidator } from "../validate";
import { OnTopTooltip } from "../../../components/DoctorFilter/OnTopTooltip";
import WithSuggestions from "../../../components/Suggestions/WithSuggestions";
import SuggestionsState from "../../../components/Suggestions/state";

function StreamHeader({
  width,
  setData,
  type,
  name,
  placeholder,
  patientsPage,
  dropInputFields,
  openMenu,
  suggestionsMenu,
}: any) {
  useEffect(() => () => dropInputFields(), [true]);
  const [hasErrors, setErrors] = useState(false);
  const input_mask = getMask(type);
  const fieldValidator = getFieldValidator(name);
  const suggestions = patientsPage.response;
  return (
    <HeaderColumn
      width={width}
      onClick={() => {
        openMenu(name);
      }}
      style={{ paddingTop: 11 }}
    >
      <WithSuggestions
        value={patientsPage.data[name] ? patientsPage.data[name] : ""}
        name={name}
        suggestions={uniq(
          suggestions
            .map((staffer: any) => staffer.stream)
            .filter((stream: string) => stream.length > 2)
        )}
        onClickAway={() => {
          setErrors(fieldValidator(patientsPage.data[name]));
        }}
        handlePick={async (value: string) => {
          setData({ [name]: value });
          setErrors(false);
        }}
      >
        <OnTopTooltip title={"Обязательное поле"} arrow isShown={hasErrors}>
          <div style={{ height: 32, marginLeft: 0, width: width - 16 }}>
            <TextInput
              placeholder={placeholder}
              error={hasErrors}
              onFocus={() => {
                openMenu(name);
              }}
              value={patientsPage.data[name]}
              onChange={(e: any) => {
                let value = input_mask.resolve(e.target.value);
                setData({ [name]: value });
              }}
            />
          </div>
        </OnTopTooltip>
      </WithSuggestions>
    </HeaderColumn>
  );
}

export default connect(
  (state: any) => ({
    patientsPage: state.patientsPage,
    suggestionsMenu: state.suggestionsMenu,
  }),
  {
    setData: PatientsPageState.actions.setData,
    dropInputFields: PatientsPageState.actions.dropInputFields,
    closeMenu: SuggestionsState.actions.closeMenu,
    openMenu: SuggestionsState.actions.openMenu,
  }
)(StreamHeader);
