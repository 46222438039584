import React, { ReactElement } from 'react';
import Popper, { PopperProps } from '@mui/material/Popper';
import styled from 'styled-components';

type ModalWrapperProps = {
  anchor: HTMLElement | null;
  container?: HTMLElement | null;
  placement?: PopperProps['placement'];
  zIndex?: number;
  children: ReactElement;
};

const PopperContainer = styled(Popper)`
  &[data-popper-reference-hidden] {
    visibility: hidden;
  }
`;

export const ModalWrapper = (props: ModalWrapperProps) => {
  const { anchor, container, zIndex = 1300, placement = 'bottom-end', children } = props;

  return (
    <PopperContainer
      open={Boolean(anchor)}
      anchorEl={anchor}
      placement={placement}
      container={container}
      style={{ zIndex: zIndex, padding: '4px 0' }}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: false,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      {children}
    </PopperContainer>
  );
};

ModalWrapper.displayName = 'ModalWrapper';
