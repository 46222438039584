import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import PatientsState from '../state';

type BlueButton = {
  width: number;
  disabled?: boolean;
};
export const BlueButton = styled.div<BlueButton>`
  margin-top: 24px;
  margin-left: 0px;
  width: ${({ width }) => width}px;
  border-radius: 4px;
  background: ${({ disabled }) => (disabled ? '#EFF0F3' : '#577bf9')};
  color: ${({ disabled }) => (disabled ? '#A8AEB5' : '#ffffff')};
  border: ${({ disabled }) => (disabled ? '1px solid #D3D6DA' : '1px solid white')};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;
export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 32px;
  align-items: center;
  padding-left: 16px;
`;

function CreateButton({ patientsPage, setCreatingPatient, setData, setNewPatient }) {
  return (
    <BlueButton
      disabled={patientsPage.isCreating}
      width={196}
      onClick={() => {
        const newId = (Math.max(...patientsPage.response.map((patient) => patient.id)) ?? 0) + 1;
        const finalNewId = isFinite(newId) ? newId : 1;

        setData({
          id: finalNewId,
        });

        setNewPatient(finalNewId);
        !patientsPage.isCreating && setCreatingPatient(true);
      }}
    >
      <Container>
        Добавить пациента
        <img
          style={{ marginLeft: 7 }}
          src={patientsPage.isCreating ? './img/grey/plus.svg' : './img/white/plus.svg'}
          alt={'add icon'}
        />
      </Container>
    </BlueButton>
  );
}

export default connect((state) => ({ patientsPage: state.patientsPage }), {
  setCreatingPatient: PatientsState.actions.setCreatingPatient,
  setData: PatientsState.actions.setData,
  setNewPatient: PatientsState.actions.setNewPatient,
})(CreateButton);
