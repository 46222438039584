import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_PAYMENT_TYPE, STARTED_AMOUNT } from './constants';
import { operationItemType } from './types';

type InitialStateType = {
  advanceAmount: number;
  totalPayType: number | null;
  operationItems: operationItemType[];
};

const INITIAL_STATE: InitialStateType = {
  advanceAmount: STARTED_AMOUNT,
  totalPayType: DEFAULT_PAYMENT_TYPE,
  operationItems: [],
};
const PaymentAccountSlice = createSlice({
  name: 'PaymentAccountSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setAdvanceAmount: (state, action) => {
      state.advanceAmount = action.payload;
    },
    setOperationItems: (state, action) => {
      state.operationItems = [...state.operationItems, ...action.payload];
    },
    updateOperationItems: (state, action) => {
      state.operationItems = action.payload;
    },
    patchOperations: (state, action) => {
      state.operationItems = state.operationItems.map((item) => {
        if (item.invoice_row !== action.payload.invoice_row) return item;
        const updatedOperations = item.operations.map((operation) => {
          return operation.id === action.payload.id ? { ...operation, ...action.payload } : operation;
        });
        return { ...item, operations: updatedOperations };
      });
    },
    setTotalPayType: (state, action) => {
      state.totalPayType = action.payload;
    },
    cleanupState: () => {
      return { ...INITIAL_STATE };
    },
  },
});
export default PaymentAccountSlice;
