import React, { FC } from 'react';

const NotConfirmedIcon: FC = () => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="none">
      <defs>
        <clipPath id="clip0_4519_211956">
          <rect
            id="svg_1"
            transform="rotate(-135 11.9937 23.3074)"
            x="11.9937"
            y="23.3074"
            fill="white"
            height="16"
            width="16"
          />
        </clipPath>
      </defs>
      <g>
        <path
          id="svg_3"
          fill="#C3000A"
          d="m3.05025,13.16251c2.73341,2.7334 7.1661,2.7334 9.8995,0c2.7334,-2.7334 2.7334,-7.16607 0,-9.89948c-2.7334,-2.73341 -7.16609,-2.73341 -9.8995,0c-2.7334,2.73341 -2.7334,7.16608 0,9.89948zm9.0598,-9.05979c2.2694,2.26939 2.2694,5.95079 0,8.22009c-2.2694,2.2694 -5.9507,2.2694 -8.22011,0c-2.26937,-2.2693 -2.26937,-5.9507 0,-8.22009c2.26941,-2.26937 5.95071,-2.26937 8.22011,0z"
        />
        <path
          id="svg_4"
          fill="#C3000A"
          d="m4.25173,7.86781l0.00056,1.1762c-0.00019,0.0831 0.01607,0.1655 0.04783,0.2424c0.03176,0.0769 0.07839,0.1468 0.13723,0.2056c0.11988,0.1198 0.27953,0.1856 0.44857,0.1856l1.26617,0c0.0832,0.0002 0.1656,-0.0161 0.2425,-0.0478c0.0769,-0.0318 0.1467,-0.0784 0.2055,-0.1373c0.1199,-0.1199 0.1856,-0.2795 0.1856,-0.4485l0,-0.9259c0.3472,-0.1305 0.7152,-0.1971 1.0861,-0.1967c0.3714,-0.0009 0.7398,0.0652 1.0877,0.195l0,0.9259c-0.0002,0.0832 0.0161,0.1656 0.0479,0.2425c0.0317,0.0769 0.0784,0.1467 0.1372,0.2055c0.1199,0.1199 0.2795,0.1856 0.4486,0.1856l1.2661,0.0011c0.0833,0.0002 0.1659,-0.0161 0.2428,-0.048c0.077,-0.0318 0.147,-0.0786 0.2058,-0.1376c0.1199,-0.1199 0.1856,-0.2795 0.1856,-0.4486l0,-1.1744c0.0006,-0.2701 -0.1088,-0.5331 -0.3,-0.7242c-0.0403,-0.0404 -0.0823,-0.0757 -0.127,-0.1072c-0.8673,-0.622 -2.002,-0.96455 -3.1936,-0.96511c-1.1905,-0.00055 -2.32406,0.34191 -3.19523,0.96451c-0.26572,0.1906 -0.42482,0.5011 -0.42593,0.8314z"
        />
        <path
          id="svg_5"
          fill="white"
          d="m12.24266,3.97029c-2.3432,-2.34314 -6.1421,-2.34314 -8.48529,0c-2.34314,2.34312 -2.34314,6.14212 0,8.48532c2.34319,2.3431 6.14209,2.3431 8.48529,0c2.3431,-2.3432 2.3431,-6.1422 0,-8.48532zm-7.8627,5.07372l-0.00056,-1.1762c0.00111,-0.3303 0.16021,-0.6408 0.42593,-0.8314c0.87117,-0.6225 2.00473,-0.96505 3.19523,-0.9645c1.1916,0.00055 2.3263,0.3431 3.1936,0.9651c0.0447,0.0315 0.0867,0.0668 0.127,0.1072c0.1912,0.1911 0.3006,0.4541 0.3,0.7242l0,1.1745c0,0.169 -0.0657,0.3286 -0.1856,0.4485c-0.0588,0.059 -0.1288,0.1058 -0.2058,0.1376c-0.0769,0.0319 -0.1595,0.0482 -0.2428,0.048l-1.2661,-0.0011c-0.1691,0 -0.3287,-0.0657 -0.4486,-0.1856c-0.0588,-0.0588 -0.1055,-0.1286 -0.1372,-0.2055c-0.0318,-0.0769 -0.0481,-0.1593 -0.0479,-0.2425l0,-0.9259c-0.3479,-0.1298 -0.7163,-0.1959 -1.0877,-0.195c-0.3709,-0.0004 -0.7389,0.0662 -1.0861,0.1967l0,0.9259c0,0.169 -0.0657,0.3287 -0.1856,0.4485c-0.0588,0.0589 -0.1286,0.1055 -0.2055,0.1373c-0.0769,0.0317 -0.1593,0.048 -0.2425,0.0478l-1.26617,0c-0.16904,0 -0.32869,-0.0657 -0.44857,-0.1856c-0.05884,-0.0588 -0.10547,-0.1287 -0.13723,-0.2056c-0.03176,-0.0769 -0.04802,-0.1593 -0.04783,-0.2424z"
          clipRule="evenodd"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default NotConfirmedIcon;
