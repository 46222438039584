import React from 'react';
import { connect } from 'react-redux';
function Budge({ messages, common }) {
  let count = messages.dialogs
    .filter(
      (item) =>
        item.message[item.message.length - 1].sender.id === common.user.id ||
        item.message[item.message.length - 1].reciever.id === common.user.id,
    )
    .reduce((acc, dialog) => acc + dialog.unReadMessages, 0);

  return (
    <div
      style={{
        position: 'absolute',
        right: -8,
        top: -4,
        width: '18px',
        height: '18px',
      }}
    >
      {count ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 10,
            background: '#E54770',
            color: '#fff',
            borderRadius: 9,
            height: 18,
            width: 18,
            margin: 0,
            padding: 0,
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              textAlign: 'center',
              top: '9%',
              left: count > 99 ? '9%' : count >= 10 ? '22%' : '37%',
            }}
          >
            {count > 99 ? '99+' : count}
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
const mapSateToProps = (state) => ({
  messages: state.messages,
  common: state.common,
});
export default connect(mapSateToProps, {})(Budge);
