import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TreatmentPlanItem } from './TreatmentPlanItem';
import { TreatmentPlanTemplate } from './TreatmentPlanTemplates';
import PriceTabs from './PriceTabs';
import { SearchHeader } from '../SearchHeader';
import EventsWebSocket from '../../../../websocket';
import { wsUrl } from '../../../../utils/request';

const ContentTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #515d6b;
  line-height: 22px;
  padding: 16px;
`;
type Props = {
  style?: CSSProperties;
  title: string;
  templates: TreatmentPlanTemplate[];
  onClickMenu?: (template) => void;
  onAddTemplate: (template) => void;
  onCategoryFilter: (category) => void;
  isMenuVisible?: boolean;
  isHeaderVisible?: boolean;
};

const HeaderPrice = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  padding: 0;
  border-bottom: 2px solid #7c8590;
  margin-top: 16px;
  background: #f9f9f9;
`;

const EmptyTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #a8aeb5;
  text-align: center;
  padding: 16px;
`;

export function TreatmentPlanPriceList({
  style,
  title,
  templates,
  onClickMenu,
  onAddTemplate,
  onCategoryFilter,
  isMenuVisible = true,
  isHeaderVisible = false,
}: Props) {
  const socket = useRef();
  const [sessions, setSessions] = useState([]);

  const handleWsMessage = (msg) => {
    if (msg.data) {
      setSessions(msg.data);
    }
  };

  useEffect(() => {
    socket.current = new EventsWebSocket(wsUrl.prices, handleWsMessage);
    return () => socket.current.disconnect();
  }, []);

  return (
    <div style={style}>
      <ContentTitle>{title}</ContentTitle>
      <PriceTabs onCategoryFilter={onCategoryFilter} />
      {templates.length ? (
        <>
          {isHeaderVisible && (
            <HeaderPrice>
              <SearchHeader
                type="text"
                placeholder="Код"
                name="code"
                width={110}
                fontSize={12}
                text="Код"
                noBorder
                searchable={false}
              />
              <SearchHeader
                type="text"
                placeholder="Наименование"
                fontSize={12}
                name="text"
                width={350}
                flexGrow={1}
                text="Наименование"
                noBorder
                searchable={false}
              />
              <SearchHeader
                type="text"
                placeholder="Цена"
                fontSize={12}
                name="price"
                width={100}
                text="Цена"
                noBorder
                searchable={false}
              />
            </HeaderPrice>
          )}
          {templates.map((template) => (
            <TreatmentPlanItem
              key={template.id}
              template={template}
              editor={sessions.find((s) => s.target_id === template.id)?.session_user_name ?? null}
              isDictionary
              onClickMenu={onClickMenu}
              onAddTemplate={onAddTemplate}
              isMenuVisible={isMenuVisible}
            />
          ))}
        </>
      ) : (
        <EmptyTitle>Нет записей для выбранной категории</EmptyTitle>
      )}
    </div>
  );
}
