import React, { FC, useCallback, useEffect } from 'react';
import { PaymentItemsContainer } from './styles';
import { PaymentItems } from './PaymentItems';
import { EAccountStatus } from './AccountStatus';
import { useGetInvoiceAllRowsByStatusQuery, useGetSubInvoicesQuery } from '../../../services/invoice';
import { SubInvoiceStatus } from '../../../services/billing';
import { useAppDispatch } from '../../../store/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import PaymentAccountSlice from './state';
import { STARTED_AMOUNT } from './constants';
import { getOperationsList } from './utils';
import { InvoiceContainerType } from './types';

export const InvoiceContainer: FC<InvoiceContainerType> = ({
  invoicePaymaster,
  currentUser,
  invoiceId,
  handleSetTheadEl,
  handleScrollTop,
  onSetTotalPaidInputValue,
  onRefreshInvoiceList,
  onRefreshDebtAmount,
  containerRef,
  commentFormHeight,
}) => {
  const dispatch = useAppDispatch();
  const operationItems = useSelector((state: RootState) => state.PaymentAccountSlice.operationItems);
  const { setOperationItems } = PaymentAccountSlice.actions;
  const activeStatuses = [SubInvoiceStatus.DEBT, SubInvoiceStatus.PENDING_PAYMENT];
  const statusList = activeStatuses.join(',');

  const { data: subInvoices = [] } = useGetSubInvoicesQuery(invoiceId, {
    skip: !invoiceId,
    refetchOnMountOrArgChange: true,
  });

  const { data: invoiceAllRows = [] } = useGetInvoiceAllRowsByStatusQuery(
    {
      invoiceId: invoiceId,
      status: statusList,
    },
    { skip: !invoiceId, refetchOnMountOrArgChange: true },
  );

  const subInvoicesForPay = subInvoices.filter(
    (subInvoice) => subInvoice.status === EAccountStatus.PENDING_PAYMENT || subInvoice.status === EAccountStatus.DEBT,
  );

  const getOperationItemsList = useCallback(() => {
    const items = [];

    invoiceAllRows.forEach((row) => {
      const existedItem = operationItems.find((item) => item.invoice_row === row.id);
      if (!existedItem) {
        items.push({
          ...row,
          invoice_id: invoiceId,
          sub_invoice_id: row.subInvoiceId,
          invoice_row: row.id,
          totalRowPaid: STARTED_AMOUNT,
          totalRowDebt: row.payment - row.paid,
          operations: getOperationsList(row),
        });
      }
    });

    return items;
  }, [invoiceAllRows, operationItems, invoiceId]);

  useEffect(() => {
    const paidRowValues = getOperationItemsList();
    dispatch(setOperationItems(paidRowValues));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceAllRows.length]);

  return (
    <>
      <PaymentItemsContainer>
        {subInvoicesForPay.map((subInvoice) => (
          <PaymentItems
            key={subInvoice.id}
            handleSetTheadEl={handleSetTheadEl}
            handleScrollTop={handleScrollTop}
            currentSubInvoiceId={subInvoice.id}
            currentSubInvoiceStatus={subInvoice.status}
            onSetTotalPaidInputValue={onSetTotalPaidInputValue}
            invoicePaymaster={invoicePaymaster}
            currentUser={currentUser}
            onRefreshInvoiceList={onRefreshInvoiceList}
            onRefreshDebtAmount={onRefreshDebtAmount}
            containerRef={containerRef}
            commentFormHeight={commentFormHeight}
          />
        ))}
      </PaymentItemsContainer>
    </>
  );
};
