import React, { useState } from "react";
import {
  InputBlock,
  Label,
  TextAreaBlockContent,
  TextAreaContainer,
} from "../style";

export default function TextAreaBlock({
  width,
  label,
  placeholder,
  initialValue,
  handleBlur,
  name,
  InputComponent,
}: any) {
  const [value, setValue] = useState(initialValue);
  function calculateHeight() {
    const len = value && value.length;
    const height = (Math.floor(len / 70) + 1) * 30;
    return len < 70 ? 30 : height >= 90 ? 90 : height;
  }
  return (
    <InputBlock width={width}>
      <TextAreaBlockContent height={calculateHeight() + 26}>
        <Label>{label}</Label>
        <TextAreaContainer height={calculateHeight()}>
          <InputComponent
            onBlur={() => handleBlur({ [name]: value })}
            onChange={(e: any) => setValue(e.target.value)}
            value={value}
            placeholder={placeholder}
            multiline
          />
        </TextAreaContainer>
      </TextAreaBlockContent>
    </InputBlock>
  );
}
