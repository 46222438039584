import styled from 'styled-components';

type RowProps = {
  padding?: string;
  marginBottom?: string;
};

export const Layout = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  width: 100%;
  height: 100vh;
  padding-bottom: 15px;
  overflow: hidden;
`;

export const CenterPage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-right: 16px;
  padding-left: 20px;
  max-width: 1440px;
  @media (min-width: 1500px) {
    margin: 0 auto;
  }
`;

export const Container = styled.div`
  margin-right: 17px;
  max-width: 1350px;
  @media (min-width: 1450px) {
    margin: 0 auto;
  }
`;

export const SubTitle = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #515d6b;
  margin-left: 20px;
`;

export const RowBlock = styled.div<RowProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding }) => (padding ? padding : '0 72px 0 20px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '12px')};
`;

export const TableContainer = styled.div`
  background: #fff;
  display: block;
  max-width: 100%;
  width: 100%;
  table {
    font-size: 12px;
    th,
    td {
      padding: 12px;
      border: none;
    }
    tr {
      height: 48px;
      position: relative;
      :before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 12px;
        right: 12px;
        height: 1px;
        background: #eff0f3;
      }
      &.active {
        :before {
          height: 100%;
          box-shadow: inset 0 0 0 1px #577bf9;
          inset: 0;
          background: transparent;
          z-index: 1;
        }
      }
    }
    td:last-child {
      padding: 0 42px 0 0;
    }
    td:nth-last-child(2) {
      padding: 12px 0 12px 4px;
    }
    th {
      color: #515d6b;
    }
  }
`;

export const NoInvoiceBlock = styled.div`
  margin: 0 auto;
  font-size: 16px;
  padding: 12px 0;
  background-color: rgba(0, 0, 0, 0.03);
  width: 100%;
  text-align: center;
`;
export const CommentIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;

export const TableWrapper = styled.div`
  [data-wrapper='wrapper']:not(:last-of-type) {
    position: relative;
    :after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 12px;
      right: 12px;
      height: 1px;
      background: #eff0f3;
    }
  }
`;
