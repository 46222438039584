import styled from 'styled-components';

export const Layout = styled.div`
  background-color: #f9f9f9;
  width: 100vw;
  height: 100vh;
  padding-bottom: 15px;
`;

export const CenterPage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  padding: 20px;
`;

export const ReportContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0 70px 0 20px;
  @media (min-width: 1500px) {
    margin: 0 auto;
  }
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #253446;
  margin-bottom: 8px;
`;