import React from 'react';
import { Row, Spacer } from '../style';
import TextInput from '../../../uikit/TextInput';
import { connect } from 'react-redux';
import MedCardPageSlice from '../state';
import TextBlock from '../TextBlock';
import { patchPatientData } from '../operations';
import { format } from 'date-fns';
import { useGetPermissionsQuery } from '../../../services/users';
import { RootState } from '../../../store';
function SecondRow({ medCardPageSlice, common }: RootState) {
  const { email, birth_date, additional_phone, id } = medCardPageSlice.patientDetails;
  function parseDate(date: string): string {
    if (!date) {
      return '';
    }
    const date_arr: string[] = date.split('-');
    return format(new Date(parseInt(date_arr[0]), parseInt(date_arr[1] - 1), parseInt(date_arr[2])), 'dd.MM.yyyy');
  }
  const patchCurrentPatientData = patchPatientData(id);
  const { data: permissions } = useGetPermissionsQuery(common.user.id);

  return (
    <Row>
      <TextBlock
        name={'additional_phone'}
        width={160}
        type={'phone'}
        label={'Доп. Телефон'}
        initialValue={
          permissions['view_patient_contacts']
            ? additional_phone
            : '*'.repeat(additional_phone ? additional_phone.length : 0)
        }
        placeholder={'+7 (ххх) ххх-хх-хх'}
        handleBlur={patchCurrentPatientData}
        InputComponent={TextInput}
        disabled={!permissions['view_patient_contacts']}
      />
      <TextBlock
        type={'date'}
        name={'birth_date'}
        width={160}
        label={'Дата рождения'}
        initialValue={birth_date && parseDate(birth_date)}
        placeholder={'ДД.ММ.ГГГГ'}
        handleBlur={patchCurrentPatientData}
        InputComponent={TextInput}
      />
      <TextBlock
        type={'email'}
        name={'email'}
        width={400}
        label={'E-mail'}
        initialValue={permissions['view_patient_contacts'] ? email : '*'.repeat(email ? email.length : 0)}
        placeholder={'e-mail@gmail.com'}
        handleBlur={patchCurrentPatientData}
        InputComponent={TextInput}
        disabled={!permissions['view_patient_contacts']}
      />
      <Spacer />
    </Row>
  );
}

export default connect((state: RootState) => ({ medCardPageSlice: state.medCardPageSlice, common: state.common }), {
  setPatientDetails: MedCardPageSlice.actions.setPatientDetails,
})(SecondRow);
