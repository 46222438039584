import React, { useState } from 'react';
import {
  Layout,
  Content,
  LogoContainer,
  ClinicName,
  BottomBlock,
  UserInfo,
  BottomBlockIcon,
  IconText,
  Name,
  Job,
} from './styles';
import DrawerItem from './DrawerItem';
import { loginSave /*, setStudyMode*/ } from '../../features/legacyState';
import { ClinicLogo } from './ClinicLogo';
import DrawerState from './state';
import ExpandButton from './ExpandButton';
import { useGetPermissionsQuery } from '../../services/users';
import { useGetClinicQuery } from '../../services/clinics';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { routerConfig } from '../../navigation/routerConfig';
import { useNavigate } from 'react-router-dom';
import { ContactUsModal } from './ContactUsModal';
import { stopWsEvents } from '../../features/sync/operations';

const user_role_name = {
  DOC: 'Врач',
  ADM: 'Администратор',
};

export default function Drawer() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    common,
    drawerState: { isExpanded },
  } = useAppSelector((state) => state);
  const { toggleExpantion } = DrawerState.actions;
  const { data: clinic, isLoading: isClinicLoading } = useGetClinicQuery(common.user.clinic.id);
  const { data: permissions, isLoading: isPermissionsLoading } = useGetPermissionsQuery(common.user.id);

  const [contactUsVisible, setContactUsVisible] = useState<boolean>(false);

  function logOut() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('login');
    sessionStorage.removeItem('password');
    sessionStorage.removeItem('access_token');
    dispatch(loginSave({}, false));
    navigate(routerConfig.ROOT.path);
    stopWsEvents();
  }

  if (isClinicLoading || isPermissionsLoading) {
    return null;
  }

  return (
    <Layout expanded={isExpanded}>
      <Content onClick={() => dispatch(toggleExpantion({}))} width={isExpanded ? 250 : 250}>
        <LogoContainer expanded={isExpanded}>
          <ClinicLogo src={clinic[0].logo} />
          <ClinicName>{clinic[0].name}</ClinicName>
        </LogoContainer>
        <ExpandButton isExpanded={isExpanded} />
        {/*<DrawerItem*/}
        {/*  id="main"*/}
        {/*  expanded={isExpanded}*/}
        {/*  fileName="main.svg"*/}
        {/*  pathName={routerConfig.RECEPTION.path}*/}
        {/*  width={"12.5px"}*/}
        {/*  padding={0}*/}
        {/*  text={routerConfig.RECEPTION.title}*/}
        {/*/>*/}
        <DrawerItem
          id="schedule-icon"
          expanded={isExpanded}
          fileName="sch.svg"
          pathName={routerConfig.SCHEDULE.path}
          width={'12.5px'}
          padding={0}
          text={routerConfig.SCHEDULE.title}
        />
        <DrawerItem
          id="patients-page-icon"
          expanded={isExpanded}
          fileName="people.svg"
          pathName={routerConfig.PATIENTS.path}
          width={'12.5px'}
          padding={1}
          text={routerConfig.PATIENTS.title}
        />
        <DrawerItem
          id="staff-icon"
          expanded={isExpanded}
          fileName="staff.svg"
          pathName={routerConfig.STAFF.path}
          padding={0}
          width={'14px'}
          text={routerConfig.STAFF.title}
        />
        <DrawerItem
          id="price-icon"
          expanded={isExpanded}
          fileName="dollar.svg"
          pathName={routerConfig.PRICE.path}
          padding={0}
          width={'14px'}
          text={routerConfig.PRICE.title}
        />
        {permissions['create_report'] && (
          <DrawerItem
            id="main-icon"
            expanded={isExpanded}
            fileName="main.svg"
            pathName={routerConfig.REPORT.path}
            padding={0}
            width={'14px'}
            text={routerConfig.REPORT.title}
          />
        )}
        {(common.user.is_superuser || permissions['edit_clinic_profile']) && (
          <DrawerItem
            id="profile-icon"
            expanded={isExpanded}
            fileName="profile.svg"
            pathName={routerConfig.PROFILE.path}
            padding={0}
            width={'14px'}
            text={routerConfig.PROFILE.title}
          />
        )}
      </Content>
      <BottomBlock>
        {isExpanded && (
          <UserInfo>
            <Name>{`${common.user.last_name} ${common.user.first_name[0]}. ${common.user.second_name?.[0]?.concat(
              '.',
            )}.`}</Name>
            <Job>{common.user.is_superuser ? 'Администратор программы' : `${user_role_name[common.user.role]}`}</Job>
          </UserInfo>
        )}
        {/*<BottomBlockIcon*/}
        {/*  expanded={isExpanded}*/}
        {/*  active={common.isStudyMode}*/}
        {/*  onClick={() => dispatch(setStudyMode({ isStudyMode: !common.isStudyMode }))}*/}
        {/*>*/}
        {/*  <div>*/}
        {/*    <img src={`./img/${common.isStudyMode ? 'blue' : 'black'}/play.svg`} alt="mail" />*/}
        {/*  </div>*/}
        {/*  <IconText active={common.isStudyMode}>Режим обучения {common.isStudyMode ? 'включен' : 'выключен'}</IconText>*/}
        {/*</BottomBlockIcon>*/}
        <BottomBlockIcon expanded={isExpanded} active={false} onClick={() => setContactUsVisible(true)}>
          <div>
            <img src="./img/black/envelope.svg" alt="mail" />
          </div>
          <IconText>Связаться с нами</IconText>
        </BottomBlockIcon>
        <BottomBlockIcon expanded={isExpanded} active={false} onClick={logOut}>
          <div>
            <img src="./img/black/mail.svg" alt="mail" />
          </div>
          <IconText>Выход</IconText>
        </BottomBlockIcon>
      </BottomBlock>
      {contactUsVisible && <ContactUsModal onClose={() => setContactUsVisible(false)} />}
    </Layout>
  );
}
