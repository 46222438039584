import React from 'react';
import { DialogActions, DialogContent, DialogProps as MuiDialogProps } from '@material-ui/core';

import { StyledDialog, Image, Title, Text } from './styles';
import Button from '../../components/Buttons';

export interface DialogProps extends MuiDialogProps {
  onClose: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
  cancelText?: string;
  submitText?: string;
  title?: string;
  text: string;
  withoutActions?: boolean;
}

export const Dialog: React.FC<DialogProps> = ({
  open,
  onClose,
  onSubmit,
  isLoading = false,
  cancelText = 'Отменить',
  submitText = 'Сохранить',
  title,
  text,
  withoutActions = false,
}) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContent>
        <Image src="./img/yellow/exclamation-circle.svg" />
        {title && <Title>{title}</Title>}
        <Text>{text}</Text>
      </DialogContent>
      {!withoutActions && (
        <DialogActions>
          <Button title={cancelText} variant="text" onClick={onClose} disabled={isLoading} />
          <Button title={submitText} variant="filled" onClick={onSubmit} disabled={isLoading} />
        </DialogActions>
      )}
    </StyledDialog>
  );
};
