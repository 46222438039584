export enum FooterTitles {
  TOTAL = 'Итого к оплате',
  PREPAY = 'Аванс',
  PAID = 'Внесено',
  CASHBACK = 'Сдача',
}

export enum ScrollHeight {
  MAX = 475,
  MIN = 380,
}

export enum InvoiceProperties {
  PAYMENT = 'payment',
  PAID = 'paid',
  DISCOUNT_SUM = 'discountSum',
  DISCOUNT_TYPE = 'discountType',
  PAYMENT_TYPE = 'payment_type',
  PAYER = 'payer',
  AMOUNT_FOR_PAY = 'amountForPay',
}

export enum PaymentTypesCodes {
  COMBINED = 'COMB',
  PREPAID = 'PREPAID',
}

export enum AdvanceOperation {
  INCOME = 'INC',
  EXPENSE = 'EXP',
}
