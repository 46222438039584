/**
 * Переводит файл из формата blob в формат base64
 * @param {Blob} file Файл в формате Blob
 * @returns {Promise<string>} Promise возвращающий строку base64
 */

type TReadFile = (file: Blob) => Promise<string>;

const readFile: TReadFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          throw new Error('Result - не строка');
        }
      },
      false,
    );
    reader.readAsDataURL(file);
  });
};

export default readFile;
