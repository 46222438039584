import React, { useContext, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { blue } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import DoctorFilter from './DoctorFilter';

import { FormErrorsContext } from '../../index';
import ErrorPopup from '../../../../../../components/ErrorPopup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: (props: { placeholder: boolean; isError: boolean }) => ({
      minWidth: '222px',
      background: 'white',
      fontSize: '14px',
      color: props.placeholder ? '#A8AEB5' : '#515D6B',
      borderColor: props.isError ? '#E54770' : '#D3D6DA',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '4px',
      padding: '5px 34px 5px 8px',
      '&:hover': {
        borderColor: theme.palette.grey[400],
      },
      '&:focus': {
        borderRadius: '4px',
        background: 'white',
        borderColor: blue[200],
      },
    }),
    icon: {
      color: '#253446',
      right: 12,
      position: 'absolute',
      userSelect: 'none',
      pointerEvents: 'none',
    },
  }),
);

const DoctorSelect = ({
  doctors,
  doctorId,
  setDoctorId,
}: {
  doctorId: number;
  setDoctorId: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [open, setOpen] = React.useState(false);
  const selectRef = React.useRef();

  useEffect(() => {
    const currentDoctor = doctors.find((doctor) => doctor.id === doctorId);
    setDoctorId(currentDoctor ? currentDoctor.id : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorId]);

  const { formErrors, setFormErrors } = useContext(FormErrorsContext);

  const handleSelection = (newValue) => {
    setDoctorId(newValue === doctorId ? 0 : newValue);
    setOpen(false);
    if (newValue > 0) {
      setFormErrors({ ...formErrors, doctor: false });
    }
  };

  const styles = useStyles({ isError: formErrors.doctor, placeholder: doctorId === 0 });

  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + ' ' + styles.icon} />;
  };

  return (
    <FormControl>
      <Select
        disableUnderline
        classes={{ root: styles.select }}
        IconComponent={iconComponent}
        ref={selectRef}
        value={doctorId}
        displayEmpty
        open={false}
        onOpen={() => setOpen(true)}
      >
        <MenuItem value={0}>Выбрать врача</MenuItem>
        {doctors.map((d) => (
          <MenuItem key={d.id} value={d.id}>{`${d.last_name} ${d.first_name} ${d.second_name}`}</MenuItem>
        ))}
      </Select>
      <ErrorPopup isShown={formErrors.doctor} style={{ bottom: -35 }} text="Укажите врача" />
      <Popover
        id="simple-popover"
        open={open}
        anchorEl={selectRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ marginTop: '8px' }}
      >
        <DoctorFilter
          doctors={doctors}
          togglePopper={handleSelection}
          pickedDoctorId={doctorId > 0 ? doctorId : null}
        />
      </Popover>
    </FormControl>
  );
};

export default DoctorSelect;
