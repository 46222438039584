import React, { useEffect, useMemo, useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  RadioGroup,
} from '@material-ui/core';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useGetPermissionsQuery, useGetUserQuery, User, UserPermissions, UserRole } from '../../../../services/users';
import Button from '../../../../components/Buttons';
import { HiddenIcon, VisibleIcon } from '../../../../uikit/Icons';
import { useAppSelector } from '../../../../store/hooks';

import {
  Account,
  Block,
  Label,
  HighlightedLabel,
  PasswordIcon,
  RadioFormControlLabel,
  StyledDialog,
  StyledInputLabel,
  StyledRadio,
  StyledTextInput,
  StyledTooltip,
  Title,
  Error,
  Errors,
} from './styles';
import { Permissions } from './Permissions';
import { AccessFormData, AccessProps } from './types';
import Scrollbars from 'react-custom-scrollbars-2';

const defaultPermissions: { [key: string]: UserPermissions } = {
  [UserRole.Doctor]: {
    edit_clinic_profile: false,
    edit_staff: false,
    edit_staff_schedule: false,
    view_staff_contacts: true,
    view_staff_access: true,
    create_account: true,
    delete_account: false,
    edit_payment: false,
    view_payment: true,
    view_patient_contacts: false,
    view_patient_docs: false,
    view_patient_medcard: true,
    edit_price: false,
    create_report: false,
  },
  [UserRole.Administrator]: {
    edit_clinic_profile: false,
    edit_staff: false,
    edit_staff_schedule: true,
    view_staff_contacts: true,
    view_staff_access: true,
    create_account: true,
    delete_account: false,
    edit_payment: true,
    view_payment: true,
    view_patient_contacts: false,
    view_patient_docs: false,
    view_patient_medcard: true,
    edit_price: false,
    create_report: false,
  },
};

const defaultValues: Partial<AccessFormData> = {
  id: undefined,
  role: UserRole.Doctor,
  username: '',
  password: '',
  permissions: defaultPermissions[UserRole.Doctor],
};

const userRoleName = {
  DOC: 'Врач',
  ADM: 'Администратор',
};

const schema = yup.object().shape({
  id: yup.mixed().nullable(),
  role: yup.string(),
  username: yup.string().when({
    is: (val: string) => val.length === 0,
    then: yup.string().when('id', {
      is: (val) => !val,
      then: yup.string().required('Обязательное поле'),
      otherwise: yup.string().min(0),
    }),
    otherwise: yup.string().min(6, 'Не менее 6 символов'),
  }),
  password: yup.string().when({
    is: (val: string) => val.length === 0,
    then: yup.string().when('id', {
      is: (val) => !val,
      then: yup.string().required('Обязательное поле'),
      otherwise: yup.string().min(0),
    }),
    otherwise: yup.string().min(6, 'Не менее 6 символов'),
  }),
  confirmedUsername: yup.string().when('username', (username, schema) => {
    return schema.test({
      test: (confirmedUsername) => confirmedUsername === username,
      message: 'Логин не совпадает',
    });
  }),
  confirmedPassword: yup.string().when('password', (password, schema) => {
    return schema.test({
      test: (confirmedPassword) => confirmedPassword === password,
      message: 'Пароль не совпадает',
    });
  }),
});

export const Access = (props: AccessProps) => {
  const { open, setOpen, user: initialUser, onSave, isLoading = false, disabled = false } = props;
  const { data: loadedUser, isLoading: isUserLoading } = useGetUserQuery(initialUser.id, { skip: !initialUser.id });
  const { data: loadedPermissions, isLoading: isPermissionsLoading } = useGetPermissionsQuery(initialUser.id, {
    skip: !initialUser.id,
  });
  const methods = useForm<AccessFormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = methods;
  const { id: clinicId } = useAppSelector((state) => state.common.user.clinic);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState<boolean>(false);
  const [permissions, setPermissions] = useState(defaultPermissions[initialUser.role || UserRole.Doctor]);
  const userRole = watch('role');

  const user = useMemo<Partial<User>>(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { permissions, ...rest } = defaultValues;
    return { ...rest, ...initialUser, ...(initialUser.id ? loadedUser : ({} as Partial<User>)) };
  }, [initialUser, loadedUser]);

  const { first_name, last_name, second_name, job, is_active, is_superuser } = user;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { username, password, permissions, ...rest } = user;
    reset({ ...rest });
  }, [user, reset]);

  useEffect(() => {
    if (!initialUser.id) {
      setPermissions(defaultPermissions[userRole]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole, initialUser, defaultPermissions]);

  useEffect(() => {
    if (initialUser.id && loadedPermissions) {
      setPermissions(loadedPermissions);
    }
  }, [loadedPermissions, initialUser]);

  const handleSave = async (data: AccessFormData) => {
    if (onSave) {
      const { username, password, permissions, ...rest } = data;
      const newUser = { ...initialUser, ...rest, clinic: clinicId };
      if (username) {
        newUser.username = username;
      }
      if (password) {
        newUser.password = password;
      }

      await onSave(newUser, permissions);
    }
    setOpen(false);
  };

  const handleClose = () => {
    reset(defaultValues);
    setOpen(false);
  };

  const handleRoleChange = (role: UserRole) => {
    setPermissions(defaultPermissions[role]);
  };

  return (
    <StyledDialog open={open} onClose={handleClose} scroll="paper" disableBackdropClick disableEscapeKeyDown>
      <DialogTitle>Настройка доступов сотрудника</DialogTitle>
      <Scrollbars style={{ width: '100%', height: 600 }}>
        <DialogContent>
          <DialogContentText>
            <FormProvider {...methods}>
              {isUserLoading || isPermissionsLoading ? (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <div className="spinner" />
                </div>
              ) : (
                <>
                  <input type="hidden" name="id" value={user.id} {...register('id')} />
                  <Block>
                    <Title>
                      {last_name} {first_name} {second_name}
                    </Title>
                    <Label>Должность: {job}</Label>
                    {is_superuser && <HighlightedLabel>Администратор программы</HighlightedLabel>}
                  </Block>

                  <Block>
                    <Title>Роль</Title>
                    {is_active && !is_superuser ? (
                      <Label style={{ marginTop: 10 }}>{userRoleName[userRole]}</Label>
                    ) : (
                      <Controller
                        name="role"
                        control={control}
                        render={({ field }) => {
                          return (
                            <RadioGroup {...field} row>
                              <RadioFormControlLabel
                                control={<StyledRadio color="primary" size="small" />}
                                label="Врач"
                                value={UserRole.Doctor}
                                disabled={disabled}
                                onChange={(event, checked) => {
                                  if (checked) {
                                    handleRoleChange(UserRole.Doctor);
                                  }
                                }}
                              />
                              <RadioFormControlLabel
                                control={<StyledRadio color="primary" size="small" />}
                                label="Администратор"
                                value={UserRole.Administrator}
                                disabled={disabled}
                                onChange={(event, checked) => {
                                  if (checked) {
                                    handleRoleChange(UserRole.Administrator);
                                  }
                                }}
                              />
                            </RadioGroup>
                          );
                        }}
                      />
                    )}
                  </Block>

                  <Block style={{ marginBottom: 0 }}>
                    <Title>{is_active ? 'Поменять пароль' : 'Учетная запись'}</Title>
                    <Account>
                      <FormControl>
                        <StyledInputLabel htmlFor="username">Логин</StyledInputLabel>
                        <StyledTooltip
                          title={errors.username ? errors.username.message : ''}
                          placement="bottom-start"
                          disableHoverListener={!errors.username}
                          disableFocusListener={!errors.username}
                          disableTouchListener={!errors.username}
                          enterDelay={500}
                          enterNextDelay={500}
                          aria-autocomplete="none"
                          dark
                        >
                          <StyledTextInput
                            name="username"
                            error={errors.username}
                            disabled={disabled}
                            {...register('username')}
                            autoComplete="off"
                          />
                        </StyledTooltip>
                      </FormControl>
                      <FormControl>
                        <StyledInputLabel htmlFor="password">Пароль</StyledInputLabel>
                        <StyledTooltip
                          title={errors.password ? errors.password.message : ''}
                          placement={'bottom-start'}
                          disableHoverListener={!errors.password}
                          disableFocusListener={!errors.password}
                          disableTouchListener={!errors.password}
                          enterDelay={500}
                          enterNextDelay={500}
                          dark
                        >
                          <StyledTextInput
                            style={{ paddingRight: '40px' }}
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            error={errors.password}
                            disabled={disabled}
                            {...register('password')}
                            autoComplete="new-password"
                          />
                        </StyledTooltip>
                        <PasswordIcon onClick={() => setShowPassword((show) => !show)}>
                          {showPassword ? <VisibleIcon /> : <HiddenIcon />}
                        </PasswordIcon>
                      </FormControl>
                    </Account>
                    <Errors style={{ height: '16px' }}>
                      <Error>{errors?.username?.message}</Error>
                      <Error>{errors?.password?.message}</Error>
                    </Errors>
                    <Account>
                      <FormControl>
                        <StyledInputLabel htmlFor="confirmedUsername">{'Подтвердите логин'}</StyledInputLabel>
                        <StyledTooltip
                          title={errors.confirmedUsername ? errors.confirmedUsername.message : ''}
                          placement={'bottom-start'}
                          disableHoverListener={!errors.confirmedUsername}
                          disableFocusListener={!errors.confirmedUsername}
                          disableTouchListener={!errors.confirmedUsername}
                          enterDelay={500}
                          enterNextDelay={500}
                          aria-autocomplete="none"
                          dark
                        >
                          <StyledTextInput
                            name="confirmedUsername"
                            error={errors.confirmedUsername}
                            disabled={disabled}
                            {...register('confirmedUsername')}
                            autoComplete="off"
                          />
                        </StyledTooltip>
                      </FormControl>
                      <FormControl>
                        <StyledInputLabel htmlFor="confirmedPassword">{'Подтвердите пароль'}</StyledInputLabel>
                        <StyledTooltip
                          title={errors.confirmedPassword ? errors.confirmedPassword.message : ''}
                          placement={'bottom-start'}
                          disableHoverListener={!errors.confirmedPassword}
                          disableFocusListener={!errors.confirmedPassword}
                          disableTouchListener={!errors.confirmedPassword}
                          enterDelay={500}
                          enterNextDelay={500}
                          dark
                        >
                          <StyledTextInput
                            style={{ paddingRight: '40px' }}
                            name="confirmedPassword"
                            type={showConfirmedPassword ? 'text' : 'password'}
                            error={errors.confirmedPassword}
                            disabled={disabled}
                            {...register('confirmedPassword')}
                            autoComplete="new-password"
                          />
                        </StyledTooltip>
                        <PasswordIcon onClick={() => setShowConfirmedPassword((show) => !show)}>
                          {showConfirmedPassword ? <VisibleIcon /> : <HiddenIcon />}
                        </PasswordIcon>
                      </FormControl>
                    </Account>
                    <Errors>
                      <Error>{errors?.confirmedUsername?.message}</Error>
                      <Error>{errors?.confirmedPassword?.message}</Error>
                    </Errors>
                  </Block>

                  <Permissions
                    permissions={permissions}
                    disabled={disabled}
                    userRole={userRole}
                    isSuperUser={is_superuser}
                  />
                </>
              )}
            </FormProvider>
          </DialogContentText>
        </DialogContent>
      </Scrollbars>
      <DialogActions>
        <Button title="Отменить" variant="outlined" onClick={handleClose} disabled={isLoading} />
        <Button title="Сохранить" variant="filled" onClick={handleSubmit(handleSave)} disabled={isLoading} />
      </DialogActions>
    </StyledDialog>
  );
};
