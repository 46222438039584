import React, { useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { DateRangePicker } from '../DateRangePicker';
import MultiSelect from '../MultiSelect';
import { useGetPriceQuery } from '../../../../services/price';
import { UserRole } from '../../../../services/users';
import { useGetRevenueReportMutation } from '../../../../services/reports';
import { useGetPayersQuery, useGetPaymentTypesQuery } from '../../../../services/dictionaries';
import { Filters, FiltersContainer, BlueButton } from './styles';
import {
  checkAndGetDateString,
  checkForFilterDates,
  getFirstLastDateOfMonth,
  getFirstLastDateOfYear,
  getFormattedFilter,
} from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setRevenueFilter, setRevenueReport } from '../../reducer';

export function ReportRevenueFilters() {
  const dispatch = useAppDispatch();
  const staff = useAppSelector((state) => state.staff);
  const revenueFilter = useAppSelector((state) => state.reports.revenueFilter);

  const [getRevenueReport] = useGetRevenueReportMutation();
  const { data: service } = useGetPriceQuery('');
  const { data: paymentTypes } = useGetPaymentTypesQuery();
  const { data: payers } = useGetPayersQuery();

  const doctors = useMemo(() => {
    return staff.responce.filter((doctor) => doctor.role === UserRole.Doctor);
  }, [staff]);

  const sortedPayers = useMemo(() => {
    if (payers) {
      return payers
        .filter((payer) => payer.payer_type !== 'PATIENT')
        .map((payer) => {
          if (payer.payer_type === 'INSURANCE') {
            return { ...payer, payer_type: 'Страховые компании' };
          }
          return { ...payer, payer_type: 'Физические лица' };
        })
        .sort((a, b) => a.title.localeCompare(b.title))
        .sort((a, b) => a.payer_type.localeCompare(b.payer_type));
    }
    return [];
  }, [payers]);

  const onFiltersChange = (value) => {
    dispatch(setRevenueFilter(value));
  };

  const onSubmit = () => {
    if (!checkForFilterDates(revenueFilter)) {
      const formattedRevenueFilter = getFormattedFilter(revenueFilter);
      getRevenueReport(formattedRevenueFilter)
        .unwrap()
        .then((res) => {
          const formattedRevenueReport = res.map((item, itemIndex) => {
            const { discount, discount_type } = item;
            return { ...item, id: itemIndex, discount: discount + ' ' + discount_type };
          });
          dispatch(setRevenueReport(formattedRevenueReport));
        })
        .catch((error) => {
          toast.error(
            error.status === 404
              ? 'Нет данных для формирования отчета'
              : 'При формировании отчёта произошла ошибка. Попробуйте позже.',
          );
        });
    }
  };

  const onCurrentMonthPick = (month, year) => {
    const [firstDateOfMonth, lastDateOfMonth] = getFirstLastDateOfMonth(month, year);
    onFiltersChange({
      start_date: firstDateOfMonth,
      end_date: lastDateOfMonth,
    });
  };

  const onCurrentYearPick = (year) => {
    const [firstDateOfYear, lastDateOfYear] = getFirstLastDateOfYear(year);
    onFiltersChange({
      start_date: firstDateOfYear,
      end_date: lastDateOfYear,
    });
  };

  return (
    <Filters>
      <FiltersContainer>
        <DateRangePicker
          value={[
            revenueFilter.start_date ? new Date(Date.parse(revenueFilter.start_date)) : null,
            revenueFilter.end_date ? new Date(Date.parse(revenueFilter.end_date)) : null,
          ]}
          onChange={(dates) =>
            onFiltersChange({
              start_date: dates[0] ? checkAndGetDateString(dates[0]) : null,
              end_date: dates[1] ? checkAndGetDateString(dates[1]) : null,
            })
          }
          onCurrentMonthPick={onCurrentMonthPick}
          onCurrentYearPick={onCurrentYearPick}
        />
        <MultiSelect
          name="doctor"
          values={doctors}
          pickedValues={revenueFilter?.doctor_id || []}
          optionName="fullname"
          width={174}
          search
          category="job"
          placeholder="Врач"
          onChange={(doctor_id) => onFiltersChange({ doctor_id })}
        />
        <MultiSelect
          name="serviceCode"
          optionName="code"
          values={service}
          pickedValues={revenueFilter?.price_codes || []}
          width={162}
          search
          placeholder="Код услуги"
          onChange={(price_codes) => onFiltersChange({ price_codes })}
        />
        <MultiSelect
          name="service"
          optionName="text"
          values={service}
          pickedValues={revenueFilter?.price_titles || []}
          width={190}
          search
          placeholder="Наименование услуги"
          onChange={(price_titles) => onFiltersChange({ price_titles })}
        />
        <MultiSelect
          name="paymentType"
          optionName="title"
          values={paymentTypes}
          pickedValues={revenueFilter?.payment_types || []}
          width={174}
          placeholder="Способ оплаты"
          onChange={(payment_types) => onFiltersChange({ payment_types })}
        />
        <MultiSelect
          name="payer"
          optionName="title"
          values={sortedPayers}
          pickedValues={revenueFilter?.payers || []}
          width={171}
          category="payer_type"
          placeholder="Плательщик"
          search
          onChange={(payers) => onFiltersChange({ payers })}
        />
        <BlueButton onClick={onSubmit}>Сформировать</BlueButton>
      </FiltersContainer>
    </Filters>
  );
}
