import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    marginRight: 8,
    display: 'flex',
    '&.disabled': {
      opacity: 0.5,
    },
  },
  switchBase: {
    padding: 2,
    color: '#BFBFBF',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#577BF9',
        borderColor: '#577BF9',
      },
      '&$disabled + $track': {
        opacity: 0.5,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    backgroundColor: 'white',
  },
  track: {
    // border: `1px solid #BFBFBF`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#BFBFBF',
  },
  checked: {},
  disabled: {},
}))(Switch);

export default AntSwitch;
