import IMask from 'imask';

const phoneMask = IMask.createMask({
  mask: '(000) 000-00-00',
});

const rusPhoneMask = IMask.createMask({
  mask: '{+}7 (000) 000-00-00',
});

const emailMask = IMask.createMask({
  mask: /^\S*@?\S*$/,
});
const dateMask = IMask.createMask({
  mask: Date,
  min: new Date(1860, 0, 1),
  max: new Date(),
});
const countMask = IMask.createMask({
  mask: Number,
  min: 0,
});
const countWithoutZeroMask = IMask.createMask({
  mask: Number,
  min: 1,
});
const numberFormatMask = IMask.createMask({
  mask: Number,
  thousandsSeparator: ' ',
});
// eslint-disable-next-line
const masks: any = {
  phone: phoneMask,
  rusPhone: rusPhoneMask,
  email: emailMask,
  birth_date: dateMask,
  count: countMask,
  count_without_zero: countWithoutZeroMask,
  number_format: numberFormatMask,
  default: { resolve: (value: string): string => value },
};
export function getMask(type: string) {
  // eslint-disable-next-line
  return masks.hasOwnProperty(type) ? masks[type] : masks.default;
}
