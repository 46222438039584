import React from 'react';
// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast';
import { printPdf, uploadFile } from '../../utils/print';
import request from '../../utils/request';

export const checkAndGetDateString = (date) =>
  date && new Date(date.getTime() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10);

export const getFirstLastDateOfMonth = (month, year) => {
  const firstDate = new Date(year, month, 1);
  const lastDate = new Date(year, month + 1, 0);
  return [firstDate, lastDate];
};

export const getFirstLastDateOfYear = (year) => {
  const firstDate = new Date(year, 0, 1);
  const lastDate = new Date(year, 11, 31);
  return [firstDate, lastDate];
};

export const getBalanceNames = (name) => {
  switch (name) {
    case 'Задолженности и авансы':
      return ['advances', 'debts'];
    case 'Задолженности':
      return ['debts'];
    case 'Авансы':
      return ['advances'];
    default:
      return [''];
  }
};

export enum EdebtTableDisplayMode {
  Advances = 'advances',
  Debts = 'debts',
  AdvancesDebts = 'advances debts',
  None = '',
}

export const getDebtsAndAdvancesColumns = (debtTableDisplayMode, viewPatientContacts) => {
  return [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Пациент',
      accessor: 'patient',
      width: viewPatientContacts ? 300 : 880,
      disableFilters: true,
    },
    viewPatientContacts
      ? {
          Header: 'Телефон',
          accessor: 'patient_phone',
          width: 290,
          disableFilters: true,
        }
      : null,
    viewPatientContacts
      ? {
          Header: 'Почта',
          accessor: 'patient_email',
          width: 290,
          disableFilters: true,
        }
      : null,
    debtTableDisplayMode !== EdebtTableDisplayMode.Advances
      ? {
          Header: 'Задолженность',
          accessor: 'debt',
          width: 210,
          disableFilters: true,
          Cell: (props) => (
            <span style={{ color: props.value === 0 ? '#515d6b' : props.value > 0 ? '#0D853D' : '#C3000A' }}>
              {props.value}
            </span>
          ),
        }
      : null,
    debtTableDisplayMode !== EdebtTableDisplayMode.Debts
      ? {
          Header: 'Аванс',
          accessor: 'advance',
          width: 210,
          disableFilters: true,
          Cell: (props) => (
            <span style={{ color: props.value === 0 ? '#515d6b' : props.value > 0 ? '#0D853D' : '#C3000A' }}>
              {props.value}
            </span>
          ),
        }
      : null,
  ].filter((col) => col);
};

export const revenueColumns = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Дата',
    accessor: 'operation_date',
    width: 106,
    disableFilters: true,
  },
  {
    Header: 'Врач',
    accessor: 'doctor',
    width: 200,
    disableFilters: true,
  },
  {
    Header: 'Код услуги',
    accessor: 'favor_code',
    width: 110,
    disableFilters: true,
  },
  {
    Header: 'Наименование услуги',
    accessor: 'favor_title',
    width: 240,
    disableFilters: true,
  },
  {
    Header: 'Цена',
    accessor: 'price',
    width: 50,
    disableFilters: true,
  },
  {
    Header: 'Кол-во',
    accessor: 'count',
    width: 50,
    disableFilters: true,
  },
  {
    Header: 'Скидка',
    accessor: 'discount',
    width: 50,
    disableFilters: true,
  },
  {
    Header: 'Способ оплаты',
    accessor: 'payment_type',
    width: 172,
    disableFilters: true,
  },
  {
    Header: 'Плательщик',
    accessor: 'payer',
    width: 120,
    disableFilters: true,
  },
  {
    Header: 'Итого',
    accessor: 'total',
    width: 106,
    disableFilters: true,
  },
  {
    Header: 'Оплачено',
    accessor: 'payment',
    width: 106,
    disableFilters: true,
  },
];

export const getFormattedFilter = (filter) => {
  const formattedFilter = {
    start_date: filter.start_date,
    end_date: filter.end_date,
  };

  filter?.doctor_id?.length ? (formattedFilter.doctor_id = filter.doctor_id.map((doctor) => doctor.id)) : null;

  filter?.price_codes?.length
    ? (formattedFilter.price_codes = filter.price_codes.map((priceCode) => priceCode.name))
    : null;

  filter?.price_titles?.length
    ? (formattedFilter.price_titles = filter.price_titles.map((priceTitle) => priceTitle.name))
    : null;

  filter?.payment_types?.length
    ? (formattedFilter.payment_types = filter.payment_types.map((paymentType) => paymentType.id))
    : null;

  filter?.payers?.length ? (formattedFilter.payers = filter.payers.map((payer) => payer.id)) : null;

  filter?.resources?.length ? (formattedFilter.resources = getBalanceNames(filter.resources[0].name)) : null;

  filter?.patients?.length ? (formattedFilter.patients = filter.patients.map((patient) => patient.id)) : null;

  filter?.export ? (formattedFilter.export = filter.export) : null;

  return formattedFilter;
};

export const getFormattedHistoryFilter = (filter) => {
  const formattedFilter = {
    date_from: filter.start_date,
    date_to: filter.end_date,
  };

  filter?.doctor_id?.length ? (formattedFilter.doctor = filter.doctor_id.map((doctor) => doctor.id)) : null;

  filter?.patients?.length ? (formattedFilter.patient = filter.patients.map((patient) => patient.id)) : null;

  return formattedFilter;
};

export const getCurrentTimeString = () => {
  const currentDate = new Date();
  let date = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  let hour = currentDate.getHours();
  let min = currentDate.getMinutes();
  let sec = currentDate.getSeconds();

  date = date < 10 ? '0' + date : date;
  month = month < 10 ? '0' + month : month;
  hour = hour < 10 ? '0' + hour : hour;
  min = min < 10 ? '0' + min : min;
  sec = sec < 10 ? '0' + sec : sec;

  return date + '-' + month + '-' + year + '-' + hour + '-' + min + '-' + sec;
};

export const getRevenueFiltersInfo = (revenueFilter) => {
  return {
    date:
      revenueFilter.start_date && revenueFilter.end_date
        ? `${revenueFilter.start_date} — ${revenueFilter.end_date}`.split('-').join('/')
        : null,
    doctor:
      revenueFilter.doctor_id && revenueFilter.doctor_id.length
        ? revenueFilter.doctor_id.map((doctor) => <div key={doctor.id}>{doctor.name}</div>)
        : null,
    code:
      revenueFilter.price_codes && revenueFilter.price_codes.length
        ? revenueFilter.price_codes.map((priceCode) => <div key={priceCode.id}>{priceCode.name}</div>)
        : null,
    priceTitles:
      revenueFilter.price_titles && revenueFilter.price_titles.length
        ? revenueFilter.price_titles.map((priceTitle) => <div key={priceTitle.id}>{priceTitle.name}</div>)
        : null,
    paymentTypes:
      revenueFilter.payment_types && revenueFilter.payment_types.length
        ? revenueFilter.payment_types.map((paymentType) => <div key={paymentType.id}>{paymentType.name}</div>)
        : null,
    payers:
      revenueFilter.payers && revenueFilter.payers.length
        ? revenueFilter.payers.map((payer) => <div key={payer.id}>{payer.name}</div>)
        : null,
  };
};

export const getDebtsFiltersInfo = (debtsAndAdvancesFilter) => {
  return {
    date:
      debtsAndAdvancesFilter.start_date && debtsAndAdvancesFilter.end_date
        ? `${debtsAndAdvancesFilter.start_date} — ${debtsAndAdvancesFilter.end_date}`.split('-').join('/')
        : null,
    resources:
      debtsAndAdvancesFilter.resources && debtsAndAdvancesFilter.resources.length
        ? debtsAndAdvancesFilter.resources.map((resource) => <div key={resource.id}>{resource.name}</div>)
        : null,
  };
};

const getFileFormatFromExportType = (exportType) => {
  switch (exportType) {
    case 'pdf':
      return '.pdf';
    case 'word':
      return '.docx';
    case 'excel':
      return '.xlsx';
    case '':
      toast.error('Пожалуйста, укажите желаемый формат файла');
      return null;
    default:
      console.error(`Допустимые форматы: pdf, word, excel. Получен формат: ${exportType}`);
      return null;
  }
};

export const checkForFilterDates = (filter) => {
  if (!filter.start_date && filter.end_date) {
    toast.error(`Необходимо указать начало отчётного периода`);
    return true;
  }
  if (filter.start_date && !filter.end_date) {
    toast.error(`Необходимо указать конец отчётного периода`);
    return true;
  }
  if (!filter.start_date && !filter.end_date) {
    toast.error(`Необходимо указать начало и конец отчётного периода`);
    return true;
  }
  return false;
};

export const checkForFilterResources = (filter) => {
  if (!filter.resources || !filter.resources.length) {
    toast.error(`Необходимо указать статью: авансы или задолженности`);
    return true;
  }
  return false;
};

export const checkForFilterPatient = (filter) => {
  if (!filter?.patients?.length) {
    toast.error(`Необходимо выбрать пациента`);
    return true;
  }
  return false;
};

export const exportRevenue = async (filter, exportType) => {
  if (checkForFilterDates(filter)) {
    return;
  }

  const fileFormat = getFileFormatFromExportType(exportType);
  if (!fileFormat) {
    return;
  }

  const result = await request.clinic.get_revenue_report(getFormattedFilter({ export: exportType, ...filter }));
  uploadFile(result.data, `${getCurrentTimeString()}-revenue-report${fileFormat}`);
};

export const exportDebtsAndAdvances = async (filter, exportType) => {
  if (checkForFilterDates(filter)) {
    return;
  }
  if (checkForFilterResources(filter)) {
    return;
  }

  const fileFormat = getFileFormatFromExportType(exportType);
  if (!fileFormat) {
    return;
  }

  const result = await request.clinic.get_debts_and_advances_report(
    getFormattedFilter({ export: exportType, ...filter }),
  );
  uploadFile(result.data, `${getCurrentTimeString()}-debts-and-advances-report${fileFormat}`);
};

export const printRevenue = async (filter) => {
  if (checkForFilterDates(filter)) {
    return;
  }

  const result = await request.clinic.get_revenue_report(getFormattedFilter({ export: 'pdf', ...filter }));
  printPdf(result.data);
};

export const printDebtsAndAdvances = async (filter) => {
  if (checkForFilterDates(filter)) {
    return;
  }
  if (checkForFilterResources(filter)) {
    return;
  }

  const result = await request.clinic.get_debts_and_advances_report(getFormattedFilter({ export: 'pdf', ...filter }));
  printPdf(result.data);
};

export const performPrint = async ({ mode, revenueFilter, debtsAndAdvancesFilter }) => {
  switch (mode) {
    case 'revenue':
      printRevenue(revenueFilter);
      break;
    case 'debts_and_advances':
      printDebtsAndAdvances(debtsAndAdvancesFilter);
      break;
    default:
      console.error(`Допустимые режимы: revenue, debts_and_advances. Получен режим: ${mode}`);
      return;
  }
};

export const performExport = async ({ mode, exportType, revenueFilter, debtsAndAdvancesFilter }) => {
  switch (mode) {
    case 'revenue':
      exportRevenue(revenueFilter, exportType);
      break;
    case 'debts_and_advances':
      exportDebtsAndAdvances(debtsAndAdvancesFilter, exportType);
      break;
    default:
      console.error(`Допустимые режимы: revenue, debts_and_advances. Получен режим: ${mode}`);
      return;
  }
};
