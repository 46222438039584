import React from 'react';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import { StudyModalConfig } from '../StudyModal/config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import StudyModalState from '../StudyModal/state';

type Props = {
  Component: React.ComponentType;
  studyMnemo: keyof typeof StudyModalConfig;
};

export function StudyComponent({ Component, studyMnemo }: Props) {
  const dispatch = useDispatch();
  const isStudyMode = useSelector((state: RootState) => state.common.isStudyMode);
  const { show } = StudyModalState.actions;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Component />
      {isStudyMode && (
        <PlayCircleOutlineOutlinedIcon
          fontSize="small"
          style={{ color: '#577BF9', marginLeft: 8 }}
          onClick={() => dispatch(show(StudyModalConfig[studyMnemo]))}
        />
      )}
    </div>
  );
}
