import styled from 'styled-components';
import { IOptionProps } from './types';

export const Selector = styled.div`
  width: 230px;
  height: 32px;
  border: 1px solid #d3d6da;
  border-radius: 4px;
  padding: 4px 6px 4px 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  margin-bottom: 4px;
`;

export const SelectedOption = styled.input`
  width: 200px;
  color: #515d6b;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  border: 0;
  background: white;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;

export const OptionsWindow = styled.div`
  width: 230px;
  border: 1px solid #d3d6da;
  border-radius: 4px;
  position: absolute;
  background: white;
  padding: 12px 0 6px;
`;

export const Options = styled.div`
  padding-top: 12px;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Option = styled.div<IOptionProps>`
  height: 28px;
  padding: 0 8px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: hidden;
  background: ${({ selected }) => (selected ? '#F6F6FA' : '#FFFFFF')};
  margin-bottom: 4px;
  &:hover {
    background: #eff2ff;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid #f6f6fa;
  margin: 12px 8px 0 8px;
`;

export const SearchInput = styled.input`
  border: 0;
  background: #ffffff;
  height: 20px;
  width: 190px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }
`;