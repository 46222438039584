import { Middleware } from '@reduxjs/toolkit';
import { clinicApi } from '../services/clinics';
import { userApi } from '../services/users';
import { dictionaryApi } from '../services/dictionaries';
import { medcardApi } from '../services/medcard';
import { medcardJournalApi } from '../services/medcardJournal';
import { invoiceApi } from '../services/invoice';
import { priceApi } from '../services/price';
import { childFormulaApi } from '../services/childFormula';
import { staffScheduleApi } from '../services/staffSchedule';
import { appointmentsApi } from '../services/appointments';
import { advanceApi } from '../services/advance';
import { reportsApi } from '../services/reports';
import { patientsApi } from '../services/patients';
import { billingApi } from '../services/billing';

const middlewares: Middleware[] = [
  clinicApi.middleware,
  userApi.middleware,
  dictionaryApi.middleware,
  medcardApi.middleware,
  medcardJournalApi.middleware,
  invoiceApi.middleware,
  priceApi.middleware,
  childFormulaApi.middleware,
  staffScheduleApi.middleware,
  appointmentsApi.middleware,
  advanceApi.middleware,
  reportsApi.middleware,
  patientsApi.middleware,
  billingApi.middleware,
];

export default middlewares;
