import React from 'react';
import TextAreaBlock from './TextAreaBlock';
import TextArea from '../../uikit/TextArea';
import { connect } from 'react-redux';
import MedCardPageSlice from './state';
import { patchPatientData } from './operations';
import { Row } from './style';
import { RootState } from '../../store';

function AdditionalInfo({ medCardPageSlice }) {
  const { id, illnesses, commentary } = medCardPageSlice.patientDetails;
  const patchCurrentPatientData = patchPatientData(id);
  return (
    <>
      <Row>
        <TextAreaBlock
          multiline
          name={'illnesses'}
          width={542}
          label={'Сопутствующие заболевания'}
          initialValue={illnesses}
          placeholder={'Заболевания, аллергии, опасные состояния'}
          handleBlur={patchCurrentPatientData}
          InputComponent={TextArea}
        />
      </Row>
      <Row>
        <TextAreaBlock
          multiline
          name={'commentary'}
          width={542}
          label={'Важная информация о пациенте'}
          initialValue={commentary}
          placeholder={'Пример: “Подготовить плед”'}
          handleBlur={patchCurrentPatientData}
          InputComponent={TextArea}
        />
      </Row>
    </>
  );
}

export default connect((state: RootState) => ({ medCardPageSlice: state.medCardPageSlice }), {
  setPatientDetails: MedCardPageSlice.actions.setPatientDetails,
})(AdditionalInfo);
