import TextInput from '../../../../uikit/TextInput';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ClickAwayListener, Popover } from '@material-ui/core';
import OutlinedButton from '../../../../uikit/Button/OutlinedButton';
import BlueButton from '../../../../uikit/Button/BlueButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const CabinetContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;

  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #515d6b;

  width: 168px;

  input {
    text-align: center;
    padding-left: 0;
  }
`;

const Cab = styled.div<{ isSelect: boolean; isEdit: boolean }>`
  display: flex;
  margin-right: ${({ isEdit }) => (isEdit ? 20 : 48)}px;
  width: ${({ isEdit }) => (isEdit ? 196 : 168)}px;
  height: 64px;
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  border: ${({ isSelect }) => (isSelect ? '1px solid #577BF9' : 0)};
  background: #eff2ff;
`;

const CabinetName = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #515d6b;
  max-width: 160px;
  word-break: break-word;
`;

const EmptyText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #a8aeb5;
`;

const ModalTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
`;

type Props = {
  name: string;
  setName: (value: string) => void;
  onDelete: () => void;
};
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginLeft: -20,
      marginTop: -4,
    },
  }),
);

export function Cabinet({ name, setName, onDelete }: Props) {
  const classes = useStyles();

  const [title, setTitle] = useState<string>(name);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const textInputRef = useRef(null);
  const cabinetDivRef = useRef(null);

  useEffect(() => {
    if (isEdit && textInputRef && textInputRef.current) {
      textInputRef.current.focus();
    }
  }, [isEdit]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!name) {
          onDelete();
        }
        if (!title) {
          setTitle(name);
        }
        setIsSelect(false);
        setIsEdit(false);
      }}
    >
      <div ref={cabinetDivRef} style={{ height: '64px' }}>
        <Cab isSelect={isSelect} isEdit={isEdit && name}>
          <CabinetContent
            onClick={() => {
              setIsSelect(true);
              setIsEdit(true);
              if (title === 'Новый кабинет') {
                setTitle('');
              }
            }}
          >
            {isEdit ? (
              <TextInput
                ref={textInputRef}
                maxLength={42}
                transparent
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={(e) => setName(e.target.value)}
              />
            ) : name ? (
              <CabinetName>{title}</CabinetName>
            ) : (
              <EmptyText>Введите название</EmptyText>
            )}
          </CabinetContent>
          {isSelect && name && (
            <div
              style={{
                color: '#577BF9',
                background: '#EFF2FF',
                borderBottomRightRadius: 8,
                borderTopRightRadius: 8,
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src="./img/blue/delete.svg"
                width="18px"
                alt="delete"
                onClick={() => {
                  setOpen(true);
                }}
              />
            </div>
          )}
        </Cab>
        <Popover
          open={open}
          anchorEl={cabinetDivRef.current}
          classes={{
            paper: classes.paper,
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClose={() => {}}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <div style={{ padding: '12px 16px' }}>
            <ModalTitle>Удалить кабинет?</ModalTitle>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12 }}>
              <OutlinedButton
                height={24}
                width={41}
                style={{ marginTop: 0, marginRight: 8, border: '1px solid #D9D9D9' }}
                onClick={() => {
                  setOpen(false);
                  setIsSelect(false);
                  setIsEdit(false);
                }}
              >
                Нет
              </OutlinedButton>
              <BlueButton
                height={24}
                width={35}
                style={{ marginTop: 0 }}
                onClick={() => {
                  setOpen(false);
                  onDelete();
                }}
              >
                Да
              </BlueButton>
            </div>
          </div>
        </Popover>
      </div>
    </ClickAwayListener>
  );
}
