import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import BlueButton from '../../../uikit/Button/BlueButton';
import OutlinedButton from '../../../uikit/Button/OutlinedButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type Props = {
  open: boolean;
  onOK: () => void;
  onCancel: () => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: '16px 24px 24px 16px',
    },
  }),
);

export function AlertDialog({ open, onOK, onCancel }: Props) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      BackdropComponent={() => null}
    >
      <DialogContent>
        <DialogContentText>
          <div style={{ display: 'flex' }}>
            <ErrorOutlineOutlinedIcon style={{ color: '#FAAD14', marginRight: 17.5 }} />
            Удалить запись дневника?
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <OutlinedButton style={{ marginRight: 24, marginTop: 0 }} width={97} noBorder onClick={onCancel}>
          Отменить
        </OutlinedButton>
        <BlueButton style={{ marginTop: 0 }} width={87} onClick={onOK}>
          Удалить
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
}
