import NotConfirmedIcon from '../modals/VisitCard/icons/NotConfirmedIcon';
import ConfirmedIcon from '../modals/VisitCard/icons/ConfirmedIcon';
import InClinicIcon from '../modals/VisitCard/icons/InClinicIcon';
import OnTreatmentIcon from '../modals/VisitCard/icons/OnTreatmentIcon';
import VisitOverIcon from '../modals/VisitCard/icons/VisitOverIcon';
import CommentIcon from '../modals/VisitCard/icons/CommentIcon';
import React, { FC } from 'react';

interface VisitStatusIconProps {
  value?: string;
}

export const statuses = [
  {
    id: 'SCH',
    name: 'Не подтвержден',
  },
  {
    id: 'CNF',
    name: 'Подтвержден',
  },
  {
    id: 'ARR',
    name: 'В клинике',
  },
  {
    id: 'INP',
    name: 'На лечении',
  },
  {
    id: 'COM',
    name: 'Визит окончен',
  },
];

export const VisitStatusIcon: FC<VisitStatusIconProps> = ({ value }) => {
  switch (value) {
    case 'SCH':
      return <NotConfirmedIcon />;
    case 'CNF':
      return <ConfirmedIcon />;
    case 'ARR':
      return <InClinicIcon />;
    case 'INP':
      return <OnTreatmentIcon />;
    case 'COM':
      return <VisitOverIcon />;
    case 'COMMENT':
      return <CommentIcon />;
    default:
      return <></>;
  }
};
