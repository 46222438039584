import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { baseURL } from '../utils/request';
import { MedcardPlanJournalField } from './medcardJournal';

export const priceApi = createApi({
  reducerPath: 'priceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['PriceList'],
  endpoints: (builder) => ({
    getPrice: builder.query<MedcardPlanJournalField[], string>({
      query: (priceCategory) => `/clinics/search/price${priceCategory ? `?category=${priceCategory}` : ''}`,
      transformResponse: (response) =>
        response.items.map((item) => ({
          id: item.id,
          code: item.code,
          text: item.title,
          price: +item.value,
        })),
      providesTags: [{ type: 'PriceList', id: 'LIST' }],
    }),
  }),
});

export const { useGetPriceQuery } = priceApi;
