import { replace } from "lodash";

export const removeWhiteSpaces = (str: string) => replace(str, " ", "");

export const isEmptyString = (field: string) => removeWhiteSpaces(field) === "";

const pass = () => false;

const fieldValidatorsMap: any = {
  first_name: isEmptyString,
  second_name: isEmptyString,
  last_name: pass,
  phone: isEmptyString,
  email: pass,
  birth_date: pass,
  stream: pass,
  commentary: pass,
  id: pass,
};

export const getFieldValidator = (field_name: string) =>
  fieldValidatorsMap[field_name];
