/* eslint-disable no-case-declarations */
import React, { memo } from 'react';
import { setIsWatchingMatches } from '../reducer';
import { MatchesWarningContainer } from '../styled-components';
import { WarningIcon } from '../../../../../uikit/Icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useGetCabinetsDictionaryQuery, CabinetDictionaryItem } from '../../../../../services/dictionaries';
import { getMatchesWarningWindowMessage, scrollTableToDate, getMissingCabinets } from './StaffSchedulerTable/functions';

const StaffScheduleMatchesWarning = () => {
  const dispatch = useAppDispatch();
  const {
    isWatchingMatches,
    sessionsMatches,
    currentSessionsMatch,
    showEvenDates,
    showOddDates,
    tableColumnModel,
    selectedCabinets,
    dateRange,
    datesToRender,
    staffSchedulerAPI,
  } = useAppSelector((state) => state.staffSchedule);
  const { data: cabinetsDictionary = [] as CabinetDictionaryItem[] } = useGetCabinetsDictionaryQuery();
  if (!!sessionsMatches.length && dateRange[1] && selectedCabinets.length > 0) {
    const actualCabinetsIDs = [...new Set(sessionsMatches.map((sessionsMatch) => sessionsMatch[1]).flat(1))];
    const actualCabinetsNames = cabinetsDictionary
      .map((cabinet) => {
        if (actualCabinetsIDs.includes(cabinet.id.toString())) {
          return cabinet.title;
        }
      })
      .filter((e) => e);

    const timePeriodString = getMatchesWarningWindowMessage(
      sessionsMatches,
      showEvenDates,
      showOddDates,
      tableColumnModel,
      actualCabinetsIDs,
      staffSchedulerAPI,
    );

    return (
      <MatchesWarningContainer>
        <WarningIcon />
        <p>
          {'Совпадение по времени в кабинетах ' +
            `${actualCabinetsNames.map((cabinetName) => ` ${cabinetName}`)}` +
            `${' на период ' + timePeriodString}`}
        </p>
        {isWatchingMatches ? (
          <span onClick={() => dispatch(setIsWatchingMatches(false))}>Скрыть</span>
        ) : (
          <span
            onClick={() => {
              dispatch(setIsWatchingMatches(true));
              scrollTableToDate(sessionsMatches[0][0], datesToRender, selectedCabinets);
              getMissingCabinets(
                sessionsMatches[currentSessionsMatch][1],
                selectedCabinets,
                cabinetsDictionary,
                dispatch,
              );
            }}
          >
            Посмотреть
          </span>
        )}
      </MatchesWarningContainer>
    );
  } else {
    return null;
  }
};

export default memo(StaffScheduleMatchesWarning);
