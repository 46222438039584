import styled from 'styled-components';
import { Container } from './Container';
import React from 'react';

type OutlinedButtonProps = {
  width: number;
  noBorder?: boolean;
  disabled?: boolean;
};

const OutlinedButton = styled.div<OutlinedButtonProps>`
  margin-top: 24px;
  width: ${({ width }) => width}px;
  border-radius: 4px;
  border: ${({ disabled, noBorder = false }) => (noBorder ? 0 : disabled ? '1px solid #D3D6DA' : '1px solid #577bf9')};
  color: ${({ disabled }) => (disabled ? '#A8AEB5' : '#577bf9')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
`;

// eslint-disable-next-line react/display-name
export default function ({ children, ...props }) {
  return (
    <OutlinedButton width={props.width} noBorder={props.noBorder} disabled={props.disabled} {...props}>
      <Container height={props.height}>{children}</Container>
    </OutlinedButton>
  );
}
