import { InvoiceRow } from '../../../../services/invoice';
import { DEFAULT_PAYER, DEFAULT_PAYMENT_TYPE, STARTED_AMOUNT } from '../constants';
import { last } from 'lodash';

export const getOperationsList = (row: InvoiceRow) => {
  const newOperation = {
    id: Math.random().toString(36).substring(2),
    amountForPay: row.payment,
    payment: STARTED_AMOUNT,
    payer: DEFAULT_PAYER,
    payment_type: DEFAULT_PAYMENT_TYPE,
    rowId: row.id,
    isNew: true,
  };
  // если у позиции в счете нет совершенных операций, добавляем строку для новой операции
  if (!row.paymentOperations.length) return [newOperation];

  //  а иначе к текущим операция добавляем строку с остатком к оплате
  const lastOperation = last(row.paymentOperations);
  const lastReturned = Boolean(lastOperation.return_status);
  const lastPayment = lastReturned ? -lastOperation.payment : lastOperation.payment;

  const rowRemainder = lastPayment - lastOperation.amountForPay;

  if (rowRemainder < STARTED_AMOUNT) {
    return [...row.paymentOperations, { ...newOperation, amountForPay: -rowRemainder }];
  }

  // если позиция полностью оплачена, возвращаем список проведенных операций
  return row.paymentOperations;
};
