import React, { MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import {
  useAsyncDebounce,
  useColumnOrder,
  useExpanded,
  useFilters,
  useRowState,
  useTable,
  UseTableHooks,
} from 'react-table';
import { MenuItem } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { CancelIcon, DotsIcon, OkIcon } from '../../uikit/Icons';
import { Column, TableAction, TableProps } from './types';
import {
  AddEntry,
  StyledAddEntryIcon,
  StyledTable,
  StyledTableRow,
  TableActionButton,
  TableActionMenu,
  TableActions,
  TableCell,
  TableNoData,
  TableWrapper,
} from './styles';
import { DefaultColumnFilter, EditableCell } from './components';
import Tooltip from '../Tooltip/Tooltip';

const emptyFunction = () => ({});

export function Table<T extends Record<string, unknown>>(props: TableProps<T>) {
  const {
    columns,
    data,
    onFetchData,
    setData,
    canAddNewEntry = false,
    canEditEntry = false,
    canDeleteEntry = false,
    canFocusRow = false,
    manualFilters = false,
    actions = [],
    noDataText = '',
    hiddenColumns = [],
    isLoading = false,
    isAllRowsExpanded = false,
    onNewEntry,
    onEditEntry,
    onDeleteEntry,
    onStartEdit = emptyFunction,
    onStopEdit = emptyFunction,
    visibleRowCount = 'all',
    disabledRowResolver,
    disabledRowIcon,
    disabledRowTooltipText,
    rowBackground,
    getHeaderProps = emptyFunction,
    getFooterProps = emptyFunction,
    renderRowSubComponent,
    hasFooter = false,
    canAutoHeight = true,
    rowTooltipText,
    getHeadRef,
    shouldHighlightRow,
    shouldDisplayCellTooltip,
    cellTooltipContent,
    anchorMenuPosition,
    changeColumnOrder,
    noScrollbars = false,
  } = props;

  const theadRef = useRef<HTMLTableSectionElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [focusedFilter, setFocusedFilter] = useState<string | null>(null);
  const [addEntryTop, setAddEntryTop] = useState<number>(108);
  const [editEntryIndex, setEditEntryIndex] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isNewEntry, setIsNewEntry] = useState<boolean>(false);
  const [successRowIndex, setSuccessRowIndex] = useState<number | null>(null);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  const defaultColumn = useMemo<Partial<Column<T>>>(
    () => ({
      Cell: EditableCell,
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const useActions = (hooks: UseTableHooks<T>) => {
    if (actions.length === 0 && !canEditEntry && !canDeleteEntry) {
      return;
    }

    hooks.visibleColumns.push((columns: Column<T>[]) => {
      return [
        ...columns,
        {
          id: 'actions',
          Header: '',
          Cell: ({ row }) => {
            const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
            const open = Boolean(anchorEl);
            const { index: rowIndex, state, setState, values, cells, original } = row;
            const rowActions: TableAction<T>[] = state.edit
              ? []
              : [...actions.filter(({ isHidden }) => !isHidden || !isHidden(row))];
            const isRowDisabled = Boolean(disabledRowResolver && disabledRowResolver(row));

            const handleClick = (event: React.MouseEvent<HTMLElement>) => {
              setAnchorEl(event.currentTarget);
            };
            const handleClose = () => {
              setAnchorEl(null);
            };
            const handleFocusRow = () => {
              if (anchorEl) {
                row.parentRowElement = anchorEl.offsetParent;
              }
            };
            const finishEditing = (success = true) => {
              setState((old) => ({ ...old, edit: false }));
              setIsEditing(false);
              setIsNewEntry(false);
              if (success) {
                setSuccessRowIndex(rowIndex);
                setTimeout(() => {
                  setSuccessRowIndex(null);
                }, 500);
              }
            };

            if (canEditEntry && !state.edit && !isRowDisabled) {
              rowActions.push({
                label: 'Редактировать',
                onClick: () => {
                  setEditEntryIndex(rowIndex);
                  setState((old) => ({ ...old, edit: true }));
                  cells.forEach(({ setState }) => {
                    setState((old) => ({ ...old, error: false }));
                  });
                  setIsEditing(true);
                  onStartEdit(values.id);
                },
              });
            }

            if (canDeleteEntry && !state.edit && !isRowDisabled) {
              rowActions.push({
                label: 'Удалить',
                onClick: async () => {
                  if (setData) {
                    setData((old) => [...old.slice(0, rowIndex), ...old.slice(rowIndex + 1)]);
                  }
                  if (onDeleteEntry) {
                    await onDeleteEntry(data[rowIndex]);
                  }
                },
              });
            }

            return (
              <TableActions>
                {state.edit ? (
                  <>
                    <TableActionButton
                      size={'small'}
                      onClick={async () => {
                        const newData = {
                          ...values,
                          ...Object.keys(state.cellState)
                            .filter((key) => state.cellState[key].value !== undefined)
                            .reduce((acc, key) => ({ ...acc, [key]: state.cellState[key].value }), {}),
                        };

                        const validatedColumns = columns.reduce((acc, column) => {
                          let isValid = column.required ? !!newData[column.id] : true;
                          let message = 'Обязательное поле';
                          if (column.validate) {
                            const valid = column.validate(newData[column.id]);
                            isValid = valid.isValid;
                            message = valid.message;
                          }
                          return {
                            ...acc,
                            [column.id]: { isValid, message },
                          };
                        }, {});
                        const isValid = Object.keys(validatedColumns).every((key) => validatedColumns[key].isValid);

                        if (isValid) {
                          if (state.new && onNewEntry) {
                            await onNewEntry({ ...newData, position_idx: rowIndex + 1 }, finishEditing);
                          }
                          if (state.edit && !state.new && onEditEntry) {
                            await onEditEntry(newData, finishEditing);
                            onStopEdit(values.id);
                          }
                          if (setData) {
                            setData((old: T[]) =>
                              old.map((row, index) => {
                                if (index === rowIndex) {
                                  return newData;
                                }
                                return row;
                              }),
                            );
                          }
                          /* Если задан какой-нибудь из обработчиков, то  управлять окончанием редактирования строки
                               таблицы нужно функцией finishEditing переданной как аргумент обработчика. */
                          if (!onNewEntry && !onEditEntry) {
                            finishEditing();
                          }
                        } else {
                          cells
                            .filter(({ column: { id } }) => !!validatedColumns[id])
                            .forEach(({ column: { id }, setState }) => {
                              setState((old) => ({
                                ...old,
                                error: !validatedColumns[id].isValid || false,
                                message: validatedColumns[id].message || '',
                              }));
                            });
                        }
                      }}
                      disableRipple
                    >
                      <OkIcon />
                    </TableActionButton>
                    <TableActionButton
                      size={'small'}
                      onClick={() => {
                        if (state.new) {
                          if (setData) {
                            setData((old) => [...old.slice(0, rowIndex), ...old.slice(rowIndex + 1)]);
                          }
                        } else {
                          cells.forEach(({ column: { id }, setState: setCellState }) => {
                            setCellState((old) => {
                              return { ...old, value: values[id] };
                            });
                          });
                          onStopEdit(values.id);
                        }
                        finishEditing(false);
                      }}
                      disableRipple
                    >
                      <CancelIcon />
                    </TableActionButton>
                  </>
                ) : (
                  <>
                    {rowActions.length > 0 && (
                      <>
                        <Tooltip
                          title={(!isRowDisabled && rowTooltipText) || (isRowDisabled && disabledRowTooltipText) || ''}
                          placement={'bottom-end'}
                          enterDelay={500}
                          enterNextDelay={500}
                          dark
                        >
                          <TableActionButton size={'small'} onClick={handleClick} disableRipple>
                            {isRowDisabled && disabledRowIcon ? disabledRowIcon : <DotsIcon />}
                          </TableActionButton>
                        </Tooltip>
                        <TableActionMenu
                          id="action-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          getContentAnchorEl={null}
                          anchorOrigin={anchorMenuPosition && anchorMenuPosition.anchor}
                          transformOrigin={anchorMenuPosition && anchorMenuPosition.transform}
                        >
                          {rowActions.map(({ label, onClick }, idx) => (
                            <MenuItem
                              key={idx}
                              className={original.editor ? 'disabled' : ''}
                              onClick={() => {
                                if (canFocusRow) {
                                  handleFocusRow();
                                }
                                if (!original.editor) {
                                  onClick(row);
                                }
                                handleClose();
                              }}
                            >
                              {!!original.editor ? (
                                <Tooltip
                                  title={
                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'flex-start' }}>
                                      <img style={{ marginTop: 2 }} src="./img/black/info-circle.svg" alt="attention" />
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div>{`Редактирует ${original.editor}`}</div>
                                        <div>Одновременная работа недоступна</div>
                                      </div>
                                    </div>
                                  }
                                  placement={'bottom-end'}
                                  enterDelay={500}
                                  enterNextDelay={500}
                                >
                                  <span>{label}</span>
                                </Tooltip>
                              ) : (
                                label
                              )}
                            </MenuItem>
                          ))}
                        </TableActionMenu>
                      </>
                    )}
                  </>
                )}
              </TableActions>
            );
          },
          collapse: true,
        },
      ];
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setColumnOrder,
    toggleAllRowsExpanded,
    state: { pageIndex, pageSize, sortBy, filters },
    setRowState,
    visibleColumns,
  } = useTable<T>(
    {
      columns,
      data,
      defaultColumn,
      manualFilters,
      autoResetRowState: false,
      initialState: {
        hiddenColumns,
      },
    },
    useFilters,
    useRowState,
    useActions,
    useExpanded,
    useColumnOrder,
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onFetchDataDebounced = onFetchData && useAsyncDebounce(onFetchData, 200);

  const scrollbarsRef = useRef(null);

  const autoHeightMax: number = (() => {
    if (!tableRef.current) {
      return 0;
    }

    let rowsCount: number = data.length;

    if (typeof visibleRowCount === 'number' && data.length > visibleRowCount) {
      rowsCount = visibleRowCount;
    }

    const th = tableRef.current.getElementsByTagName('th')[0];
    const td = tableRef.current.getElementsByTagName('td')[0];

    return th.offsetHeight + td.offsetHeight * rowsCount;
  })();

  useEffect(() => {
    if (isAllRowsExpanded) {
      toggleAllRowsExpanded(true);
    }
  }, [data, isAllRowsExpanded, toggleAllRowsExpanded]);

  useEffect(() => {
    if (onFetchDataDebounced) {
      onFetchDataDebounced({ pageIndex, pageSize, sortBy, filters });
    }
  }, [onFetchDataDebounced, pageIndex, pageSize, sortBy, filters, data]);

  useEffect(() => {
    if (theadRef.current) {
      //setAddEntryTop(theadRef.current.offsetHeight - 2);
      getHeadRef?.(theadRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theadRef.current, setAddEntryTop]);

  useEffect(() => {
    if (isEditing) {
      setRowState([editEntryIndex], (old) => ({ ...old, edit: isEditing, new: isNewEntry }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows.length, isEditing, isNewEntry]);

  useEffect(() => {
    if (changeColumnOrder) {
      const columnIds = visibleColumns.map((column) => column.id).slice(0, visibleColumns.length - 2);
      setColumnOrder([...columnIds, 'actions', 'comments']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeColumnOrder]);

  const mouseOverFilterHandler =
    (id: string): MouseEventHandler<HTMLTableHeaderCellElement> =>
    (ev) => {
      if (
        ((ev.target && (ev.target as HTMLElement).tagName === 'DIV') ||
          (ev.target && (ev.target as HTMLElement).tagName === 'INPUT') ||
          (ev.target && (ev.target as HTMLElement).tagName === 'BUTTON')) &&
        ev.relatedTarget &&
        (ev.relatedTarget as HTMLElement).tagName === 'TH'
      ) {
        setActiveFilter(id);
      }
    };

  const mouseOutFilterHandler: MouseEventHandler<HTMLTableHeaderCellElement> = (ev) => {
    if (ev.target && (ev.target as HTMLElement).tagName === 'INPUT') {
      setActiveFilter(null);
    }
  };

  const isColumnFilterSet = (columnId: string): boolean => {
    return !!filters.find((filter) => filter.id === columnId);
  };

  const mouseLeaveFilterHandler = (id: string) => () => {
    if (activeFilter === id && focusedFilter !== id && !isColumnFilterSet(id)) {
      setActiveFilter(null);
    }
  };

  const focusFilterHandler = (id: string) => () => {
    setFocusedFilter(id);
  };

  const blurFilterHandler = () => {
    setFocusedFilter(null);
  };

  const mouseMoveTableHandler: MouseEventHandler<HTMLTableSectionElement> = (ev) => {
    if (isEditing || isScrolling) {
      return;
    }
    const rect = ev.currentTarget.getBoundingClientRect();
    const posY = ev.pageY - rect.top - window.pageYOffset;
    const td = (ev.target as HTMLTableElement).closest('td');
    if (!td) {
      return;
    }
    const rowHeight = td.offsetHeight;
    const rowIndex = Math.floor((posY + rowHeight / 2) / rowHeight);

    const top = theadRef.current.offsetHeight - (scrollbarsRef.current.viewScrollTop || 0) + rowIndex * rowHeight - 2;
    if (top + 2 >= theadRef.current.offsetHeight && rowIndex > 0) {
      setAddEntryTop(top);
      setEditEntryIndex(rowIndex);
    }
  };

  const addNewEntryHandler = () => {
    if (setData) {
      setData((old: T[]) => [...old.slice(0, editEntryIndex), {} as T, ...old.slice(editEntryIndex)]);
    }
    setIsEditing(true);
    setIsNewEntry(true);
  };

  const StyleTableComponent = (
    <StyledTable {...getTableProps()} ref={tableRef}>
      <thead ref={theadRef}>
        {headerGroups.map((headerGroup) => (
          <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              const showFilter: boolean =
                column.canFilter &&
                !isLoading &&
                !isEditing &&
                (activeFilter === column.id || focusedFilter === column.id || isColumnFilterSet(column.id));
              return (
                <th
                  key={column.id}
                  {...column.getHeaderProps([
                    {
                      className: (column as Column<T>).collapse ? 'collapsed' : '',
                      style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                    },
                    getHeaderProps(column),
                  ])}
                  onMouseOver={mouseOverFilterHandler(column.id)}
                  onMouseOut={mouseOutFilterHandler}
                  onMouseLeave={mouseLeaveFilterHandler(column.id)}
                >
                  <div style={{ visibility: showFilter ? 'hidden' : 'visible', height: showFilter ? 0 : '100%' }}>
                    {column.render('Header')}
                  </div>
                  {column.canFilter && (
                    <div
                      style={{
                        visibility: showFilter ? 'visible' : 'hidden',
                        height: showFilter ? '100%' : 0,
                        fontSize: 0,
                        lineHeight: 0,
                      }}
                    >
                      {column.render('Filter', {
                        onFocus: focusFilterHandler(column.id),
                        onBlur: blurFilterHandler,
                      })}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} style={{ position: 'relative' }} onMouseMove={mouseMoveTableHandler}>
        {rows.length ? (
          rows.map((row, index) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            const isEditingRow = isEditing && row.index === editEntryIndex;
            const isRowDisabled = Boolean(disabledRowResolver && disabledRowResolver(row));
            const isRowHighlighted = shouldHighlightRow && shouldHighlightRow(row);
            const isCellTooltipDisplayed = (column) =>
              shouldDisplayCellTooltip && shouldDisplayCellTooltip(row, column);
            return (
              <>
                <StyledTableRow
                  key={row.index}
                  {...rowProps}
                  isSuccessEditing={false}
                  className={row.index === successRowIndex ? 'new_staffer2' : ''}
                  rowBackground={index % 2 !== 0 && rowBackground}
                  isRowHighlighted={isRowHighlighted}
                >
                  {row.cells.map((cell) =>
                    isCellTooltipDisplayed(cell.column) ? (
                      <Tooltip
                        key={cell.column.id}
                        title={cellTooltipContent && cellTooltipContent(row)}
                        placement="bottom-start"
                        enterDelay={500}
                        enterNextDelay={500}
                      >
                        <TableCell
                          {...cell.getCellProps({
                            className: (cell.column as Column<T>).collapse ? 'collapsed' : '',
                          })}
                          isEditing={isEditingRow}
                          isLoading={isLoading}
                          isDisabled={isRowDisabled}
                          style={{ backgroundColor: index % 2 !== 0 ? rowBackground : 'white' }}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell
                        {...cell.getCellProps({
                          className: (cell.column as Column<T>).collapse ? 'collapsed' : '',
                        })}
                        isEditing={isEditingRow}
                        isLoading={isLoading}
                        isDisabled={isRowDisabled}
                        style={{ backgroundColor: index % 2 !== 0 ? rowBackground : 'white' }}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ),
                  )}
                </StyledTableRow>
                {row.isExpanded && renderRowSubComponent && renderRowSubComponent(row, rowProps)}
              </>
            );
          })
        ) : (
          <tr>
            {visibleColumns.map((column) => {
              return (
                <td key={column.id} className={'noData'}>
                  {''}
                </td>
              );
            })}
          </tr>
        )}
      </tbody>
      <tfoot>
        {hasFooter &&
          footerGroups.map((group) => (
            <tr key={group.id} {...group.getFooterGroupProps()}>
              {group.headers.map(
                (column) =>
                  column.accessor && (
                    <th {...column.getFooterProps(getFooterProps(column))}>{column.render('Footer')}</th>
                  ),
              )}
            </tr>
          ))}
      </tfoot>
    </StyledTable>
  );

  return (
    <TableWrapper canAddNewEntry={canAddNewEntry}>
      {canAddNewEntry && !isEditing && !isLoading && <AddEntry top={addEntryTop} />}
      {canAddNewEntry && !isEditing && !isLoading && (
        <StyledAddEntryIcon top={addEntryTop} onClick={addNewEntryHandler} />
      )}

      {noScrollbars ? (
        StyleTableComponent
      ) : (
        <Scrollbars
          autoHeight
          autoHeightMax={canAutoHeight ? autoHeightMax : Number.MAX_VALUE}
          ref={scrollbarsRef}
          onScrollStart={() => {
            setIsScrolling(true);
          }}
          onScrollStop={() => {
            setIsScrolling(false);
          }}
        >
          {StyleTableComponent}
        </Scrollbars>
      )}

      {data.length === 0 && <TableNoData>{noDataText || 'Нет данных'}</TableNoData>}
    </TableWrapper>
  );
}
