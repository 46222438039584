import { DRAG_END, DRAG_START, DRAGING } from "./types";

const INITIAL_STATE = {
  mouse: { x: 0, y: 0 },
  start: false,
  event:{},
  initial: {},
  width:200,
  height:400,
  type:"",
  color:""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DRAG_START:
      return {
        ...state,
        ...{
          start: true,
          top:action.payload.top,
          left:action.payload.left,
          initial: { x: action.payload.e.pageX, y: action.payload.e.pageY },
          mouse: { x: action.payload.e.pageX, y: action.payload.e.pageY },
          width:action.payload.width,
          height:action.payload.height,
          type:action.payload.type,
          color:action.payload.color,
          data:action.payload.data
        }
      };
    case DRAG_END:
      return INITIAL_STATE;
    case DRAGING:
      return {
        ...state,
        ...{ mouse: action.payload }
      };
    default:
      return state;
  }
}
