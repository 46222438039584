/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import {
  Selector,
  SelectedOption,
  SelectedInput,
  OptionsContainer,
  Option,
  SearchInput,
  Circle,
  Divider,
  Search,
  SecondaryText,
  SecondaryLink,
  EmptySelectPlaceholder,
} from '../DoctorMultiselect/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { VisitStatusIcon } from '../global/visitStatus';
import SearchIcon from '../modals/VisitCard/icons/SearchIcon';
import Scrollbars from 'react-custom-scrollbars-2';
import { IMultiSelectProps, IValuesState } from './types';
import { onOptionClick } from './helpers';
import { setCabinets, setStatuses } from '../reducer';
import { useGetPermissionsQuery } from '../../../services/users';
import { useNavigate } from 'react-router-dom';
import { routerConfig } from '../../../navigation/routerConfig';

const MultiSelect: FC<IMultiSelectProps> = ({
  placeholder,
  searchPlaceholder,
  initialValue,
  values,
  width,
  autoHeight = false,
  children,
  statuses,
  search,
  name,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState<IValuesState[]>(initialValue);

  const { common } = useAppSelector((state) => state);

  const { data: permissions } = useGetPermissionsQuery(common.user.id);

  const searchedValues = searchedValue
    ? values.filter((el) => el.name.toLowerCase().includes(searchedValue.toLowerCase()))
    : values;
  const selectedOptions = selectedValues.length ? selectedValues?.map((el) => el?.id) : [];
  const nameValuesEnum = { CABINET: 'cabinet', STATUS: 'status' };

  const saveData = (name) => {
    switch (name) {
      case nameValuesEnum.CABINET:
        dispatch(setCabinets(selectedValues));
        break;
      case nameValuesEnum.STATUS:
        dispatch(setStatuses(selectedValues));
        break;
    }
    setOpen(false);
  };

  useEffect(() => {
    saveData(name);
  }, [selectedValues]);

  useEffect(() => {
    if (initialValue.length !== searchedValues.length) setSelectedValues(initialValue);
  }, [initialValue]);

  const handleCancelClick = () => {
    setSelectedValues([]);
    saveData(name);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Selector width={width} style={{ marginRight: 10 }}>
        <SelectedOption onClick={() => setOpen(!isOpen)}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {selectedValues?.length ? (
                <CancelIcon
                  onClick={handleCancelClick}
                  style={{ color: '#577BF9', fontSize: '16px', marginRight: '4px' }}
                />
              ) : (
                <></>
              )}
              <SelectedInput
                disabled
                placeholder={placeholder ? placeholder : 'Выбрать'}
                value={selectedValues?.length ? selectedValues[0]?.name || selectedValues[0]?.title : ''}
                width={selectedValues?.length && (selectedValues[0].name?.length || selectedValues[0].title?.length)}
              />
              {selectedValues?.length > 1 ? (
                <div style={{ display: 'flex' }}>
                  <Circle>+{selectedValues?.length - 1}</Circle>
                </div>
              ) : (
                <></>
              )}
            </div>
            <KeyboardArrowDownIcon style={{ fontSize: '20px' }} />
          </div>
        </SelectedOption>
        {isOpen && (
          <OptionsContainer width={width}>
            {values.length === 0 && name === 'cabinet' ? (
              <EmptySelectPlaceholder paddingX={17}>
                {permissions?.edit_clinic_profile ? (
                  <>
                    <SecondaryText>Для настройки кабинетов перейдите на страницу </SecondaryText>
                    <SecondaryLink onClick={() => navigate(routerConfig.PROFILE.path)}>
                      настройки профиля.
                    </SecondaryLink>
                  </>
                ) : (
                  <SecondaryText>Нет кабинетов.</SecondaryText>
                )}
              </EmptySelectPlaceholder>
            ) : (
              <>
                {search && (
                  <>
                    <Search>
                      <SearchInput
                        value={searchedValue}
                        onChange={(event) => setSearchedValue(event.target.value)}
                        placeholder={searchPlaceholder ? searchPlaceholder : 'Поиск'}
                      />
                      <SearchIcon />
                    </Search>
                    <Divider />
                  </>
                )}

                <Scrollbars style={{ width: '100%', height: autoHeight ? `${searchedValues.length * 28}px` : '105px' }}>
                  {values
                    ? searchedValues.map((value) => (
                        <Option
                          key={value.id}
                          selected={selectedOptions.includes(value.id)}
                          onClick={() => onOptionClick(value, selectedValues, setSelectedValues, selectedOptions)}
                        >
                          {statuses && (
                            <div
                              style={{
                                width: '24px',
                                display: 'flex',
                                justifyContent: 'center',
                                marginRight: '3px',
                              }}
                            >
                              <VisitStatusIcon value={value.id} />
                            </div>
                          )}
                          <div style={{ overflow: 'hidden' }}>{value.name}</div>
                        </Option>
                      ))
                    : { children }}
                </Scrollbars>
              </>
            )}
          </OptionsContainer>
        )}
      </Selector>
    </ClickAwayListener>
  );
};

export default MultiSelect;
