import React from "react";
import styled from "styled-components";

const Container = styled.span<{color: string, backgroundColor: string}>`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0 8px;
  border-radius: 20px;
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`
type Props = {
  text: string;
  color: string;
  backgroundColor: string
}

export function Status({text, color, backgroundColor}: Props) {
  return (
    <Container color={color} backgroundColor={backgroundColor}>
      {text}
    </Container>
  )
}
