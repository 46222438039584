import React, { useState } from 'react';
import { TableContainer } from '../styles';
import { connect } from 'react-redux';
import { addRowToPrice, updatePriceEntry } from '../operations';
import Body from './Body';
import { Scrollbars } from 'react-custom-scrollbars-2';
import AllPriceEntries from './AllPriceEntries';
import AddEntry from '../NewPriceEntryButton/AddEntry';
import { RootState } from '../../../store';

function PriceTable({ priceSlice, sessions, onStartEdit, onStopEdit }) {
  const { pickedCategory } = priceSlice;
  const [scrollTop, setScrollTop] = useState(0);

  return (
    <TableContainer>
      <div>
        <AddEntry scrollTop={scrollTop} />
        <div style={{ width: '100%', height: 51, position: 'relative', zIndex: 1 }} />
        <Scrollbars
          onScroll={(p) => setScrollTop(p.target.scrollTop)}
          style={{
            width: '100%',
            height: 'calc(100vh - 307px)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-end',
              position: 'relative',
            }}
          >
            <div>
              {pickedCategory.id === 'default' ? (
                <AllPriceEntries sessions={sessions} onStartEdit={onStartEdit} onStopEdit={onStopEdit} />
              ) : (
                <Body
                  group={pickedCategory.id}
                  groupIndex={0}
                  sessions={sessions}
                  onStartEdit={onStartEdit}
                  onStopEdit={onStopEdit}
                />
              )}
            </div>
          </div>
        </Scrollbars>
      </div>
    </TableContainer>
  );
}

export default connect((state: RootState) => ({ priceSlice: state.priceSlice }), {
  updatePriceEntry,
  addRowToPrice,
})(PriceTable);
