import React, { ChangeEvent, FC, memo, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnFooter } from './styles';
import { BorderAutocomplete } from './components/Autocomplete/Autocomplete';
import TextInput from '../../../uikit/TextInput';
import {
  PaymentTypeCodes,
  useAddPaymentTypeMutation,
  useDeletePaymentTypeMutation,
  useEditPaymentTypeMutation,
  useGetPaymentTypesQuery,
} from '../../../services/dictionaries';
import styled from 'styled-components';
import { getMask } from '../../../utils/masks';
import { STARTED_AMOUNT } from './constants';
import { ValidationMessage } from '../../../uikit/ValidationMessage';
import { Modal } from '../../../uikit/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import PaymentAccountSlice from './state';

type FooterTotalRowProps = {
  containerMargin: number;
  theadEl: null | HTMLElement;
  isFirstRow?: boolean;
  isPrepayRow?: boolean;
  isTotalPaidRow?: boolean;
  totalForPay?: number;
  title: string;
  hasBalanceChanged?: boolean;
  totalRemainder?: number;
  totalPaidAmount?: number;
  setAmountInputValue?: (value: number) => void;
  paymentType?: number;
  payer?: string;
  changePaymentTypeHandler?: (value: number) => void;
  changePayerHandler?: (value: string) => void;
  value?: number;
  onCalculatePaidAmount?: (total: number) => void;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
};
type RowContainerProps = {
  isFirstRow?: boolean;
};
const RowContainer = styled.div<RowContainerProps>`
  display: flex;
  align-items: center;
  border-top: ${({ isFirstRow }) => (isFirstRow ? '0' : '1px solid #EFF0F3')};
  padding: 12px 0;
`;

export const FooterTotalRow: FC<FooterTotalRowProps> = memo(
  ({
    containerMargin,
    theadEl,
    isFirstRow,
    title,
    isPrepayRow,
    isTotalPaidRow,
    hasBalanceChanged,
    totalForPay,
    totalRemainder,
    setAmountInputValue,
    totalPaidAmount,
    paymentType,
    payer,
    changePaymentTypeHandler,
    changePayerHandler,
    value,
    onCalculatePaidAmount,
    containerRef,
  }) => {
    const dispatch = useDispatch();

    const [isEmptyPaymentType, setIsEmptyPaymentType] = useState<boolean>(false);
    const [amount, setAmount] = useState<number>(STARTED_AMOUNT);
    const [anchorModalError, setAnchorModalError] = useState<null | HTMLElement>(null);

    const advanceAmount = useSelector((state: RootState) => state.PaymentAccountSlice.advanceAmount);
    const { setAdvanceAmount } = PaymentAccountSlice.actions;

    const ref = useRef(null);

    const { data: paymentTypes = [] } = useGetPaymentTypesQuery();

    const [addPaymentType] = useAddPaymentTypeMutation();
    const [editPaymentType] = useEditPaymentTypeMutation();
    const [deletePaymentType] = useDeletePaymentTypeMutation();

    const operationId = useMemo(() => Math.random().toString(36).substring(2), []);

    const dimensions = useMemo(
      () => ({
        titleMargin: theadEl?.firstChild?.childNodes[5].getBoundingClientRect().left - (containerMargin + 22),
        titleWidth: theadEl?.firstChild.childNodes[5].clientWidth - 26,
        amountForPayWidth: theadEl?.firstChild.childNodes[6].clientWidth - 26,
        paymentTypeWidth: theadEl?.firstChild.childNodes[7].clientWidth - 26,
        payerWidth: theadEl?.firstChild.childNodes[8].clientWidth - 24,
        totalAmountWidth: theadEl?.firstChild.childNodes[9].clientWidth - 24,
        totalRemainderWidth: theadEl?.firstChild.childNodes[10].clientWidth,
      }),
      [theadEl, containerMargin],
    );

    const handleAmountValueChange = (e: ChangeEvent<HTMLInputElement>) => {
      setAmount(+getMask('count').resolve(`${+e.target.value}`));
    };

    const handleBlur = () => {
      if (!paymentType) {
        setIsEmptyPaymentType(true);
        setAmount(STARTED_AMOUNT);
        return;
      }
      //проверяем на случай, если тип оплаты Аванс
      const currentPaymentType = paymentTypes.find((type) => type.id === paymentType);
      if (currentPaymentType && currentPaymentType.code === PaymentTypeCodes.PREPAID) {
        if (advanceAmount < amount) {
          setAnchorModalError(ref.current);
          return;
        }
        if (anchorModalError) setAnchorModalError(null);
        dispatch(setAdvanceAmount(advanceAmount - amount));
      }

      setAmountInputValue(amount);
      onCalculatePaidAmount && onCalculatePaidAmount(amount);
    };

    const showTotalAmount = () => {
      if (hasBalanceChanged) {
        return null;
      }
      if (!isTotalPaidRow) {
        return (
          <>
            <TextInput
              ref={ref}
              style={{ height: 24, fontSize: 12 }}
              value={amount}
              onChange={handleAmountValueChange}
              onBlur={handleBlur}
              error={anchorModalError}
            />
            <Modal anchor={anchorModalError} placementModal={'bottom-start'} unSetContainer={true} zIndex={1400}>
              <ValidationMessage arrowMarginLeft={16} arrowMarginRight={196}>
                Сумма больше чем в авансе
              </ValidationMessage>
            </Modal>
          </>
        );
      }
      return <span style={{ paddingLeft: 8 }}>{getMask('number_format').resolve(`${totalPaidAmount}`)}</span>;
    };

    const showTotalRemainder = () => {
      const colorRemainder = () => {
        if (hasBalanceChanged || totalRemainder > STARTED_AMOUNT) return '#0D853D';
        if (totalRemainder < STARTED_AMOUNT) return '#E54770';
        return '#253446';
      };
      if (isFirstRow || hasBalanceChanged) {
        return (
          <span style={{ color: colorRemainder() }}>
            {totalRemainder < STARTED_AMOUNT && <span>-</span>}
            {getMask('number_format').resolve(`${totalRemainder}`)}
          </span>
        );
      }
      return null;
    };

    const handlePayerChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!paymentType) {
        setIsEmptyPaymentType(true);
        return;
      }
      changePayerHandler(e?.target?.value);
    };

    useEffect(() => {
      if (paymentType && isEmptyPaymentType) {
        setIsEmptyPaymentType(false);
      }
    }, [paymentType, isEmptyPaymentType]);

    useEffect(() => {
      setAmount(value);
    }, [value]);

    return (
      <RowContainer isFirstRow={isFirstRow}>
        <ColumnFooter
          marginLeft={dimensions.titleMargin}
          width={dimensions.titleWidth}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {title}:
        </ColumnFooter>
        <ColumnFooter marginLeft={28} width={dimensions.amountForPayWidth}>
          {isFirstRow ? getMask('number_format').resolve(`${totalForPay}`) : null}
        </ColumnFooter>
        <ColumnFooter width={dimensions.paymentTypeWidth}>
          {isTotalPaidRow ? null : (
            <BorderAutocomplete
              editable
              maxLength={21}
              placeholder="Способ оплаты"
              baseValue={paymentType}
              onSetValue={changePaymentTypeHandler}
              items={paymentTypes}
              onAddItem={addPaymentType}
              onEditItem={editPaymentType}
              onDeleteItem={deletePaymentType}
              errorCondition={isEmptyPaymentType}
              setErrorCondition={setIsEmptyPaymentType}
              operationId={operationId}
              containerRef={containerRef}
            />
          )}
        </ColumnFooter>
        <ColumnFooter width={dimensions.payerWidth}>
          {isPrepayRow && <TextInput style={{ height: 24, fontSize: 12 }} value={payer} onChange={handlePayerChange} />}
        </ColumnFooter>
        <ColumnFooter width={dimensions.totalAmountWidth}>{showTotalAmount()}</ColumnFooter>
        <ColumnFooter width={dimensions.totalRemainderWidth}>{showTotalRemainder()}</ColumnFooter>
      </RowContainer>
    );
  },
);
FooterTotalRow.displayName = 'FooterTotalRow';
