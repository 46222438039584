import { addMonths } from "date-fns";
import { LOAD_SCHEDULE } from "../calendar/actions_calendar";
import {
  SET_PAGINATOR,
  SET_DAY,
  SET_DAYS,
  SET_PERIOD,
  SET_MONTH_STRING,
  RELOAD_SCHEDULE_STATE,
  SET_WEEKDAYS,
  SET_REF,
  FLUSH_REFS,
  SET_ODD_OR_EVEN,
  INCLUDE_DAY,
  EXCLUDE_DAY,
  SET_INITIAL,
} from "./schedule";
import { FLUSH_STATE } from "../flushState/types";
const INITIAL_STATE = {
  array: [[]],
  paginator: { page: 0, length: 0 },
  day: new Date().toDateString(),
  days: [[]],
  weekDays: [],
  dates: { include: [], exclude: [] },
  isOdd: false,
  isEven: false,
  period: {
    start: new Date().toDateString(),
    end: addMonths(new Date(), 2).toDateString(),
  },
  monthString: "",
  refs: {},
  initial: true,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_SCHEDULE:
      return {
        ...state,
        ...{ array: action.payload },
      };
    case SET_PAGINATOR:
      return {
        ...state,
        ...{ paginator: action.payload },
      };
    case SET_DAY:
      return {
        ...state,
        ...{ day: action.payload.toDateString() },
      };
    case SET_DAYS:
      return {
        ...state,
        ...{ days: action.payload },
      };
    case SET_WEEKDAYS:
      return {
        ...state,
        ...{ weekDays: action.payload },
      };
    case SET_PERIOD:
      return {
        ...state,
        ...{ period: action.payload },
      };
    case SET_MONTH_STRING:
      return {
        ...state,
        ...{ monthString: action.payload },
      };
    case RELOAD_SCHEDULE_STATE:
      return {
        ...state,
      };

    case SET_REF:
      return {
        ...state,
        ...{ refs: action.payload },
      };
    case FLUSH_REFS:
      return {
        ...state,
        ...{ refs: {} },
      };
    case SET_ODD_OR_EVEN:
      return {
        ...state,
        ...{ isEven: action.payload.even, isOdd: action.payload.odd },
      };
    case INCLUDE_DAY:
      return {
        ...state,
        ...{
          dates: {
            include: action.payload.include,
            exclude: action.payload.exclude,
          },
        },
      };
    case EXCLUDE_DAY:
      return {
        ...state,
        ...{
          dates: {
            include: action.payload.include,
            exclude: action.payload.exclude,
          },
        },
      };
    case SET_INITIAL:
      return {
        ...state,
        ...{
          initial: action.payload.initial,
        },
      };
    case FLUSH_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
}
