import React, { useState } from 'react';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { connect } from 'react-redux';

import Dots from './Dots';
import { MenuItem } from '../../PricePage/styles';
import { ColumnLayout } from './Column';
import PatientsState from '../state';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { RootState } from '../../../store';

const PatientMenu = styled.div`
  position: absolute;
  top: -30px;
  right: 35px;
  width: 190px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  z-index: 17;
`;

function MenuColumn({ isEditing, setEditing, id, editor, onStartEdit, onStopEdit }) {
  const [isMenuShown, setMenuVisibility] = useState(false);

  return (
    <ColumnLayout
      onClick={() => {
        // e.stopPropagation();
        setMenuVisibility(!isMenuShown);
      }}
      style={{ marginLeft: -8 }}
      width={68}
      hasAction={true}
    >
      <Dots id={id} isEditing={isEditing} setEditing={setEditing} onStopEdit={onStopEdit} />
      <div style={{ position: 'relative' }}>
        {isMenuShown && (
          <ClickAwayListener onClickAway={() => setMenuVisibility(false)}>
            <PatientMenu>
              <MenuItem
                disabled={!!editor}
                onClick={() => {
                  if (!editor) {
                    setEditing(true);
                    onStartEdit(id);
                  }
                }}
              >
                {editor ? (
                  <Tooltip
                    title={
                      <div style={{ display: 'flex', gap: '5px', alignItems: 'flex-start' }}>
                        <img style={{ marginTop: 2 }} src="./img/black/info-circle.svg" alt="attention" />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div>{`Редактирует ${editor}`}</div>
                          <div>Одновременная работа недоступна</div>
                        </div>
                      </div>
                    }
                    placement={'bottom-end'}
                    enterDelay={500}
                    enterNextDelay={500}
                  >
                    <span>Редактировать</span>
                  </Tooltip>
                ) : (
                  <span>Редактировать</span>
                )}
              </MenuItem>
            </PatientMenu>
          </ClickAwayListener>
        )}
      </div>
    </ColumnLayout>
  );
}

export default connect((state: RootState) => ({ common: state.common, patientsPage: state.patientsPage }), {
  redirectToSchedule: PatientsState.actions.redirectToSchedule,
  openDialog: PatientsState.actions.openDialog,
})(MenuColumn);
