import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  padding: 12px 12px 0 12px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
    background-color: unset;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #e0e0e0;
  }

  ::-webkit-scrollbar-track {
    background-color: unset;
  }

  table {
    th,
    td {
      padding: 12px 8px 11px 16px;
      height: 46px;
    }

    tr td:nth-child(4) {
      white-space: pre-wrap;
      line-height: 15px;
    }

    tr:last-child td {
      border-bottom: none;
    }
  }
`;
export const Wrapper = styled.div<{ bottomOffset: number }>`
  height: ${(props) => 'calc(100vh - ' + props.bottomOffset + 'px)'};
`;
export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  padding: 0 12px;
  height: 46px;
  font-weight: 500;
  font-size: 14px;
`;
export const Total = styled.span`
  flex: 0 1 100px;
  padding: 12px 8px 11px 16px;
`;
