import React from 'react';
import styled from 'styled-components';

const Container = styled.span<{ color: string; backgroundColor: string }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 20px;
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`;
type Props = {
  count: number;
  color?: string;
  backgroundColor?: string;
};

export function Counter({ count, color = '#7C8590', backgroundColor = '#F6F6FA' }: Props) {
  return !!count ? (
    <Container color={color} backgroundColor={backgroundColor}>
      {count}
    </Container>
  ) : null;
}
