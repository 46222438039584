import React from 'react';
import PickRegion from '../NewPriceEntryButton/PickRegion';
import { connect } from 'react-redux';
import { RootState } from '../../../store';

function EmptyCategory({ common, group }) {
  const canEdit = common.user.permissions.edit_price || common.user.is_superuser;
  return (
    canEdit && (
      <div
        style={{
          position: 'relative',
          paddingLeft: 20,
          paddingTop: 20,
          width: 1096,
          overflow: 'hidden',
          height: 70,
        }}
      >
        <div
          style={{
            marginLeft: 104,
            marginTop: -1,
            height: 5,
          }}
        >
          <PickRegion entry={{ id: 'empty', category: { id: group } }} index={0} itemIndex={0} />
        </div>
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            position: 'absolute',
            top: 14,
            left: 6,
            fontSize: 14,
            color: '#A8AEB5',
            width: 500,
          }}
        >
          Для добавления новой позиции нажмите на плюс.
        </div>
      </div>
    )
  );
}

export default connect((state: RootState) => ({ common: state.common }), {})(EmptyCategory);
