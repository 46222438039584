import React from 'react';

export const SubRow = ({ row, rowProps }) => {
  return (
    <tr {...rowProps}>
      {row.cells.map((cell) => {
        return (
          <td {...cell.getCellProps()} key={cell.column.id}>
            {cell.render(cell.column.SubCell ? 'SubCell' : 'Cell')}
          </td>
        );
      })}
    </tr>
  );
};
