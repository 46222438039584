import React, { useEffect, useState } from 'react';
import Tooltip from '../../Tooltip/Tooltip';
import TextInput from '../../../uikit/TextInput';
import { useDispatch } from 'react-redux';
import { openChat } from '../../../pages/StaffPage/operations';
import { Phone } from '../../../uikit/Phone';

export const EditableCell = ({
  value: initialValue,
  row: { state: rowState, values },
  column: { placeholder, isHidden, applyMask, isOpenChat, isFio, id },
  cell: { setState: setCellState, state: cellState },
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    const value = e.target.value;
    setValue(
      applyMask
        ? applyMask(value.trim())
        : isFio && value.length > 1
        ? `${value[0].toUpperCase()}${value.slice(1)}`.trim()
        : value.trim(),
    );
  };

  const onBlur = () => {
    setCellState({ value });
  };

  useEffect(() => {
    if (cellState.value !== undefined) {
      setValue(cellState.value);
    }
  }, [cellState]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return rowState.edit ? (
    <Tooltip
      title={cellState.message}
      placement={'bottom-start'}
      disableHoverListener={!cellState.error}
      disableFocusListener={!cellState.error}
      disableTouchListener={!cellState.error}
      enterDelay={500}
      enterNextDelay={500}
      dark
    >
      {id === 'phone' ? (
        <Phone
          value={value}
          onBlur={onBlur}
          onChange={(value, code) => () => {
            setValue(value ? `+${code} ${value}` : undefined);
          }}
          error={cellState.error}
        />
      ) : (
        <TextInput
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          error={cellState.error}
        />
      )}
    </Tooltip>
  ) : isHidden ? (
    '*'.repeat(value.length)
  ) : isOpenChat ? (
    <div style={{ cursor: 'pointer' }} onClick={() => dispatch(openChat(values))}>
      {value}
    </div>
  ) : (
    String(value || '')
  );
};
