import React, { FC, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { ClickAwayListener } from '@material-ui/core';
import SearchIcon from '../../icons/SearchIcon';
import { Selector, SelectedOption, OptionsWindow, SearchInput, Options, Option, Divider } from './styles';
import { IDoctor, IDoctorSelectProps } from './types';
import { UserRole } from '../../../../../../services/users';

const DoctorSelect: FC<IDoctorSelectProps> = (props: IDoctorSelectProps) => {
  const { register, doctorId, doctorName, setValue } = props;
  const [isOpen, setOpen] = useState<boolean>(false);
  const [doctor, setDoctor] = useState<IDoctor>({ id: doctorId, name: doctorName });
  const [search, setSearch] = useState<string>('');
  const doctors = useSelector((state: RootState) => state.staff.responce).filter((el) => el.role === UserRole.Doctor);

  useEffect(() => {
    setValue('doctor', doctorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doctorsWithFullName = doctors?.map((el) => {
    return { ...el, full_name: `${el.last_name} ${el.first_name} ${el.second_name}` };
  });

  const searchedDoctors = doctorsWithFullName.filter((el) => el.full_name.toLowerCase().includes(search.toLowerCase()));

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <Selector onClick={() => setOpen(!isOpen)}>
          <SelectedOption value={doctor.name} disabled />
          <input type="hidden" {...register('doctor')} />
          <KeyboardArrowDownIcon style={{ color: '#515D6B', fontSize: '20px' }} />
        </Selector>
        {isOpen && (
          <OptionsWindow>
            <div style={{ display: 'flex', alignItems: 'center', padding: '0 8px' }}>
              <SearchInput placeholder="Поиск по врачам" onChange={(e) => setSearch(e.target.value)} />
              <SearchIcon />
            </div>
            <Divider />
            <Options>
              {searchedDoctors?.map((value) => {
                return (
                  <Option
                    key={value?.id}
                    selected={doctor.id === value.id}
                    onClick={() => {
                      setDoctor({ id: value?.id, name: value.full_name });
                      setValue('doctor', value?.id);
                      setOpen(false);
                    }}
                  >
                    <span>{value.full_name}</span>
                  </Option>
                );
              })}
            </Options>
          </OptionsWindow>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default DoctorSelect;
