import {
  FETCH_TASKS_SUCCESS,
  PICK_CURRENT_TASK,
  CLOSE_TASK_DIALOG,
  OPEN_TASK_DIALOG,
} from "./types";
import { FLUSH_STATE } from "../flushState/types";
const INITIAL_STATE = { responce: [], current: {} };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TASKS_SUCCESS:
      //
      return {
        ...state,
        ...{ responce: action.payload },
      };

    case PICK_CURRENT_TASK:
      //
      return {
        ...state,
        ...{ current: action.payload },
      };
    case CLOSE_TASK_DIALOG:
      return {
        ...state,
        openDialog: false,
      };
    case OPEN_TASK_DIALOG:
      return {
        ...state,
        openDialog: true,
      };
    case FLUSH_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
