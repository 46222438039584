import React, { useEffect, useRef, useState } from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import MessageBubble from './MessageBubble.js';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { format, isToday, parseISO } from 'date-fns';
import { changeMessageData } from '../../features/messages/actions';
import { sendMessage, fetchMessages, checkMessageAsRead } from '../../features/messages/operations';
import { Scrollbars } from 'react-custom-scrollbars-2';
import TextInput from './TextInput.js';
import { useSearchStaffQuery } from '../../services/users';
import { useAppSelector } from '../../store/hooks';

const _ = lodash;
function MessagesList({ closeDialog, common, messages, checkMessageAsRead }) {
  const {
    user: {
      clinic: { id },
    },
  } = useAppSelector((state) => state.common);
  const { data: staffers } = useSearchStaffQuery({ clinic: id });
  const currentStaffer = staffers.filter(
    (staffer) => parseInt(staffer.id) === parseInt(messages.dialog.reciever.id),
  )[0];

  let scrollArea = useRef(null);
  let scrollToBottom = () => {
    scrollArea && scrollArea.current.scrollToBottom();
  };
  let scrollTop = (px) => {
    scrollArea && scrollArea.current.scrollTop(px);
  };
  const [isTabActive, setTabFocus] = useState(true);
  useEffect(() => {
    let page = document.body;
    page.style['overflow-y'] = 'hidden';
    window.onblur = () => setTabFocus(false);
    window.onfocus = () => setTabFocus(true);
  }, []);

  useEffect(() => {
    messages.messages.forEach((msg, index) => {
      if (msg.isNew && msg.reciever.id === common.user.id && isTabActive) {
        checkMessageAsRead(msg.reciever.id, msg.sender.id, index, msg);
      }
    });
    return () => {
      let page = document.body;
      page.style['overflow-y'] = 'auto';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.messages.length]);

  useEffect(() => {
    if (messages.dialog.fromSearch && messages.messages.length > 5) {
      let count = _.findIndex(messages.messages, (msg) => msg.date === messages.dialog.date);
      scrollTop(count * (56 + 15));
    } else {
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.messages.length]);

  return (
    <div style={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <div
          style={{
            height: 56,
            background: '#FFFFFF',
            boxShadow: '0px 0px 1px rgba(37, 52, 70, 0.04), 0px 2px 4px rgba(37, 52, 70, 0.16)',
            borderRadius: '5px 5px 0px 0px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',

              height: '100%',
              padding: 10,
            }}
          >
            <ArrowBack onClick={closeDialog} />
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'center',
                fontSize: '16px',
              }}
            >
              <div
                style={{
                  color: '#253446',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineHeight: '120%',
                  marginRight: 5,
                }}
              >
                {`${currentStaffer ? currentStaffer.job : 'Врач'}:`}
              </div>
              <div
                style={{
                  color: '#253446',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineHeight: '120%',
                }}
              >
                {`${currentStaffer.last_name} ${currentStaffer.first_name}`}
              </div>
            </div>
          </div>
        </div>

        <div>
          <Scrollbars ref={scrollArea} autoHide style={{ width: 414, height: 340 }}>
            <div
              style={{
                height: 340,
              }}
            >
              {Object.entries(
                _.groupBy(messages.messages, (el) =>
                  isToday(parseISO(el.date))
                    ? format(new Date(el.date), 'Сегодня')
                    : format(new Date(el.date), 'dd.MM.yy'),
                ),
              ).map((data) => {
                return (
                  <div key={Math.random()}>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        padding: 5,
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{
                          width: 70,
                          background: '#F6F6FA',
                          borderRadius: 10,
                          fontSize: 10,
                          color: '#A8AEB5',
                          textAlign: 'center',
                        }}
                      >
                        {data[0]}
                      </div>
                    </div>
                    <div style={{ overflowY: 'auto' }}>
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'column',
                        }}
                      >
                        {data[1].map((msg) => {
                          return (
                            <MessageBubble
                              left={msg.sender.id !== common.user.id}
                              text={msg.text}
                              date={msg.date}
                              isNew={msg.isNew}
                              key={Math.random()}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Scrollbars>
          <div>
            <TextInput />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    messages: state.messages,
  };
};

export default connect(mapStateToProps, {
  changeMessageData,
  sendMessage,
  fetchMessages,
  checkMessageAsRead,
})(MessagesList);
