import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { EmptyPages } from './components/EmptyPages';
import { ReportDebtsFilters } from './components/ReportDebtsFilters';
import { useGetPermissionsQuery } from '../../services/users';
import { ReportTable } from './components/ReportTable';
import { getDebtsAndAdvancesColumns } from './helpers';
import DebtReportTableFooter from './components/ReportTable/DebtReportTableFooter';
import { resetDebtsAndAdvancesFilter } from './reducer';

export const DebtReportPage = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.common.user.id);
  const debtsAndAdvancesReport = useAppSelector((state) => state.reports.debtsAndAdvancesReport);
  const debtsAndAdvancesTableDisplayMode = useAppSelector((state) => state.reports.debtsAndAdvancesTableDisplayMode);
  const { data: permissions } = useGetPermissionsQuery(userId);

  const onResetFilters = () => dispatch(resetDebtsAndAdvancesFilter(null));
  const viewPatientContacts = permissions?.view_patient_contacts;
  const debtsAndAdvancesColumns = useMemo(
    () => getDebtsAndAdvancesColumns(debtsAndAdvancesTableDisplayMode, viewPatientContacts),
    [debtsAndAdvancesTableDisplayMode, viewPatientContacts],
  );

  return (
    <>
      <ReportDebtsFilters />
      {debtsAndAdvancesReport && debtsAndAdvancesReport.length ? (
        <ReportTable
          tableData={debtsAndAdvancesReport}
          columns={debtsAndAdvancesColumns}
          footer={
            <DebtReportTableFooter tableData={debtsAndAdvancesReport} displayMode={debtsAndAdvancesTableDisplayMode} />
          }
          bottomOffset={debtsAndAdvancesTableDisplayMode === 'advances debts' ? 245 : 215}
          mode={'debts'}
          noScrollbars
        />
      ) : (
        <EmptyPages data={debtsAndAdvancesReport} onResetFilters={onResetFilters} />
      )}
    </>
  );
};
