import { slotLabelFormatType, weekDaysNamesType, viewsType, headerToolbarType } from './types';

//Получение диапазона дат для отображения
export const getVisibleRange = (dateRange) => {
  const visibleRange = !!dateRange[1]
    ? { start: dateRange[0], end: new Date(dateRange[1].setDate(dateRange[1].getDate() + 1)) }
    : undefined;
  return visibleRange;
};

//Ограничение времени для сессий (они не смогут вылезти за этот диапазон времени)
export const constraint = { startTime: '00:00:00', endTime: '24:00:00' };

//Режимы отображения расписания (нужны для работы с API компонента)
export const views: viewsType = {
  displayByDay: {
    type: 'resourceTimeGrid',
  },
  displayByPeriod: {
    type: 'resourceTimeGrid',
  },
};

//Enum для использования в местах, где есть проверки на текущий режим отображения
export const viewTypes = { DISPLAY_BY_DAY: 'displayByDay', DISPLAY_BY_PERIOD: 'displayByPeriod' };

//Явно объявленное отсутствие тулбара над расписанием (иначе его не убрать)
export const headerToolbar: headerToolbarType = {
  left: undefined,
  center: undefined,
  right: undefined,
};

//Формат отображения времени на шкале времени слева
export const slotLabelFormat: slotLabelFormatType = {
  hour: '2-digit',
  minute: '2-digit',
  meridiem: false,
  hour12: false,
};

//Массив с названиями дней недели (для компонента ColumnHeader, шапки таблицы)
export const weekDaysNames: weekDaysNamesType = [
  'Воскресенья',
  'Понедельники',
  'Вторники',
  'Среды',
  'Четверги',
  'Пятницы',
  'Субботы',
];

//CSS-селектор для родителя всех колонок в ШАПКЕ таблицы
export const TABLE_HEADER_SELECTOR =
  '#FCWidthWrapper > div > div.fc-view-harness.fc-view-harness-active > div.fc-view.fc-timegrid > table > thead > tr > th > div > div > table > thead > tr:nth-child(2)';

//CSS-селектор для родителя всех колонок в ТЕЛЕ таблицы
export const TABLE_BODY_SELECTOR =
  '#FCWidthWrapper > div > div.fc-view-harness.fc-view-harness-active > div.fc-view.fc-timegrid > table > tbody > tr > td > div > div > div > div.fc-timegrid-cols > table > tbody > tr';

//Смещение времени для текущего часового пояса, в миллисекундах
export const TIMEZONE_OFFSET = new Date().getTimezoneOffset() * 60000;
