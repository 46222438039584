import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Card, CardContent, CardHeader, ClickAwayListener } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import Switch from '../../../../uikit/Switch';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { TextTemplateItem } from './TextTemplateItem';
import { TextTemplateItemBorder } from './TextTemplateItemBorder';
import { EmptyTextTemplates } from './EmptyTextTemplates';
import { JournalField } from '../../Jornal/journalFieldsConfig';
import request from '../../../../utils/request';
import { ToothStateType } from '../../Formula/StateMenu';

const useStyles = makeStyles<Theme, { isBottom: boolean }>(() =>
  createStyles({
    root: {
      position: 'absolute',
      border: '1px solid #D3D6DA',
      boxSizing: 'border-box',
      boxShadow: '0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16)',
      borderRadius: 4,
      marginRight: 16,
      marginBottom: 24,
      marginLeft: 8,
      transition: 'all 1s ease-in-out',
      width: 'calc(100% - 24px)',
      top: ({ isBottom }) => (isBottom ? 'auto' : 42),
      bottom: ({ isBottom }) => (isBottom ? -24 : 'auto'),
      zIndex: 1,
    },
    header: {
      position: 'relative',
      background: '#F9F9F9',
      padding: 0,
    },
    headerContent: {
      padding: '20px 30px',
    },
    text: {
      color: '#A8AEB5',
      fontWeight: 'normal',
      fontSize: 14,
      marginRight: 8,
    },
    content: {
      padding: '0 16px 12px 16px',
      '&:last-child': {
        paddingBottom: 12,
      },
    },
    noCompleted: {
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '120%',
      color: '#A8AEB5',
      padding: '20px 14px 10px',
    },
  }),
);

const HeaderTitle = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: #253446;
  justify-content: space-between;
  align-items: center;
`;
const HeaderSubTitle = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: #515d6b;
  align-items: center;
  margin-top: 12px;
`;

const PickRegion = styled.div`
  position: absolute;
  height: 4px;
  width: 100%;
  background: transparent;
  top: 83px;
  left: 0;
`;

type Props = {
  isBottom: boolean;
  doctorId: number;
  journalField: JournalField;
  toothState: ToothStateType;
  clearFocus: VoidFunction;
};

export type TextTemplate = {
  id: number | null;
  order: number;
  dirty_text: string;
  text: string;
  is_new: boolean;
  completed: boolean;
};

export function TextTemplates({ doctorId, journalField, toothState, clearFocus, isBottom }: Props) {
  const classes = useStyles({ isBottom: isBottom });

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<TextTemplate[]>([]);
  const cardRef = useRef(null);

  const getNextTemplateId = (nextIndex) => {
    if (templateData.length) {
      const prevIndex = nextIndex > 0 ? nextIndex - 1 : 0;
      if (nextIndex === 0 && prevIndex === 0) {
        return templateData[nextIndex].order / 2;
      }
      if (nextIndex === templateData.length) {
        return templateData[nextIndex - 1].order + 1;
      }
      return (templateData[prevIndex].order + templateData[nextIndex].order) / 2;
    } else {
      return 1.0;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // Для плавности анимации показывать лоадер через секунду
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (!(templates && templates.status === 200)) {
          setIsLoading(true);
        }
      }, 1000);

      const templates = await request.users.text_templates(doctorId, journalField.name, toothState?.mnemo);
      setTemplateData(templates.data.map((t) => ({ ...t, order: +t.order })));
      setIsLoading(false);
    };
    fetchData();
  }, [doctorId, journalField.name, toothState?.mnemo]);

  const handleAddTemplate =
    (index: number) =>
    (templateId = null, text = '', is_new = true, completed = false) => {
      const order = getNextTemplateId(index);
      templateData.splice(currentIndex, 0, {
        id: templateId,
        order: order,
        dirty_text: text,
        text: text,
        is_new: is_new,
        completed: completed,
      });
      setTemplateData([...templateData]);
    };

  const handleAddFirstTemplate = (text) => {
    let templateId = null;
    request.users
      .add_text_template(doctorId, journalField.name, toothState?.mnemo, text, text, false, true, 1)
      .then(({ data }) => {
        templateId = data.id;
        handleAddTemplate(0)(templateId, text, false, true);
      });
  };

  const editTemplate = (index, templateId, text, dirty_text, is_new, completed, template) => {
    templateData.splice(index, 1, {
      id: templateId,
      order: template.order,
      dirty_text: dirty_text,
      text: text,
      is_new: is_new,
      completed: completed,
    });
    setTemplateData([...templateData]);
  };

  const handleEditTemplate =
    (index, template) =>
    ({ id, text, dirty_text, is_new, completed }) => {
      let templateId = id;
      let templateCompleted = completed;
      if (!text) {
        return;
      }
      if (templateId) {
        request.users
          .update_text_template(
            doctorId,
            journalField.name,
            toothState?.mnemo,
            templateId,
            text,
            dirty_text,
            is_new,
            templateCompleted,
          )
          .catch(() => {
            templateCompleted = false;
          })
          .finally(() => {
            editTemplate(index, templateId, text, dirty_text, is_new, templateCompleted, template);
          });
      } else {
        request.users
          .add_text_template(
            doctorId,
            journalField.name,
            toothState?.mnemo,
            text,
            dirty_text,
            is_new,
            completed,
            template.order,
          )
          .then(({ data }) => {
            templateId = data.id;
          })
          .catch(() => {
            templateCompleted = false;
          })
          .finally(() => {
            editTemplate(index, templateId, text, dirty_text, is_new, templateCompleted, template);
          });
      }
    };

  const deleteTemplate = (index) => {
    templateData.splice(index, 1);
    setTemplateData([...templateData]);
  };

  const handleDeleteTemplate = (index, template) => () => {
    if (template.id) {
      request.users.delete_text_template(doctorId, journalField.name, toothState?.mnemo, template.id).then(() => {
        deleteTemplate(index);
      });
    } else {
      deleteTemplate(index);
    }
  };

  const templates = templateData.filter((template) => (isEditMode ? true : template.completed));

  const emptyText = useMemo(() => {
    return isEmpty(templateData) ? (
      <EmptyTextTemplates onAddTemplate={handleAddFirstTemplate} />
    ) : (
      <div className={classes.noCompleted}>Чтобы увидеть шаблоны завершите редактирование</div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData]);

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (!~e.target.classList.value.indexOf('textLong')) {
          clearFocus();
        }
      }}
    >
      <Card className={classes.root} ref={cardRef}>
        <CardHeader
          className={classes.header}
          title={
            <>
              <div className={classes.headerContent}>
                <HeaderTitle>
                  <div>Шаблон</div>
                  {!isEmpty(templateData) && (
                    <div style={{ display: 'flex' }}>
                      <div className={classes.text}>Режим редактирования</div>
                      <Switch
                        checked={isEditMode}
                        // bug material ui switch: not working onChange
                        onClick={() => {
                          if (isEditMode) {
                            setCurrentIndex(0);
                          }
                          setIsEditMode(!isEditMode);
                        }}
                      />
                    </div>
                  )}
                </HeaderTitle>
                <HeaderSubTitle>
                  {toothState?.title && <div style={{ marginRight: 8 }}>{toothState.title}.</div>}
                  <div>{journalField.text}.</div>
                </HeaderSubTitle>
                <PickRegion onMouseEnter={() => isEditMode && setCurrentIndex(0)} />
              </div>
              <TextTemplateItemBorder
                index={0}
                isEditMode={isEditMode && !isEmpty(templates)}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                onAddTemplate={handleAddTemplate(0)}
              />
            </>
          }
        />
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '397px',
            }}
          >
            <div className="spinner" />
          </div>
        ) : (
          <Scrollbars
            autoHeight
            autoHeightMax={397}
            style={{
              width: '100%',
            }}
          >
            <CardContent className={classes.content}>
              {isEmpty(templates)
                ? emptyText
                : templates.map((template, index) => (
                    <TextTemplateItem
                      key={template.order}
                      index={index + 1}
                      isEditMode={isEditMode}
                      journalFieldName={journalField.name}
                      template={template}
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      onAddTemplate={handleAddTemplate(index + 1)}
                      onEditTemplate={handleEditTemplate(index, template)}
                      onDeleteTemplate={handleDeleteTemplate(index, template)}
                    />
                  ))}
            </CardContent>
          </Scrollbars>
        )}
      </Card>
    </ClickAwayListener>
  );
}
