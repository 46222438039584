import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
const Container = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({ marginTop }) => (marginTop ? 13 : 0)}px;
  padding-right: ${({ marginTop }) => (marginTop ? 5 : 0)}px;
  padding-left: ${({ marginTop }) => (marginTop ? 5 : 0)}px;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #a8aeb5;
  width: 100%;
  text-align: center;
  margin-top: 16px;
`;

const Link = styled.span`
  cursor: pointer;
  color: #577bf9;
`;
function EmptyList({ isWorking, common, deskription, marginTop = false }) {
  const [isRedirected, setRedirect] = useState(false);
  const { permissions } = common.user;
  return (
    !isWorking && (
      <Container marginTop={marginTop}>
        <Text>
          <span>{deskription} </span>
          {permissions.edit_clinic_profile ? (
            <Link onClick={() => setRedirect(true)}>настройки профиля</Link>
          ) : (
            <span>настройки профиля</span>
          )}
        </Text>
        {isRedirected && <Navigate to="/profile" />}
      </Container>
    )
  );
}

export default connect((state) => ({ common: state.common }), {})(EmptyList);
