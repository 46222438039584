import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  first_day: new Date().getTime(),
  components: {
    body: [
      [
        { cabinet: "", endHour: "", dateTime: "" },
        { cabinet: "", endHour: "", dateTime: "" },
      ],
    ],
    subheader: [],
    header: [],
    isLoading: true,
  },
  isEmpty: true,
  length: Math.floor((window.innerWidth - 50) / 200),
  type: "week",
  page: 0,
  len: 0,
  workdays: [],
  click_counter: 0,
  allTasks: [],
};

const CalendarSlice = createSlice({
  name: "calendar",
  initialState: INITIAL_STATE,
  reducers: {
    emptyCalendar: (state) => ({
      ...state,
      isEmpty: true,
    }),
    loadAllTasks: (state, action) => ({
      ...state,
      allTasks: action.payload,
    }),
    setTaskCalendarLenght: (state) => ({
      ...state,
      length: Math.floor((window.innerWidth - 30) / 200) - 1,
    }),
    loadCalendar: (state, action) => {
      return {
        ...state,
        components: {
          ...state.components,
          ...action.payload,
          isLoading: false,
        },
        isEmpty: false,
      };
    },
    nextWeek: (state, action) => ({
      ...state,
      first_day: action.payload.getTime(),
    }),
    prevWeek: (state, action) => ({
      ...state,
      first_day: action.payload.getTime(),
    }),
    setCalendarType: (state, action) => ({
      ...state,
      type: action.payload,
    }),
    initPaginator: (state, action) => ({
      ...state,
      page: action.payload.page,
      len: action.payload.len,
    }),
    saveWorkdays: (state, action) => ({
      ...state,
      workdays: action.payload,
    }),
    setPage: (state, action) => ({ ...state, page: action.payload }),
    setFirstDay: (state, action) => ({
      ...state,
      first_day: action.payload.getTime(),
    }),
    incrementClickCounter: (state) => ({
      ...state,
      click_counter: state.click_counter + 1,
    }),
    resetClickCounter: (state) => ({
      ...state,
      click_counter: 0,
    }),
    dropCalendarState: (state) => ({
      ...state,
      ...INITIAL_STATE,
    }),
  },
});

export default CalendarSlice;
