import React from "react";
import PickRegion from "./PickRegion";
import AddEntry from "./AddEntry";
import { connect } from "react-redux";
import PriceSlice from "../state";
import { addNewRowFromSearch, addRowToPrice } from "../operations";
const { setAddBtnPosition, startEditingEntry } = PriceSlice.actions;

function NewPriceEntryButton({
  itemIndex,
  index,
  item,
  priceSlice,
  setAddBtnPosition,
  categoryArrayLength,
  startEditingEntry,
  addNewRowFromSearch,
  addRowToPrice,
}: any) {
  const { canEdit, editingEntryID, addBtnPosition } = priceSlice;
  const editingIsNotStarted = editingEntryID === null;
  const isCreatingEntriesAllowed = canEdit && editingIsNotStarted;
  const isLastEntry = categoryArrayLength - 1 === itemIndex;
  return (
    <>
      {isCreatingEntriesAllowed && (
        <>
          <PickRegion entry={item} index={index} itemIndex={itemIndex} />
          {isLastEntry && (
            <PickRegion
              entry={item}
              index={index + 1}
              itemIndex={itemIndex}
              lastRow
            />
          )}
        </>
      )}
    </>
  );
}

export default connect((state: any) => ({ priceSlice: state.priceSlice }), {
  addRowToPrice,
  addNewRowFromSearch,
  startEditingEntry,
  setAddBtnPosition,
})(NewPriceEntryButton);
