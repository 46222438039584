import React, { FC } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Scrollbars from 'react-custom-scrollbars-2';
import styled from 'styled-components';

import { CommentsForm } from '../../uikit/CommentsForm';
import { useGetInvoiceCommentsQuery } from '../../services/invoice';
import { CommentIcon } from '../../pages/VisitsAndPaymentsPage/styles';
import { useCommentForm } from '../../uikit/Modal/hooks';
import { totalMargin } from '../../uikit/CommentsForm/constants';
import { Modal } from '../../uikit/Modal';

type VisitCommentsProps = {
  commentFormHeight?: number;
  invoiceId?: number;
  scrollTop?: () => void;
  cancelReason?: string;
};

const Container = styled.div`
  width: 508px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  padding-bottom: 16px;
`;

const CancelCommentText = styled.div`
  line-height: 20px;
  padding-right: 26px;
  margin: 12px 0;
  font-size: 14px;
  word-wrap: break-word;
  overflow: hidden;
  letter-spacing: 0.25px;
`;

export const VisitComments: FC<VisitCommentsProps> = (props) => {
  const { cancelReason, commentFormHeight, invoiceId, scrollTop } = props;

  const { data: invoiceComments = [] } = useGetInvoiceCommentsQuery(invoiceId, { skip: !invoiceId });

  const { commentForm, handleCommentModalClose, showComments, currentId, handleCommentListOpen } = useCommentForm();

  return (
    <>
      {(Boolean(invoiceComments.length) || cancelReason) && (
        <CommentIcon onClick={(event) => handleCommentListOpen(event, invoiceId)}>
          <img src="./img/grey/comment-icon.svg" alt="comment" />
        </CommentIcon>
      )}
      <Modal anchor={commentForm}>
        {cancelReason ? (
          <ClickAwayListener onClickAway={handleCommentModalClose}>
            <Container>
              <Scrollbars
                autoHeight
                autoHeightMax={commentFormHeight ? `${commentFormHeight - totalMargin}px` : '300px'}
              >
                <div style={{ padding: '16px 16px 0 16px', fontWeight: 400 }}>
                  <span style={{ color: '#253446' }}>Комментарий</span>
                  <CancelCommentText>{cancelReason}</CancelCommentText>
                </div>
              </Scrollbars>
            </Container>
          </ClickAwayListener>
        ) : (
          <CommentsForm
            handleSetOpenForm={handleCommentModalClose}
            scrollTop={scrollTop}
            showComments={showComments}
            invoiceId={currentId}
            formHeight={commentFormHeight}
          />
        )}
      </Modal>
    </>
  );
};
