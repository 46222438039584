import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AntTab, AntTabs } from '../../../../uikit/Tabs';
import { TreatmentPlanTemplate } from './TreatmentPlanTemplates';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
    maxWidth: 650,
    '& .MuiTabs-indicator': {
      height: '3px !important',
    },
  },
}));

type TabItemType = {
  label: string;
  value: number;
};

type Props = {
  data: TabItemType[];
  activeTreatmentPlan: TreatmentPlanTemplate;
  onToothStateSelect: (value) => void;
};

export function ToothStateTabs({ data, activeTreatmentPlan, onToothStateSelect }: Props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (data?.length) {
      if (activeTreatmentPlan) {
        setValue(activeTreatmentPlan.entry.id);
      } else {
        setValue(data[0].value);
      }
    }
  }, [data, activeTreatmentPlan]);

  const handleChange = (event, newValue: number) => {
    if (newValue !== value) {
      setValue(newValue);
      onToothStateSelect(newValue);
    }
  };

  return (
    <div className={classes.root}>
      <AntTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
      >
        {data.map((c) => (
          <AntTab key={c.value} label={c.label} value={c.value} />
        ))}
      </AntTabs>
    </div>
  );
}
