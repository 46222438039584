import React from 'react';
import styled from 'styled-components';

const LeftEdgeFillet = styled.div`
  position: absolute;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 10px;
  border-radius: 0 0 6px 0;
  border: 1px solid #d3d6da;
  border-top: none;
  border-left: none;
  background-color: rgb(249, 249, 249);
  z-index: 6;
`;

const LeftPatch = styled.div`
  position: absolute;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 10px;
  background-color: white;
  z-index: 5;
`;
const RightEdgeFillet = styled.div`
  position: absolute;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 10px;
  border-radius: 0 0 0 6px;
  border: 1px solid #d3d6da;
  border-top: none;
  border-right: none;
  background-color: rgb(249, 249, 249);
  z-index: 6;
`;

const RightPatch = styled.div`
  position: absolute;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 10px;
  background-color: white;
  z-index: 5;
`;

export default function EdgeFillet({ isVisible, right = false }: { isVisible: boolean; right?: boolean }) {
  return (
    <>
      {isVisible && (
        <>
          {right ? <RightEdgeFillet /> : <LeftEdgeFillet />}
          {right ? <RightPatch /> : <LeftPatch />}
        </>
      )}
    </>
  );
}
