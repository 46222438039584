import React, { FC } from 'react';
import { getMask } from '../../../utils/masks';

type paymentCellType = {
  rowIndex: number;
  value: string | number;
};
export const PaymentCellContainer: FC<paymentCellType> = ({ rowIndex, value, children }) => {
  return (
    <>{rowIndex === 0 ? children : typeof value === 'string' ? value : getMask('number_format').resolve(`${value}`)}</>
  );
};
