import React, { useMemo } from 'react';
import { FooterContainer, Total } from './styles';

const RevenueReportTableFooter = ({ tableData }) => {
  const total = useMemo(() => tableData.reduce((sum, item) => item.payment + sum, 0), [tableData]);
  return (
    <FooterContainer>
      <span style={{ paddingRight: '12px' }}>Итоговая сумма:</span>
      <Total>{total}</Total>
    </FooterContainer>
  );
};

export default RevenueReportTableFooter;
