import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  array: [],
  timeData: {},
  isErrorShown: false,
  detailsShown: false,
};

const AppointmentsSlice = createSlice({
  name: "appointments",
  initialState: INITIAL_STATE,
  reducers: {
    toggleOverlapingError: (state) => ({
      ...state,
      isErrorShown: !state.isErrorShown,
    }),
    saveAppointmentTimeData: (state, action) => ({
      ...state,
      timeData: { ...action.payload },
    }),
    loadAppointments: (state, action) => ({
      ...state,
      array: action.payload,
    }),
    cancelAppointment: (state, action) => {
      state.array = state.array.filter(
        (appointment) =>
          parseInt(appointment.id) !== parseInt(action.payload.id)
      );
      state.detailsShown = false;
    },
    createNewAppointment: (state, action) => {
      state.array.push(action.payload);
      state.detailsShown = false;
    },
  },
});

export default AppointmentsSlice;
