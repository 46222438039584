import React, { useState } from 'react';
import { Row, Spacer, Phone } from '../style';
import { connect } from 'react-redux';
import MedCardPageSlice from '../state';
import TextBlock from '../TextBlock';
import { patchPatientData } from '../operations';
import TextInput from '../../../uikit/TextInput';
import PickGender from '../PickGender';
import { OnTopTooltip } from '../../../components/DoctorFilter/OnTopTooltip';
import { useGetPermissionsQuery } from '../../../services/users';
import AdsChannel from '../AdsChannel';
import { RootState } from '../../../store';

const Placeholder = ({ value }) => {
  const [isShown, setIsShown] = useState(false);
  return (
    <OnTopTooltip title={'Редактирование через Базу пациентов'} arrow isShown={isShown}>
      <Phone onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
        {value}
      </Phone>
    </OnTopTooltip>
  );
};
function FirstRow({ medCardPageSlice, common }: RootState) {
  const { id, stream, phone, gender, profession } = medCardPageSlice.patientDetails;
  const patchCurrentPatientData = patchPatientData(id);
  const { data: permissions } = useGetPermissionsQuery(common.user.id);

  return (
    <Row>
      <TextBlock
        name={'phone'}
        width={160}
        label={'Телефон'}
        initialValue={permissions['view_patient_contacts'] ? phone : '*'.repeat(phone ? phone.length : 0)}
        placeholder={''}
        handleBlur={patchCurrentPatientData}
        InputComponent={Placeholder}
        disabled={!permissions['view_patient_contacts']}
      />
      <TextBlock
        type={'gender'}
        name={'gender'}
        width={160}
        label={'Пол'}
        initialValue={gender}
        placeholder={''}
        handleBlur={patchCurrentPatientData}
        InputComponent={PickGender}
      />
      <TextBlock
        name={'profession'}
        width={188}
        label={'Профессия'}
        initialValue={profession}
        placeholder={'Пример: “Архитектор”'}
        handleBlur={patchCurrentPatientData}
        InputComponent={TextInput}
      />
      <TextBlock
        name={'stream'}
        width={188}
        label={'Канал привлечения'}
        initialValue={stream}
        maxLength={150}
        placeholder={'Пример: “Соц сети“'}
        handleBlur={patchCurrentPatientData}
        InputComponent={AdsChannel}
      />
      <Spacer />
    </Row>
  );
}

export default connect((state: RootState) => ({ medCardPageSlice: state.medCardPageSlice, common: state.common }), {
  setPatientDetails: MedCardPageSlice.actions.setPatientDetails,
})(FirstRow);
