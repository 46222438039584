import WithSuggestions from '../../components/Suggestions/WithSuggestions';
import TextInput from '../../uikit/TextInput';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SuggestionsState from '../../components/Suggestions/state';
import { pluck, uniq, compose } from 'ramda';
import { RootState } from '../../store';

function AdsChannel({ value, onBlur, openMenu, closeMenu, clinic: { patients }, ...props }) {
  useEffect(
    () => () => closeMenu(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [adsChannel, setAdsChannel] = useState(value);
  const getSuggestions = compose<unknown[], string[], string[]>(uniq, pluck('stream'));
  const suggestions = getSuggestions(patients);
  return (
    <div style={{ width: '100%' }} onClick={() => openMenu('stream')}>
      <WithSuggestions
        name={'stream'}
        suggestions={suggestions}
        value={adsChannel === value ? '' : adsChannel}
        handlePick={(value: string) => {
          setAdsChannel(value);
          onBlur({
            target: { value },
          });
        }}
      >
        <div style={{ height: 30 }}>
          <TextInput {...props} onBlur={onBlur} value={adsChannel} onChange={(e) => setAdsChannel(e.target.value)} />
        </div>
      </WithSuggestions>
    </div>
  );
}

export default connect((state: RootState) => ({ clinic: state.clinic }), {
  openMenu: SuggestionsState.actions.openMenu,
  closeMenu: SuggestionsState.actions.closeMenu,
})(AdsChannel);
