import styled from 'styled-components';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: ({ isFullView }: { isFullView: boolean }) => ({
      position: 'absolute',
      width: isFullView ? 1308 : 892,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      transition: 'height 1s ease-in-out 500ms, width 50ms linear',
    }),
    title: {
      fontSize: '18px',
      marginBottom: theme.spacing(3),
    },
    label: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#253446',
    },
    labelSm: {
      fontSize: '12px',
      fontWeight: 500,
      color: '#515D6B',
    },
    labelSm1: {
      fontSize: '12px',
      fontWeight: 'normal',
      color: '#515D6B',
    },
    ml16: {
      marginLeft: '16px',
    },
    ml12: {
      marginLeft: '12px',
    },
    ml10: {
      marginLeft: '10px',
    },
    cabinet: {
      fontSize: '12px',
      fontWeight: 'normal',
      color: '#515D6B',
      marginLeft: '4px',
    },
    text1: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#A8AEB5',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

export const Info = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const InfoTable = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
`;

export const FooterContainer = styled.div`
  padding: 24px;
`;

export const HeaderContainer = styled.div`
  padding: 24px 24px 8px;
`;
