import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  patientDetails: {
    illnesses: '',
    commentary: '',
    gender: '',
    birth_date: '',
  },
  showPayment: false,
  accountVisible: false,
  currentPaymentId: null,
};

const MedCardPageSlice = createSlice({
  name: 'MedCardPageSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setPatientDetails: (state, action) => ({
      ...state,
      patientDetails: {
        ...state.patientDetails,
        ...action.payload,
      },
    }),
    patchDetails: (state, action) => {
      state.patientDetails = { ...state.patientDetails, ...action.payload };
    },
    setPaymentVisible: (state, action) => {
      state.showPayment = action.payload;
    },
    setAccountVisible: (state, action) => {
      state.accountVisible = action.payload;
    },
    setCurrentPaymentId: (state, action) => {
      state.currentPaymentId = action.payload;
    },
  },
});

export default MedCardPageSlice;
