import { createSlice } from "@reduxjs/toolkit";
const INITIAL_STATE = {
  isOpened: false,
  menuId: "",
};

const SuggestionsMenu = createSlice({
  name: "SuggestionsMenu",
  initialState: INITIAL_STATE,
  reducers: {
    openMenu: (state, action) => {
      state.isOpened = true;
      state.menuId = action.payload;
    },
    closeMenu: (state, action) => {
      state.isOpened = false;
    },
  },
});

export default SuggestionsMenu;
