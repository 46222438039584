import React, { FC, memo } from 'react';
import ruLocale from 'date-fns/locale/ru';
import { format } from 'date-fns';

type VisitDateType = {
  startDate: string;
  endDate?: string;
};

export const VisitDate: FC<VisitDateType> = memo(({ startDate, endDate }) => {
  return (
    <>
      <div style={{ marginRight: 8 }}>{format(new Date(startDate), 'dd MMM yyyy', { locale: ruLocale })}</div>
      <div style={{ marginRight: 8 }}>
        {format(new Date(startDate), 'HH:mm')}
        {endDate && <span> - {format(new Date(endDate), 'HH:mm')}</span>}
      </div>
    </>
  );
});
VisitDate.displayName = 'VisitDate';
