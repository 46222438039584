import styled from 'styled-components';

export const CalendarWrapper = styled.div<{ isDisabled: boolean }>`
  position: relative;
  background: ${({ isDisabled }) => (isDisabled ? '#F6F6FA' : 'white')};
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  max-width: 240px;
`;
export const CalendarFooter = styled.div`
  position: relative;
  margin-top: -12px;
`;
export const YearControls = styled.div`
  position: absolute;
  right: 27%;
  top: 12px;
`;
export const YearControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 52px;
  justify-content: space-between;
`;
