import styled from 'styled-components';

export const Filters = styled.div`
  margin: 24px 0;
  height: 32px;
`;
export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
`;
export const BlueButton = styled.button<{ disabled?: boolean }>`
  width: 204px;
  height: 32px;
  border-radius: 4px;
  border: 0;
  background: #577bf9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  background: ${({ disabled }) => (disabled ? '#EFF0F3' : '#577bf9')};
  color: ${({ disabled }) => (disabled ? '#A8AEB5' : '#ffffff')};
  border: ${({ disabled }) => (disabled ? '1px solid #D3D6DA' : '1px solid white')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  margin-left: auto;
  outline: none !important;
`;
