import { ClickAwayListener, Tooltip, TooltipProps } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import StateBlock from './StateBlock';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useGetToothStateDictionaryQuery } from '../../../services/dictionaries';
import { getIconState } from '../../ProfilePage/components/ToothStateSettings';
import { DoctorType, MedcardEntry, useUpdateEntryMutation } from '../../../services/medcard';
import { FormulaType } from './index';
import { RootState } from '../../../store';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';

export enum ToothMenuType {
  STATE = 'STATE',
  TREATMENT = 'TREATMENT',
}

type ModalProps = {
  left: boolean;
  bottom: boolean;
  tabNumber: ToothMenuType;
};

const Modal = styled.div<ModalProps>`
  min-height: ${({ tabNumber }) => (tabNumber === ToothMenuType.STATE ? 342 : 185)}px;
  width: 418px;
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  position: absolute;
  top: ${({ bottom }) => (bottom ? 88 : 48)}px;
  left: ${({ left }) => (left ? 0 : -418 + 40)}px;
  z-index: 88;
  padding-top: 18px;
`;

const Tabs = styled.div`
  display: flex;
  flex: 0;
  flex-direction: row;
  margin-bottom: 22px;
  padding-left: 12px;
  position: relative;
`;
type TabProps = {
  isActive: boolean;
};
const Tab = styled.div<TabProps>`
  position: relative;
  margin-right: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? '#577BF9' : '#515d6b')};
  &:hover {
    color: #577bf9;
  }
`;

const StatesTable = styled.table`
  width: 100%;
  border-top: 1px solid #d3d6da;
`;
const StateRow = styled.tr`
  height: 31.5px;
  width: 100%;
  border-top: 1px solid #d3d6da;
`;
const StateCol = styled.td`
  border-top: 1px solid #d3d6da;
  padding-left: 12px;
  cursor: pointer;
`;
const TabBorder = styled.div`
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #577bf9;
`;

export type ToothStateType = {
  title: string;
  mnemo: string;
  icon: string;
  iconColor: string;
};

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props: TooltipProps) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

function StateMenu({
  actualToothStatus,
  formulaType,
  currentEntry,
  patientId,
  doctor,
  dateId,
  left,
  bottom,
  openMenuFor,
  setEmptyToothStateError,
  toothIndex,
  teethFormulaState,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actualToothStatus: any;
  formulaType: FormulaType;
  currentEntry: MedcardEntry;
  patientId: number;
  doctor: DoctorType;
  dateId: number;
  left: boolean;
  bottom: boolean;
  openMenuFor: (id) => void;
  setEmptyToothStateError: ActionCreatorWithNonInferrablePayload;
  toothIndex: string;
  teethFormulaState: RootState['teethFormulaState'];
}) {
  const [tabNumber, setTabNumber] = useState(ToothMenuType.STATE);
  const { expandedId } = teethFormulaState;
  const [updateEntry] = useUpdateEntryMutation();

  const { data, isLoading } = useGetToothStateDictionaryQuery(tabNumber);

  const toothStateDictionary = useMemo(() => {
    if (data?.length) {
      const mid = Math.trunc(data.length / 2);

      // Имеющие пару
      const result = data.reduce((res, value, index) => {
        if (index < mid) {
          const pairItemIndex = index + mid;
          res.push([
            {
              title: value.title_russian,
              mnemo: value.mnemonic,
              icon: value.icon_letter,
              iconColor: getIconState(value.icon_type_mnemonic).color,
            },
            {
              title: data[pairItemIndex].title_russian,
              mnemo: data[pairItemIndex].mnemonic,
              icon: data[pairItemIndex].icon_letter,
              iconColor: getIconState(data[pairItemIndex].icon_type_mnemonic).color,
            },
          ]);
        }
        return res;
      }, []);

      // Если количестов нечетное
      if (data.length > mid * 2) {
        result.push([
          {
            title: data[data.length - 1].title_russian,
            mnemo: data[data.length - 1].mnemonic,
            icon: data[data.length - 1].icon_letter,
            iconColor: getIconState(data[data.length - 1].icon_type_mnemonic).color,
          },
          null,
        ]);
      }
      return result;
    } else {
      return [];
    }
  }, [data]);

  useEffect(() => {
    if (currentEntry && expandedId) {
      setTabNumber(currentEntry?.toothTreatment ? ToothMenuType.STATE : ToothMenuType.TREATMENT);
    }
  }, [currentEntry, expandedId]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        openMenuFor(null);
      }}
    >
      <Modal left={left} bottom={bottom} tabNumber={tabNumber}>
        <Tabs>
          <Tab
            onClick={() => setTabNumber(ToothMenuType.STATE)}
            isActive={tabNumber === ToothMenuType.STATE}
            className={tabNumber === ToothMenuType.STATE ? 'active-tab' : ''}
          >
            Состояние зуба
            {tabNumber === ToothMenuType.STATE && <TabBorder />}
          </Tab>
          <Tab
            onClick={() => setTabNumber(ToothMenuType.TREATMENT)}
            isActive={tabNumber === ToothMenuType.TREATMENT}
            className={tabNumber === ToothMenuType.TREATMENT ? 'active-tab' : ''}
          >
            Проведенное лечение
            {tabNumber === ToothMenuType.TREATMENT && <TabBorder />}
          </Tab>
          {expandedId &&
            (currentEntry && currentEntry.doctor.id === doctor.id ? (
              <BootstrapTooltip title="Удалить текущее состояние" placement="right">
                <div
                  style={{ position: 'absolute', top: -6, right: 15 }}
                  onClick={() => {
                    openMenuFor(null);
                    if (!currentEntry.toothTreatment) {
                      setEmptyToothStateError(true);
                    }
                    updateEntry({
                      entry: {
                        id: expandedId,
                        formula_type: formulaType,
                        tooth_index: toothIndex,
                        tooth_state_mnemonic: currentEntry.toothTreatment ? currentEntry.toothState?.mnemo ?? '' : '',
                        tooth_treatment_mnemonic: '',
                      },
                      patientId: patientId,
                    });
                  }}
                >
                  <img src="./img/black/delete.svg" />
                </div>
              </BootstrapTooltip>
            ) : (
              <BootstrapTooltip title="Запись создана другим врачом" placement="right">
                <div style={{ position: 'absolute', top: -6, right: 15 }}>
                  <img src="./img/grey/delete.svg" />
                </div>
              </BootstrapTooltip>
            ))}
        </Tabs>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: tabNumber === ToothMenuType.STATE ? 120 : 50,
            }}
          >
            <div className="spinner" />
          </div>
        ) : (
          <StatesTable>
            {toothStateDictionary &&
              toothStateDictionary.map((states: ToothStateType, index) => (
                <StateRow key={index}>
                  <StateCol>
                    <StateBlock
                      openMenuFor={openMenuFor}
                      toothIndex={toothIndex}
                      teethFormulaState={teethFormulaState}
                      {...states[0]}
                      type={tabNumber}
                      formulaType={formulaType}
                      currentEntry={currentEntry}
                      actualToothStatus={actualToothStatus}
                      patientId={patientId}
                      doctor={doctor}
                      dateId={dateId}
                    />
                  </StateCol>
                  <StateCol>
                    <StateBlock
                      openMenuFor={openMenuFor}
                      toothIndex={toothIndex}
                      teethFormulaState={teethFormulaState}
                      {...states[1]}
                      type={tabNumber}
                      formulaType={formulaType}
                      currentEntry={currentEntry}
                      actualToothStatus={actualToothStatus}
                      patientId={patientId}
                      doctor={doctor}
                      dateId={dateId}
                    />
                  </StateCol>
                </StateRow>
              ))}
          </StatesTable>
        )}
      </Modal>
    </ClickAwayListener>
  );
}

export default connect((state: RootState) => ({ teethFormulaState: state.teethFormulaState }))(StateMenu);
