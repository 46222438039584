import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';

const useStyles = makeStyles((theme) => ({
  select: {
    position: 'relative',
    height: 20,
    minWidth: 38,
    background: 'white',
    color: '#515D6B',
    borderColor: '#D3D6DA',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    fontSize: 12,
    paddingRight: '0 !important',
    paddingLeft: 8,
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      borderColor: theme.palette.grey[400],
    },
    '&:focus': {
      borderRadius: '0 4px 4px 0',
      background: 'white',
      borderColor: blue[200],
    },
    '&.Mui-disabled': {
      color: '#A8AEB5',
      background: '#EFF0F3',
    },
  },
  icon: {
    color: '#515D6B',
    height: 12,
    width: 12,
    right: 4,
    top: 4,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  list: {
    padding: '8px 0',
    background: 'white',
    '& li': {
      color: '#515D6B',
      fontSize: 12,
      padding: '5px 12px',
    },
    '& li.Mui-selected': {
      background: '#F6F6FA',
    },
  },
}));

type SelectItem = {
  value: string;
  name: string;
};

type Props = {
  className?: string;
  value: string;
  disabled?: boolean;
  setValue: (value) => void;
  items: SelectItem[];
};

export const BorderSelect = ({ className, value, disabled = false, setValue, items }: Props) => {
  const classes = useStyles();

  const menuProps = {
    classes: {
      list: classes.list,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + ' ' + classes.icon} />;
  };

  const getName = (value) => {
    const item = items.find((i) => i.value === value);
    return item ? item.name : '';
  };

  return (
    <Select
      disableUnderline
      classes={{ root: cn(classes.select, className) }}
      IconComponent={iconComponent}
      MenuProps={menuProps}
      value={value}
      disabled={disabled}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => setValue(e.target.value)}
    >
      {items.map((v) => (
        <MenuItem key={v.value} value={v.value}>
          {getName(v.value)}
        </MenuItem>
      ))}
    </Select>
  );
};
