export const WS_ACTION_TYPES = {
  PING: 'ping',
  PONG: 'pong',

  START_EDIT_EMPLOYEE: 'start_edit_employee',
  STOP_EDIT_EMPLOYEE: 'stop_edit_employee',

  START_EDIT_PATIENT: 'start_edit_patient',
  STOP_EDIT_PATIENT: 'stop_edit_patient',

  START_EDIT_APPOINTMENT: 'start_edit_appointment',
  STOP_EDIT_APPOINTMENT: 'stop_edit_appointment',

  START_EDIT_PRICE: 'start_edit_price',
  STOP_EDIT_PRICE: 'stop_edit_price',

  CONNECT_MEDCARD: 'connect_medcard',
  START_EDIT_INVOICE: 'start_edit_invoice',
  STOP_EDIT_INVOICE: 'stop_edit_invoice',
  EDIT_INVOICE: 'edit_invoice',
  EDIT_MEDCARD: 'edit_medcard',

  START_EDIT_CLINIC: 'start_edit_clinic',
  STOP_EDIT_CLINIC: 'stop_edit_clinic',
};
