import { DATE_ERROR } from '../calendar/actions_calendar';
export const UPDATE_WORHOURS = 'update_workhours';
export const SET_WORKDAYS = 'set_workdays';
export const SET_WORKDAY_CABINET = 'set_workday_cabinet';
export const SET_WORKDAYS_CABINETS = 'set_workdays_cabinets';
export const SET_STAFFER_WORKDAYS = 'SET_STAFFER_WORKDAYS';
export const PICK_WORKDAY = 'PICK_WORKDAY';
export const SET_ODD = 'SET_ODD';
export const SET_EVEN = 'SET_EVEN';

export function setStafferWorkdays(days) {
  return {
    type: SET_STAFFER_WORKDAYS,
    payload: days,
  };
}

export function showError(message) {
  return {
    type: DATE_ERROR,
    payload: {
      show: true,
      message: message,
    },
  };
}
