export const STARTED_AMOUNT = 0;
export const DEFAULT_PAYER = 1;
export const DEFAULT_PAYMENT_TYPE = 2;
export const EMPTY = '';
export const ROWS_BY_PRICE = 'rowsByPrice';

export const DiscountTypeValues = {
  RUB: 'RUB',
  PERCENT: 'PERCENT',
};

export const DiscountTypeItems = [
  {
    value: DiscountTypeValues.PERCENT,
    name: '%',
  },
  {
    value: DiscountTypeValues.RUB,
    name: '₽',
  },
];
