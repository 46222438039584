import styled from 'styled-components';
import { Dialog } from '@material-ui/core';

export const StyledDialog = styled(Dialog)`
  & .MuiBackdrop-root {
    backdrop-filter: blur(4px);
    background-color: rgba(81, 93, 107, 0.6);
  }

  & .MuiDialog-paper {
    width: 416px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  }

  & .MuiDialogContent-root {
    background: #ffffff;
    flex-direction: column;
    padding: 24px 24px 0 64px;
    position: relative;
  }

  & .MuiDialogActions-root {
    background: #ffffff;
    padding: 24px;
  }

  & .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 16px;
  }
`;

export const Image = styled.img`
  display: block;
  left: 24px;
  position: absolute;
  top: 24px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #262626;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #595959;
  line-height: 22px;
`;
