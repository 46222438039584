import React from 'react';
import { AntSwitch } from '../styles';
import { connect } from 'react-redux';
import PriceSlice from '../state';
import { RootState } from '../../../store';

function EditSwitcher({ priceSlice, toggleEdit, common }) {
  return (
    <div
      style={{
        width: 'calc(100vw - 94px)',
        maxWidth: 1440,
        paddingTop: 20,
        marginBottom: 20,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: 5,
      }}
    >
      <div style={{ width: 1096 }}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {(common.user.permissions.edit_price || common.user.is_superuser) && (
            <div
              style={{
                marginRight: 10,
                fontSize: 14,
                color: '#A8AEB5',
                pointerEvents: 'auto',
              }}
            >
              Режим редактирования
            </div>
          )}
          {(common.user.permissions.edit_price || common.user.is_superuser) && (
            <AntSwitch style={{ pointerEvents: 'auto' }} checked={priceSlice.canEdit} onChange={toggleEdit} />
          )}
        </div>
      </div>
    </div>
  );
}

export default connect((state: RootState) => ({ priceSlice: state.priceSlice, common: state.common }), {
  toggleEdit: PriceSlice.actions.toggleEdit,
})(EditSwitcher);
