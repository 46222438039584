import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { routerConfig } from '../../navigation/routerConfig';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'flex',
      backgroundColor: '#fff',
    },
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 24,
      justifyContent: 'center',
    },
    title: {
      fontWeight: 400,
      fontSize: 24,
      color: '#253446',
    },
    text: {
      fontWeight: 400,
      fontSize: 18,
      color: '#7C8590',
    },
  }),
);
export const PermissionDenied = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <svg width="106" height="140" viewBox="0 0 106 140" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M92.375 52.5H88V35C88 15.6975 72.3025 0 53 0C33.6975 0 18 15.6975 18 35V52.5H13.625C6.39167 52.5 0.5 58.3858 0.5 65.625V126.875C0.5 134.114 6.39167 140 13.625 140H92.375C99.6083 140 105.5 134.114 105.5 126.875V65.625C105.5 58.3858 99.6083 52.5 92.375 52.5ZM29.6667 35C29.6667 22.1317 40.1317 11.6667 53 11.6667C65.8683 11.6667 76.3333 22.1317 76.3333 35V52.5H29.6667V35ZM58.8333 97.545V110.833C58.8333 114.053 56.2258 116.667 53 116.667C49.7742 116.667 47.1667 114.053 47.1667 110.833V97.545C43.6958 95.5208 41.3333 91.7992 41.3333 87.5C41.3333 81.0658 46.5658 75.8333 53 75.8333C59.4342 75.8333 64.6667 81.0658 64.6667 87.5C64.6667 91.7992 62.3042 95.5208 58.8333 97.545Z"
            fill="#E5E6EA"
          />
        </svg>

        <div className={classes.title}>Не удается получить доступ к странице</div>

        <div className={classes.text}>
          Перейти на{' '}
          <Link to={routerConfig.ROOT.path} replace>
            главную страницу
          </Link>
        </div>
      </div>
    </div>
  );
};
