/* eslint-disable import/no-named-as-default */
import toast from 'react-hot-toast';

export const getUpdatedSubInvoices = (oldSubInvoices, subInvoiceToUpdateId, accessorToUpdate, newValue) => {
  const updatedSubInvoices = oldSubInvoices.map((subInvoice) => {
    if (subInvoice.id === subInvoiceToUpdateId) {
      return { ...subInvoice, [accessorToUpdate]: newValue };
    }
    return subInvoice;
  });
  return updatedSubInvoices;
};

export const validateValue = (value, minimum, maximum) => {
  if (value === '') {
    toast.error(`Необходимо ввести новое значение`);
    return false;
  }

  if (isNaN(Number(value))) {
    toast.error(`Введённое значение не является числом`);
    return false;
  }

  if (minimum !== undefined && value < minimum) {
    toast.error(`Новое значение не может быть меньше ${minimum}`);
    return false;
  }

  if (maximum !== undefined && value > maximum) {
    toast.error(`Новое значение не может быть больше ${maximum}`);
    return false;
  }

  return true;
};

export const getRefundModificator = (row, parentRows) => {
  const paymentOperationsToCalculateFrom =
    row.depth === 0
      ? row.original.paymentOperations
      : row.subRows && row.subRows.length
      ? row.subRows
      : row.originalSubRows && row.originalSubRows.length
      ? row.originalSubRows
      : parentRows.find((parentRow) => parentRow.id === row.original.rowId)?.paymentOperations;

  const refundModificators = {
    payment: 0,
    paid: 0,
    remainder: 0,
  };

  paymentOperationsToCalculateFrom
    .filter((operation) => operation.payment < 0)
    .filter(
      (operation) =>
        operation.returned === (row.depth === 0 ? paymentOperationsToCalculateFrom[0].id : row.original.id),
    )
    .forEach((operation) => {
      if (operation.return_status === 'NODEBT') {
        refundModificators.paid += operation.payment;
        refundModificators.payment += operation.payment;
      } else if (operation.return_status === 'DEBT') {
        refundModificators.remainder += operation.payment;
        refundModificators.paid += operation.payment;
      }
    });

  return refundModificators;
};

export const getTotalsRefundModificator = (rows) => {
  const totalsRefundModificators = {
    totalPaymentRefundModificator: 0,
    totalPaidRefundModificator: 0,
  };

  rows.flatMap((row) =>
    row.paymentOperations
      .filter((paymentOperation) => paymentOperation.payment < 0)
      .forEach((operation) => {
        totalsRefundModificators.totalPaidRefundModificator += operation.payment;
        if (operation.return_status === 'NODEBT') {
          totalsRefundModificators.totalPaymentRefundModificator += operation.payment;
        }
      }),
  );

  return totalsRefundModificators;
};

const getReorderedSubRows = (subRows) => {
  const subRowsCopy = [...subRows];
  const elementsWithReturnStatus = subRowsCopy.filter((element) => element.return_status !== null);

  for (let i = elementsWithReturnStatus.length - 1; i >= 0; i--) {
    const returnElement = elementsWithReturnStatus[i];
    const returnId = returnElement.returned;
    const returnIndex = subRowsCopy.findIndex((element) => element.id === returnId);

    subRowsCopy.splice(
      subRowsCopy.findIndex((element) => element.id === elementsWithReturnStatus[i].id),
      1,
    );

    if (returnIndex !== -1) {
      subRowsCopy.splice(returnIndex + 1, 0, returnElement);
    } else {
      subRowsCopy.unshift(returnElement);
    }
  }

  return subRowsCopy;
};

export const getSubinvoiceCopyWithReorderedSubRows = (subinvoiceObj, subinvoiceId) => {
  const subinvoiceCopy = JSON.parse(JSON.stringify(subinvoiceObj));

  subinvoiceCopy[subinvoiceId]?.forEach((row) => {
    if (row.subRows.length > 1) {
      row.subRows = getReorderedSubRows(row.subRows);
    }
  });

  return subinvoiceCopy;
};
