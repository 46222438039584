import React, { useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { ReportName } from '../../enums';
import { BlueButton, OutlineButton } from '../ReportRevenueFilters/styles';
import Select from '../Select';
import { Blinds, Window, Title, FormatsWrap, ButtonsWrap, Data, DataTitle, DataContent } from './styles';
import { TRevenueFiltersInfo, TDebtsFiltersInfo } from './types';
import { getDebtsFiltersInfo, getRevenueFiltersInfo, performExport } from '../../helpers';

type ExportWindowProps = {
  cancel: () => void;
  reportType: typeof ReportName[keyof typeof ReportName];
};

const ExportModal = ({ cancel, reportType }: ExportWindowProps) => {
  const { revenueFilter, debtsAndAdvancesFilter, reportDisplayMode } = useAppSelector((state) => state.reports);
  const [exportType, setExportType] = useState('');
  const revenueFiltersInfo: TRevenueFiltersInfo = getRevenueFiltersInfo(revenueFilter);
  const debtsFiltersInfo: TDebtsFiltersInfo = getDebtsFiltersInfo(debtsAndAdvancesFilter);

  return (
    <Blinds onClick={cancel}>
      <Window
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Title>Экспорт отчета</Title>
        <FormatsWrap>
          <Select onChange={(value) => setExportType(value)} placeholder="Форматы" values={['pdf', 'word', 'excel']} />
        </FormatsWrap>
        <ButtonsWrap>
          <OutlineButton onClick={cancel}>Отмена</OutlineButton>
          <BlueButton
            onClick={() =>
              performExport({ mode: reportDisplayMode, exportType, revenueFilter, debtsAndAdvancesFilter })
            }
          >
            Экспортировать
          </BlueButton>
        </ButtonsWrap>
      </Window>
      <Window
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ maxHeight: '478px', overflowY: 'auto' }}
      >
        <Title active={false}>Параметры отчета</Title>
        {reportType === ReportName.REVENUE ? (
          <Data>
            <DataContent>
              <DataTitle>Период:</DataTitle>
              {revenueFiltersInfo.date || 'Не выбран'}
            </DataContent>
            <DataContent>
              <DataTitle>Врач:</DataTitle>
              <div>{revenueFiltersInfo.doctor || 'Не выбран'}</div>
            </DataContent>
            <DataContent>
              <DataTitle>Код услуги:</DataTitle>
              <div>{revenueFiltersInfo.code || 'Не выбран'}</div>
            </DataContent>
            <DataContent>
              <DataTitle>Наименование услуги:</DataTitle>
              <div>{revenueFiltersInfo.priceTitles || 'Не выбрано'}</div>
            </DataContent>
            <DataContent>
              <DataTitle>Способ оплаты:</DataTitle>
              <div>{revenueFiltersInfo.paymentTypes || 'Не выбран'}</div>
            </DataContent>
            <DataContent>
              <DataTitle>Плательщик:</DataTitle>
              <div>{revenueFiltersInfo.payers || 'Не выбран'}</div>
            </DataContent>
          </Data>
        ) : (
          <Data>
            <DataContent>
              <DataTitle>Период:</DataTitle>
              {debtsFiltersInfo.date || 'Не выбран'}
            </DataContent>
            <DataContent>
              <DataTitle>Данные:</DataTitle>
              <div>{debtsFiltersInfo.resources || 'Не выбраны'}</div>
            </DataContent>
          </Data>
        )}
      </Window>
    </Blinds>
  );
};

export default ExportModal;
