import React from 'react';
import { Wrapper, Icon } from './styled-components';
import { useHover } from '../../hooks/useHover';

/**
 * Кнопка со стрелкой для переключения дней
 * @param {string} id - id для элемента, HTML-атрибут
 * @param {function} handleClick - callback, вызывающийся по клику
 * @param {number} iconSize - ширина иконки в пикселях. По умолчанию - 14px в компоненте Icon
 * @param {boolean} isRight - флаг, нужно ли развернуть стрелку направо (по умолчанию показывает налево)
 * @param {boolean} disabled - флаг, задизейблена ли кнопка
 */

const ArrowButton = ({ id, handleClick, iconSize = 0, isRight = false, disabled }) => {
  const [hoverRef, isHovered] = useHover();

  return (
    <Wrapper id={id} onClick={handleClick} ref={hoverRef} isHighlighted={!!isHovered} disabled={disabled}>
      <Icon
        src={isHovered ? './img/blue/left-arrow.svg' : './img/black/left-arrow.svg'}
        alt="arrow_icon"
        isRight={isRight}
        iconSize={iconSize}
      />
    </Wrapper>
  );
};

export default ArrowButton;
