import React from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import FormulaState from './state';
import { ToothMenuType } from './StateMenu';
import { DoctorType, MedcardEntry, useAddEntryMutation, useUpdateEntryMutation } from '../../../services/medcard';
import { FormulaType } from './index';
import { EAccountStatus } from '../Account/AccountStatus';
import { useAddInvoiceMutation, useAddSubInvoiceMutation, useGetInvoicesQuery } from '../../../services/invoice';
import { RootState } from '../../../store';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
import { medcardJournalApi as api, useAddPlanRecordMutation } from '../../../services/medcardJournal';

const StateTitleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-right: 12px;
`;
const StateTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #515d6b;
  margin-right: auto;
`;
type StateIconProps = {
  iconColor: string;
};
const StateIcon = styled.div<StateIconProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: ${({ iconColor }) => iconColor};
`;
type StateBlockProps = {
  title: string;
  mnemo: string;
  icon: string;
  iconColor?: string;
  toothIndex: string;
  teethFormulaState: RootState['teethFormulaState'];
  type: ToothMenuType;
  formulaType: FormulaType;
  openMenuFor: (id) => void;
  setExpanded: ActionCreatorWithNonInferrablePayload;
  pickDate: ActionCreatorWithNonInferrablePayload;
  addCurrentDate: ActionCreatorWithNonInferrablePayload;
  currentEntry: MedcardEntry;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actualToothStatus: any;
  patientId: number;
  doctor: DoctorType;
  dateId: number;
};
function StateBlock({
  title,
  mnemo,
  icon,
  iconColor,
  toothIndex,
  teethFormulaState,
  type,
  formulaType,
  openMenuFor,
  setExpanded,
  addCurrentDate,
  pickDate,
  currentEntry,
  actualToothStatus,
  patientId,
  doctor,
  dateId,
}: StateBlockProps) {
  const dispatch = useDispatch();

  const { data: invoices = [] } = useGetInvoicesQuery(
    { dateId: dateId, patientId: patientId },
    { skip: !patientId || !dateId },
  );

  const { expandedId } = teethFormulaState;
  const [addEntry] = useAddEntryMutation();
  const [updateEntry] = useUpdateEntryMutation();
  const [addInvoice] = useAddInvoiceMutation();
  const [addSubInvoice] = useAddSubInvoiceMutation();
  const [addPlanRecord] = useAddPlanRecordMutation();

  return (
    <StateTitleContainer
      onClick={() => {
        openMenuFor(null);
        if (expandedId) {
          if (currentEntry && currentEntry.doctor.id === doctor.id) {
            updateEntry({
              entry: {
                id: expandedId,
                formula_type: formulaType,
                tooth_index: toothIndex,
                [type === ToothMenuType.STATE ? 'tooth_state_mnemonic' : 'tooth_treatment_mnemonic']: mnemo,
                [type === ToothMenuType.STATE ? 'tooth_treatment_mnemonic' : 'tooth_state_mnemonic']:
                  type === ToothMenuType.STATE
                    ? currentEntry.toothTreatment
                      ? currentEntry.toothTreatment.mnemo
                      : ''
                    : currentEntry.toothState
                    ? currentEntry.toothState.mnemo
                    : actualToothStatus?.toothState?.mnemo ?? '',
                tooth_state_label: '',
              },
              patientId: patientId,
            });
            dispatch(api.util.invalidateTags(['MedcardPlanJournalField']));
            return;
          }
        }
        addEntry({
          entry: {
            doctor: doctor.id,
            date_id: dateId,
            formula_type: formulaType,
            tooth_index: toothIndex,
            tooth_state_mnemonic: type === ToothMenuType.STATE ? mnemo : actualToothStatus?.toothState?.mnemo ?? '',
            tooth_treatment_mnemonic: type === ToothMenuType.TREATMENT ? mnemo : '',
          },
          patientId: patientId,
        })
          .unwrap()
          .then(({ id, date_id }) => {
            setTimeout(() => setExpanded(id));
            if (!dateId) {
              addCurrentDate(date_id);
              pickDate(date_id);
              addInvoice({ date_id })
                .unwrap()
                .then(({ id: invoiceId }) => {
                  addSubInvoice({ invoice: invoiceId, formula_id: id, status: EAccountStatus.NOT_FILLED });
                });
            }
            if (id) {
              addPlanRecord({
                patientId,
                entryId: id,
              });
              if (invoices?.length) {
                addSubInvoice({ invoice: invoices[0].id, formula_id: id, status: EAccountStatus.NOT_FILLED });
              }
            }
          });
      }}
    >
      <StateTitle>{title}</StateTitle>
      <StateIcon iconColor={iconColor}>{icon}</StateIcon>
    </StateTitleContainer>
  );
}

export default connect(() => ({}), {
  setExpanded: FormulaState.actions.setExpanded,
  pickDate: FormulaState.actions.pickDate,
  addCurrentDate: FormulaState.actions.addCurrentDate,
})(StateBlock);
