import React from 'react';
import { UseFiltersColumnProps } from 'react-table';
import { Column } from '../types';
import TextInput from '../../../uikit/TextInput';
import { Phone } from '../../../uikit/Phone';

export function DefaultColumnFilter<T extends object>(props: {
  column: UseFiltersColumnProps<T> & Column<T>;
  onFocus: () => void;
  onBlur: () => void;
}) {
  const {
    column: { id, filterValue, setFilter, applyMask },
    onFocus,
    onBlur,
  } = props;

  return id === 'phone' ? (
    <Phone
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={(value, code) => () => {
        setFilter(value ? `+${code} ${value}` : undefined);
      }}
      placeholder="Поиск"
      underlined
    />
  ) : (
    <TextInput
      onFocus={onFocus}
      onBlur={onBlur}
      value={filterValue || ''}
      onChange={(e) => {
        let value = e.target.value || undefined; // Set undefined to remove the filter entirely
        if (applyMask) {
          value = applyMask(value);
        }
        setFilter(value);
      }}
      placeholder={'Поиск'}
      underlined
    />
  );
}
