import * as yup from 'yup';

import { parse, isValid } from 'date-fns';

export const patientFormSchema = yup.object().shape({
  first_name: yup.string().required('Введите имя'),
  last_name: yup.string().required('Введите фамилию'),
  phone: yup
    .string()
    .required('Введите номер телефона')
    .matches(/^\(\d{3}\) \d{3}-\d{2}-\d{2}$/, 'Некорректный номер телефона'),
  birth_date: yup
    .string()
    .required('Введите дату рождения')
    .matches(/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/, 'Некорректная дата рождения')
    .test('valid-date', 'Invalid birth date', function (value) {
      const parsedDate = parse(value, 'dd.MM.yyyy', new Date());
      return isValid(parsedDate);
    }),
  email: yup.string().required('Введите e-mail').email('Некорректный e-mail'),
});
