import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 125px;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100vh - 125px);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background-color: #f9f9f9;
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #253446;
  text-align: center;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #a8aeb5;
  width: 314px;
  text-align: center;
  margin-top: 16px;
`;

export const Link = styled.span`
  cursor: pointer;
  color: #577bf9;
`;
