import React from 'react';
import { RevenueReportPage } from '../RevenueReportPage';
import { DebtReportPage } from '../DebtReportPage';
import { HistoryPage } from '../HistoryPage';
import { ReportName } from '../enums';

type Props = {
  reportName: ReportName;
};

const reportConfig = {
  [ReportName.REVENUE]: RevenueReportPage,
  [ReportName.DEBT]: DebtReportPage,
  [ReportName.HISTORY]: HistoryPage,
};

export function ReportBody({ reportName }: Props) {
  const ReportComponent = reportConfig[reportName];
  return ReportComponent && <ReportComponent />;
}
