import React from 'react';
import { Provider } from 'react-redux';
import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';
import AppNavigator from './navigation/AppNavigator';
import store from './store';
import { theme } from './themes/default';

function App() {
  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AppNavigator />
          <Toaster
            toastOptions={{
              duration: 2000,
              style: {
                borderRadius: '4px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
                color: '515D6B',
                fontSize: '14px',
                maxWidth: '800px',
              },
            }}
          />
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  );
}

export default App;
