import React, {CSSProperties, useEffect} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {AntTab, AntTabs} from "../../../../uikit/Tabs";
import request from "../../../../utils/request";
import {useAppSelector} from "../../../../store/hooks";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: 'calc(100% -14px)',
    maxWidth: 650,
    marginLeft: 14
  },
}));

type Props = {
  onCategoryFilter: Function;
}

export default function PriceTabs({onCategoryFilter}: Props) {
  const classes = useStyles();
  const [categories, setCategories] = React.useState([{label: 'Все категории', value: 0}]);
  const [value, setValue] = React.useState(0);

  const user = useAppSelector(state => state.common.user);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    onCategoryFilter(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      const categoriesData = await request.clinic.load_price_categories(user.clinic);
      setCategories(categories => [...categories, ...categoriesData.data.items.map(d => ({label: d.name, value: d.id}))])
    };
    fetchData();
  }, [])

  return (
    <div className={classes.root}>
      <AntTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
      >
        {categories.map(c => <AntTab label={c.label} value={c.value}/>)}
      </AntTabs>
    </div>
  );
}
