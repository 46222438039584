import React from 'react';
import styled from 'styled-components';
import useHover from '@react-hook/hover';
import { Collapse } from '@material-ui/core';
import { Label } from './styles';

type LabelWithHintProps = {
  label: React.ReactNode;
  hint?: React.ReactNode;
  disabled: boolean;
};

const Wrapper = styled.div`
  margin-top: -3px;
  transition-property: height;
  transition-duration: 1s;
`;

const StyledLabel = styled(Label)<{ isHovering: boolean; disabled: boolean }>`
  margin-bottom: ${({ isHovering }) => (isHovering ? '8px' : '0')};
  color: ${({ isHovering, disabled, theme }) => (isHovering && !disabled ? theme.palette.primary.main : '#595959')};
  font-size: 16px;
  line-height: 22px;
  transition: margin-bottom 0.2s;

  &:hover {
    color: ${({ disabled, theme }) => (disabled ? '#595959' : theme.palette.primary.main)};
  }
`;

const Hint = styled.div`
  color: #a8aeb5;
  font-size: 14px;
  line-height: 120%;
`;

export const LabelWithHint: React.FC<LabelWithHintProps> = ({ label, hint, disabled }) => {
  const hoverTarget = React.useRef(null);
  const isHovering = useHover(hoverTarget, {
    enterDelay: 2000,
    leaveDelay: 200,
  });

  return (
    <Wrapper ref={hoverTarget}>
      <StyledLabel isHovering={isHovering} disabled={disabled}>
        {label}
      </StyledLabel>
      <Collapse in={isHovering}>
        <Hint>{hint}</Hint>
      </Collapse>
    </Wrapper>
  );
};
