import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { connectToWS } from '../../features/messages/operations';
import { loginSave } from '../../features/legacyState';
import { HiddenIcon, LoginInfoIcon, VisibleIcon } from '../../uikit/Icons';
import { startWsEvents } from '../../features/sync/operations';
import { useLoginMutation } from '../../services/users';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { routerConfig } from '../../navigation/routerConfig';
import styled from 'styled-components';
import TextInput from '../../uikit/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';

const Container = styled.div`
  width: 452px;
  background: #ffffff;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 52px 48px 40px;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  margin-top: 52px;
  margin-bottom: 8px;
  color: #515d6b;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
`;

const Subtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #7c8590;
  margin-bottom: 32px;
`;

const StyledTextInput = styled(TextInput)`
  font-size: 16px;
  height: 40px;
  line-height: 22px;
  padding: 4px 8px;
  color: #515d6b;
  font-style: normal;
  font-weight: normal;
`;

const Label = styled.div`
  color: #253446;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 4px;
`;

const Button = styled.button`
  width: 144px;
  height: 40px;
  background: #577bf9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: 0;
  margin: 24px auto 0;
  :hover {
    background: #7995fa;
  }
  :focus {
    background: #7995fa;
  }
  :active {
    background: #4555e4;
  }
  :disabled,
  button[disabled] {
    background: #eff0f3;
    border: 1px solid #d3d6da;
    color: #a8aeb5;
  }
`;

const Link = styled.div`
  font-size: 16px;
  color: #577bf9;
  font-style: normal;
  font-weight: normal;
  margin: 36px auto 0;
  cursor: pointer;
`;

const PasswordIcon = styled.span`
  position: absolute;
  bottom: 197px;
  right: 60px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled.div`
  margin-top: 4px;
  color: #515d6b;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  height: 21px;
  align-items: center;
`;

const schema = yup.object().shape({
  id: yup.mixed().nullable(),
  role: yup.string(),
  username: yup.string().when({
    is: (val: string) => val.length === 0,
    then: yup.string().required('Введите логин и пароль'),
  }),
  password: yup.string().when({
    is: (val: string) => val.length === 0,
    then: yup.string().required('Введите логин и пароль'),
  }),
});

const Login = () => {
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = methods;
  const [login] = useLoginMutation();
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector((state) => state.common);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onSubmit = async ({ username, password }) => {
    try {
      const data = await login({ username, password: btoa(password) }).unwrap();

      sessionStorage.removeItem('id');
      sessionStorage.removeItem('login');
      sessionStorage.removeItem('password');
      sessionStorage.removeItem('access_token');

      sessionStorage.setItem('id', data.user.id);
      sessionStorage.setItem('login', username);
      sessionStorage.setItem('password', btoa(password));
      sessionStorage.setItem('access_token', data.access_token);

      dispatch(loginSave(data, true));
    } catch (error) {
      if (error.data !== '') {
        toast.error(error.data);
      } else {
        setError('username', {
          type: 'manual',
          message: 'Такого логина или пароля не зарегистрировано',
        });
      }
    }
  };

  const redirect = () => {
    dispatch(connectToWS());
    startWsEvents(dispatch);
    navigate(routerConfig.SCHEDULE.path);
  };

  return (
    <Container>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <img src="./img/black/logo.svg" alt={'logo'} width={158} height={48} style={{ margin: 'auto' }} />
          <Title>Добро пожаловать!</Title>
          <Subtitle>Пожалуйста, введите данные для входа</Subtitle>
          <div>
            <Label>Логин</Label>
            <StyledTextInput
              name="username"
              autoComplete="off"
              {...register('username')}
              style={{ marginBottom: '20px' }}
            />
          </div>
          <div>
            <Label>Пароль</Label>
            <StyledTextInput
              name="password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="new-password"
              {...register('password')}
            />
            <PasswordIcon onClick={() => setShowPassword((show) => !show)}>
              {showPassword ? <VisibleIcon /> : <HiddenIcon style={{ color: '#A8AEB5' }} />}
            </PasswordIcon>
          </div>
          <ErrorMessage>
            {(errors?.username || errors?.password) && (
              <>
                <LoginInfoIcon style={{ marginRight: '3px' }} />
                <div>{errors?.password?.message || errors?.username?.message}</div>
              </>
            )}
          </ErrorMessage>
          <Button type="submit" disabled={isSubmitting}>
            Войти
          </Button>
        </form>
        <Link>Получить логин и пароль</Link>
        {isAuth ? redirect() : ''}
      </FormProvider>
    </Container>
  );
};

export default Login;
