import React from "react";

export default function NoDialogsFiller() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 85,
      }}
    >
      <img
        src="./img/grey/dialog.png"
        width="80px"
        alt="иконки чатов"
        style={{ marginBottom: 25 }}
      />
      <div
        style={{
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: 14,
          color: "#A8AEB5",
          width: 313,
          textAlign: "center",
        }}
      >
        Чтобы начать диалог нажмите на фамилию сотрудника на любой странице
      </div>
    </div>
  );
}
