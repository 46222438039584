import { getWeekdaysToHighlightType, newModelType } from './types';
import { setSelectedDate } from '../../../reducer';

export const getWeekdaysToHighlight: getWeekdaysToHighlightType = (datesWithSessions, year, month) => {
  if (!datesWithSessions) return [];
  const date = new Date(year, month, 1);
  const newModel: newModelType = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] };
  while (date.getMonth() === month) {
    newModel[date.getDay()] = [...newModel[date.getDay()], new Date(date).getTime()];
    date.setDate(date.getDate() + 1);
  }
  Object.keys(newModel).forEach(
    (weekday) => (newModel[weekday] = !!newModel[weekday].filter((date) => datesWithSessions.has(date)).length),
  );
  return newModel;
};
export const handleDateChange = (dateArg, selectedDate, datesToRender, selectedCabinets, dispatch) => {
  const newDateValue = selectedDate !== dateArg.setHours(0, 0, 0, 0) ? dateArg.setHours(0, 0, 0, 0) : 0;
  dispatch(setSelectedDate(newDateValue));
};
