import styled from "styled-components";

export const InputContainer = styled.div`
  width: 100%;
  height: 30px;
`;

export const FillerContainer = styled.div`
  width: 188px;
  height: 60px;
  margin: 7px;
  background-color: blue;
`;

export const PatientInfoLayout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 24px;
  margin-top: 32px;
`;
export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 18px;
`;
export const PatientName = styled.div`
  width: 798px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 120%;
  color: #253446;
`;
export const Phone = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  margin-top: 8px;
  color: #515d6b;
`;

export const Title = styled.div`
  width: 138px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #253446;
`;
export const Spacer = styled.div`
  width: 6px;
  height: 2px;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #253446;
  margin-bottom: 4px;
`;
type InputBlockProps = {
  width: number;
};
export const InputBlock = styled.div<InputBlockProps>`
  width: ${({ width }) => width}px;
  margin-right: 24px;
`;
export const InputBlockContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 56px;
`;

export const LinkToPayments = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #577bf9;
  margin-top: 6px;
  cursor: pointer;
  margin-bottom: 16px;
`;

type ContainerProps = {
  isExpanded: boolean;
};
export const ExpanderContainer = styled.div`
  width: 100%;
  height: 50px;
  overflow: hidden;
`;
export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: ${({ isExpanded }) => (isExpanded ? 210 : 0)}px;
  transition: 0.25s;
  overflow: ${({ isExpanded }) => (isExpanded ? "visible" : "hidden")};
  padding-top: 10px;
`;
export const Expander = styled.div`
  padding-right: 60px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ExpanderLine = styled.div`
  flex: 1;
  height: 1px;
  background-color: #d3d6da;
`;

export const ExpandButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);
`;
type BalanceProps = {
  value: number;
};
export const Balance = styled.div<BalanceProps>`
  margin-top: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.06em;
  color: ${({ value }) => (value > 0 ? "#6cd0ad" : "#DF194C")};
`;
type TextAreaContainerProps = {
  height: number;
};
export const TextAreaBlockContent = styled.div<TextAreaContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: ${({ height }) => height}px;
`;

export const TextAreaContainer = styled.div<TextAreaContainerProps>`
  width: 100%;
  height: ${({ height }) => height}px;
`;
