import React from 'react';
import styled from 'styled-components';
import { Cabinet } from './Cabinet';

import {
  useAddCabinetMutation,
  useDeleteCabinetMutation,
  useEditCabinetMutation,
  useGetCabinetsDictionaryQuery,
} from '../../../../services/dictionaries';
// eslint-disable-next-line  import/no-named-as-default
import toast from 'react-hot-toast';

const Title = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #253446;
  margin-bottom: 20px;
`;

const AddCabinet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  border: 1px dashed #828282;
  border-radius: 8px;
  color: #577bf9;
  width: 168px;
  height: 64px;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  line-height: 100%;
  cursor: pointer;
  margin-right: 48px;
  margin-bottom: 24px;
  background: #f9f9f9;

  &:hover {
    border: 1px dashed #577bf9;
  }
`;

export function Cabinets() {
  const { data: cabinets = [] } = useGetCabinetsDictionaryQuery();
  const [addCabinet] = useAddCabinetMutation();
  const [editCabinet] = useEditCabinetMutation();
  const [deleteCabinet] = useDeleteCabinetMutation();

  const handleAddCabinet = (is_medical) => () => {
    addCabinet({ title: 'Новый кабинет', is_medical: is_medical })
      .unwrap()
      .then(() => toast.success('Изменения успешно сохранены'));
  };

  const handleChangeName = (id) => (title) => {
    const cabinet = cabinets.find((cab) => cab.id === id);
    if (cabinet?.title !== title) {
      editCabinet({ id, title })
        .unwrap()
        .then(() => toast.success('Изменения успешно сохранены'));
    }
  };

  const handleDeleteCabinet = (id) => () => {
    deleteCabinet(id)
      .unwrap()
      .then(() => toast.success('Изменения успешно сохранены'));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#f9f9f9' }}>
      <div style={{ height: 'fit-content' }}>
        <Title>С возможностью записи пациентов</Title>
        <div style={{ display: 'flex', flex: 1, flexWrap: 'wrap' }}>
          <AddCabinet onClick={handleAddCabinet(true)}>Добавить кабинет</AddCabinet>
          {cabinets
            .filter((cab) => cab.is_medical)
            .map((cab) => (
              <Cabinet
                key={cab.id}
                name={cab.title}
                setName={handleChangeName(cab.id)}
                onDelete={handleDeleteCabinet(cab.id)}
              />
            ))}
        </div>
      </div>
      <div>
        <Title style={{ marginTop: '20px' }}>Без возможности записи пациентов</Title>
        <div style={{ display: 'flex', flex: 1, flexWrap: 'wrap' }}>
          <AddCabinet onClick={handleAddCabinet(false)}>Добавить кабинет</AddCabinet>
          {cabinets
            .filter((cab) => !cab.is_medical)
            .map((cab) => (
              <Cabinet
                key={cab.id}
                name={cab.title}
                setName={handleChangeName(cab.id)}
                onDelete={handleDeleteCabinet(cab.id)}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
