/* eslint-disable no-case-declarations */
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import {
  AppointmentWrapper,
  Wrapper,
  Info,
  PatientNameWrapper,
  IconWrapper,
  ResourceSpan,
  InfoPopup,
  BlockerWrapper,
  Blocker,
} from './styled-components';
import padTo2Digits from '../../../utils/padTo2Digits';
import { format } from 'date-fns';
import russianLocale from 'date-fns/locale/ru';
import { capitalize } from '../../../features/staff/components/StaffScheduleSetup/components/StaffSchedulerTable/functions';
import { useHover } from '../../../hooks/useHover';
import { declension, getStatusStringFromStatus, truncateString } from './functions';
import { VisitStatusIcon } from '../global/visitStatus';
import Tooltip from '../../../components/Tooltip/Tooltip';

export const EventContent = ({ args }) => {
  const [hoverRef, isHovered] = useHover();
  const calendarRef = useRef(null);

  switch (args.event._def.ui.display) {
    case null:
      if (calendarRef.current) {
        const appointmentParentNode = calendarRef.current.parentElement.parentElement;
        const blockerComponent = (
          <Tooltip
            title={`Работает ${args.event._def.extendedProps.currentRedactor}. Одновременная работа невозможна`}
            placement={'bottom-start'}
            enterDelay={500}
            enterNextDelay={500}
            className="blockerTooltip"
          >
            <Blocker />
          </Tooltip>
        );

        if (args.event._def.extendedProps.currentRedactor) {
          appointmentParentNode.style.pointerEvents = 'none';

          const blockerContainer = document.createElement('div');
          blockerContainer.setAttribute('id', `appointmentBlocker-${args.event._def.publicId}`);
          ReactDOM.render(blockerComponent, blockerContainer);

          appointmentParentNode.insertAdjacentElement('afterend', blockerContainer);
        } else {
          appointmentParentNode.style.pointerEvents = 'auto';
          const blockerContainer = document.getElementById(`appointmentBlocker-${args.event._def.publicId}`);
          if (blockerContainer) {
            blockerContainer.parentNode.removeChild(blockerContainer);
          }
        }
      }

      // Величина времени между началом и концом визита в минутах
      const timeDeltaInMinutes =
        (args.event._instance.range.end.getTime() - args.event._instance.range.start.getTime()) / 1000 / 60;
      const patientName = `${args.event._def.extendedProps.patient.last_name} ${args.event._def.extendedProps.patient.first_name} ${args.event._def.extendedProps.patient.second_name}`;

      return (
        // Обёртка, меняет внешний вид и раскладку в зависимости от своего размера и флагов
        <AppointmentWrapper
          id={`appointment-${args.event._def.publicId}`}
          ref={(e) => {
            calendarRef.current = e;
            hoverRef(e);
          }}
          onMouseEnter={() => (calendarRef.current.parentElement.parentElement.parentElement.style.zIndex = 2)}
          onMouseLeave={() => (calendarRef.current.parentElement.parentElement.parentElement.style.zIndex = 1)}
          isHovered={!!isHovered}
          isSmall={timeDeltaInMinutes <= 30}
          isDraggingOrResizing={args.event._def.extendedProps.isDraggingOrResizing}
          isHidden={args.event._def.extendedProps.isHidden || args.event._def.extendedProps.cancel_reason}
          isIrrelevant={
            args.event._def.extendedProps.isIrrelevant || Boolean(args.event._def.extendedProps.currentRedactor)
          }
          className="tableAppointment"
        >
          {/* Иконка комментария */}
          {args.event._def.extendedProps.comment && (
            <Tooltip
              title={args.event._def.extendedProps.comment}
              placement={'top'}
              enterDelay={500}
              enterNextDelay={500}
            >
              <IconWrapper right={timeDeltaInMinutes === 15 ? '11px' : '23px'} isTiny={timeDeltaInMinutes === 15}>
                <VisitStatusIcon value={'COMMENT'} />
              </IconWrapper>
            </Tooltip>
          )}

          {/* Иконка статуса */}
          <Tooltip
            title={getStatusStringFromStatus(args.event._def.extendedProps.status)}
            placement={'right'}
            enterDelay={500}
            enterNextDelay={500}
          >
            <IconWrapper right={timeDeltaInMinutes === 15 ? '-1px' : '3px'} isTiny={timeDeltaInMinutes === 15}>
              <VisitStatusIcon value={args.event._def.extendedProps.status} />
            </IconWrapper>
          </Tooltip>

          {/* Имя пациента, сокращается при длине имени больше 31 символа */}
          <PatientNameWrapper
            isTiny={timeDeltaInMinutes === 15 || timeDeltaInMinutes === 60}
            isSmall={timeDeltaInMinutes === 30}
          >
            <Info
              color={args.event._def.extendedProps.isIrrelevant ? '#7C8590' : '#253446'}
              fontSize={timeDeltaInMinutes === 15 ? 10 : 12}
            >
              {`${
                patientName.length > 31
                  ? args.event._def.extendedProps.patient.last_name +
                      ' ' +
                      args.event._def.extendedProps.patient.first_name[0] +
                      '. ' +
                      args.event._def.extendedProps.patient.second_name?.[0]?.concat('.') ?? ''
                  : patientName
              }`}
            </Info>
          </PatientNameWrapper>

          {/* Время визита и Имя врача*/}
          {timeDeltaInMinutes > 30 && (
            <>
              <Info color={args.event._def.extendedProps.isIrrelevant ? '#A8AEB5' : '#7C8590'} fontSize={10}>
                {args.timeText}
              </Info>
            </>
          )}

          {timeDeltaInMinutes > 45 && (
            <>
              <Info color={args.event._def.extendedProps.isIrrelevant ? '#A8AEB5' : '#7C8590'} fontSize={10}>
                {`Врач: 
                  ${args.event._def.extendedProps.doctor?.last_name || 'Неизвестно'}
                  ${args.event._def.extendedProps.doctor?.first_name[0] || 'Н'}. 
                  ${args.event._def.extendedProps.doctor?.second_name[0] || 'Н'}.`}
              </Info>
            </>
          )}

          {/* Если длительность визита больше или равно 1 часу, появляется длительность визита */}
          {timeDeltaInMinutes >= 60 && (
            <Info color={args.event._def.extendedProps.isIrrelevant ? '#A8AEB5' : '#7C8590'} fontSize={10}>
              {`Длительность: 
                  ${Math.floor(timeDeltaInMinutes / 60)} ${declension(Math.floor(timeDeltaInMinutes / 60), [
                'час',
                'часа',
                'часов',
              ])}
                  ${
                    timeDeltaInMinutes % 60
                      ? (timeDeltaInMinutes % 60) +
                        ' ' +
                        declension(Math.floor(timeDeltaInMinutes % 60), ['минута', 'минуты', 'минут'])
                      : ''
                  }
              `}
            </Info>
          )}

          {/* Всплывающее окно с временем визита и именем врача, появлется при наведении мыши & времени визита <= 30 минут */}
          {
            <InfoPopup
              isOpened={timeDeltaInMinutes <= 30 && isHovered}
              isIrrelevant={args.event._def.extendedProps.isIrrelevant}
            >
              <Info
                color={args.event._def.extendedProps.isIrrelevant ? '#A8AEB5' : '#7C8590'}
                fontSize={timeDeltaInMinutes === 15 ? 9 : 10}
              >
                {args.timeText}
              </Info>
              <Info
                color={args.event._def.extendedProps.isIrrelevant ? '#A8AEB5' : '#7C8590'}
                fontSize={timeDeltaInMinutes === 15 ? 9 : 10}
              >
                {`Врач: 
                  ${args.event._def.extendedProps.doctor?.last_name || 'Неизвестно'}
                  ${args.event._def.extendedProps.doctor?.first_name[0] || 'Н'}. 
                  ${args.event._def.extendedProps.doctor?.second_name[0] || 'Н'}.`}
              </Info>
            </InfoPopup>
          }
        </AppointmentWrapper>
      );

    case 'background':
      const timezoneOffset = new Date().getTimezoneOffset() * 60000;
      return (
        <Wrapper id={`session-${args.event._def.publicId}`} isBackground>
          <Info color={'#000000'} fontSize={12}>
            {args.event._def.title}
          </Info>
          <Info color={'#000000'} fontSize={10}>
            {`
                    ${
                      new Date(args.event._instance.range.start.getTime() + timezoneOffset).getHours() +
                      ':' +
                      padTo2Digits(args.event._instance.range.start.getMinutes())
                    } - 
                    ${
                      new Date(args.event._instance.range.end.getTime() + timezoneOffset).getHours() +
                      ':' +
                      padTo2Digits(args.event._instance.range.end.getMinutes())
                    }
                `}
          </Info>
        </Wrapper>
      );

    case 'block':
      return <BlockerWrapper />;
  }
};

export const ResourceLabelContent = ({ args }) => {
  const resourceName = args.resource._resource.extendedProps.name
    ? truncateString(args.resource._resource.extendedProps.name, 9)
    : truncateString(args.resource._resource.title, 9);

  return (
    <div>
      <ResourceSpan isPrimary>
        {capitalize(format(args.date, 'EEEEEE d MMM', { locale: russianLocale })).slice(0, -1)}
      </ResourceSpan>
      <ResourceSpan>{resourceName}</ResourceSpan>
    </div>
  );
};
