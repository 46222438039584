import { SET_CABINET_FIELD, LOAD_CABINETS } from "./types";
import { FLUSH_STATE } from "../../flushState/types";
const INITIAL_STATE = { value: "Выбрать кабинет", cabinets: [] };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CABINET_FIELD:
      return {
        ...state,
        ...{ value: action.payload },
      };
    case LOAD_CABINETS:
      return {
        ...state,
        ...{ cabinets: action.payload },
      };
    case FLUSH_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
