import React, { useMemo, useState, useEffect, useCallback, useRef, FC } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-named-as-default
/* eslint-disable */
import toast from 'react-hot-toast';
import isEmail from 'validator/es/lib/isEmail';
import isEmpty from 'validator/es/lib/isEmpty';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import { Table, Column, TableAction, TableActionHandler, FetchDataHandler } from '../../../components/Table';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  userApi,
  useAddUserMutation,
  useUpdateUserMutation,
  useUpdateCredentialsMutation,
  useSetPermissionsMutation,
  UserPermissions,
  useSearchStaffQuery,
  User,
  useGetPermissionsQuery,
} from '../../../services/users';
import { getMask } from '../../../utils/masks';
import { BlockIcon } from '../../../uikit/Icons';

import { Access } from './Access';
import { BlockAccount } from './BlockAccount';
import { BlockAppWarning } from './BlockAppWarning';
import StaffScheduleSetup from './StaffScheduleSetup';
import { TStaffData } from '../types';
import { useLocation } from 'react-router-dom';
import { routerConfig } from '../../../navigation/routerConfig';
import EventsWebSocket, { WEBSOCKET_STATUS } from '../../../websocket';
import { startEditEmployeeAction, stopEditEmployeeAction } from '../../../websocket/actionCreators';
import { wsUrl } from '../../../utils/request';
import { startEditClinic } from '../../sync/operations';

const TableContainer = styled.div`
  display: block;
  max-width: 100%;
`;

interface IProps {
  isEditMode?: boolean;
}

export const StaffTable: FC<IProps> = React.memo(({ isEditMode = true }) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const [data, setData] = useState<TStaffData[]>([]);
  const {
    user: { clinic, id, is_superuser: isSuperCommonUser },
    sessions: clinicSession,
  } = useAppSelector((state) => state.common);
  const [addUser, { isLoading: isCreating }] = useAddUserMutation();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const [updateCredentials] = useUpdateCredentialsMutation();
  const [setPermissions, { isLoading: isSettingPermissions }] = useSetPermissionsMutation();
  const [isAccessOpen, setIsAccessOpen] = useState(false);
  const [user, setUser] = useState<Partial<User>>({});
  const [onAccessSave, setOnAccessSave] = useState<
    ((user: Partial<User>, permissions: UserPermissions) => Promise<void>) | undefined
  >(undefined);
  const { data: permissions = {} } = useGetPermissionsQuery(id);
  const [canViewContacts, setCanViewContacts] = useState(permissions['view_staff_contacts']);
  const [searchQuery, setSearchQuery] = useState<Record<string, string>>(null);
  const { data: searchStaff } = useSearchStaffQuery(searchQuery, { skip: !searchQuery });
  const [isBlockAccountOpen, setIsBlockAccountOpen] = useState(false);
  const [isScheduleSetupOpen, setScheduleSetupOpen] = useState(false);
  const [isBlockAppWarningOpen, setIsBlockAppWarningOpen] = useState(false);

  const [sessions, setSessions] = useState([]);

  const socket = useRef();

  const clinicEditors = clinicSession.filter((s) => s.session_user_id !== id);

  const columns: Column<TStaffData>[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Active',
        accessor: 'is_active',
      },
      {
        Header: 'Цвет',
        accessor: 'color',
      },
      {
        Header: 'Фамилия',
        accessor: 'last_name',
        required: true,
        isOpenChat: true,
        isFio: true,
      },
      {
        Header: 'Имя',
        accessor: 'first_name',
        required: true,
        isFio: true,
      },
      {
        Header: 'Отчество',
        accessor: 'second_name',
        isFio: true,
      },
      {
        Header: 'Должность',
        accessor: 'job',
        required: true,
      },
      {
        Header: 'Телефон',
        accessor: 'phone',
        required: true,
        isHidden: !canViewContacts,
        width: 170,
      },
      {
        Header: 'Email',
        accessor: 'email',
        isHidden: !canViewContacts,
        validate: (value) => ({ isValid: isEmail(value || '') || isEmpty(value || ''), message: 'Некорректный email' }),
        applyMask: (value) => (!value ? value : getMask('email').resolve(value)),
      },
    ],
    [canViewContacts],
  );

  const hiddenColumns: string[] = ['id', 'is_active', 'color'];

  useEffect(() => {
    if (!searchStaff) {
      return;
    }

    const staffers = searchStaff
      .map(({ id, is_active, second_name, last_name, first_name, job, email, phone, color, role, is_superuser }) => ({
        id,
        is_active,
        second_name,
        first_name,
        last_name,
        job,
        email,
        phone,
        color,
        role,
        is_superuser,
      }))
      .map((staffer) => {
        const editor = sessions.find((s) => s.target_id === staffer.id)?.session_user_name ?? null;
        return { ...staffer, editor };
      });

    const superUser = staffers.find(({ is_superuser }) => is_superuser);
    if (superUser) {
      setData([superUser, ...staffers.filter(({ is_superuser }) => !is_superuser)]);
    } else {
      setData(staffers);
    }
  }, [searchStaff, sessions]);

  useEffect(() => {
    if (!searchQuery || !searchStaff) {
      return;
    }
    const { phone: phoneSearch, email: emailSearch } = searchQuery;
    const isContactFound = searchStaff.every(
      ({ phone, email }) =>
        (phoneSearch && phoneSearch.length === 18 && phone === phoneSearch) ||
        (emailSearch && isEmail(emailSearch) && email === emailSearch),
    );
    setCanViewContacts(permissions['view_staff_contacts'] || isContactFound);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStaff, searchQuery, permissions, isEmail]);

  const onAccessActionClickHandler: TableActionHandler<TStaffData> = (row) => {
    const { values } = row;
    setUser(values);
    setOnAccessSave(() => async (user, permissions) => {
      if (user.password && user.username) {
        await updateCredentials({
          id: user.id,
          new_password: user.password,
          new_username: user.username,
        });
      }
      await toast.promise(
        updateUser({
          second_name: user.second_name,
          first_name: user.first_name,
          last_name: user.last_name,
          job: user.job,
          phone: user.phone,
          email: user.email,
          role: user.role,
          clinic: user.clinic,
          is_active: user.is_active,
          id: user.id,
          is_superuser: user.is_superuser,
        })
          .unwrap()
          .then(({ id }) => {
            if (permissions) {
              return setPermissions({ userId: id, permissions }).unwrap();
            }
          }),
        {
          loading: 'Сохранение...',
          success: 'Сотрудник успешно изменён',
          error: 'Сотрудник не изменён',
        },
      );
    });
    setIsAccessOpen(true);
  };

  const handleWsMessage = (msg) => {
    if (msg.data) {
      setSessions(msg.data);
    }
  };

  const handleStartEdit = (userId) => {
    socket.current?.send(startEditEmployeeAction(userId));
  };

  const handleStopEdit = (userId) => {
    socket.current?.send(stopEditEmployeeAction(userId));
  };

  useEffect(() => {
    socket.current = new EventsWebSocket(wsUrl.employees, handleWsMessage);
    return () => socket.current.disconnect();
  }, []);

  useEffect(() => {
    if (socket.current?.readyState() === WEBSOCKET_STATUS.OPEN && user.id) {
      if (isAccessOpen || isBlockAccountOpen) {
        handleStartEdit(user.id);
      } else {
        handleStopEdit(user.id);
      }
    }
  }, [isAccessOpen, isBlockAccountOpen, socket.current, user.id]);

  useEffect(() => {
    dispatch(userApi.util.invalidateTags(['Users']));
  }, [sessions]);

  const onBlockAccountActionClickHandler: TableActionHandler<TStaffData> = (row) => {
    const { values } = row;
    setUser(values);
    setIsBlockAccountOpen(true);
  };

  const onBlockAccountHandler = async () => {
    const { id } = user;
    await toast.promise(updateUser({ id, is_active: false }).unwrap(), {
      loading: 'Сохранение...',
      success: `Сотрудник успешно заблокирован`,
      error: `Сотрудник не заблокирован`,
    });
    setIsBlockAccountOpen(false);
  };

  const onUnblockAccountActionClickHandler: TableActionHandler<TStaffData> = async (row) => {
    const {
      values: { id },
    } = row;
    await toast.promise(updateUser({ id, is_active: true }).unwrap(), {
      loading: 'Сохранение...',
      success: `Сотрудник успешно разблокирован`,
      error: `Сотрудник не разблокирован`,
    });
  };

  const onScheduleActionClickHandler: TableActionHandler<TStaffData> = (row) => {
    const { values } = row;
    setUser(values);
    // setScheduleSetupOpen(true);
    if (location.pathname === routerConfig.STAFF.path) {
      setIsBlockAppWarningOpen(true);
    } else {
      setScheduleSetupOpen(true);
    }
  };

  const actions: TableAction<TStaffData>[] = [
    {
      label: 'Настройка расписания',
      onClick: onScheduleActionClickHandler,
      isHidden: ({ values: { is_active } }) => !permissions['edit_staff_schedule'] || !is_active,
    },
    {
      label: 'Доступы',
      onClick: onAccessActionClickHandler,
      isHidden: ({ values: { is_active } }) => !permissions['view_staff_access'] || !is_active,
    },
    {
      label: 'Заблокировать аккаунт',
      onClick: onBlockAccountActionClickHandler,
      isHidden: ({ values: { is_active }, original: { is_superuser } }) =>
        !isSuperCommonUser || !is_active || is_superuser,
    },
    {
      label: 'Разблокировать аккаунт',
      onClick: onUnblockAccountActionClickHandler,
      isHidden: ({ values: { is_active } }) => !isSuperCommonUser || is_active,
    },
  ];

  const onNewEntryHandler = async (data: TStaffData, onSuccess: () => void) => {
    setUser(data);
    setOnAccessSave(() => async (user, permissions) => {
      await toast.promise(
        addUser(user)
          .unwrap()
          .then(({ id }) => {
            return setPermissions({ userId: id, permissions }).unwrap();
          }),
        {
          loading: 'Сохранение...',
          success: 'Сотрудник успешно создан',
          error: 'Сотрудник не создан',
        },
      );
      onSuccess();
    });
    setIsAccessOpen(true);
  };

  const onEditEntryHandler = async (data: TStaffData, onSuccess: () => void) => {
    await toast.promise(updateUser(data).unwrap(), {
      loading: 'Сохранение...',
      success: 'Сотрудник успешно изменён',
      error: 'Сотрудник не изменён',
    });
    onSuccess();
  };

  const onFetchData: FetchDataHandler<TStaffData> = ({ filters }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filtersHash = filters.reduce<Record<keyof TStaffData | string, any>>((acc, { id, value }) => {
      if (id === 'phone' && value.length < 18 && !permissions['view_staff_contacts']) {
        return acc;
      }
      if (id === 'email' && !isEmail(value) && !permissions['view_staff_contacts']) {
        return acc;
      }
      return {
        ...acc,
        [id]: value,
      };
    }, {});
    const queryParams = {
      ...filtersHash,
      clinic: clinic.id,
    };
    setSearchQuery(queryParams);
  };
  const onScheduleClose = useCallback(() => setScheduleSetupOpen(false), []);

  return (
    <TableContainer>
      <Table<TStaffData>
        columns={columns}
        data={data}
        setData={setData}
        actions={actions}
        sessions={sessions}
        noDataText={'Для добавления нового сотрудника нажмите на плюс'}
        hiddenColumns={hiddenColumns}
        isLoading={isCreating || isUpdating || isSettingPermissions}
        canAddNewEntry={isEditMode && permissions['edit_staff']}
        canEditEntry={permissions['edit_staff']}
        onNewEntry={onNewEntryHandler}
        onEditEntry={onEditEntryHandler}
        onStartEdit={handleStartEdit}
        onStopEdit={handleStopEdit}
        visibleRowCount={12}
        onFetchData={onFetchData}
        manualFilters={true}
        disabledRowResolver={({ values: { is_active } }) => !is_active}
        disabledRowIcon={<BlockIcon />}
        disabledRowTooltipText={'Аккаунт заблокирован'}
        rowTooltipText={'Настройте доступ и расписание'}
        rowBackground="#F9F9F9"
        shouldHighlightRow={({ original: { is_superuser } }) => !!is_superuser}
        shouldDisplayCellTooltip={({ original: { is_superuser } }, { id }) => is_superuser && id === 'job'}
        cellTooltipContent={({ values: { job } }) => (
          <>
            <span style={{ color: '#253446', fontWeight: 500 }}>{job}</span>
            <br />
            <InfoOutlined style={{ color: '#A8AEB5', width: 18 }} />
            <span style={{ color: '#A8AEB5', marginLeft: 4 }}>Администратор программы</span>
          </>
        )}
      />
      <Access
        open={isAccessOpen}
        setOpen={setIsAccessOpen}
        user={user}
        onSave={onAccessSave}
        isLoading={isCreating || isUpdating || isSettingPermissions}
        disabled={!permissions['edit_staff'] && permissions['view_staff_access']}
      />
      <BlockAccount
        open={isBlockAccountOpen}
        onClose={() => setIsBlockAccountOpen(false)}
        onSave={onBlockAccountHandler}
      />
      {(!clinicEditors?.length || isSuperCommonUser) && (
        <BlockAppWarning
          open={isBlockAppWarningOpen}
          onClose={() => setIsBlockAppWarningOpen(false)}
          onContinue={() => {
            setScheduleSetupOpen(true);
            setIsBlockAppWarningOpen(false);
            startEditClinic(id);
          }}
        />
      )}
      {isScheduleSetupOpen && <StaffScheduleSetup onClose={onScheduleClose} pickedStafferId={user.id} />}
    </TableContainer>
  );
});
