import request from '../../utils/request';
import format from 'date-fns/format';

export const UPDATE_USER = 'UPDATE_USER';
export const LOGIN_SAVE = 'login_save';
export const CABINETS_GET = 'cabinets_get';
export const LOAD_PROFILE = 'load_profile';
export const LOAD_WORKDAYS = 'load_workdays';
export const SET_CABINET = 'set_cabinet';
export const SAVE_PROFILE = 'save_profile';
export const LOAD_TASKS = 'load_tasks';
export const LOAD_APPOINTMENTS = 'load_appointments';
export const LOAD_ORGANISATIONS = 'load_organisations';
export const PICK_TASK = 'pick_task';
export const UPDATE_APPOINTMENTS = 'update_appointments';
export const RELOAD_STATE = 'reload_state';
export const HAS_ERRORS = 'has_errors';
export const LOAD_STAFF = 'load_staff';
export const ERROR = 'error';
export const LOAD_SUGGESTIONS = 'load_suggestions';
export const LOAD_PATIENTS = 'load_patients';
export const LOAD_PRICE = 'load_price';
export const NEW_ROW = 'new_row';
export const TOGGLE_SETTINGS = 'toggle_settings';
export const CHANGE_STAFFER_DETAILS = 'change_staffer_details';
export const CHANGE_PRICE_DETAILS = 'change_price_details';
export const CHANGE_PATIENT_DETAILS = 'change_patient_details';
export const CHANGE_DOCUMENT_DETAILS = 'change_document_details';
export const LOAD_DOCUMENTS = 'load_documents';
export const SET_DOCUMENTS_OWNER = 'set_documents_owner';
export const CHANGE_DOCUMENT_STATUS = 'change_document_status';
export const UPDATE_PERMISSIONS = 'update_permissions';
export const RELOAD_LOGO = 'realod_logo';
export const FORGET_PATIENT = 'forget_patient';
export const SET_REDIRECTED = 'set_redirected';
export const SET_STAFFER_SEARCH_QUERY = 'set_staffer_search_query';
export const SET_PATIENT_SEARCH_QUERY = 'set_patient_search_query';
export const SET_STUDY_MODE = 'SET_STUDY_MODE';

export const SET_SESSIONS = 'SET_SESSIONS';

export function setRedirected(isRedirected) {
  return {
    type: SET_REDIRECTED,
    payload: isRedirected,
  };
}

export function loadProfile(data) {
  return {
    type: LOAD_PROFILE,
    payload: data,
  };
}

export function loadPatients(data) {
  return {
    type: LOAD_PATIENTS,
    payload: data.items,
  };
}

export function loadStaff(data) {
  return {
    type: LOAD_STAFF,
    payload: data.items,
  };
}
export function loadAppointments(data) {
  return {
    type: LOAD_APPOINTMENTS,
    payload: data,
  };
}

export function reloadState(clinic, user) {
  return {
    type: RELOAD_STATE,
    payload: { clinic, user },
  };
}

export function handleError(error) {
  return {
    type: ERROR,
    payload: error,
  };
}

export function loadTasks(data) {
  return {
    type: LOAD_TASKS,
    payload: data,
  };
}

export function loadCabinets(data) {
  return {
    type: CABINETS_GET,
    payload: data,
  };
}

export function closeNewRow() {
  return (dispatch) => {
    let data = false;
    dispatch({
      type: NEW_ROW,
      payload: data,
    });
  };
}

export function setStudyMode(data) {
  if (data.isStudyMode) {
    localStorage.setItem('is_study_mode', 'true');
  } else {
    localStorage.removeItem('is_study_mode');
  }
  return (dispatch) => {
    dispatch({
      type: SET_STUDY_MODE,
      payload: data.isStudyMode,
    });
  };
}
export function fetchTasks(date) {
  return async (dispatch, getState) => {
    let { common } = getState();
    try {
      let responce = await request.users.load_tasks(common.user, format(date, 'yyyy-MM-dd'));
      dispatch(loadTasks(responce.data));
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function fetchCabinets() {
  return async (dispatch, getState) => {
    let { common } = getState();
    try {
      let responce = await request.clinic.get_cabinets(common.user.clinic);
      dispatch(loadCabinets(responce.data));
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function updateTask(task, status, comments, date) {
  return async (dispatch) => {
    try {
      await request.users.update_task(task, {
        status: status,
        commentary: comments,
      });
      dispatch(fetchTasks(date));
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function fetchPatients() {
  return async (dispatch, getState) => {
    let { common } = getState();
    try {
      let responce = await request.clinic.load_patients(common.user.clinic);
      dispatch(loadPatients(responce.data));
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function fetchAppointments() {
  return async (dispatch, getState) => {
    let { common } = getState();
    try {
      let responce = await request.clinic.get_appointments(common.user.clinic);
      dispatch(loadAppointments(responce.data));
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function updateAppointments(state, index, id, key, status) {
  return async (dispatch) => {
    try {
      await request.clinic.update_appointment(id, { status });
      state[index][key] = status;
      dispatch({
        type: UPDATE_APPOINTMENTS,
        payload: state,
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function pickTask(index) {
  return {
    type: PICK_TASK,
    payload: { index },
  };
}

export function setDocumentsOwner(user) {
  return {
    type: SET_DOCUMENTS_OWNER,
    payload: user,
  };
}

export function setPatientSearchQuery(data) {
  return {
    type: SET_PATIENT_SEARCH_QUERY,
    payload: { ...data },
  };
}

export function searchPatient(data) {
  return async (dispatch, getState) => {
    let { common, clinic } = getState();
    try {
      dispatch(setPatientSearchQuery(data));

      let responce = await request.clinic.search_patient({
        ...{ ...clinic.patientQuery, [data.name]: data.value },
        clinic: common.user.clinic.id,
      });
      dispatch(loadPatients(responce.data));
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function fetchStaff() {
  return async (dispatch, getState) => {
    let { common } = getState();
    try {
      let responce = await request.clinic.get_staff(common.user.clinic);
      dispatch(loadStaff(responce.data));
    } catch (error) {
      dispatch(handleError(error));
    }
  };
}

export function updateUserInfo(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER, payload: { data } });
  };
}

export function loginSave(data, isAuth = true) {
  return (dispatch) => {
    dispatch(setDocumentsOwner(data.user));
    dispatch({ type: LOGIN_SAVE, payload: { data, isAuth } });
  };
}
