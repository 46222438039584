import React, { MutableRefObject, useEffect, useMemo } from 'react';
import ruLocale from 'date-fns/locale/ru';
import format from 'date-fns/format';
import { LinkButton } from '../../Button/LinkButton';
import { InvoiceComment, useGetInvoiceCommentsQuery, useGetInvoiceRowCommentsQuery } from '../../../services/invoice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { CommentsFormOptions } from '../types';
import styled from 'styled-components';
import { VISIBLE_HEIGHT } from '../constants';

type CommentsListProps = Omit<CommentsFormOptions, 'scrollTop' | 'formHeight'> & {
  expandedCommentId: number | null;
  setExpandedCommentId: (id: number | null) => void;
  setEditingComment: (id: InvoiceComment | null) => void;
  setValue: (value: string) => void;
  deleteFlag?: MutableRefObject<boolean>;
};

const CommentText = styled.div`
  line-height: 20px;
  padding-right: 26px;
  margin: 12px 0;
  font-size: 14px;
  word-wrap: break-word;
  height: 56px;
  overflow: hidden;
  font-weight: 400;
  letter-spacing: 0.25px;
`;

const DateBlock = styled.span`
  color: #a8aeb5;
  width: 100%;
  margin-left: 8px;
  font-size: 12px;
`;

const CommentAuthor = styled.div`
  color: #a8aeb5;
  font-size: 12px;
`;

const CommentHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const CommentsList = (props: CommentsListProps) => {
  const {
    invoiceId,
    subInvoiceId,
    positionId,
    expandedCommentId,
    deleteFlag,
    handleSetOpenForm,
    setExpandedCommentId,
    setEditingComment,
    setValue,
  } = props;

  const staff = useSelector((state: RootState) => state.staff.responce);

  const { data: invoiceComments = [], isLoading: invoiceLoading } = useGetInvoiceCommentsQuery(invoiceId, {
    skip: !invoiceId,
  });
  const { data: invoiceRowComments = [], isLoading: rowLoading } = useGetInvoiceRowCommentsQuery(
    {
      subInvoiceId,
      invoiceRowId: positionId,
    },
    { skip: !positionId },
  );

  const loading = rowLoading || invoiceLoading;

  // TODO пересмотреть получение массива комментариев без зависимости от айдишников
  const currentComments = useMemo(
    () => (invoiceId ? invoiceComments : invoiceRowComments),
    [invoiceId, invoiceComments, invoiceRowComments],
  );

  const handleSetTextareaValue = (editingComment: InvoiceComment) => {
    setEditingComment(editingComment);
    setValue(editingComment.text);
  };

  const handleExpandCommentText = (commentId: number) => {
    const comment = document.getElementById(`${commentId}`) as HTMLElement;
    if (expandedCommentId && expandedCommentId !== commentId) {
      const expandedComment = document.getElementById(`${expandedCommentId}`) as HTMLElement;
      expandedComment.style.height = '';
      setExpandedCommentId(commentId);
      comment.style.height = '100%';
    }
    if (!expandedCommentId) {
      setExpandedCommentId(commentId);
      comment.style.height = '100%';
    }
    if (expandedCommentId && expandedCommentId === commentId) {
      setExpandedCommentId(null);
      comment.style.height = '';
    }
  };

  useEffect(() => {
    if (deleteFlag.current) {
      // закрываем модалку при удалении последнего комментария
      if (currentComments.length === 1) {
        handleSetOpenForm();
      }
      deleteFlag.current = false;
    }
  }, [deleteFlag, currentComments.length, handleSetOpenForm]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
          background: 'white',
        }}
      >
        <div className="spinner" />
      </div>
    );
  }

  return (
    <>
      {currentComments &&
        currentComments.map((comment) => {
          const commentDate = new Date(comment.created_at);
          const staff_member = staff.find((member) => member.id === comment.user);

          return (
            <div key={comment.id}>
              <CommentHeader>
                <span style={{ fontWeight: 400, color: '#253446' }}>Комментарий</span>
                <DateBlock>
                  {format(commentDate, 'dd MMM yyyy', { locale: ruLocale })}
                  <span style={{ padding: '0 3px' }}>{format(commentDate, 'HH:mm')}</span>
                </DateBlock>
                <div style={{ marginLeft: 'auto' }} onClick={() => handleSetTextareaValue(comment)}>
                  <img src="./img/grey/pen.svg" alt="pen" />
                </div>
              </CommentHeader>
              <CommentAuthor>
                {staff_member.last_name} {staff_member.first_name}
                <span style={{ padding: '0 3px' }}>{staff_member.second_name},</span>
                {staff_member.job}
              </CommentAuthor>
              <CommentText id={`${comment.id}`}>{comment.text}</CommentText>
              {comment.text.length > VISIBLE_HEIGHT && (
                <LinkButton
                  onClick={() => handleExpandCommentText(comment.id)}
                  style={{ padding: '0', marginBottom: '10px' }}
                >
                  {expandedCommentId !== comment.id ? 'Показать все' : 'Скрыть'}
                </LinkButton>
              )}
            </div>
          );
        })}
    </>
  );
};
