import { createSlice, PayloadAction } from '@reduxjs/toolkit';

enum EFreezeStatus {
  PROFILE = 'PROFILE',
  USER_PROFILE = 'USER_PROFILE',
}

const INITIAL_STATE = {
  visible: false,
  status: null,
};

const FreezeStateSlice = createSlice({
  name: 'FreezeModalState',
  initialState: INITIAL_STATE,
  reducers: {
    show: (state) => ({
      ...state,
      visible: true,
    }),
    hide: (state) => ({
      ...state,
      visible: false,
      status: null,
    }),
    setStatus: (state, action: PayloadAction<EFreezeStatus>) => ({
      ...state,
      status: action.payload,
    }),
  },
});

export default FreezeStateSlice;
