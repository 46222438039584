import styled from 'styled-components';

export const Container = styled.div<{ height?: number; width: number }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: ${({ height }) => height || 32}px;
  width: ${({ width }) => width}px;
  align-items: center;
  justify-content: center;
`;

export default Container;
