import React, { useEffect, useMemo, useState } from 'react';
import { Column, Table } from '../../../../components/Table';
import styled from 'styled-components';
// import { LinkButton } from '../../../../uikit/Button/LinkButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import DialogActions from '@material-ui/core/DialogActions';
import OutlinedButton from '../../../../uikit/Button/OutlinedButton';
import BlueButton from '../../../../uikit/Button/BlueButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Backdrop, CircularProgress, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ClearIcon from '@material-ui/icons/Clear';
import request from '../../../../utils/request';
// eslint-disable-next-line  import/no-named-as-default
import toast from 'react-hot-toast';
import isEqual from 'lodash/isEqual';

const FILE_MAX_SIZE = 3 * 1024 * 1024;
const ALLOW_FILE_TYPE = /(xlsx|xlx|csv)$\b/i;

export const UPLOAD_FILE_ERROR = {
  ERROR_FILE_WRONG_EXTENSION: 'ERROR_FILE_WRONG_EXTENSION',
  ERROR_FILE_WRONG_SIZE: 'ERROR_FILE_WRONG_SIZE',
  ERROR_FILE_WRONG_FORMAT: 'ERROR_FILE_WRONG_FORMAT',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '8px 24px 24px 4px',
    },
    list: {
      marginLeft: 16,
    },
    itemIcon: {
      minWidth: 32,
    },
    itemText: {
      fontSize: 14,
      color: '#595959',
    },
    upload: {
      margin: 0,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

const TableContainer = styled.div`
  display: block;
  max-width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export type DictionaryMKBData = {
  id: number;
  code: string;
  text: string;
};

export function DictionaryMKB() {
  const [open, setOpen] = useState<boolean>(false);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [data, setData] = useState<DictionaryMKBData[]>([]);
  const [error, setError] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<DictionaryMKBData | null>(null);

  const classes = useStyles();

  const columns: Column<DictionaryMKBData>[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Код',
        accessor: 'code',
        width: 200,
      },
      {
        Header: 'Наименование',
        accessor: 'text',
        width: 900,
      },
    ],
    [],
  );

  const checkFile = (file: File) => {
    const err = [];
    if (!ALLOW_FILE_TYPE.test(file.name)) {
      err.push(UPLOAD_FILE_ERROR.ERROR_FILE_WRONG_EXTENSION);
    }
    if (file.size > FILE_MAX_SIZE) {
      err.push(UPLOAD_FILE_ERROR.ERROR_FILE_WRONG_SIZE);
    }
    return err;
  };

  useEffect(() => {
    const fetchData = async () => {
      // Для плавности анимации показывать лоадер через секунду
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (!(dictionary && dictionary.status === 200)) {
          setIsLoading(true);
        }
      }, 1000);
      const dictionary = await request.users.load_dictionary_mkb(null, searchQuery);
      setIsLoading(false);
      setData(dictionary.data);
    };
    fetchData();
  }, [searchQuery]);

  const handleFetchData = ({ filters }) => {
    if (fileUploaded) {
      return;
    }
    const newSearchQuery = filters.reduce((res, cur) => ({ ...res, [cur.id]: cur.value }), {});
    if (!isEqual(newSearchQuery, searchQuery)) {
      setSearchQuery(newSearchQuery);
    }
  };

  return (
    <div>
      {/*<LinkButton disableRipple onClick={() => setOpen(true)}>*/}
      {/*  Загрузить файл в формате Excel*/}
      {/*</LinkButton>*/}
      <TableContainer>
        <Table<DictionaryMKBData>
          rowBackground="#F9F9F9"
          manualFilters={!fileUploaded}
          columns={columns}
          data={data}
          hiddenColumns={['id']}
          isLoading={isLoading}
          onFetchData={handleFetchData}
          visibleRowCount={12}
        />
      </TableContainer>
      {uploadLoading ? (
        <Backdrop className={classes.backdrop} open={uploadLoading} onClick={() => setUploadLoading(false)}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Dialog
          classes={{ paper: classes.paper }}
          open={open}
          fullWidth
          maxWidth="sm"
          onClose={() => setOpen(false)}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogContent>
            <DialogContentText>
              <div style={{ display: 'flex', alignItems: 'center', color: '#262626', fontSize: 14 }}>
                {error.length ? (
                  <HighlightOffIcon style={{ color: '#C3000A', marginRight: 17.5 }} />
                ) : (
                  <ErrorOutlineOutlinedIcon style={{ color: '#FAAD14', marginRight: 17.5 }} />
                )}
                Убедитесь, что Ваш файл отвечает следующим требованиям:
              </div>
              <List className={classes.list}>
                <ListItem>
                  <ListItemIcon className={classes.itemIcon}>
                    {error.length && error.indexOf(UPLOAD_FILE_ERROR.ERROR_FILE_WRONG_EXTENSION) > -1 ? (
                      <ClearIcon style={{ color: '#C3000A' }} />
                    ) : (
                      <CheckIcon style={{ color: '#0D853D' }} />
                    )}
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.itemText }} primary="Допустимые форматы: xlsx, xls, cvs" />
                </ListItem>
                <ListItem>
                  <ListItemIcon className={classes.itemIcon}>
                    {error.length && error.indexOf(UPLOAD_FILE_ERROR.ERROR_FILE_WRONG_FORMAT) > -1 ? (
                      <ClearIcon style={{ color: '#C3000A' }} />
                    ) : (
                      <CheckIcon style={{ color: '#0D853D' }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.itemText }}
                    primary="Первый столбец в таблице имеет наименование «Код диагноза»,
а второй «Наименования диагноза»"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon className={classes.itemIcon}>
                    {error.length && error.indexOf(UPLOAD_FILE_ERROR.ERROR_FILE_WRONG_SIZE) > -1 ? (
                      <ClearIcon style={{ color: '#C3000A' }} />
                    ) : (
                      <CheckIcon style={{ color: '#0D853D' }} />
                    )}
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.itemText }} primary="Максимальный размер: 3 Мб" />
                </ListItem>
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <OutlinedButton
              width={100}
              style={{ marginRight: 24, marginTop: 0 }}
              noBorder
              onClick={() => {
                setOpen(false);
                setError([]);
              }}
            >
              Отменить
            </OutlinedButton>
            <input
              id="add-file"
              type="file"
              hidden
              onChange={async (e) => {
                const file = e.target.files[0];
                const err = checkFile(file);
                setError(err);
                setUploadLoading(!err);
                try {
                  setIsLoading(true);
                  const dictionary = await request.users.upload_dictionary_mkb(file);
                  await request.users.upload_dictionary_mkb_confirm();
                  setOpen(false);
                  setData(dictionary.data.records);
                  setFileUploaded(true);
                  setIsLoading(false);
                  toast.success('Справочник МКБ успешно загружен');
                } catch (error) {
                  const err = error.response.data.errors;
                  if (err.some((e) => Object.keys(UPLOAD_FILE_ERROR).indexOf(e.type) === -1)) {
                    toast.error('Ошибка загрузки справочника МКБ');
                    setOpen(false);
                  } else {
                    setError(err.map((e) => e.type));
                  }
                  await request.users.upload_dictionary_mkb_cancel();
                } finally {
                  setUploadLoading(false);
                }
              }}
            />
            <label className={classes.upload} htmlFor="add-file">
              <BlueButton style={{ marginTop: 0 }} width={127} type="file">
                Выбрать файл
              </BlueButton>
            </label>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
