import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextArea from '../../../../uikit/TextArea';
import { getMask } from '../../../../utils/masks';
import { LogoModal } from './LogoModal';
import { Title, InputLabel, UploadLabel, UploadTitle, LogoWrapper, Logo, AddNewLogo } from './styled-components';
import { TextInputEllipsis } from './TextInputEllipsis';
import { useGetClinicQuery, Clinic, useUpdateClinicMutation } from '../../../../services/clinics';
import { StudyComponent } from '../../../../components/StudyComponent';
// eslint-disable-next-line  import/no-named-as-default
import toast from 'react-hot-toast';
import readFile from '../../../../utils/readFile';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginLeft: '24px',
    },
    paper: {
      backgroundColor: '#f9f9f9',
    },
  }),
);

export function Requisites() {
  const classes = useStyles();
  const [requisites, setRequisites] = useState<Clinic | null>(null);
  const [logoModalOpen, setLogoModalOpen] = useState<boolean>(false);
  const [logoImage, setLogoImage] = useState(null);
  const [canAddNewLogo, setCanAddNewLogo] = useState<boolean>(false);

  const { data: clinicProfile, isSuccess, isLoading: isClinicProfileLoading } = useGetClinicQuery();
  const [updateClinic] = useUpdateClinicMutation();

  useEffect(() => {
    if (isSuccess) {
      setRequisites(clinicProfile?.[0]);
      setLogoImage(clinicProfile?.[0].logo);
    }
  }, [clinicProfile, isSuccess]);

  const handleSetLogo = (blobUrl) => {
    fetch(blobUrl)
      .then((response) => response.blob())
      .then(readFile)
      .then((base64) => {
        updateClinic({ id: requisites?.id, logo: base64.split(',')[1] })
          .unwrap()
          .then(() => {
            toast.success('Изменения успешно сохранены');
            setLogoImage(blobUrl);
          })
          .catch(() => toast.error('Произошла техническая ошибка, попробуйте позже'));
      });
  };

  const handleBlurTextField = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    switch (name) {
      case 'phone':
        value = getMask('rusPhone').resolve(e.target.value);
        break;
    }
    updateClinic({ id: requisites?.id, [name]: value })
      .unwrap()
      .then(() => toast.success('Изменения успешно сохранены'));
  };

  const handleChangeTextField = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    switch (name) {
      case 'phone':
        value = getMask('rusPhone').resolve(e.target.value);
        break;
    }
    setRequisites((prevRequisites) => ({ ...prevRequisites, [name]: value }));
  };

  return (
    <div className={classes.root}>
      <StudyComponent Component={() => <Title>Реквизиты</Title>} studyMnemo="REQIUSITES" />
      <div style={{ display: 'flex', marginBottom: 12 }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0}>
              <InputLabel>Название клиники</InputLabel>
              <TextInputEllipsis
                name="name"
                value={requisites?.name || ''}
                onBlur={handleBlurTextField}
                onChange={handleChangeTextField}
              />
              <InputLabel>Телефон</InputLabel>
              <TextInputEllipsis
                name="phone"
                value={requisites?.phone || ''}
                onBlur={handleBlurTextField}
                onChange={handleChangeTextField}
              />
              <InputLabel>Email</InputLabel>
              <TextInputEllipsis
                name="email"
                value={requisites?.email || ''}
                onBlur={handleBlurTextField}
                onChange={handleChangeTextField}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0}>
              <InputLabel>Номер лицензии</InputLabel>
              <TextInputEllipsis
                name="license"
                value={requisites?.license || ''}
                onBlur={handleBlurTextField}
                onChange={handleChangeTextField}
              />
              <InputLabel>Юридический адрес</InputLabel>
              <TextArea
                name="address"
                value={requisites?.address || ''}
                style={{ height: '96px' }}
                onBlur={handleBlurTextField}
                onChange={handleChangeTextField}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper} elevation={0}>
              <InputLabel>Реквизиты</InputLabel>
              <TextArea
                name="requisites"
                style={{ height: '160px' }}
                value={requisites?.requisites || ''}
                maxLength={2000}
                onBlur={handleBlurTextField}
                onChange={handleChangeTextField}
              />
            </Paper>
          </Grid>
        </Grid>
        <LogoWrapper className="logo">
          {isClinicProfileLoading && <div className="spinner" />}

          {logoImage && !isClinicProfileLoading && (
            <Logo onMouseOver={() => setCanAddNewLogo(true)} onMouseLeave={() => setCanAddNewLogo(false)}>
              <img src={logoImage} />
              {canAddNewLogo && <AddNewLogo onClick={() => setLogoModalOpen(true)}>Загрузить логотип</AddNewLogo>}
            </Logo>
          )}

          {!logoImage && !isClinicProfileLoading && (
            <UploadLabel onClick={() => setLogoModalOpen(true)}>
              <UploadTitle>Загрузить логотип</UploadTitle>
            </UploadLabel>
          )}
        </LogoWrapper>
      </div>
      {logoModalOpen && <LogoModal setOpen={setLogoModalOpen} setLogoImage={handleSetLogo} />}
    </div>
  );
}
