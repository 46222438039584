import React from 'react';
import { Dialog } from '@material-ui/core';
import OutlinedButton from '../../../uikit/Button/OutlinedButton';
import BlueButton from '../../../uikit/Button/BlueButton';
import styled from 'styled-components';

interface OverPayWarningProps {
  open: boolean;
  onMakeCashBack: () => void;
  onClose: (condition: boolean) => void;
  overPayAmount?: number;
  onSetIsBalanceChanging?: (condition: boolean) => void;
  onSetAddedAdvanceAmount: (value: number) => void;
  onSetPaidValue: (value: number) => void;
  onSetOverPayAmount: (value: number) => void;
  paidAmount: number;
  totalDifference: number;
}

const StyledDialog = styled(Dialog)`
  & .MuiBackdrop-root {
    backdrop-filter: blur(4px);
    background-color: rgba(81, 93, 107, 0.6);
  }

  & .MuiDialog-paper {
    width: 513px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    margin: 0;
    padding: 24px;
    position: relative;
  }
`;
export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #262626;
  line-height: 24px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #7c8590;
  line-height: 24px;
  margin: 20px 0;
`;
export const CloseIcon = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const OverPayWarning: React.FC<OverPayWarningProps> = ({
  onClose,
  open,
  onMakeCashBack,
  onSetIsBalanceChanging,
  onSetAddedAdvanceAmount,
  onSetPaidValue,
  paidAmount,
  onSetOverPayAmount,
  totalDifference,
}) => {
  const handleRefillBalance = () => {
    onSetIsBalanceChanging(true);
    onSetAddedAdvanceAmount(totalDifference);
    onSetPaidValue(paidAmount - totalDifference);
    onClose(false);
  };
  const handleClose = () => {
    onClose(false);
  };
  const makeCashBack = () => {
    onSetOverPayAmount(totalDifference);
    onMakeCashBack();
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <CloseIcon onClick={handleClose}>
        <img src="./img/grey/close-modal.svg" alt="close" />
      </CloseIcon>
      <div>
        <Title>Пополнить аванс?</Title>
        <Text>
          <span style={{ fontSize: '16px' }}>
            Внесенная сумма больше чем к оплате на:
            <span style={{ color: '#0D853D', margin: '0 5px' }}>{totalDifference} ₽</span>
            <div>Вы можете сохранить эту сумму как аванс или выдать сдачу.</div>
          </span>
        </Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <OutlinedButton width={123} height={32} style={{ marginTop: 0 }} onClick={makeCashBack}>
          Выдать сдачу
        </OutlinedButton>
        <BlueButton width={147} height={32} style={{ marginTop: 0, marginLeft: '16px' }} onClick={handleRefillBalance}>
          Пополнить аванс
        </BlueButton>
      </div>
    </StyledDialog>
  );
};
