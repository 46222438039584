import React from 'react';
import styled from 'styled-components';
import { Radio as MaterialRadio, RadioProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      cursor: 'pointer',
    },
    colorSecondary: {
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
  }),
);

const Icon = styled.span`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
`;

const CheckedIcon = styled(Icon)`
  border: 1px solid #577bf9;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #577bf9;
    border-radius: 50%;
  }
`;

export const Radio = (props: RadioProps) => {
  const classes = useStyles();
  return <MaterialRadio classes={classes} {...props} icon={<Icon />} checkedIcon={<CheckedIcon />} disableRipple />;
};
