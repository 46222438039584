import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { DiscountTypeValues, TreatmentPlanItem } from '../Template/TreatmentPlan/TreatmentPlanJournalField';
import styled from 'styled-components';
import OutlinedButton from '../../../uikit/Button/OutlinedButton';
import BlueButton from '../../../uikit/Button/BlueButton';
import { SearchHeader } from '../Template/SearchHeader';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getMask } from '../../../utils/masks';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Column from '../../PatientPage/components/Column';
import { TextWithPopover } from '../Template/TextWithPopover';
import { AccountStatus, EAccountStatus } from './AccountStatus';
import { useGetPermissionsQuery } from '../../../services/users';
import { useAppSelector } from '../../../store/hooks';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
import {
  InvoiceRowType,
  useGetInvoiceAllRowsQuery,
  useGetSubInvoicesQuery,
  useSendInvoiceMutation,
  useUpdateSubInvoiceMutation,
} from '../../../services/invoice';
import { groupBy } from 'lodash';
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast';
import { MedcardDate } from '../../../services/medcard';
import { LinkButton } from '../../../uikit/Button/LinkButton';
import { EAccountView } from '../Jornal/JornalAccount';

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #253446;
  margin-right: 16px;
`;

const SubTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #7c8590;
  margin-right: 16px;
`;

const TextTitle = styled.div<{ isDark?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-right: 16px;
  color: #253446;
`;

const TextSubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-right: 16px;
  color: #7c8590;
`;

const Row = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${({ selected = false }) => (selected ? '#F6F6FA' : 'white')};
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
`;

const RowTitle = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #515d6b;
  padding: 2px 16px;
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: 16,
      boxSizing: 'border-box',
      boxShadow: 'none',
      borderRadius: 4,
      display: 'flex',
      justifyItems: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    toothHeader: {
      background: '#F9F9F9',
      padding: '12px 16px',
      maxWidth: 1390,
      width: '100%',
      borderBottom: '1px solid #dadada',
    },
    header: {
      background: '#F9F9F9',
      padding: 0,
      maxWidth: 1390,
      width: '100%',
    },
    headerContent: {
      display: 'flex',
      padding: 0,
    },
    content: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    footerContent: {
      display: 'flex',
      alignItems: 'center',
      background: '#F9F9F9',
      fontWeight: 500,
      fontSize: 14,
      color: '#253446',
    },
    discountSum: {
      borderBottomRightRadius: '0 !important',
      borderTopRightRadius: '0 !important',
    },
    discountType: {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderLeft: 0,
    },
  }),
);

type Props = {
  totalView: boolean;
  setExpanded: ActionCreatorWithNonInferrablePayload;
  entries: TreatmentPlanItem[];
  invoiceId: number;
  currentDate: MedcardDate;
  setAccountView: React.Dispatch<React.SetStateAction<EAccountView>>;
  setAccountVisible: (visible: boolean) => void;
  setCompletedForPayment: (completed: boolean) => void;
  onStopEdit: VoidFunction;
};

type TotalValueType = {
  count: number;
  price: number;
  discount: number;
  payment: number;
};

export function TotalAccount({
  invoiceId,
  entries,
  currentDate,
  setAccountView,
  setAccountVisible,
  setCompletedForPayment,
  onStopEdit,
}: Props) {
  const classes = useStyles();
  const [total, setTotal] = useState<Map<number, TotalValueType>>(new Map());
  const { id: userId } = useAppSelector((state) => state.common.user);
  const { data: permissions } = useGetPermissionsQuery(userId);

  const { data: invoiceRows = [] } = useGetInvoiceAllRowsQuery(invoiceId);
  const { data: subInvoices = [] } = useGetSubInvoicesQuery(invoiceId);

  const [updateSubInvoice] = useUpdateSubInvoiceMutation();
  const [sendInvoice] = useSendInvoiceMutation();

  const getTotalCount = (data) => {
    return data.reduce((prev, current) => prev + current.count, 0);
  };

  const getTotalPrice = (data) => {
    return data.reduce((prev, current) => prev + current.count * current.price, 0);
  };

  const getTotalDiscount = (data) => {
    return data.reduce(
      (prev, current) =>
        prev +
        (current.discountType === DiscountTypeValues.RUB
          ? current.discount
          : current.count * current.price * current.discount * 0.01),
      0,
    );
  };

  const getTotalPayment = (data) => {
    const totalForPay = data.reduce((prev, current) => prev + current.payment, 0);
    const totalPaid = data.reduce((prev, current) => prev + current.paid, 0);
    return totalForPay - totalPaid;
  };

  const getAllTotalPrice = (data) => {
    return data.reduce((prev, current) => prev + current.price, 0);
  };

  const getAllTotalDiscount = (data) => {
    return data.reduce((prev, current) => prev + current.discount, 0);
  };

  useEffect(() => {
    const newTotal = new Map<number, TotalValueType>();
    Object.entries(groupBy(invoiceRows, 'subInvoiceId')).forEach(([subInvoiceId, rows]) => {
      newTotal.set(subInvoiceId, {
        count: getTotalCount(rows),
        price: getTotalPrice(rows),
        discount: getTotalDiscount(rows),
        payment: getTotalPayment(rows),
      });
    });
    setTotal(newTotal);
  }, [invoiceRows]);

  return (
    <>
      <div style={{ display: 'flex', padding: 32, alignItems: 'center', borderBottom: '1px solid #D9D9D9' }}>
        <Title>Общий счет на оплату</Title>
        <SubTitle>
          Визит: {currentDate.date && format(new Date(currentDate.date), 'dd.MM.yyyy') + ' '}
          {currentDate.timeStart && format(new Date(currentDate.timeStart), 'HH:mm')}
          {currentDate.timeEnd && '-' + format(new Date(currentDate.timeEnd), 'HH:mm')}
        </SubTitle>
      </div>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ height: '100%', width: '100%' }}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '16px 0',
                maxWidth: 1390,
                width: '100%',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextTitle>Счет</TextTitle>
                <TextSubTitle>
                  создан {format(new Date(subInvoices[0].created_at), 'dd LLL yyyy', { locale: ruLocale })} Врач:{' '}
                  {entries[0]?.doctor.secondName} {entries[0]?.doctor.firstName[0]}. {entries[0]?.doctor.lastName[0]}
                </TextSubTitle>
                <AccountStatus
                  status={
                    subInvoices.find(
                      (inv) => inv.status !== EAccountStatus.NOT_FILLED && inv.status !== EAccountStatus.FILLED,
                    )?.status
                  }
                />
              </div>
              {entries[0]?.doctor.id === userId &&
                subInvoices.find(
                  (inv) =>
                    inv.status === EAccountStatus.PENDING_SEND ||
                    ((inv.status === EAccountStatus.PENDING_PAYMENT || inv.status === EAccountStatus.DEBT) &&
                      permissions['create_account']),
                ) && (
                  <OutlinedButton
                    width={168}
                    height={32}
                    style={{ marginLeft: 16, marginTop: 0 }}
                    onClick={async () => {
                      if (subInvoices.find((inv) => inv.status === EAccountStatus.PENDING_SEND)) {
                        await Promise.all(
                          subInvoices
                            .filter((inv) => inv.status === EAccountStatus.PENDING_SEND)
                            .map(
                              async (subInvoice) =>
                                await updateSubInvoice({
                                  id: subInvoice.id,
                                  status: EAccountStatus.FILLED,
                                }).unwrap(),
                            ),
                        );
                      } else {
                        await Promise.all(
                          subInvoices
                            .filter(
                              (inv) =>
                                inv.status === EAccountStatus.PENDING_PAYMENT || inv.status === EAccountStatus.DEBT,
                            )
                            .map(
                              async (subInvoice) =>
                                await updateSubInvoice({
                                  id: subInvoice.id,
                                  status: EAccountStatus.EDIT,
                                }).unwrap(),
                            ),
                        );
                      }
                      setAccountView(EAccountView.PLAIN);
                    }}
                  >
                    Редактировать счет
                  </OutlinedButton>
                )}
              {!permissions['create_account'] &&
                subInvoices.find((inv) => inv.status === EAccountStatus.PENDING_PAYMENT) && (
                  <LinkButton style={{ padding: 0, margin: 0 }} disableRipple>
                    Открыть чат
                  </LinkButton>
                )}
            </div>
          </div>
          <Scrollbars>
            {Object.entries(groupBy(invoiceRows, 'subInvoiceId')).map(([subInvoiceId, rows], index) => {
              const subInvoice = subInvoices.find(
                (inv) =>
                  (inv.id === +subInvoiceId && inv.status === EAccountStatus.PENDING_SEND) ||
                  inv.status === EAccountStatus.PENDING_PAYMENT ||
                  inv.status === EAccountStatus.DEBT ||
                  inv.status === EAccountStatus.PAID ||
                  inv.status === EAccountStatus.EDIT,
              );
              const entry = entries.find((e) => e.id === subInvoice?.formula_id);

              const groupedData = groupBy(rows, 'type');
              groupedData[InvoiceRowType.TREATMENT_PLAN] = groupBy(
                groupedData[InvoiceRowType.TREATMENT_PLAN],
                'externalEntry.id',
              );

              return (
                subInvoice && (
                  <Card key={subInvoiceId} className={classes.root}>
                    <CardHeader
                      className={classes.toothHeader}
                      title={
                        <TextTitle>
                          {(entry.toothStateLabel && `${entry.toothIndex + ' ' || ''}${entry.toothStateLabel}`) ||
                            (entry.toothState ? (
                              <>
                                {`${entry.toothIndex + ' ' || ''}`}
                                <span>{entry.toothState.title}</span>
                              </>
                            ) : (
                              `${entry.toothIndex + ' ' || ''}Состояние не указано`
                            ))}
                        </TextTitle>
                      }
                    />
                    <CardHeader
                      className={classes.header}
                      title={
                        <>
                          <div className={classes.headerContent}>
                            <SearchHeader
                              type="text"
                              placeholder="Код"
                              name="code"
                              width={194}
                              fontSize={14}
                              text="Код"
                              noBorder
                              searchable={false}
                            />
                            <SearchHeader
                              type="text"
                              placeholder="Наименование"
                              fontSize={14}
                              name="text"
                              width={800}
                              text="Наименование"
                              noBorder
                              searchable={false}
                            />
                            <SearchHeader
                              type="text"
                              placeholder="Кол-во"
                              fontSize={14}
                              name="count"
                              width={94}
                              text="Кол-во"
                              noBorder
                              searchable={false}
                            />
                            <SearchHeader
                              type="text"
                              placeholder="Цена"
                              fontSize={14}
                              name="price"
                              width={96}
                              text="Цена"
                              noBorder
                              searchable={false}
                            />
                            <SearchHeader
                              type="text"
                              placeholder="Скидка"
                              fontSize={14}
                              name="discount"
                              width={96}
                              text="Скидка"
                              noBorder
                              searchable={false}
                            />
                            <SearchHeader
                              type="text"
                              placeholder="К оплате"
                              fontSize={14}
                              name="payment"
                              width={112}
                              text="К оплате"
                              noBorder
                              searchable={false}
                            />
                          </div>
                        </>
                      }
                    />
                    <CardContent className={classes.content}>
                      {Object.entries(groupedData[InvoiceRowType.TREATMENT_PLAN])?.map(([, items]) => (
                        <>
                          <RowTitle>
                            {`План лечения: ${items[0].externalEntry.doctor.last_name} ${
                              items[0].externalEntry.doctor.first_name[0]
                            }. ${items[0].externalEntry.doctor.second_name[0]}.
                ${
                  items[0].externalEntry.tooth_index
                    ? `${items[0].externalEntry.tooth_index} ${items[0].externalEntry.tooth_state.title_russian}`
                    : items[0].externalEntry.tooth_state_label
                }`}
                          </RowTitle>
                          {items?.map((item) => (
                            <Row key={item.id}>
                              <Column name="code" defaultValue={item.code} width={194} isEditing={false} hasAction>
                                {item.code}
                              </Column>
                              <Column name="text" defaultValue={item.text} width={800} isEditing={false} hasAction>
                                <TextWithPopover text={item.text} width={386} height={70} />
                              </Column>
                              <Column name="text" defaultValue={item.count} width={94} isEditing={false} hasAction>
                                {item.count}
                              </Column>
                              <Column name="text" defaultValue={item.price} width={96} isEditing={false} hasAction>
                                {getMask('number_format').resolve(`${item.price}`)}
                              </Column>
                              <Column name="text" defaultValue={item.discount} width={96} isEditing={false} hasAction>
                                {`${getMask('number_format').resolve(`${item.discount}`)}${
                                  item.discountType === DiscountTypeValues.PERCENT ? '%' : ''
                                }`}
                              </Column>
                              <Column name="text" defaultValue={item.payment} width={112} isEditing={false} hasAction>
                                {getMask('number_format').resolve(`${item.payment - item.paid}`)}
                              </Column>
                            </Row>
                          ))}
                        </>
                      ))}
                      {groupedData[InvoiceRowType.PRICE]?.length && (
                        <>
                          <RowTitle>Прейскурант</RowTitle>
                          {groupedData[InvoiceRowType.PRICE].map((item) => (
                            <Row key={item.id}>
                              <Column name="code" defaultValue={item.code} width={194} isEditing={false} hasAction>
                                {item.code}
                              </Column>
                              <Column name="text" defaultValue={item.text} width={800} isEditing={false} hasAction>
                                <TextWithPopover text={item.text} width={386} height={70} />
                              </Column>
                              <Column name="text" defaultValue={item.count} width={94} isEditing={false} hasAction>
                                {item.count}
                              </Column>
                              <Column name="text" defaultValue={item.price} width={96} isEditing={false} hasAction>
                                {getMask('number_format').resolve(`${item.price}`)}
                              </Column>
                              <Column name="text" defaultValue={item.discount} width={96} isEditing={false} hasAction>
                                {`${getMask('number_format').resolve(`${item.discount}`)}${
                                  item.discountType === DiscountTypeValues.PERCENT ? '%' : ''
                                }`}
                              </Column>
                              <Column name="text" defaultValue={item.payment} width={112} isEditing={false} hasAction>
                                {getMask('number_format').resolve(`${item.payment - item.paid}`)}
                              </Column>
                            </Row>
                          ))}
                        </>
                      )}
                      <div className={classes.footerContent}>
                        <Column
                          name="text"
                          defaultValue=""
                          width={194 + 800}
                          isEditing={false}
                          hasAction
                          fontWeightBold
                        >
                          Стоимость:
                        </Column>
                        <Column
                          name="count"
                          defaultValue=""
                          width={94}
                          paddingLeft={16}
                          isEditing={false}
                          hasAction
                          fontWeightBold
                        >
                          {!!total.size && getMask('number_format').resolve(`${total.get(subInvoiceId).count}`)}
                        </Column>
                        <Column name="price" defaultValue="" width={96} isEditing={false} hasAction fontWeightBold>
                          {!!total.size && getMask('number_format').resolve(`${total.get(subInvoiceId).price}`)}
                        </Column>
                        <Column name="discount" defaultValue="" width={96} isEditing={false} hasAction fontWeightBold>
                          {!!total.size && getMask('number_format').resolve(`${total.get(subInvoiceId).discount}`)}
                        </Column>
                        <Column name="payment" defaultValue="" width={72} isEditing={false} hasAction fontWeightBold>
                          {!!total.size && getMask('number_format').resolve(`${total.get(subInvoiceId).payment}`)}
                        </Column>
                      </div>
                    </CardContent>
                    {index + 1 > rows.length && (
                      <div className={classes.headerContent} style={{ marginTop: 24 }}>
                        <SearchHeader
                          type="text"
                          placeholder="Код"
                          name="code"
                          width={194 + 800}
                          fontSize={18}
                          text="Итого к оплате:"
                          noBorder
                          searchable={false}
                        />
                        <SearchHeader
                          type="text"
                          placeholder="Кол-во"
                          fontSize={18}
                          name="count"
                          width={94}
                          text={
                            !!total.size &&
                            getMask('number_format').resolve(`${getTotalCount(Array.from(total.values()))}`)
                          }
                          noBorder
                          searchable={false}
                        />
                        <SearchHeader
                          type="text"
                          placeholder="Цена"
                          fontSize={18}
                          name="price"
                          width={96}
                          text={
                            !!total.size &&
                            getMask('number_format').resolve(`${getAllTotalPrice(Array.from(total.values()))}`)
                          }
                          noBorder
                          searchable={false}
                        />
                        <SearchHeader
                          type="text"
                          placeholder="Скидка"
                          fontSize={18}
                          name="discount"
                          width={96}
                          text={
                            !!total.size &&
                            getMask('number_format').resolve(`${getAllTotalDiscount(Array.from(total.values()))}`)
                          }
                          noBorder
                          searchable={false}
                        />
                        <SearchHeader
                          type="text"
                          placeholder="К оплате"
                          fontSize={18}
                          name="payment"
                          width={112}
                          text={
                            !!total.size &&
                            getMask('number_format').resolve(`${getTotalPayment(Array.from(total.values()))}`)
                          }
                          noBorder
                          searchable={false}
                          color="#E54770"
                        />
                      </div>
                    )}
                  </Card>
                )
              );
            })}
          </Scrollbars>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            padding: '8px 0 32px 0',
            borderTop: '1px solid #D9D9D9',
            flex: 1,
            zIndex: 1,
            background: 'white',
          }}
        >
          {subInvoices.find(
            (inv) => inv.status === EAccountStatus.PENDING_SEND || inv.status === EAccountStatus.EDIT,
          ) && (
            <BlueButton
              width={205}
              height={40}
              style={{ marginRight: 16 }}
              onClick={() => {
                if (subInvoices.find((inv) => inv.status === EAccountStatus.PENDING_SEND)) {
                  setCompletedForPayment(true);
                  sendInvoice(invoiceId);
                  if (permissions['edit_payment']) {
                    setAccountView(EAccountView.PAYMENT);
                  } else {
                    toast.success('Счет был отправлен на оплату');
                    setAccountVisible(false);
                    onStopEdit?.();
                  }
                } else {
                  sendInvoice(invoiceId);
                  if (permissions['edit_payment']) {
                    setAccountView(EAccountView.PAYMENT);
                  } else {
                    toast.success('Редактирование счета завершено');
                    setAccountVisible(false);
                    onStopEdit?.();
                  }
                }
              }}
            >
              Отправить на оплату
            </BlueButton>
          )}
          {subInvoices.find(
            (inv) => inv.status === EAccountStatus.PENDING_PAYMENT || inv.status === EAccountStatus.DEBT,
          ) &&
            permissions['edit_payment'] && (
              <BlueButton
                width={205}
                height={40}
                style={{ marginRight: 16 }}
                onClick={() => setAccountView(EAccountView.PAYMENT)}
              >
                Перейти в оплату
              </BlueButton>
            )}
        </div>
      </div>
    </>
  );
}
