import request from '../../utils/request';
import { curry } from 'ramda';
import { format } from 'date-fns';

export const patchPatientData = curry((patientId, patchData) => {
  request.clinic.patch_patient(patientId, {
    ...patchData,
    birth_date: patchData.birth_date && format(new Date(patchData.birth_date), 'yyyy-MM-dd'),
    passport_date: patchData.passport_date && format(new Date(patchData.passport_date), 'yyyy-MM-dd'),
  });
});
