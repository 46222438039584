import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { baseURL, HttpMethod } from '../utils/request';

type TDebtReportFilterResources = ['debts'] | ['advances'] | ['debts', 'advances'];
type TFilesFormatsForExport = 'pdf' | 'word' | 'excel';

export type TDebtReportFilterResourcesArray = TDebtReportFilterResources[] & { __isUnique: true };

export interface IRevenueReportFilter {
  start_date: string;
  end_date: string;
  patient_id: number[];
  price_codes: string[];
  price_titles: string[];
  payment_types: number[];
  export?: TFilesFormatsForExport;
}

export interface IDebtReportFilter {
  start_date: string;
  end_date: string;
  patient_id: number[];
  resources: TDebtReportFilterResourcesArray;
  export?: TFilesFormatsForExport;
}

export interface IRevenueReportResponce {
  count: number;
  discount: number;
  discount_type: string;
  doctor: string;
  favor_code: string;
  favor_title: string;
  operation_date: string;
  payer: string;
  payment: number;
  payment_type: string;
  price: number;
  total: number;
}

export interface IDebtReportResponce {
  patient: string;
  advance: number;
  debt: number;
}

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['Reports'],
  endpoints: (builder) => ({
    getRevenueReport: builder.mutation<IRevenueReportResponce, IRevenueReportFilter>({
      query: (data) => {
        return {
          url: '/reports/total_revenue',
          method: HttpMethod.POST,
          body: data,
        };
      },
    }),
    getDebtReport: builder.mutation<IDebtReportResponce, IDebtReportFilter>({
      query: (data) => {
        return {
          url: '/reports/debts_and_advances',
          method: HttpMethod.POST,
          body: data,
        };
      },
    }),
  }),
});

export const { useGetRevenueReportMutation, useGetDebtReportMutation } = reportsApi;
