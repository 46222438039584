import React, { useState } from 'react';
import styled from 'styled-components';

const HoverToolTip = styled.div<{ isSelected: boolean }>`
  position: absolute;
  top: -20px;
  left: -16px;
  height: 32px;
  z-index: 100;
  overflow: hidden;
  background-color: inherit;
  padding-top: 11px;
  padding-left: 16px;
  padding-bottom: 16px;

  &:hover {
    width: 160px;
    overflow: visible;
    height: auto;
    box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.16), 0px 0px 2px 0px rgba(40, 41, 61, 0.04);
    background-color: ${({ isSelected }) => (isSelected ? '#EFF2FF' : 'inherit')};
  }
`;

export default function WithHoverTip({
  children,
  isColored,
  isOverflown,
  defaultValue,
  isSelected = false,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  const [isMouseOver, setMouseOver] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
    >
      {isOverflown ? (
        <div
          className="hover-tip"
          style={{
            position: 'relative',
            zIndex: isMouseOver ? 10 : 2,
            backgroundColor: isColored ? '#F9F9F9' : 'white',
          }}
        >
          <HoverToolTip isSelected={isSelected}>
            {!isMouseOver ? `${defaultValue.substring(0, 15)}...` : children}
          </HoverToolTip>
        </div>
      ) : (
        children
      )}
    </div>
  );
}
