import {
  NEW_ROW,
  TOGGLE_SETTINGS,
  CHANGE_STAFFER_DETAILS,
  CHANGE_PRICE_DETAILS,
  CHANGE_DOCUMENT_DETAILS,
  SET_DOCUMENTS_OWNER,
  CHANGE_PATIENT_DETAILS,
  FORGET_PATIENT,
} from '../legacyState';
import { FLUSH_STATE } from '../flushState/types';
const INITIAL_STATE = {
  newRow: false,
  isSettingsOpened: false,
  staffer: {
    first_name: '',
    second_name: '',
    last_name: '',
    email: '',
    job: '',
    phone: '',
    username: '',
    password: '',
    clinic: '',
    role: '',
  },
  price: {
    code: '',
    title: '',
    value: '',
    clinic: '',
    errors: {
      code: false,
      title: false,
      value: false,
    },
  },
  patient: {
    first_name: '',
    second_name: '',
    last_name: '',
    email: '',
    phone: '',
    id: '',
    paier: '',
    stream: '',
    birth_date: '',
    balance: 0,
  },
  isPatientFieldsFilled: false,
  isPatientAllFieldsFilled: true,
  documents: {
    owner: '',
  },
  document: {
    title: '',
    created_at: '',
    starts_at: '',
    expires_at: '',
    reminder: '',
    user: '',
  },
};
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case NEW_ROW:
      return {
        ...state,
        ...{
          newRow: action.payload,
          price: {
            code: '',
            title: '',
            value: '',
            clinic: '',
            errors: {
              code: false,
              title: false,
              value: false,
            },
          },
          staffer: {
            first_name: '',
            second_name: '',
            last_name: '',
            email: '',
            job: '',
            phone: '',
            username: '',
            password: '',
            clinic: '',
            role: '',
          },
        },
      };

    case TOGGLE_SETTINGS:
      return {
        ...state,
        ...{ isSettingsOpened: action.payload },
      };
    case CHANGE_STAFFER_DETAILS:
      return {
        ...state,
        ...{ staffer: action.payload },
      };
    case CHANGE_PRICE_DETAILS:
      return {
        ...state,
        ...{ price: action.payload },
      };
    case SET_DOCUMENTS_OWNER:
      return {
        ...state,
        ...{ documents: { owner: action.payload } },
      };
    case CHANGE_DOCUMENT_DETAILS:
      return {
        ...state,
        ...{ document: action.payload },
      };
    case CHANGE_PATIENT_DETAILS:
      return {
        ...state,
        ...{
          patient: action.payload.patient,
          isPatientFieldsFilled: action.payload.isFilled,
          isPatientAllFieldsFilled: action.payload.isAllFilled,
        },
      };
    case FLUSH_STATE:
      return INITIAL_STATE;
    case FORGET_PATIENT:
      //
      return {
        ...state,
        ...{
          patient: {
            first_name: '',
            second_name: '',
            last_name: '',
            email: '',
            phone: '',
            id: '',
            paier: '',
            stream: '',
            birth_date: '',
            balance: 0,
          },
        },
      };
    default:
      return state;
  }
}
