import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Patient from './Patient';
import MedCardPageSlice from '../../MedcardPage/state';
import { routerConfig } from '../../../navigation/routerConfig';
import EventsWebSocket from '../../../websocket';
import { wsUrl } from '../../../utils/request';
import { startEditPatientAction, stopEditPatientAction } from '../../../websocket/actionCreators';
import { RootState } from '../../../store';
import { initPatientPage } from '../operations';

const EmptyMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #a8aeb5;
  padding: 16px;
`;

function Body({ patientsPage, setPatientDetails, initPatientPage }) {
  const navigate = useNavigate();
  const socket = useRef();
  const [sessions, setSessions] = useState([]);

  const handleWsMessage = (msg) => {
    if (msg.data) {
      setSessions(msg.data);
    }
  };

  const handleStartEdit = (patientId) => {
    socket.current?.send(startEditPatientAction(patientId));
  };

  const handleStopEdit = (patientId) => {
    socket.current?.send(stopEditPatientAction(patientId));
  };

  useEffect(() => {
    socket.current = new EventsWebSocket(wsUrl.patients, handleWsMessage);
    return () => socket.current.disconnect();
  }, []);

  useEffect(() => {
    initPatientPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions]);

  return (
    <>
      {patientsPage.patients.allIds.length > 0 ? (
        patientsPage.patients.allIds.map((patientId: number, index: number) => (
          <Patient
            key={patientId}
            handleClick={() => {
              setPatientDetails(patientsPage.patients.byId[patientId]);
              navigate(routerConfig.MEDCARD.path);
            }}
            id={patientId}
            index={index}
            isColored={index % 2 != 0}
            editor={sessions.find((s) => s.target_id === patientId)?.session_user_name ?? null}
            onStartEdit={handleStartEdit}
            onStopEdit={handleStopEdit}
          />
        ))
      ) : patientsPage.patients.allIds.length === 0 && patientsPage.isSearching && !patientsPage.isCreating ? (
        <EmptyMessage>Совпадения не найдены. Нажмите “Добавить пациента +” чтобы внести в базу</EmptyMessage>
      ) : patientsPage.isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div className="spinner" />
        </div>
      ) : (
        <EmptyMessage>Сейчас пациентов нет. Нажмите “Добавить пациента +” чтобы внести в базу</EmptyMessage>
      )}
    </>
  );
}
export default connect((state: RootState) => ({ patientsPage: state.patientsPage }), {
  initPatientPage,
  setPatientDetails: MedCardPageSlice.actions.setPatientDetails,
})(Body);
