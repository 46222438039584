/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from '@reduxjs/toolkit';
import { setFilter } from '../../../features/schedule/actions';
import { IValuesState, SelectedValues, SetSelectedValues } from './types';

export const onCancelClick = (
  dispatch: Dispatch<any>,
  name: string,
  setSelectedValues: SetSelectedValues,
  filters: object,
) => {
  setSelectedValues([]);
  dispatch(
    setFilter({
      ...filters,
      [name]: [],
    }),
  );
};

export const onOptionClick = (
  value: IValuesState,
  selectedValues: SelectedValues,
  setSelectedValues: SetSelectedValues,
  selectedOptions: number[],
) => {
  if (!selectedOptions.includes(value.id)) {
    setSelectedValues([...selectedValues, value]);
  } else {
    const filtered = selectedValues.filter((existing) => existing.id !== value.id);
    setSelectedValues(filtered);
  }
};
