import React, { useMemo } from 'react';
import { Backdrop, Drawer } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { MedcardDate, MedcardEntry } from '../../../services/medcard';
import { ActionCreatorWithNonInferrablePayload } from '@reduxjs/toolkit';
import { JornalAccountContent } from './JornalAccountContent';
import { useDispatch } from 'react-redux';
import { medcardJournalApi as api } from '../../../services/medcardJournal';
import medCardPageSlice from '../../MedcardPage/state';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      height: 'calc(100vh - 56px)',
      overflow: 'hidden',
      borderRadius: '8px 8px 0 0',
    },
  }),
);

type Props = {
  expandedId?: number;
  setExpanded?: ActionCreatorWithNonInferrablePayload;
  accountVisible: boolean;
  setAccountVisible: (visible: boolean) => void;
  entries?: MedcardEntry[];
  currentDate: MedcardDate;
  isPayment?: boolean;
  onStopEdit: VoidFunction;
};

export enum EAccountView {
  PLAIN,
  TOTAL,
  PAYMENT,
}

export function JornalAccount({
  expandedId,
  setExpanded,
  accountVisible,
  setAccountVisible,
  entries,
  currentDate,
  isPayment = false,
  onStopEdit,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { setPaymentVisible } = medCardPageSlice.actions;

  const handleClose = () => {
    setAccountVisible(false);
    onStopEdit?.();
    dispatch(api.util.invalidateTags(['MedcardPlanJournalField']));
    dispatch(setPaymentVisible(false));
  };

  const BackDrop = useMemo(
    // eslint-disable-next-line react/display-name
    () => () =>
      (
        <Backdrop open={accountVisible}>
          <div style={{ color: 'white', position: 'absolute', top: 20, right: 10 }}>
            <CloseIcon onClick={handleClose} />
          </div>
        </Backdrop>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountVisible],
  );

  return (
    <Drawer
      classes={{ paper: classes.paper }}
      variant="temporary"
      anchor="bottom"
      open={accountVisible}
      ModalProps={{
        disableBackdropClick: true,
        BackdropComponent: BackDrop,
      }}
    >
      <JornalAccountContent
        isPayment={isPayment}
        accountVisible={accountVisible}
        expandedId={expandedId}
        entries={entries}
        currentDate={currentDate}
        setExpanded={setExpanded}
        setAccountVisible={setAccountVisible}
        onStopEdit={onStopEdit}
      />
    </Drawer>
  );
}
