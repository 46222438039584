import styled from 'styled-components';

export const ResetFiltersButton = styled.button<{ noSchedule: boolean }>`
  height: 32px;
  margin-right: 10px;
  ${(props) => (props.noSchedule ? 'margin-left: auto;' : '')};
  padding: 0px 16px;
  background: #ffffff;
  border: 1px solid #577bf9;
  border-radius: 4px;
  color: #577bf9;
  outline: none !important;
`;

export const RedactWrapper = styled.button`
  height: 32px;
  width: 32px;
  border: 1px solid #d3d6da;
  border-radius: 4px;
  background: transparent;
  outline: none !important;
`;

export const SelectBlocker = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 125px;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100vh - 125px);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 6;
  background-color: #f6f6fa;
`;
