const task = {
  id: 0,
  title: '0',
  description: '',
  created_at: '2019-03-28T10:16:46.781821Z',
  starts_at: '2019-03-29T09:00:00Z',
  ends_at: '2019-03-29T12:00:00Z',
  status: 'WOR',
  addressee: {
    id: 0,
    first_name: '',
    second_name: '',
    last_name: '',
  },
  addresser: {
    id: 0,
    first_name: '',
    second_name: '',
    last_name: '',
  },
  kontragent: {
    user: {
      id: 0,
      first_name: '',
      second_name: '',
      last_name: '',
    },
  },
};

export default {
  task,
};
