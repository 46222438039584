import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import DialogsList from './DialogsList';
import MessagesList from './MessagesList';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { closeDialog, toggleChat } from '../../features/messages/actions';

const useStyles = makeStyles({
  root: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24)',
    borderRadius: '4px',
    padding: 0,
    '&::after': {
      content: 'none',
    },
  },
  paper: {
    padding: 0,
    '&::after': {
      content: 'none',
    },
  },
});

function ChatWindow({ toggleChat, closeDialog, messages }) {
  const [isSearching, setSearch] = useState(false);
  function startSearch() {
    setSearch(true);
  }
  function endSearch() {
    setSearch(false);
  }
  const classes = useStyles();

  const open = Boolean(messages.open);
  const id = open ? 'chat-popover' : undefined;

  return (
    <Popover
      container={document.getElementById('admin-home-page')}
      id={id}
      open={open}
      anchorEl={document.getElementById('chat-icon')}
      onClose={toggleChat}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: classes.root,
      }}
      PaperProps={{
        style: { overflowY: 'hidden', overflowX: 'hidden', content: 'none' },
      }}
      style={{ zIndex: 99990000 }}
    >
      {messages.dialog != null ? (
        <MessagesList
          closeDialog={closeDialog}
          name={messages.dialog.name && messages.dialog.name}
          reciever={messages.dialog.reciever && messages.dialog.reciever}
        />
      ) : (
        <DialogsList isSearching={isSearching} startSearch={startSearch} endSearch={endSearch} />
      )}
    </Popover>
  );
}

const mapStateToProps = (state) => {
  return { messages: state.messages, common: state.common };
};

export default connect(mapStateToProps, {
  closeDialog,
  toggleChat,
})(ChatWindow);
