import React, { useState } from 'react';
import Column from '../../../../PatientPage/components/Column';
import TextInput from '../../../../../uikit/TextInput';
import { getMask } from '../../../../../utils/masks';
import styled from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TableActionButton, TableActionMenu } from '../../../../../components/Table/styles';
import { DotsIcon } from '../../../../../uikit/Icons';
import { MenuItem } from '@material-ui/core';
import { BorderSelect } from '../../../Template/Select';
import { TextWithPopover } from '../../../Template/TextWithPopover';
import {
  DiscountTypeItems,
  DiscountTypeValues,
  TreatmentPlanItem,
} from '../../../Template/TreatmentPlan/TreatmentPlanJournalField';
import { InvoiceHistory } from './JournalAccountField';

const useStyles = makeStyles(() =>
  createStyles({
    discountSum: {
      borderBottomRightRadius: '0 !important',
      borderTopRightRadius: '0 !important',
    },
    discountType: {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderLeft: 0,
    },
  }),
);

const Row = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${({ selected = false }) => (selected ? '#F6F6FA' : 'white')};
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
`;

type Props = {
  item: TreatmentPlanItem;
  invoiceHistoryItem?: InvoiceHistory;
  setItem: (item) => void;
  textColumnWidth: number;
  readOnly?: boolean;
  isAddAccount?: boolean;
  totalDiscount: number;
  onRowDelete: (template) => void;
};

export function JournalAccountFieldItem({
  item,
  invoiceHistoryItem,
  setItem,
  textColumnWidth,
  readOnly = false,
  totalDiscount,
  onRowDelete,
}: Props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Row selected={item.isUsed}>
      <Column name="code" defaultValue={item.code} width={94} fontSize={12} isEditing={false} hasAction>
        {item.code}
      </Column>
      <Column name="text" defaultValue={item.text} width={textColumnWidth} fontSize={12} isEditing={false} hasAction>
        <TextWithPopover text={item.text} width={386} height={70} />
      </Column>
      <Column name="count" defaultValue={item.count} width={55} fontSize={12} isEditing={false} hasAction>
        {readOnly || invoiceHistoryItem?.count >= item.count ? (
          item.count
        ) : (
          <TextInput
            value={item.count}
            style={{ height: 22, fontSize: 12 }}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setItem({ ...item, count: +getMask('count_without_zero').resolve(e.target.value) || 1 })}
          />
        )}
      </Column>
      <Column name="price" defaultValue={item.price} width={66} fontSize={12} isEditing={false} hasAction>
        {getMask('number_format').resolve(`${item.price}`)}
      </Column>
      <Column
        name="discount"
        defaultValue={item.discount}
        width={readOnly ? 58 : 108}
        fontSize={12}
        isEditing={false}
        hasAction
      >
        {readOnly ? (
          getMask('number_format').resolve(`${item.discount}`)
        ) : (
          <div style={{ display: 'flex' }}>
            <TextInput
              className={classes.discountSum}
              value={item.discount}
              style={{ height: 22, width: 47, fontSize: 12 }}
              disabled={!!totalDiscount}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                setItem({ ...item, discount: +getMask('count').resolve(`${+e.target.value}`) });
              }}
            />
            <BorderSelect
              className={classes.discountType}
              value={item.discountType}
              disabled={!!totalDiscount}
              setValue={(value) => {
                setItem({
                  ...item,
                  discountType: value,
                  discount: value === DiscountTypeValues.PERCENT && value > 100 ? 100 : item.discount,
                });
              }}
              items={DiscountTypeItems}
            />
          </div>
        )}
      </Column>
      <Column name="text" defaultValue={item.payment} width={72} fontSize={12} isEditing={false} hasAction>
        {getMask('number_format').resolve(`${item.payment - item.paid}`)}
      </Column>
      {!(readOnly || !!invoiceHistoryItem?.paid) && (
        <>
          <TableActionButton
            style={{ position: 'absolute', right: 4 }}
            size="small"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            disableRipple
          >
            <DotsIcon />
          </TableActionButton>
          <TableActionMenu id="action-menu" anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
            <MenuItem
              onClick={() => {
                onRowDelete(item);
                handleMenuClose();
              }}
            >
              Удалить
            </MenuItem>
          </TableActionMenu>
        </>
      )}
    </Row>
  );
}
