import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Header from '../../components/Header/TasksHeader';
import Drawer from '../../components/Drawer';
import { StaffTable } from '../../features/staff';
import { routerConfig } from '../../navigation/routerConfig';
import { Requisites } from './components/Requisites';
import { Schedule } from './components/Schedule';
import { Cabinets } from './components/Сabinets';
import { ToothStateSettings } from './components/ToothStateSettings';
import { DictionaryMKB } from './components/DictionaryMKB';
import { PAGE_PADDING } from '../StaffPage/style';
import Footer from './Footer';
import { EnterModal } from './EnterModal';
import { stopEditClinic } from '../../features/sync/operations';
import { useAppSelector } from '../../store/hooks';
import { isEmpty } from 'lodash';
import EnjoyHint from '../../EnjoyHint';

import 'enjoyhint.js/dist/enjoyhint.css';

const Page = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #f9f9f9;
  width: 100%;
  overflow: hidden;
`;
const Layout = styled.div`
  width: 100%;
  min-width: 1024px;
  max-width: 1444px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 70px;
  overflow: hidden;
  margin-top: 50px;
`;

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    borderRadius: '2px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    border: 0,
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#F9F9F9',
    borderRadius: '2px',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    flexDirection: 'row-reverse',
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expandIcon: {
    marginRight: 0,
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    display: 'block',
    padding: theme.spacing(3),
    border: 0,
    paddingTop: 1,
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
    },
  }),
);

const enjoyhint_script_steps = [
  {
    'next .MuiGrid-root': 'Заполните данные клиники',
  },
  {
    'next .logo': 'Загрузите логотип клиники. Будет использоваться при печати документов',
  },
  {
    'next .schedule': 'Настройте график работы клиники',
  },
  {
    'next .cabinets': 'Настройте кабинеты клиники для создания расписания',
  },
  {
    'click #panel2a-header': 'Добавьте сотрудников',
  },
];

export default function ProfilePage() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(true);

  const {
    user: { id, is_superuser: isSuperUser },
    sessions,
  } = useAppSelector((state) => state.common);
  const editors = sessions.filter((s) => s.session_user_id !== id);

  const onChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    return () => {
      stopEditClinic(id);
      localStorage.setItem('first_settings', 'no');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enjoyhint_instance = useMemo(() => new EnjoyHint({}), []);
  enjoyhint_instance.set(enjoyhint_script_steps);

  useEffect(() => {
    if (!visible && localStorage.getItem('first_settings') === 'yes') {
      enjoyhint_instance.run();
    }
  }, [enjoyhint_instance, visible]);

  return (
    <>
      <Page>
        <div>
          <div
            style={{
              width: '100vw',
              paddingLeft: PAGE_PADDING,
              paddingRight: PAGE_PADDING,
            }}
          >
            <Header text={routerConfig.PROFILE.title} />
            <Drawer />
          </div>
          <Layout>
            <Requisites />
            <Schedule />
            <Accordion
              style={{
                marginTop: expanded === 'panel1' ? '2px' : 0,
                boxShadow:
                  expanded === 'panel1'
                    ? 'none'
                    : '0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)',
              }}
              elevation={expanded === 'panel1' ? 0 : 1}
              onChange={onChange('panel1')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={classes.heading}>
                  <span className="cabinets">Кабинеты</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: '#f9f9f9' }}>
                <Cabinets />
              </AccordionDetails>
            </Accordion>
            <Accordion
              onChange={onChange('panel2')}
              style={{ boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)' }}
            >
              <AccordionSummary
                style={{ background: '#FFFFFF' }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>Сотрудники</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <StaffTable />
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: '#f9f9f9',
                marginTop: expanded === 'panel3' ? '4px' : 0,
                boxShadow:
                  expanded === 'panel3'
                    ? 'none'
                    : '0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)',
              }}
              onChange={onChange('panel3')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography className={classes.heading}>Настройка обозначений для зубной формулы</Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: '#f9f9f9',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '4px',
                  marginBottom: '50px',
                }}
              >
                <ToothStateSettings />
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                paddingBottom: '1px',
                boxShadow:
                  expanded === 'panel4'
                    ? '0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)'
                    : '0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)',
              }}
              onChange={onChange('panel4')}
            >
              <AccordionSummary
                style={{ background: '#FFFFFF' }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Typography className={classes.heading}>Справочник МКБ</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DictionaryMKB />
              </AccordionDetails>
            </Accordion>
          </Layout>
        </div>
      </Page>
      <Footer />
      {(isEmpty(editors) || isSuperUser) && <EnterModal visible={visible} setVisible={setVisible} />}
    </>
  );
}
