import React, { useMemo } from 'react';
import { FooterContainer, Total } from './styles';

const DebtReportTableFooter = ({ tableData, displayMode }) => {
  const totalDebt = useMemo(
    () => (displayMode !== 'advances' ? tableData.reduce((sum, item) => item.debt + sum, 0) : 0),
    [tableData, displayMode],
  );
  const totalAdvance = useMemo(
    () => (displayMode !== 'debts' ? tableData.reduce((sum, item) => item.advance + sum, 0) : 0),
    [tableData, displayMode],
  );
  return (
    <>
      <FooterContainer>
        <div style={{ padding: '11px 8px 11px 16px' }}>Итого:</div>
        {displayMode !== 'advances' && (
          <div style={{ marginRight: displayMode === 'debts' ? '200px' : '160px', display: 'flex' }}>
            <Total style={{ maxWidth: '56px', display: 'block', color: '#c3000a' }}>{totalDebt}</Total>
          </div>
        )}

        {displayMode !== 'debts' && (
          <Total style={{ marginRight: displayMode === 'advances' ? '155px' : '115px', color: '#0d853d' }}>
            {totalAdvance}
          </Total>
        )}
      </FooterContainer>

      {displayMode === 'advances debts' && (
        <FooterContainer>
          <div style={{ marginRight: '158px', display: 'flex' }}>
            <div style={{ padding: '11px 8px 11px 16px' }}>Баланс:</div>
            <Total style={{ maxWidth: '56px', display: 'block' }}>{totalDebt + totalAdvance}</Total>
          </div>
        </FooterContainer>
      )}
    </>
  );
};

export default DebtReportTableFooter;
