import React from 'react';
import { MenuItem } from '../../styles';
import { OnTopTooltip } from '../../../../components/DoctorFilter/OnTopTooltip';
import Tooltip from '../../../../components/Tooltip/Tooltip';

function Item({ disabled, editor, onClick, text, tooltipPlacement = 'bottom' }) {
  return disabled ? (
    <OnTopTooltip title={'Включите режим редактирования'} placement={tooltipPlacement} arrow>
      <MenuItem disabled={disabled}>{text}</MenuItem>
    </OnTopTooltip>
  ) : (
    <MenuItem disabled={disabled || !!editor} onClick={onClick}>
      {!!editor ? (
        <Tooltip
          title={
            <div style={{ display: 'flex', gap: '5px', alignItems: 'flex-start' }}>
              <img style={{ marginTop: 2 }} src="./img/black/info-circle.svg" alt="attention" />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>{`Редактирует ${editor}`}</div>
                <div>Одновременная работа недоступна</div>
              </div>
            </div>
          }
          placement={'bottom-end'}
          enterDelay={500}
          enterNextDelay={500}
        >
          <span>{text}</span>
        </Tooltip>
      ) : (
        text
      )}
    </MenuItem>
  );
}

export default Item;
