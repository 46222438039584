import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { baseURL, HttpMethod } from '../utils/request';
import { FormulaType } from '../pages/MedcardPage/Formula';
import { groupBy, isEmpty } from 'lodash';
import { getIconState } from '../pages/ProfilePage/components/ToothStateSettings';
import { isAfter, parseISO } from 'date-fns';

type MedcardDateResponse = {
  id: number;
  date: string;
  appointment: {
    id: number;
    start_at: string;
    ends_at: string;
  };
};

export type MedcardDate = {
  id: number;
  date: Date;
  timeStart: Date | null;
  timeEnd: Date | null;
};

export type DoctorType = {
  id: number;
  first_name: string;
  second_name: string;
  last_name: string;
  job: string;
};

type ToothStateType = {
  mnemonic: string;
  title_russian: string;
  icon_letter: string;
  icon_type_mnemonic: string;
};

export type MedcardEntry = {
  id?: number;
  doctor: DoctorType;
  date_id: number;
  formula_type: FormulaType;
  tooth_index: string;
  tooth_state_label: string;
  tooth_state: ToothStateType;
  tooth_treatment: ToothStateType;
};

export type AppointmentType = {
  id: number;
  starts_at: string;
  ends_at: string;
  doctor: DoctorType;
};

export type ToothHistory = {
  date_id: number;
  entry_id: number;
  tooth_state: ToothStateType;
  tooth_treatment: ToothStateType;
  tooth_index: string;
};

export const medcardApi = createApi({
  reducerPath: 'medcardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      headers.set('authorization', `Token ${getState().common.access_token}`);
      return headers;
    },
  }),
  tagTypes: ['MedcardDate', 'MedcardEntries', 'Appointment', 'AllToothHistory'],
  endpoints: (builder) => ({
    getDates: builder.query<MedcardDate[], number>({
      query: (patientId) => `patient/${patientId}/medcard/dates`,
      transformResponse: (response: MedcardDateResponse[]) => {
        return response
          .filter(({ date }) => isAfter(Date.now(), parseISO(date)))
          .map(({ id, date, appointment }) => ({
            id,
            date: parseISO(date),
            timeStart: appointment && parseISO(appointment.starts_at),
            timeEnd: appointment && parseISO(appointment.ends_at),
          }));
      },
      providesTags: [{ type: 'MedcardDate', id: 'LIST' }],
    }),
    addDate: builder.mutation<MedcardDate, { patientId: number; date: string; appointment: { id: number } }>({
      query: (data) => {
        const { date, patientId, appointment } = data;
        return {
          url: `/patient/${patientId}/medcard/dates`,
          method: HttpMethod.POST,
          body: { date, appointment },
        };
      },
    }),
    deleteDate: builder.mutation<void, { patientId: number; dateId: number }>({
      query: (data) => {
        const { dateId, patientId } = data;
        return {
          url: `/patient/${patientId}/medcard/dates/${dateId}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: [{ type: 'MedcardDate', id: 'LIST' }],
    }),
    getEntries: builder.query<{ items: MedcardEntry[] }, { patientId: number; dateId: number }>({
      query: ({ patientId, dateId }) => `patient/${patientId}/medcard/entries?date_id=${dateId}`,
      transformResponse: (response: MedcardEntry[]) => {
        return response.map((d) => ({
          id: d.id,
          dateId: d.date_id,
          toothIndex: d.tooth_index,
          toothStateLabel: d.tooth_state_label,
          // Не стал менять местами last_name и second_name в хуке
          doctor: {
            id: d.doctor.id,
            firstName: d.doctor.first_name,
            secondName: d.doctor.second_name,
            lastName: d.doctor.last_name,
          },
          toothState: isEmpty(d.tooth_state)
            ? null
            : {
                icon: d.tooth_state.icon_letter,
                title: d.tooth_state.title_russian,
                mnemo: d.tooth_state.mnemonic,
                iconColor: getIconState(d.tooth_state.icon_type_mnemonic).color,
              },
          toothTreatment: isEmpty(d.tooth_treatment)
            ? null
            : {
                icon: d.tooth_treatment.icon_letter,
                title: d.tooth_treatment.title_russian,
                mnemo: d.tooth_treatment.mnemonic,
                iconColor: getIconState(d.tooth_treatment.icon_type_mnemonic).color,
              },
        }));
      },
      providesTags: [{ type: 'MedcardEntries', id: 'LIST' }],
    }),
    addEntry: builder.mutation<MedcardEntry, { entry: MedcardEntry; patientId: number }>({
      query: (data) => {
        const { entry, patientId } = data;
        return {
          url: `patient/${patientId}/medcard/entries`,
          method: HttpMethod.POST,
          body: { ...entry },
        };
      },
      invalidatesTags: [
        { type: 'MedcardEntries', id: 'LIST' },
        { type: 'MedcardDate', id: 'LIST' },
        { type: 'AllToothHistory', id: 'LIST' },
      ],
    }),
    updateEntry: builder.mutation<MedcardEntry, { entry: Partial<MedcardEntry>; patientId: number }>({
      query: (data) => {
        const { entry, patientId } = data;
        return {
          url: `patient/${patientId}/medcard/entries/${entry.id}`,
          method: HttpMethod.PATCH,
          body: { ...entry },
        };
      },
      invalidatesTags: [
        { type: 'MedcardEntries', id: 'LIST' },
        { type: 'AllToothHistory', id: 'LIST' },
      ],
    }),
    deleteEntry: builder.mutation<void, { patientId: number; entryId: number }>({
      query: (data) => {
        const { entryId, patientId } = data;
        return {
          url: `patient/${patientId}/medcard/entries/${entryId}`,
          method: HttpMethod.DELETE,
        };
      },
      invalidatesTags: [
        { type: 'MedcardEntries', id: 'LIST' },
        { type: 'MedcardDate', id: 'LIST' },
        { type: 'AllToothHistory', id: 'LIST' },
      ],
    }),
    getAppointments: builder.query<AppointmentType[], number>({
      query: (patientId) => `patient/${patientId}/appointments`,
      providesTags: [{ type: 'Appointment', id: 'LIST' }],
    }),
    getAllToothHistory: builder.query<ToothHistory[], number>({
      query: (patientId) => `patient/${patientId}/tooth_history`,
      providesTags: [{ type: 'AllToothHistory', id: 'LIST' }],
      transformResponse: (response: ToothHistory[]) => {
        return groupBy(
          response.map((h) => ({
            dateId: h.date_id,
            toothIndex: h.tooth_index,
            entryId: h.entry_id,
            toothState: isEmpty(h.tooth_state)
              ? null
              : {
                  icon: h.tooth_state.icon_letter,
                  title: h.tooth_state.title_russian,
                  mnemo: h.tooth_state.mnemonic,
                  iconColor: getIconState(h.tooth_state.icon_type_mnemonic).color,
                },
            toothTreatment: isEmpty(h.tooth_treatment)
              ? null
              : {
                  icon: h.tooth_treatment.icon_letter,
                  title: h.tooth_treatment.title_russian,
                  mnemo: h.tooth_treatment.mnemonic,
                  iconColor: getIconState(h.tooth_treatment.icon_type_mnemonic).color,
                },
          })),
          'toothIndex',
        );
      },
    }),
  }),
});

export const {
  useGetDatesQuery,
  useLazyGetDatesQuery,
  useAddDateMutation,
  useDeleteDateMutation,
  useGetEntriesQuery,
  useAddEntryMutation,
  useUpdateEntryMutation,
  useDeleteEntryMutation,
  useGetAppointmentsQuery,
  useGetAllToothHistoryQuery,
} = medcardApi;
