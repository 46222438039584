import styled from 'styled-components';
import { IOptionProps, ISelectedOptionProps, ISelectorProps } from './types';

export const Selector = styled.div<ISelectorProps>`
  width: 152px;
  height: 24px;
  border: ${({ value }) => (value === 'SCH' ? '1px solid #fadae2' : '1px solid #DCF3D1')};
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const SelectedOption = styled.div<ISelectedOptionProps>`
  font-size: 12px;
  color: ${({ value }) => (value === 'SCH' ? '#c3000a' : '#0D853D')};
`;

export const Options = styled.div`
  width: 152px;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  overflow: hidden;
`;

export const Option = styled.div<IOptionProps>`
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #515d6b;
  cursor: pointer;
  background: ${({ selected }) => (selected ? '#F6F6FA' : '#FFFFFF')};
  &:hover {
    background: #eff2ff;
  }
`;

export const VisitStatusWrap = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
`

export const Select = styled.div`
    display: flex;
    align-items: center;
`