import React, {useState} from 'react';
import Column from "../../../PatientPage/components/Column";
import {PopupMenu} from "../PopupMenu";
import {DiagnosisMKBTemplate} from "./DiagnosisMKBTemplates";
import styled from "styled-components";
import {TextWithPopover} from "../TextWithPopover";

const Row = styled.div<{selected: boolean}>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${({selected}) => selected ? '#F6F6FA' : 'white'};
  border-bottom: 1px solid #D9D9D9;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

type Props = {
  template: DiagnosisMKBTemplate;
  isDictionary?: boolean;
  onClickMenu: Function;
  onAddTemplate: Function;
}

export function DiagnosisMKBItem({template, isDictionary, onClickMenu, onAddTemplate}: Props){
  return (
    <Row selected={template.selected}>
      <div style={{display: 'flex', flex: 1}} onClick={() => onAddTemplate(template)}>
        <Column
          name="code"
          defaultValue={template.code}
          width={86}
          isEditing={false}
          hasAction
        >
          <TextWithPopover text={template.code} width={200} height={50}/>
        </Column>
        <Column
          name="text"
          defaultValue={template.text}
          isEditing={false}
          hasAction
        >
          {template.text}
        </Column>
      </div>
      <div style={{margin: '0 16px'}}>
        <PopupMenu actions={[{
          name: isDictionary ? 'ADD' : 'DELETE',
          text: isDictionary ? 'Добавить в шаблон' : 'Удалить из шаблона',
          handler: () => onClickMenu(template)
        }]}/>
      </div>
    </Row>
  )
}
