import React, { useEffect, useMemo } from 'react';
import { DialogContent, Modal } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import FreezeModalState from './state';
import { useAppSelector } from '../../store/hooks';

export const Image = styled.img`
  display: block;
  left: 24px;
  position: absolute;
  top: 24px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #595959;
  line-height: 22px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 462,
      padding: 24,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      transition: 'height 1s ease-in-out 500ms, width 50ms linear',
    },
    content: {
      paddingTop: '0 !important',
      paddingBottom: 0,
      paddingLeft: 32,
      paddingRight: 0,
    },
  }),
);

export function FreezeModal() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const freezeModalState = useSelector((state: RootState) => state.freezeModalState);
  const { show, hide } = FreezeModalState.actions;

  const {
    user: { id, is_superuser: isSuperUser },
    sessions,
  } = useAppSelector((state) => state.common);
  const editors = useMemo(
    () => sessions.filter((s) => s.session_user_id !== id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessions],
  );
  const staff = useAppSelector((state) => state.staff.responce);
  const editorStaff = staff.find((s) => editors.find((e) => e.session_user_id === s.id));

  useEffect(
    () => {
      if (!editors?.length || isSuperUser) {
        dispatch(hide());
        if (!isSuperUser && !sessionStorage.getItem('reload')) {
          sessionStorage.setItem('reload', true);
          location.reload();
        }
      } else {
        dispatch(show());
        if (!isSuperUser) {
          sessionStorage.removeItem('reload');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editors],
  );

  return (
    <Modal className={classes.modal} open={freezeModalState.visible}>
      <div className={classes.paper}>
        <DialogContent className={classes.content}>
          <Image src="./img/yellow/exclamation-circle.svg" />
          <Text>
            {editorStaff?.is_superuser ? '' : 'Сотрудник'}{' '}
            <span style={{ fontWeight: 500, marginRight: 2 }}>{editors[0]?.session_user_name}</span>
            {editorStaff?.is_superuser && (
              <span style={{ fontWeight: 500, marginRight: 2 }}>(Администратор программы)</span>
            )}
            начал работу на странице настройки профиля. Ваша активность временно приостановлена. Продолжить работу в
            программе возможно после того, как настройка будет завершена
          </Text>
        </DialogContent>
      </div>
    </Modal>
  );
}
