import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TextInput from '../../../../../uikit/TextInput';
import { Tooltip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type ItemProps = {
  disabled?: boolean;
};
const ActionImage = styled.img`
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

const Text = styled.div<ItemProps>`
  text-overflow: ellipsis;
  width: calc(100% - 18px);
  white-space: nowrap;
  overflow: hidden;
  color: ${({ disabled }) => (disabled ? '#A8AEB5' : '#515D6B')};
`;

const Item = styled.li<ItemProps>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      fontSize: 14,
      fontWeight: 'normal',
      padding: 4,
      borderRadius: 4,
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
      background: '#253446',
      maxWidth: 180,
    },
  }),
);

export const AutocompleteItem = ({ liProps, title, maxLength, editable, onEditItem, onDeleteItem, disabled }) => {
  const classes = useStyles();

  const [isAbleToDelete, setIsAbleToDelete] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const textDiv = useRef(null);

  useEffect(() => {
    setValue(title);
  }, [title]);

  const handleEditItem = (value) => {
    if (value) {
      onEditItem?.(value);
    }
  };
  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isAbleToDelete) {
      handleEditItem(e.target.value);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Item {...liProps} disabled={disabled}>
        {isEdit ? (
          <TextInput
            autoFocus
            maxLength={maxLength}
            style={{ height: 24, fontSize: 12, width: '80%' }}
            value={value}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setValue(e.target.value)}
            onBlur={handleBlur}
          />
        ) : textDiv?.current?.scrollWidth > textDiv?.current?.clientWidth ? (
          <Tooltip classes={classes} title={value} placement="bottom-start">
            <Text ref={textDiv}>{value}</Text>
          </Tooltip>
        ) : (
          <Text ref={textDiv} disabled={disabled}>
            {value}
          </Text>
        )}
      </Item>

      {isEdit ? (
        <ActionImage
          src="./img/grey/delete.svg"
          onClick={() => onDeleteItem()}
          onMouseOver={() => setIsAbleToDelete(true)}
          onMouseLeave={() => setIsAbleToDelete(false)}
        />
      ) : (
        editable && <ActionImage src="./img/grey/edit_row.svg" onClick={() => setIsEdit(true)} />
      )}
    </div>
  );
};
