export const routerConfig = {
  ROOT: {
    path: '/',
  },
  REAUTH: {
    path: '/reauth',
  },
  PROFILE: {
    path: '/profile',
    title: 'Настройки профиля',
  },
  RECEPTION: {
    path: '/reception',
    title: 'Ресепшен',
  },
  PATIENTS: {
    path: '/patients',
    title: 'Пациенты',
  },
  PRICE: {
    path: '/price',
    title: 'Прейскурант',
  },
  SCHEDULE: {
    path: '/schedule',
    title: 'Расписание',
  },
  VISITS_AND_PAYMENTS: {
    path: '/visits_and_payments',
    title: 'Визиты и оплаты',
  },
  STAFF: {
    path: '/staff',
    title: 'Сотрудники',
  },
  WORKDAYS: {
    path: '/workdays',
  },
  MEDCARD: {
    path: '/medcard',
  },
  REPORT: {
    path: '/report',
    title: 'Отчеты',
  },
};
