import {
  LOAD_APPOINTMENTS,
  PICK_APPOINTMENT,
  SET_APPOINTMENTS_DETAILS,
  SET_DETAILS_VISIBILITY,
  SET_ERRORS,
  SET_RESCHEDULING,
  SET_SAVED_PATIENT,
  SET_STATUS,
  SET_STEP,
  SET_TIME_END,
  SET_TIME_START,
  CANCEL_APPOINTMENT_CREATION,
} from "./types";
import { FLUSH_STATE } from "../flushState/types";

const INITIAL_STATE = {
  responce: [],
  time: { start: {}, end: {} },
  details: { visible: false, doctor: "" },
  current: {},
  errors: { overlapingDates: false },
  new: false,
  nextStep: true,
  saved: { hasSavedPatient: false, patient: {}, doctor: {}, cabinet: {} },
  detailsShown: false,
  rescheduling: false,
  event_type: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SAVED_PATIENT:
      return {
        ...state,
        ...{
          saved: {
            hasSavedPatient: action.payload.status,
            patient: action.payload.patient,
            doctor: action.payload.doctor,
            cabinet: action.payload.cabinet,
          },
        },
      };
    case SET_STEP:
      return { ...state, ...{ nextStep: action.payload } };
    case SET_STATUS:
      return { ...state, ...{ new: action.payload } };
    case SET_ERRORS:
      return {
        ...state,
        ...{ errors: { overlapingDates: action.payload } },
      };
    case SET_TIME_START:
      return {
        ...state,
        ...{ time: { start: action.payload, end: {} } },
      };
    case SET_TIME_END:
      return {
        ...state,
        ...{ time: { start: state.time.start, end: action.payload } },
      };
    case SET_DETAILS_VISIBILITY:
      return {
        ...state,
        ...{
          details: { ...state.details, ...{ visible: action.payload } },
        },
      };
    case LOAD_APPOINTMENTS:
      return {
        ...state,
        ...{ responce: [] },
      };
    case PICK_APPOINTMENT:
      return {
        ...state,
        ...{ current: action.payload },
      };
    case SET_APPOINTMENTS_DETAILS:
      return {
        ...state,
        detailsShown: action.payload.isOpened,
        current: action.payload.appointment,
      };
    case SET_RESCHEDULING:
      return {
        ...state,
        rescheduling: action.payload.value,
        event_type: action.payload.type,
      };
    case CANCEL_APPOINTMENT_CREATION:
      return {
        ...state,
        time: { start: {}, end: {} },
        details: { visible: false, doctor: "" },
        current: {},
        errors: { overlapingDates: false },
        new: false,
        nextStep: true,
        saved: {
          hasSavedPatient: false,
          patient: {},
          doctor: {},
          cabinet: {},
        },
        detailsShown: false,
        rescheduling: false,
        event_type: null,
      };
    case FLUSH_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
}
