import React from 'react';
import { DialogActions, DialogContent, Modal } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import BlueButton from '../../../uikit/Button/BlueButton';
import { LinkButton } from '../../../uikit/Button/LinkButton';
import styled from 'styled-components';
import { routerConfig } from '../../../navigation/routerConfig';
import { useNavigate } from 'react-router-dom';
import { startEditClinic } from '../../../features/sync/operations';
import { useAppSelector } from '../../../store/hooks';

export const Image = styled.img`
  display: block;
  width: 21px;
  height: 21px;
  left: 25px;
  position: absolute;
  top: 25px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #595959;
  line-height: 22px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 462,
      padding: 24,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      transition: 'height 1s ease-in-out 500ms, width 50ms linear',
    },
    content: {
      paddingTop: '0 !important',
      paddingBottom: 0,
      paddingLeft: 38,
      paddingRight: 0,
    },
  }),
);

export function EnterModal({ visible, setVisible }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    user: { id },
    sessions,
  } = useAppSelector((state) => state.common);
  const editors = sessions.filter((s) => s.session_user_id !== id);

  return (
    <Modal className={classes.modal} open={visible} disableBackdropClick>
      <div className={classes.paper}>
        <DialogContent className={classes.content}>
          <Image src="./img/yellow/exclamation-circle.svg" />
          {!editors?.length ? (
            <Text>Ваша работа на странице настройки профиля приостановит работу во всей программе</Text>
          ) : (
            <Text>
              Сотрудник {editors[0]?.session_user_name} сейчас работает на странице настройки профиля клиники. Ваш вход
              на страницу приостановит работу во всей программе и удалит все данные внесенные этим сотрудником.
            </Text>
          )}
          <Text>Продолжить вход?</Text>
        </DialogContent>
        <DialogActions style={{ paddingBottom: 0 }}>
          <LinkButton style={{ marginRight: 16 }} disableRipple onClick={() => navigate(routerConfig.SCHEDULE.path)}>
            Отменить
          </LinkButton>
          <BlueButton
            width={72}
            style={{ margin: 0 }}
            onClick={() => {
              startEditClinic(id);
              setVisible(false);
            }}
          >
            Войти
          </BlueButton>
        </DialogActions>
      </div>
    </Modal>
  );
}
