import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const LinkButton = withStyles({
  root: {
    color: '#577BF9',
    border: 0,
    fontStyle: 'normal',
    fontWeight: 'normal',
    minWidth: 'auto',
    outline: 'none',
    transition: 'none',
    fontSize: '14px',
    '&:hover': {
      background: 'transparent',
      outline: 'none',
    },
    '&:focus': {
      background: 'transparent',
      outline: 'none',
    },
    '&:active': {
      background: 'transparent',
      outline: 'none',
    },
  },
  label: {
    textTransform: 'none',
    fontWeight: 400,
    justifyContent: 'flex-start',
  },
})(Button);
