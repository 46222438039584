import React from 'react';
import styled from 'styled-components';
import { styled as styledMui } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  color: #515d6b;
`;
export const Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  color: #253446;
`;
export const Name = styled.p`
  margin-left: 20px;
  margin-top: 2.5px;
`;
export const Job = styled.p`
  margin-left: 20px;
  margin-top: 2.5px;
  color: #7c8590;
`;
export const Content = styled.div`
  display: flex;
  padding: 0px 24px;
  min-height: 800px;
  position: relative;
`;
export const Settings = styled.div`
  padding: 16px 24px;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  min-width: 286px;
`;
export const SettingsTitle = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #253446;
`;
export const MatchesWarningContainer = styled.div`
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translateX(-50%);
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background-color: white;
  border-radius: 4px;

  & > * {
    margin: 0;
    margin-right: 12px;
  }

  & > p,
  & > span {
    font-size: 14px;
    font-weight: 400;
    color: #515d6b;
    white-space: nowrap;
  }

  & > span {
    margin-right: 0;
    color: #577bf9;
    text-decoration: underline;
    cursor: pointer;
  }
`;
export const CustomTooltip = styledMui(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  left: '20px !important',

  '& .MuiTooltip-tooltip': {
    backgroundColor: 'white',
    boxShadow: '0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)',
    borderRadius: '4px',
    color: '#7C8590',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
  },
  '& .MuiTooltip-arrow::before': {
    backgroundColor: 'white',
  },
}));
