import React, { FC, memo, useEffect, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styled from 'styled-components';
import { InvoiceProperties } from '../enums';
import { Modal } from '../../../../uikit/Modal';

interface SelectItem {
  value: string;
  name: string;
}
interface DiscountTypeSelectProps {
  value: string;
  inputId?: string;
  items?: SelectItem[];
  handleUpdateInvoice?: (value: string, rowId: number, property: string) => void;
  rowId: number;
}

interface OptionProps {
  selected: boolean;
}

interface SelectorProps {
  width?: number;
}

export const Selector = styled.div<SelectorProps>`
  position: absolute;
  top: 1px;
  right: 4px;
  height: 22px;
  width: 42px;
`;

export const SelectedOption = styled.div`
  height: 22px;
  border-left: 1px solid #d3d6da;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
`;
export const SelectedInput = styled.input`
  border: 0;
  background: inherit;
  color: #515d6b;
  cursor: pointer;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const OptionsContainer = styled.div`
  width: 44px;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 4px 0 4px 0;
  box-sizing: border-box;
`;
export const Option = styled.div<OptionProps>`
  font-size: 12px;
  color: #515d6b;
  box-sizing: border-box;
  padding: 4px 0 4px 4px;
  height: 18px;
  display: flex;
  align-items: center;
  background: ${({ selected }) => (selected ? '#F6F6FA' : '#FFFFFF')};
  cursor: pointer;

  &:hover {
    background: #eff2ff;
  }
`;
export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DiscountTypeSelect: FC<DiscountTypeSelectProps> = memo(
  ({ items, handleUpdateInvoice, value, inputId, rowId }) => {
    const [inputValue, setInputValue] = useState<string>(value);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openOptionsList = (event: React.MouseEvent<HTMLElement>) => {
      const parentElement = event.currentTarget.offsetParent;
      setAnchorEl(parentElement);
    };
    const closeOptionsList = () => {
      setAnchorEl(null);
    };
    const onOptionClick = (event: React.MouseEvent<HTMLElement>, value: string) => {
      event.stopPropagation();
      setInputValue(value);
      handleUpdateInvoice(value, rowId, InvoiceProperties.DISCOUNT_TYPE);
      setAnchorEl(null);
    };
    useEffect(() => {
      const discountInput = document.getElementById(inputId);
      anchorEl ? discountInput.classList.add('activeInput') : discountInput.classList.remove('activeInput');
    }, [anchorEl, inputId]);

    return (
      <ClickAwayListener onClickAway={closeOptionsList}>
        <Selector onClick={openOptionsList}>
          <SelectedOption>
            <InputContainer>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <SelectedInput disabled value={inputValue} />
              </div>
              <KeyboardArrowDownIcon style={{ fontSize: '16px' }} />
            </InputContainer>
          </SelectedOption>
          <Modal anchor={anchorEl} unSetContainer={true} zIndex={1400}>
            <OptionsContainer>
              {items.map((item, index) => {
                return (
                  <Option key={index} selected={item.name === inputValue} onClick={(e) => onOptionClick(e, item.name)}>
                    <div style={{ overflow: 'hidden', fontSize: '12px' }}>{item.name}</div>
                  </Option>
                );
              })}
            </OptionsContainer>
          </Modal>
        </Selector>
      </ClickAwayListener>
    );
  },
);
DiscountTypeSelect.displayName = 'DiscountTypeSelect';
