import styled from 'styled-components';

export const StyledWorkDayTime = styled.div`
  display: flex;
  align-items: center;
  height: 88px;
`;

export const WorkDayTimeSeparator = styled.div`
  position: relative;
  width: 8px;

  &::after {
    background: #c4c4c4;
    content: '';
    height: 1px;
    left: 1px;
    position: absolute;
    top: 50%;
    width: 6px;
  }
`;

export const RedactingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 372px;
  padding: 24px 24px 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  transform: translate(-50%, -50%);
`;
export const RedactingHeader = styled.span`
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #253446;
`;
export const RedactingSubheader = styled.span`
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #515d6b;
`;
export const RedactingButtons = styled.div`
  height: 32px;
  margin-top: 20px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;
export const RedactingButton = styled.button`
  padding: 5px 16px;
  //width: 97px;
  //height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.disabled ? '#F6F6FA' : props.primary ? '#577BF9' : '#FFFFFF')};
  border: 1px solid ${(props) => (props.disabled ? '#D3D6DA' : '#577BF9')};
  color: ${(props) => (props.disabled ? '#A8AEB5' : props.primary ? '#FFFFFF' : '#577BF9')};
  border-radius: 4px;
  outline: none !important;
`;
export const MakeNonWorkingDayWrapper = styled.div`
  height: 70px;
  width: 100%;
  border-top: 1px solid #eff0f3;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const MakeNonWorkingDayButton = styled.button`
  padding: 0;
  background: none;
  outline: none !important;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #577bf9;
`;
