import React from 'react';
import PriceEntry from './PriceEntry';

function CategoryEntries({ price, group, groupIndex, sessions, onStartEdit, onStopEdit }) {
  return (
    <>
      {price[group].map((item, itemIndex: number, arr: []) => (
        <PriceEntry
          key={item.id}
          arr={arr}
          item={item}
          groupIndex={groupIndex + ''}
          itemIndex={itemIndex}
          editor={sessions.find((s) => s.target_id === item.id)?.session_user_name ?? null}
          onStartEdit={onStartEdit}
          onStopEdit={onStopEdit}
        />
      ))}
    </>
  );
}

export default CategoryEntries;
