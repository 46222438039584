import { createSlice } from "@reduxjs/toolkit";

const WorkdaysSlice = createSlice({
  name: "workdays",
  initialState: { array: [], object: {} },
  reducers: {
    loadWorkdays: (state, action) => ({
      ...state,
      array: action.payload,
    }),
    setWorkdaysObject: (state, action) => ({
      ...state,
      object: action.payload,
    }),
  },
});

export default WorkdaysSlice;
