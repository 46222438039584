import React from 'react';
import { connect } from 'react-redux';
import { addRowToPrice } from '../operations';
import PriceState from '../state';
import EmptyCategory from './EmptyCategory';
import CategoryEntries from './CategoryEntries';
import { RootState } from '../../../store';

function Body({ priceSlice, group, groupIndex, addRowToPrice, startEditingEntry, sessions, onStartEdit, onStopEdit }) {
  const { price, canEdit } = priceSlice;
  // eslint-disable-next-line no-prototype-builtins
  const isCategoryEmpty = price.hasOwnProperty(group);

  return (
    <>
      {isCategoryEmpty ? (
        <CategoryEntries
          price={price}
          group={group}
          groupIndex={groupIndex}
          sessions={sessions}
          onStartEdit={onStartEdit}
          onStopEdit={onStopEdit}
        />
      ) : (
        <EmptyCategory
          canEdit={canEdit}
          group={group}
          addRowToPrice={addRowToPrice}
          startEditingEntry={startEditingEntry}
        />
      )}
    </>
  );
}

export default connect((state: RootState) => ({ priceSlice: state.priceSlice }), {
  addRowToPrice,
  startEditingEntry: PriceState.actions.startEditingEntry,
})(Body);
