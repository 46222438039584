import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

export const ClinicLogo = withStyles({
  root: {
    border: '1px solid #D3D6DA',
    boxSizing: 'border-box',
    width: 32,
    height: 32,
  },
  img: {
    width: '100%',
    height: '100%',
  },
})(Avatar);
