import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchDialogs,
  fetchDialog,
  searchInMessages,
  openDialog,
} from "../../features/messages/operations";
import ToolBar from "./ToolBar";
import NoDialogsFiller from "./NoDialogsFiller";
import DialogsContent from "./DialogsContent";

function DialogsList({
  messages,
  fetchDialogs,
  searchInMessages,
  isSearching,
  startSearch,
  endSearch,
}) {
  useEffect(() => {
    fetchDialogs();
  }, []);

  return (
    <div style={{ width: 414, height: 399 }}>
      <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <ToolBar
          searchInMessages={searchInMessages}
          isSearching={isSearching}
          startSearch={startSearch}
          endSearch={endSearch}
        />

        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          {messages.dialogs.length >= 1 ? (
            <DialogsContent isSearching={isSearching} />
          ) : (
            <NoDialogsFiller />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    messages: state.messages,
    staff: state.staff,
  };
};

export default connect(mapStateToProps, {
  fetchDialogs,
  fetchDialog,
  openDialog,
  searchInMessages,
})(DialogsList);
