import { loadResponce, setDoctor, selectDoctor } from './actions';
import request from '../../utils/request';
import { setFilter } from '../schedule/actions';
import { fetchSchedule } from '../schedule/operations';
import { fetchStaff as OldFetchStaff } from '../legacyState';

export function patchStaffer(patch, user) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    await request.users.update_user_details(patch, user);
  };
}

export function fetchStaff() {
  return async (dispatch, getState) => {
    try {
      const { common } = getState();
      let responce = await request.clinic.get_staff(common.user.clinic);
      const data = responce.data.items.map((el) => {
        return {
          ...el,
          fullname: `${el.last_name} ${el.first_name} ${el.second_name}`,
        };
      });
      dispatch(loadResponce(data));
    } catch (error) {
      //
    }
  };
}

export function selectStaffer(staffer) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    await dispatch(setFilter({ doctor: [staffer.id] }));
    await dispatch(setDoctor(staffer));
    await dispatch(fetchSchedule());
  };
}

export function findAndSelectDoctor(id) {
  return async (dispatch, getState) => {
    const { staff } = getState();
    const doctor = staff.responce.filter((doctor) => doctor.id === parseInt(id))[0];
    await dispatch(selectDoctor(doctor));
  };
}

export function deleteStaffer() {
  return async (dispatch, getState) => {
    try {
      const { contextMenu } = getState();
      await request.clinic.delete_user(contextMenu.anchorEl.id);
      await dispatch(OldFetchStaff());
    } catch (error) {
      //
    }
  };
}
