import React from 'react';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

function SearchInput(props) {
  const { classes } = props;
  return (
    <TextField
      {...props}
      placeholder="Поиск"
      InputLabelProps={{
        classes: { root: classes.label, focused: classes.focused },
      }}
      InputProps={{
        classes: {
          root: classes.root,
          underline: classes.underline,
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: 'rgb(145,145,145)', width: 16 }} />
          </InputAdornment>
        ),
      }}
      margin="normal"
    />
  );
}
const styles = () => ({
  root: {
    width: '100%',
    padding: 2,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
  },
  label: {
    '&$focused': {
      color: 'rgb(38,38,38)',
    },
  },
  focused: {},
  underline: {
    borderBottom: '1px solid rgb(128,128,128)',
    '&:before': {
      borderBottom: 'unset !important',
    },
    '&:after': {
      borderBottom: 'unset !important',
    },
  },
});
export default withStyles(styles)(SearchInput);
