import React, { FC } from 'react';
import styled from 'styled-components';

type MessageContainerProps = {
  arrowMarginLeft?: number;
  arrowMarginRight?: number;
};

export const MessageContainer = styled.div<MessageContainerProps>`
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 5px 9px;
  border: 1px solid #e5e6ea;
  border-radius: 2px;
  font-size: 14px;
  position: relative;
  margin-top: 7px;
  ::before,
  ::after {
    content: '';
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-top-width: 0;
  }
  ::before {
    left: ${({ arrowMarginLeft }) => (arrowMarginLeft ? arrowMarginLeft : 226)}px;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    top: -5px;
    z-index: 2;
  }
  ::after {
    right: ${({ arrowMarginRight }) => (arrowMarginRight ? arrowMarginRight : 28)}px;
    top: -6px;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 6px solid #e5e6ea;
  }
`;
type ValidationMessageProps = {
  arrowMarginLeft?: number;
  arrowMarginRight?: number;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  children: any;
};

export const ValidationMessage: FC<ValidationMessageProps> = ({ children, arrowMarginLeft, arrowMarginRight }) => {
  return (
    <MessageContainer arrowMarginLeft={arrowMarginLeft} arrowMarginRight={arrowMarginRight}>
      <img src="./img/red/attention.svg" alt="mistake" />
      <span style={{ marginLeft: '5px' }}>{children}</span>
    </MessageContainer>
  );
};
