import { WS_ACTION_TYPES } from './actionTypes';

export const pingAction = () => ({
  action: WS_ACTION_TYPES.PING,
  data: {
    target_id: sessionStorage.getItem('id'),
  },
});

export const startEditEmployeeAction = (userId) => ({
  action: WS_ACTION_TYPES.START_EDIT_EMPLOYEE,
  data: {
    target_id: userId,
  },
});

export const stopEditEmployeeAction = (userId) => ({
  action: WS_ACTION_TYPES.STOP_EDIT_EMPLOYEE,
  data: {
    target_id: userId,
  },
});

export const startEditPatientAction = (patientId) => ({
  action: WS_ACTION_TYPES.START_EDIT_PATIENT,
  data: {
    target_id: patientId,
  },
});

export const stopEditPatientAction = (patientId) => ({
  action: WS_ACTION_TYPES.STOP_EDIT_PATIENT,
  data: {
    target_id: patientId,
  },
});

export const startEditAppointmentAction = (appointmentId) => ({
  action: WS_ACTION_TYPES.START_EDIT_APPOINTMENT,
  data: {
    target_id: appointmentId,
  },
});

export const stopEditAppointmentAction = (appointmentId) => ({
  action: WS_ACTION_TYPES.STOP_EDIT_APPOINTMENT,
  data: {
    target_id: appointmentId,
  },
});

export const startEditPriceAction = (priceId) => ({
  action: WS_ACTION_TYPES.START_EDIT_PRICE,
  data: {
    target_id: priceId,
  },
});

export const stopEditPriceAction = (priceId) => ({
  action: WS_ACTION_TYPES.STOP_EDIT_PRICE,
  data: {
    target_id: priceId,
  },
});

export const connectMedCard = (medCardId) => ({
  action: WS_ACTION_TYPES.CONNECT_MEDCARD,
  data: {
    target_id: medCardId,
  },
});

export const startEditInvoiceAction = (invoiceId) => ({
  action: WS_ACTION_TYPES.START_EDIT_INVOICE,
  data: {
    target_id: invoiceId,
  },
});

export const stopEditInvoiceAction = (invoiceId) => ({
  action: WS_ACTION_TYPES.STOP_EDIT_INVOICE,
  data: {
    target_id: invoiceId,
  },
});

export const editJournalAction = (data) => ({
  action: WS_ACTION_TYPES.EDIT_MEDCARD,
  data,
});

export const startEditClinicAction = (userId) => ({
  action: WS_ACTION_TYPES.START_EDIT_CLINIC,
  data: {
    target_id: userId,
  },
});

export const stopEditClinicAction = (userId) => ({
  action: WS_ACTION_TYPES.STOP_EDIT_CLINIC,
  data: {
    target_id: userId,
  },
});
