/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { LightBox } from '../../../../uikit/LightBox';

import StaffSchedulerCalendar from './components/StaffSchedulerCalendar';
import StaffSchedulerTable from './components/StaffSchedulerTable';
import StaffScheduleMatchesWarning from './components/StaffScheduleMatchesWarning';
import StaffScheduleDateRangePicker from './components/StaffScheduleDateRangePicker';
import StaffScheduleCabinetsPicker from './components/StaffScheduleCabinetsPicker';
import StaffScheduleColorPicker from './components/StaffScheduleColorPicker';
import StaffScheduleHeading from './components/StaffScheduleHeading';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setCurrentStaffer, reset } from './reducer';
import { Content, Settings } from './styled-components';
import { StaffScheduleSetupPropsType } from './types';

import {
  useGetScheduleByClinicIdQuery,
  useUpdateUserMutation,
  useGetStaffByClinicQuery,
  useSetScheduleBulkMutation,
} from '../../../../services/staffSchedule';
import { saveSchedulerData } from './functions';
import {
  checkForAnyRelevantSession,
  handleDateRangeChange,
  handleEvenOddFiltersChange,
  handleInitializeTableData,
  handleSelectedDateChange,
  handleTableDisplayModeChange,
  sessionColorSetter,
} from './components/StaffSchedulerTable/functions';
import LoadingScreen from '../../../../pages/CalendarPage/LoadingScreen';
import { useGetAppointmentsQuery } from '../../../../services/appointments';
import {
  useGetCabinetsDictionaryQuery,
  useGetHolidaysQuery,
  useGetWorkdaysQuery,
} from '../../../../services/dictionaries';
import { stopEditClinic } from '../../../sync/operations';
import { SelectBlocker } from '../../../../pages/CalendarPage/styled-components';

/**
 * Компонент настройки расписания сотрудника
 */

const StaffScheduleSetup: React.FC<StaffScheduleSetupPropsType> = ({ onClose, pickedStafferId }) => {
  const dispatch = useAppDispatch();

  const [tableInitialized, setTableInitialized] = useState(false);
  const [isTableHidden, setIsTableHidden] = useState(false);

  const userId = useAppSelector((state) => state.common.user.id);
  const currentClinicId = useAppSelector((state) => state.common.user.clinic.id);

  const staffSchedulerAPI = useAppSelector((state) => state.staffSchedule.staffSchedulerAPI);
  const tableColumnModel = useAppSelector((state) => state.staffSchedule.tableColumnModel);
  const datesToRender = useAppSelector((state) => state.staffSchedule.datesToRender);
  const schedulerWidth = useAppSelector((state) => state.staffSchedule.schedulerWidth);
  const currentStaffer = useAppSelector((state) => state.staffSchedule.currentStaffer);
  const dateRange = useAppSelector((state) => state.staffSchedule.dateRange);
  const datesInRange = useAppSelector((state) => state.staffSchedule.datesInRange);
  const filterCabinets = useAppSelector((state) => state.staffSchedule.selectedCabinets);
  const lastCabinetId = useAppSelector((state) => state.staffSchedule.lastCabinetId);
  const datesWithSessions = useAppSelector((state) => state.staffSchedule.datesWithSessions);
  const showEvenDates = useAppSelector((state) => state.staffSchedule.showEvenDates);
  const showOddDates = useAppSelector((state) => state.staffSchedule.showOddDates);
  const selectedDate = useAppSelector((state) => state.staffSchedule.selectedDate);
  const sessionsMatches = useAppSelector((state) => state.staffSchedule.sessionsMatches);
  const currentSessionsMatch = useAppSelector((state) => state.staffSchedule.currentSessionsMatch);
  const isWatchingMatches = useAppSelector((state) => state.staffSchedule.isWatchingMatches);
  const sessionWithTooltipId = useAppSelector((state) => state.staffSchedule.sessionWithTooltipId);
  const tableDisplayMode = useAppSelector((state) => state.staffSchedule.tableDisplayMode);

  const { data: schedule = [], isLoading: scheduleIsLoading } = useGetScheduleByClinicIdQuery(currentClinicId);
  const { data: staffers = [], isLoading: staffersAreLoading } = useGetStaffByClinicQuery(currentClinicId);
  const { data: appointments = [], isLoading: appointmentsAreLoading } = useGetAppointmentsQuery(currentClinicId);
  const { data: holidays = [], isLoading: areHolidaysLoading } = useGetHolidaysQuery();
  const { data: workdays = [], isLoading: areWorkdaysLoading } = useGetWorkdaysQuery();
  const { data: cabinetsDictionary, isLoading: areCabinetsLoading } = useGetCabinetsDictionaryQuery();

  const [setScheduleBulk] = useSetScheduleBulkMutation();
  const [updateUser] = useUpdateUserMutation();

  const data = {
    staffSchedulerAPI,
    tableColumnModel,
    datesToRender,
    schedulerWidth,
    currentStaffer,
    dateRange,
    datesInRange,
    filterCabinets,
    lastCabinetId,
    datesWithSessions,
    showEvenDates,
    showOddDates,
    selectedDate,
    sessionsMatches,
    currentSessionsMatch,
    isWatchingMatches,
    sessionWithTooltipId,
    tableDisplayMode,

    holidays,
    workdays,
    schedule,
    dispatch,
  };

  const isSchedulerLoading =
    scheduleIsLoading ||
    staffersAreLoading ||
    appointmentsAreLoading ||
    areHolidaysLoading ||
    areWorkdaysLoading ||
    areCabinetsLoading;

  const isFirstCheckValid = () => {
    return !tableInitialized && !isSchedulerLoading && staffSchedulerAPI && Object.keys(staffSchedulerAPI).length;
  };

  const isSecondCheckValid = () => {
    return checkForAnyRelevantSession(schedule, cabinetsDictionary, currentStaffer.id);
  };

  const isThirdCheckValid = () => {
    return Boolean(filterCabinets.length) && Boolean(datesInRange.length) && Boolean(dateRange[1]);
  };

  const shouldInitialize = () => {
    return isFirstCheckValid() && (isSecondCheckValid() || isThirdCheckValid());
  };

  const initializeTableData = () => {
    handleInitializeTableData({ ...data, filterCabinets, cabinetsDictionary, staffers });
    setTableInitialized(true);
  };

  // Найти из всех юзеров и закинуть в Redux текущего
  useEffect(() => {
    if (staffers.length) {
      const pickedStaffer = staffers.find((staffer) => staffer.id === pickedStafferId);
      dispatch(setCurrentStaffer(pickedStaffer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffers]);

  // Обработка смены "Цветового обозначения"
  useEffect(() => {
    if (staffSchedulerAPI && Object.keys(staffSchedulerAPI).length) {
      sessionColorSetter(data);
    }
  }, [currentStaffer?.color]);

  // Обработка изменения кабинетов и диапазона дат в пикерах
  useEffect(() => {
    const check = !tableInitialized || !Boolean(filterCabinets.length) || !Boolean(dateRange[1]);
    setIsTableHidden(check);

    if (check) return;

    handleDateRangeChange({ ...data, filterCabinets, cabinetsDictionary, staffers });
  }, [dateRange, filterCabinets]);

  // Обработка инициализации таблицы (запускается 1 раз)
  useEffect(() => {
    if (shouldInitialize()) {
      setIsTableHidden(false);
      initializeTableData();
    }
  }, [isSchedulerLoading, staffSchedulerAPI, dateRange, filterCabinets]);

  // Обработка изменения ширины таблицы
  useEffect(() => {
    const tableWrapper = document.getElementById('FCWidthWrapper');

    if (tableWrapper) {
      tableWrapper.style.width = `${schedulerWidth}px`;
    } else {
      console.warn('Не получилось найти обёртку (<div id="FCWidthWrapper">) таблицы. Ширина установлена не будет.');
    }
  }, [schedulerWidth]);

  // Обработка изменения выбранной даты
  useEffect(() => {
    if (tableInitialized && staffSchedulerAPI && Object.keys(staffSchedulerAPI).length) {
      handleSelectedDateChange({ ...data, filterCabinets, cabinetsDictionary, staffers });
    }
  }, [selectedDate]);

  // Обработка изменения фильтра чётных/нечётных
  useEffect(() => {
    if (tableInitialized && staffSchedulerAPI && Object.keys(staffSchedulerAPI).length) {
      handleEvenOddFiltersChange({ ...data, filterCabinets, cabinetsDictionary, staffers });
    }
  }, [showEvenDates, showOddDates]);

  // Обработка изменения режима отображения (дни/периоды)
  useEffect(() => {
    if (tableInitialized && staffSchedulerAPI && Object.keys(staffSchedulerAPI).length) {
      handleTableDisplayModeChange({ ...data, filterCabinets });
    }
  }, [tableDisplayMode]);

  return (
    <LightBox
      isOpen={true}
      confirmButtonText="Сохранить расписание"
      onClose={() => {
        onClose();
        dispatch(reset(null));
        stopEditClinic(userId);
      }}
      onSave={() => {
        saveSchedulerData(setScheduleBulk, updateUser, onClose, dispatch);
        stopEditClinic(userId);
      }}
    >
      {scheduleIsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <SelectBlocker onContextMenu={(e) => e.preventDefault()}>
            <StaffScheduleMatchesWarning />
            <StaffScheduleHeading />
            <Content>
              <Settings>
                {staffersAreLoading ? <LoadingScreen /> : <StaffScheduleColorPicker />}
                <StaffScheduleDateRangePicker />
                <StaffScheduleCabinetsPicker />
                <StaffSchedulerCalendar />
              </Settings>
              {staffersAreLoading || appointmentsAreLoading || areHolidaysLoading || areWorkdaysLoading ? (
                <LoadingScreen />
              ) : (
                <StaffSchedulerTable
                  appointments={appointments}
                  staffers={staffers}
                  schedule={schedule}
                  currentStaffer={currentStaffer}
                  isTableHidden={isTableHidden}
                />
              )}
            </Content>
          </SelectBlocker>
        </>
      )}
    </LightBox>
  );
};

export default StaffScheduleSetup;
