import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";

export const Adornment = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #a8aeb5;
`;

export const ContainerWithBorder = styled.div<{error: boolean}>`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  margin: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: ${({ error }) => (error ? "1px solid #DF194C" : "1px solid #d3d6da")};
  border-radius: 4px;
  &:hover {
    border: ${({ error }) =>
      error ? "1px solid #DF194C" : "1px solid #577bf9"};
    outline: none;
  }
  &:focus {
    border: ${({ error }) =>
      error ? "1px solid #DF194C" : "1px solid #4555e4"};
    box-shadow: ${({ error }) =>
      error
        ? "0px 0px 4px rgba(245, 34, 45, 0.5)"
        : "0px 0px 4px rgba(87, 123, 249, 0.5)"};

    outline: none;
  }
`;

export const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999999999;
  background: rgba(37, 52, 70, 0.6);
`;

export const Dialog = styled.div`
  position: fixed;
  width: 374px;
  top: calc(50% - 200px);
  left: calc(50% - 180px);
  z-index: 999999999999999;
  background: #ffffff;
  border-radius: 4px;
  padding-top: 32px;
  padding-left: 40px;
  padding-bottom: 32px;
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #253446;
  margin-bottom: 20px;
  width: 300px;
`;
export const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #253446;
`;

export const RadioLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #515d6b;
`;
export const FormLayout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
`;

export const ButtonsLayout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-top: -10px;
`;
export const BlueRadioButton = withStyles({
  root: {
    color: "#D3D6DA",
    cursor: "pointer",
    "&$checked": {
      color: "#577BF9",
    },
    "&:hover": {
      color: "#577BF9",
    },
  },
  checked: {},
// eslint-disable-next-line react/react-in-jsx-scope
})((props: any) => <Radio color="default" {...props} />);