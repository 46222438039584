import { makeStyles } from '@material-ui/core';

export const SelectCSS = makeStyles({
  root: {
    width: '100%',
    height: '32px',
    color: '#577BF9 !important',
    border: '1px solid #d3d6da',
    "& div[role='button']": {
      fontSize: '14px',
    },
    '& svg': {
      fill: '#515d6b',
    },
    '&:hover': {
      border: '1px solid #577BF9',
    },
    '& > input:focus': {
      'box-shadow': '0px 0px 4px rgba(87, 123, 249, 0.5)',
    },
    '& > div:first-child': {
      color: '#A8AEB5',
    },
    '& > fieldset': {
      display: 'none',
    },
  },
});

export const SelectActiveValueCSS = makeStyles({
  root: {
    width: '100%',
    height: '32px',
    color: '#577BF9 !important',
    border: '1px solid #d3d6da',
    "& div[role='button']": {
      fontSize: '14px',
    },
    '&:hover': {
      border: '1px solid #577BF9',
    },
    '& svg': {
      fill: '#253446',
    },
    '& > input:focus': {
      'box-shadow': '0px 0px 4px rgba(87, 123, 249, 0.5)',
    },
    '& > div:first-child': {
      color: '#515d6b',
    },
    '& > fieldset': {
      display: 'none',
    },
  },
});

export const SelectMouseUpCSS: React.CSSProperties = {
  boxShadow: '0px 0px 4px rgba(87, 123, 249, 0.5)',
  border: '1px solid #577BF9',
};

export const OptionStyles = makeStyles({
  root: {
    fontSize: '14px !important',
    color: '#515d6b !important',
    '&:hover': {
      backgroundColor: '#eff2ff !important',
    },
  },
});
