import PingWebSocket from './pingWebsocket';
import { get } from 'lodash';
import { WS_ACTION_TYPES } from './actionTypes';

const MAX_RECONNECT_COUNT = 3;

export const WEBSOCKET_STATUS = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

export default class EventsWebSocket {
  reconnectCount = 0;

  constructor(wsUrl: string, onMessage, hasPing = false) {
    this.wsUrl = wsUrl;

    if (hasPing) {
      this.ping = new PingWebSocket(this);
    }

    this.onMessage = onMessage;

    this.connect();
  }

  connect = () => {
    if (this.readyState() !== WEBSOCKET_STATUS.OPEN) {
      this.websocket = new WebSocket(this.wsUrl);
      this.websocket.onopen = this.handleWebsocketOpen;
      this.websocket.onclose = this.handleWebsocketClose;
      this.websocket.onmessage = this.handleWebsocketMessage;
      this.websocket.onerror = this.handleWebsocketError;
    }
  };

  disconnect = () => {
    if (this.readyState() === WEBSOCKET_STATUS.OPEN) {
      this.websocket.close();
    }
  };

  reconnect = () => {
    this.reconnectCount += 1;

    if (this.reconnectCount > MAX_RECONNECT_COUNT) {
      this.disconnect();
    }

    this.disconnect();
    this.connect();
  };

  send = (message) => {
    if (this.readyState() !== WEBSOCKET_STATUS.OPEN) {
      this.reconnect();
    }

    this.websocket.send(JSON.stringify(message));
  };

  readyState = () => {
    return get(this.websocket, 'readyState', WEBSOCKET_STATUS.CLOSED);
  };

  handleWebsocketOpen = () => {
    this.ping?.start();
    this.websocket.send(JSON.stringify({ action: 'auth', data: { token: sessionStorage.getItem('access_token') } }));
  };

  handleWebsocketClose = () => {
    this.ping?.stop();
  };

  handleWebsocketMessage = (msg) => {
    if (!msg || !msg.data) return;
    const message = JSON.parse(msg.data);

    switch (message.action) {
      case WS_ACTION_TYPES.PONG:
        if (+sessionStorage.getItem('id') === message.data.target_id) {
          this.ping?.clearCount();
        }
        break;
      default:
        this.onMessage(message);
        break;
    }
  };

  handleWebsocketError = () => {
    this.reconnect();
  };
}
