import React, { useEffect, useState } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ru from 'date-fns/locale/ru';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { CustomCalendar, renderDay } from './components';
import { getWeekdaysToHighlight } from './functions';
import { CalendarWrapper, CalendarFooter, YearControls, YearControlsWrapper } from './styled-components';
import { CalendarPropsType } from './types';

export const SchedulerCalendar: React.FC<CalendarPropsType> = ({ children, onDisableDates, isDisabled = false }) => {
  const dispatch = useAppDispatch();
  const {
    datesToHighlightSet,
    currentStaffer,
    dateRange,
    datesInRange,
    selectedDate,
    selectedCabinets,
    datesToRender,
  } = useAppSelector((state) => state.staffSchedule);

  const [timePeriodToDisplay, setTimePeriodToDisplay] = useState(new Date());
  const [weekdaysToHighlight, setWeekdaysToHighlight] = useState(
    getWeekdaysToHighlight(datesToHighlightSet, new Date().getFullYear(), new Date().getMonth()),
  );

  useEffect(() => {
    setWeekdaysToHighlight(
      getWeekdaysToHighlight(datesToHighlightSet, timePeriodToDisplay.getFullYear(), timePeriodToDisplay.getMonth()),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesToHighlightSet, selectedCabinets.length]);

  const isDisabledPreviousYear = dateRange[0] && dateRange[0].getFullYear() === timePeriodToDisplay.getFullYear();

  const theme = createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableTouchRipple: true,
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CalendarWrapper isDisabled={isDisabled}>
        <YearControls>
          <YearControlsWrapper>
            <KeyboardArrowUpIcon
              style={{
                width: 15,
                height: 15,
                color: isDisabled ? '#A8AEB5' : '#253446',
                cursor: isDisabled ? 'auto' : 'pointer',
              }}
              onClick={() => {
                if (!isDisabled) {
                  setTimePeriodToDisplay(
                    new Date(
                      timePeriodToDisplay.getFullYear() + 1,
                      timePeriodToDisplay.getMonth(),
                      timePeriodToDisplay.getDate(),
                    ),
                  );
                }
              }}
            />
            <KeyboardArrowDownIcon
              style={{
                width: 15,
                height: 15,
                color: isDisabledPreviousYear || isDisabled ? '#A8AEB5' : '#253446',
                cursor: isDisabledPreviousYear || isDisabled ? 'auto' : 'pointer',
              }}
              onClick={() => {
                if (!isDisabled && !isDisabledPreviousYear) {
                  setTimePeriodToDisplay(
                    new Date(
                      timePeriodToDisplay.getFullYear() - 1,
                      timePeriodToDisplay.getMonth(),
                      timePeriodToDisplay.getDate(),
                    ),
                  );
                }
              }}
            />
          </YearControlsWrapper>
        </YearControls>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
          <CustomCalendar
            date={timePeriodToDisplay}
            disabled={isDisabled}
            views={['day']}
            highlightColor={currentStaffer.color}
            weekdaysToHighlight={weekdaysToHighlight}
            minDate={datesInRange[0]}
            maxDate={datesInRange[datesInRange.length - 1]}
            onChange={() => {
              return;
            }}
            shouldDisableDate={onDisableDates}
            renderDay={(day, selectedDates, pickersDayProps) =>
              renderDay(
                day as Date,
                selectedDates,
                { ...pickersDayProps, allowSameDateSelection: true },
                datesToHighlightSet,
                selectedDate,
                datesToRender,
                selectedCabinets,
                dispatch,
              )
            }
            onMonthChange={(dateArg: unknown | Date) =>
              setWeekdaysToHighlight(
                getWeekdaysToHighlight(
                  datesToHighlightSet,
                  (dateArg as Date).getFullYear(),
                  (dateArg as Date).getMonth(),
                ),
              )
            }
          />
        </LocalizationProvider>
        <CalendarFooter>{children}</CalendarFooter>
      </CalendarWrapper>
    </ThemeProvider>
  );
};
